import React from "react";
import styled from "styled-components";
import { Card, CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
const ShareStyle = styled(Modal)`
    .share {
        display: flex;
        align-items: center;
        a {
            &:hover {
                text-decoration: unset;
            }
        }
        &-icon {
            width: 25px;
        }
        &-name {
            width: 100%;
            display: block;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            text-transform: capitalize;
        }
    }
    .orShare {
        display: block;
        margin: 15px 0;
        width: 100%auto;
        text-align: center;
        position: relative;
        color: #464646;
        font-size: 12px;
        &:before {
            top: 49%;
            content: "";
            position: absolute;
            left: 0;
            width: 46%;
            height: 2px;
            background: #dddddd;
        }
        &:after {
            top: 49%;
            content: "";
            position: absolute;
            right: 0;
            width: 46%;
            height: 2px;
            background: #dddddd;
        }
    }
`;
const CardClipboard = styled(Card)`
    font-size: 14px;
    background: rgb(239 242 246);
    color: rgb(34 114 187);
    border-color: rgb(34 114 187);
    margin-bottom: 15px;
    .card-body {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .copyClip {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .copy-action {
        font-size: 24px;
        cursor: pointer;
    }
    .copied {
        opacity: ${(props) => (props.active === "true" ? "1" : "0")};
        position: absolute;
        padding: 5px 20px;
        color: #fff;
        font-weight: bold;
        border-radius: 5px;
        font-size: 14px;
        bottom: -37px;
        z-index: 2;
        right: 0;
        text-align: center;
        transition: all.05s ease;
    }
`;
const ShareProduct = ({ isShow = false, data = false, toggle = () => {} }) => {
    const [isCopied, setCopied] = React.useState(false);
    const copyCodeToClipboard = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
        return navigator.clipboard.writeText(
            `${window.location.origin}/product/${data?.copy?.code}`
        );
    };
    return (
        <ShareStyle isOpen={isShow}>
            <ModalHeader toggle={toggle}>
                Bagikan {data?.copy?.product?.name}
            </ModalHeader>
            <ModalBody>
                {data && (
                    <React.Fragment>
                        {data.copy && (
                            <CardClipboard active={isCopied.toString()}>
                                <CardBody>
                                    <div className="copyClip">
                                        <span>
                                            {window.location.origin}/product/
                                            {data.copy.code}
                                        </span>
                                        <div
                                            className="copy-action"
                                            onClick={copyCodeToClipboard}
                                        >
                                            <i className="far fa-copy"></i>
                                        </div>
                                        <div className="copied bg-success">
                                            <span>Salin Url</span>
                                        </div>
                                    </div>
                                </CardBody>
                            </CardClipboard>
                        )}
                        <span className="orShare">atau</span>
                        {data.share &&
                            data.share.map((item, key) => {
                                return (
                                    <Card
                                        key={key}
                                        className="shadow"
                                        style={{ marginBottom: 15 }}
                                    >
                                        <CardBody>
                                            <a
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                href={item.link}
                                            >
                                                <div className="share">
                                                    <img
                                                        alt={item.name}
                                                        src={item.icon}
                                                        className="share-icon"
                                                    />
                                                    <span className="share-name">
                                                        {item.name}
                                                    </span>
                                                </div>
                                            </a>
                                        </CardBody>
                                    </Card>
                                );
                            })}
                    </React.Fragment>
                )}
            </ModalBody>
        </ShareStyle>
    );
};
export default ShareProduct;
