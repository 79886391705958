import React from "react";

import Style from "./style";
import { Col, Row } from "reactstrap";
import Catalog from "./Catalog";
import { useStore } from "../../../../Reducers";
import service from "../index.service";
import DeleteProduct from "../Modal/DeleteProduct";
const DEFAULT_LIMIT_DATA = 10;
const Index = ({ data = false }) => {
    const [isShow, setShow] = React.useState(false);
    const { dispatch, state } = useStore();
    const [product, setProduct] = React.useState(false);
    const [showProductModal, setShowProductModal] = React.useState(false);
    const [isFirstGet, setFirstGet] = React.useState(true);
    const [isDelete, setDelete] = React.useState(false);
    const [isFailed, setFailed] = React.useState(false);
    const [productDelete, setProductDelete] = React.useState(false);
    const [configTable, setConfigurationTable] = React.useState(false);
    const [params, setParams] = React.useState({
        page: 0,
        size: DEFAULT_LIMIT_DATA,
    });
    const showProduct = () => {
        setShow(!isShow);
    };
    const handleShowProduct = (item) => {
        setShowProductModal(!showProduct);
    };
    const handleModalDelete = (item) => {
        setProductDelete(item);
        setDelete(!isDelete);
    };
    const getData = (params) => {
        service.productList({ dispatch, params });
        setParams(params);
    };
    const onClickNumber = (number) => {
        getData({
            page: number,
            size: DEFAULT_LIMIT_DATA,
        });
    };
    React.useEffect(() => {
        if (isFirstGet) {
            service.productList({ dispatch, params });
            setFirstGet(false);
        }
    }, [isFirstGet, dispatch, params]);

    React.useEffect(() => {
        if (state?.reseller?.product?.isSuccess) {
            setProduct(state.reseller.product.data.content);

            const { number, next_page, total_pages, total_elements } =
                state.reseller.product.data;
            const isPagination = Number(total_elements) > 1;
            const responseHeaderData = {
                currentPage: Number(number + 1),
                nextPage: next_page,
                total_page: Number(total_pages),
                total_elements: total_elements,
                isPagination: isPagination,
                paginationListNumber: total_pages,
            };
            setConfigurationTable(responseHeaderData);
            service.productResellerClear({ dispatch });
            // service.productListClear({ dispatch });
        }
    }, [state, dispatch]);
    return (
        <Style>
            <Row>
                <Col xl="12">
                    <Catalog
                        defaultLimitData={DEFAULT_LIMIT_DATA}
                        onClickNumber={onClickNumber}
                        configTable={configTable}
                        state={state}
                        handleShowProduct={handleShowProduct}
                        setShowProductModal={setShowProductModal}
                        showProductModal={showProductModal}
                        isFailed={isFailed}
                        handleModalDelete={handleModalDelete}
                        dataProduct={product}
                        showProduct={showProduct}
                    />
                </Col>
            </Row>
            <DeleteProduct
                setDelete={setDelete}
                setFailed={setFailed}
                item={productDelete}
                isShow={isDelete}
                toggle={handleModalDelete}
            />
        </Style>
    );
};

export default Index;
