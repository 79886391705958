import React from "react";
import { CardBody, Card } from "reactstrap";
import styled from "styled-components";
import { formatMoney } from "../../Utils/PriceConverter";
import StartIcon from "../../Components/Assets/icon/star-card.png";

const CardStyle = styled(Card)`
  background: #ffffff;
  box-shadow: -4px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  border-color: transparent;
  margin: 15px 0;
  height: 350px;
  cursor: pointer;
  @media (max-width: 550px) {
    height: 240px;
  }
  .card {
    &-image {
      position: relative;
      margin-bottom: 16px;
      text-align: center;
      > img {
        border-radius: 8px;
        height: 188px;
        margin: auto;
        @media (max-width: 550px) {
          height: 100px;
        }
      }
    }
    &-name {
      color: #000000;
      line-height: 1.5;
      // margin-bottom: 5px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &-price {
      color: #000000;
      margin-bottom: 5px;
    }
    &-city {
      color: #000000;
      margin-bottom: 5px;
    }
    &-stock {
      color: #000a8c;
    }
    &-rating {
      position: absolute;
      top: 0;
      right: 0;
      background: #fff;
      padding: 0 10px;
      display: flex;
      align-items: center;
      border-radius: 0px 0px 0px 12px;
      .rating {
        margin-right: 5px;
      }
    }
  }
`;
const CardNew = ({
  onGetValue = () => {},
  item = {},
  image = "",
  alt = "",
  name = "",
  rating = 0,
  price = 0,
  city = "",
  stock = 0,
}) => {
  return (
    <CardStyle onClick={() => onGetValue(item)}>
      <CardBody>
        <div className="card-image">
          <img src={image} alt={alt} className="img-fluid" />
          <div className="card-rating">
            <div className="rating">{rating}</div>
            <img src={StartIcon} alt="rating" className="img-fluid" />
          </div>
        </div>
        <h6 className="card-name font-bold">{name}</h6>
        <h6 className="card-price font-semibold">{formatMoney(price)}</h6>
        <h6 className="card-city font-regular">{city}</h6>
        <p
          className={`pxsmall card-stock ${
            stock <= 5 ? "text-danger" : "text-primary"
          }`}
        >
          Stock: {stock}
        </p>
      </CardBody>
    </CardStyle>
  );
};
export default CardNew;
