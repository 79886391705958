function checkMobile() {
    if(
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPod/i)) {
            return true;
        }
        else {
            return false;
        }
    }
export default checkMobile;