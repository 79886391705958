import styled from "styled-components";
import { color } from "../../Utils/Variable";

export const Style = styled.footer`
  background: ${color.white};
  margin-top: 40px;
  .footer {
    &-top {
      border-radius: 20px 20px 0 0;
      background: ${color.primary};
      padding: 50px;
      color: #fff;
      @media (max-width: 550px) {
        padding: 10px;
      }
      .img-lakugan {
        margin-bottom: 20px;
        display: block;
        @media (max-width: 575px) {
          width: 100px;
        }
      }
      &__border {
        padding-bottom: 30px;
        border-bottom: 1.5px solid rgba(255, 255, 255, 0.4);
        @media (max-width: 550px) {
          display: none;
        }
      }
      @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &-bottom {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      padding: 16px 0px;
      font-size: 16px;
      @media (max-width: 550px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1.5px solid rgba(232, 232, 232, 0.2);
        font-size: 14px;
        margin-bottom: 10px;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          width: 7px;
          height: 7px;
          background: #fff;
          border-radius: 50%;
          top: calc(100% - 54%);
          right: calc(100% - 53%);
        }
      }
    }
  }
  .container {
  }
  .nav-footer {
    span,
    a {
      display: block;
      font-size: 16px;
      line-height: 1.6;
      color: #fff;
      @media (max-width: 575px) {
        font-size: 12px;
      }
    }
  }
  .lakugan-contact {
    margin-bottom: 15px;
    h6 {
      color: #fff;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 150%;
    }
    @media (max-width: 575px) {
      text-align: center;
      h6 {
        font-size: 12px;
      }
    }
  }
  .copyright {
    font-size: 16px;
    color: #fff;
    display: block;
    margin-bottom: 20px;
    @media (max-width: 550px) {
      display: block;
      font-size: 14px;
      text-align: center;
      font-weight: 200;
      margin-bottom: 0;
    }
  }
  .fab {
    margin-right: 10px;
    font-size: 24px;
  }
  .social-media__link {
    &:hover {
      color: unset;
    }
    .fa-facebook-square {
      color: #1977f2;
    }
    .fa-instagram-square {
      color: rgb(234 78 74);
    }
    .fa-twitter-square {
      color: rgb(80 171 241);
    }
    .fa-youtube-square {
      color: #e93f33;
    }
  }
  .share-icon {
    h4 {
      font-weight: bold;
    }
  }
  .footer-price {
    &-desktop {
      padding: 1rem 0;
      background: white;
      position: fixed;
      bottom: 0;
      box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.08);
      border-radius: 20px 20px 0 0;
      width: 100%;
      @media (min-width: 1367px) and (max-width: 1920px) {
        width: 100%;
        background: white;
        position: fixed;
        bottom: 0;
        border-radius: 20px 20px 0 0;
        box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.08);
        display: block;
        padding: 1rem 0;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
    &-mobile {
      display: none;
      @media (max-width: 768px) {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 8px 8px 0 0;
        background: #fff;
        bottom: 0;
        position: fixed;
        padding: 1rem 2rem;
        box-shadow: 0 -10px 16px rgba(0, 0, 0, 0.1);

        .container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1.5px solid #e8e8e8;
          padding: 0;
          .total {
            font-size: 14px;
            font-weight: 600;
            &-item {
              font-weight: 300;
              font-size: 14px;
              padding-top: 8px;
            }
          }
        }
        .buy-now {
          border-radius: 8px;
          height: 3rem;
          width: 8rem;
          font-size: 12px !important;
          font-weight: 600 !important;
        }
      }
    }
  }
  .dropdown-voucher {
    background: transparent;
    padding: 4px 0;
    border-radius: 8px;
    width: 184px;
    border: transparent;
    .dropdown-item {
      margin-bottom: 2px;
      padding: 0;
      &:hover {
        background: transparent;
      }
    }
  }
  .mobile-hide {
    display: block;
    @media (max-width: 550px) {
      display: none;
    }
  }
  .voucher-list {
    min-width: 220px;
    min-height: 60px;
    border: 1.5px solid #e8e8e8;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    background: #fff;
    color: #000000;
    &:hover {
      background: #868686;
      border: 1px solid #fff;
    }
    p {
      margin: auto 0;
    }
    img {
      margin: auto 0;
      height: 20px;
      width: 20px;
    }
  }
  .mid-grid {
    @media() {
      margin: 0;
    }
    @media (min-width: 1024px) {
      margin: 0 25px;
    }
    @media (min-width: 1280px) {
      margin: 0 172px;
    }
  }
  .modal {
    &-show {
      border-radius: 16px;
    }
    &-login {
      row {
        justify-content: center;
      }
    }
    &-wrapper {
      border-radius: 20px !important;
    }
  }
`;
export const ModalForm = styled.div`
  .custom-form {
    height: 54px;
  }
  .reset-password {
    display: flex;
    justify-content: flex-end;
    width: 96%;
  }
  button {
    margin: 12px 0;
  }
  p {
    margin: 0;
  }
`;
export const CustomStyles = {
  content: {
    position: "absolute",
    padding: "0",
    margin: "14% auto",
    width: "450px",
    height: "400px",
    inset: "0",
    borderRadius: "16px",
  },
  overlay: {
    background: "rgba(106, 106, 106, 0.80)",
  },
};
