/*eslint-disable*/

import React from "react";
import { Col, FormGroup, Input, Row, Label } from "reactstrap";
import styled from "styled-components";
import GrowingLoading from "../../../../Components/Loading/GrowingLoading";
import CommonTable from "../../../../Components/Molekul/CommonTable";
import Form from "../../../../Components/Molekul/FormValidation";
import Button from "../../../../Components/Particle/Button";
import FormPrice from "../../../../Components/Particle/Form/FormPrice";
import FormText from "../../../../Components/Particle/Form/FormText";
import { color } from "../../../../Utils/Variable";
import { useStore } from "../../../../Reducers";
import service from "../index.service";
import TableVolume from "./TableVolume";
import MessageState from "../../../../Components/Modal/Message";

const Style = styled.div`
    padding: 20px;
    .sub-title {
        color: #848484;
        font-size: 14px;
    }
    .form-spec {
        min-width: 100px;
    }
    .btn-remove {
        background: transparent;
        border: unset;
        i {
            color: red;
        }
    }
`;
const EditProduct = ({
    setProductItem = () => {},
    dataProduct,
    updateProduct = () => {},
    loading = false,
    setShow = () => {},
    setLoading = () => {},
    isShow = false,
    stockVoucher,
    setStockVoucher = () => {},
}) => {
    const [isSpecUpdate, setSpecUpdate] = React.useState(true);
    const [isSpec, setSpec] = React.useState(dataProduct.is_special_cut);
    const [status, setStatus] = React.useState(true);
    const [message, setMessage] = React.useState("");
    const [showMessage, setShowMessage] = React.useState(false);
    const [itemDelete, setItemDelete] = React.useState(true);
    const [isErrorSpec, setErrorSpec] = React.useState(false);
    const [isVoucher, setVoucher] = React.useState(false);
    const [validationClick, setValidationClick] = React.useState(true);
    const [nominalSpecialCut, setNominalSpecialCut] = React.useState(
        dataProduct.nominal_special_cut
    );

    const [errorCheckedPrice, setErrorCheckedPrice] = React.useState("");
    const [validateForm, setValidateForm] = React.useState(true);
    const [formPiece, setFormPiece] = React.useState(
        dataProduct.is_special_cut
    );
    const [countDay, setCountDay] = React.useState(dataProduct.count_day || 0);
    const [formDuration, setFormDuration] = React.useState(
        dataProduct.pre_order || false
    );
    const { dispatch, state } = useStore();
    const [validTable, setValidTable] = React.useState([
        {
            id: 1,
            weight: false,
            height: false,
            width: false,
            length: false,
            total_stock: false,
        },
    ]);
    const [form, setForm] = React.useState([
        {
            name: "nameProduct",
            label: "Nama produk",
            type: "text",
            action: "text",
            col: 12,
            valid: "Nama produk tidak boleh kosong",
            value: dataProduct.name,
            status: true,
        },
        {
            name: "price",
            type: "price",
            label: "Harga",
            action: "price",
            currencyLogo: "Rp. ",
            col: 6,
            value: dataProduct.price,
            defaultValue: dataProduct.price,
            status: true,
        },
        {
            name: "category",
            type: "select",
            action: "select",
            label: "Kategori",
            col: 6,
            service: {
                api: service.category,
                group: "category",
                key: "category",
            },
            isMulti: false,
            valid: "Pilih kategori",
            value: {
                label: dataProduct.category.name,
                value: dataProduct.category.id,
            },
            status: true,
        },
        {
            name: "image-multiple",
            type: "image",
            service: {
                api: service.deleteImage,
                group: "product",
                key: "deleteImage",
                productId: dataProduct.id,
            },
            label: "Gambar produk",
            action: "uploadImageMultiple",
            col: 12,
            valid: "Harap masukkan gambar",
            value: dataProduct.product_image.map((item) => {
                return {
                    id: item.id,
                    name: item.name,
                    timeStamp: "",
                    size: "",
                    type: "image/png",
                    link: item.img_url,
                };
            }),
            status: true,
            max: 10,
        },
        {
            label: "Deskripsi produk",
            name: "desc",
            required: true,
            height: 300,
            maxLength: 2000,
            label: "Deskripsi produk",
            action: "html",
            col: 12,
            valid: "Deskripsi produk tidak boleh kosong",
            value: dataProduct.description,
            status: true,
        },
    ]);
    const [specification, setSpecification] = React.useState(dataProduct.spec);
    const handleAddSpecification = () => {
        let obj = {
            color: "",
            size: "",
            total_stock: 0,
            weight: 0,
            height: 0,
            width: 0,
            length: 0,
        };
        let validObj = {
            weight: false,
            height: false,
            width: false,
            length: false,
            total_stock: false,
        };
        setSpecification((prevState) => {
            return [...prevState, obj];
        });
        setValidTable((prevState) => {
            return [...prevState, validObj];
        });
    };
    React.useEffect(() => {
        if (dataProduct) {
            for (
                var i = validTable.length - 1, l = dataProduct.spec.length + 1;
                i < l;
                i++
            ) {
                setValidTable(
                    [...Array(i)].map((el, i) => {
                        return {
                            id: dataProduct.spec[i].id,
                            weight:
                                dataProduct.spec[i].weight === 0 ? false : true,
                            height:
                                dataProduct.spec[i].height === 0 ? false : true,
                            width:
                                dataProduct.spec[i].width === 0 ? false : true,
                            length:
                                dataProduct.spec[i].length === 0 ? false : true,
                            total_stock:
                                dataProduct.spec[i].total_stock === 0
                                    ? false
                                    : true,
                        };
                    })
                );
            }
        }
    }, [dataProduct]);
    const onRemoveData = React.useCallback((value) => {
        return setSpecification(value);
    });
    const onRemoveDataValid = React.useCallback((value) => {
        return setValidTable(value);
    });
    const onGetValueStockVoucher = (item) => {
        setStockVoucher({ ...stockVoucher, value: item.value });
    };
    const handleDeleteItem = (item, valid) => {
        setItemDelete(item);
        let id = item.id;
        if (item.id === undefined) {
            const items = specification;
            const valueToRemove = item;
            const filteredItems = items.filter(function (item) {
                return item !== valueToRemove;
            });
            const validItems = validTable;
            const valueToRemoveValid = valid;
            const filteredItemsValid = validItems.filter(function (item) {
                return item !== valueToRemoveValid;
            });
            onRemoveDataValid(filteredItemsValid);
            onRemoveData(filteredItems);
        } else {
            service.deleteSpec({ dispatch, id, productId: dataProduct?.id });
        }
    };
    const getShortData = (data, item) => {
        validTable
            .filter(function (obj) {
                return obj.id === item.id;
            })
            .map(function (obj) {
                return (obj[item.name] = item.value === "" ? false : true);
            });
        return data
            .filter(function (obj) {
                return obj.id === item.id;
            })
            .map(function (obj) {
                return (obj[item.name] = item.value);
            });
    };
    const onGetValue = (item) => {
        getShortData(specification, item);
        // specification[item.id - 1][item.name] = item.value;
        // setSpecification(specification);
    };
    const handleCheckPiece = (e) => {
        let checked = e.target.checked;
        setFormPiece(checked);
        if (!checked) {
            setNominalSpecialCut(0);
        }
    };
    const handleCheckDuration = (e) => {
        let checked = e.target.checked;
        setFormDuration(checked);
        if (!checked) {
            setCountDay(0);
        }
    };
    const handleSpecialCut = (item) => {
        setNominalSpecialCut(item.value);
    };
    const onGetValuePreOrder = (item) => {
        setCountDay(item.value);
    };
    const handleCreateProduct = (e) => {
        let isError = false;
        let isErrorColor = false;
        e.preventDefault();
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        if (isVoucher) {
            isErrorColor = false;
        } else {
            for (let key in validTable) {
                if (!validTable[key].weight) {
                    isErrorColor = true;
                }
                if (!validTable[key].height) {
                    isErrorColor = true;
                }
                if (!validTable[key].width) {
                    isErrorColor = true;
                }
                if (!validTable[key].length) {
                    isErrorColor = true;
                }
                if (!validTable[key].total_stock) {
                    isErrorColor = true;
                }
                if (
                    validTable[key].weight &&
                    validTable[key].total_stock &&
                    validTable[key].height &&
                    validTable[key].width &&
                    validTable[key].length
                ) {
                    isErrorColor = false;
                }
            }
        }
        setErrorSpec(isErrorColor);
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError && !isErrorColor) {
            if (
                isSpec &&
                (formPiece === false || nominalSpecialCut < 1)
                //     form[2].value.label === "Aksesoris Khusus") ||
                // form[2].value.label === "Voucher Digital" ||
                // form[2].value.label === "Pulsa & Paket Data" ||
            ) {
                setErrorCheckedPrice("Harap isi Potongan Khusus dengan benar");
            } else {
                setErrorCheckedPrice("");
                setLoading(true);
                let id = dataProduct.id;
                let data = {
                    // id: id,
                    count_day: Number(countDay),
                    name: form[0].value,
                    description: form[4].value,
                    price: form[1].value,
                    rate: 0,
                    pre_order: formDuration,
                    is_special_cut: formPiece,
                    nominal_special_cut: formPiece ? nominalSpecialCut : 0,
                    category: {
                        id:
                            form[2].value.value === undefined
                                ? form[2].value
                                : form[2].value.value,
                    },
                    spec: specification.map((item) => {
                        return {
                            id: item.id,
                            color: item.color || "",
                            size: item.size || "",
                            total_stock: isVoucher
                                ? stockVoucher.value
                                : item.total_stock,
                            weight: item.weight,
                            height: item.height,
                            width: item.width,
                            length: item.length,
                        };
                    }),
                    product_image: form[3].value.map((item) => {
                        return {
                            name: item.name,
                            img_url: item.link,
                            id: item.id,
                        };
                    }),
                };
                updateProduct(data, id);
            }
        }
    };

    const onCheckValue = (form) => {
        if (
            form[2].value.label === "Aksesoris Khusus" ||
            form[2].value.label === "Pulsa & Paket Data"
        ) {
            setSpec(true);
        } else {
            if (form[2].value.label === "Voucher Digital") {
                setVoucher(true);
                setSpec(true);
            } else {
                setVoucher(false);
                setSpec(false);
            }
        }
    };
    React.useEffect(() => {
        if (specification) {
            setSpecUpdate(true);
        }
    }, [specification]);
    React.useEffect(() => {
        if (state?.product?.deleteSpec?.isSuccess) {
            setSpecification((prevState) => {
                return prevState.filter((item) => item.id !== itemDelete.id);
            });
            setSpecUpdate(false);
            setProductItem(dataProduct);
            service.deleteSpecClear({ dispatch });
        }
        if (state?.product?.deleteSpec?.isError) {
            service.deleteSpecClear({ dispatch });
            setStatus(state?.product?.deleteSpec.isSuccess);
            setMessage(state?.product?.deleteSpec.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
        }
        if (state?.product?.createProduct?.isSuccess) {
            setStatus(state?.product?.createProduct.isSuccess);
            setMessage(state?.product?.createProduct.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
                setShow(false);
            }, 2000);
        }
        if (state?.product?.createProduct?.isError) {
            service.updateProductClear({ dispatch });
            setStatus(state?.product?.createProduct.isSuccess);
            setMessage(state?.product?.createProduct.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
        }
    }, [state, dispatch]);

    return (
        <Style>
            <GrowingLoading active={loading} />
            <Row>
                <Col xl="6" md="6" sm="6" xs="12">
                    <span className="sub-title mb-4 d-block">
                        Deskripsi produk
                    </span>
                    <Form
                        onCheckValue={onCheckValue}
                        setForm={setForm}
                        validateForm={validateForm}
                        validationClick={validationClick}
                        form={form}
                    />
                </Col>
                <Col xl="6" md="6" sm="6" xs="12">
                    <span className="sub-title mb-4 d-block">Spesifikasi</span>
                    <div className="mb-5">
                        <MessageState
                            status={status}
                            message={message}
                            isShow={showMessage}
                        />
                        {isSpecUpdate && (
                            <React.Fragment>
                                {!isVoucher ? (
                                    <TableVolume
                                        validTable={validTable}
                                        handleDeleteItem={handleDeleteItem}
                                        specification={specification}
                                        setSpecification={setSpecification}
                                        onGetValue={onGetValue}
                                    />
                                ) : (
                                    <FormText
                                        className="form-spec"
                                        label="Stock"
                                        // id={item.id}
                                        onGetValue={onGetValueStockVoucher}
                                        item={stockVoucher}
                                    />
                                )}
                            </React.Fragment>
                        )}
                        {isErrorSpec && (
                            <small className="text-danger valid-text">
                                <i>Form volume barang harus diisi</i>
                            </small>
                        )}
                        {!isVoucher && (
                            <Button
                                onClick={handleAddSpecification}
                                color={color.primary}
                                type="button"
                                block
                            >
                                + Tambah Spesifikasi
                            </Button>
                        )}
                    </div>
                    <div>
                        <span className="sub-title mb-2 d-block">
                            Spesifikasi Khusus
                        </span>
                        <Row>
                            <Col xl="6" md="6" sm="6">
                                <span className="sub-title mb-2 d-block">
                                    Pre Order
                                </span>
                                <div className="mb-2">
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                defaultChecked={formDuration}
                                                type="checkbox"
                                                id="checkbox2"
                                                onChange={handleCheckDuration}
                                            />{" "}
                                            Durasi (Hari)
                                        </Label>
                                    </FormGroup>
                                </div>
                                {formDuration && (
                                    <FormGroup>
                                        <FormText
                                            onGetValue={onGetValuePreOrder}
                                            item={{
                                                value: countDay,
                                                type: "number",
                                                name: "day",
                                                status: formDuration
                                                    ? false
                                                    : true,
                                            }}
                                        />
                                    </FormGroup>
                                )}
                            </Col>
                            {isSpec && (
                                <Col xl="6" md="6" sm="6">
                                    <span className="label-specification">
                                        Pilih Jenis Potongan
                                        <span className="text-danger">*</span>
                                    </span>
                                    <div className="mb-2">
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    checked={formPiece}
                                                    type="checkbox"
                                                    id="checkbox2"
                                                    onChange={handleCheckPiece}
                                                />{" "}
                                                Potongan Khusus
                                            </Label>
                                        </FormGroup>
                                    </div>
                                    {formPiece && (
                                        <FormGroup>
                                            <FormPrice
                                                item={{
                                                    value: nominalSpecialCut,
                                                    defaultValue:
                                                        nominalSpecialCut,
                                                    currencyLogo: "Rp ",
                                                }}
                                                onGetValue={handleSpecialCut}
                                            />
                                        </FormGroup>
                                    )}
                                    {errorCheckedPrice !== "" && (
                                        <i
                                            className="text-danger"
                                            style={{ fontSize: 12 }}
                                        >
                                            {errorCheckedPrice}
                                        </i>
                                    )}
                                </Col>
                            )}
                        </Row>
                    </div>
                </Col>
            </Row>
            <div className="text-right">
                <Button
                    type="button"
                    color={color.primary}
                    onClick={handleCreateProduct}
                >
                    Simpan
                </Button>
            </div>
        </Style>
    );
};
export default EditProduct;
