import { servicesAction } from "../../../Reducers";

const service = {
    transactionList: ({ dispatch, params, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/transaction/client/${id}`,
            method: "GET",
            params: params,
            qs: true,
            token: true,
            reducer: "service",
            group: "supplier",
            key: "transactionList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Daftar transaksi",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
    transactionAddress: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/transaction_address/list",
            method: "GET",
            token: true,
            reducer: "service",
            group: "supplier",
            key: "transactionAddress",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Profile berhasil diperbaharui",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
            },
        });
    },
    complaintAction: ({ dispatch, transaction_code, status, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/complaint/${transaction_code}/${status}`,
            method: "POST",
            data: data,
            token: true,
            reducer: "service",
            group: "supplier",
            key: "complaintAction",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text:
                        status === "accept"
                            ? "Anda telah memproses keluhan transaksi ini"
                            : "Anda telah menolak keluhan transaksi ini",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
    cancelTransaction: ({ dispatch, transaction_code }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/transaction/${transaction_code}/cancel`,
            method: "PUT",
            token: true,
            reducer: "service",
            group: "supplier",
            key: "cancelTransaction",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Transaksi telah ditolak",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
    agentId: ({ dispatch, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/shipper/agents?shipper_district_id=${id}`,
            token: true,
            method: "GET",
            reducer: "service",
            group: "shipper",
            key: "agentId",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
            },
        });
    },
    orderDetailChecked: ({ dispatch, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/biteship/pickup-order/${id}`,
            token: true,
            method: "GET",
            reducer: "service",
            group: "shipper",
            key: "orderDetailChecked",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
            },
        });
    },
    orderDetail: ({ dispatch, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/biteship/pickup-order/${id}`,
            token: true,
            method: "GET",
            reducer: "service",
            group: "shipper",
            key: "orderId",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
            },
        });
    },

    complaintSendReceipt: ({ dispatch, transaction_code, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/complaint/${transaction_code}/supplier`,
            method: "PUT",
            data: data,
            token: true,
            reducer: "service",
            group: "supplier",
            key: "complaintReceiptSent",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Nomor Resi berhasil terkirim",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
    orderShipperNew: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/biteship/pickup-order/new`,
            token: true,
            method: "POST",
            reducer: "service",
            data: data,
            group: "shipper",
            key: "order",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Pemesanan telah di proses",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
            },
        });
    },
    orderShipper: ({ dispatch, transaction_code }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/shipper/order_shipper?transaction_code=${transaction_code}`,
            token: true,
            method: "GET",
            reducer: "service",
            group: "shipper",
            key: "order",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Pemesanan telah di proses",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
            },
        });
    },
    pickupShipper: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/shipper/pickup_request`,
            token: true,
            method: "POST",
            data: data,
            reducer: "service",
            group: "shipper",
            key: "pickup",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
            },
        });
    },
    transactionDetail: ({ dispatch, transaction_id, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/transaction/${transaction_id}/${id}`,
            // /api/v1/transaction/{transactionCode}/{idClient:[\d]+}
            method: "GET",
            token: true,
            reducer: "service",
            group: "supplier",
            key: "transactionDetail",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
            },
        });
    },
    voucherDigitalPost: ({ dispatch, data, transaction_id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/voucher/digital/${transaction_id}`,
            method: "POST",
            data: data,
            token: true,
            reducer: "service",
            group: "supplier",
            key: "voucherDigital",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Voucher berhasil dikirim",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
            },
        });
    },
    voucherDigital: ({ dispatch, data, transaction_id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/${transaction_id}/voucherdigital`,
            method: "PUT",
            data: data,
            token: true,
            reducer: "service",
            group: "supplier",
            key: "voucherDigital",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Voucher berhasil dikirim",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
            },
        });
    },
    transactionDetailClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "supplier",
            key: "transactionDetail",
            type: "CLEAR",
        });
    },
    voucherDigitalClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "supplier",
            key: "voucherDigital",
            type: "CLEAR",
        });
    },
    pickupShipperClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "shipper",
            key: "pickup",
            type: "CLEAR",
        });
    },
    orderDetailClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "shipper",
            key: "orderId",
            type: "CLEAR",
        });
    },
    orderDetailCheckedClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "shipper",
            key: "orderDetailChecked",
            type: "CLEAR",
        });
    },
    orderShipperClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "shipper",
            key: "order",
            type: "CLEAR",
        });
    },
    agentIdClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "shipper",
            key: "agentId",
            type: "CLEAR",
        });
    },
    transactionAddressClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "supplier",
            key: "transactionAddress",
            type: "CLEAR",
        });
    },
    transactionListClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "supplier",
            key: "transactionList",
            type: "CLEAR",
        });
    },
    commissionList: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/commission",
            method: "GET",
            token: true,
            reducer: "service",
            group: "commission",
            key: "commissionList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Commission List",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    cancelTransactionClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "supplier",
            key: "cancelTransaction",
            type: "CLEAR",
        });
    },
    commissionListClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "commission",
            key: "commissionList",
            type: "CLEAR",
        });
    },
    complaintActionClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "supplier",
            key: "complaintAction",
            type: "CLEAR",
        });
    },
    SentReceiptActionClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "supplier",
            key: "complaintReceiptSent",
            type: "CLEAR",
        });
    },
};
export default service;
