import React, { useRef, useEffect } from "react";
import debounce from "../../../Utils/Debounce";

import {
    FormGroup,
    Row,
    Col,
    InputGroupText,
    InputGroup,
    Input,
    InputGroupAddon,
} from "reactstrap";

const TableTop = (
    {
        haveChecked = false,
        searchBy = "full_name",
        searchInput,
        onSearch = () => {},
        searchValue,
        dummyData = [],
        dataTable = [],
        service,
        setDataTable = () => {},
    },
    props
) => {
    const inputEl = useRef(null);

    const eventOnChangeSearchInput = debounce((value) => {
        if (service) {
            onSearch(value);
        }
    }, 750);
    const eventOnChangeSearchInputDummy = (value) => {
        if (service) {
            onSearch(value);
        } else {
            let getData = dummyData;
            if (value.length > 0) {
                let data = dummyData;
                data = data.filter((person) => {
                    return person[searchBy].toLowerCase().search(value) !== -1;
                    // return lowerCase;
                });
                setDataTable(data);
            }
            if (value.length < 1) {
                setDataTable(getData);
            }
        }
    };
    useEffect(() => {
        if (searchInput) {
            inputEl.current.value = searchValue;
        }
    }, [searchValue, searchInput]);
    return (
        <Row className={`justify-content-end`}>
            {searchInput && (
                <Col sm={5} xs={12}>
                    <FormGroup className="search-box">
                        <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-search" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                ref={inputEl}
                                onChange={
                                    service
                                        ? ({ target: { value } }) =>
                                              eventOnChangeSearchInput(value)
                                        : (e) =>
                                              eventOnChangeSearchInputDummy(
                                                  e.target.value
                                              )
                                }
                                defaultValue={searchValue}
                                placeholder="Cari"
                                type="text"
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
            )}
        </Row>
    );
};

export default TableTop;
