import React from "react";
import Layout from "./items/Layout";
import { useStore } from "../../../Reducers";
import service from "./index.service";
import Skeleton from "../../../Components/Loading/Skeleton";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";

const Style = styled.div`
    background: #fff;
`;
const About = () => {
    const { dispatch, state } = useStore();
    const [isFirstGet, setFirstGet] = React.useState(true);
    const [data, setData] = React.useState(false);

    React.useEffect(() => {
        if (isFirstGet) {
            service.about({ dispatch });
            setFirstGet(false);
        }
    }, [isFirstGet, dispatch]);
    React.useEffect(() => {
        if (state?.user?.about?.isSuccess) {
            setData(state.user.about.data.content);
        }
    }, [state]);
    return (
        <Style>
            {data ? (
                data.map((item, key) => {
                    return (
                        <section key={key}>
                            <Layout item={item} />
                        </section>
                    );
                })
            ) : (
                <Container>
                    <Row>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <Skeleton
                                width="100%"
                                height="50px"
                                style={{ marginBottom: 15 }}
                            />
                            <Skeleton
                                width="100%"
                                height="20px"
                                style={{ marginBottom: 7 }}
                            />
                            <Skeleton
                                width="100%"
                                height="20px"
                                style={{ marginBottom: 7 }}
                            />
                            <Skeleton
                                width="100%"
                                height="20px"
                                style={{ marginBottom: 7 }}
                            />
                            <Skeleton
                                width="100%"
                                height="20px"
                                style={{ marginBottom: 7 }}
                            />
                            <Skeleton
                                width="100%"
                                height="20px"
                                style={{ marginBottom: 7 }}
                            />
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <Skeleton width="100%" height="375px" />
                        </Col>
                    </Row>
                </Container>
            )}
        </Style>
    );
};

export default About;
