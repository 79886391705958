/*eslint-disable*/

import React from "react";
import { SectionTestimonial } from "./styles";
import ArrowRight from "../Assets/icon/arrow-right-circle.png";
import ArrowLeft from "../Assets/icon/arrow-left-circle.png";
import Slider from "react-slick";
import StringIcon from "../Assets/icon/string.png";
import { Row, Container, Col, Card, CardBody } from "reactstrap";
import { testimoni } from "../../Pages/Auth/Home/ListArr";

const Testimonial = () => {
  const settingSliderTwo = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: true,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <SectionTestimonial>
      <h3 className="title-testimonial font-bold">Testimonial</h3>

      <Slider {...settingSliderTwo}>
        {testimoni.map((item, key) => {
          return (
            <Col key={key}>
              <Card className="card-testimoni">
                <CardBody>
                  <h4 className="desc-testimonial font-semibold">
                    "{item.desc}"
                  </h4>
                  <div className="user">
                    <img
                      src={item.image}
                      alt="display"
                      className="img-fluid img-user"
                    />
                    <div>
                      <h6 className="desc-name">{item.name}</h6>
                      <p className="pxsmall desc-company">{item.status}</p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </Slider>
    </SectionTestimonial>
  );
};
export default Testimonial;
