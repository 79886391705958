import React from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Logo from "../../../../Components/Assets/icon/Lakugan_Logo_Circle.png";
import styled from "styled-components";
import SkeletonLoading from "../../../../Components/Loading/Skeleton";
import { color } from "../../../../Utils/Variable";
const TrackingStyle = styled.div`
    display: flex;
    .time {
        color: ${(props) => (props.trackingId === 0 ? `#464646` : "#989898")};
        font-size: 12px;
        margin-right: 10px;
        width: 125px;
    }
    .tracking {
        padding-left: 40px;
        position: relative;
        margin-bottom: 25px;
        width: calc(100% - 125px);
        &-order {
            color: ${(props) =>
                props.trackingId === 0 ? color.primary : "#989898"};
            font-weight: 300;
            font-size: 12px;
        }
        &:before {
            content: "";
            position: absolute;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 2px solid
                ${(props) => (props.trackingId === 0 ? "unset" : "#989898")};
            left: 7px;
            /* background-image: url(${Logo}); */
            background: #989898;
            background-image: ${(props) =>
                props.trackingId === 0 ? `url(${Logo})` : "unset"};
            background-size: contain;
            background-repeat: no-repeat;
            top: 0;
            z-index: 2;
        }
        &:after {
            z-index: 1;
            content: "";
            position: absolute;
            height: ${(props) => `${props.border + 26}px`};
            width: 2px;
            background: #989898;
            left: 16px;
            top: 20px;
            display: ${(props) =>
                props.length === 1
                    ? "none"
                    : props.trackingId + 1 === props.length
                    ? "none"
                    : "block"};
        }
    }
`;
const TrackingList = ({ item, isShow = false, id = 0, length = 1 }) => {
    const [border, setBorder] = React.useState(0);
    const [showTime, setShowTimes] = React.useState("");
    const [showHours, setShowHours] = React.useState("");
    const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agt",
        "Sep",
        "Okt",
        "Nov",
        "Des",
    ];
    const days = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Saptu",
    ];
    React.useEffect(() => {
        if (isShow) {
            let date = new Date(item.created);
            let getDay = date.getDay();
            let getDate = String(date.getDate()).padStart(2, "0");
            let getMouth = date.getMonth();
            let getYear = date.getFullYear();
            let getHour = String(date.getHours()).padStart(2, "0");
            let getMinutes = String(date.getMinutes()).padStart(2, "0");
            const height = document.getElementById(
                `tracking_${id + 1}`
            ).clientHeight;
            setBorder(height);
            setShowTimes(
                `${days[getDay]}, ${getDate} ${month[getMouth]} ${getYear}`
            );
            setShowHours(`${getHour}:${getMinutes}`);
        }
    }, [isShow, id, month, item, days]);
    return (
        <TrackingStyle border={border} length={length} trackingId={id}>
            <div className="time">
                <div>{showTime}</div>
                <div>Pukul {showHours} WIB</div>
            </div>
            <div className="tracking" id={`tracking_${id + 1}`}>
                <div className="tracking-order">
                    {item?.external?.description}
                </div>
            </div>
        </TrackingStyle>
    );
};

const TrackingOrder = ({
    getTracking = false,
    isShow = false,
    toggle = () => {},
    data = false,
}) => {
    return (
        <Modal isOpen={isShow} scrollable={true}>
            <ModalHeader toggle={toggle}>Live Tracking</ModalHeader>
            <ModalBody>
                {getTracking && data && (
                    <div
                        style={{
                            marginBottom: 40,
                            color: "rgb(96, 96, 96)",
                        }}
                    >
                        <div
                            style={{
                                fontSize: 14,
                                fontWeight: 600,
                            }}
                        >
                            Pengirim
                        </div>
                        <div
                            style={{
                                marginBottom: 10,
                            }}
                        >
                            <div
                                style={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    color: "#464646",
                                }}
                            >
                                {data.shipper.rate_name}
                            </div>
                            <div
                                style={{
                                    fontSize: 14,
                                    color: "#464646",
                                }}
                            >
                                No Resi:{" "}
                                {getTracking.data.courier.waybill_id}
                            </div>
                        </div>
                        <div
                            style={{
                                fontSize: 14,
                            }}
                        >
                            <div>
                                Dikirim kepada{" "}
                                <b>{data.customer_detail.full_name}</b>
                            </div>
                            <div>
                                Telepon: {data.customer_detail.phone_number}
                            </div>
                        </div>
                    </div>
                )}

                {data ? (
                    data.shipper.tracking
                        .sort((a, b) => {
                            return new Date(b.created) - new Date(a.created);
                        })

                        .map((item, key) => {
                            return (
                                <TrackingList
                                    length={data.shipper.tracking.length}
                                    item={item}
                                    key={key}
                                    id={key}
                                    isShow={isShow}
                                />
                            );
                        })
                ) : (
                    <Row>
                        <Col>
                            <SkeletonLoading
                                width="100%"
                                height="75px"
                                style={{ marginBottom: "30px" }}
                            />
                        </Col>
                    </Row>
                )}
                {/* {TrackingDummy.map((item, key) => {
                    return (
                        <TrackingList
                            length={TrackingDummy.length}
                            item={item}
                            key={key}
                            id={key}
                            isShow={isShow}
                        />
                    );
                })} */}
            </ModalBody>
        </Modal>
    );
};
export default TrackingOrder;
