import styled from "styled-components";
import { color } from "../../Utils/Variable";

const Style = styled.div`
    display: none;
    position: fixed;
    top: ${(props) => (props.active ? "0" : "2000px")};
    transition: all 0.75s ease;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9;
    @media (max-width: 550px) {
        display: block;
    }
    .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0px;
    }
    .lakugan-filter {
        padding: 10px 0;
        margin-bottom: 10px;
    }
    .filter {
        position: absolute;
        padding: 0px 15px;
        background: #fff;
        border-radius: 15px 15px 0 0;
        top: 20%;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: auto;
        &-header {
            border-bottom: 1.5px solid #e8e8e8;
            padding: 10px 0;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .fa-times {
                font-size: 22px;
                color: ${color.primary};
            }
        }
        &-body {
            margin-bottom: 15px;
        }
        &-value {
            width: max-content;
            padding: 5px 7px;
            font-size: 14px;
            font-weight: 600;
            margin: 0 0px 15px 15px;
            border: 1px solid #e8e8e8;
            border-radius: 10px;
        }
    }
    .sort-product,
    .filter-category,
    .filter-rating,
    .filter-other {
        &.active {
            color: #fff;
            background: ${color.primary};
            border-color: ${color.primary};
        }
    }
    .search-category {
        margin-bottom: 20px;
        position: relative;
        img {
            position: absolute;
            padding: 7px;
            right: 5px;
            top: 0;
        }
    }
    .category {
        display: block;
        width: 100%;
        margin-bottom: 15px;
        &-list {
            padding: 10px 0;
            border-bottom: 1.5px solid #e8e8e8;
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
    }
`;
export default Style;
