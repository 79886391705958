import React from "react";
import styled from "styled-components";

const Style = styled.div`
    p {
        font-size: 14px;
        line-height: 1.8;
    }
    ul {
        list-style: decimal;
        li {
            font-size: 14px;
            line-height: 1.8;
        }
    }
`;
const TermAndConditions = () => {
    return (
        <Style>
            <div>
                <h6>Hi, terima kasih telah mempercayai Lakugan.</h6>
                <p className="mb-4">
                    Pengguna harus membaca Syarat dan Ketentuan ini dengan
                    cermat dan menghubungi Lakugan jika Anda memiliki
                    pertanyaan.
                </p>
                <p>
                    Syarat dan Ketentuan ini merupakan akses Anda ke dan
                    penggunaan situs web Lakugan, konten, fitur, dan layanan
                    pembayaran yang disediakan oleh Lakugan. Dengan mengakses
                    atau menggunakan Lakugan, Pengguna setuju dan terikat oleh
                    Syarat dan Ketentuan ini (“Syarat dan Ketentuan”), dan
                    “Kebijakan Privasi” yang tertera pada halaman berbeda.
                </p>
            </div>
            <div>
                <h6>I. Umum</h6>
                <ul>
                    <li>
                        Platform Lakugan membuka peluang usaha bagi jutaan
                        masyarakat Indonesia dengan bergabung menJoin Reseller
                        tanpa modal untuk memasarkan produk supplier yang telah
                        bekerjasama dengan Lakugan.
                    </li>
                    <li>
                        Lakugan juga membuka peluang kerjasama bagi Supplier
                        yang ingin memasarkan barang jualannya di platform
                        Lakugan.
                    </li>
                </ul>
            </div>
            <div>
                <h6>II. Ketentuan Penggunaan Platform</h6>
                <ul>
                    <li>
                        Warga negara Indonesia yang secara hukum berhak untuk
                        mengadakan perjanjian yang mengikat berdasarkan hukun
                        Negara Republik Indonesia dan telah memiliki kartu tanda
                        penduduk sebagai pengenal.
                    </li>
                    <li>
                        Kami mengumpulkan informasi pribadi anda seperti nama,
                        alamat, no telepon, email ketika Anda mendaftar. Anda
                        harus memberikan informasi yang akurat dan lengkap,
                        memperbaharui informasi kepada kami bukti identitas
                        apapun yang secara wajar dapat kami mintakan. Jika ada
                        perubahan data informasi seperti alamat atau nomor
                        telepon mohon perbarui rincian inormasi anda dengan
                        mengirimkan permintaan anda kepada kami. Kami akan
                        memproses perubahan data anda selambat-lambatnya 14 hari
                        kerja sejak diterimanya permintaan perubahan data.
                    </li>
                    <li>
                        Anda dapat menggunakan platform kami hanya apabila telah
                        terdaftar. Setelah anda berhasil mendaftarkan diri,
                        platform akan memberikan anda akun pribadi yang dapat
                        diakses dengan katasandi yang anda tentukan.
                    </li>
                    <li>
                        Hanya anda yang dapat menggunakan akun anda sendiri dan
                        anda berjanji untuk tidak memberikan wewenang kepada
                        orang lain untuk menggunakan akun anda. Anda tidak dapat
                        menyerahkan atau mengalihkan akun kepada orang lain.
                        Anda harus menjaga kerahasiaan dan keamanan kata sandi
                        akun anda.
                    </li>
                    <li>Anda hanya dapat memiliki satu akun Lakugan.</li>
                    <li>
                        Reseller memiliki peran untuk memperkenalkan produk
                        Lakugan kepada pelanggan, melayani dan berinteraksi
                        dengan pelanggan. Jika terdapat komplain, Reseller wajib
                        menyampaikan kepada Supplier dengan menggunakan layanan
                        keluhan yang tersedia di platform Reseller.
                    </li>
                    <li>
                        Reseller memberikan rating kepada supplier berdasarkan
                        dari pengalaman bertransaksi pelanggan. Dengan aktif
                        memberi rating dapat mendorong Supplier untuk memberikan
                        pelayanan yang semakin baik kepada pelanggan.
                    </li>
                    <li>
                        Supplier adalah mitra kerjasama Lakugan yang menyuplai
                        produk untuk dijual atau dipasarkan oleh Reseller.
                        Produk yang dijual dapat berupa berbagai macam barang
                        seperti Fashion, tas, sepatu, kebutuhan pokok,
                        elektronik, makanan atau minuman. Produk yang dijual
                        atau yang dipasarkan bukan produk illegal yang dilarang
                        secara hukum di wilayah Negara Kesatuan Republik
                        Indonesia.
                    </li>
                    <li>
                        Supplier tunduk dan patuh terhadap kebijakan privasi
                        yang ditetapkan oleh Lakugan.
                    </li>
                    <li>
                        Anda berjanji bahwa anda akan menggunakan ptlatform
                        hanya untuk tujuan yang dimaksud untuk mendapat
                        menggunakan layanan. Anda tidak diperbolehkan untuk
                        menyalahgunakan aplikasi untuk tujuan penipuan atau
                        melakukan hal-hal yang menyebabkan kerugian kepada orang
                        lain.
                    </li>
                    <li>
                        Anda tidak diperkenankan untuk membahayakan, mengubah
                        atau memodifikasi platform atau mencoba dengan cara
                        apapun. Kami berhak melarang Anda menggunakan platform
                        kami lebih lanjut jika Anda menggunakan perangkat yang
                        tidak sesuai atau tidak sah untuk tujuan lain selain
                        daripada tujuan yang dimaksud. Anda berjanji bahwa Anda
                        hanya akan menggunakan suatu jalur akses yang
                        diperbolehkan untuk Anda gunakan.
                    </li>
                    <li>
                        Anda memahami dan setuju bahwa penggunaan platform oleh
                        Anda akan tunduk pada kebijakan privasi kami sebagaimana
                        dapat diubah dari waktu ke waktu. Anda juga memberikan
                        persetujuan sebagaimana dipersyaratkan berdasarkan
                        kebijakan privasi kami.
                    </li>
                    <li>
                        Kami dapat memberikan kebijakan promosi yang dapat
                        ditukar untuk manfaat terkait denganpenggunaan platform.
                        Anda setuju bahwa anda hanya menggunakan promosi
                        tersebut sebagaimana promosi tersebut dimaksudkan dan
                        tidak akan menyalahgunakan, menggandakan, menjual atau
                        mengalihkan promosi tersebut dengan cara apapun.
                    </li>
                    <li>
                        Mohon menginformasikan kepada kami jika Anda tidak lagi
                        memiliki control atas akun yang Anda miliki yang
                        disebabkan oleh retas (hack) dari pihak lain yang tidak
                        bertanggung jawab atau telepon Anda dicuri, sehingga
                        kami dapat membatalkan akun Anda dengan sebagaimana
                        mestinya. Mohon diperhatikan bahwa Anda bertanggung
                        jawab atas penggunaan akun Anda dan Anda mungkin dapat
                        dimintakan tanggung jawabnya meskipun akun Anda tersebut
                        digunakan oleh orang lain.
                    </li>
                </ul>
            </div>
            <div>
                <h6>III. Legalitas</h6>
                <ul>
                    <li>
                        <span className="d-block mb-2">
                            Identitas Perusahaan
                        </span>
                        <div className="mb-3">
                            <div className="d-flex">
                                <p style={{ width: 150, marginBottom: 3 }}>
                                    Nama Perusahaan
                                </p>
                                <p style={{ width: 30, marginBottom: 3 }}>:</p>
                                <p style={{ marginBottom: 3 }}>
                                    PT. Virtu Inovasi Digital
                                </p>
                            </div>
                            <div className="d-flex">
                                <p style={{ width: 150, marginBottom: 3 }}>
                                    Nama platform
                                </p>
                                <p style={{ width: 30, marginBottom: 3 }}>:</p>
                                <p style={{ marginBottom: 3 }}>Lakugan</p>
                            </div>
                            <div className="d-flex">
                                <p style={{ width: 150, marginBottom: 3 }}>
                                    Alamat Kantor
                                </p>
                                <p style={{ width: 30, marginBottom: 3 }}>:</p>
                                <p style={{ marginBottom: 3 }}>
                                    Jl. Aren No 29 RT 007/03, Jati Pulo,
                                    Palmerah, Tomang, Jakarta Barat, Jakarta,
                                    11430, Indonesia
                                </p>
                            </div>
                            <div className="d-flex">
                                <p style={{ width: 150, marginBottom: 3 }}>
                                    Email kantor
                                </p>
                                <p style={{ width: 30, marginBottom: 3 }}>:</p>
                                <p style={{ marginBottom: 3 }}>
                                    <a href="mailto:halo@lakugan.com">
                                        halo@lakugan.com
                                    </a>
                                </p>
                            </div>
                            <div className="d-flex">
                                <p style={{ width: 150, marginBottom: 3 }}>
                                    Nomor telepon
                                </p>
                                <p style={{ width: 30, marginBottom: 3 }}>:</p>
                                <p style={{ marginBottom: 3 }}>
                                    021 - 22 56 28 87
                                </p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <span className="d-block mb-2">Legalitas Usaha</span>
                        <p>
                            PT. Virtu Inovasi Digital Didirikan pada 01 Januari
                            2020 dihadapan Notaris ANDHIKA MAYRIZAL AMIR, SH,
                            M.Kn. notaris di kabupaten Tangerang. Dokumen
                            Legalitas yang dimiliki untuk menjalankan kegiatan
                            usaha sebagai berikut:
                        </p>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Dokumen</th>
                                    <th>Keterangan</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>Akte pendirian</td>
                                    <td>No.1 Selasa 01-01-2020</td>
                                </tr>
                                <tr>
                                    <td>2.</td>
                                    <td>SK Kemenkumham</td>
                                    <td>AHU-0000038.AH.01.01.TAHUN 2020</td>
                                </tr>
                                <tr>
                                    <td>3.</td>
                                    <td>NIB</td>
                                    <td>0220201120699</td>
                                </tr>
                                <tr>
                                    <td>4.</td>
                                    <td>NPWP</td>
                                    <td>93.908.516.3-036.000</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            Secara legalitas PT.Virtu Inovasi Digital telah
                            memenuhi syarat (sah) sebagai badan usaha yang
                            berbadan hukum.
                        </p>
                    </li>
                </ul>
            </div>
            <div>
                <h6>IV. Pengumpulan dan Penggunaan Data</h6>
                <div className="ml-4">
                    <p>
                        Untuk menggunakan platform Lakugan bagi Reseller,
                        Supplier dan Customer diperlukan sejumlah informasi
                        personal seperti:
                    </p>
                    <p className="font-weight-bold mb-1">
                        Reseller dan Supplier
                    </p>
                    <ul>
                        <li>E-mail</li>
                        <li>Nomor Telepon</li>
                        <li>Nomor rekening</li>
                        <li>Foto KTP</li>
                        <li>Foto diri</li>
                        <li>Nama lengkap</li>
                        <li>Tanggal lahir</li>
                        <li>Alamat lengkap</li>
                    </ul>
                    <p className="font-weight-bold mb-1">Costumer</p>
                    <ul>
                        <li>E-mail</li>
                        <li>Alamat tujuan</li>
                        <li>Nama lengkap</li>
                        <li>Nomor Telepon</li>
                    </ul>
                    <p>
                        Lakugan menganjurkan untuk memasukkan informasi dengan
                        benar, Lakugan tidak bertanggung jawab atas segala
                        kerugian yang timbul akibat kesalahan informasi yang
                        anda masukkan. Informasi yang Anda masukkan hanya
                        digunakan untuk keperluan berikut:
                    </p>
                    <ul>
                        <li>
                            Proses identifikasi dan verifikasi akun pengguna
                        </li>
                        <li>Mempermudah proses transaksi pelanggan</li>
                        <li>Data administrasi di Lakugan</li>
                        <li>Menyimpan histori transaksi pengguna di Lakugan</li>
                        <li>Memberikan informasi ataupun notifikasi promo</li>
                    </ul>
                </div>
            </div>
            <div>
                <h6>V. Verifikasi Akun</h6>
                <ul>
                    <li>
                        Verifikasi akun adalah proses verifikasi email atau
                        nomor Telepon. Proses verifikasi dilakukan dengan
                        mengirimkan foto KTP dan foto diri dengan KTP.
                    </li>
                    <li>
                        Melengkapi data profil adalah proses melengkapi data
                        profil anda seperti Tanggal lahir, alamat, serta input
                        no rekening tabungan.
                    </li>
                    <li>
                        Tujuan verifikasi akun dan melengkapi data profil agar
                        memastikan akun itu adalah benar anda, valid dan tidak
                        duplikat akun.
                    </li>
                    <li>
                        Akun yang menurut Lakugan tidak valid atau terdapat
                        kejanggalan dapat ditolak dan diinfokan melalui email.
                    </li>
                </ul>
            </div>
            <div>
                <h6>VI. Tutup Akun</h6>
                <ul>
                    <li>
                        Pengguna harus terverifikasi terlebih dahulu sebelum
                        tutup akun.
                    </li>
                    <li>Sisa Saldo dapat dicairkan seluruhnya.</li>
                    <li>
                        Pengguna mengajukan secara resmi dengan mengirimkan
                        email ke{" "}
                        <a href="mailto:halo@lakugan.com">halo@lakugan.com.</a>
                    </li>
                    <li>
                        Lakugan akan memproses verifikasi dengan menghubungi
                        pengguna sebelum akun di tutup.
                    </li>
                    <li>
                        Akun yang telah ditutup tidak bisa dibuka kembali. Jika
                        ingin menjadi pengguna Lakugan kembali, maka harus
                        melakukan registrasi baru.
                    </li>
                </ul>
            </div>
        </Style>
    );
};
export default TermAndConditions;
