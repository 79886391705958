import React from "react";
import { useHistory } from "react-router";
import { useStore } from "../../../../Reducers";
import JSON_to_URLEncoded from "../../../../Utils/JSON-URLEncode";
import service from "../index.service";

const EWalletPayment = ({ match }) => {
    const [isFirstGet, setFirstGet] = React.useState(true);
    const { dispatch, state } = useStore();
    const history = useHistory();
    React.useEffect(() => {
        if (isFirstGet) {
            var url = new URL(window.location.href);
            var callback = url.searchParams.get("bill_no");
            service.transactionCode({ dispatch, params: callback });
            setFirstGet(false);
        }
    }, [isFirstGet, dispatch]);
    React.useEffect(() => {
        if (state?.user?.transaction?.isSuccess) {
            let transactionCode =
                state.user.transaction.data.transaction_code.split("/");
            let params = {
                full_name:
                    state.user.transaction.data.customer_detail.full_name,
                id: state.user.transaction.data.customer_detail.id,

                product_name: state.user.transaction.data.product.name,
                transaction_id: transactionCode[1],
                payment_channel:
                    state.user.transaction.data.payment_channel.name,
                shipper_name:
                    state?.user?.transaction?.data?.shipper?.rate_name || "-",
            };
            service.transactionCodeClear({ dispatch });
            history.push(`/payment/transaction?${JSON_to_URLEncoded(params)}`);
        }
    }, [state, dispatch, history]);
    return <React.Fragment></React.Fragment>;
};
export default EWalletPayment;
