import React from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import styled from "styled-components";
import service from "../index.service";
import { formatMoney } from "../../../../Utils/PriceConverter";
import Button from "../../../../Components/Particle/Button";
import { color } from "../../../../Utils/Variable";
import Logo from "../../../../Components/Assets/Images/Lakugan_LogoColor_BrightBG.png";
import { useStore } from "../../../../Reducers";
import GrowingLoading from "../../../../Components/Loading/GrowingLoading";
import MessageState from "../../../../Components/Modal/Message";
import ReturnForm from "./ReturnForm";
const Style = styled(Modal)`
    color: #4a4a4a;
    .title {
        color: #4a4a4a;
        width: 164px;
        font-size: 14px;
        font-weight: 500;
    }
    .desc {
        padding-bottom: 10px;
        margin-bottom: 30px;
        border-bottom: 1px dashed #4a4a4a;
        span {
            display: block;
            color: #888888;
            font-size: 14px;
        }
        &-transaction {
            font-size: 14px;
            margin-bottom: 10px;
            &__detail {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
            }
            &__total {
                color: ${color.primary};
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: bold;
            }
            &__input-receipt {
                ${"" /* display: flex; */}
                margin-bottom: 5px;
                margin-top: 30px;
                padding-top: 10px;
                border-top: 1px dashed #4a4a4a;
            }
        }
        &-complaint {
            font-weight: 500;
            font-size: 14px;
        }

        &-value {
            color: ${color.primary};
            font-size: 14px;
            font-weight: 500;
            width: calc(100% - 164px);
            overflow: auto;
        }

        &-complaint-receipt {
            padding-top: 30px;
            margin-bottom: 30px;
            border-top: 1px dashed #4a4a4a;
        }

        th,
        td {
            font-size: 14px;
        }
    }
    .order-picture {
        margin: auto;
        img {
            width: 200px;
            height: 200px;
        }
        h6 {
            font-size: 18px;
            color: #4a4a4a;
            font-weight: 500;
            margin-top: 30px;
        }
    }
    .order-title {
        font-size: 16px;
        margin-right: 10px;
    }
    .view-image {
        cursor: pointer;
    }
`;

const ComplaintModal = ({
    isShow = false,
    toggle = () => {},
    data = false,
    setRefresh = () => {},
    cancel = false,
    process = false,
    openFormVoucher = () => {},
}) => {
    const { dispatch, state } = useStore();
    const [imageView, setImageView] = React.useState("");
    const [showImage, setShowImage] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [transactionDate, setTransactionDate] = React.useState("");
    const [status, setStatus] = React.useState(true);
    const [message, setMessage] = React.useState("");
    const [showReceiptForm, setShowReceiptForm] = React.useState(false);
    const [showMessage, setShowMessage] = React.useState(false);
    const handleActionComplaint = (status) => {
        let fullTransactionCode = data?.transaction_code.split("/");
        let splitTransactionCode = fullTransactionCode[1];
        setLoading(true);
        setRefresh(false);
        service.complaintAction({
            dispatch,
            transaction_code: splitTransactionCode,
            status: status.status,
            data: {
                reason: data?.complaint?.reason,
                solution: data?.complaint?.solution,
                description: data?.complaint?.description,
                images: data?.complaint?.images,
            },
        });
    };
    const viewImage = (image) => {
        setImageView(image);
        setShowImage(true);
    };
    React.useEffect(() => {
        if (data?.customer_detail) {
            let getTime = data.transaction_code.split("/");

            let timestamp = getTime[1];
            let getFullDate = new Date(Number(timestamp));
            setTransactionDate(
                `${getFullDate.getFullYear()}-${String(
                    getFullDate.getMonth()
                ).padStart(2, "0")}-${String(getFullDate.getDate()).padStart(
                    2,
                    "0"
                )}`
            );
            // fetchCommission();
        }
    }, [data]);

    React.useEffect(() => {
        if (state?.supplier?.complaintAction?.isSuccess) {
            service.complaintActionClear({ dispatch });
            setRefresh(true);
            setShowMessage(true);
            setLoading(false);
            setStatus(state.supplier?.complaintAction?.isSuccess);
            setMessage(state.supplier?.complaintAction?.message.text);
            setTimeout(() => {
                setShowMessage(false);
                toggle();
            }, 2000);
        }
        if (state?.supplier?.complaintAction?.isError) {
            service.complaintActionClear({ dispatch });
            setRefresh(true);
            setLoading(false);
            setShowMessage(true);
            setStatus(state.supplier?.complaintAction?.isSuccess);
            setMessage(state.supplier?.complaintAction?.data.message);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
        }

        if (state?.supplier?.complaintReceiptSent?.isSuccess) {
            toggle();
        }
    }, [state, dispatch, toggle, setRefresh]);

    const handleToggleReceiptModal = () => setShowReceiptForm(!showReceiptForm);

    const complaintReceiptElement = () => {
        return (
            <div className="desc-complaint-receipt">
                <Row>
                    <Col md={6}>
                        <div className="desc-transaction__total mb-2">
                            Resi Pengembalian
                        </div>
                        <div className="mb-1">
                            <div className="title mb-1">
                                {data?.complaint.from_customer?.logistic_name}
                            </div>
                            <div style={{ fontSize: 14 }}>
                                {data?.complaint.from_customer?.serial_number}
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="desc-transaction__total mb-2">
                            Resi Pengembalian
                        </div>
                        {!data?.complaint.from_client ? (
                            "-"
                        ) : (
                            <div className="mb-1">
                                <div className="title mb-1">
                                    {data?.complaint.from_client?.logistic_name}
                                </div>
                                <div style={{ fontSize: 14 }}>
                                    {data?.complaint.from_client?.serial_number}
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        );
    };
    return (
        <Style isOpen={isShow} toggle={toggle} size="lg">
            <GrowingLoading active={isLoading} />

            <ModalHeader toggle={toggle}>
                <img src={Logo} alt="Lakugan" className="img-fluid" />
            </ModalHeader>
            {data && (
                <ModalBody>
                    <div className="desc">
                        <div className="mb-4">
                            <Row className="justify-content-between">
                                <Col xl="6" lg="6" md="6" sm="12">
                                    <h6 className="mb-2 border-bottom pb-2">
                                        Data Pembeli
                                    </h6>
                                    <div className="d-flex">
                                        <div className="title">Nama</div>
                                        <div className="mr-2">:</div>
                                        <div className="desc-value">
                                            {data.customer_detail.full_name}
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="title">Email</div>
                                        <div className="mr-2">:</div>
                                        <div className="desc-value">
                                            {data.customer_detail.email}
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="title">Telepon</div>
                                        <div className="mr-2">:</div>
                                        <div className="desc-value">
                                            {data.customer_detail.phone_number}
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="title">Alamat</div>
                                        <div className="mr-2">:</div>
                                        <div className="desc-value">
                                            {data.customer_detail.address}
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="title">
                                            Metode Pembayaran
                                        </div>
                                        <div className="mr-2">:</div>
                                        <div className="desc-value">
                                            {data.payment_channel.name}
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="5" lg="5" md="5" sm="12">
                                    <div className="text-center">
                                        <div className="mb-4">
                                            <div className="order-picture">
                                                <h6>Pemesanan</h6>
                                                <img
                                                    src={
                                                        data.product
                                                            .product_image[0]
                                                            .img_url
                                                    }
                                                    alt=""
                                                    className="img-fluid mb-3"
                                                />
                                                <h4 className="text-center">
                                                    {data.product.name}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className="order-title">
                                                Jumlah
                                            </div>
                                            <div className="mr-2">:</div>
                                            <div
                                                className="desc-value"
                                                style={{ fontSize: 16 }}
                                            >
                                                {data.total_amount} Barang
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="12"></Col>
                            </Row>
                        </div>
                        <div className="mb-4 desc-complaint">
                            <div className="title mb-1">
                                Alasan Pengembalian:{" "}
                            </div>
                            <div>{data?.complaint?.reason}</div>
                        </div>{" "}
                        <div className="mb-4">
                            <div className="title mb-1">Detail Keluhan: </div>
                            <div>{data?.complaint?.description || "-"}</div>
                        </div>
                        <div className="mb-4">
                            <div className="title mb-1">Solusi: </div>
                            <div>{data?.complaint?.solution || "-"}</div>
                        </div>
                        <div className="mb-4">
                            <div className="title mb-1">Foto Keluhan: </div>
                            <Row>
                                {data?.complaint?.images.map((item, key) => {
                                    return (
                                        <Col sm={3} key={key}>
                                            <div
                                                className="view-image"
                                                onClick={() =>
                                                    viewImage(item.img_url)
                                                }
                                            >
                                                <img
                                                    src={item.img_url}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                    </div>

                    <div className="desc-transaction">
                        <div className="desc-transaction__detail">
                            <div>Nomor Transaksi</div>
                            <div style={{ overflow: "auto" }}>
                                {data.transaction_code}
                            </div>
                        </div>
                        <div className="desc-transaction__detail ">
                            <div>Tanggal Transaksi</div>
                            <div>{transactionDate}</div>
                        </div>
                    </div>
                    <div className="desc-transaction__total">
                        <div>Total Transaksi</div>
                        <div>{formatMoney(data?.total_cost)}</div>
                    </div>

                    <div className="mt-4">
                        <div className="text-right">
                            {data?.status === 14 && (
                                <React.Fragment>
                                    {data?.product?.category?.name !==
                                        "Voucher Digital" && (
                                        <Button
                                            className="btn-danger mr-3"
                                            onClick={() =>
                                                handleActionComplaint({
                                                    status: "rejected",
                                                })
                                            }
                                        >
                                            Tolak
                                        </Button>
                                    )}

                                    {data?.product?.category?.name ===
                                        "Voucher Digital" &&
                                    data?.complaint?.solution ===
                                        "Penukaran Barang" ? (
                                        <Button
                                            color="primary"
                                            onClick={openFormVoucher}
                                        >
                                            Kirim ulang voucher
                                        </Button>
                                    ) : (
                                        <Button
                                            color="primary"
                                            onClick={() =>
                                                handleActionComplaint({
                                                    status: "accepted",
                                                })
                                            }
                                        >
                                            Proses
                                        </Button>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    {data?.status >= 22 && complaintReceiptElement()}

                    {data?.status === 22 &&
                        data?.complaint?.solution !== "Pengembalian Dana" && (
                            <div className="text-right">
                                <Button
                                    color="primary"
                                    onClick={() => handleToggleReceiptModal()}
                                >
                                    Pesanan diterima
                                </Button>
                            </div>
                        )}
                </ModalBody>
            )}
            <Modal
                size={"xl"}
                isOpen={showImage}
                toggle={() => setShowImage(false)}
            >
                <img src={imageView} alt="" className="img-fluid" />
            </Modal>
            <ReturnForm
                data={data}
                toggle={handleToggleReceiptModal}
                isShow={showReceiptForm}
                setRefresh={setRefresh}
                toggleDetailModal={toggle}
            />
            <MessageState
                status={status}
                message={message}
                isShow={showMessage}
            />
        </Style>
    );
};
export default ComplaintModal;
