import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";
import Form from "../../../../Components/Molekul/FormValidation";
import Button from "../../../../Components/Particle/Button";
import { useStore } from "../../../../Reducers";
import { color } from "../../../../Utils/Variable";
import MessageState from "../../../../Components/Modal/Message";
import GrowingLoading from "../../../../Components/Loading/GrowingLoading";
import service from "../index.service";
const Style = styled(Modal)`
    .desc {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px dashed #888888;
        span {
            display: block;
            color: #888888;
            font-size: 14px;
        }
        &-transaction {
            font-size: 14px;
            margin-bottom: 10px;
            &__detail {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
            }
            &__total {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: bold;
                margin-bottom: 50px;
            }
        }
        &-refund {
            font-weight: bold;
            color: ${color.primary};
        }
    }
    .status {
        margin-bottom: 15px;
        .fas {
            margin-right: 10px;
            border-radius: 50%;
        }
        .success {
            box-shadow: 0 2px 4px #28a745;
        }
        span {
            font-size: 14px;
        }
    }
    .cancel-column {
        padding: 10px 0;
        border-top: 1px dashed #888888;
    }
`;

const ModalComplaint = ({
    isShow = false,
    toggle = () => {},
    setRefresh = () => {},
    toggleDetaiModal = () => {},
    data = false,
}) => {
    const { dispatch, state } = useStore();
    const [form, setForm] = React.useState([
        {
            name: "shipper",
            label: "Pilih Kurir",
            type: "select",
            action: "select",
            col: {md: 6},
            option: [
                {
                    label: "JNE",
                    value: "1",
                },
                {
                    label: "SiCepat",
                    value: "2",
                },
                {
                    label: "J&T",
                    value: "3",
                },
                {
                    label: "Wahana",
                    value: "4",
                },
                {
                    label: "Tiki",
                    value: "5",
                },
                {
                    label: "Anteraja",
                    value: "6",
                },
                {
                    label: "Ninja Xpress",
                    value: "7",
                },
                {
                    label: "RPX",
                    value: "8",
                },
                {
                    label: "Lion Parcel",
                    value: "9",
                },
                {
                    label: "SAP",
                    value: "10",
                },
            ],
            valid: "Pilih kurir",
            value: "",
            status: false,
            required: true,
        },
        {
            name: "receipt-number",
            label: "Nomor Resi",
            placeholder: "Masukkan nomor resi",
            type: "text",
            action: "text",
            required: true,
            col: 12,
            valid: "Mohon masukkan nomor resi",
            value: "",
            status: false,
        },
    ]);
    const [validationClick, setValidationClick] = React.useState(true);
    const [validateForm, setValidateForm] = React.useState(true);
    const [showMessage, setShowMessage] = React.useState(false);
    const [status, setStatus] = React.useState(true);
    const [message, setMessage] = React.useState("");
    const [isLoading, setLoading] = React.useState(false);
    const handleSubmitReceipt = (e) => {
        let isError = false;
        e.preventDefault();
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);

        if (!isError) {
            setLoading(true);
            setRefresh(false)
            let getTransaction = data?.transaction_code.split("/");
            let transaction_code = getTransaction[1];
            let dataPost = {
                logistic_name: form[0].value.label,
                serial_number: form[1].value,
            };
            service.complaintSendReceipt({
                dispatch,
                transaction_code,
                data: dataPost,
            });
        }
    };
    React.useEffect(() => {
        if (state?.supplier?.complaintReceiptSent?.isSuccess) {
            service.SentReceiptActionClear({ dispatch });
            setRefresh(true);
            setShowMessage(true);
            setLoading(false);
            setStatus(state.supplier?.complaintReceiptSent?.isSuccess);
            setMessage(state.supplier?.complaintReceiptSent?.message.text);
            setTimeout(() => {
                setShowMessage(false);
                toggleDetaiModal()
                toggle();
            }, 2000);
        }
        if (state?.supplier?.complaintReceiptSent?.isError) {
            service.SentReceiptActionClear({ dispatch });
            setRefresh(true);
            setLoading(false);
            setShowMessage(true);
            setStatus(state.supplier?.complaintReceiptSent?.isSuccess);
            setMessage(state.supplier?.complaintReceiptSent?.data.message);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
        }
    }, [state, dispatch, toggle, toggleDetaiModal, setRefresh]);
    return (
        <Style size="md" isOpen={isShow} toggle={toggle}>
            <GrowingLoading active={isLoading} />
            {data && (
                <React.Fragment>
                    <ModalHeader toggle={toggle}>
                      Konfirmasi Resi Pengembalian
                    </ModalHeader>
                    <ModalBody>
                        <div className="mb-2">
                            <Form
                                setForm={setForm}
                                validateForm={validateForm}
                                validationClick={validationClick}
                                form={form}
                            />
                        </div>
                        <div className="text-right">
                            <Button
                                type="button"
                                color="primary"
                                onClick={handleSubmitReceipt}
                            >
                                Simpan
                            </Button>
                        </div>
                    </ModalBody>
                </React.Fragment>
            )}
            <MessageState
                status={status}
                message={message}
                isShow={showMessage}
            />
        </Style>
    );
};
export default ModalComplaint;
