import { servicesAction } from "../../../Reducers";

const service = {
    searchProduct: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/product/customerAllNoSort",
            method: "GET",
            params: params,
            qs: true,
            reducer: "service",
            group: "product",
            key: "search",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
            },
        });
    },
    ratingProduct: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/product/customerAllByRating",
            method: "GET",
            params: params,
            qs: true,
            reducer: "service",
            group: "user",
            key: "ratingProduct",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
            },
        });
    },
    category: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/category/?filters=[["type","=","0"]]`,
            method: "GET",
            qs: true,
            params: params,
            reducer: "service",
            group: "category",
            key: "category",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    searchProductClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "product",
            key: "search",
            type: "CLEAR",
        });
    },
};
export default service;
