/* eslint-disable */
import React, { useEffect } from "react";
import moment from "moment";
import { Card, CardBody, Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import Tab from "./Tab";
import service from "./index.service";
import LocalStorage from "../../../Utils/LocalStorage";
import { useStore } from "../../../Reducers";
import General from "./content/General";
import Store from "./content/Store";
import Verification from "./content/Verification";
import Button from "../../../Components/Particle/Button";
import MessageState from "../../../Components/Modal/Message";
import GrowingLoading from "../../../Components/Loading/GrowingLoading";
import { useHistory } from "react-router";
import { useGeoLocation } from "../../../CustomHooks";
const Style = styled(Card)`
    border: unset;
    width: 1200px;
    margin: auto;
    hr {
        margin-top: 0;
        margin-bottom: 50px;
    }
    @media (max-width: 1200px) {
        width: 100%;
    }
`;

const EditProfile = () => {
    const token = LocalStorage().get("auth");
    const history = useHistory();
    const { dispatch, state } = useStore();
    const auth = LocalStorage().get(`${token.access_token}`);
    const [status, setStatus] = React.useState(true);
    const [message, setMessage] = React.useState("");
    const [showMessage, setShowMessage] = React.useState(false);
    const [isFirstGet, setFirstGet] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [isSuccess, setSuccess] = React.useState(false);
    const [isOpenCollapse, setOpenCollapse] = React.useState(true);
    //
    const [city, setCity] = React.useState([]);
    const [district, setDistrict] = React.useState([]);
    const [area, setArea] = React.useState([]);
    const [province, setProvince] = React.useState([]);
    const [valueProvince, setValueProvince] = React.useState(province[0]);
    const [valueCity, setValueCity] = React.useState(city[0]);
    const [valueArea, setValueArea] = React.useState(area[0]);
    const [postCode, setPostCode] = React.useState("");
    const [valueDistrict, setValueDistrict] = React.useState(district[0]);
    const [address, setAddress] = React.useState(auth.address);
    //
    const [addressStore, setAddressStore] = React.useState(
        auth.store.address === undefined ? "" : auth.store.address
    );
    const [avatar, setAvatar] = React.useState(auth.store.image_store);
    const [cityStore, setCityStore] = React.useState([]);
    const [districtStore, setDistrictStore] = React.useState([]);
    const [areaStore, setAreaStore] = React.useState([]);
    const [provinceStore, setProvinceStore] = React.useState([]);
    const [valueProvinceStore, setValueProvinceStore] = React.useState(
        provinceStore[0]
    );
    const [descStore, setDescStore] = React.useState(auth.description);
    const [valueCityStore, setValueCityStore] = React.useState(city[0]);
    const [valueAreaStore, setValueAreaStore] = React.useState(area[0]);
    const [postCodeStore, setPostCodeStore] = React.useState("");
    const [valueDistrictStore, setValueDistrictStore] = React.useState(
        district[0]
    );
    const [longitude, setLongitude] = React.useState("");
    const [latitude, setLatitude] = React.useState("");
    //
    const [validationClick, setValidationClick] = React.useState(true);
    const [validateForm, setValidateForm] = React.useState(true);
    const [descriptionStore, setDescriptionStore] = React.useState({
        name: "description",
        height: 183,
        required: true,
        label: "Deskripsi toko",
        valid: "Deskripsi toko tidak boleh kosong",
        value: descStore,
        status:
            descStore === null ||
            descStore.replace(/(<([^>]+)>)/gi, "").length < 1
                ? false
                : true,
    });
    const [addressGeneral, setAddressGeneral] = React.useState({
        type: "textarea",
        required: true,
        action: "textarea",
        label: "Alamat lengkap",
        rows: 5,
        character: {
            max: {
                length: 200,
                valid: "Miximal 200 karakter",
            },
            min: {
                length: 5,
                valid: "Minimal 5 karakter",
            },
        },
        name: "address",
        valid: "Alamat tidak boleh kosong",
        value: address,
        status: address === "" || address === null ? false : true,
    });
    const [formAddress, setFormAddress] = React.useState({
        type: "textarea",
        required: true,
        action: "textarea",
        label: "Alamat Lengkap",
        rows: 5,
        name: "address",
        valid: "Alamat tidak boleh kosong",
        character: {
            min: {
                length: 5,
                valid: "Minimal 5 karakter",
            },
            max: {
                length: 200,
                valid: "Miximal 200 karakter",
            },
        },
        value: addressStore,
        status: addressStore === "" || addressStore === null ? false : true,
    });
    const [formVerification, setFormVerification] = React.useState([
        {
            label: "Foto KTP",
            name: "identity_photo",
            required: true,
            action: "uploadImage",
            placeholder: "Klik Untuk Masukan Gambar",
            col: 4,
            valid: "Harap masukkan gambar",
            value: auth.identity_photo,
            status: auth.identity_photo !== null ? true : false,
            normalData: false,
            heightStyle: "150px",
        },
        {
            type: "number",
            required: true,
            action: "text",
            name: "ktp",
            label: "Nomor KTP",
            col: 8,
            character: {
                min: {
                    length: "0",
                    valid: "Nomor KTP tidak valid",
                },
                max: {
                    length: "16",
                    valid: "Nomor KTP tidak valid",
                },
            },
            valid: "Nomor KTP tidak boleh kosong",
            value: auth.nik,
            status: auth.nik !== null ? true : false,
        },
    ]);
    const [formStore, setFormStore] = React.useState([
        {
            type: "text",
            required: true,
            action: "text",
            name: "store_name",
            label: "Nama toko",
            col: 6,
            valid: "Nama toko tidak boleh kosong",
            value: auth.store.name,
            status: auth.store.name !== null ? true : false,
        },
        {
            type: "number",
            required: true,
            action: "phone",
            name: "phone",
            label: "Nomor telepon toko (WhatsApp)",
            col: 6,
            valid: "Nomor telepon toko tidak boleh kosong",
            value: auth.store.phone_cs || "",
            status: auth.store.phone_cs !== undefined ? true : false,
        },
        {
            label: "Hari operasional",
            required: true,
            type: "select",
            isMulti: true,
            action: "select",
            name: "operational",
            col: 4,
            option: [
                {
                    label: "Senin",
                    value: "Senin",
                },
                {
                    label: "Selasa",
                    value: "Selasa",
                },
                {
                    label: "Rabu",
                    value: "Rabu",
                },
                {
                    label: "Kamis",
                    value: "Kamis",
                },
                {
                    label: "Jumat",
                    value: "Jumat",
                },
                {
                    label: "Sabtu",
                    value: "Sabtu",
                },
                {
                    label: "Minggu",
                    value: "Minggu",
                },
            ],
            valid: "Hari operasional tidak boleh kosong",
            value:
                auth?.store.open_schedules?.length > 0
                    ? auth.store.open_schedules.map((item) => {
                          return { label: item, value: item };
                      })
                    : "",
            status: auth.store.open_schedules !== undefined ? true : false,
        },
        {
            type: "number",
            required: true,
            action: "time",
            name: "open",
            label: "Jam buka",
            col: 4,
            valid: "Jam buka tidak boleh kosong",
            value:
                auth.store.open_hour === undefined ? "" : auth.store.open_hour,
            status: auth.store.open_hour !== undefined ? true : false,
        },
        {
            type: "number",
            required: true,
            action: "time",
            name: "closed",
            label: "Jam tutup",
            col: 4,
            valid: "Jam tutup tidak boleh kosong",
            value:
                auth.store.close_hour === undefined
                    ? ""
                    : auth.store.close_hour,
            status: auth.store.close_hour !== undefined ? true : false,
        },
    ]);
    const [form, setForm] = React.useState([
        {
            type: "text",
            required: true,
            action: "text",
            name: "username",
            label: "Nama",
            col: 4,
            valid: "Nama tidak boleh kosong",
            value: auth.full_name,
            status: auth.full_name !== null ? true : false,
        },
        {
            type: "email",
            required: true,
            action: "email",
            name: "Email",
            label: "Email",
            col: 4,
            valid: "",
            disable: true,
            value: auth.email,
            status: true,
        },
        {
            type: "number",
            required: true,
            action: "phone",
            name: "phone",
            label: "Nomor telepon",
            col: 4,
            valid: "Nomor telepon tidak boleh kosong",
            value: auth.phone_number,
            status: auth.phone_number !== null ? true : false,
        },
        {
            label: "Jenis kelamin",
            required: true,
            type: "select",
            action: "select",
            name: "gender",
            col: 4,
            option: [
                {
                    label: "Laki-Laki",
                    value: "1",
                },
                {
                    label: "Perempuan",
                    value: "2",
                },
            ],
            valid: "Jenis kelamin tidak boleh kosong",
            value:
                auth.gender === "Laki-Laki"
                    ? {
                          label: "Laki-Laki",
                          value: "1",
                      }
                    : auth.gender === "Perempuan"
                    ? {
                          label: "Perempuan",
                          value: "2",
                      }
                    : "",
            status: auth.gender !== null ? true : false,
        },
        {
            label: "Pekerjaan",
            required: "optional",
            type: "text",
            action: "text",
            name: "jobdesc",
            col: 4,
            valid: "",
            value: auth.job,
            status: true,
        },
        {
            type: "date",
            required: true,
            action: "datepicker",
            name: "birthday",
            label: "Tanggal lahir",
            col: 4,
            maxDate: new Date(moment(new Date()).format("YYYY-MM-DD")),
            valid: "Tanggal lahir tidak boleh kosong",
            value:
                auth.dob !== null
                    ? moment(auth.dob).format("YYYY-MM-DD")
                    : moment(new Date()).format("YYYY-MM-DD"),
            status: true,
        },
    ]);
    const handleGetCity = (value) => {
        setValueProvince(value);
        setDistrict([]);
        setValueCity([]);
        setValueDistrict([]);
        setArea([]);
        setValueArea([]);
        setPostCode("");
        let params = value.value;
        service.getCity({ dispatch, params });
        service.getClearDistrict({ dispatch });
        service.getClearArea({ dispatch });
    };
    const handleGetDistrict = (value) => {
        service.getClearArea({ dispatch });
        setValueDistrict([]);
        setValueArea([]);
        setArea([]);
        setPostCode("");
        let params = value.value;
        setValueCity({ label: value.label, value: value.value });
        service.getDistrict({ dispatch, params });
    };
    const handleGetValueDistrict = (value) => {
        setValueArea([]);
        setPostCode("");
        setValueDistrict({ label: value.label, value: value.value });
        let params = value.value;
        service.getArea({ dispatch, params });
    };
    const handleGetValueArea = (value) => {
        setValueArea({ label: value.label, value: value.value });
        setPostCode(value.postcode);
    };
    // STORE
    const handleGetCityStore = (value) => {
        setValueProvinceStore(value);
        setDistrictStore([]);
        setValueCityStore([]);
        setValueDistrictStore([]);
        setValueAreaStore([]);
        setPostCodeStore("");
        let params = value.value;
        service.getCityStore({ dispatch, params });
        service.getClearDistrictStore({ dispatch });
        service.getClearAreaStore({ dispatch });
    };
    const handleGetDistrictStore = (value) => {
        setValueDistrictStore([]);
        setValueAreaStore([]);
        setAreaStore([]);
        setPostCodeStore("");
        let params = value.value;
        setValueCityStore({ label: value.label, value: value.value });
        service.getClearAreaStore({ dispatch });
        service.getDistrictStore({ dispatch, params });
    };
    const handleGetValueDistrictStore = (value) => {
        setValueAreaStore([]);
        setPostCodeStore("");
        setValueDistrictStore({ label: value.label, value: value.value });
        let params = value.value;
        service.getAreaStore({ dispatch, params });
    };
    const handleGetValueAreaStore = (value) => {
        setValueAreaStore({ label: value.label, value: value.value });
        setPostCodeStore(value.postcode);
    };
    const handlerSubmitData = (e) => {
        let isError = false;
        let isErrorInformation = false;
        let isErrorAddressStoreStatus = false;
        let isErrorAddressStatus = false;
        let isErrorVerification = false;
        let addressStoreStatus = [
            {
                value: valueProvinceStore,
                status:
                    valueProvinceStore?.label || valueProvinceStore?.length > 0
                        ? true
                        : false,
            },
            {
                value: valueCityStore,
                status:
                    valueCityStore?.label || valueCityStore?.length > 0
                        ? true
                        : false,
            },
            {
                value: valueDistrictStore,
                status:
                    valueDistrictStore?.label || valueDistrictStore?.length > 0
                        ? true
                        : false,
            },
            {
                value: valueAreaStore,
                status:
                    valueAreaStore?.label || valueAreaStore?.length > 0
                        ? true
                        : false,
            },
        ];
        let addressStatus = [
            {
                value: valueProvince,
                status:
                    valueProvince?.label || valueProvince?.length > 0
                        ? true
                        : false,
            },
            {
                value: valueCity,
                status:
                    valueCity?.label || valueCity?.length > 0 ? true : false,
            },
            {
                value: valueDistrict,
                status:
                    valueDistrict?.label || valueDistrict?.length > 0
                        ? true
                        : false,
            },
            {
                value: valueArea,
                status:
                    valueArea?.label || valueArea?.length > 0 ? true : false,
            },
        ];
        e.preventDefault();
        for (let key in formVerification) {
            if (!formVerification[key].status) {
                isErrorVerification = true;
            }
        }
        for (let key in addressStatus) {
            if (!addressStatus[key].status) {
                isErrorAddressStatus = true;
            }
        }
        for (let key in addressStoreStatus) {
            if (!addressStoreStatus[key].status) {
                isErrorAddressStoreStatus = true;
            }
        }
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        for (let key in formStore) {
            if (!formStore[key].status) {
                isErrorInformation = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (
            !isError &&
            !isErrorInformation &&
            !isErrorAddressStoreStatus &&
            !isErrorAddressStatus &&
            !isErrorVerification &&
            avatar !== undefined &&
            formAddress.status &&
            descriptionStore.status &&
            addressGeneral.status
        ) {
            setLoading(true);
            let data = {
                shipper_province_id:
                    valueProvinceStore.length > 0
                        ? valueProvinceStore[0].value
                        : valueProvinceStore?.value,
                shipper_province_name:
                    valueProvinceStore.length > 0
                        ? valueProvinceStore[0].label
                        : valueProvinceStore?.label,
                shipper_city_id:
                    valueCityStore.length > 0
                        ? valueCityStore[0].value
                        : valueCityStore?.value,
                shipper_city_name:
                    valueCityStore.length > 0
                        ? valueCityStore[0].label
                        : valueCityStore?.label,
                shipper_district_id:
                    valueDistrictStore.length > 0
                        ? valueDistrictStore[0].value
                        : valueDistrictStore?.value,
                shipper_district_name:
                    valueDistrictStore.length > 0
                        ? valueDistrictStore[0].label
                        : valueDistrictStore?.label,
                shipper_sub_district_id:
                    valueAreaStore.length > 0
                        ? valueAreaStore[0].value
                        : valueAreaStore?.value,
                shipper_sub_district_name:
                    valueAreaStore.length > 0
                        ? valueAreaStore[0].label
                        : valueAreaStore?.label,
                address: addressStore,
                address_direction: addressStore,
                post_code:
                    postCodeStore?.length > 0
                        ? postCodeStore[0]
                        : postCodeStore,
            };
            service.transactionAddress({ dispatch, data });
        }
    };
    React.useEffect(() => {
        if (isFirstGet) {
            service.getProvince({ dispatch });
            service.getProvinceStore({ dispatch });
            setFirstGet(false);
        }
    }, [isFirstGet, dispatch]);

    React.useEffect(() => {
        if (state?.supplier?.profile?.isSuccess) {
            LocalStorage().save(
                `${token.access_token}`,
                state.supplier.profile.data
            );
            if (auth.store.sub_district === undefined) {
                setMessage("Yey, Profile kamu sekarang sudah lengkap");
            } else {
                setMessage(state.supplier.profile.message.text);
            }

            setStatus(state?.supplier?.profile.isSuccess);

            setShowMessage(true);
            setTimeout(() => {
                if (auth.store.sub_district === undefined) {
                    history.push("/supplier/home");
                    setShowMessage(false);
                } else {
                    setShowMessage(false);
                }
            }, 3000);
            service.profileClear({ dispatch });
            setLoading(false);
        }
        if (state?.supplier?.profile?.isError) {
            setLoading(false);
            setStatus(state?.supplier?.profile.isSuccess);
            setMessage(state.supplier.profile.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
            service.profileClear({ dispatch });
        }
        if (state?.supplier?.transactionAddress?.isError) {
            setLoading(false);
            setStatus(state?.supplier?.transactionAddress.isSuccess);
            setMessage(state.supplier.transactionAddress.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
        }
        if (state?.supplier?.transactionAddress?.isSuccess) {
            let data = {
                // identity_photo: formVerification[0].value,
                phone_number: form[2].value,
                full_name: form[0].value,
                gender: form[3].value.label,
                address: address,
                identity_photo: formVerification[0].value,
                nik: formVerification[1].value,
                description: descStore,
                dob: form[5].value,
                email: form[1].value,
                province:
                    valueProvince.length > 0
                        ? valueProvince[0].label
                        : valueProvince?.label,
                city:
                    valueCity.length > 0
                        ? valueCity[0].label
                        : valueCity?.label,
                district:
                    valueDistrict.length > 0
                        ? valueDistrict[0].label
                        : valueDistrict?.label,
                sub_district:
                    valueArea.length > 0
                        ? valueArea[0].label
                        : valueArea?.label,
                job: form[4].value,
                postal_code: Array.isArray(postCode) ? postCode[0] : postCode,
                store: {
                    image_store: avatar,
                    name: formStore[0].value,
                    address: addressStore,
                    description: descStore,
                    open_schedules: formStore[2].value.map(
                        (item) => item.value
                    ),
                    longitude: String(state?.location?.coords?.longitude),
                    latitude: String(state?.location?.coords?.latitude),
                    open_hour:
                        auth.store.open_hour === undefined
                            ? `${formStore[3].value}:00`
                            : `${formStore[3].value}`,
                    close_hour:
                        auth.store.close_hour === undefined
                            ? `${formStore[4].value}:00`
                            : `${formStore[4].value}`,
                    phone_cs: formStore[1].value,
                    province:
                        valueProvinceStore.length > 0
                            ? valueProvinceStore[0].label
                            : valueProvinceStore?.label,
                    city:
                        valueCityStore.length > 0
                            ? valueCityStore[0].label
                            : valueCityStore?.label,
                    district:
                        valueDistrictStore.length > 0
                            ? valueDistrictStore[0].label
                            : valueDistrictStore?.label,
                    sub_district:
                        valueAreaStore.length > 0
                            ? valueAreaStore[0].label
                            : valueAreaStore?.label,
                    postal_code: Array.isArray(postCodeStore)
                        ? postCodeStore[0]
                        : postCodeStore,
                },
            };
            service.transactionAddressClear({ dispatch });
            service.updateProfile({ dispatch, data });
            // service.transactionAddressClear({ dispatch });
        }
        if (state?.user?.province?.isSuccess) {
            setProvince(
                state?.user.province.data.content.map((item) => {
                    return {
                        ...{ label: "Pilih", value: 0 },
                        ...{ label: item.name, value: item.id },
                    };
                })
            );
            service.getClearProvince({ dispatch });
            if (auth.province !== null) {
                setValueProvince(
                    state.user.province.data.content
                        .filter((item) => item.name === auth.province)
                        .map((item) => {
                            return { label: item.name, value: item.id };
                        })
                );
                let params = state.user.province.data.content
                    .filter((item) => item.name === auth.province)
                    .map((item) => {
                        return item.id;
                    });
                service.getCity({ dispatch, params });
            }
        }
        if (state?.user?.city?.isSuccess) {
            setDistrict([]);
            setCity(
                state.user.city.data.content.map((item) => {
                    return {
                        ...{ label: "Pilih", value: 0 },
                        ...{ label: item.name, value: item.id },
                    };
                })
            );
            service.getClearCity({ dispatch });

            if (auth.city !== null) {
                setValueCity(
                    state.user.city.data.content
                        .filter((item) => item.name === auth.city)
                        .map((item) => {
                            return { label: item.name, value: item.id };
                        })
                );
                let params = state.user.city.data.content
                    .filter((item) => item.name === auth.city)
                    .map((item) => {
                        return item.id;
                    });
                service.getDistrict({ dispatch, params });
            }
        }
        if (state?.user?.district?.isSuccess) {
            setDistrict(
                state.user.district.data.content.map((item) => {
                    return {
                        ...{ label: "Pilih", value: 0 },
                        ...{ label: item.name, value: item.id },
                    };
                })
            );

            service.getClearDistrict({ dispatch });

            if (auth.district !== null) {
                setValueDistrict(
                    state.user.district.data.content
                        .filter((item) => item.name === auth.district)
                        .map((item) => {
                            return { label: item.name, value: item.id };
                        })
                );
                let params = state.user.district.data.content
                    .filter((item) => item.name === auth.district)
                    .map((item) => {
                        return item.id;
                    });
                service.getArea({ dispatch, params });
            }
        }
        if (state?.user?.area?.isSuccess) {
            setArea(
                state.user.area.data.content.map((item) => {
                    return {
                        ...{ label: "Pilih", value: 0 },
                        ...{
                            label: item.name,
                            value: item.id,
                            postcode: item.postal_code,
                        },
                    };
                })
            );
            if (auth.sub_district !== null) {
                setValueArea(
                    state.user.area.data.content
                        .filter((item) => item.name === auth.sub_district)
                        .map((item) => {
                            return { label: item.name, value: item.id };
                        })
                );
                setPostCode(
                    state.user.area.data.content
                        .filter((item) => item.name === auth.sub_district)
                        .map((item) => {
                            return item.postal_code;
                        })
                );
            }

            service.getClearArea({ dispatch });
            // if (auth.store.area !== null) {
            //     let params = state.user.district.data.data.rows
            //         .filter((item) => item.name === auth.area)
            //         .map((item) => {
            //             return item.id;
            //         });
            //     // service.getArea({ dispatch, params });
            // }
        }
        // STORE ADDRESS
        if (state?.user?.provinceStore?.isSuccess) {
            setProvinceStore(
                state.user.provinceStore.data.content.map((item) => {
                    return {
                        ...{ label: item.name, value: item.id },
                    };
                })
            );

            if (auth.store.province !== null) {
                setValueProvinceStore(
                    state.user.provinceStore.data.content
                        .filter((item) => item.name === auth.store.province)
                        .map((item) => {
                            return { label: item.name, value: item.id };
                        })
                );
                let params = state.user.provinceStore.data.content
                    .filter((item) => item.name === auth.store.province)
                    .map((item) => {
                        return item.id;
                    });
                service.getCityStore({ dispatch, params });
            }

            service.getClearProvinceStore({ dispatch });
        }
        if (state?.user?.cityStore?.isSuccess) {
            setDistrictStore([]);
            setCityStore(
                state.user.cityStore.data.content.map((item) => {
                    return {
                        ...{ label: "Pilih", value: 0 },
                        ...{ label: item.name, value: item.id },
                    };
                })
            );
            service.getClearCityStore({ dispatch });

            if (auth.store.city !== null) {
                setValueCityStore(
                    state.user.cityStore.data.content
                        .filter((item) => item.name === auth.store.city)
                        .map((item) => {
                            return { label: item.name, value: item.id };
                        })
                );
                let params = state.user.cityStore.data.content
                    .filter((item) => item.name === auth.store.city)
                    .map((item) => {
                        return item.id;
                    });
                service.getDistrictStore({ dispatch, params });
            }
        }
        if (state?.user?.districtStore?.isSuccess) {
            setDistrictStore(
                state.user.districtStore.data.content.map((item) => {
                    return {
                        ...{ label: "Pilih", value: 0 },
                        ...{ label: item.name, value: item.id },
                    };
                })
            );

            service.getClearDistrictStore({ dispatch });
            if (auth.store.district !== null) {
                setValueDistrictStore(
                    state.user.districtStore.data.content
                        .filter((item) => item.name === auth.store.district)
                        .map((item) => {
                            return { label: item.name, value: item.id };
                        })
                );
                let params = state.user.districtStore.data.content
                    .filter((item) => item.name === auth.store.district)
                    .map((item) => {
                        return item.id;
                    });
                service.getAreaStore({ dispatch, params });
            }
        }
        if (state?.user?.areaStore?.isSuccess) {
            setAreaStore(
                state.user.areaStore.data.content.map((item) => {
                    return {
                        ...{ label: "Pilih", value: 0 },
                        ...{
                            label: item.name,
                            value: item.id,
                            postcode: item.postal_code,
                        },
                    };
                })
            );
            if (auth.store.sub_district !== null) {
                setValueAreaStore(
                    state.user.areaStore.data.content
                        .filter((item) => item.name === auth.store.sub_district)
                        .map((item) => {
                            return { label: item.name, value: item.id };
                        })
                );
                setPostCodeStore(
                    state.user.areaStore.data.content
                        .filter((item) => item.name === auth.store.sub_district)
                        .map((item) => {
                            return item.postal_code;
                        })
                );
                setPostCodeStore(
                    state.user.areaStore.data.content
                        .filter((item) => item.name === auth.store.sub_district)
                        .map((item) => {
                            return item.postal_code;
                        })
                );
            }
            service.getClearAreaStore({ dispatch });
        }
    }, [state]);
    useGeoLocation(
        addressStore,
        valueProvinceStore,
        valueCityStore,
        valueDistrictStore,
        valueAreaStore,
        postCodeStore
    );
    return (
        <Style>
            <GrowingLoading active={loading} />
            <CardBody>
                <div
                    className="d-flex justify-content-between align-items-baseline"
                    style={{ overflow: "auto" }}
                >
                    <Tab />
                </div>
                <hr />
                <div>
                    <General
                        setAddressGeneral={setAddressGeneral}
                        addressGeneral={addressGeneral}
                        isOpenCollapse={isOpenCollapse}
                        address={address}
                        setAddress={setAddress}
                        city={city}
                        district={district}
                        area={area}
                        province={province}
                        handleGetCity={handleGetCity}
                        handleGetDistrict={handleGetDistrict}
                        handleGetValueDistrict={handleGetValueDistrict}
                        handleGetValueArea={handleGetValueArea}
                        valueCity={valueCity}
                        valueDistrict={valueDistrict}
                        valueArea={valueArea}
                        valueProvince={valueProvince}
                        postCode={postCode}
                        setForm={setForm}
                        validateForm={validateForm}
                        validationClick={validationClick}
                        form={form}
                    />
                    <Store
                        descriptionStore={descriptionStore}
                        setDescriptionStore={setDescriptionStore}
                        formAddress={formAddress}
                        setFormAddress={setFormAddress}
                        descStore={descStore}
                        setAddress={setAddressStore}
                        address={addressStore}
                        isOpenCollapse={isOpenCollapse}
                        avatar={avatar}
                        setAvatar={setAvatar}
                        setDescStore={setDescStore}
                        city={cityStore}
                        district={districtStore}
                        area={areaStore}
                        province={provinceStore}
                        handleGetCity={handleGetCityStore}
                        handleGetDistrict={handleGetDistrictStore}
                        handleGetValueDistrict={handleGetValueDistrictStore}
                        handleGetValueArea={handleGetValueAreaStore}
                        valueCity={valueCityStore}
                        valueDistrict={valueDistrictStore}
                        valueArea={valueAreaStore}
                        valueProvince={valueProvinceStore}
                        postCode={postCodeStore}
                        setForm={setFormStore}
                        validateForm={validateForm}
                        validationClick={validationClick}
                        form={formStore}
                    />
                    {auth.update_data === false && (
                        <Verification
                            isOpenCollapse={isOpenCollapse}
                            validateForm={validateForm}
                            validationClick={validationClick}
                            formVerification={formVerification}
                            setFormVerification={setFormVerification}
                        />
                    )}
                </div>

                <div className="text-right" style={{ marginTop: 20 }}>
                    <Button
                        type="button"
                        color="primary"
                        onClick={handlerSubmitData}
                    >
                        {auth.verify_id === false ? "Kirim" : "Simpan"}
                    </Button>
                </div>
            </CardBody>
            <Modal isOpen={isSuccess}>
                <ModalBody>
                    <div className="text-center">
                        <i
                            className="fas fa-check-circle text-success mb-4"
                            style={{ fontSize: 40 }}
                        ></i>
                        <div>Profile anda terupdate!</div>
                    </div>
                </ModalBody>
            </Modal>
            <MessageState
                status={status}
                message={message}
                isShow={showMessage}
            />
        </Style>
    );
};

export default EditProfile;
