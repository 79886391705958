import React from "react";
import Image from "../../../Components/Assets/Images/NOT_FOUND.jpg";

const NotFound = () => {
    return (
        <div className="d-flex justify-content-center">
            <img src={Image} alt="404" className="img-fluid" />
        </div>
    );
};
export default NotFound;
