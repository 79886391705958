import React from "react";
import Style from "../index.style";
import Frame1 from "../../../../Components/Assets/v2/frame4.png";
import Frame2 from "../../../../Components/Assets/v2/frame5.png";
import Frame3 from "../../../../Components/Assets/v2/frame6.png";

const SectionStep = () => {
  
  return (
    <Style>
        <div className="container">
            <div className="row">
                <div className="col-sm-7 col-title">
                    <ul>
                        <li>Caranya gini doang !!!</li>
                    </ul>
                    <p className="subtitle-primary">cuma dengan 3 langkah mudah ini, cuan bisa mengalir ke rekenignmu.</p>
                </div>
            </div>
            <div className="row pt-5">
                <div className="col-sm-4">
                    <div className="column-frame">
                        <img src={Frame1} alt="" />
                    </div>
                    <div className="column-frame-text">
                        <p className="frame-subtitle">Pilih produk jualan yang sesuai dengan passion dan market yang kamu punya</p>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="column-frame">
                        <img src={Frame2} alt="" />
                    </div>
                    <div className="column-frame-text">
                        <p className="frame-subtitle">Masukkan produk ke storage katalogmu</p>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="column-frame">
                        <img src={Frame3} alt="" />
                    </div>
                    <div className="column-frame-text">
                        <p className="frame-subtitle">Share link produknya ke calon pelangganmu, atau kamu bisa taruh link produk di linktree, bio ataupun story social media</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 col-step">
                    <p className="text-step">Transaksi berhasil, komisi langsung masuk ke akun kamu....</p>
                    <p className="text-step">Komisi nya lumayan lo, mulai dari 10%</p>
                </div>
            </div>
        </div>
    </Style>
  );
};
export default SectionStep;
