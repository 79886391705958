import React from "react";
import service from "../index.service";
import CommonTable from "../../../../Components/Molekul/CommonTable";
import DetailTracking from "../Modal/DetailTracking";
import { formatMoney } from "../../../../Utils/PriceConverter";
import { useStore } from "../../../../Reducers";
import TrackingOrder from "../Modal/Tracking";
import LocalStorage from "../../../../Utils/LocalStorage";
import styled from "styled-components";
const Style = styled.div`
    .table-absolute {
        position: absolute;
        width: 84px;
        right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 575px) {
            padding-top: 23px !important;
            padding-bottom: 23px !important;
            width: 55px;
        }
    }
    tbody {
        .table-absolute {
            padding: 14px !important;
            @media (max-width: 575px) {
                padding: 10px !important;
            }
        }
    }
`;

const SendItem = () => {
    const { dispatch, state } = useStore();
    const [showTracking, setShowTracking] = React.useState(false);
    const [data, setData] = React.useState(false);
    const [getTracking, setTracking] = React.useState(false);
    const getToken = LocalStorage().get("auth");
    const getId = LocalStorage().get(`${getToken.access_token}`);
    // const [getOrderShipper, setOrderShipper] = React.useState(false);
    const [isRefresh, setRefresh] = React.useState(true);
    const [isShow, setShow] = React.useState(false);
    const [isFirstGetCommission, setIsFirstGetCommision] = React.useState(true);
    const handleShowTracking = (data) => {
        let id = data.biteship_order_id;
        setShow(false);
        service.orderDetail({ dispatch, id });
        setShowTracking(!showTracking);
    };
    const handlerViewDetail = (item) => {
        let id = item.biteship_order_id;
        let transactionCode = item.transaction_code.split("/");
        if (item.shipper) {
            service.transactionDetail({
                dispatch,
                transaction_id: transactionCode[1],
                id: getId.id,
            });
            service.orderDetail({ dispatch, id });
            handleToggleModal();
        } else {
            service.transactionDetail({
                dispatch,
                transaction_id: transactionCode[1],
                id: getId.id,
            });
            handleToggleModal();
        }
    };
    const handleToggleModal = () => {
        setShow(!isShow);
        if (isShow) {
            setData(false);
            // setOrderShipper(false);
            setTracking(false);
        }
    };

    React.useState(() => {
        if (isFirstGetCommission) {
            setIsFirstGetCommision(false);
        }
    }, [isFirstGetCommission]);
    React.useEffect(() => {
        if (state?.supplier?.transactionDetail?.isSuccess) {
            setData(state?.supplier?.transactionDetail.data);
            service.transactionDetailClear({ dispatch });
        }
        if (state?.supplier?.transactionList?.isSuccess) {
            service.transactionListClear({ dispatch });
        }
        if (state?.shipper?.orderId?.isSuccess) {
            setTracking(state.shipper.orderId.data);
            service.orderDetailClear({ dispatch });
        }
    }, [state, dispatch]);
    return (
        <Style>
            <CommonTable
                setRefresh={setRefresh}
                isRefresh={isRefresh}
                searchBy={"customer_detail.full_name"}
                configuration={{
                    searchInput: true,
                    searchValue: ["customer_detail.full_name", "product.name"],
                    nav: {
                        hasSearch: true,
                        filter: [
                            {
                                icon: "fas fa-sort-amount-down",
                                type: "dropdown",
                                value: [
                                    {
                                        value: "full_name",
                                        label: "Email",
                                        key: "customer_detail.full_name",
                                    },
                                ],
                            },
                        ],
                    },
                    service: {
                        api: service.transactionList,
                        id: getId.id,
                        group: "reseller",
                        key: "transactionList",
                        filters: ``,
                        filterData: `["status","=","4"]`,
                        // filters: ``,
                    },
                    optionTable: [
                        {
                            headName: "No",
                            type: "number",
                        },
                        {
                            headName: "Nama Pemesan",
                            type: "isCostume",
                            fieldName: "customer_detail.full_name",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",
                            renderItem: (item) => {
                                return (
                                    <React.Fragment>
                                        <span
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {item.customer_detail.full_name}
                                        </span>
                                    </React.Fragment>
                                );
                            },
                        },
                        {
                            headName: "Nomor Telepon",
                            type: "isCostume",
                            fieldName: "customer_detail.phone_number",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",
                            renderItem: (item) => {
                                return (
                                    <React.Fragment>
                                        <span
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {item.customer_detail.phone_number}
                                        </span>
                                    </React.Fragment>
                                );
                            },
                        },
                        {
                            headName: "Tanggal Pemesanan",
                            type: "isCostume",
                            fieldName: "created",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",
                            renderItem: (item) => {
                                let transactionDate = "";
                                let getTime = item.transaction_code.split("/");
                                let timestamp = getTime[1];
                                let getFullDate = new Date(Number(timestamp));

                                transactionDate = `${getFullDate.getFullYear()}-${String(
                                    getFullDate.getMonth() + 1
                                ).padStart(2, "0")}-${String(
                                    getFullDate.getDate()
                                ).padStart(2, "0")}`;
                                return (
                                    <React.Fragment>
                                        <span
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {transactionDate}
                                        </span>
                                    </React.Fragment>
                                );
                            },
                        },
                        {
                            headName: "Nama Produk",
                            type: "isCostume",
                            fieldName: "product.name",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",
                            renderItem: (item) => {
                                return (
                                    <React.Fragment>
                                        <span
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {item.product.name}
                                        </span>
                                    </React.Fragment>
                                );
                            },
                        },
                        {
                            headName: "Jumlah",
                            type: "text",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",
                            fieldName: "total_amount",
                        },
                        {
                            headName: "Total Pembayaran",
                            type: "isCostume",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",
                            renderItem: (item) => {
                                return (
                                    <React.Fragment>
                                        <span
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {formatMoney(item.total_cost)}
                                        </span>
                                    </React.Fragment>
                                );
                            },
                        },
                        {
                            headName: "Status",
                            type: "isCostume",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",
                            style: {
                                paddingRight: 84,
                            },
                            renderItem: (item) => {
                                return (
                                    <React.Fragment>
                                        {item.product.category.name ===
                                        "Voucher Digital" ? (
                                            "Sedang di proses"
                                        ) : (
                                            <span
                                                className={`${
                                                    item.shipper
                                                        .status_by_shipper ===
                                                        null ||
                                                    item.shipper
                                                        .status_by_shipper ===
                                                        undefined ||
                                                    !item.shipper
                                                        .status_by_shipper
                                                        ? ""
                                                        : "text-success"
                                                }`}
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {item.shipper
                                                    .status_by_shipper ===
                                                    null ||
                                                item.shipper
                                                    .status_by_shipper ===
                                                    undefined ||
                                                !item.shipper.status_by_shipper
                                                    ? "Menunggu pickup"
                                                    : "Dikirim"}
                                            </span>
                                        )}
                                    </React.Fragment>
                                );
                            },
                        },
                        {
                            headName: "Aksi",
                            type: "actions",
                            className: "table-absolute",
                            options: [
                                {
                                    actionType: "isViewModal",
                                    function: (item) => handlerViewDetail(item),
                                    renderItem: () => {},
                                    field: "id",
                                },
                            ],
                        },
                    ],
                }}
            ></CommonTable>
            <DetailTracking
                action={{ detail: "tracking" }}
                setRefresh={setRefresh}
                handleShowTracking={handleShowTracking}
                // setOrderShipper={setOrderShipper}
                getTracking={getTracking}
                cancel={true}
                isShow={isShow}
                setTracking={setTracking}
                toggle={handleToggleModal}
                data={data}
                process={true}
            />
            {getTracking && (
                <TrackingOrder
                    getTracking={getTracking}
                    data={data}
                    isShow={showTracking}
                    toggle={() => setShowTracking(!showTracking)}
                />
            )}
        </Style>
    );
};
export default SendItem;
