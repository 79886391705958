import React from "react";
import { Link } from "react-router-dom";
import Style from "../index.style";
import Hero2 from "../../../../Components/Assets/v2/hero-2.png";

import NavBar from "../../NavBar";

const SectionStart = () => {

    return (
        <Style>
            <div className="header">
                <NavBar />
                <div className="wrapper-header">
                    <div className="row">
                        <div className="col-sm-6 order-first">
                            <img src={Hero2} alt="hero" width="500px" />
                        </div>
                        <div className="col-sm-6 order-second">
                            <div className="wrapper-header-column">
                                <h3 className="header3-primary">Siap jualan</h3>
                                <h3 className="header3-primary">bareng <strong>Lakugan?</strong></h3>
                                <Link
                                    to={`/signup/reseller`}
                                    className="btn btn-warning btn-register"
                                >
                                    Mulai Jualan
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Style>
    );
};
export default SectionStart;
