import React from "react";
import { Col, Container, Jumbotron, Row } from "reactstrap";
import Image from "../../../Components/Assets/Images/FAQ-banner.png";
import styled from "styled-components";
import TabMenu from "../../../Components/TabMenu";
import TabLakugan from "./TabLakugan";
import TermAndConditions from "./TermsAndConditions";
import service from "./index.service";
import { useStore } from "../../../Reducers";
import { color } from "../../../Utils/Variable";

const Style = styled.div`
    .jumbotron {
        background-color: #1047f2;
        border-radius: 0;
    }
    .title {
        font-size: 40px;
        font-weight: bold;
        color: ${color.user.yellow};
        line-height: 1.5;
        margin-bottom: 30px;
        @media (max-width: 767px) {
            font-size: 30px;
        }
        @media (max-width: 575px) {
            font-size: 18px;
        }
    }
    .btn {
        &-search {
            position: absolute;
            right: 0;
            top: 0;
            padding: 7px 15px;
            background: transparent;
            border: none;
            color: #4a4a4a;
        }
    }
`;
const FAQ = () => {
    const { dispatch, state } = useStore();
    const [isFirstGet, setFirstGet] = React.useState(true);
    const [data, setData] = React.useState(false);

    React.useEffect(() => {
        if (isFirstGet) {
            setFirstGet(false);
            service.FAQ({ dispatch });
        }
    }, [isFirstGet, dispatch]);
    React.useEffect(() => {
        if (state?.user?.faqList?.isSuccess) {
            setData(state.user.faqList.data.content);
        }
    }, [state]);
    return (
        <Style>
            <Jumbotron>
                <Container fluid="md">
                    <Row className="justify-content-between align-items-center">
                        <Col xl="7" lg="7" md="6" sm="6" xs="6">
                            <h1 className="title">
                                Dapatkan uang tambahan, berawal dari share link
                            </h1>
                        </Col>
                        <Col xl="5" lg="5" md="6" sm="6" xs="6">
                            <img
                                src={Image}
                                alt="Lakugan Banner"
                                className="img-fluid"
                            />
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <Container fluid="md">
                {data && (
                    <TabMenu
                        color={{
                            text: "#aaa",
                            background: "#fff",
                            activeColor: "#000",
                            activeBackground: "#000",
                        }}
                        data={[
                            {
                                title: "Seputar Reseller",
                                key: 1,
                                render: () => {
                                    return data
                                        .filter(
                                            (item) =>
                                                item.category === "Reseller"
                                        )
                                        .map((item, key) => {
                                            return (
                                                <TabLakugan
                                                    item={item}
                                                    key={key}
                                                    id={key}
                                                />
                                            );
                                        });
                                },
                            },
                            {
                                title: "Seputar Supplier",
                                key: 2,
                                render: () => {
                                    return data
                                        .filter(
                                            (item) =>
                                                item.category === "Supplier"
                                        )
                                        .map((item, key) => {
                                            return (
                                                <TabLakugan
                                                    item={item}
                                                    key={key}
                                                    id={key}
                                                />
                                            );
                                        });
                                },
                            },
                            {
                                title: "Syarat dan ketentuan",
                                key: 3,
                                render: () => {
                                    return <TermAndConditions />;
                                },
                            },
                        ]}
                    />
                )}
            </Container>
        </Style>
    );
};
export default FAQ;
