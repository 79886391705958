/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  Alert,
} from "reactstrap";
import styled from "styled-components";
import { navMenu } from "./NavMenu";
import { formatMoney } from "../../Utils/PriceConverter";
import Logo from "../Assets/Images/Lakugan_LogoColor_DarkBG.png";
import { color } from "../../Utils/Variable";
import Facebook from "../Assets/icon/facebook.png";
import Youtube from "../Assets/icon/youtube.png";
import Instagram from "../Assets/icon/instagram.png";
import Twitter from "../Assets/icon/twitter.png";
import ShareButton from "../Assets/icon/share.png";
import Button from "../Particle/Button";
import Plusbtn from "../Assets/icon/circle-plus.png";
import InputOrder from "../Particle/InputOrder";
import closeIcon from "../../Components/Assets/icon/icon-close.png";
import LocalStorage from "../../Utils/LocalStorage";
import JSON_to_URLEncoded from "../../Utils/JSON-URLEncode";
import service from "../../Pages/Auth/Product/index.service";
import { useStore } from "../../Reducers";
import CustomForm from "../../Components/Molekul/FormValidation";
import { Style, ModalForm, CustomStyles } from "./index.style";
import CheckMobile from "../../Utils/CheckMobile";
import CustomModal from "react-modal";

const Footer = ({
  footerTop = true,
  item,
  isPriceFooterShow,
  totalItem,
  dataProduct = {},
  voucherList = [],
  nextPayment = () => {},
  setTotalItem = () => {},
  voucherName,
  setVoucherName = () => {},
}) => {
  const token = LocalStorage().get("auth");
  const auth = LocalStorage().get(`${token?.access_token}`);
  const [isFirstGet, setFirstGet] = useState(true);
  const [isFailed, setFailed] = useState(false);
  const [validationClick, setValidationClick] = useState(true);
  const [validateForm, setValidateForm] = useState(true);
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const { dispatch, state } = useStore();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [isOpenVoucher, setOpenVoucher] = useState(false);
  const [isReseller, setIsReseller] = useState(true);

  const [form, setForm] = useState([
    {
      type: "email",
      placeholder: "Email",
      action: "email",
      required: true,
      name: "email",
      col: 11,
      valid: "Email tidak boleh kosong",
      status: false,
      className: "custom-form",
    },
    {
      type: "password",
      placeholder: "Password",
      action: "password",
      required: true,
      name: "password",
      col: 11,
      valid: "Kata sandi tidak boleh kosong",
      value: "",
      status: false,
      className: "custom-form",
    },
  ]);
  const handleShowShare = () => {
    setShow(!show);
  };
  const handleGetVoucher = (item) => {
    let voucherUsed = {
      discount_percentages: item.discount_percentages,
      id: item.id,
      limit_voucher: item.limit_voucher,
      max_discount: item.max_discount,
      name: item.name,
      publish_until: item.publish_until,
      published: item.published,
      status: true,
    };
    LocalStorage().remove("VOUCHER_USED", voucherUsed);
    LocalStorage().save("VOUCHER_USED", voucherUsed);
    setVoucherName(item.name);
  };
  const onGetValue = (value) => {
    setTotalItem(value);
  };

  const handlerSubmitData = (e) => {
    setFailed(false);
    setMessage(false);
    let isError = false;
    e.preventDefault();
    for (let key in form) {
      if (!form[key].status) {
        isError = true;
      }
    }
    setValidationClick(isError);
    setValidateForm(false);
    if (!isError) {
      setLoading(true);
      let formData = {
        username: form[0].value,
        password: form[1].value,
        grant_type: "password",
        client_id: "my-client-id",
        client_secret: "password",
      };
      let data = JSON_to_URLEncoded(formData);
      service.token({ dispatch, data });
      setFailed(false);
      setTimeout(() => {
        setLoading(false);
        setFailed(true);
      }, 60000);
    }
  };
  useEffect(() => {
    if (state?.user?.token?.isSuccess) {
      LocalStorage().save("auth", state.user.token.data);
      service.profile({ dispatch });
      setFailed(false);
    }
    if (state?.user?.profile?.isSuccess) {
      const token = LocalStorage().get("auth");
      LocalStorage().save(`${token.access_token}`, state.user.profile.data);
      setFailed(false);
      setLoading(false);
      history.push("/reseller/home");
    }
    if (state?.user?.token?.isError) {
      setLoading(false);
      setFailed(false);
      setMessage(state.user.token.data.message);
    }
    if (state?.user?.profile?.isError) {
      setLoading(false);
      setFailed(false);
      setMessage(state.user.profile.data.message);
    }
  }, [state.user.token]);
  useEffect(() => {
    if (state?.user?.profile?.isSuccess) {
      const token = LocalStorage().get("auth");

      LocalStorage().save(`${token.access_token}`, state.user.profile.data);
      // SETUP EXPIRED LOCAL STORAGE (TERHAPUS OTOMATIS DALAM WAKTU 5 JAM)
      const ex_date = new Date();
      LocalStorage().save("EX", `${new Date(ex_date.getTime() + 5 * 60 * 60 * 1000)}`);
      setLoading(false);

      if (state.user.profile.data.user.authorities[0].name === "ROLE_RESELLER") {
        history.push("/reseller/home");
      }
    }
  }, [state.user.profile]);
  React.useEffect(() => {
    if (auth !== null && isFirstGet) {
      if (auth.user.authorities[0].name === "ROLE_RESELLER") {
        setIsReseller(false);
      } else {
        false;
      }
      setFirstGet(false);
    }
  }, [auth]);
  return (
    <Style id="footer">
      {footerTop && (
        <div className="footer-top">
          <div className="container">
            <div className="footer-top__border">
              <Row className="justify-content-between">
                <Col xl="4" lg="3" md="6" sm="6">
                  <div>
                    <img
                      src={Logo}
                      alt="Lakugan"
                      className="img-fluid img-lakugan"
                      style={{ width: "212px" }}
                    />
                    <div>
                      <div style={{ maxWidth: "300px" }}>
                      Jl. Aria Putra no.88, RT.09/RW.01, Sawah Baru, Kec.Ciputat, Kota Tangerang Selatan, Banten 
                      </div>
                      <br />
                      <div>halo@lakugan.com</div>
                      <br />
                      <span>0821-8000-5188</span>
                    </div>
                  </div>
                </Col>
                {navMenu.map((item, key) => {
                  return (
                    <Col xl="3" lg="3" md="6" sm="6" xs={key === 0 ? "12" : "4"} key={key}>
                      <div className="lakugan-contact">
                        <h6>{item.title}</h6>
                        {item.menuList.map((value, key) => {
                          return (
                            <div className="nav-footer" key={key}>
                              {value.link !== undefined ? (
                                <Link to={value.link}>
                                  <span>{value.list}</span>
                                </Link>
                              ) : (
                                <a href={value.email}>
                                  <span>{value.list}</span>
                                </a>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </Col>
                  );
                })}
                <div className="share-icon">
                  <h4>Media Sosial</h4>
                  <a
                    href="https://www.facebook.com/Lakugan.id/"
                    className="social-media__link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{
                        width: 32,
                        marginRight: 10,
                      }}
                      src={Facebook}
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/lakugan_id/"
                    className="social-media__link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{
                        width: 32,
                        marginRight: 10,
                      }}
                      src={Instagram}
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/channel/UChz841F6gxoHYHtyTg-Zi_w"
                    className="social-media__link"
                  >
                    <img
                      style={{
                        width: 32,
                        marginRight: 10,
                      }}
                      src={Youtube}
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/lakugan_id"
                    className="social-media__link"
                  >
                    <img
                      style={{
                        width: 32,
                        marginRight: 10,
                      }}
                      src={Twitter}
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                </div>
              </Row>
            </div>
            <div className="footer-bottom">
              <span style={{ fontWeight: "bold" }}>Syarat Penggunaan</span>
              <span style={{ fontWeight: "bold" }}>Kebijakan Privasi</span>
            </div>
            <span className="copyright">
              &copy; {new Date().getFullYear()} Lakugan, Inc. All rights reserved
            </span>
          </div>
        </div>
      )}
      {CheckMobile() === true
        ? /* footer price for mobile view */
          isPriceFooterShow && (
            <div className="footer-price-mobile">
              <div className="container d-flex align-items-center">
                <p className="total">Jumlah</p>
                <InputOrder
                  max={dataProduct?.product?.total_stock_product}
                  onGetValue={onGetValue}
                />
              </div>
              <div className="d-flex flex-row justify-content-between pt-2">
                <div className="d-flex flex-column">
                  <span className="total-item">Total Harga</span>
                  <h3>{formatMoney(dataProduct?.product?.price * totalItem)}</h3>
                </div>
                <Button color="primary" onClick={nextPayment} className="buy-now">
                  Beli Sekarang
                </Button>
              </div>
            </div>
          )
        : /* footer price for desktop view */
          isPriceFooterShow && (
            <div className="footer-price-desktop">
              <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex  align-items-center">
                    <div className="mr-4">
                      <h4 className="font-light">Total Harga</h4>
                      <h3 className="font-bold">
                        {formatMoney(dataProduct?.product?.price * totalItem)}
                      </h3>
                    </div>
                    <div style={{ display: "flex" }}>
                      <InputOrder
                        // setVoucher={setVoucher}
                        // handleReOrder={handleReOrder}
                        // defaultValue={1}
                        max={dataProduct?.product?.total_stock_product}
                        onGetValue={onGetValue}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    {voucherList.length > 0 && (
                      <div className="mobile-hide">
                        <div>
                          <Dropdown
                            isOpen={isOpenVoucher}
                            toggle={() => setOpenVoucher(!isOpenVoucher)}
                          >
                            <DropdownToggle
                              style={{
                                border: "1px solid #E8E8E8",
                                borderRadius: 8,
                                padding: 12,
                                display: "flex",
                                justifyContent: "space-between",
                                background: "#fff",
                                color: "#000000",
                                minWidth: 220,
                                minHeight: 60,
                              }}
                            >
                              <p
                                style={{
                                  margin: "auto 0",
                                }}
                              >
                                {voucherName}
                              </p>
                              <img
                                src={Plusbtn}
                                alt=""
                                style={{
                                  height: 20,
                                  width: 20,
                                  margin: "auto 0",
                                }}
                              />
                            </DropdownToggle>
                            <DropdownMenu
                              direction="up"
                              id="background_dropdwn"
                              className="dropdown-voucher"
                            >
                              {/* <DropdownItem header>Header</DropdownItem> */}
                              {voucherList.map((item, key) => {
                                return (
                                  <DropdownItem onClick={() => handleGetVoucher(item)} key={key}>
                                    <div className="voucher-list">
                                      <p>{item.name}</p>
                                      <img src={Plusbtn} alt="" />
                                    </div>
                                  </DropdownItem>
                                );
                              })}
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </div>
                    )}
                    <Button
                      color="primary"
                      onClick={nextPayment}
                      className="mx-3"
                      style={{ minHeight: 60, minWidth: 220, borderRadius: 8 }}
                    >
                      Beli Sekarang
                    </Button>
                    {isReseller && (
                      <div className="mobile-hide">
                        <div>
                          <div onClick={handleShowShare} style={{ textAlign: "left" }}>
                            <img src={ShareButton} alt=" " style={{ height: 27, width: 27 }} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <CustomModal
                    isOpen={show}
                    onRequestClose={handleShowShare}
                    style={CustomStyles}
                    ariaHideApp={false}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        border: "none",
                        textAlign: "center",
                        padding: "32px 16px",
                        justifyContent: "center",
                      }}
                    >
                      <h4 style={{ textAlign: "center" }}>
                        Ingin Membagikan?
                        <br />
                        Ayo Ambil Komisi Mu!
                      </h4>
                      <Button
                        onClick={handleShowShare}
                        style={{
                          border: "none",
                          background: "transparent",
                          position: "absolute",
                          right: 14,
                        }}
                      >
                        <img src={closeIcon} alt="" style={{ width: 20, height: 20 }} />
                      </Button>
                    </div>
                    <div>
                      <div>
                        {message && <Alert color="danger">{message}</Alert>}
                        {isFailed && !message && (
                          <Alert color="danger" className="text-center">
                            Terjadi kesalahan, harap coba beberapa menit lagi, Terimakasih
                          </Alert>
                        )}
                        <ModalForm>
                          <form
                            onSubmit={handlerSubmitData}
                            style={{
                              justifyContent: "center",
                              margin: "0 calc(1/10*100%)",
                            }}
                          >
                            <CustomForm
                              setForm={setForm}
                              validateForm={validateForm}
                              validationClick={validationClick}
                              form={form}
                            />
                            <div className="reset-password">
                              <p>
                                <Link
                                  to="/forgot-password"
                                  style={{ fontWeight: 600, color: "#000A8C" }}
                                >
                                  Lupa Password?
                                </Link>
                              </p>
                            </div>
                            <div style={{ display: "flex", margin: "0 4%" }}>
                              <Button
                                block
                                type="submit"
                                disabled={loading}
                                color="primary"
                                style={{ width: "100%", borderRadius: 8 }}
                              >
                                {loading ? <Spinner color="light" /> : "Masuk"}
                              </Button>
                            </div>
                          </form>
                        </ModalForm>
                        <div style={{ margin: 16, textAlign: "center" }}>
                          <p>
                            Belum Punya Akun? Daftar{" "}
                            <Link
                              to="/signup/reseller"
                              style={{ fontWeight: 600, color: "#000A8C" }}
                            >
                              Disini
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </CustomModal>
                </div>
              </div>
            </div>
          )}
    </Style>
  );
};
export default Footer;
