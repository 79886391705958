/* eslint-disable */
import React from "react";
import { color } from "../../../Utils/Variable";
import styled from "styled-components";
import Testimon1 from "../../../Components/Assets/Images/testimoni1.png";
import Testimon2 from "../../../Components/Assets/Images/testimoni2.png";
import Testimon3 from "../../../Components/Assets/Images/testimoni3.png";
import Testimon4 from "../../../Components/Assets/Images/testimoni4.jpeg";
import Background2 from "../../../Components/Assets/Images/testimoni_2.jpg";
import Background3 from "../../../Components/Assets/Images/testimoni_3.jpg";
import Background4 from "../../../Components/Assets/Images/testimoni_6.jpg";
import Background5 from "../../../Components/Assets/Images/testimoni_5.jpg";

import Slider from "react-slick";
import { Container } from "reactstrap";
import LocalStorage from "../../../Utils/LocalStorage";

const Style = styled.div`
    padding: 50px 0;

    .background-slider {
        padding: 30px 30px;
        border-radius: 5px;
        img {
            width: 300px;
            height: 300px;
            object-fit: cover;
        }
        p {
            color: #4a4a4a;
        }
        .desc {
            margin-bottom: 100px;
        }
    }
    .slick-dots {
        bottom: -35px;
        li {
            width: 80px;
            .rectangle {
                border-radius: 3px;
                height: 8px;
                display: block;
                background: #ddd;
                transition: all 0.25s ease;
            }
            @media (max-width: 389px) {
                width: 30px;
            }
        }
        .slick-active {
            .rectangle {
                background: ${color.primary};
            }
        }
    }
    .reseller {
        &-slider {
            margin-bottom: 50px;
        }
        &-section {
            padding: 100px;
            background: #000;
            color: #fff;
            margin-bottom: 100px;
            &__image {
                background: #c4c4c4;
                position: relative;
                border-radius: 5px;
                &-title {
                    position: absolute;
                    padding: 10px;
                    border-radius: 0 0 3px 3px;
                    background: #222222;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    p {
                        margin-bottom: 0;
                        font-size: 12px;
                    }
                }
            }
        }
        &-bottom {
            &__header {
                margin-bottom: 40px;
                @media (max-width: 575px) {
                    font-size: 14px;
                    h4 {
                        font-size: 22px;
                    }
                }
            }
            &__list {
                &-time {
                    color: #4a4a4a;
                    margin-bottom: 5px;
                    display: block;
                    font-size: 14px;
                }
                &-title {
                    text-decoration: underline;
                    margin-bottom: 10px;
                }
                @media (max-width: 575px) {
                    font-size: 14px;
                }
            }
        }
    }
`;
export const testimoni = [
    {
        background: Background5,
        name: "Rizal",
        status: "Pengusaha",
        image: Testimon1,
        desc: "Caranya mudah dan sangat membantu banget, Terima kasih Lakugan",
    },
    {
        background: Background2,
        name: "Ika",
        status: "Ibu Rumah Tangga",
        image: Testimon2,
        desc:
            "Begitu coba langsung dapat uang, cocok banget untuk ibu rumah tangga punya bisnis, tetep bisa urus anak dirumah tanpa terganggu",
    },
    {
        background: Background3,
        name: "Wahyu",
        status: "Mahasiswa",
        image: Testimon3,
        desc:
            "Lumayan, selain bisa cari uang tambahan bisa mulai belajar bisnis juga",
    },
    {
        background: Background4,
        name: "Nina Wantina",
        status: "Karyawan Swasta",
        image: Testimon4,
        desc:
            "Akhirnya bisa punya bisnis sampingan yang enak,nggak pake modal,nggak repot dan yang terpenting nggak ganggu kerjaan kantor",
    },
];
const TestimonyStyle = styled.div`
    .testimoni {
        &-background {
            position: relative;
            border-radius: 10px;
            img {
                @media (max-width: 450px) {
                    height: 190px;
                    object-fit: cover;
                }
            }
        }
        &-content {
            border-radius: 10px;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 35px;
            &__user {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .testimoni-text,
            .testimoni-image {
                width: 45%;
            }
            @media (max-width: 389px) {
                padding: 8px;
            }
            .testimoni-image {
                img {
                    width: 100%;
                    height: 300px;
                    object-fit: contain;
                    @media (max-width: 991px) {
                        height: 200px;
                    }
                    @media (max-width: 767px) {
                        height: 150px;
                    }
                    @media (max-width: 389px) {
                        height: 100px;
                    }
                }
            }
        }
    }

    p {
        color: #fff;
        font-weight: 500;
        @media (max-width: 549px) {
            font-size: 12px;
        }
        @media (max-width: 329px) {
            font-size: 10px;
        }
    }
    .desc {
        margin-bottom: 50px;
        @media (max-width: 549px) {
            font-size: 12px;
            margin-bottom: 15px;
        }
        @media (max-width: 329px) {
            font-size: 10px;
        }
    }
    @media (max-width: 767px) {
        background-size: contain;
    }
    @media (max-width: 329px) {
        p {
            font-size: 12px;
        }
    }
`;
const Reseller = () => {
    const token = LocalStorage().get("auth");
    const auth = LocalStorage().get(`${token?.access_token}`);
    const settings = {
        customPaging: function (i) {
            return (
                <a>
                    <span className="rectangle"></span>
                </a>
            );
        },
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <React.Fragment>
            <Style>
                <div className="reseller-slider">
                    <Slider {...settings}>
                        {testimoni.map((item, key) => {
                            return (
                                <TestimonyStyle key={key}>
                                    <div className="testimoni-background">
                                        <img
                                            src={item.background}
                                            alt=""
                                            className="img-fluid 
                                                @media(max-width: 450px){
                                                    height: 190px;
                                                    object-fit: cover;
                                                }"
                                        />
                                        <div className="testimoni-content">
                                            <Container fluid="md">
                                                <div className="testimoni-content__user">
                                                    <div className="testimoni-text">
                                                        <p className="desc">
                                                            {item.desc}
                                                        </p>
                                                        <p className="font-weight-bold mb-0">
                                                            {item.name},{" "}
                                                            {item.status}
                                                        </p>
                                                    </div>

                                                    <div className="testimoni-image">
                                                        <img
                                                            src={item.image}
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                </div>
                                            </Container>
                                        </div>
                                    </div>
                                </TestimonyStyle>
                            );
                        })}
                    </Slider>
                </div>
                <div className="reseller-bottom">
                    <Container fluid="md">
                        <div className="reseller-bottom__header">
                            <h4>Webinar dari Lakugan</h4>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry
                            </p>
                        </div>
                        <div className="reseller-bottom__list">
                            <span className="reseller-bottom__list-time">
                                SENIN, 16 NOVEMBER 2020
                            </span>
                            <h6 className="reseller-bottom__list-title">
                                Judul Webinar yang akan diadakan
                            </h6>
                            <p className="reseller-bottom__list-desc">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry
                            </p>
                        </div>
                    </Container>
                </div>
            </Style>
        </React.Fragment>
    );
};
export default Reseller;
