import styled from "styled-components";
import Banner from "../../../Components/Assets/v2/banner.png";
import BannerMobile from "../../../Components/Assets/v2/banner-mobile.png";
import BgWhite from "../../../Components/Assets/v2/bg-white.svg";
import BgBlue from "../../../Components/Assets/v2/bg-blue.svg";
import BgGrey from "../../../Components/Assets/v2/bg-grey.svg";
import BackgroundGrey from "../../../Components/Assets/v2/background-grey.png";
import BackgroundBlue from "../../../Components/Assets/v2/background-blue.png";
import BackgroundGrey2 from "../../../Components/Assets/v2/background-grey2.png";
import BackgroundBlueMobile from "../../../Components/Assets/v2/background-blue-mobile.png";
import BackgroundFooterMobile from "../../../Components/Assets/v2/background-footer-mobile.png";
import BtnPrevBlue from "../../../Components/Assets/v2/btn-prev-blue.png";
import BtnNextBlue from "../../../Components/Assets/v2/btn-next-blue.png";
import BtnPrevWhite from "../../../Components/Assets/v2/btn-prev-white.png";
import BtnNextWhite from "../../../Components/Assets/v2/btn-next-white.png";

const Style = styled.div`
    .first, .second, .third, .fourth, .fiveth, .sixth, .seventh {
        border-bottom: 1px solid #e0e0e0;
    }
    .first .header {
        background: url(${Banner});
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .first .footer {
        background: url(${BgWhite});
        background-size: 100%;
        width: 100%;
        height: 300px;
        margin-top: -220px;
        position: relative;
        z-index: 999;
    }
    .navbar {
        padding: 30px 140px;
    }
    .nav-item {
        padding: 0 8px;
        font-size: 18px;
    }
    .nav-item a {
        font-size: 18px;
    }
    .nav-item .nav-link {
        color: #fff !important;
        font-weight: bold;
    }
    .wrapper-header {
        padding: 0 180px;
    }
    .wrapper-header-column {
        padding-top: 121px;
    }
    .btn-radius {
        border-radius: 64px;
        color: #124981 !important;
        font-weight: bold;
        width: 115px;
        height: 44px;
    }
    .header1-primary {
        font-weight: 600;
        font-size: 44px;
        line-height: 66px;
        color: #F8F8F8;
    }
    .header4-primary {
        font-size: 24px;
        font-weight: 500;
        margin-top: 18px;
        line-height: 28px;
        color: #F8F8F8;
    }
    .btn-register {
        margin-top: 32px;
        font-weight: bold;
        font-size: 18px;
        line-height: 34px;
        width: 205px;
        height: 48px;
        background-color: #FFB508;
        border-radius: 16px;
        color: #003466 !important;
        position: relative;
        z-index: 1000;
    }
    .subtitle-primary {
        padding-left: 2em;
        color: #00305E;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
    }
    .second {
        margin-top: 100px;
        padding-bottom: 60px;
    }
    .second .col-sm-4 {
        margin-bottom: 24px;
    }
    .col-title ul {
        list-style: none;
        padding: 0;
    }
    .col-title li {
        padding-left: 1.3em;
        font-size: 36px;
        font-weight: bold;
        line-height: 54px;
        color: #00305E;
    }
    .col-title li:before {
        content: "\f068"; /* FontAwesome Unicode */
        font-family: FontAwesome;
        display: inline-block;
        margin-left: -1.3em; /* same as padding-left set on li */
        width: 1.3em; /* same as padding-left set on li */
        color: #FFB508;
    }
    .number {
        width: 61px;
        height: 144px;
        font-weight: 600;
        font-size: 96px;
        line-height: 144px;
        color: #FFB508;
        margin-right: -30px;
    }
    .column-frame {
        display: flex;
        justify-content: center;
    }
    .column-frame-text {
        margin-top: 12px;
        text-align: center;
    }
    .frame-title {
        font-weight: bold;
        font-size: 32px;
        line-height: 48px;
        color: #FFB508;
    }
    .frame-subtitle {
        margin-top: 8px;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #00305E;
    }
    .third .header {
        padding-top: 80px;
        background-color: #F5F5F7;
    }
    .third .footer {
        background: url(${BgBlue});
        background-size: 100%;
        width: 100%;
        height: 300px;
        margin-top: -230px;
        position: relative;
        z-index: 999;
    }
    .button-benefit {
        padding: 7px 27px;
        gap: 16px;
        height: 48px;
        background: #FFB508;
        border-radius: 64px 64px 32px 32px;
        color: #003466;
        font-weight: bold;
    }
    .text-benefit {
        margin-top: 18px;
    }
    .text-benefit ul {
        list-style: none;
        padding: 0;
    }
    .text-benefit li {
        padding-left: 1.3em;
        font-size: 18px;
        line-height: 32px;
    }
    .text-benefit li:before {
        content: "\f00c"; /* FontAwesome Unicode */
        font-family: FontAwesome;
        display: inline-block;
        margin-left: -1.3em; /* same as padding-left set on li */
        width: 1.3em; /* same as padding-left set on li */
        color: #FFB508;
    }
    .fourth {
        background-color: #F5F5F7;
        padding-bottom: 100px;
    }
    .fourth .header {
        padding-top: 80px;
        background: #00305E;
    }
    .title-primary-white {
        font-weight: bold;
        font-size: 36px;
        line-height: 54px;
        color: #FFF;
    }
    .fourth .footer {
        background: url(${BgGrey});
        background-size: 100%;
        width: 100%;
        height: 300px;
        margin-top: -220px;
        position: relative;
        z-index: 999;
        margin-bottom: 100px;
    }
    .box-image {
        background-color: #fff;
        border-radius: 64px 64px 32px 32px;
        width: 400px;
        height: 280px;
        display:block;
        margin:auto;
        position: relative;
        margin-top: 70px;
    }
    .box-image img {
        position: absolute;
        top: -50px;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
    }
    .product-text {
        padding-top: 50px;
        padding-bottom: 240px;
        text-align: center;
    }
    .product-text h2 {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #fff;
    }
    .product-text a {
        font-weight: bold;
        font-size: 18px;
        line-height: 34px;
        color: #003466 !important;
        background: #FFB508;
        border-radius: 16px;
        width: 180px;
        height: 48px;
    }
    .swiperProduct .swiper-slide {
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        margin-top: 85px;
        width: 80%;
    }
    .swiperProduct .swiper-slide > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
        margin-top: -60px;
    }
    .swiperProduct .swiper-button-prev {
        top: 225px;
        margin-left: 200px;
    }
    .swiperProduct .swiper-button-next {
        top: 225px;
        margin-right: 200px;
    }
    .swiperProduct .swiper-pagination {
        top: 450px;
    }
    .swiperProduct .swiper-button-prev::after {
        content: url(${BtnPrevWhite});
    }
    .swiperProduct .swiper-button-next::after{
        content: url(${BtnNextWhite});
    }
    .fiveth .header {
        padding-top: 80px;
        padding-bottom: 280px;
        background: url(${BackgroundGrey});
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .swiperTestimonial {
        margin-top: 50px;
    }
    .wrapper-testmonial {
        padding: 32px;
        background-color: #fff;
        border-radius: 20px;
        width: 100%;
    }
    .wrapper-testmonial p {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        word-wrap: break-word;
        text-align: justify;
    }
    .wrapper-testmonial h3 {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #003466;
    }
    .wrapper-testmonial span {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #003466;
    }
    .avatar-testimonial {
        width: 97px;
        height: 110px;
    }
    .avatar-testimonial img {
        object-fit: cover;
        border-radius: 12px;
        width: 97px;
        height: 110px;
    }
    .swiperTestimonial .swiper-slide {
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        width: 40%;
    }
    .sixth {
        margin-top: 80px;
    }
    .wrapper-faq {
        margin-top: 12px;
        border-bottom: 1px solid #141313;
        padding-bottom: 16px;
    }
    .wrapper-faq:last-of-type {
        border-bottom: none;
    }
    .wrapper-faq h3 {
        font-weight: bold;
        font-size: 22px;
        line-height: 36px;
        color: #141313;
    }
    .wrapper-faq p {
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #141313;
    }
    .btn-load-faq {
        font-weight: bold;
        line-height: 34px;
        color: #003466 !important;
        background-color: #fff;
        padding: 7px 27px;
        width: 225px;
        height: 48px;
        border: 1px solid #003466;
        border-radius: 16px;
        margin-top: 36px;
        margin-bottom: 80px;
    }
    .seventh {
        margin-top: 80px;
        padding-bottom: 30px;
    }
    .seventh .col-6 {
        padding: 0 24px;
    }
    .wrapper-reseller-month {
        background-color: #FFB508;
        border-radius: 16px;
        padding: 12px;
        min-height: 900px;
    }
    .wrapper-reseller-rating {
        background-color: #003466;
        border-radius: 16px;
        padding: 12px;
        min-height: 900px;
    }
    .card-reseller-month {
        text-align: center;
        margin-top: 36px;
    }
    .card-reseller-month img {
        border-radius: 12px;
        width: 128px;
        height: 145px;
        object-fit: cover;
    }
    .card-reseller-month h3 {
        color: #fff;
        font-weight: bold;
        font-size: 32px;
        line-height: 48px;
    }
    .card-reseller-month p {
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px
    }
    .table-reseller-rating {
        margin-top: 36px;
        padding: 0 12px;
    }
    .borderless td, .borderless th {
        border: none;
    }
    .table {
        color: #fff;
        font-size: 18px;
        line-height: 32px;
    }
    .table tr > td:last-of-type {
        text-align: center;
    }
    .wrapper-partnership {
        margin-top: 36px;
    }
    .wrapper-partnership img {
        display: block;
        margin: auto;
        padding: 24px;
    }
    .wrapper-partnership .col-4 {
        display: flex;
        justify-content: center;
    }
    .row-finance, .row-shipper {
        margin-top: 36px;
    }
    .row-finance .col-4 {
        display: block;
        margin: auto;
        padding: 12px;
    }
    .row-finance img {
        display: block;
        margin: auto;
    }
    .row-shipper .col-4 {
        display: block;
        margin: auto;
        padding: 12px;
    }
    .row-shipper img {
        display: block;
        margin: auto;
    }
    .eighth {
        margin-top: 120px;
    }
    .eighth .header {
        background: url(${BackgroundGrey2});
        background-position-y: 80px;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .eighth .footer {
        background: url(${BackgroundBlue});
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: -260px;
        padding-bottom: 36px;
        position: relative;
        z-index: 999;
    }
    .wrapper-footer {
        padding-top: 180px;
    }
    .wrapper-footer h3 {
        font-weight: bold;
        font-size: 28px;
        line-height: 42px;
        color: #fff;
    }
    .wrapper-footer h4 {
        font-weight: bold;
        font-size: 24px;
        line-height: 34px;
        text-align: center;
        color: #fff;
        padding-bottom: 12px;
    }
    .wrapper-footer img {
        padding-bottom: 12px;
    }
    .wrapper-footer p, .wrapper-footer span {
        font-weight: 500;
        font-size: 16px;
        line-height: 34px;
        color: #fff;
    }
    .wrapper-footer a {
        color: #fff !important;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 34px;
        display: block;
        padding: 6px 0;
    }
    .wrapper-icons a {
        display: inline-block;
    }
    .header1-primary-blue {
        font-weight: bold;
        font-size: 44px;
        line-height: 66px;
        color: #003466;
    }
    .img-footer {
        margin-top: -80px;
        padding-left: 36px;
    }
    .row-footer {
        padding-bottom: 24px;
        border-bottom: 1px solid #fff;
    }
    .row-footer-bottom {
        padding: 46px 0;
    }
    .row-footer-bottom a {
        font-weight: bold;
        font-size: 24px;
        line-height: 34px;
        color: #fff !important;
    }
    .text-footer {
        font-weight: 500;
        font-size: 18px;
        line-height: 34px;
        color: #fff;
    }
    .img-icons {
        padding: 0 6px;
    }
    .benefit-mobile-image img {
        margin-left: -120px;
        margin-top: -80px;
    }
    .row-partnership {
        margin-top: 120px;
    }
    .wrapper-reseller-month .col-title, .wrapper-reseller-rating .col-title {
        padding-top: 24px;
    }
    .wrapper-reseller-month li {
        color: #fff;
    }
    .wrapper-reseller-month .col-title li:before {
        color: #fff;
    }
    .wrapper-reseller-rating li {
        color: #fff;
    }
    .wrapper-reseller-rating .col-title li:before {
        color: #fff;
    }
    tbody tr td:first-child { 
        text-align: right;
        width: 11%; 
    };
    .column-frame img {
        min-height: 20px;
    }
    .swiper-slide {
        padding-bottom: 60px;
    }
    .swiper-pagination-bullet {
        width: 20px;
        height: 20px;
    }
    .testimonial-nav .swiper-button-prev {
        left: auto;
        right:76px;
    }
    .swiperTestimonial .swiper-button-prev::after, .testimonial-nav .swiper-button-prev::after {
        content: url(${BtnPrevBlue});
    }
    .swiperTestimonial .swiper-button-next::after, .testimonial-nav .swiper-button-next::after{
        content: url(${BtnNextBlue});
    }
    .text-left a {
        text-align: left;
        margin-left: 72px;
    }

    @media only screen and (max-width: 768px) {
        .row {
            margin: 0px !important;
        }
        .first, .second, .third, .fourth, .fiveth, .sixth, .seventh {
            border-bottom: none;
        }
        .navbar {
            padding: 12px;
        }
        .navbar img {
            width: 140px;
        }
        .first .header {
            background: url(${BannerMobile});
            background-position: top right 50%;
            background-repeat: no-repeat;
        }
        .first .footer {
            background: url(${BgWhite});
            background-size: 100%;
            width: 100%;
            height: 100px;
            margin-top: -70px;
        }
        .wrapper-header {
            padding: 0;
        }
        .wrapper-header .row .col-6 {
            align-self: center;
        }
        .wrapper-header-column {
            padding-top: 36px;
            text-align: center;
        }
        .wrapper-header img {
            width: 100%;
            display: block;
            margin: auto;
        }
        .header1-primary, .header1-primary-blue {
            font-weight: 600;
            font-size: 24px;
            line-height: 41px;
            padding: 0 10px;
        }
        .header1-primary br {
            display: none;
        }
        .header4-primary {
            font-size: 18px;
            line-height: 28px;
        }
        .col-title li {
            font-size: 26px;
            line-height: 34px;
        }
        .benefit-mobile-text {
            order: 1;
        }
        .benefit-mobile-image {
            order: 2;
        }
        .third .footer {
            background: url(${BgBlue});
            background-size: 100%;
            width: 100%;
            height: 80px;
            margin-top: -70px;
        }
        .text-benefit li {
            font-size: 16px;
        }
        .benefit-mobile-image img {
            margin-left: -30px;
            padding-top: 48px;
            width: 100%;
        }
        .product-text {
            padding-top: 12px;
            padding-bottom: 24px;
        }
        .product-text h2 {
            font-size: 26px;
            line-height: 40px;
        }
        .fourth .header {
            background: url(${BackgroundBlueMobile});
            background-size: 100% 100%;
        }
        .fourth .header .col-sm-6 {
            padding-bottom: 40px;
        }
        .fourth .footer {
            height: 0px;
            margin-top: 0px;
            margin-bottom: 0px;
        }
        .fiveth .header {
            padding-top: 220px;
            padding-bottom: 0;
            background-size: cover;
        }
        .wrapper-testmonial {
            padding: 12px;
        }
        .wrapper-testmonial p {
            font-size: 12px;
            line-height: 20px;
        }
        .wrapper-testmonial h3 {
            font-size: 22px;
            line-height: 28px;
        }
        .wrapper-testmonial span {
            font-size: 14px;
            line-height: 21px;
        }
        .col-testimonial-mobile {
            padding-left: 44px !important;
            padding-top: 12px !important;
        }
        .avatar-testimonial {
            width: 77px;
            height: 90px;
        }
        .avatar-testimonial img {
            object-fit: cover;
            border-radius: 12px;
        }
        .title-primary-white {
            font-size: 28px;
            line-height: 44px;
        }
        .wrapper-reseller-month, .wrapper-reseller-rating {
            margin: 12px;
        }
        .row-finance, .row-shipper {
            margin: 24px 0;
        }
        .row-finance img, .row-shipper img {
            width: 80px;
        }
        .wrapper-partnership img {
            width: 150px;
        }
        .img-footer {
            padding-left: 0;
            width: 100%;
            margin-top: 50px;
        }
        .box-image {
            width: 250px;
            height: 200px;
        }
        .box-image img {
            width: 200px;
        }
        .wrapper-footer {
            padding-top: 36px;
        }
        .eighth {
            margin-top: 36px;
        }
        .eighth .header {
            background: url(${BackgroundGrey2});
            background-position-y: 160px;
            background-repeat: no-repeat;
        }
        .eighth .footer {
            background: url(${BackgroundFooterMobile});
            background-size: cover;
            margin-top: -60px;
        }
        .wrapper-footer, .row-footer-bottom, .text-footer {
            text-align: center;
        }
        .row-footer-bottom a {
            font-size: 18px;
        }
        .wrapper-footer h4 {
            font-size: 18px;
        }
        .wrapper-footer > img {
            width: 140px;
        }
        .col-footer {
            padding-left: 15px;
            text-align: center;
        }
        .swiperProduct .swiper-pagination {
            top: 150px;
            margin-top: 24px;
        }
        .swiperProduct .swiper-button-prev {
            top: 85px;
            margin-left: 30px;
        }
        .swiperProduct .swiper-button-next {
            top: 85px;
            margin-right: 30px;
        }
        .swiperProduct .swiper-button-prev::after {
            font-size: 36px;
        }
        .swiperProduct .swiper-button-next::after{
            font-size: 36px;
        }
        .sixth img {
            width: 100%;
        }
        .wrapper-partnership {
            margin-top: 0px;
            margin-bottom: 36px;
        }
        .row-finance, .row-shiper {
            padding-bottom: 36px;
        }
        .row-partnership {
            padding-top: 36px;
        }
        .button-benefit {
            padding: 0px 18px;
        }
        .swiperTestimonial .swiper-button-prev {
            margin-left: 250px;
        }
        .swiperTestimonial .swiper-button-next {
            margin-right: 20px;
        }
        .swiperTestimonial .swiper-button-prev::after {
            font-size: 36px;
        }
        .swiperTestimonial .swiper-button-next::after{
            font-size: 36px;
        }
        .nav-open {
            position: fixed;
            top: 0 !important;
            left: 0 !important;
            height: 100%;
            width: 100%;
            background-color: #003263;
            z-index: 1001;
            -webkit-transtition: left .3s ease-in-out, right .3s ease-in-out;
            transition: left .3s ease-in-out, right .3s ease-in-out;
        }
        .nav-open ul {
            text-align: center;
        }
        .nav-open .nav-link {
            padding: 24px 0;
        }
        .nav-open img {
            float: left;
            padding: 12px;
            width: 165px;
        }
        .nav-open i {
            float: right;
            color: #fff;
            padding: 12px;
            font-size: 36px;
            margin-right: 12px;
        }
        .nav-open .nav-item:last-of-type {
            border-bottom: 1px solid #fff;
            width: 70%;
            display: block;
            margin: auto;
        }
        .nav-open-btn a {
            margin: 24px;
        }
        .btn-radius {
            height: 40px;
        }
        .faq-mobile-text {
            order: 1;
        }
        .faq-mobile-image {
            order: 2;
        }
        .table-reseller-rating {
            padding: 0 8px;
        }
        .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
        }
        .swiperTestimonial .swiper-slide {
            width: 70%;
        }
        .swiperTestimonial .swiper-button-prev::after, .testimonial-nav .swiper-button-prev::after {
            font-size: 36px;
        }
        .swiperTestimonial .swiper-button-next::after, .testimonial-nav .swiper-button-next::after{
            font-size: 36px;
        }
        .testimonial-nav {
            margin-top: -36px;
        }
        .text-left a {
            text-align: center;
            margin-left: 0;
        }
    }

    @media only screen and (min-width: 1440px) {
        .benefit-mobile-image img {
            margin-left: -165px;
        }
        .fiveth .header {
            padding-bottom: 360px;
        }
        .swiperProduct .swiper-pagination {
            margin-top: 24px;
        }
        .fourth .footer {
            height: 320px;
        }
    }

    @media only screen and (min-width: 1600px) {
        .swiperProduct .swiper-button-prev {
            top: 275px;
            margin-left: 225px;
        }
        .swiperProduct .swiper-button-next {
            top: 275px;
            margin-right: 225px;
        }
        .swiperProduct .swiper-pagination {
            margin-top: 96px;
        }
        .fiveth .header {
            padding-top: 120px;
        }
    }

    @media only screen and (min-width: 1920px) {
        .first .footer {
            margin-top: -250px;
        }
        .swiperProduct .swiper-button-prev {
            top: 300px;
        }
        .swiperProduct .swiper-button-next {
            top: 300px;
        }
        .swiperProduct .swiper-pagination {
            margin-top: 172px;
        }
        .fiveth .header {
            padding-top: 210px;
            padding-bottom: 400px;
        }
    }
`;
export default Style;
