/* eslint-disable */
import React from "react";
import { Col, FormGroup, Input, Row, Label } from "reactstrap";
import styled from "styled-components";
import CommonTable from "../Molekul/CommonTable";
import Form from "../Molekul/FormValidation";
import { color } from "../../Utils/Variable";
import Button from "../Particle/Button";
import FormPrice from "../Particle/Form/FormPrice";
import FormText from "../Particle/Form/FormText";
import service from "./index.service";
import GrowingLoading from "../Loading/GrowingLoading";
import { useStore } from "../../Reducers";

const Style = styled.div`
    padding: 20px;
    .sub-title {
        color: #848484;
        font-size: 14px;
    }
`;
const EditProduct = ({
    dataProduct,
    setAlert = () => {},
    setShow = () => {},
    isShow = true,
}) => {
    const { dispatch, state } = useStore();
    const [validationClick, setValidationClick] = React.useState(true);
    const [validateForm, setValidateForm] = React.useState(true);
    const [formPiece, setFormPiece] = React.useState(false);
    const [formDuration, setFormDuration] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState([
        {
            name: "nameProduct",
            label: "Nama produk",
            type: "text",
            action: "text",
            col: 12,
            valid: "Nama produk tidak boleh kosong",
            value: dataProduct.name,
            status: true,
        },
        {
            name: "price",
            type: "price",
            label: "Harga",
            action: "price",
            currencyLogo: "Rp. ",
            col: 6,
            value: dataProduct.price,
            defaultValue: dataProduct.price,
            status: true,
        },
        {
            name: "category",
            type: "select",
            action: "select",
            label: "Kategori",
            col: 6,
            service: {
                api: service.category,
                group: "category",
                key: "category",
            },
            isMulti: false,
            valid: "Pilih kategori",
            value: dataProduct.category.id,
            status: true,
        },
        {
            name: "image-multiple",
            type: "image",
            label: "Gambar produk",
            action: "uploadImageMultiple",
            col: 12,
            valid: "Harap masukkan gambar",
            value: dataProduct.product_image.map((item) => {
                return {
                    name: item.name,
                    timeStamp: "",
                    size: "",
                    type: "image/png",
                    link: item.img_url,
                };
            }),
            status: true,
            max: 10,
        },
        {
            name: "desc",
            type: "textarea",
            label: "Deskripsi produk",
            action: "textarea",
            rows: 10,
            col: 12,
            valid: "Harap masukkan gambar",
            value: dataProduct.description,
            status: true,
            max: 3,
        },
    ]);
    const [specification, setSpecification] = React.useState(dataProduct.spec);
    const handleAddSpecification = () => {
        let obj = {
            id: specification.length + 1,
            color: "",
            size: "",
            total_stock: 0,
            weight: 0,
        };
        setSpecification((prevState) => {
            return [...prevState, obj];
        });
        // setDataDummy(obj);
    };
    const handleDeleteItem = (item) => {
        // e.preventDefault();
        const items = specification;
        const valueToRemove = item;
        const filteredItems = items.filter(function (item) {
            return item !== valueToRemove;
        });
        // setSpecification(specification.splice(0, item.id - 1));
        // setLoadingRemove(true);
        onRemoveData(filteredItems);
    };
    const onRemoveData = React.useCallback((value) => {
        return setSpecification(value);
    });
    const getShortData = (data, item) => {
        return data
            .filter(function (obj) {
                return obj.id === item.id;
            })
            .map(function (obj) {
                return (obj[item.name] = item.value);
            });
    };
    const onGetValue = (item) => {
        getShortData(specification, item);
        // specification[item.id - 1][item.name] = item.value;
        // setSpecification(specification);
    };
    const handleCheckPiece = (e) => {
        let checked = e.target.checked;
        setFormPiece(checked);
    };
    const handleCheckDuration = (e) => {
        let checked = e.target.checked;
        setFormDuration(checked);
    };
    const handleCreateProduct = (e) => {
        let isError = false;
        e.preventDefault();
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }

        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setLoading(true);
            let id = dataProduct.id;
            let data = {
                name: form[0].value,
                description: form[4].value,
                price: form[1].value,
                rate: 0.0,
                is_special_cut: false,
                nominal_special_cut: 0.1,
                category: { id: form[2].value },
                spec: specification.map((item) => {
                    return {
                        color: item.color,
                        size: item.size,
                        total_stock: item.total_stock,
                        weight: item.weight,
                    };
                }),
                product_image: form[3].value.map((item) => {
                    return { name: item.name, img_url: item.link };
                }),
            };
            service.updateProduct({ dispatch, data, id });
        }
    };
    React.useEffect(() => {
        if (state?.product?.createProduct?.isSuccess && isShow) {
            service.product({ dispatch });
            // getProduct();
            setShow(false);
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
            }, 3000);
        }
        if (state?.product?.productList?.isSuccess) {
            setLoading(false);
        }
    }, [state, isShow]);
    return (
        <Style>
            <GrowingLoading active={loading} />
            <Row>
                <Col xl="6" md="6" sm="6" xs="12">
                    <span className="sub-title mb-4 d-block">
                        Deskripsi produk
                    </span>
                    <Form
                        setForm={setForm}
                        validateForm={validateForm}
                        validationClick={validationClick}
                        form={form}
                    />
                </Col>
                <Col xl="6" md="6" sm="6" xs="12">
                    <span className="sub-title mb-4 d-block">Spesifikasi</span>
                    <div className="mb-5">
                        <CommonTable
                            edit={true}
                            dummyData={specification}
                            setDataDummy={setSpecification}
                            configuration={{
                                searchInput: false,
                                nav: {
                                    hasSearch: false,
                                },
                                // service: {
                                //     api: service.missionList,
                                //     group: "mission",
                                //     key: "missionList"
                                // },
                                optionTable: [
                                    {
                                        headName: "Warna",
                                        fieldName: "color",
                                        type: "isCostume",
                                        renderItem: (item) => {
                                            return (
                                                <React.Fragment>
                                                    <FormText
                                                        id={item.id}
                                                        onGetValue={onGetValue}
                                                        item={{
                                                            value: item.color,
                                                            name: "color",
                                                        }}
                                                    />
                                                </React.Fragment>
                                            );
                                        },
                                    },
                                    {
                                        headName: "Ukuran",
                                        fieldName: "size",
                                        type: "isCostume",
                                        renderItem: (item) => {
                                            return (
                                                <React.Fragment>
                                                    <FormText
                                                        id={item.id}
                                                        item={{
                                                            value: item.size,
                                                            name: "size",
                                                        }}
                                                        onGetValue={onGetValue}
                                                    />
                                                </React.Fragment>
                                            );
                                        },
                                    },
                                    {
                                        headName: "Stok",
                                        fieldName: "total_stock",
                                        type: "isCostume",
                                        renderItem: (item) => {
                                            return (
                                                <React.Fragment>
                                                    <FormText
                                                        id={item.id}
                                                        onGetValue={onGetValue}
                                                        item={{
                                                            name: "total_stock",
                                                            value: item.total_stock,
                                                            type: "number",
                                                        }}
                                                    />
                                                </React.Fragment>
                                            );
                                        },
                                    },
                                    {
                                        headName: "Berat",
                                        fieldName: "weight",
                                        type: "isCostume",
                                        renderItem: (item) => {
                                            return (
                                                <React.Fragment>
                                                    <FormPrice
                                                        onGetValue={onGetValue}
                                                        id={item.id}
                                                        item={{
                                                            defaultValue:
                                                                item.weight,
                                                            suffix: " kg",
                                                            precision: 1,
                                                            name: "weight",
                                                        }}
                                                    />
                                                </React.Fragment>
                                            );
                                        },
                                    },
                                    {
                                        headName: "",
                                        type: "isCostume",
                                        renderItem: (item, key) => {
                                            return (
                                                specification[
                                                    specification.length - 1
                                                ] === item && (
                                                    <React.Fragment key={key}>
                                                        <FormGroup>
                                                            <Button
                                                                color="transparent"
                                                                size="sm"
                                                                type="button"
                                                                onClick={() =>
                                                                    handleDeleteItem(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                <i className="fas fa-trash-alt text-secondary"></i>
                                                            </Button>
                                                        </FormGroup>
                                                    </React.Fragment>
                                                )
                                            );
                                        },
                                    },
                                ],
                            }}
                        ></CommonTable>
                        <Button
                            onClick={handleAddSpecification}
                            color={color.primary}
                            type="button"
                            block
                        >
                            + Tambah Spesifikasi
                        </Button>
                    </div>
                    <div>
                        <span className="sub-title mb-2 d-block">
                            Spesifikasi Khusus
                        </span>
                        <Row>
                            <Col xl="6" md="6" sm="6">
                                <span className="sub-title mb-2 d-block">
                                    Pilih Jenis Potongan
                                </span>
                                <div className="mb-2">
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                id="checkbox2"
                                                onChange={handleCheckPiece}
                                            />{" "}
                                            Potongan Khusus
                                        </Label>
                                    </FormGroup>
                                </div>
                                {formPiece && (
                                    <FormGroup>
                                        <FormPrice
                                            item={{
                                                defaultValue: 0,
                                                currencyLogo: "Rp ",
                                            }}
                                        />
                                    </FormGroup>
                                )}
                            </Col>
                            <Col xl="6" md="6" sm="6">
                                <span className="sub-title mb-2 d-block">
                                    Pre Order
                                </span>
                                <div className="mb-2">
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                defaultValue={
                                                    item.count_day < 1
                                                        ? false
                                                        : true
                                                }
                                                type="checkbox"
                                                id="checkbox2"
                                                onChange={handleCheckDuration}
                                            />{" "}
                                            Durasi (Hari)
                                        </Label>
                                    </FormGroup>
                                </div>
                                {formDuration && (
                                    <FormGroup>
                                        <FormText
                                            item={{
                                                defaultValue: "",
                                                type: "number",
                                                name: "day",
                                            }}
                                        />
                                    </FormGroup>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <div className="text-right">
                <Button
                    type="button"
                    color={color.secondary}
                    onClick={handleCreateProduct}
                >
                    Simpan
                </Button>
            </div>
        </Style>
    );
};
export default EditProduct;
