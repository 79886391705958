import React from "react";
import { Col, Row } from "reactstrap";
import Accordion from "../../../Components/Accordion";
import styled from "styled-components";

const Style = styled.div`
    .title-accordion {
        @media (max-width: 767px) {
            font-size: 20px;
            margin-bottom: 15px;
        }
        @media (max-width: 575px) {
            font-size: 18px;
        }
    }
    @media (max-width: 575px) {
        p {
            font-size: 14px;
        }
    }
`;
const TabLakugan = ({ item, id }) => {
    return (
        <Style>
            <Row>
                <Col xl="12">
                    <h3 className="title-accordion">{item.title}</h3>
                    {item.question_answer_list.map((value, key) => {
                        return <Accordion key={key} item={value} />;
                    })}
                </Col>
            </Row>
            {id + 1 !== item.length && <hr style={{ margin: "5rem 0" }} />}
        </Style>
    );
};
export default TabLakugan;
