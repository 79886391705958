import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import EditProduct from "../Cards/EditProduct";

const Detail = ({
    setShow = () => {},
    toggle = () => {},
    isShow = false,
    dataProduct,
    setAlert = () => {},
    updateProduct = () => {},
}) => {
    return (
        <Modal size="xl" toggle={toggle} isOpen={isShow}>
            <ModalHeader className="border-0 pb-0" toggle={toggle} />
            <ModalBody>
                <EditProduct
                    updateProduct={updateProduct}
                    isShow={isShow}
                    setShow={setShow}
                    setAlert={setAlert}
                    dataProduct={dataProduct}
                />
            </ModalBody>
        </Modal>
    );
};
export default Detail;
