import { servicesAction } from "../../../Reducers";

const service = {
    product: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/product/supplier",
            params: params,
            qs: true,
            method: "GET",
            reducer: "service",
            group: "product",
            token: true,
            key: "productList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Bad Request",
                },
            },
        });
    },
    commissionList: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/commission",
            method: "GET",
            token: true,
            reducer: "service",
            group: "commission",
            key: "commissionList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Commission List",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    category: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/category/?filters=[["type","=","0"]]`,
            method: "GET",
            params: params,
            qs: true,
            token: true,
            reducer: "service",
            group: "category",
            key: "category",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    updateProduct: ({ dispatch, data, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/product/${id}`,
            method: "PUT",
            token: true,
            data: data,
            reducer: "service",
            group: "product",
            key: "createProduct",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Produk Berhasil di Update!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    deleteSpec: ({ dispatch, id, productId }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/product/spec/del/${id}/${productId}`,
            method: "DELETE",
            token: true,
            reducer: "service",
            group: "product",
            key: "deleteSpec",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    deleteImage: ({ dispatch, productId, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/link/delete/${productId}/${id}`,
            method: "DELETE",
            token: true,
            reducer: "service",
            group: "product",
            key: "deleteImage",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    deleteSpecClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "product",
            key: "deleteSpec",
            type: "CLEAR",
        });
    },
    productClearList: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "product",
            key: "createProduct",
            type: "CLEAR",
        });
    },
    commissionListClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "commission",
            key: "commissionList",
            type: "CLEAR",
        });
    },
    updateProductClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "product",
            key: "createProduct",
            type: "CLEAR",
        });
    },
    productListClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "product",
            key: "productList",
            type: "CLEAR",
        });
    },
};
export default service;
