import React from "react";
import {
    Collapse,
    InputGroupText,
    InputGroup,
    Input,
    InputGroupAddon,
} from "reactstrap";
import Button from "../../../../Components/Particle/Button";
import { color } from "../../../../Utils/Variable";

const CategoryList = ({
    item,
    setSortFilter = () => {},
    onSortFilter = [],
    categoryFilters = [],
    setCategoryFilters = () => {},
}) => {
    const [isActive, setActive] = React.useState(0);
    const handleFilterCategory = (item) => {
        if (isActive === 0) {
            setCategoryFilters((prevstate) => {
                return categoryFilters.length < 1
                    ? [...prevstate, item.name]
                    : [...prevstate, item.name];
            });
            setSortFilter((prevstate) => {
                return onSortFilter.length < 1
                    ? [...prevstate, `["category.id","like","${item.id}"]`]
                    : [
                          ...prevstate,
                          `["or"],["category.id","like","${item.id}"]`,
                      ];
            });
            setActive(item.id);
        } else {
            const removeCategory = categoryFilters.filter(function (value) {
                let withOr = value !== item.name;
                let notWithOr = value !== item.name;
                return notWithOr ? withOr : notWithOr;
            });
            const removeFilter = onSortFilter.filter(function (value) {
                let withOr = value !== `["category.id","like","${item.id}"]`;
                let notWithOr =
                    value !== `["or"],["category.id","like","${item.id}"]`;
                return notWithOr ? withOr : notWithOr;
            });
            setCategoryFilters(removeCategory);
            setSortFilter(removeFilter);
            setActive(0);
        }
    };
    return (
        <div className="filter-value">
            <button
                type="button"
                className={`btn btn-filter ${
                    categoryFilters.filter((value) => value === item.name)
                        .length === 1
                        ? "active"
                        : ""
                }`}
                onClick={() => handleFilterCategory(item)}
            >
                {item.name}
            </button>
        </div>
    );
};
const Category = ({
    handleGetAllCategory = () => {},
    handleShowFilter = () => {},
    setCategoryFilters = () => {},
    handlerSearchCategory = () => {},
    setSortFilter = () => {},
    handleFilterAction = () => {},
    handleCloseCategory = () => {},
    setShowAllCategory = () => {},
    setOpenCategory = () => {},
    isShowFilter = true,
    showAllCategory = false,
    searchValueCategory = "",
    allCategory = false,
    categoryFilters = [],
    onSortFilter = [],
    openCategory = false,
    category = [],
}) => {
    return (
        <div
            style={{
                borderBottom: "1px solid #ddd",
                position: "relative",
            }}
        >
            {showAllCategory && (
                <div className="popup-category">
                    <div className="popup-category__head">
                        <div
                            style={{
                                marginRight: 10,
                            }}
                        >
                            Kategori
                        </div>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-search" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                // ref={inputEl}
                                onChange={({ target: { value } }) =>
                                    handlerSearchCategory(value)
                                }
                                defaultValue={searchValueCategory}
                                placeholder="Cari"
                                type="text"
                            />
                        </InputGroup>
                    </div>
                    <div className="popup-category__section">
                        <div className="filter-category">
                            {allCategory &&
                                allCategory
                                    .filter((item) => item.type === 0)
                                    .map((item, key) => {
                                        return (
                                            <CategoryList
                                                categoryFilters={
                                                    categoryFilters
                                                }
                                                setCategoryFilters={
                                                    setCategoryFilters
                                                }
                                                onSortFilter={onSortFilter}
                                                setSortFilter={setSortFilter}
                                                key={key}
                                                item={item}
                                            />
                                        );
                                    })}
                        </div>
                    </div>
                    <div className="popup-category__bottom">
                        <Button
                            size={"14px"}
                            color="outline-primary"
                            style={{
                                marginRight: 10,
                            }}
                            onClick={handleCloseCategory}
                        >
                            Batal
                        </Button>
                        <Button
                            size={"14px"}
                            color="primary"
                            onClick={() => setShowAllCategory(false)}
                        >
                            Simpan
                        </Button>
                    </div>
                </div>
            )}
            <div>
                <div
                    className={`label-category ${openCategory ? "active" : ""}`}
                    onClick={() => setOpenCategory(!openCategory)}
                >
                    Kategori
                    <i
                        className={`fas fa-angle-down ${
                            openCategory ? "active" : ""
                        }`}
                    ></i>
                </div>
            </div>
            <div className="filter-list" style={{ paddingBottom: 0 }}>
                <Collapse isOpen={openCategory}>
                    <div className="filter-category">
                        {category &&
                            category.length > 0 &&
                            category
                                .filter((item) => item.type === 0)
                                .map((item, key) => {
                                    return (
                                        <CategoryList
                                            categoryFilters={categoryFilters}
                                            setCategoryFilters={
                                                setCategoryFilters
                                            }
                                            onSortFilter={onSortFilter}
                                            setSortFilter={setSortFilter}
                                            key={key}
                                            item={item}
                                        />
                                    );
                                })}
                    </div>
                    <div className="text-right">
                        <button
                            onClick={handleGetAllCategory}
                            className="btn"
                            style={{
                                color: color.primary,
                            }}
                        >
                            Lihat Semua
                        </button>
                    </div>
                </Collapse>
            </div>
        </div>
    );
};
export default Category;
