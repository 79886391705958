import React, { Fragment } from "react";
import Content from "./content";
import styled from "styled-components";

const Style = styled.div`
    .main {
        .side {
            width: 20%;
            display: flex;
            justify-content: center;
            border-right: 1px solid #dedede;
        }
        .content {
            transition: all 0.25s ease;
            background: #f5f5fb;
        }
    }
    .card {
        border: unset;
    }
    @media (max-width: 450px) {
        .title-section {
            font-size: 16px;
        }
    }
`;

function Information() {
    return (
        <Fragment>
            <Style>
                <section>
                    <div className="main">
                        <div className="content">
                            <Content />
                        </div>
                    </div>
                </section>
            </Style>
        </Fragment>
    );
}

export default Information;
