import React from "react";
import { Link } from "react-router-dom";
import Style from "../index.style";
import Hero from "../../../../Components/Assets/v2/hero.png";

import NavBar from "../../NavBar";

const SectionStart = () => {
  
    return (
        <Style>
            <div className="header">
                <NavBar />
                <div className="wrapper-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="wrapper-header-column">
                                <h1 className="header1-primary">Bisa Mulai Bisnis <br/> Tanpa Modal!</h1>
                                <h4 className="header4-primary">Mau jadi reseller di <strong>Lakugan?</strong></h4>
                                <Link
                                    to={`/signup/reseller`}
                                    className="btn btn-warning btn-register"
                                >
                                    Daftar Sekarang
                                </Link>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <img src={Hero} alt="hero" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer"></div>
        </Style>
    );
};
export default SectionStart;
