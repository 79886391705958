import React from "react";
import Testimon1 from "../../../Components/Assets/Images/testimoni1.png";
import Testimon2 from "../../../Components/Assets/Images/testimoni5.jpeg";
import Testimon3 from "../../../Components/Assets/Images/testimoni3.png";
import Testimon4 from "../../../Components/Assets/Images/testimoni4.jpeg";
import PictOne from "../../../Components/Assets/Product/PictOne.png";
import PictTwo from "../../../Components/Assets/Product/PictTwo.png";

import PictFive from "../../../Components/Assets/Product/PictFive.png";
import Background2 from "../../../Components/Assets/Images/testimoni_2.jpg";
import Background3 from "../../../Components/Assets/Images/testimoni_3.jpg";
import Background4 from "../../../Components/Assets/Images/testimoni_6.jpg";
import Background5 from "../../../Components/Assets/Images/testimoni_5.jpg";
export const sectionBottom = [
    {
        title: "Memperkenalkan produk di katalog",
        desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis adipisci maiores nobis cupiditate, sequi deserunt tempore nihil laudantium quam neque alias amet autem, quos at, architecto molestias obcaecati similique? Quo?",
    },
    {
        title: "Melayanin transaksi dari setiap pelanggan",
        desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis adipisci maiores nobis cupiditate, sequi deserunt tempore nihil laudantium quam neque alias amet autem, quos at, architecto molestias obcaecati similique? Quo?",
    },
    {
        title: "Interaktif dan membantu kendala para pelanggan",
        desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis adipisci maiores nobis cupiditate, sequi deserunt tempore nihil laudantium quam neque alias amet autem, quos at, architecto molestias obcaecati similique? Quo?",
    },
];

export const sectionTop = [
    {
        image: PictTwo,
        title: "Share Link Barang Daganganmu",
        render: () => {
            return (
                <h6 className="col-title text-center">
                    Share Link
                    <br />
                    <b>Barang Daganganmu</b>
                </h6>
            );
        },
        desc: "Bebas pilih barang daganganmu sendiri sesuai dengan yang disediakan oleh Lakugan dari berbagai macam supplier produk. Share link produknya ke pelangganmu atau orang terdekatmu.",
    },
    {
        image: PictFive,
        title: "Pembeli Bebas Pilih Produk dan Metode Pembayaran",
        render: () => {
            return (
                <h6 className="col-title text-center">
                    Pembeli <b>Bebas Pilih</b>
                    <br />
                    Produk dan Metode Pembayaran
                </h6>
            );
        },
        desc: "Pembeli membuka link produk yang dikirimkan oleh reseller, cek warna deskripsi serta ketersediaan stok barang, lengkapi data pembelian, pilih metode pembayaran dan pelanggan dapat langsung melakukan pembayaran.",
    },
    {
        image: PictOne,
        title: "Barang Akan Dikirim Langsung Oleh Sistem",
        render: () => {
            return (
                <h6 className="col-title text-center">
                    Barang Akan <b>Dikirim Langsung</b>
                    <br />
                    Oleh Sistem
                </h6>
            );
        },
        desc: "Setelah pembayaran dilakukan, barang otomatis akan dikemas oleh supplier kami dan langsung di proses kirim ke pelanggan. Sistem pengiriman sudah terintegrasi sehingga pelacakan pesananpun mudah dipantau.Setelah pembayaran dilakukan, barang otomatis akan dikemas oleh supplier kami dan langsung di proses kirim ke pelanggan. Sistem pengiriman sudah terintegrasi sehingga pelacakan pesanan pun mudah dipantau.",
    },
];
export const testimoni = [
    {
        background: Background5,
        name: "Rizal",
        status: "Pengusaha",
        image: Testimon1,
        desc: "Caranya mudah dan sangat membantu banget, Terima kasih Lakugan",
    },
    {
        background: Background2,
        name: "Ika",
        status: "Ibu Rumah Tangga",
        image: Testimon2,
        desc: "Begitu coba langsung dapat uang, cocok banget untuk ibu rumah tangga punya bisnis, tetep bisa urus anak dirumah tanpa terganggu",
    },
    {
        background: Background3,
        name: "Wahyu",
        status: "Mahasiswa",
        image: Testimon3,
        desc: "Lumayan, selain bisa cari uang tambahan bisa mulai belajar bisnis juga",
    },
    {
        background: Background4,
        name: "Nina Wantina",
        status: "Karyawan Swasta",
        image: Testimon4,
        desc: "Akhirnya bisa punya bisnis sampingan yang enak,nggak pake modal,nggak repot dan yang terpenting nggak ganggu kerjaan kantor",
    },
];
