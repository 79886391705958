import React from "react";
import FormText from "../../../../Components/Particle/Form/FormText";
import Select from "react-select";
// import LocalStorage from "../../../../Utils/LocalStorage";
import { Collapse, Row, Col, FormGroup } from "reactstrap";
import styled from "styled-components";
import Form from "../../../../Components/Molekul/FormValidation";
import ImageUpload from "../../../../Components/Particle/Form/UploadImage";
import FormHTML from "../../../../Components/Particle/Form/FormHTML";

const Style = styled.div`
  border-bottom: 1px solid #ddd;
  .btn-accordion {
    background: transparent;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 0;
    border: none;
    margin-bottom: 10px;
    font-weight: 500;
    text-align: left;
    @media (max-width: 499px) {
      font-size: 14px;
    }
    &:focus {
      box-shadow: none;
      outline: none;
    }
    .fa-angle-down {
      transition: all 0.5s ease;
      transform: rotate(${(props) => (props.active ? "180deg" : "0")});
    }
  }
  p {
    @media (max-width: 499px) {
      font-size: 12px;
    }
  }
`;

const Store = ({
  avatar = "",
  setAvatar = () => {},
  setDescStore = () => {},
  handleGetCity = () => {},
  handleGetDistrict = () => {},
  handleGetValueDistrict = () => {},
  handleGetValueArea = () => {},
  city = [],
  district = [],
  address = "",
  area = [],
  province = [],
  setAddress = () => {},
  valueProvince = undefined,
  valueCity = undefined,
  valueDistrict = undefined,
  valueArea = undefined,
  postCode = "",
  descStore = "",
  isOpenCollapse = false,
  form = [],
  validationClick = true,
  validateForm = true,
  setForm = () => {},
  onCheckValue = () => {},
  setFormAddress = () => {},
  formAddress = {},
  descriptionStore = {},
  setDescriptionStore = () => {},
}) => {
  // const token = LocalStorage().get("auth");
  const [formImage, setFormImage] = React.useState({
    heightStyle: "155px",
    widthStyle: "155px",
    avatar: true,
    name: "photo_profile",
    placeholder: avatar === null || !avatar ? "Pilih Foto" : "Ganti Foto",
    valid: "Harap masukkan gambar",
    value: avatar,
    status: avatar === "" ? false : true,
    normalData: false,
  });

  // const auth = LocalStorage().get(`${token.access_token}`);
  const [isOpen, setIsOpen] = React.useState(isOpenCollapse);

  const toggle = () => setIsOpen(!isOpen);
  const onGetValue = (value) => {
    setAvatar(value.value);
    formImage.status = true;
    formImage.value = value.value;
    setFormImage(formImage);
  };
  const onGetValueDescStore = (obj) => {
    setDescStore(obj.value);
    if (obj.value.replace(/(<([^>]+)>)/gi, "") === "") {
      descriptionStore.status = false;
    } else {
      descriptionStore.status = true;
    }
    setDescriptionStore(descriptionStore);
  };
  const onGetValueAddress = (obj) => {
    setAddress(obj.value);
    formAddress.value = obj.value;
    if (
      obj.value === "" ||
      obj.value.length < formAddress.character.min.length
    ) {
      formAddress.status = false;
    } else {
      formAddress.status = true;
    }

    setFormAddress(formAddress);
  };
  return (
    <Style active={isOpen}>
      <button onClick={toggle} className="btn-accordion">
        <div>
          Informasi Toko ({" "}
          <i className="text-warning" style={{ fontSize: 14 }}>
            Data informasi toko ini digunakan untuk penjemputan barang oleh
            shipper
          </i>{" "}
          )
        </div>
        <i className="fas fa-angle-down"></i>
      </button>
      <Collapse isOpen={isOpen}>
        <Row>
          <Col xl="4" lg="4" md="6" sm="12">
            <ImageUpload
              isCrop={true}
              onGetValue={onGetValue}
              validateForm={validateForm}
              validationClick={validationClick}
              item={formImage}
            />
          </Col>
          <Col xl="8" lg="8" md="6" sm="12">
            <Form
              onCheckValue={onCheckValue}
              setForm={setForm}
              validateForm={validateForm}
              validationClick={validationClick}
              form={form}
            />
          </Col>
          <Col xl="12">
            <Row>
              <Col xl="4" md="4" sm="12">
                <label htmlFor="">
                  Provinsi
                  <span className="text-danger">*</span>
                </label>
                <Select
                  placeholder="Pilih"
                  onChange={(e) => handleGetCity(e)}
                  options={province}
                  value={valueProvince}
                />
                {valueProvince === undefined ||
                  (valueProvince?.length < 1 && !validateForm && (
                    <small className="text-danger">
                      <i>Provinsi harus diisi</i>
                    </small>
                  ))}
              </Col>
              <Col xl="4" md="4" sm="12">
                <FormGroup>
                  <label htmlFor="">
                    Kota
                    <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Pilih"
                    onChange={(e) => handleGetDistrict(e)}
                    options={city}
                    value={valueCity}
                  />
                  {valueCity === undefined && !validateForm ? (
                    <small className="text-danger">
                      <i>Kota harus diisi</i>
                    </small>
                  ) : (
                    valueCity?.length === 0 &&
                    !validateForm && (
                      <small className="text-danger">
                        <i>Kota harus diisi</i>
                      </small>
                    )
                  )}
                </FormGroup>
              </Col>
              <Col xl="4" md="4" sm="12">
                <FormGroup>
                  <label htmlFor="">
                    Kecamatan
                    <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Pilih"
                    onChange={(e) => handleGetValueDistrict(e)}
                    value={valueDistrict}
                    options={district}
                  />
                  {valueDistrict === undefined && !validateForm ? (
                    <small className="text-danger">
                      <i>Kecamatan harus diisi</i>
                    </small>
                  ) : (
                    valueDistrict?.length === 0 &&
                    !validateForm && (
                      <small className="text-danger">
                        <i>Kecamatan harus diisi</i>
                      </small>
                    )
                  )}
                </FormGroup>
              </Col>
              <Col xl="4" md="4" sm="12">
                <FormGroup>
                  <label htmlFor="">
                    Kelurahan
                    <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Pilih"
                    onChange={(e) => handleGetValueArea(e)}
                    value={valueArea}
                    options={area}
                  />
                  {valueArea === undefined && !validateForm ? (
                    <small className="text-danger">
                      <i>Kelurahan harus diisi</i>
                    </small>
                  ) : (
                    valueArea?.length === 0 &&
                    !validateForm && (
                      <small className="text-danger">
                        <i>Kelurahan harus diisi</i>
                      </small>
                    )
                  )}
                </FormGroup>
              </Col>
              <Col xl="4" md="4" sm="12">
                <FormText
                  validateForm={validateForm}
                  validationClick={validationClick}
                  item={{
                    readOnly: true,
                    label: "Kode pos",
                    type: "number",
                    required: true,
                    action: "text",
                    name: "post-code",
                    valid: "Kode pos tidak boleh kosong",
                    value: postCode,
                    status: postCode === "" ? false : true,
                    defaultValue: postCode,
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="6" sm="12">
                <FormText
                  validationClick={validationClick}
                  validateForm={validateForm}
                  onGetValue={onGetValueAddress}
                  item={formAddress}
                />
              </Col>
              <Col xl="6" lg="6" md="6" sm="12">
                <FormHTML
                  onGetValue={onGetValueDescStore}
                  validateForm={validateForm}
                  validationClick={validationClick}
                  item={descriptionStore}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Collapse>
    </Style>
  );
};
export default Store;
