import React, { useState } from "react";
import Modal from "react-modal";
// import { DeliveryStyle } from "./index.style";
import { formatMoney } from "../../../../../Utils/PriceConverter";
import IconRight from "../../../../../Components/Assets/icon/arrow-step-right.svg";
import IconBack from "../../../../../Components/Assets/icon/arrow-left.svg";
import Tiki from "../../../../../Components/Assets/icon/tiki.png";
import SiCepat from "../../../../../Components/Assets/icon/sicepat.png";
import Anteraja from "../../../../../Components/Assets/icon/anteraja.png";
import JNE from "../../../../../Components/Assets/icon/jne.png";
import LionParcel from "../../../../../Components/Assets/icon/lion-parcel.png";
import Ninja from "../../../../../Components/Assets/icon/ninja.png";
import Wahana from "../../../../../Components/Assets/icon/wahana.png";
import RPX from "../../../../../Components/Assets/icon/rpx.png";
import Grab from "../../../../../Components/Assets/icon/grab.png";
import AlfaTrex from "../../../../../Components/Assets/icon/alfatrex.png";

const customStyles = {
    content: {
        position: 'fixed',
        padding: '0',
        margin: 'auto 0',
        width: '100%',
        height: '100%',
        inset: '0',
    },
};

const ModalDelivery = ({
    shipperList = false,
    biteshipList = false,
    selectCourier = false,
    courierImage = null,
    courierEstimateTime = null,
    handleGetCourier = () => { },
    setCourierRate,
    setShipperUsed = false,
    setBiteshipUsed= false,
    setCourierImage,
    setCourierEstimateTime = null,
    setSelectCourier,
    setPriceInsurance,
    setTypeShipper,
    setHeaderShow = () => {},
    headerShow,
    setDeliveryMobile = () => {},
}) => {
    const [modalShow, setModalShow] = useState(false);
    const [courierState] = useState("Pilih Opsi Pengiriman");

    let date = new Date();
    let dayToday = date.getDate();
    const listMonth = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    let month = listMonth[date.getUTCMonth()];
    let year = date.getFullYear();

    const handleShowModal = () => {
        setModalShow(!modalShow);
        setHeaderShow(!headerShow);
    }
    const handleCombined = (item) => {
        setCourierRate(item.price);
        setBiteshipUsed(item)
        setCourierImage(item);
        setCourierEstimateTime(item);
        setSelectCourier(true);
        setPriceInsurance(item);
        setTypeShipper(item);
        if (item.compulsory_insurance === 1) {
            setPriceInsurance(item);
        }
        setModalShow(false);
        setHeaderShow(!headerShow);
        setDeliveryMobile(true);
    }

    return (
        <div style={{borderTop: "1.5px solid #e8e8e8",
            borderBottom: "1.5px solid #e8e8e8",
            padding: "16px 0",
            margin: "16px 0"}}>
            <div onClick={handleShowModal} style={{fontWeight:600}}>
                {selectCourier === false ?
                    <div className="d-flex justify-content-between px-3 h5">
                        {courierState}
                        <img src={IconRight} alt="" />
                    </div>
                    :
                    <div className="d-flex px-3 justify-content-between">
                        {(
                            () => {
                                if (courierImage.company == "jne") {
                                    return (
                                        <img src={JNE} alt="" style={{maxWidth:"25%", objectFit:"contain"}}/>
                                    )
                                } else if (courierImage.company == "tiki") {
                                    return (
                                        <img src={Tiki} alt="" style={{maxWidth:"25%", objectFit:"contain"}}/>
                                    )
                                } else if (courierImage.company == "sicepat") {
                                    return (
                                        <img src={SiCepat} alt="" style={{maxWidth:"25%", objectFit:"contain"}}/>
                                    )
                                } else if (courierImage.company == "anteraja") {
                                    return (
                                        <img src={Anteraja} alt="" style={{maxWidth:"25%", objectFit:"contain"}}/>
                                    )
                                } else if (courierImage.company == "lion") {
                                    return (
                                        <img src={LionParcel} alt="" style={{maxWidth:"25%", objectFit:"contain"}}/>
                                    )
                                } else if (courierImage.company == "wahana") {
                                    return (
                                        <img src={Wahana} alt="" style={{maxWidth:"25%", objectFit:"contain"}}/>
                                    )
                                } else if (courierImage.company == "ninja") {
                                    return (
                                        <img src={Ninja} alt="" style={{maxWidth:"25%", objectFit:"contain"}}/>
                                    )
                                } else if (courierImage.company == "rpx") {
                                    return (
                                        <img src={RPX} alt="" style={{maxWidth:"25%", objectFit:"contain"}}/>
                                    )
                                } else if (courierImage.company == "grab") {
                                    return (
                                        <img src={Grab} alt="" style={{maxWidth:"25%", objectFit:"contain"}}/>
                                    )
                                } else if (courierImage.company == "alfatrex") {
                                    return (
                                        <img src={AlfaTrex} alt="" style={{maxWidth:"25%", objectFit:"contain"}}/>
                                    )
                                }
                            }
                        )()}
                        {courierEstimateTime !== null && <div>Estimasi {courierEstimateTime?.shipment_duration_range} hari</div>}
                        <p className="my-auto mx-0" style={{color:"#000A8C"}}>{formatMoney(courierEstimateTime?.price)}</p>
                        <img src={IconRight} alt="" />
                    </div>    
                }
            </div>
            <Modal
                isOpen={modalShow}
                onRequestClose={handleShowModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="d-flex justify-content-between my-3 py-3 px-2" style={{boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.08)", borderRadius:"0 0 8px 8px"}}>
                    <img src={IconBack} alt="" onClick={handleShowModal}/>
                    <h3 style={{ fontWeight: 700, fontSize: 20, paddingRight:"20%" }}>Opsi Pengiriman</h3>
                </div>
                <div>
                    <div style={{ width: "100%", overflow: "visible" }}>
                    {biteshipList?.length > 0 && (
                        <div className="label-type">
                            {biteshipList?.map((item, key) => {
                                let image = item?.logo_url
                                if (item.company == "jne") {
                                    image=JNE
                                } else if (item.company == "tiki") {
                                    image=Tiki
                                } else if (item.company == "sicepat") {
                                    image=SiCepat
                                } else if (item.company == "anteraja") {
                                    image=Anteraja
                                } else if (item.company == "lion") {
                                    image=LionParcel
                                } else if (item.company == "wahana") {
                                    image=Wahana
                                } else if (item.company == "ninja") {
                                    image=Ninja
                                } else if (item.company == "rpx") {
                                   image=RPX
                                } else if (item.company == "grab") {
                                    image=Grab
                                } else if (item.company == "alfatrex") {
                                    image=AlfaTrex
                                }
                                return (
                                    <div key={key} onClick={() => handleCombined(item)} className="d-flex flex-row">
                                        <div className="d-flex m-2 p-2" style={{border:"1px solid #e8e8e8", borderRadius:10}}>
                                            <img src={image} alt="" style={{maxWidth: "20%",objectFit: "contain"}} />
                                            <div className="d-flex flex-column py-2 px-2">
                                                <div className="d-flex flex-row justify-content-between">
                                                    <p className="m-0 h6 font-bold">{item.courier_name}{" "}{item.courier_service_name}</p>
                                                    &nbsp;
                                                    <p className="m-0 h6 font-bold">{formatMoney(item?.price)}</p>
                                                </div>
                                                <p className="m-0 pt-2 font-light">Estimasi {item.shipment_duration_range} hari</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    )}
                        {/* {shipperList?.rates?.logistic["express"].length > 0 && (
                            <div className="label-type">
                                {shipperList?.rates?.logistic["express"].map((item, key) => {
                                    return (
                                        <div key={key} onClick={() => handleCombined(item)} className="d-flex flex-row">
                                            <div className="d-flex m-2 p-2" style={{border:"1px solid #e8e8e8", borderRadius:10}}>
                                                <img src={item?.logo_url} alt="" style={{maxWidth: "20%",objectFit: "contain"}} />
                                                <div className="d-flex flex-column py-2 px-2">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <p className="m-0 h6 font-bold">{item.name}{" "}Express</p>
                                                        <p className="m-0 h6 font-bold">{formatMoney(item?.finalRate)}</p>
                                                    </div>
                                                    {item?.min_day === item?.max_day ?
                                                    <p className="m-0 pt-2 font-light">Estimasi Barang sampai pada tanggal {dayToday+item?.max_day} {month} {year} (1 Hari Kerja)</p>
                                                    :
                                                    <p className="m-0 pt-2 font-light">Estimasi Barang sampai pada tanggal {dayToday+item?.min_day}-{dayToday+item?.max_day} {month} {year} ({item?.min_day}-{item?.max_day} Hari Kerja)</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                        {shipperList?.rates?.logistic["instant"].length > 0 && (
                            <div className="label-type">
                                {shipperList?.rates?.logistic["instant"].map((item, key) => {
                                    return (
                                        <div key={key} onClick={() => handleCombined(item)} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "12px 0" }}>
                                            <div className="d-flex m-2 p-2" style={{border:"1px solid #e8e8e8", borderRadius:10}}>
                                                <img src={item?.logo_url} alt="" style={{maxWidth: "20%",objectFit: "contain"}} />
                                                <div className="d-flex flex-column py-2 px-2">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <p className="m-0 h6 font-bold">{item.name}{" "}Instant</p>
                                                        <p className="m-0 h6 font-bold">{formatMoney(item?.finalRate)}</p>
                                                    </div>
                                                    {item?.min_day === item?.max_day ?
                                                    <p className="m-0 pt-2 font-light">Estimasi Barang sampai pada tanggal {dayToday+item?.max_day} {month} {year} (1 Hari Kerja)</p>
                                                    :
                                                    <p className="m-0 pt-2 font-light">Estimasi Barang sampai pada tanggal {dayToday+item?.min_day}-{dayToday+item?.max_day} {month} {year} ({item?.min_day}-{item?.max_day} Hari Kerja)</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                        {shipperList?.rates?.logistic["regular"].length > 0 && (
                            <div>
                                {shipperList?.rates?.logistic["regular"].map((item, key) => {
                                    return (
                                        <div key={key} onClick={() => handleCombined(item)} className="mx-2">
                                            <div className="d-flex m-2 p-2" style={{border:"1px solid #e8e8e8", borderRadius:10}}>
                                                <img src={item?.logo_url} alt="" style={{maxWidth: "20%",objectFit: "contain"}} />
                                                <div className="d-flex flex-column py-2 px-2">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <p className="m-0 h6 font-bold">{item?.name} Reg</p>
                                                        <p className="m-0 h6 font-bold">{formatMoney(item?.finalRate)}</p>
                                                    </div>
                                                    {item?.min_day === item?.max_day ?
                                                    <p className="m-0 pt-2 font-light">Estimasi Barang sampai pada tanggal {dayToday+item?.max_day} {month} {year} (1 Hari Kerja)</p>
                                                    :
                                                    <p className="m-0 pt-2 font-light">Estimasi Barang sampai pada tanggal {dayToday+item?.min_day}-{dayToday+item?.max_day} {month} {year} ({item?.min_day}-{item?.max_day} Hari Kerja)</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                        {shipperList?.rates?.logistic["same day"].length > 0 && (
                            <div className="label-type">
                                {shipperList?.rates?.logistic["same day"].map((item, key) => {
                                    return (
                                        <div key={key} onClick={() => handleCombined(item)} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "12px 0" }}>
                                            <div className="d-flex m-2 p-2" style={{border:"1px solid #e8e8e8", borderRadius:10}}>
                                                <img src={item?.logo_url} alt="" style={{maxWidth: "20%",objectFit: "contain"}} />
                                                <div className="d-flex flex-column py-2 px-2">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <p className="m-0 h6 font-bold">{item.name}{" "}Same{" "}Day</p>
                                                        <p className="m-0 h6 font-bold">{formatMoney(item?.finalRate)}</p>
                                                    </div>
                                                    {item?.min_day === item?.max_day ?
                                                    <p className="m-0 pt-2 font-light">Estimasi Barang sampai pada tanggal {dayToday+item?.max_day} {month} {year} (1 Hari Kerja)</p>
                                                    :
                                                    <p className="m-0 pt-2 font-light">Estimasi Barang sampai pada tanggal {dayToday+item?.min_day}-{dayToday+item?.max_day} {month} {year} ({item?.min_day}-{item?.max_day} Hari Kerja)</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                        {shipperList?.rates?.logistic["trucking"].length > 0 && (
                            <div className="label-type">
                                {shipperList?.rates?.logistic["trucking"].map((item, key) => {
                                    return (
                                        <div key={key} onClick={() => handleCombined(item)} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "12px 0" }}>
                                            <div className="d-flex m-2 p-2" style={{border:"1px solid #e8e8e8", borderRadius:10}}>
                                                <img src={item?.logo_url} alt="" style={{maxWidth: "20%",objectFit: "contain"}} />
                                                <div className="d-flex flex-column py-2 px-2">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <p className="m-0 h6 font-bold">{item?.name} Trucking</p>
                                                        <p className="m-0 h6 font-bold">{formatMoney(item?.finalRate)}</p>
                                                    </div>
                                                    {item?.min_day === item?.max_day ?
                                                    <p className="m-0 pt-2 font-light">Estimasi Barang sampai pada tanggal {dayToday+item?.max_day} {month} {year} (1 Hari Kerja)</p>
                                                    :
                                                    <p className="m-0 pt-2 font-light">Estimasi Barang sampai pada tanggal {dayToday+item?.min_day}-{dayToday+item?.max_day} {month} {year} ({item?.min_day}-{item?.max_day} Hari Kerja)</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )} */}
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default ModalDelivery;