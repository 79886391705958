import styled from "styled-components";

const Style = styled.div`
    .card {
        margin-bottom: 20px;
        border: unset;
        display: flex;
        flex-direction: column;
        background: #fff;
        .balance {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 767px) {
                position: unset;
                justify-content: start;
            }
        }
        .title {
            margin: 0;
        }
        .sub-title {
            color: #aaa;
            font-size: 14px;
            padding-bottom: 5px;
            margin-bottom: 17px;
            border-bottom: 1px solid #ddd;
        }
        .text {
            &-provisions {
                font-size: 12px;
            }
            &-balance {
                font-size: 30px;
            }
            &-location {
                margin: 0;
            }
        }
        .location {
            display: flex;
            align-items: center;
        }
    }
    .product-desc {
        margin-bottom: 50px;
    }
    .btn {
        padding: 7px 20px;
        border-radius: 5px;
    }
    .label-specification {
        color: #aaa;
        font-size: 14px;
        padding-bottom: 5px;
        margin-bottom: 17px;
    }
    .fa-trash-alt {
        transition: all 0.25s ease;
        color: #888888;
        &:hover {
            color: #dc3545;
        }
    }
    .form-spec {
        min-width: 100px;
    }
    .valid-text {
        position: absolute;
        z-index: 2;
        bottom: 71px;
        left: 10px;
    }
`;
export default Style;
