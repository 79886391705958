import React from "react";
import TabLink from "../../../../Components/TabLink";

const Tab = () => {
    const tabLink = [
        {
            name: "Menunggu Konfirmasi",
            link: "/reseller/transaction/order-list",
            icon: "fas fa-shopping-cart mr-2",
        },
        // {
        //     name: "Pengemasan",
        //     link: "/reseller/transaction/packaging",
        //     icon: "fas fa-box mr-2",
        // },
        {
            name: "Dikirim",
            link: "/reseller/transaction/send-item",
            icon: "fas fa-truck mr-2",
        },
        {
            name: "Barang sampai",
            link: "/reseller/transaction/barang-sampai",
            icon: "fas fa-map-marker-alt mr-2",
        },
        {
            name: "Selesai",
            link: "/reseller/transaction/done",
            icon: "fas fa-flag-checkered mr-2",
        },
        // {
        //     name: "Konfirmasi",
        //     link: "/reseller/transaction/confirmation",
        //     icon: "far fa-check-circle mr-2",
        // },
        {
            name: "Batal",
            link: "/reseller/transaction/cancel",
            icon: "far fa-times-circle mr-2",
        },
        {
            name: "Keluhan",
            link: "/reseller/transaction/complaint",
            icon: "far fa-comment-dots mr-2",
        },
    ];
    return <TabLink data={tabLink} />;
};
export default Tab;
