/* eslint-disable */
import React from "react";
import {
    Col,
    FormGroup,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Collapse,
    Table,
    Alert,
} from "reactstrap";
import styled from "styled-components";
import { color } from "../../../../Utils/Variable";
import Button from "../../../../Components/Particle/Button";
import { formatMoney } from "../../../../Utils/PriceConverter";
import Slider from "react-slick";
import { useStore } from "../../../../Reducers";
import service from "../index.service";

const Style = styled(Modal)`
    .slick-prev {
        left: 0;
        z-index: 3;
        &:before {
            font-size: 30px;
            color: ${color.primary};
        }
    }
    .slick-next {
        right: 8px;
        &:before {
            font-size: 30px;
            color: ${color.primary};
        }
    }
    .sub-title {
        color: #848484;
        font-size: 14px;
    }
    .img-product {
        width: 250px;
        object-fit: contain;
        display: block;
        margin: auto;
    }
    .table {
        th,
        td {
            @media (max-width: 450px) {
                font-size: 12px;
            }
        }
    }
    .product {
        text-align: center;
        &-name {
            color: #4a4a4a;
            @media (max-width: 450px) {
                font-size: 16px;
            }
        }
        &-price {
            color: ${color.primary};
            font-size: 24px;
            font-weight: bold;
            @media (max-width: 450px) {
                font-size: 16px;
            }
        }
        &-label {
            color: #aaa;
            font-size: 14px;
            width: 100px;
            @media (max-width: 450px) {
                font-size: 12px;
            }
        }

        &-description {
            border-bottom: 1px solid #ddd;
            margin-bottom: 60px;
            .btn-accordion {
                background: transparent;
                display: flex;
                width: 100%;
                justify-content: space-between;
                padding: 10px 0;
                border: none;
                margin-bottom: 10px;
                font-weight: 500;
                margin: 0;
                &:focus {
                    box-shadow: none;
                    outline: none;
                }
                .fa-angle-down {
                    transition: all 0.5s ease;
                    transform: rotate(
                        ${(props) =>
                            props.activecollapse === "true" ? "180deg" : "0"}
                    );
                }
                @media (max-width: 499px) {
                    font-size: 14px;
                }
            }
            p {
                @media (max-width: 499px) {
                    font-size: 12px;
                }
            }
        }
        &-category {
            display: flex;
            align-items: center;
            label,
            p {
                margin: 0;
                margin-right: 10px;
                @media (max-width: 450px) {
                    font-size: 12px;
                }
            }
            .dots {
                margin-right: 5px;
                color: #aaa;
            }
        }
        &-total {
            &__stock {
                font-weight: 500;
            }
        }
    }
`;
const ProductDetail = ({
    totalCommission = {},
    code = "",
    item = false,
    store = {},
    title = "Tambah Produk",
    isShow = false,
    myProduct = false,
    toggle = () => {},
    handleModalDelete = () => {},
    isFailed = false,
    setShow = () => {},
}) => {
    const { dispatch, state } = useStore();
    const [isOpen, setIsOpen] = React.useState(false);
    const handleCollapse = () => setIsOpen(!isOpen);
    const settings = {
        customPaging: function (i) {
            return (
                <a>
                    <span className="rectangle"></span>
                </a>
            );
        },
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    class TravellerCollection extends Array {
        sum(key) {
            return this.reduce((a, b) => a + (b[key] || 0), 0);
        }
    }
    const traveler = new TravellerCollection(...item.spec);
    const handleAddProduct = () => {
        let id = item.id;
        service.addProduct({ dispatch, id });
    };
    React.useEffect(() => {
        if (state?.reseller?.addProduct?.isSuccess) {
            service.productList({ dispatch });
            setShow(false);
            service.addProductClear({ dispatch });
        }
        if (state?.reseller?.addProduct?.isError) {
            service.addProductClear({ dispatch });
        }
    }, [state.reseller.addProduct]);
    return (
        <Style
            activecollapse={isOpen.toString()}
            size="lg"
            isOpen={isShow}
            toggle={toggle}
        >
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            {item && (
                <ModalBody>
                    <Row>
                        <Col xl="12">
                            <div className="mb-3">
                                {isFailed && (
                                    <Alert
                                        color="danger"
                                        className="text-center"
                                    >
                                        Terjadi kesalahan, silakan mencoba
                                        beberapa saat lagi, terimakasih.
                                    </Alert>
                                )}
                                <Slider {...settings}>
                                    {item.product_image.map((item, key) => {
                                        return (
                                            <div key={key}>
                                                <Col>
                                                    <img
                                                        src={item.img_url}
                                                        alt={item.name}
                                                        className="img-fluid img-product"
                                                    />
                                                </Col>
                                            </div>
                                        );
                                    })}
                                </Slider>
                            </div>
                        </Col>
                        <Col xl="12">
                            <div className="product mt-4">
                                <h2 className="product-name">{item.name}</h2>
                                <p className="product-price">
                                    {formatMoney(item.price)}
                                </p>
                            </div>
                        </Col>

                        <Col xl="12">
                            <FormGroup>
                                <div className="product-category">
                                    <label htmlFor="" className="product-label">
                                        Supplier
                                    </label>
                                    <span className="dots">:</span>
                                    <p>{store?.name}</p>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="product-category">
                                    <label htmlFor="" className="product-label">
                                        Telepon
                                    </label>
                                    <span className="dots">:</span>
                                    <p>{store?.profile?.phone_number}</p>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="product-category">
                                    <label htmlFor="" className="product-label">
                                        Kategori
                                    </label>
                                    <span className="dots">:</span>
                                    <p>{item.category.name}</p>
                                </div>
                            </FormGroup>
                        </Col>
                        {item.supplier && (
                            <Col xl="12">
                                <FormGroup>
                                    <h6>Supplier</h6>
                                    <div className="product-category">
                                        <label
                                            htmlFor=""
                                            className="product-label"
                                        >
                                            Nama toko
                                        </label>
                                        <span className="dots">:</span>
                                        <p>{item.supplier.name}</p>
                                    </div>
                                    <div className="product-category">
                                        <label
                                            htmlFor=""
                                            className="product-label"
                                        >
                                            Alamat
                                        </label>
                                        <span className="dots">:</span>
                                        <p>{item.supplier.address}</p>
                                    </div>
                                </FormGroup>
                            </Col>
                        )}
                        <Col cl="12">
                            <FormGroup>
                                <div className="product-category">
                                    <label htmlFor="" className="product-label">
                                        Komisi
                                    </label>
                                    <span className="dots">:</span>
                                    <p>
                                        {formatMoney(item.commission_product)}
                                    </p>
                                </div>
                            </FormGroup>
                        </Col>
                        {item.pre_order && (
                            <Col cl="12">
                                <FormGroup>
                                    <div className="product-category">
                                        <label
                                            htmlFor=""
                                            className="product-label"
                                        >
                                            Pre Order
                                        </label>
                                        <span className="dots">:</span>
                                        <p>{item.count_day} Hari</p>
                                    </div>
                                </FormGroup>
                            </Col>
                        )}
                        {item.spec.length > 0 && (
                            <Col xl="12">
                                <label
                                    htmlFor=""
                                    className="product-label w-100"
                                >
                                    Spesifikasi yang tersedia :
                                </label>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Warna</th>
                                            <th>Ukuran</th>
                                            <th>Stok</th>
                                            <th>Berat</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {item.spec.map((item, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{item.color}</td>
                                                    <td>{item.size}</td>
                                                    <td>{item.total_stock}</td>
                                                    <td>{item.weight} kg</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        )}
                        <Col xl="12">
                            <FormGroup>
                                <div className="product-category">
                                    <label htmlFor="" className="product-label">
                                        Total Stok :
                                    </label>
                                    <div>
                                        <p
                                            className={`product-total__stock ${
                                                traveler.sum("total_stock") < 11
                                                    ? "text-danger"
                                                    : traveler.sum(
                                                          "total_stock"
                                                      ) < 25
                                                    ? "text-warning"
                                                    : "text-success"
                                            }`}
                                        >
                                            {traveler.sum("total_stock")}{" "}
                                            <span>item</span>
                                        </p>
                                    </div>
                                </div>
                            </FormGroup>
                        </Col>

                        <Col xl="12">
                            <div className="product-description">
                                <button
                                    onClick={handleCollapse}
                                    className="btn-accordion"
                                >
                                    Deskripsi
                                    <i className="fas fa-angle-down"></i>
                                </button>
                                <Collapse isOpen={isOpen}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: item.description,
                                        }}
                                    />
                                </Collapse>
                            </div>
                        </Col>
                    </Row>
                    {!myProduct ? (
                        <div className="d-flex justify-content-between">
                            <Button type="button" onClick={toggle}>
                                Tutup
                            </Button>
                            <Button
                                type="button"
                                color="primary"
                                onClick={handleAddProduct}
                            >
                                Tambah Produk
                            </Button>
                        </div>
                    ) : (
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <Button
                                    type="button"
                                    onClick={() => handleModalDelete(item)}
                                >
                                    Hapus Produk
                                </Button>
                            </div>
                        </div>
                    )}
                </ModalBody>
            )}
        </Style>
    );
};
export default ProductDetail;
