import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import CardCatalog from "./CatalogCard";
import EmptyImage from "../../../../Components/Assets/Product/empty_product.jpg";
import SkeletonLoading from "../../../../Components/Loading/Skeleton";
import Button from "../../../../Components/Particle/Button";
import { Link, useHistory } from "react-router-dom";
import ShareProduct from "../Modal/ShareProduct";
import EmptyProduct from "../../../../Components/Assets/Product/empty_product.jpg";
import LocalStorage from "../../../../Utils/LocalStorage";
import TablePagination from "../../../../Components/Molekul/CommonTable/index.pagination";

const Catalog = ({
    state,
    handleModalDelete = () => {},
    handleShowProduct = () => {},
    showProductModal = false,
    setShowProductModal = () => {},
    showProduct = () => {},
    dataProduct = false,
    isFailed = false,
    defaultLimitData = 10,
    onClickNumber = () => {},
    configTable = {},
}) => {
    const history = useHistory();
    const token = LocalStorage().get("auth");
    const auth = LocalStorage().get(`${token?.access_token}`);
    const [shareItem, setShareItem] = React.useState(false);
    const [showShare, setShowShare] = React.useState(false);
    const handleCloseShare = () => {
        setShowShare(false);
        setShareItem(false);
    };
    return (
        <Card>
            <ShareProduct
                toggle={handleCloseShare}
                isShow={showShare}
                data={shareItem}
            />
            <CardBody>
                <div className="catalog-header">
                    <h5 className="title">
                        {dataProduct && dataProduct.length > 0
                            ? "Daftar Katalog"
                            : ""}
                    </h5>
                    {auth.verify_id === true &&
                        dataProduct &&
                        dataProduct.length > 0 && (
                            <div className="catalog-button">
                                <div style={{ marginRight: 10 }}>
                                    <div className="text-right">
                                        <Button
                                            color="primary"
                                            type="button"
                                            onClick={() =>
                                                history.push(
                                                    "/reseller/tambah-produk"
                                                )
                                            }
                                        >
                                            Tambah Produk
                                        </Button>
                                    </div>
                                </div>
                                <Link
                                    className="d-block"
                                    style={{ marginBottom: 30 }}
                                    to="/reseller/supplier-list"
                                >
                                    <Button
                                        color="outline-primary"
                                        type="button"
                                    >
                                        List Supplier
                                    </Button>
                                </Link>
                            </div>
                        )}
                </div>
                <Row>
                    {auth.verify_id === true ? (
                        dataProduct ? (
                            dataProduct.length > 0 ? (
                                dataProduct.map((item, key) => {
                                    return (
                                        <Col
                                            xl="6"
                                            lg="6"
                                            md="6"
                                            sm="6"
                                            xs="6"
                                            key={key}
                                        >
                                            <CardCatalog
                                                setShowShare={setShowShare}
                                                setShareItem={setShareItem}
                                                state={state}
                                                handleShowProduct={
                                                    handleShowProduct
                                                }
                                                setShowProductModal={
                                                    setShowProductModal
                                                }
                                                showProduct={showProductModal}
                                                isFailed={isFailed}
                                                handleModalDelete={
                                                    handleModalDelete
                                                }
                                                item={item}
                                            />
                                        </Col>
                                    );
                                })
                            ) : (
                                <div className="text-center w-100">
                                    <h3 className="null-product">
                                        <img
                                            src={EmptyImage}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </h3>
                                    <h3 className="null-product mb-3">
                                        Yuk klik Tambah Produk
                                    </h3>
                                    <div className="d-flex justify-content-center">
                                        <Button
                                            style={{
                                                marginBottom: 30,
                                                marginRight: 10,
                                            }}
                                            color="primary"
                                            type="button"
                                            onClick={() =>
                                                history.push(
                                                    "/reseller/tambah-produk"
                                                )
                                            }
                                        >
                                            Tambah Produk
                                        </Button>
                                        <Link
                                            className="d-block"
                                            style={{ marginBottom: 30 }}
                                            to="/reseller/supplier-list"
                                        >
                                            <Button
                                                color="primary"
                                                type="button"
                                            >
                                                List Supplier
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            )
                        ) : (
                            <React.Fragment>
                                <Col xl="6" md="6" xs="6">
                                    <SkeletonLoading
                                        width="100%"
                                        height="177px"
                                        style={{
                                            marginBottom: 30,
                                        }}
                                    />
                                </Col>
                                <Col xl="6" md="6" xs="6">
                                    <SkeletonLoading
                                        width="100%"
                                        height="177px"
                                        style={{
                                            marginBottom: 30,
                                        }}
                                    />
                                </Col>
                                <Col xl="6" md="6" xs="6">
                                    <SkeletonLoading
                                        width="100%"
                                        height="177px"
                                        style={{
                                            marginBottom: 30,
                                        }}
                                    />
                                </Col>
                                <Col xl="6" md="6" xs="6">
                                    <SkeletonLoading
                                        width="100%"
                                        height="177px"
                                        style={{
                                            marginBottom: 30,
                                        }}
                                    />
                                </Col>
                            </React.Fragment>
                        )
                    ) : auth.update_data === false ? (
                        <Col xl="12">
                            <div className="text-center">
                                <img
                                    src={EmptyProduct}
                                    alt="product_empty"
                                    className="img-"
                                />
                                <h2 className="data-null">
                                    {auth.verifikasi_message === ""
                                        ? "Yuk Lengkapi datamu, agar bisa berjualan di lakugan!"
                                        : auth.verifikasi_message}
                                </h2>
                                <Link
                                    className="btn btn-primary"
                                    to="/reseller/profile"
                                >
                                    Lengkapi Data
                                </Link>
                            </div>
                        </Col>
                    ) : (
                        <Col xl="12">
                            <div className="text-center">
                                <img
                                    src={EmptyProduct}
                                    alt="product_empty"
                                    className="img-"
                                />
                                <h2 className="data-null">
                                    Data kamu sudah dikirim, tunggu 1x 24 jam
                                    untuk verifikasi akun Anda
                                </h2>
                            </div>
                        </Col>
                    )}
                </Row>

                {dataProduct && configTable && configTable.total_page > 1 && (
                    <TablePagination
                        limitPagination={defaultLimitData}
                        onClickNumber={onClickNumber}
                        {...configTable}
                    ></TablePagination>
                )}
            </CardBody>
        </Card>
    );
};
export default Catalog;
