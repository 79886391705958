// AUTH

import Home from "../Pages/Auth/Home";
import HomeV2 from "../Pages/Auth/HomeV2";
import HowToJoin from "../Pages/Auth/HowToJoin";
import ListNewAndRateProduct from "../Pages/Auth/ListNewAndRateProduct";
import FAQ from "../Pages/Auth/FAQ";
import Reseller from "../Pages/Auth/Reseller";
import Blog from "../Pages/Auth/Blog";
import Detail from "../Pages/Auth/Blog/Detail";
import About from "../Pages/Auth/About";
import Login from "../Pages/Auth/Login";
import SignUp from "../Pages/Auth/SignUp";
import Product from "../Pages/Auth/Product";
import Payment from "../Pages/Auth/Product/Payment";
import Settlement from "../Pages/Auth/Product/Payment/Settlement";

// CUSTOMER
// import ProductDetailCustomer from '../Pages/Customer/Product/Detail'
// RESELLER
import HomeReseller from "../Pages/Reseller/Home";
import ProductList from "../Pages/Reseller/ProductList";
import TransactionReseller from "../Pages/Reseller/Transaction/PackageReseller";
import TransactionComplaint from "../Pages/Reseller/Transaction/Complaint";
import TransactionConfirmation from "../Pages/Reseller/Transaction/Confirmation";
import TransactionCancel from "../Pages/Reseller/Transaction/Cancel";
// import TrackShippingReseller from "../Pages/Reseller/Track-Shipping";
import SellRecapReseller from "../Pages/Reseller/Sell-Recap";
import HistoryReseller from "../Pages/Reseller/History";
import ProfileReseller from "../Pages/Reseller/EditProfile";
import ArrivedReseller from "../Pages/Reseller/Transaction/ArrivedReseller";
import SupplierList from "../Pages/Reseller/SupplierList";
import OrderListReseller from "../Pages/Reseller/Transaction";
import SendItemReseller from "../Pages/Reseller/Transaction/SendItemReseller";
import AccountReseller from "../Pages/Reseller/EditProfile/Account";
import PasswordChangeReseller from "../Pages/Reseller/EditProfile/PasswordChangeReseller";

// SUPPLIER
import HomeSupplier from "../Pages/Supplier/Home";
import TransactionSupplier from "../Pages/Supplier/Transaction/Package";
import TransactionComplaintSupplier from "../Pages/Supplier/Transaction/Complaint";
// import TransactionConfirmSupplier from "../Pages/Supplier/Transaction/Confirmation";
import TransactionCancelSupplier from "../Pages/Supplier/Transaction/Cancel";
// import TrackShippingSupplier from "../Pages/Supplier/Track-Shipping";
import SellRecapSupplier from "../Pages/Supplier/Sell-Recap";
import HistorySupplier from "../Pages/Supplier/History";
import ProductsSupplier from "../Pages/Supplier/Products";
import ProfileSupplier from "../Pages/Supplier/EditProfile";
import OrderList from "../Pages/Supplier/Transaction";
import SendItemSupplier from "../Pages/Supplier/Transaction/SendItem";
import Arrived from "../Pages/Supplier/Transaction/Arrived";
import NotFound from "../Pages/Auth/404";
import Account from "../Pages/Supplier/EditProfile/Account";
import PasswordChangeSupplier from "../Pages/Supplier/EditProfile/PasswordChangeSupplier";
import Maintenance from "../Pages/Auth/503";

// ICON
import HomeIcon from "../Components/Assets/icon/mobile/home.png";
import HomeActiveIcon from "../Components/Assets/icon/mobile/home-active.png";
import TransactionIcon from "../Components/Assets/icon/mobile/transaction.png";
import TransactionActiveIcon from "../Components/Assets/icon/mobile/transaction-active.png";
import WalletIcon from "../Components/Assets/icon/mobile/balance.png";
import WalletActiveIcon from "../Components/Assets/icon/mobile/balance-active.png";
import RecapIcon from "../Components/Assets/icon/mobile/recap.png";
import RecapActiveIcon from "../Components/Assets/icon/mobile/recap-active.png";
import ProductIcon from "../Components/Assets/icon/mobile/product.png";
import ProductActiveIcon from "../Components/Assets/icon/mobile/product-active.png";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import TransactionPayment from "../Pages/Auth/Product/Payment/TransactionPayment";
import EWalletPayment from "../Pages/Auth/Product/Payment/EWalletPayment";
import DoneTransaction from "../Pages/Supplier/Transaction/DoneTransaction";
import DoneTransactionReseller from "../Pages/Reseller/Transaction/DoneTransactionReseller";

import LocalStorage from "../Utils/LocalStorage";
import SearchPage from "../Pages/Auth/Search";
const token = LocalStorage().get("auth");

const auth = LocalStorage().get(`${token?.access_token}`);
const RouteList = [
    // AUTH

    {
        component: Home,
        path: "/home",
        isHeader: true,
        layout: import("../Router/RouteGuestLayout"),
        isFooter: true,
        title: "Home",
        role: "",
        accessRole: ["", "/reseller"],
    },
    {
        component: HomeV2,
        path: "/",
        isHeader: true,
        layout: import("./RouteGuestLayoutV2"),
        isFooter: true,
        title: "Home",
        role: "",
        accessRole: ["", "/reseller"],
    },
    {
        component: HowToJoin,
        path: "/how-to-join",
        isHeader: true,
        layout: import("./RouteGuestLayoutV2"),
        isFooter: true,
        title: "Yuk Jualan",
        role: "",
        accessRole: ["", "/reseller"],
    },
    {
        component: ListNewAndRateProduct,
        path: "/new-product",
        isHeader: true,
        responsive: [
            {
                breakpoint: 550,
                isHeader: false,
            },
        ],
        layout: import("../Router/RouteGuestLayout"),
        isFooter: true,
        title: "Produk Terbaru",
        role: "",
    },
    {
        role: "",
        component: ListNewAndRateProduct,
        path: "/best-product",
        isHeader: true,
        responsive: [
            {
                breakpoint: 550,
                isHeader: false,
            },
        ],
        layout: import("../Router/RouteGuestLayout"),
        isFooter: true,
        title: "Produk Terlaris",
    },
    {
        component: SearchPage,
        path: "/search",
        isHeader: true,
        responsive: [
            {
                breakpoint: 550,
                isHeader: false,
            },
        ],
        layout: import("../Router/RouteGuestLayout"),
        isFooter: true,
        title: "Home",
        role: "",
        accessRole: ["", "/reseller"],
    },
    {
        component: ForgotPassword,
        path: "/forgot-password",
        isHeader: false,
        isFooter: false,
        layout: import("../Router/RouteGuestLayout"),

        title: "Home",
        role: "",
        accessRole: ["", "/reseller"],
    },
    {
        component: NotFound,
        path: "/404",
        isHeader: true,
        layout: import("../Router/RouteGuestLayout"),
        isFooter: true,
        role: "",
        accessRole: ["", "/reseller"],
    },
    {
        component: FAQ,
        path: "/FAQ",
        isHeader: true,
        layout: import("../Router/RouteGuestLayout"),
        isFooter: true,
        title: "FAQ",
        role: "",
        accessRole: ["", "/reseller"],
    },
    {
        component: Reseller,
        path: "/user-manual/reseller",
        isHeader: true,
        layout: import("../Router/RouteGuestLayout"),
        isFooter: true,
        title: "Reseller",
        role: "",
        accessRole: ["", "/reseller"],
    },
    {
        component: Blog,
        path: "/blog",
        isHeader: true,
        layout: import("../Router/RouteGuestLayout"),
        isFooter: true,
        title: "Blog",
        role: "",
        accessRole: ["", "/reseller"],
    },
    {
        component: About,
        path: "/about-us",
        isHeader: true,
        layout: import("../Router/RouteGuestLayout"),
        isFooter: true,
        title: "About Us",
        role: "",
        accessRole: ["", "/reseller"],
    },
    {
        component: Login,
        path: "/login",
        isHeader: false,
        layout: import("../Router/RouteGuestLayout"),
        isFooter: false,
        title: "Login",
        role: "",
        accessRole: ["", "/reseller"],
    },
    {
        component: SignUp,
        path: "/signup/reseller",
        isHeader: false,
        layout: import("../Router/RouteGuestLayout"),
        isFooter: false,
        title: "Signup - Reseller",
        role: "",
        accessRole: ["", "/reseller"],
    },
    {
        component: SignUp,
        path: "/signup/supplier",
        isHeader: false,
        layout: import("../Router/RouteGuestLayout"),
        isFooter: false,
        title: "Signup - Supplier",
        role: "",
        accessRole: ["", "/reseller"],
    },
    {
        component: Detail,
        path: "/blog/:slug/:name",
        isHeader: false,
        layout: import("../Router/RouteGuestLayout"),
        isFooter: true,
        title: "Article",
        role: "",
        accessRole: ["", "/reseller"],
    },
    // PRODUCT
    {
        component: Product,
        path: "/product/:slug",
        isHeader: false,
        layout: import("../Router/RouteGuestLayout"),
        isFooter: false,
        title: "Produk",
        role: "",
        accessRole: ["", "/reseller"],
    },

    // PAYMENT
    {
        component: Payment,
        path: "/product/order/beli-sekarang",
        isHeader: false,
        layout: import("../Router/RouteGuestLayout"),
        isFooter: false,
        title: "Payment",
        role: "",
        accessRole: ["", "/reseller"],
    },
    // {
    //     component: Settlement,
    //     path: "/payment/:slug",
    //     isHeader: false,
    //     layout: import("../Router/RouteGuestLayout"),
    //     isFooter: false,
    //     title: "Payment",
    //     role: "",
    // },
    {
        component: Settlement,
        path: "/payment/transaction",
        isHeader: false,
        layout: import("../Router/RouteGuestLayout"),
        isFooter: true,
        title: "Payment",
        role: "",
        accessRole: ["", "/reseller"],
    },
    {
        component: TransactionPayment,
        path: "/payment/:slug/:timestamp/:method/:id/:price/:date/:mouth",
        isHeader: false,
        layout: import("../Router/RouteGuestLayout"),
        isFooter: false,
        title: "Payment",
        role: "",
        accessRole: ["", "/reseller"],
    },
    {
        component: EWalletPayment,
        path: "/e-wallet/payment",
        isHeader: false,
        layout: import("../Router/RouteGuestLayout"),
        isFooter: false,
        title: "e-wallet",
        role: "",
        accessRole: ["", "/reseller"],
    },

    // RESELLER
    {
        component: HomeReseller,
        path: "/home",
        title: "Beranda",
        activeName: "home",
        isHeader: true,
        sidebar: true,
        icon: HomeIcon,
        iconActive: HomeActiveIcon,
        layout: import("../Router/RouteResellerLayout"),
        role: "/reseller",
        accessRole: ["/reseller"],
        access: true,
    },
    {
        component: OrderListReseller,
        path: "/transaction/order-list",
        title: "Transaksi",
        activeName: "transaction",
        icon: TransactionIcon,
        iconActive: TransactionActiveIcon,
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteResellerLayout"),
        role: "/reseller",
        accessRole: ["/reseller"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: SendItemReseller,
        path: "/transaction/send-item",
        activeName: "send-item",
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteResellerLayout"),
        role: "/reseller",
        accessRole: ["/reseller"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: ArrivedReseller,
        path: "/transaction/barang-sampai",
        activeName: "barang-sampai",
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteResellerLayout"),
        role: "/reseller",
        accessRole: ["/reseller"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: DoneTransactionReseller,
        path: "/transaction/done",
        activeName: "done",
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteResellerLayout"),
        role: "/reseller",
        accessRole: ["/reseller"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: TransactionReseller,
        path: "/transaction/packaging",
        activeName: "packaging",
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteResellerLayout"),
        role: "/reseller",
        accessRole: ["/reseller"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: TransactionComplaint,
        path: "/transaction/complaint",
        activeName: "complaint",
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteResellerLayout"),
        role: "/reseller",
        accessRole: ["/reseller"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: TransactionConfirmation,
        path: "/transaction/confirmation",
        activeName: "confirmation",
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteResellerLayout"),
        role: "/reseller",
        accessRole: ["/reseller"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: TransactionCancel,
        path: "/transaction/cancel",
        activeName: "cancel",
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteResellerLayout"),
        role: "/reseller",
        accessRole: ["/reseller"],
        access: () => {
            return auth?.verify_id;
        },
    },
    // {
    //     component: TrackShippingReseller,
    //     path: "/track",
    //     activeName: "track",
    //     title: "Lacak Pengiriman",
    //     isHeader: true,
    //     sidebar: true,
    //     layout: import("../Router/RouteResellerLayout"),
    //     role: "/reseller",
    // },
    {
        component: SellRecapReseller,
        path: "/recap",
        activeName: "recap",
        title: "Rekap Penjualan",
        icon: RecapIcon,
        iconActive: RecapActiveIcon,
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteResellerLayout"),
        role: "/reseller",
        accessRole: ["/reseller"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: HistoryReseller,
        path: "/wallet",
        activeName: "wallet",
        title: "Mutasi Saldo",
        icon: WalletIcon,
        iconActive: WalletActiveIcon,
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteResellerLayout"),
        role: "/reseller",
        accessRole: ["/reseller"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: ProductList,
        path: "/tambah-produk",
        isHeader: false,
        sidebar: false,
        layout: import("../Router/RouteResellerLayout"),
        role: "/reseller",
        accessRole: ["/reseller"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: ProfileReseller,
        path: "/profile",
        isHeader: true,
        sidebar: false,
        layout: import("../Router/RouteResellerLayout"),
        role: "/reseller",
        accessRole: ["/reseller"],
        access: true,
    },
    {
        component: SupplierList,
        path: "/supplier-list",
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteResellerLayout"),
        role: "/reseller",
        accessRole: ["/reseller"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: AccountReseller,
        path: "/account",
        isHeader: true,
        sidebar: false,
        layout: import("../Router/RouteResellerLayout"),
        role: "/reseller",
        accessRole: ["/reseller"],
        access: true,
    },
    {
        component: PasswordChangeReseller,
        path: "/ganti-kata-sandi",
        isHeader: true,
        sidebar: false,
        layout: import("../Router/RouteResellerLayout"),
        role: "/reseller",
        accessRole: ["/reseller"],
        access: true,
    },

    // SUPPLIER
    {
        component: HomeSupplier,
        path: "/home",
        title: "Beranda",
        activeName: "home",
        icon: HomeIcon,
        iconActive: HomeActiveIcon,
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteSupplierLayout"),
        role: "/supplier",
        accessRole: ["/supplier"],
        access: true,
    },
    {
        component: OrderList,
        path: "/transaction/order-list",
        title: "Transaksi",
        activeName: "transaction",
        icon: TransactionIcon,
        iconActive: TransactionActiveIcon,
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteSupplierLayout"),
        role: "/supplier",
        accessRole: ["/supplier"],
        access: () => {
            return auth?.verify_id;
        },
    },

    {
        component: SendItemSupplier,
        path: "/transaction/send-item",
        activeName: "send-item",
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteSupplierLayout"),
        role: "/supplier",
        accessRole: ["/supplier"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: DoneTransaction,
        path: "/transaction/done",
        activeName: "done",
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteSupplierLayout"),
        role: "/supplier",
        accessRole: ["/supplier"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: Arrived,
        path: "/transaction/barang-sampai",
        activeName: "barang-sampai",
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteSupplierLayout"),
        role: "/supplier",
        accessRole: ["/supplier"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: TransactionSupplier,
        path: "/transaction/packaging",
        activeName: "packaging",
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteSupplierLayout"),
        role: "/supplier",
        accessRole: ["/supplier"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: TransactionComplaintSupplier,
        path: "/transaction/complaint",
        activeName: "complaint",
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteSupplierLayout"),
        role: "/supplier",
        accessRole: ["/supplier"],
        access: () => {
            return auth?.verify_id;
        },
    },
    // {
    //     component: TransactionConfirmSupplier,
    //     path: "/transaction/confirmation",
    //     activeName: "confirmation",
    //     isHeader: true,
    //     sidebar: true,
    //     layout: import("../Router/RouteSupplierLayout"),
    //     role: "/supplier",
    // },
    {
        component: TransactionCancelSupplier,
        path: "/transaction/cancel",
        activeName: "cancel",
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteSupplierLayout"),
        role: "/supplier",
        accessRole: ["/supplier"],
        access: () => {
            return auth?.verify_id;
        },
    },
    // {
    //   component: TrackShippingSupplier,
    //   path: "/track",
    //   title: "Lacak Pengiriman",
    //   activeName: "track",
    //   isHeader: true,
    //   sidebar: true,
    //   layout: import("../Router/RouteSupplierLayout"),
    //   role: "/supplier",
    // },
    {
        component: SellRecapSupplier,
        path: "/recap",
        title: "Rekap Penjualan",
        icon: RecapIcon,
        iconActive: RecapActiveIcon,
        activeName: "recap",
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteSupplierLayout"),
        role: "/supplier",
        accessRole: ["/supplier"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: HistorySupplier,
        path: "/wallet",
        title: "Mutasi Saldo",
        icon: WalletIcon,
        iconActive: WalletActiveIcon,
        activeName: "wallet",
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteSupplierLayout"),
        role: "/supplier",
        accessRole: ["/supplier"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: ProductsSupplier,
        path: "/products",
        icon: ProductIcon,
        iconActive: ProductActiveIcon,
        title: "Produk",
        activeName: "products",
        isHeader: true,
        sidebar: true,
        layout: import("../Router/RouteSupplierLayout"),
        role: "/supplier",
        accessRole: ["/supplier"],
        access: () => {
            return auth?.verify_id;
        },
    },
    {
        component: ProfileSupplier,
        path: "/profile",
        title: "My Profile",
        isHeader: false,
        sidebar: false,
        layout: import("../Router/RouteSupplierLayout"),
        role: "/supplier",
        accessRole: ["/supplier"],
        access: true,
    },
    {
        component: Account,
        path: "/account",
        title: "My Account",
        isHeader: false,
        sidebar: false,
        layout: import("../Router/RouteSupplierLayout"),
        role: "/supplier",
        accessRole: ["/supplier"],
        access: true,
    },
    {
        component: PasswordChangeSupplier,
        path: "/ganti-kata-sandi",
        title: "Ganti Kata Sandi",
        isHeader: false,
        sidebar: false,
        layout: import("../Router/RouteSupplierLayout"),
        role: "/supplier",
        accessRole: ["/supplier"],
        access: true,
    },
    {
        component: Maintenance,
        path: "/503",
        layout: import("../Router/RouteGuestLayout"),
        role: "",
        accessRole: ["", "/reseller"],
    },
];
export default RouteList;
