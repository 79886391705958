import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../../../Components/Assets/Images/Lakugan_LogoColor_BrightBG.png";
import Button from "../../../../Components/Particle/Button";
import { color } from "../../../../Utils/Variable";
import CheckLogo from "../../../../Components/Assets/icon/checkmark.svg";

const Style = styled.header`
  position: fixed;
  top: 0;
  z-index: 4;
  background: ${(porps) => (porps.lakugan === "" ? "#fff" : color.primary)};
  display: ${(props) => (props.isShow === true ? "block" : "none")};
  width: 100%;
  padding: 0 20px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  @media (max-width: 576px) {
    border-radius: 0px 0px 8px 8px;
    padding 20px 0;
  }
  .header-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 576px) {
      justify-content: center;
    }
  }
  .header-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 12px;
    font-weight: 600;
  }
  .mid-header {
    padding: 0 300px;
  }
  .step {
    margin: 12px 0;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      height: 36px;
      width: 36px;
    }
`;
const Header = ({
  backButton = true,
  history = "",
  lakugan = "",
  headerChecklist = false,
  headerTwo = false,
  isShow = "",
}) => {
  return (
    <Style id="header-payment" lakugan={lakugan} isShow={isShow}>
      <div className="container">
        <div className="header-menu">
          <div className="text-center">
            <Link to="/">
              <img
                style={{ maxWidth: 168 }}
                src={lakugan === "" ? Logo : lakugan}
                alt="Lakugan"
                className="img-fluid"
              />
            </Link>
          </div>
          <div className="d-md-block d-none">
            <div>
              {window.location.pathname === "/product/order/beli-sekarang" && (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="header-status" style={{ color: false ? "#DADADA" : "#000A8C" }}>
                    <div
                      style={{
                        background: "#000A8C",
                        color: "white",
                        margin: "12px 0",
                        height: 48,
                        width: 48,
                        borderRadius: "50%",
                      }}
                    >
                      <p
                        style={{
                          padding: "10px 0",
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: 20,
                          margin: 0,
                        }}
                      >
                        {" "}
                        {headerChecklist === false ? 1 : <img src={CheckLogo} alt="" />}
                      </p>
                    </div>
                    Isi Data
                  </div>
                  <div
                    className="header-status"
                    style={{
                      color: headerTwo === false ? "#DADADA" : "#000A8C",
                    }}
                  >
                    <div
                      style={{
                        background: headerTwo === false ? "rgba(252, 238, 33, 0.33)" : "#000A8C",
                        color: headerTwo === false ? "#DADADA" : "white",
                        margin: "12px 0",
                        height: 48,
                        width: 48,
                        borderRadius: "50%",
                      }}
                    >
                      <p
                        style={{
                          padding: "10px 0",
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: 20,
                          margin: 0,
                        }}
                      >
                        {true ? 2 : <img src={CheckLogo} alt="" />}
                      </p>
                    </div>
                    Pembayaran
                  </div>
                  <div className="header-status" style={{ color: true ? "#DADADA" : "#000A8C" }}>
                    <div
                      style={{
                        background: true ? "rgba(252, 238, 33, 0.33)" : "#000A8C",
                        color: true ? "#DADADA" : "white",
                        margin: "12px 0",
                        height: 48,
                        width: 48,
                        borderRadius: "50%",
                      }}
                    >
                      <p
                        style={{
                          padding: "10px 0",
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: 20,
                          margin: 0,
                        }}
                      >
                        3
                      </p>
                    </div>
                    Konfirmasi
                  </div>
                </div>
              )}
            </div>
            <div>
              {window.location.pathname === "/payment/transaction" && (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="header-status" style={{ color: false ? "#DADADA" : "#000A8C" }}>
                    <div
                      style={{
                        background: "#000A8C",
                        color: "white",
                        margin: "12px 0",
                        height: 48,
                        width: 48,
                        borderRadius: "50%",
                      }}
                    >
                      <p
                        style={{
                          padding: "10px 0",
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: 20,
                          margin: 0,
                        }}
                      >
                        {" "}
                        {false ? 1 : <img src={CheckLogo} alt="" />}
                      </p>
                    </div>
                    Isi Data
                  </div>
                  <div className="header-status" style={{ color: false ? "#DADADA" : "#000A8C" }}>
                    <div
                      style={{
                        background: false ? "rgba(252, 238, 33, 0.33)" : "#000A8C",
                        color: headerTwo === false ? "#DADADA" : "white",
                        margin: "12px 0",
                        height: 48,
                        width: 48,
                        borderRadius: "50%",
                      }}
                    >
                      <p
                        style={{
                          padding: "10px 0",
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: 20,
                          margin: 0,
                        }}
                      >
                        {false ? 2 : <img src={CheckLogo} alt="" />}
                      </p>
                    </div>
                    Pembayaran
                  </div>
                  <div className="header-status" style={{ color: false ? "#DADADA" : "#000A8C" }}>
                    <div
                      style={{
                        background: false ? "rgba(252, 238, 33, 0.33)" : "#000A8C",
                        color: true ? "#DADADA" : "white",
                        margin: "12px 0",
                        height: 48,
                        width: 48,
                        borderRadius: "50%",
                      }}
                    >
                      <p
                        style={{
                          padding: "10px 0",
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: 20,
                          margin: 0,
                        }}
                      >
                        {true ? 3 : <img src={CheckLogo} alt="" />}
                      </p>
                    </div>
                    Konfirmasi
                  </div>
                </div>
              )}
            </div>
            <div>
              {window.location.pathname === "/payment/lakugan" && (
                <Button type="button" color="outline-primary">
                  <i className="fas fa-download"></i> Bukti Pembelian
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Style>
  );
};
export default Header;
