import { servicesAction } from "../../../Reducers";

const service = {
    // SERVICE API GET AUTH
    token: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/oauth/token",
            method: "POST",
            data: data,
            v1: false,
            formData: true,
            type: "application/x-www-form-urlencoded",
            reducer: "service",
            group: "user",
            key: "token",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
    // SERVICE GET PROFILE USER
    profile: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/profile/me",
            method: "GET",
            reducer: "service",
            group: "user",
            token: true,
            key: "profile",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Bad Request",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
};
export default service;
