/* eslint-disable */
import React from "react";
import {
    Card,
    CardBody,
    Col,
    FormGroup,
    Modal,
    ModalBody,
    Row,
} from "reactstrap";
import styled from "styled-components";

import Dummy from "../../../Components/Assets/Product/defaultImage.jpg";
import { formatMoney } from "../../../Utils/PriceConverter";
import Button from "../../../Components/Particle/Button";
import { color } from "../../../Utils/Variable";
import FormSelect from "../../../Components/Particle/Form/FormSelect";
import { useStore } from "../../../Reducers";
import service from "./index.service";
import ProductDetail from "./ProductDetail";
import MessageState from "../../../Components/Modal/Message";
import TablePagination from "../../../Components/Molekul/CommonTable/index.pagination";
import SkeletonLoading from "../../../Components/Loading/Skeleton";
import Rating from "../../../Components/Particle/Rating";
import GrowingLoading from "../../../Components/Loading/GrowingLoading";
import { useHistory } from "react-router";
const Style = styled(Modal)`
    .supplier {
        margin-bottom: 30px;
        &-name {
            font-size: 26px;
            margin-bottom: 20px;
            @media (max-width: 767px) {
                font-size: 18px;
            }
            @media (max-width: 575px) {
                font-size: 14px;
            }
        }
        &-detail {
            display: flex;
            font-size: 14px;
            margin-bottom: 10px;
            span,
            div {
                display: block;
                margin-right: 5px;
            }
        }
        &-label {
            width: 110px;
            color: #868686;
        }
        &-value {
            width: calc(100% - 110px);
        }
        &-detail {
            display: flex;
            font-size: 14px;
            margin-bottom: 10px;
            span,
            div {
                display: block;
                margin-right: 5px;
            }
        }
        &-label {
            width: 175px;
            color: #868686;
            @media (max-width: 575px) {
                width: 150px !important;
            }
        }
        &-value {
        }
        &-colon {
            @media (max-width: 575px) {
                position: relative;
                left: 23px;
            }
        }
    }
    .avatar {
        width: 250px;
        height: 250px;
        object-fit: contain;
        display: block;
        margin: auto;
        border-radius: 50%;
    }
    .title {
        font-size: 14px;
        color: #aaa;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @media (max-width: 575px) {
            font-size: 12px;
        }
    }
    .product-title {
        width: max-content;
        border-bottom: 1px solid #464646;
        color: #464646;
        font-size: 20px;
        margin-bottom: 15px;
        @media (max-width: 575px) {
            font-size: 14px;
        }
    }
    .product-zero {
        width: 100%;
        color: #464646;
    }
    .card {
        &-product {
            .btn {
                @media (max-width: 575px) {
                    padding: 0 5px;
                    margin-bottom: 10px;
                }
            }
            .product-detail {
                @media (max-width: 575px) {
                    padding: 0 10px;
                }
                p {
                    @media (max-width: 575px) {
                        font-size: 12px;
                    }
                }
                .product-city {
                    @media (max-width: 575px) {
                        font-size: 10px;
                    }
                }
            }
            .pre-order {
                padding: 4px;
                font-size: 14px;
                width: max-content;
                margin: 5px 0 0 auto;
                background: ${color.primary};
                font-weight: bold;
                position: absolute;
                top: 0;
                left: 10px;
                border-radius: 5px;
                color: ${color.user.yellow};
                @media (max-width: 575px) {
                    font-size: 12px;
                }
            }
            .product-city {
                @media (max-width: 575px) {
                    font-size: 10px;
                }
            }
            &__list {
                transition: all 0.25s ease;
                height: 220px;
                &:hover {
                    box-shadow: 0 3px 6px #ddd;
                    transition: all 0.25s ease;
                }
            }
        }
    }
`;

const ProductList = ({
    store = false,
    setShow = () => {},
    configTable = false,
    dataProductSupplier = false,
    dataProductReseller = false,
    productList = false,
    isShow = false,
    getDataProduct = () => {},
    toggle = () => {},
    paramsProduct = {},
    defaultLimitData = 10,
}) => {
    const history = useHistory();
    const [categoryId, setCategoryId] = React.useState("");
    const [status, setStatus] = React.useState(true);
    const [message, setMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [showMessage, setShowMessage] = React.useState(false);
    const [dataProduct, setDataProduct] = React.useState(false);
    const [isOpen, setOpen] = React.useState(false);
    const [isHistory, setHistory] = React.useState(false);
    const [productReseller, setProductReseller] = React.useState([]);
    const { dispatch, state } = useStore();
    const handleDetailProduct = (item) => {
        setDataProduct(item);
        setOpen(true);
    };
    const toggleDetailProduct = () => {
        setOpen(!isOpen);
    };
    const checkProduct = (value) => {
        let isHideProduct = false;
        for (let key in productReseller) {
            if (productReseller[key] === value) {
                isHideProduct = true;
            }
        }
        return isHideProduct;
    };
    const onClickNumber = (number) => {
        getDataProduct({
            filters:
                categoryId === ""
                    ? paramsProduct.filters
                    : `[["supplier.id","like","${store.id}"],["and"],["category.id","like", "${categoryId}"]]`,
            page: number,
            size: defaultLimitData,
        });
    };
    const onGetValue = (obj) => {
        setCategoryId(obj.value.value);
        getDataProduct({
            filters: `[["supplier.id","like","${store.id}"],["and"],["category.id","like", "${obj.value.value}"]]`,
            page: 1,
            size: defaultLimitData,
        });
    };
    const handleAddProduct = (item) => {
        setLoading(true);
        let id = item.id;
        service.addProduct({ dispatch, id });
    };

    React.useEffect(() => {
        if (state?.reseller?.addProductMultiple?.isSuccess && isHistory) {
            setHistory(false);
            window.location = "/reseller/home";
        }
        if (state?.reseller?.addProductMultiple?.isError) {
            setStatus(state?.reseller.addProductMultiple.isSuccess);
            setMessage(state.reseller.addProductMultiple.data.message);
            service.addProductMultipleClear({ dispatch });
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
        }
    }, [state.reseller.addProductMultiple, isHistory]);
    React.useEffect(() => {
        if (dataProductReseller) {
            setProductReseller(
                dataProductReseller
                    ?.filter((item) => {
                        const filterArray = dataProductReseller?.map(
                            (item) => item.product.id
                        );
                        return !filterArray.includes(item.id);
                    })
                    .map((item) => {
                        return item.product.id;
                    })
            );
        }
    }, [dataProductReseller]);
    React.useEffect(() => {
        if (state?.reseller?.addProduct?.isSuccess) {
            setStatus(state?.reseller.addProduct.isSuccess);
            setMessage(state.reseller.addProduct.message.text);
            setShowMessage(true);
            setLoading(false);
            setTimeout(() => {
                history.push("/reseller/home");
                setShowMessage(false);
            }, 2000);

            service.addProductClear({ dispatch });
        }
        if (state?.reseller?.addProduct?.isError) {
            setLoading(false);
            setStatus(state?.reseller.addProduct.isSuccess);
            setMessage(state.reseller.addProduct.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
            service.addProductClear({ dispatch });
        }
    }, [state.reseller.addProduct]);
    return (
        <Style isOpen={isShow} size="xl" toggle={toggle}>
            <GrowingLoading active={loading} />
            <ModalBody>
                <MessageState
                    status={status}
                    message={message}
                    isShow={showMessage}
                />
                {store && (
                    <div className="supplier">
                        <Row>
                            <Col xl={4} md={4} sm={4}>
                                <img
                                    src={
                                        !store.image_store
                                            ? Dummy
                                            : store.image_store
                                    }
                                    alt=""
                                    className="avatar"
                                />
                            </Col>
                            <Col xl={8} md={8} sm={8}>
                                <h2 className="supplier-name">{store.name}</h2>
                                <div className="supplier-detail">
                                    <span className="supplier-label">
                                        Nomor Telepon
                                    </span>
                                    <span>: </span>
                                    <span className="supplier-value">
                                        {!store.phone_cs ? "-" : store.phone_cs}
                                    </span>
                                </div>
                                <div className="supplier-detail">
                                    <span className="supplier-label">Kota</span>
                                    <span>: </span>
                                    <span className="supplier-value">
                                        {!store.city ? "-" : store.city}
                                    </span>
                                </div>
                                <div className="supplier-detail">
                                    <span className="supplier-label">
                                        Hari Operasional
                                    </span>
                                    <span>: </span>
                                    <span className="supplier-value">
                                        {store.open_schedules
                                            ? store.open_schedules.map(
                                                  (item) => {
                                                      return store
                                                          .open_schedules[
                                                          store.open_schedules
                                                              .length - 1
                                                      ] === item
                                                          ? `${item}`
                                                          : `${item}, `;
                                                  }
                                              )
                                            : "-"}
                                    </span>
                                </div>
                                <div className="supplier-detail">
                                    <span className="supplier-label">
                                        Jam Operasional
                                    </span>
                                    <span>: </span>
                                    <span className="supplier-value">
                                        {store.open_hour
                                            ? `${store.open_hour} - ${store.close_hour}`
                                            : "-"}
                                    </span>
                                </div>
                                <div className="supplier-detail">
                                    <span className="supplier-label">
                                        Tentang Toko
                                    </span>
                                    <span className="supplier-colon">: </span>
                                    <div
                                        className="supplier-value"
                                        dangerouslySetInnerHTML={{
                                            __html: store.description
                                                ? store.description
                                                : "-",
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}

                <Row className="justify-content-between">
                    <Col xl="5" lg="5" md="5">
                        <h6 className="product-title">Daftar Produk</h6>
                    </Col>
                    {productList &&
                        dataProductReseller &&
                        productList.length > 0 && (
                            <Col xl="5" lg="5" md="5">
                                <FormGroup>
                                    <div>
                                        <FormSelect
                                            onGetValue={onGetValue}
                                            validationClick={false}
                                            validateForm={false}
                                            item={{
                                                name: "category",
                                                type: "select",
                                                action: "select",
                                                col: 12,
                                                defaultValue: {
                                                    label: "Semua",
                                                    value: "",
                                                },
                                                service: {
                                                    api: service.category,
                                                    group: "category",
                                                    key: "category",
                                                },
                                                valid: "Pilih kategori",
                                                value: "",
                                                status: true,
                                            }}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        )}
                </Row>
                <div>
                    <Row>
                        {dataProductSupplier ? (
                            dataProductSupplier.length > 0 ? (
                                dataProductSupplier.map((item, key) => {
                                    return (
                                        <Col
                                            xl="6"
                                            lg="6"
                                            md="6"
                                            sm="6"
                                            xs="6"
                                            key={key}
                                        >
                                            <div
                                                className="position-relative "
                                                style={{ marginBottom: 30 }}
                                            >
                                                <Card className="card-product">
                                                    <Row>
                                                        <Col
                                                            xl="5"
                                                            md="5"
                                                            sm="5"
                                                            xs="12"
                                                        >
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.product_image[0]
                                                                        ?.img_url
                                                                }
                                                                alt=""
                                                                className="img-fluid"
                                                                style={{
                                                                    height: 177,
                                                                    objectFit:
                                                                        "contain",
                                                                }}
                                                            />
                                                        </Col>
                                                        {item.pre_order && (
                                                            <div className="pre-order">
                                                                PO
                                                            </div>
                                                        )}
                                                        <Col
                                                            xl="7"
                                                            md="7"
                                                            sm="7"
                                                            xs="12"
                                                        >
                                                            <div className="product-detail">
                                                                <h6 className="mt-2 title">
                                                                    {item.name}
                                                                </h6>
                                                                <p className="font-weight-bold mb-0">
                                                                    {formatMoney(
                                                                        item.price
                                                                    )}
                                                                </p>

                                                                <Rating
                                                                    rating={
                                                                        item.rating
                                                                    }
                                                                />

                                                                <div className="mb-4">
                                                                    {item
                                                                        .supplier
                                                                        .city && (
                                                                        <p className=" mb-0 mr-3 product-city">
                                                                            {
                                                                                item
                                                                                    .supplier
                                                                                    .city
                                                                            }
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <Row>
                                                                <Col
                                                                    xl="8"
                                                                    lg="8"
                                                                    md="8"
                                                                    sm="8"
                                                                    xs="8"
                                                                >
                                                                    {!checkProduct(
                                                                        item.id
                                                                    ) ? (
                                                                        <Button
                                                                            color="primary"
                                                                            block
                                                                            onClick={() =>
                                                                                handleAddProduct(
                                                                                    item
                                                                                )
                                                                            }
                                                                        >
                                                                            Tambah
                                                                            katalog
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            color="outline-primary"
                                                                            block
                                                                            disabled={
                                                                                true
                                                                            }
                                                                        >
                                                                            Sudah
                                                                            tersedia
                                                                        </Button>
                                                                    )}
                                                                </Col>
                                                                <Col
                                                                    xl="4"
                                                                    lg="4"
                                                                    md="4"
                                                                    sm="4"
                                                                    xs="4"
                                                                    style={{
                                                                        paddingLeft: 0,
                                                                    }}
                                                                    onClick={() =>
                                                                        handleDetailProduct(
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    <Button color="outline-primary">
                                                                        Lihat
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </div>
                                        </Col>
                                    );
                                })
                            ) : (
                                <Col>
                                    <h6 className="product-zero">
                                        Produk Tidak di temukan{" "}
                                    </h6>
                                </Col>
                            )
                        ) : (
                            <React.Fragment>
                                <Col xl="6" lg="6" md="6" sm="6" xs="12">
                                    <SkeletonLoading
                                        style={{ marginBottom: 30 }}
                                        width="100%"
                                        height="177px"
                                    />
                                </Col>
                                <Col xl="6" lg="6" md="6" sm="6" xs="12">
                                    <SkeletonLoading
                                        style={{ marginBottom: 30 }}
                                        width="100%"
                                        height="177px"
                                    />
                                </Col>
                                <Col xl="6" lg="6" md="6" sm="6" xs="12">
                                    <SkeletonLoading
                                        style={{ marginBottom: 30 }}
                                        width="100%"
                                        height="177px"
                                    />
                                </Col>
                                <Col xl="6" lg="6" md="6" sm="6" xs="12">
                                    <SkeletonLoading
                                        style={{ marginBottom: 30 }}
                                        width="100%"
                                        height="177px"
                                    />
                                </Col>
                            </React.Fragment>
                        )}
                    </Row>
                </div>
                {configTable && dataProductSupplier && (
                    <TablePagination
                        limitPagination={defaultLimitData}
                        onClickNumber={onClickNumber}
                        {...configTable}
                    ></TablePagination>
                )}
                <div className="m-3 text-right">
                    <Button onClick={() => setShow(false)}>Tutup</Button>
                </div>
            </ModalBody>

            {dataProduct && (
                <ProductDetail
                    store={store}
                    productList={productList}
                    checkProduct={checkProduct}
                    myProduct={false}
                    item={dataProduct}
                    title="Produk Detail"
                    isShow={isOpen}
                    setShow={setOpen}
                    toggle={toggleDetailProduct}
                />
            )}
        </Style>
    );
};
export default ProductList;
