import React from "react";
import AddProduct from "./cardPorduct";

import Style from "./style";
import { Card, CardBody, Col, Row } from "reactstrap";

const Index = () => {
    return (
        <Style>
            <Row>
                <Col xl="12">
                    <Card>
                        <CardBody>
                            <AddProduct />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Style>
    );
};

export default Index;
