import React from "react";
import Style from "../index.style";
import Emas from "../../../../Components/Assets/v2/emas.png";
import Skincare from "../../../../Components/Assets/v2/skincare.png";

const SectionProduct = () => {
  
  return (
    <Style>
        <div className="header">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-title">
                        <ul>
                            <li style={{color: 'white'}}>Produk Unggulan</li>
                        </ul>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-sm-6">
                        <div className="box-image">
                            <img src={Emas} alt="" />
                        </div>
                        <div className="product-text">
                            <h2>Lakugan Emas</h2>
                            <a href="/home" className="btn btn-warning">Beli Sekarang</a>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="box-image">
                            <img src={Skincare} alt="" />
                        </div>
                        <div className="product-text">
                            <h2>Lakugan Health and Beauty</h2>
                            <a href="/home" className="btn btn-warning">Beli Sekarang</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Style>
  );
};
export default SectionProduct;
