import React from "react";
import Slider from "react-slick";
import { Col, Row } from "reactstrap";
import CardNew from "../../../../Components/Cards/CardNew";
import styled from "styled-components";
import SkeletonLoading from "../../../../Components/Loading/Skeleton";
import { Link } from "react-router-dom";
import { color } from "../../../../Utils/Variable";

const Style = styled.div`
  padding-bottom: 30px;
  border-bottom: 1.5px solid #e8e8e8;
  margin-bottom: 60px;
  .header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // .see-all {
    //   font-weight: 500;
    //   color: ${color.primary};
    //   font-size: 14px;
    //   i {
    //     margin-left: 5px;
    //   }
    //   @media (max-width: 550px) {
    //     font-size: 12px;
    //   }
    // }
  }
  .slick-prev,
  .slick-next {
    top: calc(100% - 50%);
    font-size: 20px;
    color: #000000;
    width: max-content;
    height: max-content;
    padding: 6px 10px;
    box-shadow: -4px 4px 16px rgba(0, 0, 0, 0.08);
    background: #fff;
    border-radius: 50%;
    &::before {
      display: none;
    }
  }
  .slick-prev {
    left: -5px;
    z-index: 3;
  }
  .slick-next {
    right: -7px;
  }
  .fa-chevron-left,
  .fa-chevron-right {
    color: ${color.primary};
  }
  .slick-product {
    @media (max-width: 550px) {
      .slick-list {
        padding: 0 20% 0 0 !important;
      }
      .slick-slide {
      }
    }
  }
`;
const SectionProduct = ({ title = "", data = [], onGetValue = () => {} }) => {
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <button
        style={{ ...style, border: "unset" }}
        onClick={onClick}
        className={className}
        type="button"
        // className="prev"
      >
        <i className="fas fa-chevron-left"></i>
      </button>
    );
  };
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <button
        style={{ ...style, border: "unset" }}
        onClick={onClick}
        className={className}
        type="button"
        // className="next"
      >
        <i className="fas fa-chevron-right"></i>
      </button>
    );
  };
  const sliderNewProduct = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 4,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <Style>
      <div className="header-title">
        <h3 className="font-bold">{title}</h3>
        <Link to="/new-product" className="font-bold">
          Lihat Semua <i className="fa fa-chevron-right"></i>
        </Link>
      </div>

      {data.length > 0 ? (
        <Slider className="slick-product" {...sliderNewProduct}>
          {data.map((item, key) => {
            return (
              <div key={key}>
                <Col>
                  <CardNew
                    onGetValue={onGetValue}
                    item={item}
                    image={item.product.product_image[0]?.img_url}
                    alt={item.product.product_image[0]?.name}
                    name={item.product.name}
                    rating={item.product.rating}
                    price={item.product.price}
                    city={item.store.city}
                    stock={item.product.total_stock_product}
                  />
                </Col>
              </div>
            );
          })}
        </Slider>
      ) : (
        <Row>
          {[...Array(4)].map((item, key) => {
            return (
              <Col xl={3} key={key}>
                <SkeletonLoading width="100%" height="250px" />
              </Col>
            );
          })}
        </Row>
      )}
    </Style>
  );
};
export default SectionProduct;
