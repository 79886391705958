import React from "react";
import { Row, Col, Container } from "reactstrap";
import styled from "styled-components";
import { color } from "../../../../Utils/Variable";

const Style = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    .content {
        width: 90%;
    }
    .title {
        color: ${color.primary};
        padding-bottom: 7px;
        width: max-content;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 4px;
            background: ${color.primary};
            border-radius: 20px;
        }
        font-weight: bold;
        @media (max-width: 767px) {
            font-size: 30px;
        }
        @media (max-width: 575px) {
            font-size: 18px;
        }
    }
    p,
    span {
        color: #464646 !important;
        font-weight: 400;
        line-height: 2;
        font-size: 15px;
        margin-bottom: 2rem;
        @media (max-width: 575px) {
            font-size: 12px;
        }
    }
    strong {
        color: ${color.primary} !important;
    }
    .box {
        margin-bottom: 50px;
        width: 100%;
        text-overflow: ellipsis;
        -webkit-line-clamp: ${(props) => props.clamp};
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        @media (max-width: 991px) {
            margin-bottom: 20px;
        }
    }
    .image {
        background-color: #ddd;
        width: 100%;
        border-radius: 10px;
    }
    .see-more {
        cursor: pointer;
        transition: all 0.25s ease;
        color: ${color.primary};
        &:hover {
            color: #1857b4;
        }
        @media (max-width: 991px) {
            margin-bottom: 30px;
        }
    }
    .article {
        &-list {
            list-style: decimal;
            ol {
                list-style: disc;
            }
        }
    }
`;

const Layout = ({ item }) => {
    const [clamp, setClamp] = React.useState(6);
    const showAll = () => {
        if (!clamp) {
            setClamp(6);
        }
        if (clamp) {
            setClamp(false);
        }
    };
    return (
        <>
            <Style clamp={clamp}>
                <Container fluid="md">
                    <div style={{ marginTop: 50 }}>
                        <Row>
                            <Col
                                xl="6"
                                lg="6"
                                md="12"
                                className="d-block d-lg-none"
                            >
                                <div className="box">
                                    <div className="image">
                                        <img
                                            style={{ borderRadius: "10px" }}
                                            src={item.img_url}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col xl="6" lg="6" md="12">
                                <div className="box">
                                    <h3 className="title">{item.title}</h3>
                                    <div className="content">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: item.content,
                                            }}
                                        ></div>

                                        {item.sub_desc && (
                                            <p>{item.sub_desc}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="see-more" onClick={showAll}>
                                    {clamp ? (
                                        <React.Fragment>
                                            Baca selengkapnya
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            Sembunyikan Info
                                        </React.Fragment>
                                    )}
                                </div>
                            </Col>
                            <Col
                                xl="6"
                                lg="6"
                                md="12"
                                className="d-none d-lg-block"
                            >
                                <div className="box">
                                    <div className="image">
                                        <img
                                            style={{ borderRadius: "10px" }}
                                            src={item.img_url}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Style>
        </>
    );
};

export default Layout;
