import React from "react";
import styled from "styled-components";
// import { CardBody, Card } from "reactstrap";
import { formatMoney } from "../../../../../Utils/PriceConverter";

const Style = styled.div`
    .title {
        font-weight: bold;
        font-size: 20px;
        color: #000000;
    }

    .product,
    .payment {
        display: flex;
        padding-bottom: 10px;
        margin-bottom: 15px;
        border-bottom: 1.5px solid #e8e8e8;
        &-img {
            margin-right: 15px;
            > img {
                width: 80px;
                // height: 80px;
                border-radius: 20px;
            }
        }
        &-detail {
            width: 100%;
        }

        &-order {
            //   font-size: 16px;
            color: #000000;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .total-payment {
        width: 100%;
        div {
            font-weight: bold;
        }
    }
`;
const DetailProduct = ({ data }) => {
    return (
        <Style>
            <div className="product">
                <div className="product-img">
                    <img
                        src={data?.product?.product_image[0]?.img_url}
                        alt=""
                        className="img-fluid"
                    />
                </div>
                <div className="product-detail">
                    <h6 className="sub-title font-bold mb-2">
                        {data?.product_name}
                    </h6>
                    <div className="product-order mb-1">
                        <p className="font-regular">Jumlah</p>
                        <p className="font-semibold">{data?.total_amount}x</p>
                    </div>
                    <div className="product-order">
                        <p className="font-regular">Per-Item</p>
                        <p className="font-semibold">
                            {formatMoney(data?.product_fee)}
                        </p>
                    </div>
                </div>
            </div>
            <div className="payment">
                <div style={{ width: "100%" }}>
                    <h6 className="sub-title mb-2">Ringkasan Pembayaran</h6>
                    <div className="product-order mb-1">
                        <p>Total Harga</p>
                        <p>
                            {formatMoney(data.product_fee * data.total_amount)}
                        </p>
                    </div>
                    <div className="product-order mb-1">
                        <p>Ongkos Kirim</p>
                        <p>{formatMoney(data.shipping_fee)}</p>
                    </div>
                    <div className="product-order mb-1">
                        <p>Asuransi</p>
                        <p>
                            {data.insurance_fee === 0
                                ? "-"
                                : formatMoney(data.insurance_fee)}
                        </p>
                    </div>
                    <div className="product-order mb-1">
                        <p>Biaya Admin</p>
                        <p>{formatMoney(data.admin_fee)}</p>
                    </div>
                </div>
            </div>
            <div className="payment">
                <div className="product-order total-payment">
                    <h6 className="font-bold">Total Pembayaran</h6>
                    <h6 className="font-bold">
                        {formatMoney(data.total_cost)}
                    </h6>
                </div>
            </div>
        </Style>
    );
};
export default DetailProduct;
