import React from "react";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import Logo from "../../../../Components/Assets/icon/Lakugan_Logo_Square-2.png";
import Button from "../../../../Components/Particle/Button";
import { color } from "../../../../Utils/Variable";
const ModalStyle = styled(Modal)`
    h3 {
        font-size: 18px;
        @media (max-width: 575px) {
            font-size: 14px;
        }
    }
    div {
        font-size: 14px;
        @media (max-width: 575px) {
            font-size: 10px;
        }
    }
    .lakugan-term {
        margin-bottom: 20px;
    }
    ul {
        list-style: lower-alpha;
    }
    a {
        color: #0056b3;
    }
`;
const CheckedStyle = styled.div`
    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        font-size: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        @media (max-width: 575px) {
            font-size: 14px;
        }
    }

    /* Hide the browser's default checkbox */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
        background-image: url(${Logo});
        background-repeat: no-repeat;
        background-size: contain;
    }
`;
const CheckTerm = styled.div`
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    border-top: 1px solid #ddd;
    background: #fff;
    padding: 10px;
    .check {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
const TermConditionReseller = ({
    handleSubmitRegister = () => {},
    isShow = true,
    toggle = () => {},
}) => {
    const [isChecked, setChecked] = React.useState(false);
    return (
        <ModalStyle isOpen={isShow} size={"lg"} toggle={toggle}>
            <ModalBody>
                <div style={{ overflow: "auto", maxHeight: 600 }}>
                    <div style={{ marginBottom: 20, fontSize: 14 }}>
                        <div
                            style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                marginBottom: 20,
                            }}
                        >
                            TERMS &amp; CONDITIONS <br />( Syarat
                            &amp; Ketentuan Penggunaan 
                            <a href="https://lakugan.com">www.lakugan.com</a> )
                        </div>
                        <div className="lakugan-term">
                            <div>
                                <h3
                                    className="text-center"
                                    style={{
                                        color: color.primary,
                                        fontWeight: 900,
                                    }}
                                >
                                    PERJANJIAN PENGGUNAAN LAYANAN SITUS
                                </h3>
                            </div>
                            <div>
                                Lakugan.com adalah platform reseller tanpa modal
                                yang menghubungkan para supplier dan produsen
                                produk dengan reseller untuk dapat bersinergi
                                dalam melakukan penjualan. Reseller akan
                                menjualkan produk supplier di halaman toko
                                Lakugan.com- nya dengan cara yang simple, mudah
                                dan cepat. Hanya dengan sekali klik dan share
                                link.. <br />
                                <br />
                                Mohon untuk membaca dengan hati-hati Perjanjian
                                ini. Pengguna harus membaca, memahami, menerima
                                dan menyetujui semua persyaratan dan ketentuan
                                dalam Perjanjian ini sebelum menggunakan
                                aplikasi dan/atau menerima konten yang terdapat
                                di dalamnya. <br />
                                <br />
                                Dengan mengunduh, memasang, dan/atau menggunakan
                                Platform, dan melanjutkan akses terhadap situs{" "}
                                <a href="https://lakugan.com">
                                    www.lakugan.com.
                                </a>
                                , Pengguna menyetujui persyaratan dan ketentuan
                                Kami, dan oleh karena itu menyetujui untuk
                                terikat dalam suatu kontrak dengan Kami dan oleh
                                karenanya Pengguna menyatakan persetujuan untuk
                                dapat menerima layanan dan akses atas seluruh
                                konten yang terdapat dalam aplikasi ini. <br />
                                <br />
                                Jika Pengguna tidak menerima dan menyetujui
                                Perjanjian ini, pengguna tidak diperkenankan
                                untuk mengakses lebih lanjut situs{" "}
                                <a href="https://lakugan.com">
                                    www.lakugan.com.
                                </a>
                                 dan dipersilahkan untuk meninggalkan situs{" "}
                                <a href="https://lakugan.com">
                                    www.lakugan.com.
                                </a>{" "}
                                <br />
                                <br />
                                Setiap kegiatan terkait dengan penggunaan situs,
                                maka baik penyelenggara, pengguna dalam hal ini
                                termasuk juga adalah penjual (merchant) dan
                                pembeli, dilindungi secara hukum melalui Undang
                                – Undang Republik Indonesia No.11 Tahun 2008
                                tentang Informasi dan Teknologi, Undang-Undang
                                Republik Indonesia No. 19 Tahun 2002 tentang Hak
                                Cipta, dan terhadap segala bentuk perikatan yang
                                timbul dari segala aktifitas di situs  
                                <a href="https://lakugan.com">
                                    www.lakugan.com
                                </a>
                                 telah memenuhi kaidah dan syarat sahnya suatu
                                perikatan sebagaimana yang tercantum dalam Kitab
                                Undang-Undang Hukum Perdata Indonesia.{" "}
                            </div>
                        </div>

                        <div className="lakugan-term">
                            <h3>1. PENDAHULUAN</h3>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>1.1</div>
                                <div style={{ width: "fit-content" }}>
                                    Perjanjian ini diatur dan diinterprestasikan
                                    berdasarkan Hukum Republik Indonesia
                                    (Indonesia). Pihak-pihak yang disebutkan
                                    dalam Perjanjian ini dengan ini sepakat
                                    untuk tunduk kepada Pengadilan di Indonesia.
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>1.2</div>
                                <div style={{ width: "fit-content" }}>
                                    Dalam Perjanjian ini yang dimaksud dengan
                                    “Pengguna” adalah Reseller dan Supplier baik
                                    orang perseorangan, warga negara Indonesia,
                                    warga negara asing, maupun badan hukum
                                    dengan kemampuan menggunakan komputer,
                                    jaringan, komputer dan/atau media elektronik
                                    lainnya mampu untuk mengakses situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                     sesuai dengan keperluannya. Dalam hal ini
                                    termasuk juga Pengguna yang telah
                                    mendaftarkan diri pada situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                     sebagai Pengguna Terdaftar atau Pengguna
                                    tersebut membayar untuk layanan tertentu
                                    yang mungkin disediakan oleh situs{" "}
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                    .
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>1.3</div>
                                <div style={{ width: "fit-content" }}>
                                    Dengan mengakses atau menggunakan situs{" "}
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>{" "}
                                    ini, Pengguna yang termasuk dalam kategori
                                    Pengguna sebagaimana disebutkan dalam poin
                                    1.2. di atas menyatakan diri setuju untuk
                                    menerima semua syarat dan ketentuan yang
                                    tercantum dalam Perjanjian ini. Sebagai
                                    Pengguna, Pengguna terikat dengan syarat dan
                                    ketentuan dalam Perjanjian yang berlaku
                                    dalam hal Pengguna mengakses atau
                                    menggunakan situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                     ini. Jika Pengguna tidak menerima semua
                                    syarat dan ketentuan dalam Perjanjian ini,
                                    Pengguna diharuskan untuk langsung
                                    meninggalkan situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                    .
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>1.4</div>
                                <div style={{ width: "fit-content" }}>
                                    Kami dapat mengubah atau memperbarui
                                    Perjanjian ini setiap waktu dengan
                                    mencantumkan Perjanjian yang telah diubah
                                    atau diperbarui di dalam situs dan
                                    persyaratan dan ketentuan yang telah diubah
                                    dan diperbarui akan segera berlaku setelah
                                    persyaratan dan ketentuan yang telah diubah
                                    dan diperbarui dicantumkan oleh situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                    , dan Pengguna diwajibkan untuk setiap saat
                                    membaca persyaratan dan ketentuan baru dan
                                    dianggap telah menyetujui perubahan atau
                                    pembaruan tersebut apabila setelah
                                    dicantumkan di dalam situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                     dan Pengguna terus menggunakan situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                    .
                                </div>
                            </div>
                        </div>
                        <div className="lakugan-term">
                            <h3>2. KETENTUAN UMUM PENGGUNAAN SITUS</h3>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>2.1</div>
                                <div style={{ width: "fit-content" }}>
                                    <div>
                                        Saat mengunjungi dan menggunakan situs 
                                        <a href="https://lakugan.com">
                                            www.lakugan.com
                                        </a>
                                        , termasuk setiap fitur dan layanannya,
                                        Setiap Pengguna tidak diperkenankan
                                        untuk:
                                    </div>
                                    <ul>
                                        <li>
                                            melanggar setiap hukum yang berlaku
                                            (termasuk tetapi tidak terbatas pada
                                            peraturan mengenai perlindungan
                                            konsumen, persaingan tidak sehat,
                                            anti diskriminasi atau iklan palsu),
                                            hak-hak pihak lain baik hak
                                            intelektual, asasi, dan lainnya, dan
                                            aturan-aturan yang diatur pada
                                            Perjanjian ini.
                                        </li>
                                        <li>
                                            memberikan informasi dan konten yang
                                            salah, tidak akurat, bersifat
                                            menyesatkan, bersifat memfitnah,
                                            bersifat asusila, mengandung
                                            pornografi, bersifat diskriminasi
                                            atau rasis.
                                        </li>
                                        <li>
                                            mengambil tindakan yang dapat
                                            mengacaukan sistem saran atau
                                            masukan dan atau peringkat (seperti
                                            menampilkan, mengimpor atau
                                            mengekspor informasi atau masukan
                                            dari situs luar atau menggunakannya
                                            untuk tujuan yang tidak terkait
                                            dengan situs 
                                            <a href="https://lakugan.com">
                                                www.lakugan.com
                                            </a>
                                            ).
                                        </li>
                                        <li>
                                            memberikan account di situs 
                                            <a href="https://lakugan.com">
                                                www.lakugan.com
                                            </a>
                                             (termasuk saran atau masukan) dan
                                            nama account kepada pihak lain tanpa
                                            sepengetahuan Kami.
                                        </li>
                                        <li>
                                            menyebarkan spam, hal-hal yang tidak
                                            berasusila, atau pesan elektronik
                                            yang berjumlah besar, pesan
                                            bersambung.
                                        </li>
                                        <li>
                                            menyebarkan virus atau seluruh
                                            teknologi lainnya yang sejenis yang
                                            dapat merusak dan/atau merugikan
                                            situs 
                                            <a href="https://lakugan.com">
                                                www.lakugan.com
                                            </a>
                                            , afiliasinya dan pengguna lainnya.
                                        </li>
                                        <li>
                                            memasukkan atau memindahkan fitur
                                            pada situs 
                                            <a href="https://lakugan.com">
                                                www.lakugan.com
                                            </a>
                                             tidak terkecuali tanpa
                                            sepengetahuan dan persetujuan dari
                                            Kami.
                                        </li>
                                        <li>
                                            menyimpan, meniru, mengubah, atau
                                            menyebarkan konten dan fitur situs 
                                            <a href="https://lakugan.com">
                                                www.lakugan.com
                                            </a>
                                            , termasuk cara pelayanan, konten,
                                            hak cipta dan intelektual yang
                                            terdapat pada Situs 
                                            <a href="https://lakugan.com">
                                                www.lakugan.com
                                            </a>
                                            .
                                        </li>
                                        <li>
                                            mengambil atau mengumpulkan
                                            informasi dari pengguna lain,
                                            termasuk alamat email, tanpa
                                            sepengetahuan pengguna lain.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>2.2</div>
                                <div style={{ width: "fit-content" }}>
                                    Kami berhak membatasi atau tidak memberikan
                                    akses, atau memberikan akses yang berbeda
                                    untuk dapat membuka situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                     dan fitur di dalamnya kepada masing-masing
                                    Pengguna, atau mengganti salah satu fitur
                                    atau memasukkan fitur baru tanpa
                                    pemberitahuan sebelumnya. Setiap Pengguna
                                    sadar bahwa jika situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                     tidak dapat digunakan seluruhnya atau
                                    sebagian karena alasan apapun, maka usaha
                                    atau kegiatan apapun yang dilakukan Pengguna
                                    dapat terganggu. Setiap Pengguna dengan ini
                                    sepakat bahwa karena alasan apapun
                                    membebaskan Kami dari segala bentuk
                                    pertanggungjawaban terhadap Pengguna atau
                                    terhadap pihak ketiga jika yang bersangkutan
                                    tidak dapat menggunakan Situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                     (baik karena gangguan, dibatasinya akses,
                                    dilakukannya perubahan fitur atau tidak
                                    dimasukkannya lagi fitur tertentu atau
                                    karena alasan lain); atau jika komunikasi
                                    atau transmisi tertunda, gagal atau tidak
                                    dapat berlangsung; atau jika timbul kerugian
                                    (secara langsung, tidak langsung) karena
                                    digunakannya atau tidak dapat digunakannya
                                    situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                     atau salah satu fitur di dalamnya.
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>2.3</div>
                                <div style={{ width: "fit-content" }}>
                                    Dengan terus melakukan akses atau terus
                                    menggunakan situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                    , Pengguna dianggap telah membaca, memahami
                                    dan menyetujui ketentuan situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                     tentang Privacy Policy yang mengatur
                                    masalah penggunaan informasi yang dimasukkan
                                    masing-masing Pengguna ke dalam situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                    . Pengguna menerima ketentuan ini serta
                                    tambahan atau setiap perubahan atau
                                    pembaruannya. Pengguna memahami dan
                                    mengetahui secara sadar bahwa Kami dapat
                                    mengubah ketentuan tentang Kerahasiaan
                                    Pribadi ini sewaktu-waktu dan akan
                                    memasukkan versi terbarunya di situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                    . Jika terus menggunakan situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                    , Pengguna dianggap menerima dan menyetujui
                                    ketentuan tentang Privacy Policy yang
                                    tercantum di situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                     pada saat digunakan.
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>2.4</div>
                                <div style={{ width: "fit-content" }}>
                                    Situs ini dapat berisi link internet ke
                                    situs lainnya yang dimiliki dan dioperasikan
                                    oleh pihak ketiga. Perlu Pengguna ketahui,
                                    bahwa 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                     tidak bertanggung jawab terhadap
                                    pengoperasian ataupun konten yang terletak
                                    di situs tersebut.
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>2.5</div>
                                <div style={{ width: "fit-content" }}>
                                    Akses situs ini hanya diperkenankan untuk
                                    keperluan dan kepentingan berbelanja dan
                                    informasi terkait dengan layanan situs ini.
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>2.6</div>
                                <div style={{ width: "fit-content" }}>
                                    Pengguna tidak diperkenankan untuk
                                    merepropertisi, mendistribusikan, memajang,
                                    menjual, menyewakan, mengirimkan, membuat
                                    karya turunan, menerjemahkan, memodifikasi,
                                    merekayasa balik, membongkar, mengurai atau
                                    mengeksploitasi situs ini.
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>2.7</div>
                                <div style={{ width: "fit-content" }}>
                                    {" "}
                                    <div>
                                        Pengguna tidak diperkenankan untuk
                                        memuat dan menerbitkan konten yang:
                                    </div>
                                    <ul>
                                        <li>
                                            Melanggar hak cipta, paten, merek
                                            dagang, merek layanan, rahasia
                                            dagang, atau hak kepemilikan lainnya
                                        </li>
                                        <li>
                                            Mengancam, tidak senonoh, pornografi
                                            atau bisa menimbulkan segala
                                            kewajiban Hukum perdata atau pidana
                                            Indonesia atau hukum internasional.
                                        </li>
                                        <li>
                                            Mengandung bug, virus, worm, pintu
                                            perangkap, trojan horse atau kode
                                            dan properti berbahaya lainnya.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="lakugan-term">
                            <h3>3. KETENTUAN RESSELER</h3>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>3.1</div>
                                <div style={{ width: "fit-content" }}>
                                    <b>Reseller</b> adalah Pengguna situs
                                    lakugan.com yang memasarkan dan menjual
                                    produk yang disediakan oleh supplier
                                    Lakugan.com.
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>3.2</div>
                                <div style={{ width: "fit-content" }}>
                                    <div>
                                        Seluruh Reseller mempunyai Hak sebagai
                                        berikut :
                                    </div>
                                    <ul>
                                        <li>
                                            Memperoleh bagi hasil dari
                                            Lakugan.com atas setiap Transaksi
                                            produk yang berhasil dilakukan.
                                        </li>
                                        <li>
                                            Mendapatkan informasi terhadap
                                            system, skema dan standar
                                            operational prosedur dari
                                            Lakugan.com secara berkala.
                                        </li>
                                        <li>
                                            Bertindak sebagai mitra resmi untuk
                                            memasarkan dan menjual produk.
                                        </li>
                                        <li>
                                            Melakukan konfirmasi dan berhubungan
                                            langsung terhadap pelanggan
                                            sehubungan dengan transaksi produk.
                                        </li>
                                        <li>
                                            Berhubungan langsung dengan supplier
                                            untuk penanganan complain, retur
                                            barang dan pertanyaan seputar
                                            produk.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>3.3</div>
                                <div style={{ width: "fit-content" }}>
                                    <div>
                                        Seluruh Reseller mempunyai Kewajiban
                                        sebagai berikut :
                                    </div>
                                    <ul>
                                        <li>
                                            Memberikan informasi yang benar
                                            kepada pelanggan terkait dengan
                                            produk yang dijual.
                                        </li>
                                        <li>
                                            Mematuhi dan melaksanakan semua
                                            ketentuan yang diberlakukan oleh
                                            Lakugan.com.
                                        </li>
                                        <li>
                                            Melakukan transaksi hanya dari
                                            system yang disediakan oleh
                                            Lakugan.com.
                                        </li>
                                        <li>Menjaga nama baik Lakugan.com.</li>
                                        <li>
                                            Mematuhi semua peraturan
                                            perundang-undangan yang berlaku di
                                            wilayah negara Republik Indonesia
                                            sehubungan dengan kerjasama ini.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="lakugan-term">
                            <h3>4. KETENTUAN SUPPLIER</h3>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>4.1</div>
                                <div style={{ width: "fit-content" }}>
                                    <b>Supplier</b> adalah Pengguna situs
                                    lakugan.com yang menyediakan produk atau
                                    barang untuk dipasarkan oleh reseller kepada
                                    pelanggan.
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>4.2</div>
                                <div style={{ width: "fit-content" }}>
                                    <div>
                                        Seluruh Supplier mempunyai hak sebagai
                                        berikut :
                                    </div>
                                    <ul>
                                        <li>
                                            Memperoleh uang hasil penjualan dari
                                            Lakugan.com atas setiap transaksi
                                            produk yang berhasil dilakukan.
                                        </li>
                                        <li>
                                            Mendapatkan informasi terhadap
                                            system, skema dan standar
                                            operational prosedur dari
                                            Lakugan.com secara berkala.
                                        </li>
                                        <li>
                                            Bertindak sebagai mitra resmi
                                            penyedia produk.
                                        </li>
                                        <li>
                                            Berhubungan langsung dengan reseller
                                            untuk penanganan complain, retur
                                            barang &amp; pertanyaan seputar
                                            produk
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>4.3</div>
                                <div style={{ width: "fit-content" }}>
                                    <div>
                                        Seluruh Supplier berkewajiban sebagai
                                        berikut :
                                    </div>
                                    <ul>
                                        <li>
                                            Menjual produk yang tidak melanggar
                                            hukum negara republik Indonesia
                                        </li>
                                        <li>
                                            Memberikan informasi yang benar
                                            terkait dengan produk yang dijual.
                                        </li>
                                        <li>
                                            Mematuhi dan melaksanakan semua
                                            ketentuan yang diberlakukan oleh
                                            Lakugan.com.
                                        </li>
                                        <li>
                                            Melakukan transaksi hanya dari
                                            system yang disediakan oleh
                                            Lakugan.com.
                                        </li>
                                        <li>Menjaga nama baik Lakugan.com.</li>
                                        <li>
                                            Mematuhi semua peraturan
                                            perundang-undangan yang berlaku di
                                            wilayah negara Republik Indonesia
                                            sehubungan dengan kerjasama ini.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="lakugan-term">
                            <h3>5. KETENTUAN PEMBAYARAN</h3>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>5.1</div>
                                <div style={{ width: "fit-content" }}>
                                    Kami bekerja sama dengan penyedia jasa
                                    perbankan/pembayaran yang terpercaya dalam
                                    menyediakan berbagai metode pembayaran yang
                                    dapat digunakan oleh Pengguna.
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>5.2</div>
                                <div style={{ width: "fit-content" }}>
                                    <div>
                                        Lakugan.com akan melakukan pembayaran
                                        kepada Pengguna melalui rekening
                                        pengguna yang telah terdaftar, dengan
                                        ketentuan sebagai berikut :
                                    </div>
                                    <ul>
                                        <li>
                                            Reseller akan menerima pembayaran
                                            komisi (bagi hasil) pada tanggal 10
                                            setiap bulannya
                                        </li>
                                        <li>
                                            Supplier akan menerima pembayaran
                                            hasil penjualan dalam jangka waktu
                                            maksimal 3 – 6 hari kerja setelah
                                            barang diterima oleh Pelanggan.
                                        </li>
                                        <li>
                                            Ketentuan pembayaran ini dapat
                                            berubah sewaktu-waktu.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>5.3</div>
                                <div style={{ width: "fit-content" }}>
                                    Nomor Rekening yang tercantum harus sesuai
                                    dengan akun yang terdaftar di
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="lakugan-term">
                            <h3>6. KEWENANGAN LAKUGAN.COM</h3>
                            <div className="d-flex">
                                <div style={{ width: "fit-content" }}>
                                    PT. VIRTU INOVASI DIGITAL sebagai pemilik
                                    www.lakugan.com tidak bertanggung jawab atas
                                    transaksi apapun yang dilakukan oleh
                                    Reseller dan Supplier diluar dari situs
                                    www.lakugan.com;
                                </div>
                            </div>
                        </div>
                        <div className="lakugan-term">
                            <h3>7. HAK MILIK KEKAYAAN INTELEKTUAL</h3>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>7.1</div>
                                <div style={{ width: "fit-content" }}>
                                    PT. VIRTU INOVASI DIGITAL menjadi pemilik
                                    tunggal atau pemegang sah semua hak atas
                                    Situs dan konten situs ini dengan penyerahan
                                    hak cipta dari pembuat/developer website
                                    ini. Seluruh konten yang terdapat di dalam
                                    situs ini mencakup hak milik intelektual
                                    yang dilindungi oleh undang-undang hak cipta
                                    dan undang-undang yang melindungi kekayaan
                                    intelektual lainnya yang berlaku di seluruh
                                    dunia. Semua hak milik dan hak milik
                                    intelektual atas situs ini dan isinya tetap
                                    pada Kami, afiliasinya atau pemilik lisensi
                                    isi situs ini
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>7.2</div>
                                <div style={{ width: "fit-content" }}>
                                    Situs 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                    , nama, dan ikon serta logo terkait
                                    merupakan merek dagang terdaftar di berbagai
                                    wilayah hukum dan dilindungi undang-undang
                                    tentang hak cipta, merek dagang atau hak
                                    milik kekayaan intelektual lainnya. Dilarang
                                    keras menggunakan, mengubah, atau memasang
                                    merek-merek tersebut di atas untuk
                                    kepentingan pribadi dan untuk
                                    mendeskriditkan 
                                    <a href="https://lakugan.com">
                                        www.lakugan.com
                                    </a>
                                    .
                                </div>
                            </div>
                        </div>
                        <div className="lakugan-term">
                            <h3>8. PEMBERITAHUAN</h3>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>8.1</div>
                                <div style={{ width: "fit-content" }}>
                                    Semua pemberitahuan atau permintaan
                                    informasi kepada atau tentang Kami akan
                                    diproses jika dibuat secara tertulis dan
                                    dikirimkan kepada: PT. VIRTU INOVASI
                                    DIGITAL, Jalan Aren No 29 Kel. Jati Pulo
                                    Kec. Palmerah Tomang Jakarta Barat 11430.
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>8.2</div>
                                <div style={{ width: "fit-content" }}>
                                    <div>
                                        Semua pemberitahuan atau permintaan
                                        kepada atau tentang Pengguna akan
                                        diproses jika diserahkan langsung,
                                        dikirimkan melalui kurir, surat
                                        tercatat, faksimili atau email ke alamat
                                        surat, faks atau alamat email yang
                                        diberikan oleh Pengguna kepada Kami atau
                                        dengan cara memasang pemberitahuan atau
                                        permintaan tersebut di satu tempat di
                                        situs 
                                        <a href="https://lakugan.com">
                                            www.lakugan.com
                                        </a>
                                         yang dapat diakses oleh umum tanpa
                                        dikenai biaya. Pemberitahuan kepada
                                        Pengguna akan dianggap sudah diterima
                                        oleh Pengguna tersebut jika dan bila:
                                    </div>
                                    <ul>
                                        <li>
                                            Kami dapat menunjukkan bahwa
                                            komunikasi itu, baik dalam bentuk
                                            fisik maupun elektronik, telah
                                            dikirimkan kepada Pengguna tersebut,
                                            atau
                                        </li>
                                        <li>
                                            Kami sudah memasang pemberitahuan
                                            tersebut di tempat di situs 
                                            <a href="https://lakugan.com">
                                                www.lakugan.com
                                            </a>
                                             yang dapat diakses oleh umum tanpa
                                            dikenai biaya.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="lakugan-term">
                            <h3>9. PENUTUP</h3>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>9.1</div>
                                <div style={{ width: "fit-content" }}>
                                    Kami dan Pengguna merupakan hubungan
                                    penghubung antara Reseller dan supplier dan
                                    tidak ada hubungan keagenan, kemitraan,
                                    usaha patungan, karyawan- perusahaan atau
                                    pemilik waralaba-pewaralaba yang akan dibuat
                                    atau dibuat dengan adanya Perjanjian ini.
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>9.2</div>
                                <div style={{ width: "fit-content" }}>
                                    Judul di dalam Perjanjian ini dibuat sebagai
                                    acuan saja, dan sama sekali tidak
                                    menetapkan, membatasi, menjelaskan atau
                                    menjabarkan apa yang ada atau tercakup dalam
                                    pasal tersebut.
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: 30 }}>9.3</div>
                                <div style={{ width: "fit-content" }}>
                                    Tidak dilaksanakannya hak Kami untuk
                                    menuntut hak Kami berdasarkan Perjanjian ini
                                    ataupun tidak diambilnya tindakan oleh Kami
                                    terhadap pelanggaran yang dilakukan oleh
                                    Pengguna terhadap Perjanjian ini tidak akan
                                    mengesampingkan atau tidak mengesampingkan
                                    hak Kami untuk mengambil tindakan terhadap
                                    pelanggaran serupa atau pelanggaran
                                    berikutnya.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <CheckTerm>
                <div className="check">
                    <CheckedStyle>
                        <label className="container">
                            Saya setuju
                            <input
                                onChange={(e) => setChecked(e.target.checked)}
                                type="checkbox"
                                name="termCondition"
                                id="termCondition"
                            />
                            <span className="checkmark"></span>
                        </label>
                    </CheckedStyle>

                    <Button
                        onClick={isChecked ? handleSubmitRegister : () => {}}
                        disabled={isChecked ? false : true}
                        color="primary"
                    >
                        Lanjut
                    </Button>
                </div>
            </CheckTerm>
        </ModalStyle>
    );
};
export default TermConditionReseller;
