import React from "react";
import Style from "./index.style";

import SectionStart from "./SectionStart";
import SectionStep from "./SectionStep";
import SectionEnd from "./SectionEnd";

const HowToJoin = () => {

    return (
        <Style>
            <section className="first">
                <SectionStart />
            </section>
            <section className="second">
                <SectionStep />
            </section>
            <section className="eighth">
                <SectionEnd />
            </section>
        </Style>
    );
};
export default HowToJoin;
