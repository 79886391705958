import React from "react";
import Style from "../index.style";
import Benefit from "../../../../Components/Assets/v2/benefit.png";

const SectionBenefit = () => {
  
  return (
    <Style>
        <div className="header">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-title">
                        <ul>
                            <li>Keuntungan <br/> Gabung Lakugan</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 benefit-mobile-image">
                        <img src={Benefit} alt="" />
                    </div>
                    <div className="col-sm-6 benefit-mobile-text">
                        <button className="btn btn-warning button-benefit">Keuntungan Jadi Reseller di Lakugan.com</button>
                        <div className="text-benefit">
                            <ul>
                                <li>Bisa bisnis tanpa modal yang tidak menyita waktu, dapat dilakukan kapan saja dan di mana saja</li>
                                <li>Cara pemasaran yang mudah hanya dengan share link produk komisi langsung masuk sesuai penjualan</li>
                                <li>Dapat komisi mulai dari 10%</li>
                                <li>Reseller dapat harga langsung dari supplier, sehingga harganya kompetitif dan tidak ada markup harga</li>
                                <li>Bisa Custom Emas untuk keperluan berbagai macam keperluan (branding, giveaway, atau pun keperluan corporate)</li>
                                <li>Banyak pilihan produk mulai dari Emas, Kosmetik, Produk Kesehatan, Pakaian, Sandal, Sepatu, Aksesoris, dll.</li>
                                <li>Pengiriman barang sudah langsung tersistem otomatis, dengan berbagai macam pilihan logistic yang ada di Indonesia</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer"></div>
    </Style>
  );
};
export default SectionBenefit;
