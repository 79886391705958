import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import styled from "styled-components";
import Pict1 from "../../../../Components/Assets/icon/lakugan-website-icon-1.png";
import Pict2 from "../../../../Components/Assets/icon/lakugan-website-icon-2.png";
import Pict3 from "../../../../Components/Assets/icon/lakugan-website-icon-3.png";
import Pict4 from "../../../../Components/Assets/icon/lakugan-website-icon-4.png";

const Style = styled.div`
  margin-bottom: 65px;
  @media (max-width: 550px) {
    margin-bottom: 0px;
  }
  .card {
    position: relative;
    border-radius: 20px;
    border: 1.5px solid #e8e8e8;
    @media (max-width: 550px) {
      border: unset;
    }
    &-body {
      padding-top: 100px;
      @media (max-width: 550px) {
        padding-top: 1.25rem;
      }
    }
  }

  .title {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    position: absolute;
    background: #fff;
    top: -33px;
    padding: 15px;
    left: 50px;
    @media (max-width: 550px) {
      font-size: 18px;
      margin-bottom: 0;
      position: unset;
      margin-bottom: 20px;
      padding: 0;
    }
  }
  .benefit {
    position: relative;
    &-card {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      text-align: center;
      top: -80px;
      img {
        margin: auto;
      }
    }
  }
  .icon-benefit {
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    text-align: center;
    @media (max-width: 550px) {
      position: unset;
      img {
        max-width: 55px;
        margin-bottom: 5px;
      }
    }
  }
  .col-title {
    line-height: 1.5;
    @media (max-width: 414px) {
      font-size: 12px;
    }
  }
  .card-benefit {
    background: #ffffff;
    border-radius: 20px;
    width: 100%;
    padding: 70px 30px 45px;
    border: 1px solid #e8e8e8;
    @media (max-width: 550px) {
      padding: 10px;
      height: 120px;
    }
    /* @media (max-width: 575px) {
            padding: 0 10px;
            margin-bottom: 10px;
        }
        > img {
            width: 68px;
        } */
  }
  .card-area {
    @media (max-width: 550px) {
      margin-bottom: 10px;
    }
  }
  .section-bottom {
    .col-6 {
      @media (max-width: 550px) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
`;

const SectionBenefit = ({ commission = {} }) => {
  return (
    <Style>
      <Card>
        <CardBody>
          <div>
            <h3 className="title">Keuntungan Reseller</h3>
          </div>
          <div className="section-bottom">
            <Row>
              <Col xl={3} md={3} xs={6}>
                <div
                  className="text-center card-area"
                  style={{ display: "flex" }}
                >
                  <div className="card-benefit">
                    <div className="icon-benefit">
                      <img src={Pict1} alt="" className="img-fluid" />
                    </div>
                    <div>
                      <h6 className="col-title text-center">
                        Bisa <span>#BisnisTanpaModal</span>
                      </h6>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={3} md={3} xs={6}>
                <div
                  className="text-center card-area"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div className="card-benefit">
                    <div className="icon-benefit">
                      <img src={Pict2} alt="" className="img-fluid" />
                    </div>
                    <h6 className="col-title text-center">
                      <span>Komisi hingga 10%</span>
                    </h6>
                  </div>
                </div>
              </Col>
              <Col xl={3} md={3} xs={6}>
                <div
                  className="text-center card-area"
                  style={{ display: "flex" }}
                >
                  <div className="card-benefit">
                    <div className="icon-benefit">
                      <img src={Pict3} alt="" className="img-fluid" />
                    </div>
                    <h6 className="col-title text-center">
                      Mulai Bisnis <br />
                      <span>#AntiRibet</span>
                    </h6>
                  </div>
                </div>
              </Col>
              <Col xl={3} md={3} xs={6}>
                <div className="text-center card-area">
                  <div className="card-benefit">
                    <div className="icon-benefit">
                      <img src={Pict4} alt="" className="img-fluid" />
                    </div>
                    <h6 className="col-title text-center">
                      Bisa <span>#BisnisModalJempol</span>
                    </h6>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Style>
  );
};
export default SectionBenefit;
