import { servicesAction } from "../../../Reducers";

const service = {
    getBanner: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/banner/customerAllBanner",
            method: "GET",
            reducer: "service",
            group: "user",
            key: "banner",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
            },
        });
    },
    newProduct: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/product/customerAllNoLogin",
            method: "GET",
            params: params,
            reducer: "service",
            group: "user",
            key: "newProduct",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
            },
        });
    },
    ratingProduct: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/product/customerAllByRating",
            method: "GET",
            params: params,
            reducer: "service",
            group: "user",
            key: "ratingProduct",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
            },
        });
    },
    commissionList: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/commission",
            method: "GET",
            reducer: "service",
            group: "commission",
            key: "commissionList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Commission List",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    FAQ: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/faq",
            params: params,
            qs: true,
            method: "GET",
            reducer: "service",
            group: "user",
            key: "faqList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Bad Request",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
    getProduct: ({ dispatch, slug }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/product/list/${slug}`,
            method: "GET",
            reducer: "service",
            group: "user",
            key: "product",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
            },
        });
    },
    getProductClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "product",
            type: "CLEAR",
        });
    },
    commissionListClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "commission",
            key: "commissionList",
            type: "CLEAR",
        });
    },
    newProductClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "newProduct",
            type: "CLEAR",
        });
    },
};
export default service;
