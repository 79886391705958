import React from "react";
import { Link } from "react-router-dom";
import { color } from "../../../Utils/Variable";
import {
    Col,
    Row,
} from "reactstrap";
import styled from "styled-components";
import Image from "../../../Components/Assets/Images/maintenance.png";
import Logo from "../../../Components/Assets/Images/Lakugan_LogoColor_BrightBG.png";
import LogoMobile from "../../../Components/Assets/icon/Lakugan_Logo_Circle.png";
import Facebook from "../../../Components/Assets/icon/facebook.png";
import Youtube from "../../../Components/Assets/icon/youtube.png";
import Instagram from "../../../Components/Assets/icon/instagram.png";
import Twitter from "../../../Components/Assets/icon/twitter.png";

const Header = styled.header`
    position: ${(props) => (props.scroll > 385 ? "fixed" : "absolute")};
    padding: 20px 0;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    left: 0;
    right: 0;
    width: 100%;
    background-color: ${color.white};
    top: ${(props) =>
        props.scroll < 285 && props.scroll > 250 ? "-100px" : "0"};
    z-index: 99;
    transition: all 1.25s ease;
    @media (max-width: 500px) {
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 10px;
        box-shadow: unset;
    }
    ${(props) =>
        props.responsive &&
        props.responsive.map((item) => {
            return `@media (max-width: ${item.breakpoint}px) {
                display: ${item.isHeader ? "block" : "none"};
            }`;
    })}
    .container {
        padding: 0 20px;
    }
    .header {
        padding: 0 40px;
        @media (max-width: 550px) {
            padding: 0 20px;
        }
        &-area {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }
    .logo {
        width: 200px;
        @media (max-width: 575px) {
            width: 130px;
        }
    }
    .desktop-lakugan {
        height: 40px !important;
        @media (max-width: 550px) {
            width: 30px;
            height: 30px !important;
        }
    }
`;

export const Footer = styled.footer`
    position: ${(props) => (props.scroll > 385 ? "fixed" : "absolute")};
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    left: 0;
    right: 0;
    width: 100%;
    background-color: ${color.white};
    bottom: ${(props) =>
        props.scroll < 285 && props.scroll > 250 ? "-100px" : "0"};
    z-index: 99;
    transition: all 1.25s ease;
    @media (max-width: 500px) {
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 10px;
        box-shadow: unset;
    }
    background: ${color.white};
    margin-top: 40px;
    .footer {
        &-top {
            background: ${color.primary};
            padding: 20px;
            color: #fff;
            @media (max-width: 550px) {
                padding: 10px;
            }
            .img-lakugan {
                margin-bottom: 20px;
                display: block;
                @media (max-width: 575px) {
                width: 100px;
                }
            }
            &__border {
                padding-bottom: 30px;
                border-bottom: 1.5px solid rgba(255, 255, 255, 0.4);
                @media (max-width: 550px) {
                display: none;
                }
            }
            @media (max-width: 767px) {
                padding-left: 0;
                padding-right: 0;
            }
        }
        &-bottom {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
            padding: 16px 0px;
            font-size: 16px;
            @media (max-width: 550px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1.5px solid rgba(232, 232, 232, 0.2);
                font-size: 14px;
                margin-bottom: 10px;
                position: relative;
                &:before {
                content: "";
                position: absolute;
                width: 7px;
                height: 7px;
                background: #fff;
                border-radius: 50%;
                top: calc(100% - 54%);
                right: calc(100% - 53%);
                }
            }
        }
    }
    .copyright {
        font-size: 16px;
        color: #fff;
        display: block;
        margin-bottom: 20px;
        display: table-cell;
        vertical-align: middle;
        @media (max-width: 550px) {
            display: block;
            font-size: 14px;
            text-align: center;
            font-weight: 200;
            margin-bottom: 0;
        }
    }
    .footer-top {
        padding: 10px;
    }
`;

const Maintenance = (props) => {
    const [scrollTop, setScrollTop] = React.useState(0);
    const { menu = true, responsive = false } = props;
    return (
        <div>
            <Header
                id="header"
                scroll={scrollTop}
                responsive={responsive}
            >
                <div className="header">
                    <div className="header-area">
                        <div className="desktop-lakugan">
                            <Link to="#">
                                <img
                                    src={
                                        window.screen.width > 550
                                            ? Logo
                                            : LogoMobile
                                    }
                                    alt="Lakugan"
                                    className="img-fluid logo"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </Header>
            <div className="vh-100 d-flex justify-content-center align-items-center">
                <img src={Image} alt="503" className="img-fluid" />
            </div>
            <Footer
                id="footer"
            >
                <div className="footer-top">
                    <div className="container">
                        <Row className="justify-content-between">
                            <Col xl="4" lg="3" md="6" sm="6" style={{ display: "table" }}>
                                <span className="copyright">
                                    &copy; {new Date().getFullYear()} Lakugan, Inc. All rights reserved
                                </span>
                            </Col>
                            <div className="share-icon">
                            <a
                                href="https://www.facebook.com/Lakugan.id/"
                                className="social-media__link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                style={{
                                    width: 32,
                                    marginRight: 10,
                                }}
                                src={Facebook}
                                alt=""
                                className="img-fluid"
                                />
                            </a>
                            <a
                                href="https://www.instagram.com/lakugan_id/"
                                className="social-media__link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                style={{
                                    width: 32,
                                    marginRight: 10,
                                }}
                                src={Instagram}
                                alt=""
                                className="img-fluid"
                                />
                            </a>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.youtube.com/channel/UChz841F6gxoHYHtyTg-Zi_w"
                                className="social-media__link"
                            >
                                <img
                                style={{
                                    width: 32,
                                    marginRight: 10,
                                }}
                                src={Youtube}
                                alt=""
                                className="img-fluid"
                                />
                            </a>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://twitter.com/lakugan_id"
                                className="social-media__link"
                            >
                                <img
                                style={{
                                    width: 32,
                                    marginRight: 10,
                                }}
                                src={Twitter}
                                alt=""
                                className="img-fluid"
                                />
                            </a>
                            </div>
                        </Row>
                    </div>
                </div>
            </Footer>
        </div>
    );
};
export default Maintenance;
