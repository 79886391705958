import React from "react";
import styled from "styled-components";

const Style = styled.span`
    font-size: 40px;
    display: block;
    text-align: center;
    font-weight: bold;
    color: #f34d14;
`;

const CountDown = ({ date }) => {
    const [time, setTime] = React.useState("00:00:00");

    React.useEffect(() => {
        var countDownDate = new Date(date).getTime();
        var x = setInterval(function () {
            var now = new Date().getTime();
            var distance = countDownDate - now;
            var hours = String(
                Math.floor(
                    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                )
            );
            var minutes = String(
                Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            );
            var seconds = String(Math.floor((distance % (1000 * 60)) / 1000));

            setTime(
                hours.padStart(2, "0") +
                    ":" +
                    minutes.padStart(2, "0") +
                    ":" +
                    seconds.padStart(2, "0")
            );
            if (distance < 0) {
                clearInterval(x);
                setTime("Waktu Pembayaran telah Kadaluarsa");
            }
        }, 1000);
    }, [date]);
    return <Style>{time}</Style>;
};
export default CountDown;
