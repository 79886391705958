import { servicesAction } from "../../Reducers";

const service = {
    category: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/category/?filters=[["type","=","0"]]`,
            method: "GET",
            token: true,
            qs: true,
            reducer: "service",
            group: "category",
            key: "category",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    updateProduct: ({ dispatch, data, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/product/${id}`,
            method: "PUT",
            token: true,
            data: data,
            reducer: "service",
            group: "product",
            key: "createProduct",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    product: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/product",
            method: "GET",
            reducer: "service",
            group: "product",
            token: true,
            key: "productList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Bad Request",
                },
            },
        });
    },
};
export default service;
