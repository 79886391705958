import React from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import styled from "styled-components";
import Form from "../../../../Components/Molekul/FormValidation";
import Button from "../../../../Components/Particle/Button";
import { useStore } from "../../../../Reducers";
import { formatMoney } from "../../../../Utils/PriceConverter";
import { color } from "../../../../Utils/Variable";
import service from "../index.service";
const Style = styled(Modal)`
    .desc {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px dashed #888888;
        span {
            display: block;
            color: #888888;
            font-size: 14px;
        }
        &-transaction {
            font-size: 14px;
            margin-bottom: 10px;
            &__detail {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
            }
            &__total {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: bold;
                margin-bottom: 50px;
            }
        }
        &-refund {
            font-weight: bold;
            color: ${color.primary};
        }
    }
    .status {
        margin-bottom: 15px;
        .fas {
            margin-right: 10px;
            border-radius: 50%;
        }
        .success {
            box-shadow: 0 2px 4px #28a745;
        }
        span {
            font-size: 14px;
        }
    }
    .cancel-column {
        padding: 10px 0;
        border-top: 1px dashed #888888;
    }
`;

const ModalComplaint = ({
    setRefresh = () => {},
    isShow = false,
    toggle = () => {},
    data = false,
    setLoading = () => {},
}) => {
    const { dispatch } = useStore();
    const [form, setForm] = React.useState([
        {
            name: "reason",
            label: "Alasan",
            type: "select",
            action: "select",
            col: {md: 6},
            option: [
                {
                    label: "Saya tidak menerima pesanan",
                    value: "1",
                },
                {
                    label: "Saya menerima produk yang salah (salah ukuran, salah warna atau produk berbeda)",
                    value: "2",
                },
                {
                    label: "Produk yang diterima tidak lengkap",
                    value: "3",
                },
                {
                    label: "Produk yang diterima cacat",
                    value: "4",
                },
                {
                    label: "Produk yang diterima tidak berfungsi dengan baik",
                    value: "5",
                },
                {
                    label: "Produk yang diterima berbeda dengan deskripsi/foto",
                    value: "6",
                },
                {
                    label: "Produk yang diterima tidak original",
                    value: "7",
                },
            ],
            valid: "Pilih alasan",
            value: "",
            status: false,
            required: true,
        },
        {
            name: "image-multiple",
            label: "Tambah Bukti Foto",
            type: "image",
            action: "uploadImageMultiple",
            col: 12,
            required: true,
            valid: "Harap masukkan gambar",
            value: [],
            status: false,
            max: 3,
        },
        {
            name: "complaint",
            label: "Keterangan",
            placeholder: "Keterangan pengembalian (opsional)",
            type: "textarea",
            action: "textarea",
            required: false,
            rows: 4,
            col: 12,
            valid: "Harap masukkan keluhan",
            value: "",
            status: true,
            max: 3,
        },
        {
            name: "solution",
            label: "Solusi",
            type: "select",
            action: "select",
            col: {md: 6},
            option: [
                {
                    label: "Pengembalian Barang dan Dana",
                    value: "1",
                },
                {
                    label: "Pengembalian Dana",
                    value: "2",
                },
                {
                    label: "Penukaran Barang",
                    value: "3",
                },
            ],
            valid: "Pilih solusi",
            value: "",
            status: false,
            required: true,
        },
    ]);
    const [validationClick, setValidationClick] = React.useState(true);
    const [validateForm, setValidateForm] = React.useState(true);
    const [showRefund, setShowRefund] = React.useState(true);
    const [transactionDate, setTransactionDate] = React.useState("");
    const handleSubmitComplaint = (e) => {
        let isError = false;
        e.preventDefault();
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setLoading(true);
            let getTransaction = data?.transaction_code.split("/");
            let transaction_code = getTransaction[1];
            let dataPost = {
                reason: form[0].value.label,
                images: form[1].value.map((item) => {
                    return { img_url: item.link };
                }),
                description: form[2].value,
                solution: form[3].value.label,
            };
            service.transactionComplaint({
                dispatch,
                transaction_code,
                data: dataPost,
            });
            setRefresh(false);
        }
    };
    const checkFormValue = () => {
        if(form[3].value !== "" && form[3].value.value !== "3") {
            setShowRefund(true)
        } else {
            setShowRefund(false)
        }
    }
    React.useEffect(() => {
        if (data?.customer_detail) {
            let getTime = data.transaction_code.split("/");
            let timestamp = getTime[1];
            let getFullDate = new Date(Number(timestamp));
            setTransactionDate(
                `${getFullDate.getFullYear()}-${String(
                    getFullDate.getMonth()
                ).padStart(2, "0")}-${String(getFullDate.getDate()).padStart(
                    2,
                    "0"
                )}`
            );
        }
    }, [data]);
    return (
        <Style size="lg" isOpen={isShow} toggle={toggle}>
            {data && (
                <React.Fragment>
                    <ModalHeader toggle={toggle}>
                        {data?.product?.name}
                    </ModalHeader>
                    <ModalBody>
                        <div className="status">
                            <i className="fas fa-circle text-success success"></i>
                            <span>Diterima</span>
                        </div>
                        <h6 style={{ textTransform: "capitalize" }}>
                            {data?.customer_detail?.full_name}
                        </h6>
                        <div className="desc">
                            <span>{data?.total_amount} items</span>
                            <span>
                                {data?.customer_detail?.email} /{" "}
                                {data?.customer_detail?.phone_number}
                            </span>
                            <span>{data?.customer_detail?.address}</span>
                            <span>{data?.shipper?.rate_name}</span>
                        </div>
                        <div className="desc-transaction">
                            <div className="desc-transaction__detail">
                                <div>Nomor Transaksi</div>
                                <div style={{ overflow: "auto" }}>
                                    {data?.transaction_code}
                                </div>
                            </div>
                            <div className="desc-transaction__detail">
                                <div>Reseller</div>
                                <div>{data?.reseller?.full_name}</div>
                            </div>
                            <div className="desc-transaction__detail ">
                                <div>Tanggal Transaksi</div>
                                <div>{transactionDate}</div>
                            </div>
                        </div>
                        <div className="desc-transaction__total">
                            <div>Total Transaksi</div>
                            <div>{formatMoney(data?.total_cost)}</div>
                        </div>
                        <div className="mb-2">
                            <Form
                                setForm={setForm}
                                validateForm={validateForm}
                                validationClick={validationClick}
                                form={form}
                                onCheckValue={checkFormValue}
                            />
                        </div>
                        {
                            showRefund && 
                            <Row className="mb-2 desc-refund">
                                <Col xs="7" md="4">
                                    <div>Dana Pengembalian</div>
                                </Col>
                                <Col xs="5" md="8">
                                    <div className="text-right text-md-left">{formatMoney(data?.total_cost - data?.admin_fee)}</div>
                                </Col>
                            </Row>
                        }
                        <div className="text-right">
                            <Button
                                type="button"
                                color="primary"
                                onClick={handleSubmitComplaint}
                            >
                                Kirim
                            </Button>
                        </div>
                    </ModalBody>
                </React.Fragment>
            )}
        </Style>
    );
};
export default ModalComplaint;
