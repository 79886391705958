import styled from "styled-components";
const Style = styled.div`
  margin-bottom: 50px;
  .title {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    @media (max-width: 550px) {
      font-size: 18px;
      margin-bottom: 0;
    }
  }
  .col {
    &-payment {
      //   margin-right: 30px;
      @media (max-width: 550px) {
        // margin-bottom: 40px;
        .title {
          margin-bottom: 10px;
        }
      }
    }
    &-shipper {
      margin-left: 30px;
      @media (max-width: 550px) {
        margin-left: 0px;
        .title {
          margin-bottom: 10px;
        }
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .grid {
    &-payment {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, 3vw);
      grid-gap: 1rem;
      @media (max-width: 550px) {
        grid-template-rows: repeat(3, 10vw);
      }
    }
    &-shipper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, 3vw);
      grid-gap: 1rem;
      @media (max-width: 550px) {
        grid-template-rows: repeat(3, 10vw);
      }
    }
    &-item {
      &__1 {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;
      }
      &__2 {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
      }
      &__3 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 1;
      }
      &__4 {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 1;
      }
      &__5 {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 3;
        grid-row-end: 3;
      }
      &__6 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 2;
      }
      &__7 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;
      }
      &__8 {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 2;
        grid-row-end: 2;
      }
      &__9 {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 1;
      }
      &__10 {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 1;
      }
      &__11 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;
      }
      &__13 {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 2;
      }
      &__14 {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 2;
        grid-row-end: 2;
      }
      &__15 {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 3;
      }
    }
  }
`;
export default Style;
