import React, { Fragment } from "react";
import Content from "./content";
import styled from "styled-components";
// import FirstModalLogin from "./FirstModal";
import { useStore } from "../../../Reducers";
import service from "./index.service";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import EmptyProduct from "../../../Components/Assets/Product/empty_product.jpg";
import LocalStorage from "../../../Utils/LocalStorage";
// import Style from "./style";

const Style = styled.div`
    .main {
        .side {
            width: 20%;
            display: flex;
            justify-content: center;
            border-right: 1px solid #dedede;
        }
        .content {
            transition: all 0.25s ease;
            background: #f5f5fb;
        }
    }
`;

const Home = () => {
    const { dispatch, state } = useStore();
    const [isFirstLogin, setFirstLogin] = React.useState(false);
    const isFirstGet = true;
    const token = LocalStorage().get("auth");
    const auth = LocalStorage().get(`${token?.access_token}`);
    React.useEffect(() => {
        if (isFirstGet) {
            service.getTransactionAddress({ dispatch });
        }
    }, [isFirstGet, dispatch]);
    React.useEffect(() => {
        if (state?.supplier?.transaction_address?.isSuccess) {
            setFirstLogin(state.supplier.transaction_address.data);
            service.TransactionAddressClear({ dispatch });
        }
    }, [state, dispatch]);
    return (
        <Fragment>
            {isFirstLogin && (
                <Fragment>
                    <Style>
                        <section>
                            <div className="main">
                                <div className="content">
                                    {auth.verify_id === true ? (
                                        <Content />
                                    ) : auth.update_data === false ? (
                                        <Card>
                                            <CardBody>
                                                <div className="text-center">
                                                    <img
                                                        src={EmptyProduct}
                                                        alt="product_empty"
                                                        className="img-"
                                                    />
                                                    <h2 className="data-null">
                                                        {auth.verifikasi_message ===
                                                        ""
                                                            ? "Yuk Lengkapi datamu, agar bisa berjualan di lakugan!"
                                                            : auth.verifikasi_message}
                                                    </h2>
                                                    <Link
                                                        className="btn btn-primary"
                                                        to="/supplier/profile"
                                                    >
                                                        Lengkapi Data
                                                    </Link>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    ) : (
                                        <Card>
                                            <CardBody>
                                                <div className="text-center">
                                                    <img
                                                        src={EmptyProduct}
                                                        alt="product_empty"
                                                        className="img-"
                                                    />
                                                    <h2 className="data-null">
                                                        Data kamu sudah dikirim,
                                                        tunggu 1x 24 jam untuk
                                                        verifikasi akun Anda
                                                    </h2>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    )}
                                    {/* {isFirstLogin.length < 1 ? (
                                        <Card>
                                            <CardBody>
                                                <div className="text-center">
                                                    <img
                                                        src={EmptyProduct}
                                                        alt="product_empty"
                                                        className="img-"
                                                    />
                                                    <h2 className="data-null">
                                                        {auth.verifikasi_message ===
                                                        ""
                                                            ? "Yuk Lengkapi datamu, agar bisa berjualan di lakugan!"
                                                            : auth.verifikasi_message}
                                                    </h2>
                                                    <Link
                                                        className="btn btn-primary"
                                                        to="/supplier/profile"
                                                    >
                                                        Lengkapi Data
                                                    </Link>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    ) : (
                                        <Content />
                                    )} */}
                                </div>
                            </div>
                        </section>
                    </Style>
                </Fragment>
            )}
        </Fragment>
    );
};

export default Home;
