import React from "react";
import { Col, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";
import styled from "styled-components";
import { formatMoney } from "../../../../Utils/PriceConverter";
import { color } from "../../../../Utils/Variable";
// import ImageItem from "../../../../Components/Assets/Images/image_16.png";
import Logo from "../../../../Components/Assets/Images/Lakugan_LogoColor_BrightBG.png";
import { useStore } from "../../../../Reducers";
import service from "../index.service";
import Rating from "../../../../Components/Particle/Rating";
import Button from "../../../../Components/Particle/Button";
import ReturnForm from "./ReturnForm";
const Style = styled(Modal)`
    color: #4a4a4a;
    .title {
        color: #4a4a4a;
        width: 164px;
        font-size: 14px;
        font-weight: 500;
    }
    .view-image {
        cursor: pointer;
    }
    .desc {
        padding-bottom: 10px;
        margin-bottom: 30px;
        border-bottom: 1px dashed #4a4a4a;
        &-transaction {
            font-size: 14px;
            margin-bottom: 10px;
            &__detail {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
            }
            &__total {
                color: ${color.primary};
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: bold;
                margin-bottom: 20px;
            }
        }

        &-value {
            color: ${color.primary};
            font-size: 14px;
            font-weight: 500;
            width: calc(100% - 164px);
            overflow: auto;
        }

        &-complaint-receipt {
            padding-top: 30px;
            margin-bottom: 30px;
            border-top: 1px dashed #4a4a4a;
        }

        th,
        td {
            font-size: 14px;
        }
    }
    .order-picture {
        margin: auto;
        img {
            width: 200px;
            height: 200px;
        }
        h6 {
            font-size: 18px;
            color: #4a4a4a;
            font-weight: 500;
            margin-top: 30px;
        }
    }
    .order-title {
        font-size: 16px;
        margin-right: 10px;
    }
    .btn-product__info {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        border: 2px solid #77e879;
        background: #77e879;
        color: #fff;
        width: 100%;
        font-weight: bold;
        font-size: 14px;
        .fa-whatsapp {
            font-size: 20px;
            font-weight: bold;
        }
        @media (max-width: 767px) {
            margin-bottom: 15px;
        }
    }
`;

const DetailOrder = ({
    isShow = false,
    setOrderIdNumber = () => {},
    orderIdNumber = "",
    toggle = () => {},
    data = false,
    getTracking = false,
    handleShowTracking = () => {},
    setRefresh = () => {},
}) => {
    const [transactionDate, setTransactionDate] = React.useState("");
    const [imageView, setImageView] = React.useState("");
    const [showImage, setShowImage] = React.useState(false);
    const [showReceiptForm, setShowReceiptForm] = React.useState(false);
    const [showReturnButton, setShowReturnButton] = React.useState(false);
    const { dispatch, state } = useStore();
    const viewImage = (image) => {
        setImageView(image);
        setShowImage(true);
    };
    const complaintElement = () => {
        return (
            <div className="desc">
                <div className="mb-4">
                    <div className="title mb-1">Alasan Pengembalian: </div>
                    <div style={{ fontSize: 14 }}>
                        {data?.complaint?.reason}
                    </div>
                </div>
                <div className="mb-4">
                    <div className="title mb-1">Detail Keluhan: </div>
                    <div style={{ fontSize: 14 }}>
                        {data?.complaint?.description || "-"}
                    </div>
                </div>
                <div className="mb-4">
                    <div className="title mb-1">Solusi: </div>
                    <div style={{ fontSize: 14 }}>
                        {data?.complaint?.solution}
                    </div>
                </div>
                <div className="mb-4">
                    <div className="title mb-1">Foto Keluhan: </div>
                    <Row>
                        {data?.complaint?.images.map((item, key) => {
                            return (
                                <Col sm={3} key={key}>
                                    <div
                                        className="view-image"
                                        onClick={() => viewImage(item.img_url)}
                                    >
                                        <img
                                            src={item.img_url}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            </div>
        );
    };

    const complaintReceiptElement = () => {
        return (
            <div className="desc-complaint-receipt">
                <Row>
                    <Col md={6}>
                        <div className="desc-transaction__total mb-2">
                            Resi Pengembalian
                        </div>
                        <div className="mb-1">
                            <div className="title mb-1">
                                {data?.complaint.from_customer?.logistic_name}
                            </div>
                            <div style={{ fontSize: 14 }}>
                                {data?.complaint.from_customer?.serial_number}
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="desc-transaction__total mb-2">
                            Resi Pengembalian
                        </div>
                        {!data?.complaint.from_client ? (
                            "-"
                        ) : (
                            <div className="mb-1">
                                <div className="title mb-1">
                                    {data?.complaint.from_client?.logistic_name}
                                </div>
                                <div style={{ fontSize: 14 }}>
                                    {data?.complaint.from_client?.serial_number}
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        );
    };
    const handleToggleModal = () => setShowReceiptForm(!showReceiptForm);
    React.useEffect(() => {
        if (data?.customer_detail) {
            let getTime = data.transaction_code.split("/");
            let timestamp = getTime[1];
            let getFullDate = new Date(Number(timestamp));
            setTransactionDate(
                `${getFullDate.getFullYear()}-${String(
                    getFullDate.getMonth() + 1
                ).padStart(2, "0")}-${String(getFullDate.getDate()).padStart(
                    2,
                    "0"
                )}`
            );
        }

        if (data?.status === 21) {
            setShowReturnButton(
                data?.complaint?.solution !== "Pengembalian Dana"
            );
        } else {
            setShowReturnButton(false);
        }
    }, [data]);
    React.useEffect(() => {
        if (state?.shipper?.orderId?.isSuccess) {
            setOrderIdNumber(state.shipper.orderId.data.data.id);
            service.orderDetailClear({ dispatch });
        }
    }, [state, dispatch, setOrderIdNumber]);
    return (
        <Style isOpen={isShow} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>
                <img src={Logo} alt="Lakugan" className="img-fluid" />
            </ModalHeader>
            <ModalBody>
                {data?.customer_detail && (
                    <React.Fragment>
                        <div className="desc">
                            <div className="mb-4">
                                <Row className="justify-content-between">
                                    <Col xl="6" lg="6" md="6" sm="12">
                                        <h6 className="mb-2 border-bottom pb-2">
                                            Data Pembeli
                                        </h6>
                                        <div className="d-flex">
                                            <div className="title">Nama</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {
                                                    data?.customer_detail
                                                        .full_name
                                                }
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Email</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data?.customer_detail.email}
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Telepon</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {
                                                    data?.customer_detail
                                                        .phone_number
                                                }
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Alamat</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data?.customer_detail.address}
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">
                                                Metode Pembayaran
                                            </div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data?.payment_channel.name}
                                            </div>
                                        </div>
                                        {data?.shipper && (
                                            <div className="d-flex">
                                                <div className="title">
                                                    Metode Pengiriman
                                                </div>
                                                <div className="mr-2">:</div>
                                                <div className="desc-value">
                                                    {data?.shipper?.rate_name}
                                                </div>
                                            </div>
                                        )}
                                        <div className="d-flex">
                                            <div className="title">
                                                Komisi saya
                                            </div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {formatMoney(
                                                    data?.product_commission_transaction
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="5" lg="5" md="5" sm="12">
                                        <h6 className="mb-2 border-bottom pb-2">
                                            Data Supplier
                                        </h6>
                                        <div className="d-flex">
                                            <div className="title">Nama</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data?.product.supplier.name}
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Telepon</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {
                                                    data?.product.supplier
                                                        .phone_cs
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="12">
                                        <div className="text-center">
                                            <div className="mb-4">
                                                <div className="order-picture">
                                                    <h6>Pemesanan</h6>
                                                    <img
                                                        src={
                                                            data?.product
                                                                .product_image[0]
                                                                .img_url
                                                        }
                                                        alt=""
                                                        className="img-fluid mb-3"
                                                    />
                                                    <h4 className="text-center">
                                                        {data?.product.name}
                                                    </h4>
                                                    <h4 className="text-center">
                                                        {formatMoney(
                                                            data.product_fee
                                                        )}
                                                    </h4>

                                                    {data.is_rated && (
                                                        <div
                                                            className="d-block mx-auto"
                                                            style={{
                                                                width: "max-content",
                                                            }}
                                                        >
                                                            <Rating
                                                                size={"24px"}
                                                                totalStars={5}
                                                                rating={
                                                                    data.rating
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <div className="order-title">
                                                    Jumlah
                                                </div>
                                                <div className="mr-2">:</div>
                                                <div
                                                    className="desc-value"
                                                    style={{ fontSize: 16 }}
                                                >
                                                    {data?.total_amount} Barang
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <span>Spesifikasi Pemesanan :</span>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Warna</th>
                                        <th>Ukuran</th>
                                        <th>Jumlah Barang</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{data?.product?.spec[0]?.color}</td>
                                        <td>{data?.product?.spec[0]?.size}</td>
                                        <td>{data?.total_amount}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div className="mb-4">
                                <div className="title mb-1">
                                    Catatan Pembeli:{" "}
                                </div>
                                <div style={{ fontSize: 14 }}>
                                    {data?.customer_detail.address_direction}
                                </div>
                            </div>
                            {/* <span>JNE REG</span> */}
                        </div>
                        {data?.status >= 14 && complaintElement()}
                        <div className="desc-transaction">
                            {orderIdNumber !== "" && (
                                <div className="desc-transaction__detail">
                                    <div>Order ID</div>
                                    <div>{orderIdNumber}</div>
                                </div>
                            )}
                            <div className="desc-transaction__detail">
                                <div>Nomor Transaksi</div>
                                <div style={{ overflow: "auto" }}>
                                    {data?.transaction_code}
                                </div>
                            </div>
                            <div className="desc-transaction__detail ">
                                <div>Tanggal Transaksi</div>
                                <div>{transactionDate}</div>
                            </div>
                        </div>
                        <div className="desc-transaction__total">
                            <div>Total Transaksi</div>
                            <div>{formatMoney(data?.total_cost)}</div>
                        </div>
                        {data?.status >= 22 && complaintReceiptElement()}
                    </React.Fragment>
                )}

                <div className="text-right">
                    <Row>
                        <Col sm={showReturnButton ? 6 : 12}>
                            <a
                                rel="noopener noreferrer"
                                href={`https://api.whatsapp.com/send?phone=${data?.product?.supplier?.phone_cs.replace(
                                    "0",
                                    "62"
                                )}&text=Hallo ka ${
                                    data?.product?.supplier?.name
                                }, ada pesanan ${data?.total_amount} ${
                                    data?.product?.name
                                } dari lakugan, mohon di proses ya, terimakasih ?`}
                                target="_blank"
                                className="btn btn-product__info"
                                type="button"
                            >
                                <i className="fab fa-whatsapp mr-2"></i> Hubungi
                                Penjual
                            </a>
                        </Col>
                        {showReturnButton && (
                            <Col sm={6}>
                                <Button
                                    color="primary"
                                    className="btn-block"
                                    onClick={handleToggleModal}
                                >
                                    Input Resi
                                </Button>
                            </Col>
                        )}
                    </Row>
                </div>
            </ModalBody>
            <ReturnForm
                data={data}
                toggle={handleToggleModal}
                isShow={showReceiptForm}
                setRefresh={setRefresh}
                toggleDetailModal={toggle}
            />
            <Modal
                size={"xl"}
                isOpen={showImage}
                toggle={() => setShowImage(false)}
            >
                <img src={imageView} alt="" className="img-fluid" />
            </Modal>
        </Style>
    );
};
export default DetailOrder;
