export const calculateCommission = async ({
    product,
    commission_type,
    total_amount,
    commissionList,
    product_fee,
}) => {
    let result = 0;
    let commission = {};
    if (product_fee !== undefined) {
        if (commissionList && commissionList.length > 0) {
            if (product.is_special_cut) {
                commission = await commissionList.find((v) => v.id === 2);
                if (commission) {
                    result =
                        total_amount *
                        ((product.nominal_special_cut *
                            commission[commission_type]) /
                            100);
                }
            } else {
                commission = await commissionList.find((v) => v.id === 1);
                if (commission) {
                    result =
                        total_amount *
                        ((product_fee * commission[commission_type]) / 100);
                }
            }
        }
    }
    return result;
};
