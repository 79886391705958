import React from "react";
import { Col, Row } from "reactstrap";
import Accordion from "../Accordion";
import styled from "styled-components";

const Style = styled.div`
    @media (max-width: 575px) {
        p {
            font-size: 14px;
        }
    }
`;
const Collapse = ({ item }) => {
    return (
        <Style>
            <Row>
                <Col xl="12">
                    {item.question_answer_list.length > 0 &&
                        item.question_answer_list.map((value, key) => {
                            return (
                                key < 5 && <Accordion key={key} item={value} />
                            );
                        })}
                </Col>
            </Row>
        </Style>
    );
};
export default Collapse;
