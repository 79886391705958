import styled from "styled-components";
import { color } from "../../../Utils/Variable";

export const Style = styled.div`
    margin-bottom: 30px;
    .not-found {
        text-align: center;
        padding: 30px 0;
        border-bottom: 1.5px solid #e8e8e8;
        img {
            margin-bottom: 20px;
            @media (max-width: 550px) {
                max-width: 60%;
            }
        }
        .wording {
            font-size: 24px;
            color: #000000;
            font-weight: 600;
            @media (max-width: 550px) {
                font-size: 14px;
            }
        }
    }
`;
export const SectionStyle = styled.div`
    padding-bottom: 30px;
    margin-bottom: 60px;
    .header-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .see-all {
            font-weight: bold;
            color: ${color.primary};
            font-size: 16px;
            i {
                margin-left: 5px;
            }
        }
    }
    .title {
        margin-bottom: 15px;
        font-weight: bold;
        font-size: 24px;
        color: #000000;
        @media (max-width: 550px) {
            font-size: 18px;
        }
    }
    .see-more {
        text-align: center;
        margin-top: 25px;
    }
`;
