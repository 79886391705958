import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import Button from "../../../Components/Particle/Button";
import CustomForm from "../../../Components/Molekul/FormValidation";
import Logo from "../../../Components/Assets/Images/Lakugan_Logo.png";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Alert } from "reactstrap";
import PictReseller from "../../../Components/Assets/Images/sign_up_2.jpg";
import PictSupplier from "../../../Components/Assets/Images/sign_up_1.jpg";
// import service from "./services";
import { useStore } from "../../../Reducers";
// import JSON_to_URLEncoded from "../../../Utils/JSON-URLEncode";
import { color } from "../../../Utils/Variable";
import service from "./index.service";
import TermConditionReseller from "./TermCondition/Reseller";

const Style = styled.div`
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .lakugan-title {
        font-weight: bold;
        margin-bottom: 30px;
        @media (max-width: 1200px) {
            font-size: 20px;
        }
        @media (max-width: 767px) {
            text-align: center;
        }
    }
    .login-page {
        height: 100%;
        width: 100%;
        display: flex;
        overflow: scroll;
    }
    .btn-custom {
        border-radius: 5px;
    }
    .background-image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #4a4a4a;
    }
    .card {
        &-image {
            width: 65%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @media (max-width: 767px) {
                display: none;
            }
        }
        &-form {
            padding: 20px;
            display: flex;
            align-items: center;
            height: 100%;
            width: 35%;
            overflow: auto;
            form {
                width: 100%;
            }
            .lakugan-logo {
                width: 150px;
                margin-bottom: 30px;
                display: block;
                @media (max-width: 767px) {
                    display: block;
                    margin: 0 auto 20px;
                }
            }
            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }
    .login {
        display: block;
        margin-top: 10px;
        font-size: 14px;
        a {
            color: ${color.lakugan};
        }
        @media (max-width: 450px) {
            font-size: 12px;
        }
    }
`;

const SignUp = () => {
    const [validationClick, setValidationClick] = useState(true);
    const [validateForm, setValidateForm] = useState(true);
    const history = useHistory();
    const [isFailed, setFailed] = useState(false);
    const [isTerm, setTerm] = useState(false);
    const [status, setStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isShow, setShow] = useState(false);
    const { dispatch, state } = useStore();
    const [message, setMessage] = useState(false);
    const [formReferral, setFormReferral] = useState([
        {
            type: "text",
            action: "text",
            name: "referal",
            label: "Kode Referal (Opsional)",
            col: 12,
            valid: "Nama tidak boleh kosong",
            value: "",
            status: true,
            character: {
                min: {
                    length: "0",
                },
                max: {
                    length: "20",
                },
            },
        },
    ]);
    const [formSupplier, setFormSupplier] = useState([
        {
            type: "text",
            action: "text",
            name: "username",
            label: "Nama Lengkap",
            col: 12,
            valid: "Nama tidak boleh kosong",
            value: "",
            required: true,
            status: false,
        },
        {
            type: "text",
            action: "text",
            name: "store_name",
            label: "Nama Toko",
            col: 12,
            valid: "Nama toko tidak boleh kosong",
            value: "",
            status: false,
            required: true,
        },
        {
            type: "phone",
            action: "phone",
            name: "phone",
            label: "Nomor Telepon",
            col: 12,
            valid: "Nomor telepon tidak boleh kosong",
            value: "",
            status: false,
            required: true,
        },
        {
            type: "email",
            action: "email",
            name: "email",
            label: "Email",
            col: 12,
            valid: "Email tidak boleh kosong",
            value: "",
            required: true,
            status: false,
        },
        {
            type: "password",
            action: "password",
            required: true,
            name: "password",
            label: "Kata Sandi",
            col: 12,
            valid: "Kata sandi tidak boleh kosong",
            value: "",
            status: false,
        },
    ]);
    const [form, setForm] = useState([
        {
            type: "text",
            action: "text",
            required: true,
            name: "username",
            label: "Nama Lengkap",
            col: 12,
            valid: "Nama tidak boleh kosong",
            value: "",
            status: false,
        },
        {
            type: "phone",
            action: "phone",
            required: true,
            name: "phone",
            label: "Nomor Telepon",
            col: 12,
            valid: "Nomor telepon tidak boleh kosong",
            value: "",
            status: false,
        },
        {
            type: "email",
            action: "email",
            required: true,
            name: "email",
            label: "Email",
            col: 12,
            valid: "Email tidak boleh kosong",
            value: "",
            status: false,
        },
        {
            type: "password",
            action: "password",
            required: true,
            name: "password",
            label: "Kata Sandi",
            col: 12,
            valid: "Kata sandi tidak boleh kosong",
            value: "",
            status: false,
        },
    ]);
    const handlerSubmitData = (e) => {
        let isError = false;

        let isErrorSupplier = false;
        e.preventDefault();
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        for (let key in formSupplier) {
            if (!formSupplier[key].status) {
                isErrorSupplier = true;
            }
        }
        setShow(false);
        setValidationClick(isError);
        setValidateForm(false);
        if (window.location.pathname.split("/")[2] === "supplier") {
            if (!isErrorSupplier) {
                setTerm(true);
            }
        }
        if (window.location.pathname.split("/")[2] === "reseller") {
            if (!isError) {
                setTerm(true);
            }
        }
    };
    const handleSubmitRegister = () => {
        setTerm(false);
        setLoading(true);
        let dataSupplier = {
            email: formSupplier[3].value,
            password: formSupplier[4].value,
            phone_number: formSupplier[2].value,
            full_name: formSupplier[0].value,
            store: {
                name: formSupplier[1].value,
            },
        };
        let dataReseller = {
            email: form[2].value,
            password: form[3].value,
            phone_number: form[1].value,
            full_name: form[0].value,
            referral_code: formReferral[0].value,
        };
        setFailed(false);
        setTimeout(() => {
            setLoading(false);
            setFailed(true);
        }, 60000);
        let url = window.location.pathname.split("/")[2];
        switch (url) {
            case "supplier":
                return service.register({ dispatch, data: dataSupplier });

            default:
                return service.registerReseller({
                    dispatch,
                    data: dataReseller,
                });
        }
    };

    useEffect(() => {
        if (state?.user?.register?.isSuccess) {
            setStatus(state.user.register);
            // setShow(true);
            setFailed(false);
            setLoading(false);
            setTimeout(() => {
                history.push("/login");
                service.registerClearList({ dispatch });
                setShow(false);
            }, 2000);
        }
        if (state?.user?.register?.isError) {
            setLoading(false);
            setFailed(false);
            setShow(true);
            service.registerClearList({ dispatch });
            setStatus(state.user.register);
            setMessage(state.user?.register?.data.message);
            setTimeout(() => {
                setShow(false);
                setMessage("");
            }, 30000);
        }
    }, [state, history, dispatch]);

    return (
        <Style id="login">
            <div className="login-page">
                <div className="card-image">
                    <img
                        src={
                            window.location.pathname.split("/")[2] ===
                            "reseller"
                                ? PictReseller
                                : PictSupplier
                        }
                        alt="signup"
                        className="img-fluid"
                    />
                </div>
                <div className="card-form">
                    <form onSubmit={handlerSubmitData}>
                        <div>
                            <Link to="/">
                                <img
                                    src={Logo}
                                    alt="logo"
                                    className="img-fluid lakugan-logo"
                                />
                            </Link>
                        </div>
                        <h2 className="lakugan-title">
                            Daftar jadi{" "}
                            {window.location.pathname.split("/")[2] ===
                            "reseller"
                                ? "Reseller"
                                : "Supplier"}
                        </h2>
                        {isShow && (
                            <Alert
                                color={status?.isError ? "danger" : "success"}
                            >
                                {message}
                            </Alert>
                        )}
                        {isFailed && !status && (
                            <Alert color="danger" className="text-center">
                                Terjadi kesalahan, harap coba beberapa menit
                                lagi, Terimakasih
                            </Alert>
                        )}
                        <CustomForm
                            setForm={
                                window.location.pathname.split("/")[2] ===
                                "reseller"
                                    ? setForm
                                    : setFormSupplier
                            }
                            validateForm={validateForm}
                            validationClick={validationClick}
                            form={
                                window.location.pathname.split("/")[2] ===
                                "reseller"
                                    ? form
                                    : formSupplier
                            }
                        />
                        {window.location.pathname.split("/")[2] ===
                            "reseller" && (
                            <CustomForm
                                setForm={setFormReferral}
                                validateForm={validateForm}
                                validationClick={validationClick}
                                form={formReferral}
                            />
                        )}

                        <Button
                            block
                            color="primary"
                            type="submit"
                            className="btn-custom"
                            disabled={loading}
                        >
                            {loading ? <Spinner color="light" /> : "Daftar"}
                        </Button>
                        <span className="login">
                            <Link to="/login">Sudah punya akun?</Link>
                        </span>
                    </form>
                </div>
            </div>
            <TermConditionReseller
                handleSubmitRegister={handleSubmitRegister}
                isShow={isTerm}
                toggle={() => setTerm(!isTerm)}
            />
        </Style>
    );
};
export default SignUp;
