const serviceState = {
  user: {
    reseller: false,
    register: false,
    token: false,
    product: false,
    paymentMethod: false,
    province: false,
    city: false,
    district: false,
    area: false,
    provinceStore: false,
    cityStore: false,
    districtStore: false,
    areaStore: false,
    shipper: false,
    buyProduct: false,
    faqList: false,
    about: false,
    banner: false,
    store: false,
    passwordChange: false,
  },
  product: {
    productList: false,
    createProduct: false,
    deleteSpec: false,
    deleteImage: false,
  },
  reseller: {
    transactionList: false,
    product: false,
    productList: false,
    supplierList: false,
    deleteProduct: false,
    addProduct: false,
    addProductMultiple: false,
    bank: false,
    profile: false,
  },
  supplier: {
    profile: false,
    transactionAddress: false,
    bank: false,
    transaction_address: false,
    transactionList: false,
  },
  commission: {
    commissionList: false,
  },
  shipper: {
    agentId: false,
  },
  voucher: {
    voucherList: false,
  },
  location: {
    coords: false,
  },
};

export default serviceState;
