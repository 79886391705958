import styled from "styled-components";
import { color } from "../../../Utils/Variable";

export const ProductStyle = styled.div`
  padding: 132px 0;
  @media (max-width: 550px) {
    padding: 0 0 80px 0;
  }
  .isClose {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: yellow;
  }
  hr {
    margin: 4rem 0;
  }
  .lakugan {
    &-product {
      color: #000;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__image {
        width: 100%;
        height: 185px;
        object-fit: contain;
        margin: 0 auto 5px;
      }
      &__title {
        margin-bottom: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre-wrap;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        @media (max-width: 499px) {
          font-size: 14px;
        }
      }
      &__price {
        width: 50%;
        text-align: right;
      }
      &__category {
        color: #4a4a4a;
        font-size: 14px;
        text-transform: uppercase;
        @media (max-width: 499px) {
          font-size: 12px;
        }
      }
    }
  }
  .any-product {
    margin-bottom: 30px;
    @media (max-width: 499px) {
      font-size: 16px;
      margin: 0;
    }
  }
  .title-information {
    margin-bottom: 30px;
    @media (max-width: 499px) {
      font-size: 20px;
    }
  }
  .slider-product {
    margin-bottom: ${(props) => (props.mrg === 1 ? 0 : "110px")};
    max-width: 443px;
    max-height: 528px;
    border-radius: 20px !important;
    height: 100% !important;
    img {
      border-radius: 20px;
    }
    @media (max-width: 550px) {
      display: contents;
      padding: 0 !important;
      img {
        border-radius: 0;
        width: 100%;
      }
    }
    @media (min-width: 768px) and (max-width: 820px) {
      img {
        border-radius: 10px;
      }
    }
  }
  .no-padding {
    @media (max-width: 550px) {
      padding: 0 !important;
    }
  }
`;
export const DetailStyle = styled.div`
  .product {
    margin-bottom: 25px;
    &-category {
      font-size: 16px;
      color: #aaa;
      text-transform: uppercase;
      display: block;
      margin-bottom: 10px;
    }
    @media (max-width: 767px) {
      text-align: center;
      margin-bottom: 10px;
      > div {
        text-align: center;
      }
    }
    &-city {
      display: block;
      padding-top: 16px;
      width: 120%;
      @media (max-width: 550px) {
        text-align: left;
        padding: 0;
        width: 100%;
      }
    }
    h3 {
      font-size: 32px;
      font-weight: bold;
      padding-bottom: 32px;
    }
    &-title {
      font-size: 32px;
      font-weight: 600;
      @media (max-width: 550px) {
        display: none;
      }
    }
    &-price {
      @media (max-width: 550px) {
        display: none;
      }
      &-mobile {
        @media (max-width: 550px) {
          font-size: 20px;
          font-weight: 700;
          text-align: left;
          padding: 16px 0;
        }
      }
    }
    span {
      img {
        width: 16px;
        height: 16px;
        position: relative;
        top: -2px;
      }
      p {
        display: inline;
      }
    }
  }
  .rating-product {
    margin-bottom: 10px;
    @media (max-width: 767px) {
      width: max-content;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .price {
    margin-bottom: 50px;
    h3 {
      font-weight: 600;
    }
    .stock {
      font-size: 12px;
    }
    @media (max-width: 767px) {
      text-align: center;
      margin-bottom: 10px;
    }
  }
  .order {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    border-top: 1.5px solid rgb(232, 232, 232);
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    @media (max-width: 550px) {
      margin: 0;
    }
    hr {
      margin: 2rem 0;
    }
    &-item {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 30px;
      @media (max-width: 767px) {
        display: block;
        margin: auto;
        width: max-content;
      }
    }
    &-size {
      display: flex;
      align-items: center;
      margin: 24px 0;
      @media (max-width: 767px) {
        display: block;
      }
      .size-list {
        @media (max-width: 767px) {
          justify-content: center;
          display: flex;
          flex-wrap: wrap;
        }
        @media (max-width: 550px) {
          justify-content: flex-start;
        }
      }
      &__button {
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 400;
        margin-right: 8px;
        transition: all 0.25s ease;
        @media (max-width: 550px) {
          font-size: 14px;
          font-weight: 600;
        }
        &:hover,
        &.active {
          color: ${color.user.primary};
          background: ${color.primary};
          border: 1px solid ${color.primary};
          transition: all 0.25s ease;
        }
      }
    }
    &-color {
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        display: block;
      }
      .color-list {
        @media (max-width: 767px) {
          justify-content: center;
          display: flex;
          flex-wrap: wrap;
        }
        @media (max-width: 550px) {
          justify-content: flex-start;
        }
      }
      &__button {
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 400;
        margin-right: 10px;
        transition: all 0.25s ease;
        &:hover,
        &.active {
          color: #fff;
          background: ${color.primary};
          border: 1px solid ${color.primary};
          transition: all 0.25s ease;
        }
        @media (max-width: 767px) {
          margin-bottom: 10px;
        }
        @media (max-width: 550px) {
          padding: 12px;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
  .font-sub {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    display: block;
    margin-right: 15px;
    @media (max-width: 767px) {
      text-align: center;
      margin-right: 0;
      margin-bottom: 15px;
    }
    @media (max-width: 550px) {
      font-size: 16px;
      font-weight: 600;
      text-align: left;
    }
  }
  .btn-product__info {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 2px solid #77e879;
    background: #77e879;
    color: #fff;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    .fa-whatsapp {
      font-size: 20px;
      font-weight: bold;
    }
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }
  .estimation-desc {
    margin-bottom: 25px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
      .font-sub {
        text-align: left;
        margin-bottom: 0;
      }
    }
    @media (max-width: 550px) {
      width: 100%;
    }
  }
  .buy-payment {
    @media (max-width: 767px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px;
      z-index: 3;
      background: #fff;
      border-top: 1px solid #aaa;
    }
  }
  .info-product {
    font-size: 20px;
    padding-top: 16px;
    font-weight: bold;
    @media (max-width: 550px) {
      text-align: left !important;
      font-size: 16px;
      font-weight: 600;
      padding: 0;
    }
  }
  .article-desc {
    @media (max-width: 550px) {
      width: 100% !important;
    }
  }
`;
export const PaymentStyle = styled.div`
    padding: 100px 0;
    @media (max-width: 550px) {
        padding: 0;
    }
    .title {
        margin-bottom: 10px;
        font-weight: bold;
    }
    .isClose {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        color: yellow;
    }
    .slider-product {
        margin-bottom: ${(props) => (props.mrg < 1 ? 0 : "110px")};
    }
    .accordion-area {
        padding-top: 74px;
        padding-left: 16px;
        border-radius: 5px;
        margin-bottom: 25px;
        width: 100%;
        @media (max-width: 550px) {
            padding: 0;
            margin: auto;
        }
        &-price {
            border: 1.5px solid #E8E8E8;
            // margin-top: 24px;
            border-radius: 20px;
            @media (max-width: 550px) {
                border: none;
                margin: 0;
            }
        }
        .btn-accordion {
            background: transparent;
            padding: 10px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            border: none;
            font-weight: 500;

            &:focus {
                box-shadow: none;
                outline: none;
            }
            @media (max-width: 499px) {
                font-size: 14px;
            }
        }
        p {
            @media (max-width: 550px) {
                font-size: 14px;
            }
        }

        .collapse,
        .collapsing {
            padding: 20px 10px;
        }
        /*style for new checklist*/
        .round {
            position: relative;
          }
          
          .round label {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 50%;
            cursor: pointer;
            height: 24px;
            margin: 0;
            top: 0;
            width: 24px;
            margin: 0 !important;
          }
          
          .round label:after {
            border: 2px solid #fff;
            border-top: none;
            border-right: none;
            content: "";
            height: 6px;
            left: 19px;
            opacity: 0;
            top: 8px;
            transform: rotate(-45deg);
            width: 12px;
          }
          
          .round input[type="checkbox"] {
            visibility: hidden;
          }
          
          .round input[type="checkbox"]:checked + label {
            background-color: rgb(0, 10, 140);
            border-color: rgb(0, 10, 140);            ;
          }
          
          .round input[type="checkbox"]:checked + label:after {
            opacity: 1;
            position: absolute;
          }
        /*endline styling checklist*/
    }
    .btn-address {
        .fa-angle-down {
            transition: all 0.5s ease;
            transform: rotate(${(props) => (props.active ? "180deg" : "0")});
        }
    }
    .btn-item {
        .fa-angle-down {
            transition: all 0.5s ease;
            transform: rotate(
                ${(props) => (props.activeItem ? "180deg" : "0")}
            );
        }
    }
    .bill-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        padding: 0 16px;
        @media (max-width: 576px) {
            padding: 0;
        }
        &__text {
            color: #4a4a4a;
            font-size: 14px;
            display: block;
            font-weight: 500;
        }
        h6 {
            font-size: 14px;
        }
        .desc {
            font-size: 12px;
            color: #4a4a4a;
        }
        .price {
            font-weight: 500;
            color: #4a4a4a;
            font-size: 14px;
            &-total {
                font-size: 20px;
                font-weight: bold;
                color: #000000;
                ;
            }
        }
        Button {
            display: block;
            @media (max-width: 550px) {
                display: none;
            }
        }
    }
    .price-detail {
        display: flex;
        flex-direction: row; 
        justify-content: space-between;
        p {
            @media (max-width: 550px) {
                font-size: 14px;
                font-weight: 400;
            }
        }
`;
export const Container = styled.div`
    padding: 0 14%;
    @media (max-width: 550px) {
        padding: 0;
    }
`;
export const OnlyMobile = styled.div`
  display: none;
  @media (max-width: 550px) {
    display: block;
    .product-name {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 20px;
      padding-top: 8px;
      h4 {
        text-align: left;
      }
    }
    .voucher-list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 8px 16px;
      p {
        font-size: 18px !important;
        font-weight: 600 !important;
      }
    }
    .voucher-select {
      p {
        padding: 16px 0;
        font-size: 16px;
        font-weight: 400;
      }
      img {
        height: 18px;
        width: 10px;
        margin: auto 0;
      }
    }
    .border {
      &-top {
        border-top: 1px solid #e0e0e0;
      }
      &-botton {
        border-bottom: 1px solid #e0e0e0;
      }
    }
  }
`;
export const SellerNoteStyle = styled.div`
    display: flex; 
    margin-top: 32px;
    @media(max-width: 550px) {
        margin: 0 16px;
        justify-content: space-between;
    }
    .small {
        margin: 0; 
        color: #a3a3a3;
    }
    .title-note {
        width: calc(1/3*100%);
        p {
            font-size: 14px;
            margin: 0;
        }
    }
    .custom-textarea {
        min-width: 38em; 
        min-height: 100px; 
        border-radius: 20px; 
        padding: 12px;
        @media (max-width: 550px) {
            min-width: 100%;
            min-height: 60px; 
        }
    }
`;
export const ModalInfo = styled.div`
    width: calc(1/2*100%);
    padding-left: calc(1/5*100%);
    img {
        padding-top: 32px;
        width: 100%;
        width: -webkit-fill-available; 
        width: -moz-available;
    }
    h6 {
        font-weight: 700; 
        min-width: 280px; 
        padding-top: 64px;
    }
    p {
        min-width: 300px;
        padding: 16px 0;
    }
    Button {
        width: 300px; 
        border-radius: 8px;
    }
`;
export const CustomStyles = {
    content: {
        position: 'fixed',
        padding: '0',
        margin: 'auto 0',
        width: '100%',
        height: '100%',
        inset: '0',
    },
};
export const ModalForm = styled.div`
min-width: calc(1/2*100%);
    .custom-email {
        height: 50px;
    }
`;
export const LoadingText = styled.span`
    position: relative;
    top: 6px;
    color: #aaa;
`;
