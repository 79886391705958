import React from "react";
import CommonTable from "../../../Components/Molekul/CommonTable";
import DetailOrder from "./Modal/DetailOrder";
import service from "./index.service";
import { formatMoney } from "../../../Utils/PriceConverter";
import { useStore } from "../../../Reducers";
import LocalStorage from "../../../Utils/LocalStorage";
import moment from "moment";
import Button from "../../../Components/Particle/Button";
import styled from "styled-components";
import MessageState from "../../../Components/Modal/Message";
import GrowingLoading from "../../../Components/Loading/GrowingLoading";

const Style = styled.div`
    margin-bottom: ${(props) =>
        props.activeMargin.length > 0 ? "100px" : "0"};
    .btn-pickup {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 99;
        button {
            border-radius: 5px 5px 0 0;
            padding: 15px;
            span {
                font-size: 20px;
            }
        }
    }
    .note {
        background: #fff7ec;
        padding: 5px 15px;
        width: max-content;
        border-radius: 5px;
        margin-bottom: 20px;
        @media (max-width: 500px) {
            width: 100%;
            font-size: 12px;
        }
    }

    .table-absolute {
        position: absolute;
        width: 84px;
        right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 575px) {
            padding-top: 23px !important;
            padding-bottom: 23px !important;
            width: 55px;
        }
    }
    tbody {
        .table-absolute {
            padding: 14px !important;
            @media (max-width: 575px) {
                padding: 10px !important;
            }
        }
    }
`;

const DataTable = () => {
    const { dispatch, state } = useStore();
    const [isLoading, setLoading] = React.useState(false);
    const getToken = LocalStorage().get("auth");
    const [isChecked, setChecked] = React.useState(false);
    const [listDataId, setListDataId] = React.useState([]);
    const [listDataLongId, setListDataLongId] = React.useState([]);
    const getId = LocalStorage().get(`${getToken.access_token}`);
    const [isShow, setShow] = React.useState(false);
    const [orderIdNumber, setOrderIdNumber] = React.useState("");
    const [data, setData] = React.useState(false);
    const [status, setStatus] = React.useState(true);
    const [isFirstGetCommission, setIsFirstGetCommision] = React.useState(true);
    const [commissionList, setCommissionList] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const [showMessage, setShowMessage] = React.useState(false);
    const [isRefresh, setRefresh] = React.useState(true);
    const handlerViewDetail = (item) => {
        let id = item.biteship_order_id;
        service.orderDetail({ dispatch, id });
        let transactionCode = item.transaction_code.split("/");
        service.transactionDetail({
            dispatch,
            transaction_id: transactionCode[1],
            id: getId.id,
        });
        handleToggleModal();
    };
    const handleProcessPickup = () => {
        let ex_date = new Date();
        let getDateLater = new Date(ex_date.getTime() + 1 * 60 * 60 * 1000);
        let dataPickup = {
            datePickup: moment(getDateLater).format("YYYY-MM-DD HH:mm:ss"),
            orderIds: listDataId,
            orderIdLong: listDataLongId,
        };
        setLoading(true);
        setRefresh(false);
        service.pickupShipper({
            dispatch,
            data: dataPickup,
            id: "",
        });
        // setLoading(true);
    };
    const handleToggleModal = () => {
        setShow(!isShow);
    };
    const handleGetValueData = (e, data) => {
        let checked = e.target.checked;
        let id = data.biteship_order_id;
        setChecked(checked);
        setLoading(true);
        service.orderDetailChecked({ dispatch, id });
    };
    React.useState(() => {
        if (isFirstGetCommission) {
            service.commissionList({ dispatch });
            setIsFirstGetCommision(false);
        }
    }, [isFirstGetCommission]);
    React.useEffect(() => {
        if (state?.supplier?.transactionDetail?.isSuccess) {
            setData(state?.supplier?.transactionDetail.data);
            service.transactionDetailClear({ dispatch });
        }
        if (state?.supplier?.transactionList?.isSuccess) {
            service.transactionListClear({ dispatch });
        }
        if (state?.shipper?.orderDetailChecked?.isSuccess) {
            setLoading(false);
            if (isChecked) {
                setListDataId((prevState) => [
                    ...prevState,
                    state.shipper.orderDetailChecked.data.data.order.detail.id,
                ]);
                setListDataLongId((prevState) => [
                    ...prevState,
                    state.shipper.orderDetailChecked.data.data.order.detail._id,
                ]);
            } else {
                setListDataId((prevState) =>
                    prevState.filter(
                        (item) =>
                            item !==
                            state.shipper.orderDetailChecked.data.data.order
                                .detail.id
                    )
                );
                setListDataLongId((prevState) =>
                    prevState.filter(
                        (item) =>
                            item !==
                            state.shipper.orderDetailChecked.data.data.order
                                .detail._id
                    )
                );
            }
            service.orderDetailCheckedClear({ dispatch });
        }
        if (state?.shipper?.pickup?.isSuccess) {
            service.pickupShipperClear({ dispatch });
            service.orderDetailClear({ dispatch });
            setLoading(false);
            setStatus(state.shipper.pickup.isSuccess);
            setMessage(state.shipper.pickup?.data?.data?.message);
            setLoading(false);
            setShowMessage(true);
            setTimeout(() => {
                setRefresh(true);
                setShowMessage(false);
            }, 2000);
        }
        if (state?.shipper?.pickup?.isError) {
            service.pickupShipperClear({ dispatch });
            setStatus(state.shipper.pickup.isSuccess);
            setMessage(state.shipper.pickup.data.message);
            setLoading(false);
            setLoading(false);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
        }
        if (state?.commission?.commissionList?.isSuccess) {
            setCommissionList(state.commission.commissionList.data.content);
        }
    }, [state, dispatch, isChecked]);
    return (
        <Style activeMargin={listDataId}>
            {listDataId.length > 0 && (
                <div className="btn-pickup">
                    <Button block color="primary" onClick={handleProcessPickup}>
                        <span>Pickup</span>
                    </Button>
                </div>
            )}
            <GrowingLoading active={isLoading} />
            <div className="note">
                <i>
                    <span className="text-danger">Catatan</span> : Untuk
                    pengemasan cukup mencantumkan <b>Order ID</b>
                </i>
            </div>
            <MessageState
                status={status}
                message={message}
                isShow={showMessage}
            />
            <CommonTable
                haveChecked={{
                    check: true,
                    action: handleGetValueData,
                    actionData: handleProcessPickup,
                }}
                setRefresh={setRefresh}
                isRefresh={isRefresh}
                no_data={"Data Pengemasan tidak ada"}
                configuration={{
                    searchInput: true,
                    searchValue: ["customer_detail.full_name", "product.name"],
                    nav: {
                        hasSearch: true,
                        filter: [
                            {
                                icon: "fas fa-sort-amount-down",
                                type: "dropdown",
                                value: [
                                    {
                                        value: "full_name",
                                        label: "Email",
                                        key: "customer_detail.full_name",
                                    },
                                ],
                            },
                        ],
                    },
                    service: {
                        api: service.transactionList,
                        group: "supplier",
                        id: getId.id,
                        key: "transactionList",
                        filters: ``,
                        filterData: `["status","=","3"]`,
                    },
                    optionTable: [
                        {
                            headName: "No",
                            type: "number",
                        },
                        {
                            headName: "Nama Pemesan",
                            type: "isCostume",
                            fieldName: "customer_detail.full_name",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",

                            renderItem: (item) => {
                                return (
                                    <React.Fragment>
                                        <span
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {item.customer_detail.full_name}
                                        </span>
                                    </React.Fragment>
                                );
                            },
                        },
                        {
                            headName: "Nomor Telepon",
                            type: "isCostume",
                            fieldName: "customer_detail.phone_number",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",
                            renderItem: (item) => {
                                return (
                                    <React.Fragment>
                                        <span
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {item.customer_detail.phone_number}
                                        </span>
                                    </React.Fragment>
                                );
                            },
                        },
                        {
                            headName: "Tanggal Pemesanan",
                            type: "isCostume",
                            fieldName: "created",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",
                            renderItem: (item) => {
                                let transactionDate = "";
                                let getTime = item.transaction_code.split("/");
                                let timestamp = getTime[1];
                                let getFullDate = new Date(Number(timestamp));

                                transactionDate = `${getFullDate.getFullYear()}-${String(
                                    getFullDate.getMonth() + 1
                                ).padStart(2, "0")}-${String(
                                    getFullDate.getDate()
                                ).padStart(2, "0")}`;
                                return (
                                    <React.Fragment>
                                        <span
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {transactionDate}
                                        </span>
                                    </React.Fragment>
                                );
                            },
                        },
                        {
                            headName: "Nama Produk",
                            type: "isCostume",
                            fieldName: "product.name",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",
                            renderItem: (item) => {
                                return (
                                    <React.Fragment>
                                        <span
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {item.product.name}
                                        </span>
                                    </React.Fragment>
                                );
                            },
                        },
                        {
                            headName: "Jumlah",
                            type: "text",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",
                            fieldName: "total_amount",
                        },
                        {
                            headName: "Order ID",
                            type: "isCostume",
                            fieldName: "shipper.tracking_id",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",
                            renderItem: (item) => {
                                return (
                                    <React.Fragment>
                                        <span
                                            style={{
                                                background: "#fff7ec",
                                                padding: "2px 5px",
                                                width: "max-content",
                                                borderRadius: "5px",
                                                color: "orange",
                                                fontWeight: 700,
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {item.shipper?.tracking_id || item.biteship_order_id || "-"}
                                        </span>
                                    </React.Fragment>
                                );
                            },
                        },
                        {
                            headName: "Total Pembayaran",
                            type: "isCostume",
                            iconClass: "fas fa-sort-alpha-down",
                            iconClass2: "fas fa-sort-alpha-up-alt",
                            style: {
                                paddingRight: 84,
                            },
                            renderItem: (item) => {
                                return (
                                    <React.Fragment>
                                        <span
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {formatMoney(item.total_cost)}
                                        </span>
                                    </React.Fragment>
                                );
                            },
                        },
                        // {
                        //     headName: "Status",
                        //     type: "text",
                        //     fieldName: "status",
                        // },
                        {
                            headName: "Aksi",
                            type: "actions",
                            className: "table-absolute",
                            options: [
                                {
                                    actionType: "isViewModal",
                                    function: (item) => handlerViewDetail(item),
                                    renderItem: () => {},
                                    field: "id",
                                },
                            ],
                        },
                    ],
                }}
            ></CommonTable>
            <DetailOrder
                setLoading={setLoading}
                setOrderIdNumber={setOrderIdNumber}
                orderIdNumber={orderIdNumber}
                setRefresh={setRefresh}
                action={{ detail: "request_pickup" }}
                cancel={true}
                isShow={isShow}
                toggle={() => setShow(false)}
                commissions={commissionList}
                data={data}
                process={true}
            />
        </Style>
    );
};
export default DataTable;
