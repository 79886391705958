import React from "react";
import { Card, CardBody } from "reactstrap";
import styled from "styled-components";
import GrowingLoading from "../../../Components/Loading/GrowingLoading";
import Form from "../../../Components/Molekul/FormValidation";
import Button from "../../../Components/Particle/Button";
import service from "./index.service";
import { useStore } from "../../../Reducers";
import Tab from "./Tab";
import LocalStorage from "../../../Utils/LocalStorage";
import MessageState from "../../../Components/Modal/Message";
const Style = styled(Card)`
    border: unset;
    width: 1200px;
    margin: auto;
    hr {
        margin-top: 0;
        margin-bottom: 50px;
    }
    @media (max-width: 1200px) {
        width: 100%;
    }
`;

const Account = () => {
    const { dispatch, state } = useStore();
    const token = LocalStorage().get("auth");
    const auth = LocalStorage().get(`${token.access_token}`);
    const [isSuccess, setSuccess] = React.useState(false);
    const [isActive, setActive] = React.useState(false);
    const [validationClick, setValidationClick] = React.useState(true);
    const [validateForm, setValidateForm] = React.useState(true);
    const [formInformation, setFormInformation] = React.useState([
        {
            type: "number",
            action: "text",
            required: true,
            name: "rekening",
            label: "Nomor Rekening",
            col: 6,
            character: {
                min: {
                    length: "10",
                    valid: "Nomor rekening tidak valid",
                },
                max: {
                    length: "16",
                    valid: "Nomor rekening tidak valid",
                },
            },
            valid: "Nomor rekening tidak boleh kosong",
            value: auth.bank === null ? "" : auth.bank.account_number,
            status: auth.bank !== null ? true : false,
        },
        {
            type: "text",
            action: "text",
            required: true,
            name: "penerima",
            label: "Nama Pemilik Rekening",
            col: 6,
            valid: "Nama pemilik rekening tidak boleh kosong",
            value: auth.bank === null ? "" : auth.bank.account_name,
            status: auth.bank !== null ? true : false,
        },
        {
            type: "text",
            action: "text",
            required: true,
            name: "bank_name",
            label: "Nama Bank",
            col: 6,
            valid: "Nama bank tidak boleh kosong",
            value: auth.bank === null ? "" : auth.bank.bank_name,
            status: auth.bank !== null ? true : false,
        },
        {
            label: "Cabang Bank",
            type: "text",
            action: "text",
            required: true,
            name: "cabang",
            col: 6,
            valid: "Cabang bank tidak boleh kosong",
            value: auth.bank === null ? "" : auth.bank.bank_branch,
            status: auth.bank !== null ? true : false,
        },
    ]);
    const handleSaveAccount = () => {
        let isError = false;
        for (let key in formInformation) {
            if (!formInformation[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setActive(true);
            let data = {
                bank_name: formInformation[2].value,
                bank_branch: formInformation[3].value,
                account_number: formInformation[0].value,
                account_name: formInformation[1].value,
            };
            switch (auth.bank) {
                case null:
                    service.postBank({ dispatch, data });
                    break;
                default:
                    service.updateBank({ dispatch, data });
                    break;
            }
        }
    };
    React.useEffect(() => {
        if (state?.reseller?.bank?.isSuccess) {
            setActive(false);

            setSuccess(true);
            service.bankUpdateClear({ dispatch });
            setTimeout(() => {
                setSuccess(false);
            }, 2000);
        }
        if (state?.reseller?.bank?.isError) {
            setActive(false);
        }
    }, [state, auth, token, dispatch]);
    return (
        <Style>
            <GrowingLoading active={isActive} />
            <CardBody>
                <Tab />
                <hr />
                <Form
                    setForm={setFormInformation}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={formInformation}
                />
                <div className="text-right">
                    <Button color="primary" onClick={handleSaveAccount}>
                        Simpan
                    </Button>
                </div>
            </CardBody>
            <MessageState
                isShow={isSuccess}
                message="Informasi rekening telah di ubah"
            />
        </Style>
    );
};

export default Account;
