import React from "react";
const TableHead = ({
    sortFilter = "",
    dataHead = [],
    haveChecked = false,
    dataNav,
    dataTable = [],
    rowHead = [],
    onGetValueFilter = () => {},
}) => {
    // eslint-disable-next-line
    const [isActive, setActive] = React.useState(true);
    const handleActiveSort = (value) => {
        // setActive(value);
        onGetValueFilter(value);
    };
    return (
        <thead className="thead-light">
            {rowHead.length > 0 && (
                <tr>
                    {rowHead.map((item, key) => {
                        return (
                            <th
                                style={{
                                    ...item.style,
                                    borderBottom:
                                        item.headName === ""
                                            ? "unset"
                                            : "2px solid #f8f8f8",
                                }}
                                key={key}
                                scope="col"
                                className={`text-center`}
                                colSpan={item.colSpan}
                            >
                                {item.headName}
                            </th>
                        );
                    })}
                </tr>
            )}
            {dataNav.hasSearch ? (
                <tr>
                    {haveChecked && dataTable.length > 0 && <th></th>}

                    {dataHead.map((item, key) => {
                        return (
                            <React.Fragment key={key}>
                                {item.fieldName !== undefined ? (
                                    <th
                                        rowSpan={item.rowSpan || 1}
                                        className={
                                            isActive
                                                ? sortFilter ===
                                                      item.fieldName ||
                                                  sortFilter ===
                                                      `-${item.fieldName} ${item.className}`
                                                    ? `text-primary ${item.className}`
                                                    : `${item.className}`
                                                : `${item.className}`
                                        }
                                        style={{
                                            ...item.style,
                                            cursor: "pointer",
                                        }}
                                        scope="col"
                                        onClick={() =>
                                            handleActiveSort(item.fieldName)
                                        }
                                    >
                                        {item.headName}{" "}
                                        {item.required === true && (
                                            <span className="text-danger">
                                                *
                                            </span>
                                        )}
                                        <i
                                            style={{ fontSize: 16 }}
                                            className={
                                                sortFilter === item.fieldName
                                                    ? item.iconClass
                                                    : sortFilter ===
                                                      `-${item.fieldName}`
                                                    ? item.iconClass2
                                                    : item.iconClass
                                            }
                                        ></i>
                                    </th>
                                ) : (
                                    <th
                                        scope="col"
                                        key={key}
                                        style={{ ...item.style }}
                                        className={item.className}
                                    >
                                        {item.headName}{" "}
                                        {item.required === true && (
                                            <span className="text-danger">
                                                *
                                            </span>
                                        )}
                                    </th>
                                )}
                            </React.Fragment>
                        );
                    })}
                </tr>
            ) : (
                <tr>
                    {dataHead.map((item, key) => {
                        return (
                            <th
                                style={{ ...item.style }}
                                scope="col"
                                key={key}
                                rowSpan={item.rowSpan || 1}
                                className={item.className}
                            >
                                {item.headName}{" "}
                                {item.required === true && (
                                    <span className="text-danger">*</span>
                                )}
                            </th>
                        );
                    })}
                </tr>
            )}
        </thead>
    );
};

export default TableHead;
