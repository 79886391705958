/*eslint-disable*/
import React from "react";
import {
    Col,
    Row,
    Card,
    CardBody,
    Alert,
    InputGroup,
    Input,
    FormGroup,
} from "reactstrap";
import CardProduct from "./Card";
import service from "../index.service";
import { useStore } from "../../../../Reducers";
import SkeletonLoading from "../../../../Components/Loading/Skeleton";
import ModalUpdate from "./Modal";
import Button from "../../../../Components/Particle/Button";
import { Link } from "react-router-dom";
import ProductDetail from "./ProductDetail";
import TablePagination from "../../../../Components/Molekul/CommonTable/index.pagination";
import debounce from "../../../../Utils/Debounce";

const DEFAULT_LIMIT_DATA = 18;
const DEFAULT_LIMIT_PAGINATION = 5;
const Index = () => {
    const [productItem, setProductItem] = React.useState(false);
    const [isFirstGet, setFirstGet] = React.useState(true);
    const [item, setItem] = React.useState(false);
    const [commissionCustom, setCommissionCustom] = React.useState({});
    const [onSortFilter, setSortFilter] = React.useState("");
    const [configTable, setConfigurationTable] = React.useState(false);
    const [isShow, setShow] = React.useState(false);
    const [isAlert, setAlert] = React.useState(false);
    const [isLoading, setLoadingData] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState(false);
    const [inputSearch, setInputSearch] = React.useState(0);
    const [showModal, setShowModal] = React.useState(false);
    const [params, setParams] = React.useState({
        filters: onSortFilter,
        page: 0,
        size: DEFAULT_LIMIT_DATA,
    });
    const [stockVoucher, setStockVoucher] = React.useState(false);
    const { dispatch, state } = useStore();

    const updateProduct = (data, id) => {
        service.updateProduct({ dispatch, data, id });
    };
    const getData = (params) => {
        setLoadingData(true);
        service.product({ dispatch, params });
        setParams(params);
    };
    const getProduct = () => {
        getData(params);
    };
    const openProduct = (item) => {
        setItem(item);
        setShow(true);
    };
    const handleEditProduct = (item) => {
        setProductItem(item);
        setShow(false);
        setShowModal(true);
        service.product({ dispatch, params });
    };
    const onClickNumber = (number) => {
        getData({
            filters: onSortFilter,
            page: number,
            size: DEFAULT_LIMIT_DATA,
        });
    };
    const eventOnChangeSearchInput = debounce((value) => {
        params.page = 1;
        params.size = 18;
        params.filters = `[["name","like","${value}"]]`;
        setSortFilter(value);
        getData(params);
    }, 750);
    const getProductCallback = React.useCallback(getProduct);
    React.useEffect(() => {
        if (state?.product?.createProduct?.isSuccess) {
            getData(params);
            setLoading(false);
            service.productClearList({
                dispatch,
            });
        }
    }, [state, dispatch]);
    React.useEffect(() => {
        if (isFirstGet) {
            service.commissionList({ dispatch });
            getProductCallback();
            setFirstGet(false);
        }
    }, [getProductCallback, isFirstGet]);
    React.useEffect(() => {
        if (state?.product?.productList?.isSuccess) {
            setData(state.product.productList.data);
            const { number, next_page, total_pages, total_elements } =
                state.product.productList.data;
            const isPagination = Number(total_elements) > 1;
            const responseHeaderData = {
                currentPage: Number(number + 1),
                nextPage: next_page,
                total_page: Number(total_pages),
                total_elements: total_elements,
                isPagination: isPagination,
                paginationListNumber: total_pages,
            };
            service.productListClear({ dispatch });
            setConfigurationTable(responseHeaderData);
        }
        if (state?.product?.productList?.isSuccess && onSortFilter === "") {
            setInputSearch(state.product.productList.data.total_elements);
        }
        if (state?.commission?.commissionList?.isSuccess) {
            const customCommission =
                state?.commission?.commissionList.data.content.filter(
                    (item) => item.id === 2
                );
            setCommissionCustom(customCommission[0]);
            service.commissionListClear({ dispatch });
        }
        setLoadingData(false);
    }, [state, dispatch]);
    return (
        <div>
            <Card>
                <CardBody>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h4 className="title-section">Daftar Produk</h4>

                        <Link to="/supplier/home" className="d-block">
                            <Button color="primary">Tambah Produk</Button>
                        </Link>
                    </div>
                    {isAlert && (
                        <Alert color="success">Sukses Update Product</Alert>
                    )}

                    <Row>
                        {inputSearch > 0 && (
                            <Col xl={12}>
                                <Row>
                                    <Col xl="5">
                                        <FormGroup className="search-box">
                                            <InputGroup className="mb-4">
                                                <Input
                                                    onChange={({
                                                        target: { value },
                                                    }) =>
                                                        eventOnChangeSearchInput(
                                                            value
                                                        )
                                                    }
                                                    defaultValue={onSortFilter}
                                                    placeholder="Cari"
                                                    type="text"
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        )}

                        {data ? (
                            data.content.length > 0 ? (
                                <React.Fragment>
                                    {data.content.map((item, key) => {
                                        return (
                                            <Col
                                                xl="6"
                                                lg="6"
                                                md="6"
                                                sm="6"
                                                xs="6"
                                                key={key}
                                            >
                                                <CardProduct
                                                    handleEditProduct={
                                                        handleEditProduct
                                                    }
                                                    openProduct={openProduct}
                                                    setShow={setShowModal}
                                                    setProductItem={
                                                        setProductItem
                                                    }
                                                    isAlert={isAlert}
                                                    item={item}
                                                    product="true"
                                                />
                                            </Col>
                                        );
                                    })}
                                </React.Fragment>
                            ) : (
                                <h2 className="data-null">Tidak Ada Produk</h2>
                            )
                        ) : (
                            <React.Fragment>
                                <Col xl="6" lg="6" md="6" sm="6" xs="6">
                                    <SkeletonLoading
                                        width="100%"
                                        height="185px"
                                        style={{ marginBottom: 30 }}
                                    />
                                </Col>
                                <Col xl="6" lg="6" md="6" sm="6" xs="6">
                                    <SkeletonLoading
                                        width="100%"
                                        height="185px"
                                        style={{ marginBottom: 30 }}
                                    />
                                </Col>
                                <Col xl="6" lg="6" md="6" sm="6" xs="6">
                                    <SkeletonLoading
                                        width="100%"
                                        height="185px"
                                        style={{ marginBottom: 30 }}
                                    />
                                </Col>
                                <Col xl="6" lg="6" md="6" sm="6" xs="6">
                                    <SkeletonLoading
                                        width="100%"
                                        height="185px"
                                        style={{ marginBottom: 30 }}
                                    />
                                </Col>
                            </React.Fragment>
                        )}
                    </Row>
                    {configTable && data && !isLoading && (
                        <TablePagination
                            limitPagination={DEFAULT_LIMIT_PAGINATION}
                            onClickNumber={onClickNumber}
                            {...configTable}
                        ></TablePagination>
                    )}
                </CardBody>
            </Card>

            <ProductDetail
                commissionCustom={commissionCustom}
                handleEditProduct={handleEditProduct}
                myProduct={false}
                // code={item.code}
                item={item}
                title="Produk Detail"
                isShow={isShow}
                toggle={() => setShow(!isShow)}
            />
            <ModalUpdate
                stockVoucher={stockVoucher}
                setStockVoucher={setStockVoucher}
                setProductItem={setProductItem}
                setLoading={setLoading}
                loading={loading}
                updateProduct={updateProduct}
                setShow={setShowModal}
                setAlert={setAlert}
                dataProduct={productItem}
                toggle={() => {
                    setShowModal(!showModal);
                    setStockVoucher(false);
                }}
                isShow={showModal}
            />
        </div>
    );
};

export default Index;
