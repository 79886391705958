import styled from "styled-components";
import { color } from "../../../Utils/Variable";

const Style = styled.div`
    .form-control {
        border-left: unset;
        &:focus {
            box-shadow: unset;
            border-color: #ced4da;
        }
    }
    .input-group-text {
        background: #fff;
        padding-right: 0;
    }
    .title {
        font-size: 18px;
        color: #464646;
        font-weight: bold;
        text-transform: uppercase;
        white-space: normal;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        @media (max-width: 575px) {
            font-size: 12px;
        }
    }
    .isClose {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        color: yellow;
    }
    .card-product {
        box-shadow: 0 3px 6px #ddd;
        border: unset;
        img {
            border-radius: 0.25rem 0 0 0.25rem;
        }
        .img-product {
            height: 195px;
            object-fit: contain;
            width: 100%;
        }
        .btn {
            @media (max-width: 575px) {
                padding: 0 5px;
                margin-bottom: 10px;
            }
        }
        p {
            @media (max-width: 575px) {
                font-size: 12px;
            }
        }
    }
    .product-desc {
        font-size: 14px;
        white-space: normal;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
    }
    .product-city {
        @media (max-width: 575px) {
            font-size: 10px;
        }
    }
    .label-filter {
        .fas {
            display: none;
        }
        @media (max-width: 767px) {
            position: fixed;
            z-index: 2;
            background: #fff;
            left: 0;
            right: 0;
            bottom: 0;
            border-top: 1px solid #ddd;
            padding: 10px;
            font-size: 12px;
            display: flex;
            justify-content: center;
            .fas {
                display: inline-block;
            }
        }
    }
    .label-top {
        color: #868686;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .popup-category {
        position: absolute;
        z-index: 9;
        background: #fff;
        border: 1px solid #ddd;
        width: 500px;
        box-shadow: 0 3px 6px #eee;
        border-radius: 10px;
        &__head {
            display: flex;
            align-items: center;
            padding: 20px;
        }
        &__section {
            overflow: auto;
            height: 202px;
            padding: 20px;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            @media (max-width: 767px) {
                height: 100%;
                padding-bottom: 135px;
            }
        }
        &__bottom {
            background: #fff;
            box-shadow: 0 3px 6px #aaa;
            padding: 10px;
            @media (max-width: 767px) {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
        @media (max-width: 767px) {
            position: fixed;
            width: 100%;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            border-radius: 0px;
            box-shadow: unset;
        }
    }
    .label-category {
        background: transparent;
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 10px 0;
        border: none;
        margin-bottom: 0;
        font-weight: 500;
        text-align: left;
        cursor: pointer;
        &.active {
            margin-bottom: 10px;
        }
        @media (max-width: 499px) {
            font-size: 14px;
        }
        .fa-angle-down {
            transition: all 0.5s ease;
            &.active {
                transform: rotate(180deg);
                transition: all 0.5s ease;
            }
        }
    }
    .product-zero {
        width: 100%;
        padding: 50px;
        font-weight: bold;
        color: #aaa;
    }

    .card {
        &-body {
            padding: 10px;
        }
        &-product {
            &__list {
                transition: all 0.25s ease;
                height: 250px;
                &:hover {
                    box-shadow: 0 3px 6px #ddd;
                    transition: all 0.25s ease;
                }
            }
        }
        &-filter {
            @media (max-width: 767px) {
                position: fixed;
                z-index: 4;
                left: 0;
                right: 0;
                top: 2000px;
                bottom: 0;
                transition: all 0.5s ease;
                background: rgba(0, 0, 0, 0.4);
                padding-top: 70px;
                height: 100%;
                &.active {
                    top: 0;
                    transition: all 0.25s ease;
                    .filter-action {
                        @media (max-width: 767px) {
                            position: fixed;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            background: #fff;
                            border-top: 1px solid #ddd;
                            padding: 10px;
                        }
                    }
                }
                > .card {
                    border-radius: 30px 30px 0 0;
                    overflow: auto;
                    height: 0%;
                    transition: all 0.25s ease;
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    &.active {
                        transition: all 0.5s ease;
                        height: 90%;
                    }
                }
                .card-body {
                    padding: 40px;
                }
            }
        }
    }
    .filter-list {
        display: block;
        width: 100%;

        > div {
            margin-right: 10px;
        }
        &:-webkit-scrollbar {
            display: none;
            width: 0px;
            background: transparent;
        }
        &:-webkit-scrollbar-thumb {
            background: #ff0000;
        }
        @media (max-width: 767px) {
            padding-bottom: 80px;
        }
    }
    .filter-value {
        margin: 0 10px 10px 0;
    }
    .filter-category {
        display: block;
        width: 100%;
    }
    .btn-reset {
        color: ${color.primary};
        font-size: 16px;
        @media (max-width: 575px) {
            font-size: 12px;
        }
    }
    .btn-filter {
        text-transform: capitalize;
        font-size: 14px;
        color: #4a4a4a;
        font-weight: 5000;
        background: #fff;
        display: block;
        width: 100%;
        text-align: left;
        padding-left: 0;
        border: unset;
        border-bottom: 1px solid #4a4a4a;
        border-radius: 0;
        transition: all 0.25s ease;
        &:hover {
            color: ${color.primary};
        }
        &.active {
            color: #fff;
            background: ${color.primary};
            border-bottom: 1px solid ${color.primary};
            padding-left: 0.75rem;
            transition: all 0.25s ease;
        }
        &:focus {
            outline: unset;
            box-shadow: unset;
        }
    }

    .pre-order {
        padding: 4px;
        font-size: 14px;
        width: max-content;
        margin: 5px 0 0 auto;
        background: ${color.primary};
        font-weight: bold;
        position: absolute;
        top: 0;
        left: 10px;
        border-radius: 5px;
        color: ${color.user.yellow};
        @media (max-width: 575px) {
            font-size: 12px;
        }
    }
    .price {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 0;
        text-align: left;
        @media (max-width: 575px) {
            font-size: 12px;
        }
    }
    .product-detail {
        @media (max-width: 575px) {
            padding: 0 10px;
        }
    }
    .title-product {
        margin-bottom: 0;
        @media (max-width: 450px) {
            font-size: 14px;
        }
    }
    .fa-arrow-left {
        @media (max-width: 450px) {
            font-size: 14px;
        }
    }
    .desktop-action {
        @media (max-width: 575px) {
            display: none;
        }
    }
`;
export default Style;
