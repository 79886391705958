import { servicesAction } from "../../../Reducers";

const service = {
    transactionAddress: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/transaction_address",
            method: "POST",
            token: true,
            data: data,
            reducer: "service",
            group: "supplier",
            key: "transactionAddress",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Profile berhasil diperbaharui",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
            },
        });
    },
    updateProfile: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/profile",
            method: "PUT",
            token: true,
            data: data,
            reducer: "service",
            group: "supplier",
            key: "profile",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Profile berhasil diperbaharui",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
            },
        });
    },
    postBank: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/bank/new",
            method: "POST",
            token: true,
            data: data,
            reducer: "service",
            group: "supplier",
            key: "bank",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Informasi rekening telah di ubah",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
            },
        });
    },
    updateBank: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/bank/update",
            method: "PUT",
            token: true,
            data: data,
            reducer: "service",
            group: "supplier",
            key: "bank",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Informasi rekening telah di ubah",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
            },
        });
    },
    getProvinceStore: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/province?size=34",
            method: "GET",
            token: true,
            reducer: "service",
            qs: true,
            group: "user",
            key: "provinceStore",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    getProvince: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/province?size=34",
            method: "GET",
            token: true,
            reducer: "service",
            qs: true,
            group: "user",
            key: "province",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    getCityStore: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/city/${params}?size=100`,
            method: "GET",
            token: true,
            reducer: "service",
            group: "user",
            key: "cityStore",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    getCity: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/city/${params}?size=100`,
            method: "GET",
            token: true,
            reducer: "service",
            group: "user",
            key: "city",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    getDistrictStore: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/district/${params}?size=100`,
            method: "GET",
            token: true,
            reducer: "service",
            group: "user",
            key: "districtStore",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    getDistrict: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/district/${params}?size=100`,
            method: "GET",
            token: true,
            reducer: "service",
            group: "user",
            key: "district",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    getAreaStore: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/subdistrict/${params}?size=100`,
            method: "GET",
            token: true,
            reducer: "service",
            group: "user",
            key: "areaStore",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, harap mencoba beberapa saat lagi",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, harap mencoba beberapa saat lagi",
                },
            },
        });
    },
    getArea: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/subdistrict/${params}?size=100`,
            method: "GET",
            token: true,
            reducer: "service",
            group: "user",
            key: "area",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, harap mencoba beberapa saat lagi",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, harap mencoba beberapa saat lagi",
                },
            },
        });
    },
    updatePasswordSupplier: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/password/change",
            method: "POST",
            token: true,
            data: data,
            reducer: "service",
            group: "user",
            key: "passwordChange",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap pencoba beberapa saat lagi",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
    passwordClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "passwordChange",
            type: "CLEAR",
        });
    },
    transactionAddressClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "supplier",
            key: "transactionAddress",
            type: "CLEAR",
        });
    },
    getClearProvinceStore: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "provinceStore",
            type: "CLEAR",
        });
    },
    getClearProvince: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "province",
            type: "CLEAR",
        });
    },
    getClearCityStore: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "cityStore",
            type: "CLEAR",
        });
    },
    getClearCity: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "city",
            type: "CLEAR",
        });
    },
    getClearDistrictStore: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "districtStore",
            type: "CLEAR",
        });
    },
    bankUpdateClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "supplier",
            key: "bank",
            type: "CLEAR",
        });
    },
    profileClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "supplier",
            key: "profile",
            type: "CLEAR",
        });
    },
    getClearDistrict: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "district",
            type: "CLEAR",
        });
    },
    getClearAreaStore: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "areaStore",
            type: "CLEAR",
        });
    },
    getClearArea: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "area",
            type: "CLEAR",
        });
    },
};
export default service;
