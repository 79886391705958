import styled from "styled-components";
import { color } from "../../../Utils/Variable";

const StyleCommon = styled.div`
    .input-group {
    }
    .search-box {
        box-shadow: rgb(0 96 183 / 25%) 0px 2px 6px;
        border-radius: 5px;
        input {
            background-color: #fff;
            padding-top: 14px;
            padding-bottom: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.24;
            letter-spacing: normal;
            text-align: left;
            color: #656565;
            font-size: 16px !important;
            border: none;
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
        i {
            color: #656565;
        }
        .input-group-text {
            border: none;
            border-top-left-radius: 11px;
            border-bottom-left-radius: 11px;
            padding: 10px;
            font-size: 16px;
            background-color: #fff;
        }
    }
    .common-table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .table {
        border-collapse: collapse;
        border-spacing: 0 1em;
        border-radius: 5px;
        margin-bottom: 0;
        .thead-light th {
            background: transparent;
            border: 0;
            color: #4a4a4a;
            font-weight: bold;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            background: #f2f2f2f2;
            line-height: 1.24;
            letter-spacing: normal;
            text-align: left;
            padding: 20px 15px;
            @media (max-width: 575px) {
                font-size: 10px;
            }
        }
        th,
        td {
            border: 0;

            @media (max-width: 575px) {
                font-size: 10px;
            }
        }
        th {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border-bottom: 1px solid #ddd;
            .sort-icon {
                font-size: 16px;
                @media (max-width: 575px) {
                    font-size: 10px;
                }
            }
            &:first-child {
                border-top-left-radius: 5px;
            }
        }
        thead {
            tr {
                background: #f2f2f2;
            }
        }
        tbody {
            tr {
                /* box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16); */
                border: 1px solid #f2f2f2;
                margin: 10px 0;
                &:last-child {
                    border-bottom: unset;
                }
                td {
                    padding: 20px 15px;
                    background: #fff;
                    span {
                        font-size: 16px;
                        font-weight: 500;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.24;
                        letter-spacing: normal;
                        color: #484848;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        /* / autoprefixer: off / */
                        -webkit-box-orient: vertical;
                        /* / autoprefixer: on / */
                        text-overflow: ellipsis;
                        overflow: hidden;
                        @media (max-width: 575px) {
                            font-size: 10px;
                        }
                    }
                    .text-description {
                        white-space: normal;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        max-width: 300px;
                        /* display: block; */
                        display: -webkit-box;
                    }
                    &:first-child {
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }
                    &:last-child {
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                        border-bottom: unset;
                    }
                }
            }
        }
    }
    .table-top-filter {
        display: flex;
        justify-content: start;
        .btn-secondary {
            background: ${color.secondary};
            border: none;
        }
    }
    .pagination-table {
        display: block;
        width: 100%;
        margin-top: 30px;
    }

    .page-item .page-link {
        border: none;
    }

    .page-item {
        margin: 0 5px;
        .page-link {
            box-shadow: none;
            border-radius: 10px;
            &.arrow {
                background-color: ${color.primary};
                opacity: 0.7;
                color: #fff;
            }
        }
        &.disabled {
            .page-link {
                cursor: not-allowed;
                &.number {
                    background-color: #f0f3ff;
                    opacity: 1;
                    color: #93a2dd;
                }
                &.arrow {
                    background-color: ${color.primary};
                    color: #fff;
                    opacity: 0.3;
                }
            }
        }
        &.active {
            color: #fff;
            .page-link {
                &.number {
                    background: ${color.primary};
                    color: #fcee31;
                }
                &.arrow {
                    opacity: 1;
                }
            }
        }
    }
    .active {
        color: #fff;
    }
`;

export default StyleCommon;
