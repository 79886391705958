import React from "react";
import { Card, Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { formatMoney } from "../../../../../Utils/PriceConverter";
import CountDown from "../../../../../Components/CountDown";
import { color } from "../../../../../Utils/Variable";
import StepOfPayment from "../../../../../helpers/StepOfPayment";
import AccordionPayment from "./AccourdionPayment";

const Style = styled.div`
  margin: 85px 0;
  min-height: 600px;
  padding-top: 35px;

  .container-collapse {
    @media (max-width: 576px) {
      max-width: 100% !important;
      padding: 0;
    }
  }

  .card {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 20px;
    padding: 7px 19px;
  }
  .accordion-area {
    background: #f8f8f8;
    margin-bottom: 20px;
    padding: 0 15px;
    .collapse.show {
      padding-bottom: 15px;
    }
    @media (max-width: 576px) {
      margin-bottom: 0;
      border-bottom: 1px solid #e8e8e8;
    }
  }
  .payment {
    &-notice {
      margin-bottom: 50px;
      &__box {
        background: #ffe2a2;
        padding: 5px;
        margin: auto;
        text-align: center;
      }
      &__text {
        font-weight: 600;
        text-align: center;
      }
    }
    &-card {
      &__text {
        color: #212121;
      }
      &__code {
        display: block;
        border: none;
        @media (min-width:350px) and (max-width: 550px) {
          max-width: 200px;
        }
        @media (min-width:300px) and (max-width: 349px) {
          max-width: 142px;
        }
      }
      &__price {
        font-weight: bold;
        color: ${color.primary};
        display: block;
        border: none;
      }
    }
  }

  .none {
    width: 0;
    height: 0;
    padding: 0;
  }
  input {
    background: transparent;
    &:focus {
      outline: none;
      box-shadow: unset;
    }
  }
  .btn {
    &-copy {
      transition: all 0.25s ease;
      color: ${color.primary};
      font-weight: 500;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .qr {
    &-payment {
      padding: 40px 0;
      text-align: center;
      .idr-payment {
        font-size: 24px;
        font-weight: bold;
        color: ${color.primary};
      }
    }
    &-image {
      width: 500px;
    }
  }
  .title-section {
    color: #868686;
  }
  .btn-accordion {
    background: transparent;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 19px 0;
    border: none;
    margin-bottom: 10px;
    font-weight: 500;
    text-align: left;
    @media (max-width: 576px) {
      margin-bottom: 0;
    }
    &:focus {
      box-shadow: none;
      outline: none;
    }
    .fa-angle-down {
      transition: all 0.5s ease;
      transform: rotate(${(props) => (props.active ? "180deg" : "0")});
      padding: 0 35px;
    }
  }
  .img-payment {
    width: 192px;
    @media (max-width: 550px) {
      width: 155px;
    }
  }
`;
const StatusZero = ({ data = false, stateTransaction = false }) => {
  const [isMobile, setMobile] = React.useState(false);
  const [orderCode, setOrderCode] = React.useState("");
  const paymentCode = React.useRef(null);
  const paymentPrice = React.useRef(null);
  const copyToClipboard = (e, value) => {
    value.current.select();
    document.execCommand("copy");
    e.target.focus();
  };
  React.useEffect(() => {
    let getTransaction = data.transaction_code.split("/");
    let transaction_code = getTransaction[1];
    setOrderCode(transaction_code);
    if (window.screen.width > 767) {
      setMobile(false);
    } else {
      if (data.payment.actions.length > 0) {
        setMobile(
          data.payment.actions.filter(
            (item) => item.name === "deeplink-redirect"
          )
        );
      }
    }
  }, [data]);
  React.useEffect(() => {
    if (isMobile) {
      let url = `${isMobile[0].url}`;
      window.location.href = url;
    }
  }, [isMobile]);
  React.useEffect(() => {
    if (data?.payment?.redirect_url !== undefined) {
      window.open(data.payment.redirect_url, "_blank");
    }
  }, [data]);
  return (
    <Style>
      {data?.payment?.redirect_url === undefined &&
        (data?.payment_channel?.vendor === "Midtrans" ? (
          data.payment.actions
            .filter((item) => item.name === "generate-qr-code")
            .map((item, key) => {
              return (
                !isMobile && (
                  <div className="qr-payment" key={key}>
                    <div>
                      <img
                        src={data.payment_channel.image}
                        alt={data.payment_channel.name}
                        className="img-fluid"
                      />
                    </div>
                    <div className="text-center">
                      <img
                        src={item.url}
                        alt={item.name}
                        className="img-fluid qr-image"
                      ></img>
                    </div>
                    <div className="idr-payment">
                      {formatMoney(data.total_cost)}
                    </div>
                  </div>
                )
              );
            })
        ) : (
          <Container>
            <h2 className="font-bold mb-3 d-md-block d-none">
              Konfirmasi Pembayaran
            </h2>
            <h4 className="font-semibold text-center mb-4">
              Selesaikan pembayaran dalam
            </h4>
            <h1 className="mb-3">
              <CountDown date={data.count_down_payment} />
            </h1>
            <h6 className="text-center font-light mb-3">
              Pesanan anda akan segera dikemas sambil menunggu pembayaran
            </h6>
            <div>
              <Row className="justify-content-center">
                <Col xl="7" md="10" sm="12">
                  <div>
                    <div className="justify-content-between align-items-center mb-3 d-flex flex-row">
                        <div>
                          <img
                            src={data.payment_channel.image}
                            alt=""
                            className="img-fluid img-payment"
                          />
                        </div>
                      <div>
                        <h4 className="text-right font-semibold">
                          No Pesanan : {orderCode}
                        </h4>
                      </div>
                    </div>
                    <div className="mb-3">
                      <h6 className="title-section mb-2 font-light">
                        Nomor Virtual Account
                      </h6>
                      <Card>
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <input
                              readOnly
                              type="text"
                              ref={paymentCode}
                              value={data.payment.va_number}
                              className="payment-card__code h4"
                            ></input>
                          </div>
                          <div>
                            <button
                              type="button"
                              className="btn btn-copy d-flex align-items-center"
                              onClick={(e) => copyToClipboard(e, paymentCode)}
                            >
                              <i className="far fa-clone mr-2"></i>
                              <p>Salin</p>
                            </button>
                          </div>
                        </div>
                      </Card>
                    </div>
                    <div className="section-info">
                      <h6 className="title-section mb-2 font-light">
                        Total Pembayaran
                      </h6>
                      <Card>
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <div className="payment-card__price h4">
                              {formatMoney(data.total_cost)}
                            </div>
                            <input
                              readOnly
                              type="text"
                              ref={paymentPrice}
                              value={data.total_cost}
                              className="invisibility"
                            />
                          </div>
                          <div>
                            <button
                              type="button"
                              className="btn btn-copy d-flex align-items-center"
                              onClick={(e) => copyToClipboard(e, paymentPrice)}
                            >
                              <i className="far fa-clone mr-2"></i>
                              <p>Salin</p>
                            </button>
                          </div>
                        </div>
                      </Card>
                    </div>
                    <p className="mt-2 mb-4">
                      Ini adalah halaman pembayaran otomatis, halaman akan masuk
                      ke bagian pemesanan jika pembayaran sudah di terima oleh
                      sistem
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        ))}
      <div className="container container-collapse">
        {/* Cara Pembauaran */}
        {StepOfPayment.filter((item) => item.name === data.payment_channel_name)
          .length > 0 && (
          <div>
            <h4 className="font-semibold mb-2 ml-md-0 ml-3">Cara Pembayaran</h4>
            {StepOfPayment.filter(
              (item) => item.name === data.payment_channel_name
            ).map((item, key) => {
              return (
                <React.Fragment key={key}>
                  {item.step.map((value, i) => {
                    return (
                      <div className="accordion-area" key={i}>
                        <AccordionPayment value={value} />
                      </div>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </div>
        )}

        {/* END CARA PEMBAYARAN */}
      </div>
    </Style>
  );
};
export default StatusZero;
