import React from "react";
import { Card, CardBody } from "reactstrap";
import CommonTable from "../../../../Components/Molekul/CommonTable";
import { formatMoney } from "../../../../Utils/PriceConverter";
import service from "../index.service";

const Index = () => {
    const month = [
        "Januari",
        "Febuari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
    ];
    return (
        <div>
            <Card>
                <CardBody>
                    <CommonTable
                        no_data={"Tidak ada riwayat transaksi"}
                        configuration={{
                            searchInput: false,
                            searchValue: ["customer_detail.full_name"],
                            nav: {
                                hasSearch: false,
                                filter: [
                                    {
                                        icon: "fas fa-sort-amount-down",
                                        type: "dropdown",
                                        value: [
                                            {
                                                value: "full_name",
                                                label: "Email",
                                                key: "customer_detail.full_name",
                                            },
                                        ],
                                    },
                                ],
                            },
                            service: {
                                api: service.walletLog,
                                group: "supplier",
                                key: "walletLog",
                                filters: ``,
                            },
                            optionTable: [
                                {
                                    headName: "No",
                                    type: "number",
                                },
                                {
                                    headName: "Tanggal",
                                    type: "isCostume",
                                    fieldName: "created",
                                    renderItem: (item) => {
                                        return (
                                            <React.Fragment>
                                                <span
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    {String(
                                                        new Date(
                                                            item.created
                                                        ).getDate()
                                                    ).padStart(2, "0")}{" "}
                                                    {
                                                        month[
                                                            new Date(
                                                                item.created
                                                            ).getMonth()
                                                        ]
                                                    }{" "}
                                                    {new Date(
                                                        item.created
                                                    ).getFullYear()}
                                                </span>
                                            </React.Fragment>
                                        );
                                    },
                                },
                                {
                                    headName: "Debit",
                                    type: "isCostume",
                                    fieldName: "debet",
                                    renderItem: (item) => {
                                        return (
                                            <React.Fragment>
                                                <span
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    {item.debet === null ||
                                                    item.debet === undefined
                                                        ? formatMoney(0)
                                                        : formatMoney(
                                                              item.debet
                                                          )}
                                                </span>
                                            </React.Fragment>
                                        );
                                    },
                                },
                                {
                                    headName: "Kredit",
                                    type: "isCostume",
                                    fieldName: "kredit",
                                    renderItem: (item) => {
                                        return (
                                            <React.Fragment>
                                                <span
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    {item.kredit === null ||
                                                    item.kredit === undefined
                                                        ? formatMoney(0)
                                                        : formatMoney(
                                                              item.kredit
                                                          )}
                                                </span>
                                            </React.Fragment>
                                        );
                                    },
                                },
                                {
                                    headName: "Saldo",
                                    type: "price",
                                    fieldName: "current_balance",
                                },
                            ],
                        }}
                    ></CommonTable>
                </CardBody>
            </Card>
        </div>
    );
};

export default Index;
