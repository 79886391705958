import React from "react";
import FormText from "../../../../Components/Particle/Form/FormText";
import Select from "react-select";
import { Collapse, Row, Col, FormGroup } from "reactstrap";
import styled from "styled-components";
import Form from "../../../../Components/Molekul/FormValidation";
import ImageUpload from "../../../../Components/Particle/Form/UploadImage";
// import ImageUpload from "../../../../Components/Particle/Form/UploadImage";

const Style = styled.div`
  border-bottom: 1px solid #ddd;
  .btn-accordion {
    background: transparent;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 0;
    border: none;
    margin-bottom: 10px;
    font-weight: 500;
    text-align: left;
    @media (max-width: 499px) {
      font-size: 14px;
    }
    &:focus {
      box-shadow: none;
      outline: none;
    }
    .fa-angle-down {
      transition: all 0.5s ease;
      transform: rotate(${(props) => (props.active ? "180deg" : "0")});
    }
  }
  p {
    @media (max-width: 499px) {
      font-size: 12px;
    }
  }
`;

const General = ({
  auth = {},
  formAddress = {},
  setFormAddress = () => {},
  setAddress = () => {},
  onGetValue = () => {},
  handleGetCity = () => {},
  handleGetDistrict = () => {},
  handleGetValueDistrict = () => {},
  handleGetValueArea = () => {},
  avatar = "",
  address = "",
  city = [],
  district = [],
  area = [],
  province = [],
  valueProvince = undefined,
  valueCity = undefined,
  valueDistrict = undefined,
  valueArea = undefined,
  postCode = "",
  form = [],
  validationClick = true,
  validateForm = true,
  setForm = () => {},
  onCheckValue = () => {},
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onGetValueAddress = (obj) => {
    setAddress(obj.value);
    formAddress.value = obj.value;
    if (
      obj.value === "" ||
      obj.value.length < formAddress.character.min.length
    ) {
      formAddress.status = false;
    } else {
      formAddress.status = true;
    }
    setFormAddress(formAddress);
  };
  React.useEffect(() => {
    if (auth.verify_id === true) {
      setIsOpen(true);
    }
  }, [auth]);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <Style active={isOpen}>
      <button onClick={toggle} className="btn-accordion">
        Informasi Umum
        <i className="fas fa-angle-down"></i>
      </button>
      <Collapse isOpen={isOpen}>
        <Row>
          <Col xl="12">
            <div className="mb-4">
              <ImageUpload
                onGetValue={onGetValue}
                validateForm={validateForm}
                validationClick={validationClick}
                item={{
                  heightStyle: "155px",
                  widthStyle: "155px",
                  avatar: true,
                  name: "photo_profile",
                  placeholder:
                    avatar === null || !avatar ? "Pilih Foto" : "Ganti Foto",
                  valid: "Harap masukkan gambar",
                  value: avatar,
                  status: true,
                  normalData: false,
                }}
              />
            </div>
          </Col>
          <Col xl="12" lg="12" md="12" sm="12">
            <Form
              setForm={setForm}
              validateForm={validateForm}
              validationClick={validationClick}
              form={form}
            />
            <Row>
              <Col xl="4" md="4" sm="12">
                <FormGroup>
                  <label htmlFor="">
                    Provinsi
                    <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Pilih"
                    onChange={(e) => handleGetCity(e)}
                    options={province}
                    value={valueProvince}
                  />
                  {valueProvince?.length === 0 && !validateForm && (
                    <small className="text-danger">
                      <i>Provinsi harus diisi</i>
                    </small>
                  )}
                </FormGroup>
              </Col>
              <Col xl="4" md="4" sm="12">
                <FormGroup>
                  <label htmlFor="">
                    Kota
                    <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Pilih"
                    onChange={(e) => handleGetDistrict(e)}
                    options={city}
                    value={valueCity}
                  />
                  {valueCity === undefined && !validateForm ? (
                    <small className="text-danger">
                      <i>Kota harus diisi</i>
                    </small>
                  ) : (
                    valueCity?.length === 0 &&
                    !validateForm && (
                      <small className="text-danger">
                        <i>Kota harus diisi</i>
                      </small>
                    )
                  )}
                </FormGroup>
              </Col>
              <Col xl="4" md="4" sm="12">
                <FormGroup>
                  <label htmlFor="">
                    Kecamatan
                    <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Pilih"
                    onChange={(e) => handleGetValueDistrict(e)}
                    value={valueDistrict}
                    options={district}
                  />
                  {valueDistrict === undefined && !validateForm ? (
                    <small className="text-danger">
                      <i>Kecamatan harus diisi</i>
                    </small>
                  ) : (
                    valueDistrict?.length === 0 &&
                    !validateForm && (
                      <small className="text-danger">
                        <i>Kecamatan harus diisi</i>
                      </small>
                    )
                  )}
                </FormGroup>
              </Col>
              <Col xl="4" md="4" sm="12">
                <FormGroup>
                  <label htmlFor="">
                    Kelurahan
                    <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Pilih"
                    onChange={(e) => handleGetValueArea(e)}
                    value={valueArea}
                    options={area}
                  />
                  {valueArea === undefined && !validateForm ? (
                    <small className="text-danger">
                      <i>Kelurahan harus diisi</i>
                    </small>
                  ) : (
                    valueArea?.length === 0 &&
                    !validateForm && (
                      <small className="text-danger">
                        <i>Kelurahan harus diisi</i>
                      </small>
                    )
                  )}
                </FormGroup>
              </Col>
              <Col xl="4" md="4" sm="12">
                <FormText
                  validateForm={validateForm}
                  validationClick={validationClick}
                  item={{
                    readOnly: true,
                    label: "Kode pos",
                    type: "number",
                    required: true,
                    action: "text",
                    name: "post-code",
                    valid: "Kode pos tidak boleh kosong",
                    value: postCode,
                    status: postCode === "" ? false : true,
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                <FormText
                  onGetValue={onGetValueAddress}
                  validateForm={validateForm}
                  validationClick={validationClick}
                  item={formAddress}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Collapse>
    </Style>
  );
};
export default General;
