import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../Components/Assets/v2/logo.png";

const NavBar = () => {

    const [hidden, setHidden] = useState(true);
    let pathname = window.location.pathname;
    useEffect(() => {
        pathname = window.location.pathname;
    }, [window.location.pathname]);

    return (
        <>
            <div hidden={hidden} className="nav-open">
                <ul className="navbar-nav">
                    <li>
                        <img src={Logo} alt="logo" />
                        <span onClick={() => setHidden(!hidden)}>
                            <i className="fa fa-close"></i>
                        </span>
                    </li>
                    <li className="nav-item">
                        <Link
                            to={`/blog`}
                            className={`${pathname.match('/blog') ? 'nav-link active' : 'nav-link'}`}
                        >
                            Blog
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to={`/how-to-join`}
                            className={`${pathname.match('/how-to-join') ? 'nav-link active' : 'nav-link'}`}
                        >
                            Yuk Jualan
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to={`/new-product`}
                            className={`${pathname.match('/new-product') ? 'nav-link active' : 'nav-link'}`}
                        >
                            Katalog
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to={`/home`}
                            className={`${pathname.match('/home') ? 'nav-link active' : 'nav-link'}`}
                        >
                            Coba Beli
                        </Link>
                    </li>
                    <div className="nav-open-btn">
                        <Link
                            to={`/login`}
                            className="btn btn-light btn-radius"
                        >
                            Masuk
                        </Link>
                        <Link
                            to={`/signup/reseller`}
                            className="btn btn-warning btn-radius"
                        >
                            Daftar
                        </Link>
                    </div>
                </ul>
            </div>
            
            <nav className="navbar navbar-dark navbar-expand-lg navbar-fixed-top">
                <img src={Logo} alt="logo" />
                <button onClick={() => setHidden(!hidden)} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
            
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link
                                to={`/blog`}
                                className={`${pathname.match('/blog') ? 'nav-link active' : 'nav-link'}`} 
                            >
                                Blog
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to={`/how-to-join`}
                                className={`${pathname.match('/how-to-join') ? 'nav-link active' : 'nav-link'}`}
                            >
                                Yuk Jualan
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to={`/new-product`}
                                className={`${pathname.match('/new-product') ? 'nav-link active' : 'nav-link'}`}
                            >
                                Katalog
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to={`/home`}
                                className={`${pathname.match('/home') ? 'nav-link active' : 'nav-link'}`}
                            >
                                Coba Beli
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to={`/login`}
                                className="btn btn-light btn-radius"
                            >
                                Masuk
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to={`/signup/reseller`}
                                className="btn btn-warning btn-radius"
                            >
                                Daftar
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
};
export default NavBar;
