import React from "react";
import { Card, Col, Row } from "reactstrap";
import styled from "styled-components";
import TwitterIcon from "../../../../Components/Assets/icon/twitter-logo.png";
import FaceBookIcon from "../../../../Components/Assets/icon/fb-logo.png";
import WhatsAppIcon from "../../../../Components/Assets/icon/wa-logo.png";
import TelegramIcon from "../../../../Components/Assets/icon/telegram-logo.png";
import { formatMoney } from "../../../../Utils/PriceConverter";
import ModalData from "../../../../Components/Modal/modal";
import ProductDetail from "../Modal/ProductDetail";
import Rating from "../../../../Components/Particle/Rating";
import Button from "../../../../Components/Particle/Button";
import { color } from "../../../../Utils/Variable";

const Style = styled(Card)`
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 2px 4px #ddd;
    transition: all 0.25s ease;
    p {
        @media (max-width: 575px) {
            font-size: 12px;
        }
    }
    .btn-share__product {
        position: relative;
        z-index: 5;
        &:hover,
        &:focus {
            opacity: 1;
        }
    }
    .close-product {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 6;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #ffeb3b;
        font-weight: bold;
        @media (max-width: 575px) {
            font-size: 12px;
        }
    }
    .btn {
        &-menu {
            color: #7d7d7d;
            border: none;
            background: #fff;
            padding: 10px 20px;
            z-index: 3;
            background: #fff;
            top: 2px;
            right: 2px;
            transition: all 0.25s ease;
            outline: none;
            &:hover {
                background: #fbfbfb;
            }
        }
        &-delete {
            border: none;
            background: #fff;
            padding: 5px 10px;
            z-index: 2;
            top: ${(props) => (props.active === "true" ? "92px" : "4px")};
            right: 4px;
            border-radius: 50%;
            font-size: 14px;
            color: #fff;
            outline: none;
            transition: all 0.3s ease;
            @media (max-width: 575px) {
                font-size: 12px;
            }
        }
        &-edit {
            border: none;
            background: #fff;
            padding: 7px 10px;
            z-index: 2;
            top: ${(props) => (props.active === "true" ? "52px" : "4px")};
            right: 4px;
            border-radius: 50%;
            font-size: 12px;
            color: #fff;
            outline: none;
            transition: all 0.25s ease;
        }
        &-list {
            position: absolute;
        }
    }
    .product-detail {
        @media (max-width: 575px) {
            padding: 0 10px;
        }
    }
    .title {
        font-size: 16px;
        color: #464646;
        font-weight: bold;
        text-transform: uppercase;
        white-space: normal;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        @media (max-width: 550px) {
            font-size: 12px;
        }
    }
    .price {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 0;
        text-align: left;
        @media (max-width: 575px) {
            font-size: 12px;
        }
    }
    .city {
        font-size: 12px;
        color: #4a4a4a;
        text-align: left;
        position: absolute;
        bottom: 10px;
        margin: 0px;
    }
    .img-product {
        height: 195px;
        object-fit: contain;
        width: 100%;
    }
    .btn-detail {
        border: unset;
        background: transparent;
        transition: all 0.25s ease;
        &:focus {
            outline: unset;
            box-shadow: unset;
        }
    }
    .action {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            text-align: left;
            font-size: 12px;
            padding: 8px;
        }
    }
    .pre-order {
        padding: 4px;
        font-size: 14px;
        width: max-content;
        margin: 5px 0 0 auto;
        background: ${color.primary};
        font-weight: bold;
        position: absolute;
        top: 0;
        left: 10px;
        border-radius: 5px;
        color: ${color.user.yellow};
        @media (max-width: 575px) {
            font-size: 12px;
        }
    }
    .product-city {
        @media (max-width: 575px) {
            font-size: 10px;
        }
    }
    .btn {
        @media (max-width: 575px) {
            padding: 0 5px;
            margin-bottom: 10px;
        }
    }
`;

const CardCatalog = ({
    setShowShare = () => {},
    handleModalDelete = () => {},
    item,
    product,
    setShareItem = () => {},
    state,
    isFailed = false,
}) => {
    const [showProduct, setShowProduct] = React.useState(false);
    const [isShow, setShow] = React.useState(false);
    const handleShowFilter = () => {
        setShow(!isShow);
    };
    const handleShowShare = (item, data) => {
        setShareItem({ share: item, copy: data });

        setShowShare(true);
    };
    const handleShowProduct = () => {
        setShowProduct(!showProduct);
    };
    // <a href="#" class="fa fa-pinterest" onclick="OpenShareWindow('https://pinterest.com/pin/create/button/?url={{ENCODED_URL}}&media={{THUMBNAIL}}')"></a>
    // <a href="#" class="fa fa-tumblr" onclick="OpenShareWindow('http://www.tumblr.com/share/link?url={{ENCODED_URL}}')"></a>
    // <a href="#" class="fa fa-reddit" onclick="OpenShareWindow('http://www.reddit.com/submit?url={{ENCODED_URL}}')"></a>
    const handleShareProduct = [
        {
            icon: WhatsAppIcon,
            link: `https://api.whatsapp.com/send?text=Yuk beli ${item.product?.name} di sini ${window.location.origin}/product/${item.code}`,
            name: "whatsapp",
        },
        {
            icon: FaceBookIcon,
            link: `https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/product/${item.code}&?text=Yuk%20beli%20${item.product?.name}%20di%20sini`,
            name: "facebook",
        },
        {
            icon: TwitterIcon,
            link: `https://twitter.com/intent/tweet/?text=Yuk%20beli%20${item.product?.name}%20di%20sini%20&url=${window.location.origin}/product/${item.code}`,
            name: "twitter",
        },
        {
            icon: TelegramIcon,
            link: `https://t.me/share/url?url=${window.location.origin}/product/${item.code}`,
            name: "telegram",
        },
    ];
    class TravellerCollection extends Array {
        sum(key) {
            return this.reduce((a, b) => a + (b[key] || 0), 0);
        }
    }
    const traveler = new TravellerCollection(...item?.product?.spec);
    React.useEffect(() => {
        if (state?.reseller?.deleteProduct?.isSuccess) {
            setShowProduct(false);
        }
    }, [state.reseller.deleteProduct, state]);
    return (
        <Style>
            {!item?.store?.is_open && (
                <div className="close-product">
                    <div className="text-center">
                        <div style={{ marginBottom: 10 }}>
                            Toko ini sedang tutup
                        </div>
                        <Button
                            color="primary"
                            onClick={() => handleModalDelete(item.product)}
                        >
                            Hapus dari katalog
                        </Button>
                    </div>
                </div>
            )}
            <Row>
                <Col xl="5" md="5" sm="5" xs="12">
                    <img
                        src={item?.product?.product_image[0]?.img_url}
                        alt=""
                        className="img-fluid img-product"
                    />
                </Col>
                {item.product?.pre_order && <div className="pre-order">PO</div>}
                <Col xl="7" md="7" sm="7" xs="12">
                    <div className="product-detail">
                        <h6 className="mt-2 title">{item.product?.name}</h6>
                        <p className="font-weight-bold mb-0">
                            {formatMoney(item.product?.price)}
                        </p>
                        <div style={{ fontSize: 12, color: "#28a745" }}>
                            Komisi yang di dapat:{" "}
                            <b>
                                {formatMoney(item.product.commission_product)}
                            </b>
                        </div>
                        <Rating rating={item.product?.rating} />

                        <div
                            className={
                                traveler.sum("total_stock") > 9
                                    ? "mb-4"
                                    : "mb-0"
                            }
                        >
                            {item.store.city && (
                                <p className=" mb-0 mr-3 product-city">
                                    {item.store.city}
                                </p>
                            )}
                            <div>
                                {traveler.sum("total_stock") === 0 ? (
                                    <i
                                        style={{ fontSize: 14 }}
                                        className="text-danger"
                                    >
                                        Stok kosong
                                    </i>
                                ) : (
                                    traveler.sum("total_stock") < 10 && (
                                        <i
                                            style={{ fontSize: 12 }}
                                            className="text-danger"
                                        >
                                            Stok hampir habis
                                        </i>
                                    )
                                )}
                            </div>
                        </div>
                        <Row className="align-items-center">
                            <Col xl="8" lg="8" md="8" sm="8" xs="8">
                                <Button
                                    color="primary"
                                    block
                                    onClick={() => handleShowProduct()}
                                >
                                    Lihat Detail
                                </Button>
                            </Col>
                            <Col
                                xl="4"
                                lg="4"
                                md="4"
                                sm="4"
                                xs="4"
                                style={{ paddingLeft: 0 }}
                            >
                                <Button
                                    color="primary"
                                    className="btn-share__product"
                                    onClick={() =>
                                        handleShowShare(
                                            handleShareProduct,
                                            item
                                        )
                                    }
                                >
                                    <i className="fas fa-share-alt"></i>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <ProductDetail
                myProduct={true}
                isFailed={isFailed}
                handleModalDelete={handleModalDelete}
                code={item.code}
                item={item.product}
                store={item.store}
                title="Produk Detail"
                isShow={showProduct}
                toggle={handleShowProduct}
            />
            <ModalData data={item} toggle={handleShowFilter} isShow={isShow} />
        </Style>
    );
};
export default CardCatalog;
