import React from "react";
import TabLink from "../../../Components/TabLink";
const Tab = () => {
  const tabLink = [
    {
      name: "Profile",
      link: "/supplier/profile",
      icon: "fas fa-user mr-2",
    },
    {
      name: "Informasi Rekening",
      link: "/supplier/account",
      icon: "fas fa-wallet mr-2",
    },
    {
      name: "Ganti Kata Sandi",
      link: "/supplier/ganti-kata-sandi",
      icon: "fas fa-key mr-2",
    },
  ];
  return <TabLink data={tabLink} />;
};
export default Tab;
