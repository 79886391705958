import React from "react";
import { HeaderStyle } from "./index.styles";
import ArrowLeft from "../Assets/icon/mobile/arrow-left.png";
import SearchIcon from "../Assets/icon/search-icon.svg";
import Button from "../Particle/Button";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";
import { useHistory } from "react-router-dom";
import IconFilter from "../Assets/icon/icon-filter.png";
const HeaderMobile = ({ title = "", openFilter = () => {} }) => {
    const history = useHistory();
    const [isSearch, setSearch] = React.useState(false);
    const [searchProduct, setSearchProduct] = React.useState("");
    const onChangeProduct = (e) => {
        let value = e.target.value;
        setSearchProduct(value);
    };
    const onShowSearch = () => {
        setSearch(!isSearch);
    };
    const onSubmitProduct = (e) => {
        e.preventDefault();
        history.push(`/search?${searchProduct}`);
        setSearch(false);
        // service.searchProduct({ dispatch, params: filter });
    };
    return (
        <HeaderStyle isSearch={isSearch}>
            <div className="header-top">
                {!isSearch ? (
                    <div className="header-left">
                        <Link to="/" className="back-link">
                            <img src={ArrowLeft} alt="" className="img-fluid" />
                        </Link>{" "}
                        <span className="title">{title}</span>
                    </div>
                ) : (
                    <div className="search-input">
                        <div onClick={onShowSearch} className="close-search">
                            <img src={ArrowLeft} alt="" className="img-fluid" />
                        </div>
                        <Input
                            onChange={onChangeProduct}
                            placeholder="Pakaian, Makanan, Vouc.."
                            type="search"
                        ></Input>
                    </div>
                )}

                <div>
                    {!isSearch && (
                        <Button
                            className="btn-search btn-filter"
                            color="transparent"
                            onClick={openFilter}
                        >
                            <img src={IconFilter} alt="" className="img-f" />
                        </Button>
                    )}
                    <Button
                        className="btn-search"
                        color="transparent"
                        onClick={isSearch ? onSubmitProduct : onShowSearch}
                    >
                        <img src={SearchIcon} alt="" className="img-fluid" />
                    </Button>
                </div>
            </div>
        </HeaderStyle>
    );
};
export default HeaderMobile;
