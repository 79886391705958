import React, { useState, useEffect, useCallback } from "react";
import { Row, Table } from "reactstrap";
import TableHead from "./index.head";
// import TableHeader from "./index.header";
import TableBody from "./index.body";
import TablePagination from "./index.pagination";
import TableTop from "./index.table-top";
import StyleCommon from "./index.style";

// begin reducer
import { useStore } from "../../../Reducers";
// end reducer

// constant variable
const DEFAULT_LIMIT_DATA = 10;
const DEFAULT_LIMIT_PAGINATION = 5;

const CommonTable = ({
    searchBy = "full_name",

    isRefresh = true,
    no_data = "Data tidak ada",
    edit = false,
    dummyData,
    setDataDummy = () => {},
    haveChecked = {
        check: false,
        action: () => {},
        actionData: () => {},
    },
    configuration = {
        searchInput: true,
        searchValue: "",
        nav: {
            hasSearch: true,
        },
        optionTable: [],
        rowHead: [],
        service: {
            group: false,
            key: false,
            id: false,
            api: () => {},
            filters: "",
            filterData: false,
        },
        header: {},
    },
    title = false,
}) => {
    const {
        optionTable,
        rowHead,
        service = false,
        nav,
        searchInput,
        searchValue = [],
        // searchValue,
    } = configuration;
    const { check = false, action = () => {} } = haveChecked;
    const { dispatch, state } = useStore();
    const [isNumber, setNumber] = React.useState(1);
    const [sortFilter, setSortFilter] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [configTable, setConfigurationTable] = useState(false);
    const [dataTable, setDataTable] = useState([]);
    const [params, setParams] = useState({
        page: 0,
        size: DEFAULT_LIMIT_DATA,
        filters: service.filterData
            ? `[${service.filterData}]`
            : `[${service.filters}]`,
    });

    const getData = (params) => {
        setLoading(true);
        if (service) {
            if (service.id) {
                service.api({
                    dispatch,
                    params,
                    id: service.id,
                });
            } else {
                service.api({
                    dispatch,
                    params,
                });
            }

            setParams(params);
        }
        if (!service) {
            setParams(params);
            setDataDummy(dummyData);
        }
    };

    const getDataCallBack = useCallback(getData, []);

    const responseGetData = (state) => {
        if (service) {
            if (state[service.group] && state[service.group][service.key]) {
                const { data, isSuccess } = state[service.group][service.key];
                if (isSuccess) {
                    const { number, next_page, total_pages, total_elements } =
                        data;

                    const isPagination = Number(total_elements) > 1;
                    const responseHeaderData = {
                        currentPage: Number(number + 1),
                        nextPage: next_page,
                        total_page: Number(total_pages),
                        total_elements: total_elements,
                        isPagination: isPagination,
                        paginationListNumber: total_pages,
                    };
                    setDataTable(data.content);
                    setConfigurationTable(responseHeaderData);
                }
                setLoading(false);
            }
        } else {
            setDataTable(dummyData);
            setDataDummy(dummyData);
            setLoading(false);
            return dataTable;
        }
    };

    const responseGetDataCallBack = useCallback(responseGetData, []);

    useEffect(() => {
        if (isRefresh) {
            getDataCallBack(params);
        }
    }, [isRefresh, params, getDataCallBack]);

    useEffect(() => {
        if (isRefresh) {
            responseGetDataCallBack(state);
        }
    }, [isRefresh, state, responseGetDataCallBack]);

    const onClickNumber = (number) => {
        setNumber(number);
        // console.log(number);
        getData({
            page: number,
            size: DEFAULT_LIMIT_DATA,
            sort: sortFilter,
            filters: params.filters,
        });
    };
    const onSearchDataDummy = (value) => {};
    const onSearchData = (value) => {
        // let example =
        params.page = 1;
        params.size = 10;
        const x = searchValue.map((item, key) => {
            if (key + 1 === searchValue.length) {
                return `["${item}","like","${value}"]`;
            } else {
                return `["${item}","like","${value}"],["or"]`;
            }
        });
        params.filters =
            value !== ""
                ? service.filterData !== ""
                    ? service.filterData !== ""
                        ? `[${x},["and"],${service.filterData}]`
                        : `[${x}]`
                    : `[${x}]`
                : `[${service.filterData}]`;

        getData(params);
    };

    const onGetValueFilterHead = (value) => {
        if (sortFilter === value) {
            params.sort = `-${value}`;
            setSortFilter(`-${value}`);
        } else {
            params.sort = value;
            setSortFilter(value);
        }

        params.page = 1;
        params.size = 10;
        getData(params);
    };
    return (
        <StyleCommon>
            <Row>
                <div className="col">
                    <div style={{ position: "relative" }}>
                        <div>
                            <TableTop
                                haveChecked={check}
                                searchBy={searchBy}
                                searchInput={searchInput}
                                onSearchDataDummy={onSearchDataDummy}
                                setDataTable={setDataTable}
                                service={service}
                                setDataDummy={setDataDummy}
                                dummyData={dummyData}
                                dataTable={dataTable}
                                onSearch={onSearchData}
                            ></TableTop>
                            <div
                                style={{
                                    borderRadius: "5px",
                                    boxShadow:
                                        "rgb(0 96 183 / 25%) 0px 2px 6px",
                                }}
                            >
                                <Table
                                    className="align-items-center"
                                    responsive
                                >
                                    <TableHead
                                        haveChecked={check}
                                        sortFilter={sortFilter}
                                        onGetValueFilter={onGetValueFilterHead}
                                        dataNav={nav}
                                        rowHead={rowHead}
                                        dataHead={optionTable}
                                        dataTable={dataTable}
                                    ></TableHead>
                                    <TableBody
                                        configTable={configTable}
                                        haveChecked={check}
                                        onGetValueChecked={action}
                                        no_data={no_data}
                                        isNumber={isNumber}
                                        edit={edit}
                                        dummyData={dummyData}
                                        isLoading={isLoading}
                                        optionTable={optionTable}
                                        dataTable={dataTable}
                                    ></TableBody>
                                </Table>
                            </div>

                            {configTable &&
                                !isLoading &&
                                configTable?.total_elements > 10 && (
                                    <TablePagination
                                        limitPagination={
                                            DEFAULT_LIMIT_PAGINATION
                                        }
                                        onClickNumber={onClickNumber}
                                        {...configTable}
                                    ></TablePagination>
                                )}
                        </div>
                    </div>
                </div>
            </Row>
        </StyleCommon>
    );
};

export default CommonTable;
