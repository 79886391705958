import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Header from "../../../../Components/Header";
import { useStore } from "../../../../Reducers";
import service from "../index.service";
import styled from "styled-components";
import CardArticle from "../../../../Components/Cards/CardArticle";
import Slider from "react-slick";
import { color } from "../../../../Utils/Variable";

const Style = styled.section`
    padding-top: 75px;
    .article {
        &-image {
            display: block;
            margin: 30px auto 15px;
        }
        &-time {
            color: #aaa;
            font-size: 12px;
            display: block;
            margin-bottom: 10px;
        }
        &-title {
            font-size: 16px;
        }
        &-desc {
            font-size: 14px;
        }
    }
    a {
        &:hover {
            text-decoration: unset;
        }
    }
    .slick-arrow {
        &:before {
            color: ${color.primary};
            font-size: 24px;
        }
        opacity: 0.5;
        &:hover {
            opacity: 1;
        }
    }
`;

const ArticleDetail = (
    {
        match: {
            params: { slug },
        },
    },
    props
) => {
    const month = [
        "Januari",
        "Febuari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
    ];
    const history = useHistory();
    const { dispatch, state } = useStore();
    const [data, setData] = React.useState(false);
    const [blogList, setBlogList] = React.useState(false);
    const isFirstGet = true;
    const settings = {
        dots: false,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
    };
    React.useEffect(() => {
        if (isFirstGet) {
            service.blogDetail({ dispatch, slug });
            service.blogList({ dispatch });
        }
    }, [isFirstGet, dispatch, slug]);
    const handleGetState = () => {
        if (state?.user?.blogDetail?.isSuccess) {
            setData(state.user.blogDetail.data);
            let replaceText = state.user.blogDetail.data.title.replace(
                / /g,
                "-"
            );
            window.scroll(0, 0);
            history.push(`/blog/${slug}/${replaceText.replace("/", "-atau-")}`);
            service.blogDetailClear({ dispatch });
        }
        if (state?.user?.blogDetail?.isError) {
            window.location = "/404";
        }
        if (state?.user?.blogList?.isSuccess) {
            setBlogList(state.user.blogList.data.content);
        }
    };
    const handleGetStateCallback = React.useCallback(handleGetState);
    React.useEffect(() => {
        handleGetStateCallback();
    }, [handleGetStateCallback]);

    React.useEffect(() => {});
    return (
        <React.Fragment>
            <Header menu={false}>
                <Link
                    to="/blog"
                    style={{ padding: "0.85rem 0.75rem", color: "#fff" }}
                    className="d-block"
                >
                    <i className="fas fa-arrow-left"></i> Kembali
                </Link>
            </Header>
            {data && (
                <Style>
                    <Container>
                        <Row className="justify-content-center">
                            <Col xl="10" lg="10" md="10">
                                <img
                                    src={data.article_image[0].img_url}
                                    alt={data.article_image[0].name}
                                    className="img-fluid article-image"
                                />
                                <time className="article-time">
                                    {String(
                                        new Date(data.created).getDate()
                                    ).padStart(2, "0")}{" "}
                                    {month[new Date(data.created).getMonth()]}{" "}
                                    {new Date(data.created).getFullYear()}
                                </time>
                                <h4 className="article-title">{data.title}</h4>
                                <div
                                    className="article-desc"
                                    dangerouslySetInnerHTML={{
                                        __html: data.content,
                                    }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <br />
                        <hr />
                        <br />
                        <br />
                        <br />
                        {blogList && (
                            <Slider {...settings}>
                                {blogList
                                    .filter((item) => item.title !== data.title)
                                    .map((item, key) => {
                                        return (
                                            <div key={key}>
                                                <Col>
                                                    <CardArticle data={item} />
                                                </Col>
                                            </div>
                                        );
                                    })}
                            </Slider>
                        )}
                    </Container>
                </Style>
            )}
        </React.Fragment>
    );
};
export default ArticleDetail;
