import { servicesAction } from "../../../Reducers";

const service = {
    productList: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/product/reseller",
            method: "GET",
            token: true,
            reducer: "service",
            group: "reseller",
            key: "product",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    commissionList: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/commission",
            method: "GET",
            token: true,
            reducer: "service",
            group: "commission",
            key: "commissionList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Commission List",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    commissionListClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "commission",
            key: "commissionList",
            type: "CLEAR",
        });
    },
    product: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/product",
            method: "GET",
            qs: true,
            params: params,
            token: true,
            reducer: "service",
            group: "reseller",
            key: "productList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    productClearList: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "reseller",
            key: "productList",
            type: "CLEAR",
        });
    },
    productResellerClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "reseller",
            key: "product",
            type: "CLEAR",
        });
    },
    category: ({ dispatch, paramsCategory }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/category",
            method: "GET",
            token: true,
            qs: true,
            params: paramsCategory,
            reducer: "service",
            group: "category",
            key: "category",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    allCity: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/shipper/allcity",
            method: "GET",
            qs: true,
            params: params,
            token: true,
            reducer: "service",
            group: "city",
            key: "allCity",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    categoryAll: ({ dispatch, paramsCategory }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/category",
            method: "GET",
            token: true,
            qs: true,
            params: paramsCategory,
            reducer: "service",
            group: "category",
            key: "categoryAll",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    deleteProduct: ({ dispatch, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/product/delete/${id}`,
            method: "DELETE",
            token: true,
            reducer: "service",
            group: "reseller",
            key: "deleteProduct",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    addProductClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "reseller",
            key: "addProduct",
        });
    },
    addProduct: ({ dispatch, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/product/add/${id}`,
            method: "POST",
            token: true,
            reducer: "service",
            group: "reseller",
            key: "addProduct",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Produk katalog berhasil di tambahkan",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    addProductMultiple: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/product/add",
            method: "POST",
            token: true,
            data: data,
            reducer: "service",
            group: "reseller",
            key: "addProductMultiple",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    addProductMultipleClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "reseller",
            key: "addProductMultiple",
            type: "CLEAR",
        });
    },
};
export default service;
