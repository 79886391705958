import { servicesAction } from "../../../Reducers";

const service = {
    getBanner: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/banner/customerAllBanner",
            method: "GET",
            reducer: "service",
            group: "user",
            key: "banner",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
            },
        });
    },
    newProduct: ({ dispatch, params, location }) => {
        servicesAction(dispatch).reduxFetch({
            url: `${
                location === "/new-product"
                    ? "/product/customerAllNoLogin"
                    : "/product/customerAllByRating"
            }`,
            qs: true,
            method: "GET",
            params: params,
            reducer: "service",
            group: "user",
            key: "listProduct",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
            },
        });
    },

    getProduct: ({ dispatch, slug }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/product/list/${slug}`,
            method: "GET",
            reducer: "service",
            group: "user",
            key: "product",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
            },
        });
    },
    getProductClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "product",
            type: "CLEAR",
        });
    },
    newProductClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "listProduct",
            type: "CLEAR",
        });
    },
};
export default service;
