import React from "react";
import Style from "./index.style";
import { Row } from "reactstrap";
import { color } from "../../Utils/Variable";
import Button from "../Particle/Button";

const SortFilters = ({ filterActive = "", onChangeFilter = () => {} }) => {
    return (
        <div>
            <h6 className="title">Urutkan</h6>
            <div className="lakugan-filter">
                <Row>
                    <div
                        className={`filter-value sort-product ${
                            filterActive === "product.rating" ? "active" : ""
                        }`}
                        onClick={() => onChangeFilter("product.rating")}
                    >
                        Terlaris
                    </div>
                    <div
                        className={`filter-value sort-product ${
                            filterActive === "product.price" ? "active" : ""
                        }`}
                        onClick={() => onChangeFilter("product.price")}
                    >
                        Harga Tertinggi
                    </div>
                    <div
                        className={`filter-value sort-product ${
                            filterActive === "-product.price" ? "active" : ""
                        }`}
                        onClick={() => onChangeFilter("-product.price")}
                    >
                        Harga Terendah
                    </div>
                    <div
                        className={`filter-value sort-product ${
                            filterActive === "product.rate" ? "active" : ""
                        }`}
                        onClick={() => onChangeFilter("product.rate")}
                    >
                        Ulasan
                    </div>
                </Row>
            </div>
        </div>
    );
};
const RatingFilter = ({ onSortRating = () => {}, sortRating = "" }) => {
    return (
        <div>
            <h6 className="title">Rating</h6>
            <div className="lakugan-filter">
                <Row>
                    <div
                        onClick={() => onSortRating(5)}
                        className={`filter-value filter-rating ${
                            sortRating === 5 ? "active" : ""
                        }`}
                    >
                        Bintang 5
                    </div>
                    <div
                        onClick={() => onSortRating(4)}
                        className={`filter-value filter-rating ${
                            sortRating === 4 ? "active" : ""
                        }`}
                    >
                        Bintang 4
                    </div>
                    <div
                        onClick={() => onSortRating(3)}
                        className={`filter-value filter-rating ${
                            sortRating === 3 ? "active" : ""
                        }`}
                    >
                        Bintang 3
                    </div>
                    <div
                        onClick={() => onSortRating(2)}
                        className={`filter-value filter-rating ${
                            sortRating === 2 ? "active" : ""
                        }`}
                    >
                        Bintang 2
                    </div>
                    <div
                        onClick={() => onSortRating(1)}
                        className={`filter-value filter-rating ${
                            sortRating === 1 ? "active" : ""
                        }`}
                    >
                        Bintang 1
                    </div>
                </Row>
            </div>
        </div>
    );
};
const CategoryValue = ({
    item,
    setSortFilter = () => {},
    onSortFilter = [],
    categoryFilters = [],
    setCategoryFilters = () => {},
}) => {
    const [isActive, setActive] = React.useState(
        categoryFilters.filter((value) => value === item.name).length === 1
            ? item.id
            : 0
    );
    const handleFilterCategory = (item) => {
        if (isActive === 0) {
            setCategoryFilters((prevstate) => {
                return categoryFilters.length < 1
                    ? [...prevstate, item.name]
                    : [...prevstate, item.name];
            });
            setSortFilter((prevstate) => {
                return onSortFilter.length < 1
                    ? [...prevstate, `["category.id","=","${item.id}"]`]
                    : [...prevstate, `["or"],["category.id","=","${item.id}"]`];
            });
            setActive(item.id);
        } else {
            const removeCategory = categoryFilters.filter(function (value) {
                let withOr = value !== item.name;
                let notWithOr = value !== item.name;
                return notWithOr ? withOr : notWithOr;
            });
            const removeFilter = onSortFilter.filter(function (value) {
                let withOr = value !== `["category.id","=","${item.id}"]`;
                let notWithOr =
                    value !== `["or"],["category.id","=","${item.id}"]`;
                return notWithOr ? withOr : notWithOr;
            });
            setCategoryFilters(removeCategory);
            setSortFilter(removeFilter);
            setActive(0);
        }
    };
    return (
        <div
            className={`filter-value filter-category ${
                categoryFilters.filter((value) => value === item.name)
                    .length === 1
                    ? "active"
                    : ""
            }`}
            onClick={() => handleFilterCategory(item)}
        >
            {item.name}
        </div>
    );
};
const CategoryFilter = ({
    category = [],
    showAllCategoryFilter = () => {},
    setSortFilter = () => {},
    onSortFilter = [],
    categoryFilters = [],
    setCategoryFilters = () => {},
}) => {
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h6 className="title">Kategori</h6>
                <small
                    style={{ color: color.primary }}
                    onClick={showAllCategoryFilter}
                >
                    Lihat Semua
                </small>
            </div>
            <div className="lakugan-filter">
                <Row>
                    {category.map((item, key) => {
                        return (
                            <CategoryValue
                                categoryFilters={categoryFilters}
                                setCategoryFilters={setCategoryFilters}
                                onSortFilter={onSortFilter}
                                setSortFilter={setSortFilter}
                                item={item}
                                key={key}
                            />
                        );
                    })}
                </Row>
            </div>
        </div>
    );
};
const OtherFilters = ({ onOtherFilter = () => {}, otherFilter = "" }) => {
    return (
        <div>
            <h6 className="title">Lainnya</h6>
            <div className="lakugan-filter">
                <Row>
                    <div
                        onClick={() => onOtherFilter("pre-order")}
                        className={`filter-value filter-other ${
                            otherFilter === "pre-order" ? "active" : ""
                        }`}
                    >
                        Pre-Order
                    </div>
                    <div
                        onClick={() => onOtherFilter("stock")}
                        className={`filter-value filter-other ${
                            otherFilter === "stock" ? "active" : ""
                        }`}
                    >
                        Stok Tersedia
                    </div>
                </Row>
            </div>
        </div>
    );
};
const FilterMobile = ({
    onClose = () => {},
    activeFilter = false,
    showAllCategoryFilter = () => {},
    categoryList = [],
    filterActive = "",
    onChangeFilter = () => {},
    setSortFilter = () => {},
    onSortFilter = [],
    categoryFilters = [],
    setCategoryFilters = () => {},
    onSortRating = () => {},
    sortRating = "",
    onOtherFilter = () => {},
    otherFilter = "",
    onRemoveFilter = () => {},
    onFilterProduct = () => {},
}) => {
    return (
        <Style active={activeFilter}>
            <div className="filter">
                <div className="filter-header">
                    <h6 className="title">Filter</h6>
                    <i className="fas fa-times" onClick={onClose}></i>
                </div>
                <div className="filter-body">
                    <SortFilters
                        onChangeFilter={onChangeFilter}
                        filterActive={filterActive}
                    />
                    <RatingFilter
                        sortRating={sortRating}
                        onSortRating={onSortRating}
                    />
                    <CategoryFilter
                        setCategoryFilters={setCategoryFilters}
                        onSortFilter={onSortFilter}
                        setSortFilter={setSortFilter}
                        categoryFilters={categoryFilters}
                        category={categoryList}
                        showAllCategoryFilter={showAllCategoryFilter}
                    />
                    <OtherFilters
                        onOtherFilter={onOtherFilter}
                        otherFilter={otherFilter}
                    />
                </div>
                <div className="text-center p-3">
                    <div className="mb-3">
                        <Button color="primary" onClick={onFilterProduct}>
                            Gunakan Filter
                        </Button>
                    </div>
                    <div>
                        <Button
                            onClick={onRemoveFilter}
                            color="transparent"
                            style={{ color: color.primary }}
                        >
                            Hapus Filter
                        </Button>
                    </div>
                </div>
            </div>
        </Style>
    );
};
export default FilterMobile;
