export const navMenu = [
    {
        title: "Lakugan",
        menuList: [
            {
                list: "Tentang Kami",
                link: "/about-us",
            },
            {
                list: "Blog",
                link: "/blog",
            },
            {
                list: "FAQ",
                link: "/FAQ",
            },
        ],
    },
    {
        title: "Pengguna",
        menuList: [
            {
                list: "Daftar Reseller",
                link: "/signup/reseller",
            },
            {
                list: "Daftar Supplier",
                link: "/signup/supplier",
            },
        ],
    },
];
