import React from "react";
import styled from "styled-components";
import RatingIcon from "../../Assets/icon/star-rating.png";
import RatingIconActive from "../../Assets/icon/startActive.png";
const RatingStyle = styled.div`
    text-align: left;
    img {
        width: ${(props) => props.size};
        cursor: pointer;
    }
    span {
        font-size: 12px;
    }
    ${({ ratingStart }) =>
        ratingStart &&
        `
        text-align: center;
        &:hover > i:before {
            color: gold;
            cursor: pointer;
        }

        > i:hover ~ i:before {
            color: #bbc0d5;
            cursor: pointer;
        }
    `}
`;

const Rating = ({
    onGetValue = () => {},
    ratingStart = false,
    size = "14px",
    style = {},
    rating = 0,
    totalStars = 5,
    activeColor = "#ffc400",
    inActiveColor = "#bbc0d5",
    textColor = "text-black-50",
}) => {
    return (
        <RatingStyle size={size} ratingStart={ratingStart}>
            {[...Array(totalStars)].map((el, i) =>
                i < Math.floor(rating) ? (
                    <React.Fragment>
                        <img
                            style={{ ...style }}
                            onClick={() => onGetValue({ rating: i + 1 })}
                            value={i + 1}
                            src={RatingIconActive}
                            alt="rating"
                            className="img-fluid img-rating img-rating__active"
                        />
                    </React.Fragment>
                ) : (
                    <img
                        style={{ ...style }}
                        onClick={() => onGetValue({ rating: i + 1 })}
                        value={i + 1}
                        src={RatingIcon}
                        alt="rating"
                        className="img-fluid img-rating"
                    />
                )
            )}
            {textColor && <span className={textColor}>{` (${rating})`}</span>}
        </RatingStyle>
    );
};

export default Rating;
