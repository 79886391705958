import React from "react";
import { CardBody, Card, Col } from "reactstrap";
import Button from "../../../../Components/Particle/Button";
import Category from "./Category";
import City from "./City";

const Filter = ({
    handleGetAllCategory = () => {},
    handleShowFilter = () => {},
    handleCloseCity = () => {},
    handlerSearchCity = () => {},
    setCategoryFilters = () => {},
    handlerSearchCategory = () => {},
    setSortFilter = () => {},
    handleFilterAction = () => {},
    handleCloseCategory = () => {},
    setShowAllCategory = () => {},
    setOpenCategory = () => {},
    setOpenCity = () => {},
    setCityFilters = () => {},
    handleGetAllCity = () => {},
    setShowAllCity = () => {},
    setSortFilterCity = () => {},
    onSortFilterCity = [],
    cityFilters = [],
    city = [],
    allCity = [],
    isShowFilter = true,
    showAllCity = false,
    showAllCategory = false,
    searchValueCity = "",
    searchValueCategory = "",
    allCategory = false,
    categoryFilters = [],
    onSortFilter = [],
    openCategory = false,
    openCity = false,
    category = [],
}) => {
    return (
        <Col xl="3" lg="3" md="3">
            <div className="label-filter">
                <div className="label-top" onClick={handleShowFilter}>
                    <i className="fas fa-filter mr-2"></i>Filter
                </div>
            </div>
            <div
                className={isShowFilter ? "card-filter active" : "card-filter"}
            >
                <Card className={isShowFilter ? "active" : ""}>
                    <CardBody>
                        <Category
                            handlerSearchCategory={handlerSearchCategory}
                            handleGetAllCategory={handleGetAllCategory}
                            handleShowFilter={handleShowFilter}
                            handleCloseCategory={handleCloseCategory}
                            isShowFilter={isShowFilter}
                            showAllCategory={showAllCategory}
                            searchValueCategory={searchValueCategory}
                            allCategory={allCategory}
                            categoryFilters={categoryFilters}
                            setCategoryFilters={setCategoryFilters}
                            onSortFilter={onSortFilter}
                            setSortFilter={setSortFilter}
                            setShowAllCategory={setShowAllCategory}
                            setOpenCategory={setOpenCategory}
                            openCategory={openCategory}
                            category={category}
                            handleFilterAction={handleFilterAction}
                        />
                        <City
                            handleCloseCity={handleCloseCity}
                            searchValueCity={searchValueCity}
                            handlerSearchCity={handlerSearchCity}
                            setShowAllCity={setShowAllCity}
                            showAllCity={showAllCity}
                            handleGetAllCity={handleGetAllCity}
                            cityFilters={cityFilters}
                            setCityFilters={setCityFilters}
                            openCity={openCity}
                            setOpenCity={setOpenCity}
                            city={city}
                            allCity={allCity}
                            onSortFilterCity={onSortFilterCity}
                            setSortFilterCity={setSortFilterCity}
                        />
                        <div className="filter-action">
                            <div style={{ marginTop: 20 }}>
                                <Button
                                    color="primary"
                                    block
                                    onClick={handleFilterAction}
                                >
                                    Terapkan
                                </Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </Col>
    );
};
export default Filter;
