import styled from "styled-components";
import { color } from "../../../../../Utils/Variable";

const Style = styled.div`
  padding-top: 120px;
  @media (max-width: 993px) {
    padding-top: 100px;
  }
  .header-title {
    display: flex;
    align-items: baseline;
  }
  .title {
    margin-bottom: 15px;
    // font-weight: bold;
    // font-size: 32px;
    color: #000000;
    margin-right: 30px;
    @media (max-width: 414px) {
      font-size: 19px;
      margin-right: 0;
    }
  }
  .sub-title {
    // font-weight: bold;
    // font-size: 16px;
    color: #000000;
  }
  .order-id {
    // font-weight: 600;
    // font-size: 20px;
    color: #000000;
    @media (max-width: 414px) {
      font-size: 14px;
    }
  }
  .icon-area {
    text-align: center;
    margin-bottom: 15px;
    position: relative;
    img {
      position: relative;
      z-index: 3;
    }
  }
  .button-area {
    display: flex;
    justify-content: space-between;
  }
  .icon-border {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      background: #ddd;
      top: 30%;
      left: 100px;
      @media (max-width: 993px) {
        left: 94px;
      }
      @media (max-width: 768px) {
        left: 75px;
      }
      @media (max-width: 414px) {
        left: 50px;
      }
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      background: #ddd;
      bottom: 30%;
      left: 100px;
      @media (max-width: 993px) {
        left: 94px;
      }
      @media (max-width: 768px) {
        left: 75px;
      }
      @media (max-width: 414px) {
        left: 50px;
      }
    }
    &.active {
      &:before {
        background: ${color.primary};
      }
      &:after {
        background: ${color.primary};
      }
    }
  }
  .card-address {
    border: 1.5px solid #e8e8e8;
    border-radius: 20px;
  }
  .status {
    font-weight: 300;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    @media (max-width: 550px) {
      font-size: 15px;
    }
    &.active {
      color: ${color.primary};
      font-weight: bold;
    }
    @media (max-width: 414px) {
      font-size: 14px;
    }
  }
  .title-btn {
    @media (max-width: 414px) {
      font-size: 12px;
    }
  }
  .btn-size {
    padding: 12px 40px;
    @media (max-width: 550px) {
      padding: 4px 24px;
    }
    @media (min-width: 300px) and (max-width: 333px) {
      padding: 4px 16px;
    }
  }
  .img-done-transaction {
    width: 120px;
    @media (max-width: 550px) {
      width: 64px;
    }
  }
  .btn-reseller {
    border-radius: 4px;
    @media (max-width: 550px) {
      border-radius: 8px;
    }
  }
`;
export default Style;
