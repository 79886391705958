import React from "react";
import { Style, SectionStyle } from "./index.style";
import NotFound from "../../../Components/Assets/Images/not_found_search.png";
import SkeletonLoading from "../../../Components/Loading/Skeleton";
import { Col, Row } from "reactstrap";
import Button from "../../../Components/Particle/Button";
import CardNew from "../../../Components/Cards/CardNew";
const NotFoundProduct = ({
    title = "",
    data = [],
    dataShow = 10,
    onGetValue = () => {},
    params = {
        size: 0,
    },
    totalElements = 0,
    handleLoadData = () => {},
    isLoading = false,
}) => {
    return (
        <React.Fragment>
            <Style>
                <div className="not-found">
                    <img src={NotFound} alt="Not Found" className="img-fluid" />
                    <div className="wording">
                        Produk yang kamu cari sedang kosong nih,
                        <br />
                        tapi masih banyak produk bagus lainnya loh!
                    </div>
                </div>
            </Style>
            <SectionStyle>
                <div className="header-title">
                    <h4 className="title">{title}</h4>
                </div>

                {data.length > 0 ? (
                    <Row>
                        {data.map((item, key) => {
                            return (
                                <Col xl={3} md={3} xs={6} key={key}>
                                    <CardNew
                                        onGetValue={onGetValue}
                                        item={item}
                                        image={
                                            item.product.product_image[0]
                                                ?.img_url
                                        }
                                        alt={
                                            item.product.product_image[0]?.name
                                        }
                                        name={item.product.name}
                                        rating={item.product.rating}
                                        price={item.product.price}
                                        city={item.store.city}
                                        stock={item.product.total_stock_product}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                ) : (
                    <Row>
                        {[...Array(4)].map((item, key) => {
                            return (
                                <Col xl={3} md={3} xs={6} key={key}>
                                    <SkeletonLoading
                                        width="100%"
                                        height="250px"
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                )}
                {isLoading && (
                    <Row>
                        {[...Array(4)].map((item, key) => {
                            return (
                                <Col xl={3} md={3} key={key}>
                                    <SkeletonLoading
                                        width="100%"
                                        height="250px"
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                )}
                <div className="see-more">
                    {params.size < totalElements && (
                        <Button
                            onClick={handleLoadData}
                            color="primary"
                            style={{ paddingRight: 30, paddingLeft: 30 }}
                        >
                            Lebih Banyak
                        </Button>
                    )}
                </div>
            </SectionStyle>
        </React.Fragment>
    );
};
export default NotFoundProduct;
