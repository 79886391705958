import { servicesAction } from "../../../Reducers";
const service = {
    transactionList: ({ dispatch, params, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/transaction/client/${id}`,
            params: params,
            qs: true,
            method: "GET",
            token: true,
            reducer: "service",
            group: "reseller",
            key: "transactionList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Daftar transaksi",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
    transactionListClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "reseller",
            key: "transactionList",
            type: "CLEAR",
        });
    },
    commissionList: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/commission",
            method: "GET",
            token: true,
            reducer: "service",
            group: "commission",
            key: "commissionList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Commission List",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    transactionComplaint: ({ dispatch, transaction_code, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/complaint/${transaction_code}`,
            data: data,
            method: "POST",
            token: true,
            reducer: "service",
            group: "reseller",
            key: "complaint",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Komplain pemesanan berhasil dikirim, terimakasih",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
    transactionComplaintClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "reseller",
            key: "complaint",
            type: "CLEAR",
        });
    },
    complaintSendReceipt: ({ dispatch, transaction_code, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/complaint/${transaction_code}/customer`,
            method: "PUT",
            data: data,
            token: true,
            reducer: "service",
            group: "reseller",
            key: "complaintReceiptSent",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Nomor Resi berhasil terkirim",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
    orderDetail: ({ dispatch, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/biteship/pickup-order/${id}`,
            token: true,
            method: "GET",
            reducer: "service",
            group: "shipper",
            key: "orderId",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
            },
        });
    },
    transactionDetail: ({ dispatch, transaction_id, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/transaction/${transaction_id}/${id}`,
            method: "GET",
            token: true,
            reducer: "service",
            group: "supplier",
            key: "transactionDetail",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
            },
        });
    },
    transactionDetailClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "supplier",
            key: "transactionDetail",
            type: "CLEAR",
        });
    },
    orderDetailClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "shipper",
            key: "orderId",
            type: "CLEAR",
        });
    },
    commissionListClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "commission",
            key: "commissionList",
            type: "CLEAR",
        });
    },
    SentReceiptActionClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "reseller",
            key: "complaintReceiptSent",
            type: "CLEAR",
        });
    },
};
export default service;
