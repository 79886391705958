import React from "react";
import { Col, Container, Row } from "reactstrap";
import Style from "./index.style";
// PAYMENT
import Niaga from "../../../../Components/Assets/icon/niaga.png";
import Ovo from "../../../../Components/Assets/icon/ovo.png";
import Gopay from "../../../../Components/Assets/icon/gopay.png";
import LinkAja from "../../../../Components/Assets/icon/link_aja.png";
import Danamon from "../../../../Components/Assets/icon/danamon.png";
import Dana from "../../../../Components/Assets/icon/dana.png";
import Maybank from "../../../../Components/Assets/icon/maybank.png";
import Sinarmas from "../../../../Components/Assets/icon/sinar_mas.png";
// SHIPPER
import Tiki from "../../../../Components/Assets/icon/tiki.png";
import SiCepat from "../../../../Components/Assets/icon/sicepat.png";
import Anteraja from "../../../../Components/Assets/icon/anteraja.png";
import JNE from "../../../../Components/Assets/icon/jne.png";
import LionParcel from "../../../../Components/Assets/icon/lion-parcel.png";
import Ninja from "../../../../Components/Assets/icon/ninja.png";
import Wahana from "../../../../Components/Assets/icon/wahana.png";
import RPX from "../../../../Components/Assets/icon/rpx.png";
import Grab from "../../../../Components/Assets/icon/grab.png";
import AlfaTrex from "../../../../Components/Assets/icon/alfatrex.png";
const SectionBottom = () => {
  const dataPayment = [
    {
      image: Sinarmas,
      className: "grid-item__1",
    },
    {
      image: Niaga,
      className: "grid-item__2",
    },
    {
      image: Maybank,
      className: "grid-item__3",
    },
    {
      image: LinkAja,
      className: "grid-item__4",
    },
    {
      image: Danamon,
      className: "grid-item__6",
    },
    {
      image: Ovo,
      className: "grid-item__13",
    },
    {
      image: Gopay,
      className: "grid-item__11",
    },
    {
      image: Dana,
      className: "grid-item__14",
    },
  ];
  const dataShipper = [
    {
      image: Anteraja,
      className: "grid-item__1",
    },
    {
      image: SiCepat,
      className: "grid-item__9",
    },
    {
      image: Tiki,
      className: "grid-item__3",
    },
    {
      image: JNE,
      className: "grid-item__10",
    },
    {
      image: LionParcel,
      className: "grid-item__6",
    },
    {
      image: Wahana,
      className: "grid-item__5",
    },
    {
      image: Ninja,
      className: "grid-item__11",
    },
    {
      image: RPX,
      className: "grid-item__14",
    },
    {
      image: Grab,
      className: "grid-item__13",
    },
    {
      image: AlfaTrex,
      className: "grid-item__15",
    },
  ];
  return (
    <Style>
      <Container fluid="md">
        <Row className="justify-content-between">
          <Col xl={6} md={6}>
            <div className="col-payment">
              <h3 className="title">Pembayaran</h3>
              <div className="grid-payment">
                {dataPayment.map((item, key) => {
                  return (
                    <img
                      key={key}
                      src={item.image}
                      alt="payment_channel"
                      className={`${item.className} img-fluid`}
                    />
                  );
                })}
              </div>
            </div>
          </Col>
          <Col xl={6} md={6}>
            <div className="col-shipper">
              <h3 className="title">Jasa Pengiriman</h3>
              <div className="grid-shipper">
                {dataShipper.map((item, key) => {
                  return (
                    <img
                      key={key}
                      src={item.image}
                      alt="payment_channel"
                      className={`${item.className} img-fluid`}
                    />
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Style>
  );
};
export default SectionBottom;
