import React from "react";
import { useHistory } from "react-router";
import Slider from "react-slick";
import service from "./index.service";
import { Link } from "react-router-dom";
import { useStore } from "../../../Reducers";
import { Col, Container, Row } from "reactstrap";
import CardNew from "../../../Components/Cards/CardNew";
import SkeletonLoading from "../../../Components/Loading/Skeleton";
import Button from "../../../Components/Particle/Button";
import { Style } from "./index.style";
import HeaderMobile from "../../../Components/Header/HeaderMobile";

const DEFAULT_LIMIT_DATA = 16;
const ListNewAndRateProduct = () => {
    const { dispatch, state } = useStore();
    const [banner, setBanner] = React.useState(false);
    const location = window.location.pathname;
    const history = useHistory();
    const [isFirstGet, setFirstGet] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [totalElements, setTotalElements] = React.useState(0);
    const [params, setParams] = React.useState({
        page: 0,
        size: DEFAULT_LIMIT_DATA,
    });
    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <button
                style={{ ...style, border: "unset" }}
                onClick={onClick}
                className={className}
                type="button"
                // className="prev"
            >
                <i className="fas fa-chevron-left"></i>
            </button>
        );
    };
    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <button
                style={{ ...style, border: "unset" }}
                onClick={onClick}
                className={className}
                type="button"
                // className="next"
            >
                <i className="fas fa-chevron-right"></i>
            </button>
        );
    };
    const settingsBanner = {
        dots: true,
        infinite: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        speed: 1000,
        autoplay: true,
    };
    const getData = (params) => {
        service.newProduct({ dispatch, params, location });
        setParams(params);
    };
    const onGetValue = (item) => {
        // service.getProduct({ dispatch, slug: item.id });
        history.push(`/product/${item.code}`);
    };
    const handleLoadData = () => {
        setLoading(true);
        getData({
            page: params.page + 1,
            size: params.size + DEFAULT_LIMIT_DATA,
        });
    };
    React.useEffect(() => {
        if (isFirstGet) {
            service.newProduct({ dispatch, params, location });
            service.getBanner({ dispatch });
            setFirstGet(false);
        }
    }, [isFirstGet, dispatch, params, location]);
    React.useEffect(() => {
        if (state?.user?.banner?.isSuccess) {
            setBanner(state.user.banner.data.content);
        }
        if (state?.user?.listProduct?.isSuccess) {
            setLoading(false);
            setData((prevState) => {
                return [...prevState, ...state.user.listProduct.data.content];
            });
            service.newProductClear({ dispatch });
            setTotalElements(state.user.listProduct.data.total_elements);
        }
        if (state?.user?.product?.isSuccess) {
            history.push(`/product/${state.user.product.data.product_code}`);
            service.getProductClear({ dispatch });
        }
    }, [state, history, dispatch]);
    return (
        <Style>
            <HeaderMobile
                title={
                    window.location.pathname === "best-product"
                        ? "Produk Terlaris"
                        : "Produk Terbaru"
                }
            />
            {banner && (
                <div className="banner">
                    <Container fluid={"md"}>
                        <Slider {...settingsBanner}>
                            {banner.map((item, key) => {
                                return (
                                    <div key={key}>
                                        <Col>
                                            <Link
                                                to={
                                                    item.banner_url === ""
                                                        ? "/"
                                                        : item.banner_url
                                                }
                                            >
                                                <img
                                                    src={item.image.img_url}
                                                    alt=""
                                                    className="img-fluid image-banner"
                                                />
                                            </Link>
                                        </Col>
                                    </div>
                                );
                            })}
                        </Slider>
                    </Container>
                </div>
            )}
            <Container fluid="md">
                <section className="section-product">
                    {data.length > 0 ? (
                        <Row>
                            {data.map((item, key) => {
                                return (
                                    <Col xl={3} md={3} xs={6} key={key}>
                                        <CardNew
                                            onGetValue={onGetValue}
                                            item={item}
                                            image={
                                                item.product.product_image[0]
                                                    ?.img_url
                                            }
                                            alt={
                                                item.product.product_image[0]
                                                    ?.name
                                            }
                                            name={item.product.name}
                                            rating={item.product.rating}
                                            price={item.product.price}
                                            city={item.store.city}
                                            stock={
                                                item.product.total_stock_product
                                            }
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    ) : (
                        <Row>
                            {[...Array(4)].map((item, key) => {
                                return (
                                    <Col xl={3} md={3} key={key}>
                                        <SkeletonLoading
                                            width="100%"
                                            height="250px"
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    )}
                    {isLoading && (
                        <Row>
                            {[...Array(4)].map((item, key) => {
                                return (
                                    <Col xl={3} md={3} key={key}>
                                        <SkeletonLoading
                                            width="100%"
                                            height="250px"
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    )}
                </section>
                <div className="text-center">
                    {params.size < totalElements && (
                        <Button
                            onClick={handleLoadData}
                            color="primary"
                            style={{ paddingRight: 30, paddingLeft: 30 }}
                        >
                            Lebih Banyak
                        </Button>
                    )}
                </div>
            </Container>
        </Style>
    );
};
export default ListNewAndRateProduct;
