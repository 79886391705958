import React from "react";
import Header from "../Header";
import Footer from "../../../../Components/Footer";
import service from "../index.service";
import { useStore } from "../../../../Reducers";
import { useHistory } from "react-router-dom";
import GrowingLoading from "../../../../Components/Loading/GrowingLoading";
import MessageState from "../../../../Components/Modal/Message";
// import Logo from "../../../../Components/Assets/Images/Lakugan_transparent.png";
import StatusZero from "./SettlementStatus/StatusZero";
// import StatusTen from "./SettlementStatus/StatusTen";
import StatusOne from "./SettlementStatus/StatusOne";
// import StatusFour from "./SettlementStatus/StatusFour";
// import StatusThree from "./SettlementStatus/StatusThree";
import LocalStorage from "../../../../Utils/LocalStorage";
// import StatusTen from "./SettlementStatus/StatusTen";
// import StatusFive from "./SettlementStatus/StatusFive";

const Settlement = (props) => {
    const history = useHistory();
    const { dispatch, state } = useStore();
    const [data, setData] = React.useState(false);
    const [status, setStatus] = React.useState(true);
    const [message, setMessage] = React.useState("");
    const [showMessage, setShowMessage] = React.useState(false);
    const [isFirstGet, setFirstGet] = React.useState(true);

    const [isLoading, setLoading] = React.useState(false);
    // redirect page status payment
    const statusPayment = () => {
        if (data) {
            switch (data.status) {
                case 0:
                    return (
                        <StatusZero
                            data={data}
                            stateTransaction={state?.user?.transaction}
                        />
                    );
                case 1:
                    return <StatusOne data={data} activeKey={2} />;
                case 3:
                    return <StatusOne data={data} activeKey={3} />;
                case 4:
                    return <StatusOne data={data} activeKey={4} />;
                case 5:
                    return (
                        <StatusOne
                            data={data}
                            activeKey={5}
                            setLoading={setLoading}
                        />
                    );
                case 10:
                    return (
                        <StatusOne
                            data={data}
                            activeKey={5}
                            setLoading={setLoading}
                        />
                    );
                case 13:
                    return <div>Pembayaran anda telah dibatalkan</div>;
                default:
                    return (window.location = "/404");
            }
        }
    };
    React.useEffect(() => {
        if (isFirstGet) {
            const url = new URL(window.location.href);
            service.transactionCode({
                dispatch,
                params: url.searchParams.get("transaction_id"),
            });

            setFirstGet(false);
        }
    }, [isFirstGet, dispatch]);

    React.useEffect(() => {
        if (state?.user?.transaction?.isSuccess) {
            var url = new URL(window.location.href);
            let transactionCode =
                state.user.transaction.data.transaction_code.split("/");
            if (
                // ini bikin manual dari depan untuk url payment dari sisi customer, soalnya dari belakang ga sediain url callback sukses payment
                url.searchParams.get("full_name") ===
                    state.user.transaction.data.customer_detail.full_name &&
                Number(url.searchParams.get("id")) ===
                    state.user.transaction.data.customer_detail.id &&
                url.searchParams.get("product_name") ===
                    state.user.transaction.data.product.name &&
                url.searchParams.get("transaction_id") === transactionCode[1] &&
                url.searchParams.get("payment_channel") ===
                    state.user.transaction.data.payment_channel.name
            ) {
                setData(state.user.transaction.data);
                service.transactionCodeClear({ dispatch });
            } else {
                history.push("/404");
            }
        }
        if (state?.user?.transaction?.isError) {
            history.push("/404");
        }
        if (state?.user?.productRating?.isSuccess) {
            let url = LocalStorage().get("_ORDER_ITEM_LAKUGAN");
            setLoading(false);
            setStatus(state.user.productRating.isSuccess);
            setMessage(state.user.productRating.message.text);
            setShowMessage(true);
            setTimeout(() => {
                window.location = `/product/${url.item}`;
                setShowMessage(false);
            }, 2000);
            service.ratingProductClear({ dispatch });
        }
        if (state?.user?.productRating?.isError) {
            setLoading(false);
            setStatus(state.user.productRating.isSuccess);
            setMessage(state.user.productRating.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
            service.ratingProductClear({ dispatch });
        }
        if (state?.user?.transactionDone?.isSuccess) {
            window.location.reload();
        }
        if (state?.user?.transactionDone?.isError) {
            setStatus(state.user.transactionDone.isSuccess);
            setMessage(state.user.transactionDone.data.message);
            setShowMessage(true);
            setLoading(false);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
            service.transactionDoneClear({ dispatch });
        }
    }, [state, history, dispatch]);
    React.useEffect(() => {
        if (data?.is_rated && data?.status === 10) {
            window.location = "/404";
        }
    }, [data]);
    return (
        <React.Fragment>
            <Header history={"order/beli-sekarang"} isShow={true} />
            {statusPayment()}
            <GrowingLoading active={isLoading} />
            <MessageState
                status={status}
                message={message}
                isShow={showMessage}
            />
            <Footer footerTop={false} />
        </React.Fragment>
    );
};
export default Settlement;
