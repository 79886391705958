import styled from "styled-components";

const Style = styled.div`
    .label-country {
        @media (max-width: 575px) {
            font-size: 12px;
        }
    }
    .method-payment {
        @media (max-width: 767px) {
            position: fixed;
            z-index: 5;
            background: rgba(0, 0, 0, 0.4);
            left: 0;
            right: 0;
            bottom: 0;
            padding-top: 100px;
            transition: all 0.5s ease;
            top: 2000px;
            overflow: auto;
            &.active {
                top: 0;
                transition: all 0.5s ease;
            }
            > .form-group {
                background: #fff;
                padding: 20px 20px 0;
                border-radius: 20px 20px 0 0;
                margin-bottom: 0;
                padding-bottom: 30px;
            }
        }
    }
    .dropdown-toggle {
        display: flex;
        flex-direction: row;
        justify-content: space-between; 
        align-items: center;
        width: 100%;
        margin: 12px 0;
        ::after{
            content: none;
        }
    }
    .customer-info {
        display: none;
        padding-top: 1rem;
        @media (min-width: 600px) {
            display: block;
            font-weight: 600;
            padding-top: 24px;
        }
        &-no-address {
            display: flex; 
            flex-direction: column;
            border: 1.5px solid #E8E8E8; 
            border-radius: 20px; 
            margin-top: 16px;
            @media (max-width: 550px) {
                margin: 0 16px;
                margin-top: 22%;
            }
            h5 {
                margin: 0 auto;
                padding: 16px; 
                font-weight: 600;
                @media (max-width: 550px) {
                    font-size: 16px !important;
                }
                @media (max-width: 310px) {
                    padding: 6px;
                }
            }
            img {
                width: 146px;
                height: 169px;
                margin: 0 auto;
                @media (max-width: 310px) {
                    width: 90px;
                    height: 110px;
                }
            }
            Button {
                width: calc(1/5*100%);
                margin: 16px auto; 
                border-radius: 8px;
                @media (max-width: 550px) {
                    width: 10rem;
                    height: 2rem;
                    font-size: 12px !important;
                    font-weight: 600;
                }
            }
        }
    }
    .customer-address {
        display: flex;
        flex-direction: column;
        border: 1.5px solid #E8E8E8; 
        border-radius:20px;
        margin-top: 16px; 
        padding: 22px;
        @media (max-width: 550px) {
            margin: 22% 2% 0 2%;
        }
        &-wrap {
            display: flex;
            flex-direction: row; 
            justify-content: space-between;
            p {
                font-weight: 500;
                font-size: 18px;
                margin: 0;
                width: 100%;
                overflow-wrap: break-word;
                @media (max-width: 550px) {
                    font-size: 14px;
                }
            }
        }
        &-info {
            width: 100%;
            overflow-wrap: break-word;
            font-size: 18px; 
            font-weight: 400;
            @media (max-width: 550px) {
                font-size: 12px;
            }
            &-wrapper {
                margin: 0 0 32 16;
                font-family: poppins;
            }
            &-head {
                width: 100%; 
                overflow-wrap: break-word; 
                font-weight: 500;  
                font-size: 18px;
            }
        }
        &-edit {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 12px; 
            color: #000A8C;
            img {
                height: 23px;
                width: 23px; 
                margin-bottom: 8px;
            }
            @media (max-width: 550px) {
                font-size: 10px;
                img {
                    height: 18px;
                    width: 18px;
                    margin-bottom: 2px;
                }
            }
        }
    }
    .address-wrapper {
        @media (max-width: 550px) {
            padding-top: 16px;
        }
    }
    .mobile-only {
        display: none;
        @media (max-width: 550px) {
            display: block:
        }
    }
    .buy-btn-mobile {
        padding: 8px 1rem;
        margin: 1.6rem 0;
        min-width: 142px;
        border: none;
        background: #000a8c;
        color: white;border-radius: 8px;
        font-weight: 600;"
    }
`;
export default Style;