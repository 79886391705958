import React from "react";
import { Card, CardBody } from "reactstrap";
import styled from "styled-components";
import GrowingLoading from "../../../Components/Loading/GrowingLoading";
import Form from "../../../Components/Molekul/FormValidation";
import Button from "../../../Components/Particle/Button";
import service from "./index.service";
import { useStore } from "../../../Reducers";
import Tab from "./Tab";
import LocalStorage from "../../../Utils/LocalStorage";
import MessageState from "../../../Components/Modal/Message";
const Style = styled(Card)`
    border: unset;
    width: 1200px;
    margin: auto;
    div.row {
        display: block;
    }
    hr {
        margin-top: 0;
        margin-bottom: 50px;
    }
    @media (max-width: 1200px) {
        width: 100%;
    }
`;

const PasswordChangeReseller = () => {
    const { dispatch, state } = useStore();
    const token = LocalStorage().get("auth");
    const auth = LocalStorage().get(`${token.access_token}`);
    const [status, setStatus] = React.useState(true);
    const [message, setMessage] = React.useState("");
    const [showMessage, setShowMessage] = React.useState(false);
    const [isActive, setActive] = React.useState(false);
    const [validationClick, setValidationClick] = React.useState(true);
    const [validateForm, setValidateForm] = React.useState(true);
    const [formInformation, setFormInformation] = React.useState([
        {
            type: "password",
            action: "password",
            required: true,
            name: "password",
            label: "Kata Sandi Lama",
            col: {
                xl: 4,
                lg: 4,
                md: 6,
                sm: 6,
                xs: 12,
            },
            valid: "Kata sandi lama tidak boleh kosong",
        },
        {
            type: "password",
            action: "confirmPassword",
            block: false,
            required: true,
            name: "new_password",
            label: "Kata Sandi Baru",
            label2: "Konfirmasi Kata Sandi Baru",
            col: 8,
            valid: "Kata Sandi Baru tidak boleh kosong",
            valid2: "Konfirmasi kata sandi baru tidak boleh kosong",
        },
    ]);
    const handleChangePassword = () => {
        let isError = false;
        for (let key in formInformation) {
            if (!formInformation[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setActive(true);
            let data = {
                password: formInformation[0].value,
                new_password: formInformation[1].value,
            };
            service.updatePasswordReseller({ dispatch, data });
        }
    };
    React.useEffect(() => {
        if (state?.user?.passwordChange?.isSuccess) {
            setActive(false);
            setMessage("Kata Sandi telah di update");
            setStatus(state.user.passwordChange.isSuccess);
            service.passwordClear({ dispatch });
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
        }
        if (state?.user?.passwordChange?.isError) {
            setActive(false);
            setMessage(state.user.passwordChange.data.message);
            setStatus(state.user.passwordChange.isSuccess);
            service.passwordClear({ dispatch });
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
        }
    }, [state, auth, token, dispatch]);
    return (
        <Style>
            <GrowingLoading active={isActive} />
            <CardBody>
                <Tab />
                <hr />
                <Form
                    setForm={setFormInformation}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={formInformation}
                />
                <div className="text-right">
                    <Button color="primary" onClick={handleChangePassword}>
                        Simpan
                    </Button>
                </div>
            </CardBody>
            <MessageState
                status={status}
                message={message}
                isShow={showMessage}
            />
        </Style>
    );
};

export default PasswordChangeReseller;
