/* eslint-disable */
import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
// import Slider from "react-slick";
import service from "./index.service";
// import { Link } from "react-router-dom";
import Select from "react-select";
import { useStore } from "../../../Reducers";
import { Col, Container, Input, Row } from "reactstrap";
import CardNew from "../../../Components/Cards/CardNew";
import SkeletonLoading from "../../../Components/Loading/Skeleton";
import Button from "../../../Components/Particle/Button";
import NotFoundProduct from "./NotFound";
import HeaderMobile from "../../../Components/Header/HeaderMobile";
import FilterMobile from "../../../Components/FilterProduct/FilterMobile";
import FilterCategory from "../../../Components/FilterProduct/FilterCategory";

const Style = styled.div`
    .banner {
        margin-bottom: 35px;
    }
    .image-banner {
        border-radius: 20px;
    }
    .section-product {
        margin-bottom: 30px;
    }
`;

const DEFAULT_LIMIT_DATA = 16;
const DEFAULT_LIMIT_DATA_RATING = 8;
const DEFAULT_LIMIT_CATEGORY = 4;
const SearchPage = (props) => {
    const { dispatch, state } = useStore();
    // const [banner, setBanner] = React.useState(false);
    const [paramsFilters, setParamsFilters] = React.useState(
        props.location.search.replace(/[^a-zA-Z ]/g, "")
    );

    const history = useHistory();
    const [isFilterMobile, setFilterMobile] = React.useState(false);
    const [isFilterCategory, setFilterCategory] = React.useState(false);
    const [isFirstGet, setFirstGet] = React.useState(true);
    const [isDataSuccess, setDataSuccess] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [ratingProduct, setRatingProduct] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [totalElements, setTotalElements] = React.useState(0);
    const [totalElementsRating, setTotalElementsRating] = React.useState(0);
    const [paramsProduct, setParamsProduct] = React.useState({
        filters: `[["product.name", "like", "${paramsFilters}"]]`,
        size: DEFAULT_LIMIT_DATA,
        sort: "",
    });
    const [categoryList, setCategoryList] = React.useState([]);
    const [paramsCategory, setParamsCategory] = React.useState({
        page: 0,
        size: DEFAULT_LIMIT_CATEGORY,
    });
    const [params, setParams] = React.useState({
        page: 0,
        size: DEFAULT_LIMIT_DATA_RATING,
    });
    const [sortRating, setSortRating] = React.useState("");
    const [filterActive, setFilterActive] = React.useState("");
    const [onSortFilter, setSortFilter] = React.useState([]);
    const [categoryFilters, setCategoryFilters] = React.useState([]);
    const showFilterMobile = () => {
        setFilterMobile(!isFilterMobile);
    };
    const [otherFilter, setOtherFilter] = React.useState("");
    const [otherFilterValue, setOtherFilterValue] = React.useState("");
    const sort = [
        {
            label: "Terlaris",
            value: "product.rating",
        },
        {
            label: "Harga Tertinggi",
            value: "product.price",
        },
        {
            label: "Harga Terendah",
            value: "-product.price",
        },
        {
            label: "Ulasan",
            value: "product.rate",
        },
    ];
    const showAllCategoryFilter = () => {
        if (!isFilterCategory) {
            service.category({
                dispatch,
                params: {
                    page: 0,
                    size: 100,
                },
            });
        } else {
            service.category({
                dispatch,
                params: {
                    page: 0,
                    size: DEFAULT_LIMIT_CATEGORY,
                },
            });
        }
        setFilterMobile(!isFilterMobile);
        setFilterCategory(!isFilterCategory);
    };
    const handleLoadDataRating = () => {
        setDataSuccess(true);
        setLoading(true);
        getDataRating({
            page: params.page,
            size: params.size + DEFAULT_LIMIT_DATA_RATING,
        });
    };
    const getDataRating = (params) => {
        service.ratingProduct({ dispatch, params });
        setParams(params);
    };
    const getData = (params) => {
        service.searchProduct({ dispatch, params: params });
        setParamsProduct(params);
    };
    const onGetValue = (item) => {
        // service.getProduct({ dispatch, slug: item.id });
        history.push(`/product/${item.code}`);
    };
    const handleLoadData = () => {
        let nextSize = paramsProduct.size + DEFAULT_LIMIT_DATA;
        setLoading(true);
        getData({
            filters: `[["product.name","=","${paramsFilters}"]]`,
            size: nextSize,
            sort: paramsProduct.sort,
        });
    };
    const handleGetProduct = (item) => {
        history.push(`/product/${item.code}`);
        // service.getProduct({ dispatch, slug: item.id });
    };
    const onSortRating = (rating) => {
        if (sortRating === rating) {
            setSortRating("");
        } else {
            setSortRating(rating);
        }
    };
    const onChangeFilter = (value) => {
        setFilterActive(value === filterActive ? "" : value);
    };
    const onChangeFilterFront = (value) => {
        console.log(value.value);
        setFilterActive(value.value);
        getData({
            filters: paramsProduct.filters,
            size: 16,
            sort: value.value,
        });
    };
    const onOtherFilter = (value) => {
        if (value === otherFilter) {
            setOtherFilter("");
            setOtherFilterValue("");
        } else {
            setOtherFilter(value);
            setOtherFilterValue(
                value === "stock"
                    ? `["product.total_stock_product","!=","0"]`
                    : `["product.pre_order","=","true"]`
            );
        }
    };
    const onRemoveFilter = () => {
        setOtherFilter("");
        setFilterActive("");
        setSortRating("");
        setSortFilter([]);
        setCategoryFilters([]);
    };
    const onFilterProduct = () => {
        let filters = `[["product.name","like","${paramsFilters}"]${
            onSortFilter.length > 0 ? `,["and"],${onSortFilter}` : ""
        }${otherFilter !== "" ? `,[and],${otherFilterValue}` : ""}${
            sortRating !== ""
                ? `,[and],["product.rating","=","${sortRating}"]`
                : ""
        }]`;
        getData({
            filters: filters,
            size: 16,
            sort: filterActive,
        });
    };
    React.useEffect(() => {
        if (isFirstGet) {
            service.searchProduct({ dispatch, params: paramsProduct });
            setParamsProduct(paramsProduct);
            service.category({ dispatch, params: paramsCategory });
            setParamsCategory(paramsCategory);
            setFirstGet(false);
            setParamsFilters(paramsFilters);
        }
    }, [dispatch, isFirstGet, paramsProduct, paramsFilters]);
    React.useEffect(() => {
        if (
            paramsFilters !== props.location.search.replace(/[^a-zA-Z ]/g, "")
        ) {
            let params = props.location.search.replace(/[^a-zA-Z ]/g, "");
            setParamsProduct({
                filters: `[["product.name","like","${params}"]]`,
                size: DEFAULT_LIMIT_DATA,
                sort: paramsProduct.sort,
            });
            setDataSuccess(false);
            setFirstGet(true);
            setParamsFilters(params);
        }
    }, [paramsFilters, props.location.search]);
    React.useEffect(() => {
        if (state?.product?.search?.isSuccess) {
            setDataSuccess(true);
            setData(state.product.search.data.content);
            setLoading(false);
            setFilterMobile(false);
            setTotalElements(state.product.search.data.total_elements);
            if (state.product.search.data.content.length < 1) {
                getDataRating(params);
                service.searchProductClear({ dispatch });
            }
        }
        if (state?.user?.ratingProduct?.isSuccess) {
            setLoading(false);
            setDataSuccess(true);
            setRatingProduct(state.user.ratingProduct.data.content);
            setTotalElementsRating(
                state.user.ratingProduct.data.total_elements
            );
        }
        if (state?.category?.category?.isSuccess) {
            setCategoryList(state.category.category.data.content);
        }
    }, [state]);
    return (
        <Style>
            <HeaderMobile
                openFilter={showFilterMobile}
                title={"Hasil Pencarian"}
            />

            <Container fluid="md">
                <div className="mt-3 mb-3">
                    <Select
                        className="label-country"
                        onChange={(e) => onChangeFilterFront(e)}
                        placeholder="Pilih"
                        options={sort}
                        // value={filterActive}
                    />
                </div>
                <section className="section-product">
                    {data.length > 0 ? (
                        <Row>
                            {data.map((item, key) => {
                                return (
                                    <Col xl={3} md={3} xs={6} key={key}>
                                        <CardNew
                                            onGetValue={onGetValue}
                                            item={item}
                                            image={
                                                item.product.product_image[0]
                                                    ?.img_url
                                            }
                                            alt={
                                                item.product.product_image[0]
                                                    ?.name
                                            }
                                            name={item.product.name}
                                            rating={item.product.rating}
                                            price={item.product.price}
                                            city={item.store.city}
                                            stock={
                                                item.product.total_stock_product
                                            }
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    ) : isDataSuccess ? (
                        <NotFoundProduct
                            isLoading={isLoading}
                            onGetValue={handleGetProduct}
                            title={"Produk Terlaris"}
                            handleLoadData={handleLoadDataRating}
                            data={ratingProduct}
                            dataShow={8}
                            params={params}
                            totalElements={totalElementsRating}
                        />
                    ) : (
                        <Row>
                            {[...Array(4)].map((item, key) => {
                                return (
                                    <Col xl={3} md={3} key={key}>
                                        <SkeletonLoading
                                            width="100%"
                                            height="250px"
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    )}
                    {isLoading && (
                        <Row>
                            {[...Array(4)].map((item, key) => {
                                return (
                                    <Col xl={3} md={3} xs={6} key={key}>
                                        <SkeletonLoading
                                            width="100%"
                                            height="250px"
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    )}
                </section>
                <div className="text-center">
                    {paramsProduct.size < totalElements && (
                        <Button
                            onClick={handleLoadData}
                            color="primary"
                            style={{ paddingRight: 30, paddingLeft: 30 }}
                        >
                            Lebih Banyak
                        </Button>
                    )}
                </div>
            </Container>
            <FilterMobile
                onFilterProduct={onFilterProduct}
                onRemoveFilter={onRemoveFilter}
                onSortRating={onSortRating}
                sortRating={sortRating}
                setCategoryFilters={setCategoryFilters}
                onSortFilter={onSortFilter}
                setSortFilter={setSortFilter}
                categoryFilters={categoryFilters}
                filterActive={filterActive}
                onChangeFilter={onChangeFilter}
                categoryList={categoryList}
                showAllCategoryFilter={showAllCategoryFilter}
                onClose={showFilterMobile}
                activeFilter={isFilterMobile}
                onOtherFilter={onOtherFilter}
                otherFilter={otherFilter}
            />
            <FilterCategory
                setCategoryFilters={setCategoryFilters}
                onSortFilter={onSortFilter}
                setSortFilter={setSortFilter}
                categoryFilters={categoryFilters}
                categoryList={categoryList}
                showAllCategoryFilter={showAllCategoryFilter}
                activeFilter={isFilterCategory}
            />
        </Style>
    );
};
export default SearchPage;
