import React from "react";
import { Col, Container, Row } from "reactstrap";
import Skeleton from "../../../Components/Loading/Skeleton";
import styled from "styled-components";
import CardArticle from "../../../Components/Cards/CardArticle";
import TabMenu from "../../../Components/TabMenu";
import { Link } from "react-router-dom";
import service from "./index.service";
import { useStore } from "../../../Reducers";
import { color } from "../../../Utils/Variable";

const Style = styled.div`
    padding: 50px 0;
    .blog {
        &-time {
            color: #aaa;
            font-size: 12px;
            display: block;
            margin-bottom: 10px;
        }
        &-title {
            margin-bottom: 10px;
        }
        &-desc {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            / autoprefixer: off /
            -webkit-box-orient: vertical;
            / autoprefixer: on /
            white-space: normal;
            text-overflow: ellipsis;
            line-clamp: 4;
            overflow: hidden;
            /* display: block; */
            margin-bottom: 30px;
        }
        &-card {
            &:hover {
                text-decoration: unset;
                color: #4a4a4a;
            }
        }
    }
    .btn-blog {
        &:hover {
            color: ${color.primary};
        }
    }
`;
const Blog = () => {
    const { dispatch, state } = useStore();
    const month = [
        "Januari",
        "Febuari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
    ];
    const [isFirstGet, setFirstGet] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [urlTitle, setUrlTitle] = React.useState("");
    React.useEffect(() => {
        if (isFirstGet) {
            service.blogList({ dispatch });
            setFirstGet(false);
        }
    }, [isFirstGet, dispatch]);
    React.useEffect(() => {
        if (state?.user?.blogList?.isSuccess) {
            setData(state.user.blogList.data.content);
        }
    }, [state]);
    React.useEffect(() => {
        if (data.length > 0) {
            let replaceText = data[data.length - 1].title.replace(/ /g, "-");
            setUrlTitle(
                `/blog/${data[data.length - 1].id}/${replaceText.replace(
                    "/",
                    "-atau-"
                )}`
            );
        }
    }, [data]);
    return (
        <Style id="blog">
            <Container fluid="md">
                <div className="mb-5">
                    <Row className="align-items-center">
                        {data ? (
                            data.length > 0 && (
                                <React.Fragment>
                                    <Col xl="6" lg="6" md="6" sm="12">
                                        <img
                                            src={
                                                data[data.length - 1]
                                                    .article_image[0].img_url
                                            }
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </Col>
                                    <Col xl="6" lg="6" md="6" sm="12">
                                        <time className="blog-time">
                                            {String(
                                                new Date(
                                                    data[
                                                        data.length - 1
                                                    ].created
                                                ).getDate()
                                            ).padStart(2, "0")}{" "}
                                            {
                                                month[
                                                    new Date(
                                                        data[
                                                            data.length - 1
                                                        ].created
                                                    ).getMonth()
                                                ]
                                            }{" "}
                                            {new Date(
                                                data[data.length - 1].created
                                            ).getFullYear()}
                                        </time>
                                        <div>
                                            <h4>
                                                {data[data.length - 1].title}
                                            </h4>
                                            <div
                                                className="blog-desc"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        data[data.length - 1]
                                                            .content,
                                                }}
                                            />
                                        </div>
                                        <div className="text-right">
                                            <Link
                                                to={urlTitle}
                                                className="btn btn-blog"
                                            >
                                                Baca Selengkapnya
                                            </Link>
                                        </div>
                                    </Col>
                                </React.Fragment>
                            )
                        ) : (
                            <React.Fragment>
                                <Col xl="6" lg="6" md="6" sm="12">
                                    <Skeleton
                                        width="100%"
                                        height="300px"
                                        style={{ marginBottom: 10 }}
                                    />
                                </Col>
                                <Col xl="6" lg="6" md="6" sm="12">
                                    <Skeleton
                                        width="105px"
                                        height="15px"
                                        style={{ marginBottom: 10 }}
                                    />
                                    <div>
                                        <Skeleton
                                            width="100%"
                                            height="45px"
                                            style={{ marginBottom: 10 }}
                                        />
                                        <Skeleton
                                            width="100%"
                                            height="95px"
                                            style={{ marginBottom: 10 }}
                                        />
                                    </div>
                                </Col>
                            </React.Fragment>
                        )}
                    </Row>
                </div>
                <div>
                    <TabMenu
                        color={{
                            text: "#aaa",
                            background: "#fff",
                            activeColor: "#000",
                            activeBackground: "#000",
                        }}
                        data={data
                            .reduce((acc, current) => {
                                const x = acc.find(
                                    (filter) =>
                                        filter.category.name ===
                                        current.category.name
                                );
                                if (!x) {
                                    return acc.concat([current]);
                                } else {
                                    return acc;
                                }
                            }, [])
                            .map((value, key) => {
                                return {
                                    title: value.category.name,
                                    key: key,
                                    render: () => {
                                        return (
                                            <Row>
                                                {data.length > 0 &&
                                                    data
                                                        .filter(
                                                            (item) =>
                                                                item.category
                                                                    .name ===
                                                                value.category
                                                                    .name
                                                        )
                                                        .map((item, key) => {
                                                            return (
                                                                <Col
                                                                    xl="3"
                                                                    lg="3"
                                                                    md="4"
                                                                    sm="6"
                                                                    xs="12"
                                                                    key={key}
                                                                >
                                                                    <CardArticle
                                                                        data={
                                                                            item
                                                                        }
                                                                    />
                                                                </Col>
                                                            );
                                                        })}
                                            </Row>
                                        );
                                    },
                                };
                            })}
                    />
                </div>
            </Container>
        </Style>
    );
};
export default Blog;
