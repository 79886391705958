import React from "react";
import { Link } from "react-router-dom";
import Style from "../index.style";
import Faq from "../../../../Components/Assets/v2/faq.png";

const SectionFAQ = () => {
  
  return (
    <Style>
        <div className="container">
            <div className="row">
                <div className="col-sm-6 col-title">
                    <ul>
                        <li>Pertanyaan Umum <br/> Tentang Lakugan</li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-5 faq-mobile-image">
                    <img src={Faq} alt="" />
                </div>
                <div className="col-sm-7 faq-mobile-text">
                    <div className="col-faq">
                        <div className="wrapper-faq">
                            <h3>Kenapa harus di Lakugan?</h3>
                            <p>Lakugan memberikan kesempatan kepada masyarakat untuk bisa mendapatkan uang atau penghasilan tambahan tanpa modal cukup dengan 3 langkah mudah: klik, pilih, dan share link.</p>
                        </div>
                        <div className="wrapper-faq">
                            <h3>Bagaimana cara mulai berjualan di Lakugan?</h3>
                            <p>Daftar melalui website www.lakugan.com lalu masuk ke menu login reseller.</p>
                        </div>
                        <div className="wrapper-faq">
                            <h3>Berapa modal yang diperlukan untuk berjualan di Lakugan?</h3>
                            <p>Anda dapat bergabung secara gratis tanpa modal, cukup siapkan market calon pelanggan anda.</p>
                        </div>
                        <div className="wrapper-faq">
                            <h3>Bagaimana cara mulai berjualan di Lakugan?</h3>
                            <p>Daftar melalui website www.lakugan.com lalu masuk ke menu login reseller.</p>
                        </div>
                    </div>
                    <div className="text-center">
                        <Link
                            to={`/faq`}
                            className="btn btn-load-faq"
                        >
                            Lihat Lebih Banyak
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </Style>
  );
};
export default SectionFAQ;
