import styled from "styled-components";
import { color } from "../../../Utils/Variable";
export const Style = styled.div`
    .banner {
        margin-bottom: 35px;
        @media (max-width: 550px) {
            margin-top: -10px;
        }
        .slick-prev,
        .slick-next {
            top: calc(100% - 50%);
            font-size: 20px;
            color: #000000;
            width: max-content;
            height: max-content;
            padding: 6px 10px;
            box-shadow: -4px 4px 16px rgba(0, 0, 0, 0.08);
            background: #fff;
            border-radius: 50%;
            @media (max-width: 550px) {
                font-size: 14px;
                padding: 6px 8px;
            }
            &::before {
                display: none;
            }
        }
        .slick-prev {
            left: -5px;
            z-index: 3;
            @media (max-width: 550px) {
                left: 5px;
            }
        }
        .slick-next {
            right: -7px;
            @media (max-width: 550px) {
                right: 5px;
            }
        }
        .fa-chevron-left,
        .fa-chevron-right {
            color: ${color.primary};
        }
        .slick-dots {
            display: block;
            position: absolute;
            bottom: 25px;
            right: 60px;
            width: max-content;
            @media (max-width: 550px) {
                bottom: 20px;
                right: 20px;
            }
            li {
                @media (max-width: 550px) {
                    width: 5px;
                    height: 5px;
                }
            }
        }
        .slick-dots li button:before {
            font-size: 20px;
            @media (max-width: 550px) {
                font-size: 10px;
            }
        }
        .slick-dots li.slick-active button:before {
            color: #fff;
        }
        &-slider,
        .container {
            @media (max-width: 550px) {
                padding: 0;
            }
        }
        .col {
            @media (max-width: 550px) {
                padding: 0;
            }
        }
    }
    .image-banner {
        border-radius: 20px;
        @media (max-width: 550px) {
            border-radius: 0px;
        }
    }
    .section-product {
        margin-bottom: 30px;
    }
`;
