import { servicesAction } from "../../Reducers";

const service = {
    searchProduct: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/product/customerAllNoSort",
            method: "GET",
            params: params,
            qs: true,
            reducer: "service",
            group: "product",
            key: "search",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
            },
        });
    },
    product: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/product/supplier",
            params: params,
            qs: true,
            method: "GET",
            reducer: "service",
            group: "product",
            token: true,
            key: "list",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Bad Request",
                },
            },
        });
    },
    getProduct: ({ dispatch, slug }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/product/list/${slug}`,
            method: "GET",
            reducer: "service",
            group: "user",
            key: "product",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    productList: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/product/reseller",
            method: "GET",
            token: true,
            reducer: "service",
            group: "product",
            key: "list",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
};
export default service;
