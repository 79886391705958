/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Col, Row, FormGroup, Alert, Spinner, Modal, ModalBody, ModalHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from "reactstrap";
import LocalStorage from "../../../../Utils/LocalStorage";
import Header from "../Header";
// import Delivery from "../Detail/Delivery";
import Select from "react-select";
import FormText from "../../../../Components/Particle/Form/FormText";
import styled from "styled-components";
import service from "../index.service";
import { PaymentStyle, Container, SellerNoteStyle, ModalInfo, CustomStyles, ModalForm, LoadingText } from "../index.style";
import { useHistory } from "react-router-dom";
import CardBill from "./CardBill";
import PaymentMethod from "./PaymentMethod";
import ProductOrder from "./ProductOrder";
import PaymentForm from "./index.form";
import { useStore } from "../../../../Reducers";
import GrowingLoading from "../../../../Components/Loading/GrowingLoading";
// import SliderProduct from "../SliderProduct";
import ButtonNavigation from "./ButtonNavigation";
import { formatMoney } from "../../../../Utils/PriceConverter";
// import { useGeoLocation } from "../../../../CustomHooks";
import JSON_to_URLEncoded from "../../../../Utils/JSON-URLEncode";
import Button from "../../../../Components/Particle/Button";
import NoAddressImage from "../../../../Components/Assets/Images/no-address.png";
import DeliveryAddressImage from "../../../../Components/Assets/Images/delivery-address.png";
import DropdownImg from "../../../../Components/Assets/icon/icon-dropdown.svg";
import EditShipping from "../../../../Components/Assets/icon/edit-shipping.png";
import Style from "./index.style";
import Footer from "../../../../Components/Footer";
import CheckMobile from "../../../../Utils/CheckMobile";
import ModalDeliveryMobile from "./CustomModalMobile/ModalDelivery";
import ModalPaymentMobile from "./CustomModalMobile/ModalPayment";
import ArrowLeft from "../../../../Components/Assets/icon/arrow-left.svg";
import CustomModal from "react-modal";
import IconBack from "../../../../Components/Assets/icon/arrow-left.svg";
// COURIER
import Tiki from "../../../../Components/Assets/icon/tiki.png";
import SiCepat from "../../../../Components/Assets/icon/sicepat.png";
import Anteraja from "../../../../Components/Assets/icon/anteraja.png";
import JNE from "../../../../Components/Assets/icon/jne.png";
import LionParcel from "../../../../Components/Assets/icon/lion-parcel.png";
import Ninja from "../../../../Components/Assets/icon/ninja.png";
import Wahana from "../../../../Components/Assets/icon/wahana.png";
import RPX from "../../../../Components/Assets/icon/rpx.png";
import Grab from "../../../../Components/Assets/icon/grab.png";
import AlfaTrex from "../../../../Components/Assets/icon/alfatrex.png";

const StylePayment = styled.div`
    margin-bottom: 25px;
    display: none;

    .fa-truck {
        font-size: 20px;
        margin-left: 10px;
    }
    .list-delivery {
        position: absolute;
        left: -1px;
        right: -1px;
        background: #fff;
        z-index: 2;
        border: 1px solid #ddd;
        border-bottom: none;
        top: 44px;
        transition: all 0.25s ease;
        display: block;
        &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px;
            border-bottom: 1px solid #ddd;
            transition: all 0.25s ease;
            &:hover {
                outline: -webkit-focus-ring-color auto 1px;
            }
        }
        &__check {
            display: block;
            .options-delivery {
                margin-bottom: 0;
                font-weight: bold;
            }
            &-btn {
                cursor: pointer;
                font-weight: 500;
                transition: all 0.25s ease;
                &:hover {
                    opacity: 0.7;
                    transition: all 0.25s ease;
                }
            }
        }
    }
    .fa-angle-down {
        transition: all 0.25s ease;
    }
    .shipper-type {
        padding: 10px 20px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .img-shipper {
            width: 100px;
            margin-right: 25px;
        }
    }
    @media (max-width: 767px) {
        display: block;
    }
`;

const Payment = () => {
    const dataOrder = LocalStorage().get("_ORDER_ITEM_LAKUGAN");
    const voucherUsed = LocalStorage().get("VOUCHER_USED");
    let slug = dataOrder?.item;
    const { dispatch, state } = useStore();
    const history = useHistory();
    const [voucher, setVoucher] = useState(false);
    const [digital, setDigital] = useState(false);
    const [voucherList, setVoucherList] = useState([]);
    const [priceInsurance, setPriceInsurance] = useState(false);
    const [getSpec, setGetSpec] = useState(dataOrder?.spec);
    const [shipperUsed, setShipperUsed] = useState(false);
    const [validationClick, setValidationClick] = useState(true);
    const [validateForm, setValidateForm] = useState(true);
    const [shipperList, setShipperList] = useState(false);
    const [mobilePayment, setMobilePayment] = useState(false);
    const [typeShipper, setTypeShipper] = useState("");
    const [courier, setCourier] = useState("");
    const [address, setAddress] = useState("");
    const [message, setMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isFirstGet, setFirstGet] = useState(true);
    const [isOpenItem, setIsOpenItem] = useState(true);
    const [productInfo, setProductInfo] = useState(false);
    const [paymentChannel, setPaymentChannel] = useState(false);
    const [productUpdate, setProductUpdate] = useState(false);
    const [city, setCity] = useState([]);
    const [filterSize, setFilterSize] = useState([]);
    const [filterColor, setFilterColor] = useState([]);
    const [size, setSize] = useState(dataOrder?.size);
    const [colorItem, setColorItem] = useState(dataOrder?.color);
    const [totalItem, setTotalItem] = useState(1);
    const [district, setDistrict] = useState([]);
    const [area, setArea] = useState([]);
    const [province, setProvince] = useState([]);
    const [valueProvince, setValueProvince] = useState(province[0]);
    const [valueCity, setValueCity] = useState(city[0]);
    const [valueArea, setValueArea] = useState(area[0]);
    const [postCode, setPostCode] = useState("");
    const [valueDistrict, setValueDistrict] = useState(district[0]);
    const [longitude, setLongitude] = useState("");
    const [latitude, setLatitude] = useState("");
    const [getLocation, setGetLocation] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalShowMobile, setModalShowMobile] = useState(false);
    const [isOpenCourier, setOpenCourier] = useState(false);
    const [checkCourier, setCheckCourier] = useState(false);
    const [selectCourier, setSelectCourier] = useState(false);
    const [courierRate, setCourierRate] = useState(0);
    const [courierEstimateTime, setCourierEstimateTime] = useState(null);
    const [courierImage, setCourierImage] = useState("");
    const [paymentShow, setPaymentShow] = useState(false);
    const [insuranceShow, setInsuranceShow] = useState(false);
    const [headerChecklist, setHeaderChecklist] = useState(false);
    const [headerTwo, setHeaderTwo] = useState(false);
    const [sellerNote, setSellerNote] = useState("");
    const [isPriceFooterMobilePayment] = useState(true);
    const [headerShow, setHeaderShow] = useState(true);
    const [deliveryMobile, setDeliveryMobile] = useState(false);
    const [paymentMobile, setPaymentMobile] = useState(false);
    const [biteshipList, setBiteshipList] = useState(false);
    const [biteshipUsed, setBiteshipUsed] = useState(false);
    const [typeBiteship, setTypeBiteship] = useState("");

    const [form, setForm] = React.useState([
        {
            type: "email",
            required: true,
            action: "email",
            placeholder: "Masukkan email kamu",
            name: "email",
            optional: "",
            label: "Email",
            extraLabel: "",
            col: 12,
            valid: "Email tidak boleh kosong",
            value: "",
            status: false,
        },
        {
            type: "text",
            required: true,
            action: "text",
            name: "username",
            placeholder: "Masukkan nama lengkap kamu",
            label: "Nama Lengkap",
            col: 6,
            valid: "Nama tidak boleh kosong",
            value: "",
            status: false,
            character: {
                min: {
                  length: 3,
                  valid: "Minimal 3 karakter",
                },
                max: {
                  length: 60,
                  valid: "maksimal 60 karakter",
                },
            }
        },
        {
            type: "phone",
            required: true,
            action: "phone",
            name: "phone",
            label: "Nomor Telepon",
            placeholder: "Masukkan nomor telepon kamu",
            col: 6,
            valid: "Nomor telepon tidak boleh kosong",
            value: "",
            status: false,
        },
    ]);
const [formAddress, setFormAddress] = useState({
        type: "textarea",
        required: true,
        action: "textarea",
        rows: 5,
        name: "address",
        label: "Alamat Pengiriman",
        valid: "Alamat tidak boleh kosong",
        value: address,
        placeholder: "Masukkan alamat lengkap kamu",
        status:
            address === "" ||
                address === null
                ? false
                : true,
        character: {
            min: {
              length: 7,
              valid: "Minimal 7 karakter",
            },
            max: {
              length: 150,
              valid: "Maximal 150 karakter",
            },
        }
})

const onGetValueAddress = (obj) => {
    setAddress(obj.value);
    formAddress.value = obj.value;
    if (obj.value === "" || obj.value.length < formAddress.character.min.length) {
        formAddress.status = false;
    } else {
        formAddress.status = true;
    }
    setFormAddress(formAddress);
}

    const handleGetCity = (value) => {
        setPaymentChannel(false);
        // setShipperUsed(false);
        // setShipperList(false);
        setBiteshipUsed(false);
        setBiteshipList(false);
        setValueProvince(value);
        setVoucher(false);
        setDistrict([]);
        setValueCity([]);
        setValueDistrict([]);
        setArea([]);
        setValueArea([]);
        setMessage(false);
        setPostCode("");
        let params = value.value;
        service.getCity({ dispatch, params });
        service.getClearDistrict({ dispatch });
        service.getClearArea({ dispatch });
    };
    const handleGetDistrict = (value) => {
        setPaymentChannel(false);
        // setShipperUsed(false);
        // setShipperList(false);
        setBiteshipUsed(false);
        setBiteshipList(false);
        setVoucher(false);
        service.getClearArea({ dispatch });
        setValueDistrict([]);
        setValueArea([]);
        setArea([]);
        setPostCode("");
        setMessage(false);
        let params = value.value;
        setValueCity({ label: value.label, value: value.value });
        service.getDistrict({ dispatch, params });
    };
    const handleGetValueDistrict = (value) => {
        setPaymentChannel(false);
        // setShipperUsed(false);
        setBiteshipUsed(false);
        setVoucher(false);
        setMessage(false);
        // setShipperList(false);
        setBiteshipList(false);
        setValueArea([]);
        setPostCode("");
        setValueDistrict({ label: value.label, value: value.value });
        let params = value.value;
        service.getArea({ dispatch, params });
    };
    const handleGetValueArea = (value) => {
        setPaymentChannel(false);
        // setShipperUsed(false);
        setBiteshipUsed(false);
        setMessage(false);
        setVoucher(false);
        // setShipperList(false);
        setBiteshipList(false);
        setValueArea({ label: value.label, value: value.value });
        setPostCode(value.postcode);
    };
    const handleGetCourier = (item) => {
        // setCourierRate(item.finalRate);
        setCourierRate(item.price);
        // setShipperUsed(item)
        setBiteshipUsed(item)
        // setCourierImage(item.logo_url);
        setCourierImage(item);
        setCourierEstimateTime(item);
        setSelectCourier(true);
        setPriceInsurance(item);
        setTypeShipper(item);
        if (item.compulsory_insurance === 1) {
            setPriceInsurance(item);
        }
    }
    const handleOrder = () => {
        setCheckCourier(true);
        setPaymentShow(true);
        setHeaderTwo(true);
        setHeaderChecklist(true);
    }
    const handleStepBack = () => {
        setCheckCourier(false);
        setPaymentShow(false);
        // setSelectCourier(false);
        setPaymentChannel(false);
        setInsuranceShow(false);
        setHeaderTwo(false);
    }
    const onGetValue = (obj) => {
        setSellerNote(obj.value);
    };
    const onCheckValue = (e) => {
        setCourier("");
        setVoucher(false);
        setMessage(false);
        setSelectCourier(false);
        let checkingArea = [
            {
                name: "province",
                status: valueProvince === undefined ? false : true,
            },
            {
                name: "city",
                status:
                    valueCity === undefined || valueCity.length === 0
                        ? false
                        : true,
            },
            {
                name: "district",
                status:
                    valueDistrict === undefined || valueDistrict.length === 0
                        ? false
                        : true,
            },
            {
                name: "area",
                status:
                    valueArea === undefined || valueArea.length === 0
                        ? false
                        : true,
            },
            {
                name: "postcode",
                status: postCode === "" ? false : true,
            },
        ];
        if (productInfo && productInfo.product.supplier.is_open !== false) {
            let isError = false;
            let isErrorAddress = address === "" ? true : false;
            let isErrorArea = false;
            e.preventDefault();
            for (let key in form) {
                if (!form[key].status) {
                    isError = true;
                }
            }
            for (let key in checkingArea) {
                if (!checkingArea[key].status) {
                    isErrorArea = true;
                }
            }
            setValidationClick(isError);
            setValidateForm(false);
            if (!latitude && longitude) {
                setGetLocation(true);
                return;
            }
            if (!isError && !isErrorArea && !isErrorAddress) {
                setLoading(true);
                // setTypeShipper("");
                setTypeBiteship("");
                setCourier("");
                let id = valueArea.value;
                let product = dataOrder?.product_id;
                let qty = dataOrder?.total;
                let coords = `${latitude},${longitude}`;
                if (productInfo.product.category.name === "Voucher Digital") {
                    setDigital(true);
                    setLoading(false);
                    setMobilePayment(true);
                    setModalShow(false);
                    setSelectCourier(true);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 500)
                    setModalShow(false);
                }
            }
        }
    };
    const handleCheckInsurance = (e, data) => {
        let checked = e.target.checked;
        if (checked) {
            setPriceInsurance(data);
        } else {
            setPriceInsurance(false);
        }
    };
    const handleOrderPayment = (e) => {
        setMessage(false);
        let isError = false;
        let isErrorAddress = false;
        e.preventDefault();
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError && !isErrorAddress) {
            setLoading(true);
            let params = {
                shipping_fee:
                    productInfo.product.category.name === "Voucher Digital"
                        ? 0
                        : typeShipper.price,
                insurance_fee: priceInsurance && insuranceShow === true
                    ? priceInsurance.insuranceRate
                    : 0,
            };
            if (voucher) {
                let data = {
                    customer_detail: {
                        full_name: form[1].value,
                        address: address,
                        email: form[0].value,
                        phone_number: form[2].value,
                        address_direction: address,
                        sub_district_id: valueArea.value,
                        latitude: latitude,
                        longtitude: longitude,
                        noted_customer: sellerNote,
                        city: valueCity.value,
                        districts: valueDistrict.value,
                        zip_code: postCode,
                    },
                    shipper: {
                        rate_id:
                            productInfo.product.category.name ===
                                "Voucher Digital"
                                ? 0
                                : typeShipper.rate_id,
                        rate_id: 1,
                        rate_name:
                            productInfo.product.category.name ===
                                "Voucher Digital"
                                ? 0
                                : typeShipper.courier_name,
                    },
                    voucher: {
                        id: voucher.id,
                    },
                    product: {
                        id: productInfo.product.id,
                    },
                    detail: [
                        {
                            amount: dataOrder?.total,
                            spec: {
                                id: getSpec[0]?.id,
                            },
                        },
                    ],
                    payment_channel: {
                        id: paymentChannel.id,
                        name: paymentChannel.name,
                        type: paymentChannel.type,
                        publish: paymentChannel.publish,
                        fee: paymentChannel.fee,
                        percentages: paymentChannel.percentages === null || paymentChannel.percentages === undefined ? 0 : paymentChannel.percentages,
                        vendor: paymentChannel.vendor,
                        channel: paymentChannel.channel,
                    },
                    reseller: {
                        id: productInfo.reseller.id,
                    },
                };
                service.buyProduct({ dispatch, data, params });
            } else {
                let data = {
                    customer_detail: {
                        full_name: form[1].value,
                        address: address,
                        email: form[0].value,
                        phone_number: form[2].value,
                        address_direction: address,
                        sub_district_id: valueArea.value,
                        latitude: latitude,
                        longtitude: longitude,
                        noted_customer: sellerNote,
                        city: valueCity.value,
                        districts: valueDistrict.value,
                        zip_code: postCode,
                    },
                    shipper: {
                        // rate_id:
                        //     productInfo.product.category.name ===
                        //         "Voucher Digital"
                        //         ? 0
                        //         : typeShipper.rate_id,
                        rate_id: 1,
                        rate_name:
                            productInfo.product.category.name ===
                                "Voucher Digital"
                                ? 0
                                : typeShipper.courier_name,
                    },

                    product: {
                        id: productInfo.product.id,
                    },
                    detail: [
                        {
                            amount: dataOrder?.total,
                            spec: {
                                id: getSpec[0]?.id,
                            },
                        },
                    ],
                    payment_channel: {
                        id: paymentChannel.id,
                        name: paymentChannel.name,
                        type: paymentChannel.type,
                        publish: paymentChannel.publish,
                        fee: paymentChannel.fee,
                        percentages: paymentChannel.percentages === null || paymentChannel.percentages === undefined ? 0 : paymentChannel.percentages,
                        vendor: paymentChannel.vendor,
                        channel: paymentChannel.channel,
                    },
                    reseller: {
                        id: productInfo.reseller.id,
                    },
                };
                service.buyProduct({ dispatch, data, params });
            }
        }
    };
    const handleShowModal = (e) => {
        setModalShow(!modalShow);
        if(modalShow == true) {
            onCheckValue(e);
        } else{
            setModalShow(true);
        }
    }
    const handleShowModalMobile = (e) => {
        setModalShowMobile(!modalShowMobile);
        setHeaderShow(!headerShow);
        if(modalShowMobile == true) {
            onCheckValue(e);
        } else{
            setModalShowMobile(true);
        }
    }
    const handleInsuranceChecklist = () => {
        setInsuranceShow(!insuranceShow);
    }
    React.useEffect(() => {
        if (typeShipper?.compulsory_insurance === 1) {
            setPriceInsurance(typeShipper);
        }
    }, [typeShipper]);
    React.useEffect(() => {
        if (isFirstGet) {
            let paramVoucher = {
                size: 10000,
            };
            service.voucherList({ dispatch, params: paramVoucher });
            service.getProduct({ dispatch, slug });
            service.getProvince({ dispatch });
            setFirstGet(false);
        }
    }, [isFirstGet]);
    React.useEffect(() => {
        if (state?.user?.product?.isSuccess) {
            setProductInfo(state.user.product.data);
            setProductUpdate(dataOrder);
            // form[0].extraLabel =
            //     state.user.product.data.product.category.name ===
            //         "Voucher Digital"
            //         ? "(Note: Voucher akan dikirimkan sesuai dengan email yang telah di isi)"
            //         : "";
            setForm(form);
        }
        if (state?.user?.product?.isError) {
            window.location = "/404";
        }
        if (state?.user?.buyProduct?.isSuccess) {
            setLoading(false);
            let transactionCode =
                state.user.buyProduct.data.transaction_code.split("/");
            setTypeShipper("");
            let params = {
                full_name: state.user.buyProduct.data.customer_detail.full_name,
                id: state.user.buyProduct.data.customer_detail.id,

                product_name: state.user.buyProduct.data.product.name,
                transaction_id: transactionCode[1],
                payment_channel:
                    state.user.buyProduct.data.payment_channel.name,
                shipper_name:
                    productInfo.product.category.name === "Voucher Digital"
                        ? "-"
                        : state.user.buyProduct.data.shipper.rate_name,
            };
            history.push(`/payment/transaction?${JSON_to_URLEncoded(params)}`);
            service.buyProductClear({ dispatch });
        }
        if (state?.user?.buyProduct?.isError) {
            setLoading(false);
            window.scroll(0, 0);
            setMessage(state.user.buyProduct.data);
        }
        if (state?.voucher?.voucherList?.isSuccess) {
            service.voucherListClear({ dispatch });
            setVoucherList(state.voucher.voucherList.data.content);
        }

        if (state?.location?.coords) {
            setLatitude(state?.location?.coords?.latitude);
            setLongitude(state?.location?.coords?.longitude);
            setGetLocation(false);
        }
    }, [state, dispatch, form]);
    // React.useEffect(() => {
    //     if (isFirstGet) {
    //         service.getProvince({ dispatch });
    //         setFirstGet(false);
    //     }
    // }, [isFirstGet, dispatch]);
    React.useEffect(() => {
        if (state?.user?.province?.isSuccess) {
            setProvince(
                state.user.province.data.content.map((item) => {
                    return {
                        ...{ label: "Pilih", value: 0 },
                        ...{ label: item.name, value: item.id },
                    };
                })
            );
        }
        if (state?.user?.city?.isSuccess) {
            setDistrict([]);
            setCity(
                state.user.city.data.content.map((item) => {
                    return {
                        ...{ label: "Pilih", value: 0 },
                        ...{ label: item.name, value: item.id },
                    };
                })
            );
        }
        if (state?.user?.district?.isSuccess) {
            setDistrict(
                state.user.district.data.content.map((item) => {
                    return {
                        ...{ label: "Pilih", value: 0 },
                        ...{ label: item.name, value: item.id },
                    };
                })
            );
        }
        if (state?.user?.subdistrict?.isSuccess) {
            setArea(
                state.user.subdistrict.data.content.map((item) => {
                    return {
                        ...{ label: "Pilih", value: 0 },
                        ...{
                            label: item.name,
                            value: item.id,
                            postcode: item.postal_code,
                        },
                    };
                })
            );
        }
        // if (state?.user?.shipper?.isSuccess) {
        //     setLoading(false);
        //     setIsOpenItem(true);
        //     setShipperList(state.user.shipper.data.data);
        //     service.getShipperClear({ dispatch });
        //     window.scroll(0, 0);
        // }
        // if (state?.user?.shipper?.isError) {
        //     setMessage(state.user.shipper.data);
        //     setLoading(false);
        //     service.getShipperClear({ dispatch });
        //     window.scroll(0, 0);
        // }
        if (state?.user?.biteship?.isSuccess) {
            setLoading(false);
            setIsOpenItem(true);
            setBiteshipList(state.user.biteship.data.pricing);
            window.scroll(0, 0);
        }
        if (state?.user?.biteship?.isError) {
            setMessage(state.user.biteship.data);
            setLoading(false);
            service.getBiteshipClear({ dispatch });
            window.scroll(0, 0);
        }
    }, [state]);
    const filterSpecProduct = () => {
        if (dataOrder?.color !== "" && dataOrder?.size === "") {
            setGetSpec(
                dataOrder?.spec.filter(
                    (item) => item.color === dataOrder?.color
                )
            );
        }
        if (dataOrder?.size !== "" && dataOrder?.color === "") {
            setGetSpec(
                dataOrder?.spec.filter((item) => item.size === dataOrder?.size)
            );
        }
        if (dataOrder?.size !== "" && dataOrder?.color !== "") {
            setGetSpec(
                dataOrder?.spec.filter(
                    (item) =>
                        item.color === dataOrder?.color &&
                        item.size === dataOrder?.size
                )
            );
        }
    };
    const handleReOrder = () => {
        setPaymentChannel(false);
        // setShipperUsed(false);
        // setShipperList(false);
        setBiteshipUsed(false);
        setShipperList(false);
    };

    const filterSpecProductCallback = React.useCallback(filterSpecProduct);
    React.useEffect(() => {
        filterSpecProductCallback();
    }, []);

    React.useEffect(()=>{
        // if(state?.location?.coords?.type === 200){ // Jika menggunakan api google map, bisa diaktifkan lagi
            if(postCode){
            
            // let id = valueArea.value;
            // let product = dataOrder?.product_id;
            // let qty = dataOrder?.total;
            // let coords = `${latitude},${longitude}`;
            // service.getShipper({ dispatch, id, product, qty, coords });

            let supplierPostalCode = productInfo.product.supplier.postal_code
            if(typeof supplierPostalCode === "string"){
                supplierPostalCode = parseInt(supplierPostalCode)
            }
            let data = {
                origin_postal_code: supplierPostalCode, //ini ambil dari product
                // destination_latitude: state?.location?.coords?.latitude,
                // destination_longitude: state?.location?.coords?.longitude,
                // destination_latitude: postCode,
                destination_postal_code: postCode,
                couriers: "anteraja,grab,jne,tiki",
                items: [{
                    name: productInfo.product.name,
                    description: productInfo.product.description,
                    value: productInfo.product.price,
                    length: dataOrder?.length,
                    width: dataOrder?.width,
                    height: dataOrder?.height,
                    weight: dataOrder?.weight,
                    quantity: dataOrder?.total
                }]
            }
            service.getCourierBiteship({ dispatch, data});
            // service.clearGeoLocation({ dispatch })
        }
    }, [postCode])

    // useGeoLocation(
    //     address,
    //     valueProvince,
    //     valueCity,
    //     valueDistrict,
    //     valueArea,
    //     postCode
    // );

    return (
        <Style>
            <React.Fragment>
                <GrowingLoading active={loading} />
                <Header history={slug} headerChecklist={headerChecklist} headerTwo={headerTwo} isShow={headerShow} />
                <PaymentStyle
                    mrg={productInfo?.product?.product_image?.length}
                    activeItem={isOpenItem}
                >
                    {productInfo && !productInfo.product.supplier.is_open && (
                        <div className="isClose">
                            <div>Toko ini sedang tidak beroprasi</div>
                        </div>
                    )}
                    <Container>
                        {message && (
                            <div className="mb-4">
                                <Alert color="danger">
                                    {message.message} ({message.error}{" "}
                                    {message.status})
                                </Alert>
                            </div>
                        )}
                        <div className="container-mb mx-0 mx-md-4 d-flex flex-sm-row flex-column">
                            <Col xl="8" className="px-0">
                            {paymentShow === true && <PaymentMethod 
                                                                mobilePayment={mobilePayment}
                                                                setMobilePayment={setMobilePayment}
                                                                setPaymentChannel={setPaymentChannel} />}
                            {checkCourier === false && <div className="address-wrapper">
                                <h3 className="customer-info">Informasi Pembeli</h3>
                                {form[1].value !== "" ? 
                                    CheckMobile() === true ?
                                        // Mobile View
                                        <div className="customer-address">
                                        <div className="customer-address-wrap">
                                            <p>{form[1].value}{" "}|{" "}{form[2].value}</p>
                                            <div onClick={handleShowModalMobile} className="customer-address-edit"><img src={EditShipping} alt=""/>Ubah</div>                                            
                                        </div>
                                            <div className="customer-address-info-wrapper">
                                                <p className="customer-address-info">{address}</p>
                                                <p className="customer-address-info" >{valueArea?.label}, {valueDistrict?.label}, {valueCity?.label}, {valueProvince?.label}</p>
                                                <p className="customer-address-info">{postCode}</p><br/>
                                                <p className="customer-address-info">{form[0].value}</p>
                                            </div>
                                        </div>
                                        :
                                        // Desktop View
                                        <div className="customer-address">
                                                <div className="customer-address-wrap">
                                                    <p>{form[1].value}</p>
                                                    <div onClick={handleShowModal} className="customer-address-edit"><img src={EditShipping} alt=""/>Ubah</div>
                                                </div>
                                            <div className="customer-address-info-wrapper">
                                                <p className="customer-address-info-head">{form[2].value}</p>
                                                <p className="customer-address-info">{form[0].value}</p>
                                                <p className="customer-address-info">{address} {valueArea?.label}</p>
                                                <p className="customer-address-info" style={{ textTransform: "uppercase" }}>{valueDistrict?.label}, {valueCity?.label}, {valueProvince?.label}</p>
                                                <p className="customer-address-info">{postCode}</p>
                                            </div>
                                        </div>
                                    :
                                    <div className="customer-info-no-address">
                                        <h5>Belum ada alamat pengiriman</h5>
                                        <img src={NoAddressImage} alt="" />
                                        {CheckMobile() === true ?
                                            <Button color="primary" onClick={handleShowModalMobile}>Isi Data</Button>
                                            :
                                            <Button color="primary" onClick={handleShowModal}>Isi Data</Button>
                                        }
                                    </div>
                                } 
                        {/* This Section Shipping */}
                        {CheckMobile() === true ? 
                            <ModalDeliveryMobile 
                                shipperList={shipperList}
                                biteshipList={biteshipList}
                                courier={courier}
                                selectCourier={selectCourier}
                                courierImage={courierImage}
                                courierEstimateTime={courierEstimateTime}
                                courierRate={courierRate}
                                handleGetCourier={handleGetCourier}
                                setCourierRate={setCourierRate}
                                setShipperUsed={setShipperUsed}
                                setBiteshipUsed={setBiteshipUsed}
                                setCourierImage={setCourierImage}
                                setCourierEstimateTime={setCourierEstimateTime}
                                setSelectCourier={setSelectCourier}
                                setPriceInsurance={setPriceInsurance}
                                setTypeShipper={setTypeShipper}
                                setHeaderShow={setHeaderShow}
                                headerShow={headerShow}
                                setDeliveryMobile={setDeliveryMobile}
                                />
                        :
                        <div>
                            {productInfo?.product?.category?.name !== "Voucher Digital" && <Dropdown
                                isOpen={isOpenCourier}
                                toggle={() => setOpenCourier(!isOpenCourier)}
                            >
                                <DropdownToggle style={{
                                    borderRadius: 20,
                                    color: "#000",
                                    background: "#fff",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    border: "1.5px solid #E8E8E8",
                                    margin: "32px 0 0 0",
                                    width: "100%",
                                }}>
                                    {selectCourier ? 
                                    <div className="dropdown-toggle">
                                        <div>
                                            {(
                                                () => {
                                                    if (courierImage.company == "jne") {
                                                        return (
                                                            <img src={JNE} alt="" style={{ width: 98 }}/>
                                                        )
                                                    } else if (courierImage.company == "tiki") {
                                                        return (
                                                            <img src={Tiki} alt="" style={{ width: 98 }}/>
                                                        )
                                                    } else if (courierImage.company == "sicepat") {
                                                        return (
                                                            <img src={SiCepat} alt="" style={{ width: 98 }}/>
                                                        )
                                                    } else if (courierImage.company == "anteraja") {
                                                        return (
                                                            <img src={Anteraja} alt="" style={{ width: 98 }}/>
                                                        )
                                                    } else if (courierImage.company == "lion") {
                                                        return (
                                                            <img src={LionParcel} alt="" style={{ width: 98 }}/>
                                                        )
                                                    } else if (courierImage.company == "wahana") {
                                                        return (
                                                            <img src={Wahana} alt="" style={{ width: 98 }}/>
                                                        )
                                                    } else if (courierImage.company == "ninja") {
                                                        return (
                                                            <img src={Ninja} alt="" style={{ width: 98 }}/>
                                                        )
                                                    } else if (courierImage.company == "rpx") {
                                                        return (
                                                            <img src={RPX} alt="" style={{ width: 98 }}/>
                                                        )
                                                    } else if (courierImage.company == "grab") {
                                                        return (
                                                            <img src={Grab} alt="" style={{ width: 98 }}/>
                                                        )
                                                    } else if (courierImage.company == "alfatrex") {
                                                        return (
                                                            <img src={AlfaTrex} alt="" style={{ width: 98 }}/>
                                                        )
                                                    }
                                                }
                                            )()}
                                        </div>
                                        {/* {courierEstimateTime !== null && <div>Estimasi {courierEstimateTime?.min_day}{courierEstimateTime?.min_day !== courierEstimateTime?.max_day ? `-${courierEstimateTime?.max_day}` : ""} hari</div>} */}
                                        {courierEstimateTime !== null && <div>Estimasi {courierEstimateTime?.shipment_duration_range} hari</div>}
                                        <div style={{ fontWeight: 700 }}>{formatMoney(courierRate)}</div>
                                        <div><img src={DropdownImg} alt="" style={{width: 25, height: 25}}/></div>
                                    </div>
                                    :
                                    <div className="dropdown-toggle">
                                        <div>Pilih Opsi Pengiriman</div>
                                        <img src={DropdownImg} alt="" style={{width: 25, height: 25}}/>
                                    </div>
                                    }
                                    
                                </DropdownToggle>
                                <DropdownMenu style={{width:"100%", maxHeight: 250, overflow: "auto"}}>
                                {/* {shipperList?.rates?.logistic["express"].length > 0 && (
                                    <div
                                        className="checked-type"
                                    >
                                        <div className="label-type">
                                            <span
                                                className={
                                                    courier === "Express" ? "active" : ""
                                                }
                                            ></span>
                                            Express
                                            {shipperList?.rates?.logistic["express"].map((item, key) => {
                                                return(
                                                    <DropdownItem key={key} onClick={() => handleGetCourier(item)} style={{ display:"flex", flexDirection:"row", justifyContent: "space-between", margin: "12px 0"}}>
                                                        <img
                                                            src={item.logo_url}
                                                            style={{ marginRight: 20, width: 70 }}
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                        <div>
                                                            Estimasi {item.min_day}{item.min_day !== item.max_day ? `-${item.max_day}` : ""} hari
                                                        </div>
                                                        <div >
                                                            {formatMoney(item.finalRate)}
                                                        </div>
                                                    </DropdownItem>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                                {shipperList?.rates?.logistic["instant"].length > 0 && (
                                    <div
                                        className="checked-type"
                                    >
                                        <div className="label-type">
                                            <span
                                                className={
                                                    courier === "Instant" ? "active" : ""
                                                }
                                            ></span>
                                            Instant
                                            {shipperList?.rates?.logistic["instant"].map((item, key) => {
                                                return(
                                                    <DropdownItem key={key} onClick={() => handleGetCourier(item)} style={{ display:"flex", flexDirection:"row", justifyContent: "space-between", margin: "12px 0" }}>
                                                        <img
                                                            src={item.logo_url}
                                                            style={{ marginRight: 20, width: 70 }}
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                        <div>
                                                            Estimasi {item.min_day} hari
                                                        </div>
                                                        <div >
                                                            {formatMoney(item.finalRate)}
                                                        </div>
                                                    </DropdownItem>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                                {shipperList?.rates?.logistic["regular"].length > 0 && (
                                    <div
                                        className="checked-type"
                                    >
                                        <div className="label-type">
                                            <span
                                                className={
                                                    courier === "Regular" ? "active" : ""
                                                }
                                            ></span>
                                            Regular
                                            {shipperList?.rates?.logistic["regular"].map((item, key) => {
                                                return(
                                                    <DropdownItem key={key} onClick={() => handleGetCourier(item)} style={{ display:"flex", flexDirection:"row", justifyContent: "space-between", margin: "12px 0" }}>
                                                        <img
                                                            src={item.logo_url}
                                                            style={{ marginRight: 20, width: 70 }}
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                        <div>
                                                            Estimasi {item.min_day} hari
                                                        </div>
                                                        <div >
                                                            {formatMoney(item.finalRate)}
                                                        </div>
                                                    </DropdownItem>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                                {shipperList?.rates?.logistic["same day"].length > 0 && (
                                    <div
                                        className="checked-type"
                                    >
                                        <div className="label-type">
                                            <span
                                                className={
                                                    courier === "Same Day" ? "active" : ""
                                                }
                                            ></span>
                                            Same Day
                                            {shipperList?.rates?.logistic["same day"].map((item, key) => {
                                                return(
                                                    <DropdownItem key={key} onClick={() => handleGetCourier(item)} style={{ display:"flex", flexDirection:"row", justifyContent: "space-between", margin: "12px 0" }}>
                                                        <img
                                                            src={item.logo_url}
                                                            style={{ marginRight: 20, width: 70 }}
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                        <div>
                                                            Estimasi {item.min_day} hari
                                                        </div>
                                                        <div >
                                                            {formatMoney(item.finalRate)}
                                                        </div>
                                                    </DropdownItem>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                                {shipperList?.rates?.logistic["trucking"].length > 0 && (
                                    <div
                                        className="checked-type"
                                    >
                                        <div className="label-type">
                                            <span
                                                className={
                                                    courier === "Trucking" ? "active" : ""
                                                }
                                            ></span>
                                            Trucking
                                            {shipperList?.rates?.logistic["trucking"].map((item, key) => {
                                                return(
                                                    <DropdownItem key={key} onClick={() => handleGetCourier(item)} style={{ display:"flex", flexDirection:"row", justifyContent: "space-between", margin: "12px 0" }}>
                                                        <img
                                                            src={item.logo_url}
                                                            style={{ marginRight: 20, width: 70 }}
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                        <div>
                                                            Estimasi {item.min_day} hari
                                                        </div>
                                                        <div >
                                                            {formatMoney(item.finalRate)}
                                                        </div>
                                                    </DropdownItem>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )} */}
                                {biteshipList?.length > 0 && (
                                    <div
                                        className="checked-type"
                                    >
                                        <div className="label-type">
                                            {biteshipList?.map((item, key) => {
                                                return(
                                                    <DropdownItem key={key} onClick={() => handleGetCourier(item)} style={{ display:"flex", flexDirection:"row", justifyContent: "space-between", margin: "12px 0" }}>
                                                        {(
                                                            () => {
                                                                if (item.company == "jne") {
                                                                    return (
                                                                        <img
                                                                            src={JNE}
                                                                            style={{ marginRight: 20, width: 70 }}
                                                                            alt=""
                                                                            className="img-fluid"
                                                                        />
                                                                    )
                                                                } else if (item.company == "tiki") {
                                                                    return (
                                                                        <img
                                                                            src={Tiki}
                                                                            style={{ marginRight: 20, width: 70 }}
                                                                            alt=""
                                                                            className="img-fluid"
                                                                        />
                                                                    )
                                                                } else if (item.company == "sicepat") {
                                                                    return (
                                                                        <img
                                                                            src={SiCepat}
                                                                            style={{ marginRight: 20, width: 70 }}
                                                                            alt=""
                                                                            className="img-fluid"
                                                                        />
                                                                    )
                                                                } else if (item.company == "anteraja") {
                                                                    return (
                                                                        <img
                                                                            src={Anteraja}
                                                                            style={{ marginRight: 20, width: 70 }}
                                                                            alt=""
                                                                            className="img-fluid"
                                                                        />
                                                                    )
                                                                } else if (item.company == "lion") {
                                                                    return (
                                                                        <img
                                                                            src={LionParcel}
                                                                            style={{ marginRight: 20, width: 70 }}
                                                                            alt=""
                                                                            className="img-fluid"
                                                                        />
                                                                    )
                                                                } else if (item.company == "wahana") {
                                                                    return (
                                                                        <img
                                                                            src={Wahana}
                                                                            style={{ marginRight: 20, width: 70 }}
                                                                            alt=""
                                                                            className="img-fluid"
                                                                        />
                                                                    )
                                                                } else if (item.company == "ninja") {
                                                                    return (
                                                                        <img
                                                                            src={Ninja}
                                                                            style={{ marginRight: 20, width: 70 }}
                                                                            alt=""
                                                                            className="img-fluid"
                                                                        />
                                                                    )
                                                                } else if (item.company == "rpx") {
                                                                    return (
                                                                        <img
                                                                            src={RPX}
                                                                            style={{ marginRight: 20, width: 70 }}
                                                                            alt=""
                                                                            className="img-fluid"
                                                                        />
                                                                    )
                                                                } else if (item.company == "grab") {
                                                                    return (
                                                                        <img
                                                                            src={Grab}
                                                                            style={{ marginRight: 20, width: 70 }}
                                                                            alt=""
                                                                            className="img-fluid"
                                                                        />
                                                                    )
                                                                } else if (item.company == "alfatrex") {
                                                                    return (
                                                                        <img
                                                                            src={AlfaTrex}
                                                                            style={{ marginRight: 20, width: 70 }}
                                                                            alt=""
                                                                            className="img-fluid"
                                                                        />
                                                                    )
                                                                }
                                                            }
                                                        )()}
                                                        <div>
                                                            Estimasi {item.shipment_duration_range} hari
                                                        </div>
                                                        <div >
                                                            {formatMoney(item.price)}
                                                        </div>
                                                    </DropdownItem>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                                </DropdownMenu>
                            </Dropdown>}
                        </div>}
                        {/* Section seller message */}
                        <SellerNoteStyle>
                            <div className="title-note">
                                <p>Pesan Untuk Penjual</p>
                                <p className="small">(optional)</p>
                            </div>
                            <FormText 
                                onGetValue={onGetValue}
                                item={{
                                    type: "textarea",
                                    value: sellerNote,
                                    action: "textarea",
                                    placeholder: "Catatan untuk penjual",
                                    className: "custom-textarea",
                                }}
                                />
                        </SellerNoteStyle>
                            </div>}
                        {  CheckMobile() === true ?
                        <div >
                            <h4 className="p-2">Metode Pembayaran</h4>
                            <ModalPaymentMobile
                            mobilePayment={mobilePayment}
                            paymentChannel={paymentChannel}
                            setMobilePayment={setMobilePayment}
                            setPaymentChannel={setPaymentChannel}
                            headerShow={headerShow}
                            setHeaderShow={setHeaderShow}
                            setPaymentMobile={setPaymentMobile}
                            />
                        </div>
                        :
                          false
                        }
                        </Col>
                            <Col xl="4" className="mx-0 px-0 mx-md-3">
                            <CardBill
                                digital={digital}
                                voucher={voucher}
                                setVoucher={setVoucher}
                                voucherList={voucherList}
                                priceInsurance={priceInsurance}
                                setMobilePayment={setMobilePayment}
                                mobilePayment={mobilePayment}
                                typeShipper={typeShipper}
                                shipperList={shipperList}
                                courier={courier}
                                paymentChannel={paymentChannel}
                                shipperUsed={shipperUsed}
                                setShipperUsed={setShipperUsed}
                                biteshipUsed={biteshipUsed}
                                setBiteshipUsed={setBiteshipUsed}
                                setPaymentChannel={setPaymentChannel}
                                handleOrderPayment={handleOrderPayment}
                                productUpdate={productUpdate}
                                productInfo={productInfo}
                                dataOrder={dataOrder}
                                checkCourier={checkCourier}
                                handleOrder={handleOrder}
                                courierRate={courierRate}
                                selectCourier={selectCourier}
                                setPriceInsurance={setPriceInsurance}
                                insuranceShow={insuranceShow}
                                handleInsuranceChecklist={handleInsuranceChecklist}
                                voucherUsed={voucherUsed}
                                handleStepBack={handleStepBack}
                                handleCheckInsurance={handleCheckInsurance}
                                /></Col>
                        </div>

                        {/* This section Form Data modal Desktop*/}
                        <Modal isOpen={modalShow} toggle={handleShowModal} centered fullscreen="lg" size="xl">
                            <ModalHeader>
                                Data Diri
                            </ModalHeader>
                            <ModalBody>
                                <div>
                                    <Col xl="10" md="10" lg="10">
                                        <div className="accordion-area btn-address" style={{ display: "flex", flexDirection: "row" }}>
                                            <ModalForm>
                                                <PaymentForm
                                                    form={form}
                                                    setForm={setForm}
                                                    validationClick={validationClick}
                                                    validateForm={validateForm}
                                                />
                                                <Row>
                                                    <Col xl="6" md="6" sm="12">
                                                        <FormGroup>
                                                            <label htmlFor="">
                                                                Provinsi
                                                                <span className="text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <Select
                                                                className="label-country"
                                                                onChange={(e) =>
                                                                    handleGetCity(e)
                                                                }
                                                                placeholder="Pilih"
                                                                options={province}
                                                                value={valueProvince}
                                                            />
                                                            {valueProvince === undefined &&
                                                                !validateForm && (
                                                                    <small className="text-danger">
                                                                        <i>
                                                                            Provinsi harus
                                                                            diisi
                                                                        </i>
                                                                    </small>
                                                                )}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl="6" md="6" sm="12">
                                                        <FormGroup>
                                                            <label htmlFor="">
                                                                Kota
                                                                <span className="text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <Select
                                                                className="label-country"
                                                                onChange={(e) =>
                                                                    handleGetDistrict(e)
                                                                }
                                                                placeholder="Pilih"
                                                                options={city}
                                                                value={valueCity}
                                                            />
                                                            {valueCity === undefined &&
                                                                !validateForm ? (
                                                                <small className="text-danger">
                                                                    <i>Kota harus diisi</i>
                                                                </small>
                                                            ) : (
                                                                valueCity?.length === 0 &&
                                                                !validateForm && (
                                                                    <small className="text-danger">
                                                                        <i>
                                                                            Kota harus diisi
                                                                        </i>
                                                                    </small>
                                                                )
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl="6" md="6" sm="12">
                                                        <FormGroup>
                                                            <label htmlFor="">
                                                                Kecamatan
                                                                <span className="text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <Select
                                                                className="label-country"
                                                                onChange={(e) =>
                                                                    handleGetValueDistrict(
                                                                        e
                                                                    )
                                                                }
                                                                placeholder="Pilih"
                                                                value={valueDistrict}
                                                                options={district}
                                                            />
                                                            {valueDistrict === undefined &&
                                                                !validateForm ? (
                                                                <small className="text-danger">
                                                                    <i>
                                                                        Kecamatan harus
                                                                        diisi
                                                                    </i>
                                                                </small>
                                                            ) : (
                                                                valueDistrict?.length ===
                                                                0 &&
                                                                !validateForm && (
                                                                    <small className="text-danger">
                                                                        <i>
                                                                            Kecamatan harus
                                                                            diisi
                                                                        </i>
                                                                    </small>
                                                                )
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl="6" md="6" sm="12">
                                                        <FormGroup>
                                                            <label htmlFor="">
                                                                Kelurahan
                                                                <span className="text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <Select
                                                                className="label-country"
                                                                onChange={(e) =>
                                                                    handleGetValueArea(e)
                                                                }
                                                                placeholder="Pilih"
                                                                value={valueArea}
                                                                options={area}
                                                            />
                                                            {valueArea === undefined &&
                                                                !validateForm ? (
                                                                <small className="text-danger">
                                                                    <i>
                                                                        Kelurahan harus
                                                                        diisi
                                                                    </i>
                                                                </small>
                                                            ) : (
                                                                valueArea?.length === 0 &&
                                                                !validateForm && (
                                                                    <small className="text-danger">
                                                                        <i>
                                                                            Kelurahan harus
                                                                            diisi
                                                                        </i>
                                                                    </small>
                                                                )
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl="8" md="6" sm="6">
                                                        <FormText
                                                            onGetValue={onGetValueAddress}
                                                            validateForm={validateForm}
                                                            validationClick={validationClick}
                                                            item={formAddress}
                                                        />
                                                    </Col>
                                                    <Col xl="4" md="4" sm="12">
                                                        <FormText
                                                            validateForm={validateForm}
                                                            validationClick={
                                                                validationClick
                                                            }
                                                            item={{
                                                                readOnly: true,
                                                                label: "Kode Pos",
                                                                type: "number",
                                                                required: true,
                                                                action: "text",
                                                                name: "post-code",
                                                                valid: "Kode pos tidak boleh kosong",
                                                                value: postCode,
                                                                status:
                                                                    postCode === ""
                                                                        ? false
                                                                        : true,
                                                                defaultValue: postCode,
                                                            }}
                                                        />
                                                    </Col>
                                                    {getLocation && (
                                                        <Col xl="12" md="12" sm="12">
                                                            <Spinner
                                                                size="sm"
                                                                color="secondary"
                                                            />
                                                            <LoadingText>
                                                                {" "}
                                                                Sedang mendapatkan data
                                                                lokasi
                                                            </LoadingText>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </ModalForm>
                                            <ModalInfo>
                                                <img src={DeliveryAddressImage} alt=""/>
                                                <h6>Mohon Data di Check Kembali</h6>
                                                <p>Pastikan Semua Data Terisi Dengan benar agar pengiriman lancar</p>
                                                <Button color="primary" onClick={handleShowModal}>Simpan</Button>
                                            </ModalInfo>
                                        </div>
                                    </Col>
                                </div>
                            </ModalBody>
                        </Modal>

                        {/* This section modal Mobile */}
                        <CustomModal 
                            isOpen={modalShowMobile}
                            onRequestClose={handleShowModalMobile}
                            style={CustomStyles}
                            ariaHideApp={false}
                            >
                            <div className="d-flex justify-content-between my-3 py-3 px-3" style={{boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.08)", borderRadius:"0 0 8px 8px"}}>
                                <img src={IconBack} alt="" onClick={handleShowModalMobile}/>
                                <h3 style={{ fontWeight: 700, fontSize: 20, paddingRight:"40%" }}>Isi Data</h3>
                            </div>
                            <div>
                                <Col xl="10" md="10" lg="10">
                                    <div className="accordion-area btn-address" style={{ display: "flex", flexDirection: "row" }}>
                                        <ModalForm>
                                            <PaymentForm
                                                form={form}
                                                setForm={setForm}
                                                validationClick={validationClick}
                                                validateForm={validateForm}
                                            />
                                            <Row>
                                                <Col xl="6" md="6" sm="12">
                                                    <FormGroup>
                                                        <label htmlFor="">
                                                            Provinsi
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <Select
                                                            className="label-country"
                                                            onChange={(e) =>
                                                                handleGetCity(e)
                                                            }
                                                            placeholder="Pilih"
                                                            options={province}
                                                            value={valueProvince}
                                                        />
                                                        {valueProvince === undefined &&
                                                            !validateForm && (
                                                                <small className="text-danger">
                                                                    <i>
                                                                        Provinsi harus
                                                                        diisi
                                                                    </i>
                                                                </small>
                                                            )}
                                                    </FormGroup>
                                                </Col>
                                                <Col xl="6" md="6" sm="12">
                                                    <FormGroup>
                                                        <label htmlFor="">
                                                            Kota
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <Select
                                                            className="label-country"
                                                            onChange={(e) =>
                                                                handleGetDistrict(e)
                                                            }
                                                            placeholder="Pilih"
                                                            options={city}
                                                            value={valueCity}
                                                        />
                                                        {valueCity === undefined &&
                                                            !validateForm ? (
                                                            <small className="text-danger">
                                                                <i>Kota harus diisi</i>
                                                            </small>
                                                        ) : (
                                                            valueCity?.length === 0 &&
                                                            !validateForm && (
                                                                <small className="text-danger">
                                                                    <i>
                                                                        Kota harus diisi
                                                                    </i>
                                                                </small>
                                                            )
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                <Col xl="6" md="6" sm="12">
                                                    <FormGroup>
                                                        <label htmlFor="">
                                                            Kecamatan
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <Select
                                                            className="label-country"
                                                            onChange={(e) =>
                                                                handleGetValueDistrict(
                                                                    e
                                                                )
                                                            }
                                                            placeholder="Pilih"
                                                            value={valueDistrict}
                                                            options={district}
                                                        />
                                                        {valueDistrict === undefined &&
                                                            !validateForm ? (
                                                            <small className="text-danger">
                                                                <i>
                                                                    Kecamatan harus
                                                                    diisi
                                                                </i>
                                                            </small>
                                                        ) : (
                                                            valueDistrict?.length ===
                                                            0 &&
                                                            !validateForm && (
                                                                <small className="text-danger">
                                                                    <i>
                                                                        Kecamatan harus
                                                                        diisi
                                                                    </i>
                                                                </small>
                                                            )
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                <Col xl="6" md="6" sm="12">
                                                    <FormGroup>
                                                        <label htmlFor="">
                                                            Kelurahan
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <Select
                                                            className="label-country"
                                                            onChange={(e) =>
                                                                handleGetValueArea(e)
                                                            }
                                                            placeholder="Pilih"
                                                            value={valueArea}
                                                            options={area}
                                                        />
                                                        {valueArea === undefined &&
                                                            !validateForm ? (
                                                            <small className="text-danger">
                                                                <i>
                                                                    Kelurahan harus
                                                                    diisi
                                                                </i>
                                                            </small>
                                                        ) : (
                                                            valueArea?.length === 0 &&
                                                            !validateForm && (
                                                                <small className="text-danger">
                                                                    <i>
                                                                        Kelurahan harus
                                                                        diisi
                                                                    </i>
                                                                </small>
                                                            )
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                <Col xl="8" md="6" sm="6">
                                                    <FormText
                                                        onGetValue={onGetValueAddress}
                                                        validateForm={validateForm}
                                                        validationClick={validationClick}
                                                        item={formAddress}
                                                    />
                                                </Col>
                                                <Col xl="4" md="4" sm="12">
                                                    <FormText
                                                        validateForm={validateForm}
                                                        validationClick={
                                                            validationClick
                                                        }
                                                        item={{
                                                            readOnly: true,
                                                            label: "Kode Pos",
                                                            type: "number",
                                                            required: true,
                                                            action: "text",
                                                            name: "post-code",
                                                            valid: "Kode pos tidak boleh kosong",
                                                            value: postCode,
                                                            status:
                                                                postCode === ""
                                                                    ? false
                                                                    : true,
                                                            defaultValue: postCode,
                                                        }}
                                                    />
                                                </Col>
                                                <div className="my-2 px-3 py-0" style={{width:"100%"}}>
                                                    <Button color="primary" onClick={handleShowModalMobile} style={{width:"100%", fontWeight:600}}>Simpan Alamat</Button>
                                                </div>
                                            </Row>
                                        </ModalForm>
                                    </div>
                                </Col>
                            </div>
                        </CustomModal>
                    </Container>
                </PaymentStyle>
            </React.Fragment>

            {/* Footer Price Mobile*/}
            { CheckMobile() === true && isPriceFooterMobilePayment ?
                <div className="d-flex mt-4 mx-2 justify-content-between">
                    <div className="d-flex my-4 flex-column" >
                    <p className="m-0 h5 font-light">Total Pembayaran</p>
                        {priceInsurance && insuranceShow === true ? (
                            <div className="price-total h2 font-bold" style={{color:"#000A8C"}}>
                                {voucher
                                    ? paymentChannel
                                        ? paymentChannel.percentages !== 0
                                            ? formatMoney(
                                                dataOrder?.total *
                                                productInfo?.product?.price +
                                                (biteshipUsed.price || 0) +
                                                ((dataOrder?.total *
                                                    productInfo?.product
                                                        ?.price) /
                                                    100) *
                                                paymentChannel.percentages +
                                                priceInsurance.insuranceRate +
                                                (((biteshipUsed.price ||
                                                    0) /
                                                    100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) >
                                                    voucher.max_discount
                                                    ? voucher.max_discount -
                                                    voucher.max_discount * 2
                                                    : ((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) -
                                                    ((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) *
                                                    2)
                                            )
                                            : formatMoney(
                                                dataOrder?.total *
                                                productInfo?.product?.price +
                                                (biteshipUsed.price || 0) +
                                                paymentChannel.fee +
                                                priceInsurance.insuranceRate +
                                                (((biteshipUsed.price ||
                                                    0) /
                                                    100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) >
                                                    voucher.max_discount
                                                    ? voucher.max_discount -
                                                    voucher.max_discount * 2
                                                    : ((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) -
                                                    ((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) *
                                                    2)
                                            )
                                        : formatMoney(
                                            dataOrder?.total *
                                            productInfo?.product?.price +
                                            (biteshipUsed.price || 0) +
                                            priceInsurance.insuranceRate +
                                            (((biteshipUsed.price || 0) /
                                                100) *
                                                Number(
                                                    voucher.discount_percentages
                                                ) >
                                                voucher.max_discount
                                                ? voucher.max_discount -
                                                voucher.max_discount * 2
                                                : ((biteshipUsed.price ||
                                                    0) /
                                                    100) *
                                                Number(
                                                    voucher.discount_percentages
                                                ) -
                                                ((biteshipUsed.price ||
                                                    0) /
                                                    100) *
                                                Number(
                                                    voucher.discount_percentages
                                                ) *
                                                2)
                                        )
                                    : paymentChannel
                                        ? paymentChannel.percentages !== 0
                                            ? formatMoney(
                                                dataOrder?.total *
                                                productInfo?.product?.price +
                                                (biteshipUsed.price || 0) +
                                                ((dataOrder?.total *
                                                    productInfo?.product?.price) /
                                                    100) *
                                                paymentChannel.percentages +
                                                priceInsurance.insuranceRate
                                            )
                                            : formatMoney(
                                                dataOrder?.total *
                                                productInfo?.product?.price +
                                                (biteshipUsed.price || 0) +
                                                paymentChannel.fee +
                                                priceInsurance.insuranceRate
                                            )
                                        : formatMoney(
                                            dataOrder?.total *
                                            productInfo?.product?.price +
                                            (biteshipUsed.price || 0) +
                                            priceInsurance.insuranceRate
                                        )}
                            </div>
                        ) : voucher ? (
                            paymentChannel ? (
                                paymentChannel.percentages !== 0 ? (
                                    <div className="price-total h2 font-bold" style={{color:"#000A8C"}}>
                                        {priceInsurance && insuranceShow === true
                                            ? formatMoney(
                                                dataOrder?.total *
                                                productInfo?.product?.price +
                                                (biteshipUsed.price || 0) +
                                                ((dataOrder?.total *
                                                    productInfo?.product
                                                        ?.price) /
                                                    100) *
                                                paymentChannel.percentages +
                                                priceInsurance.insuranceRate +
                                                (((biteshipUsed.price ||
                                                    0) /
                                                    100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) >
                                                    voucher.max_discount
                                                    ? voucher.max_discount -
                                                    voucher.max_discount * 2
                                                    : ((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) -
                                                    ((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) *
                                                    2)
                                            )
                                            : formatMoney(
                                                dataOrder?.total *
                                                productInfo?.product?.price +
                                                (biteshipUsed.price || 0) +
                                                ((dataOrder?.total *
                                                    productInfo?.product
                                                        ?.price) /
                                                    100) *
                                                paymentChannel.percentages +
                                                (((biteshipUsed.price ||
                                                    0) /
                                                    100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) >
                                                    voucher.max_discount
                                                    ? voucher.max_discount -
                                                    voucher.max_discount * 2
                                                    : ((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) -
                                                    ((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) *
                                                    2)
                                            )}
                                    </div>
                                ) : priceInsurance && insuranceShow === true ? (
                                    <div className="price-total h2 font-bold" style={{color:"#000A8C"}}>
                                        {formatMoney(
                                            dataOrder?.total *
                                            productInfo?.product?.price +
                                            (biteshipUsed.price || 0) +
                                            paymentChannel.fee +
                                            priceInsurance.insuranceRate +
                                            (((biteshipUsed.price || 0) /
                                                100) *
                                                Number(
                                                    voucher.discount_percentages
                                                ) >
                                                voucher.max_discount
                                                ? voucher.max_discount -
                                                voucher.max_discount * 2
                                                : ((biteshipUsed.price ||
                                                    0) /
                                                    100) *
                                                Number(
                                                    voucher.discount_percentages
                                                ) -
                                                ((biteshipUsed.price ||
                                                    0) /
                                                    100) *
                                                Number(
                                                    voucher.discount_percentages
                                                ) *
                                                2)
                                        )}
                                    </div>
                                ) : (
                                    <div className="price-total h2 font-bold" style={{color:"#000A8C"}}>
                                        {formatMoney(
                                            dataOrder?.total *
                                            productInfo?.product?.price +
                                            (biteshipUsed.price || 0) +
                                            paymentChannel.fee +
                                            (((biteshipUsed.price || 0) /
                                                100) *
                                                Number(
                                                    voucher.discount_percentages
                                                ) >
                                                voucher.max_discount
                                                ? voucher.max_discount -
                                                voucher.max_discount * 2
                                                : ((biteshipUsed.price ||
                                                    0) /
                                                    100) *
                                                Number(
                                                    voucher.discount_percentages
                                                ) -
                                                ((biteshipUsed.price ||
                                                    0) /
                                                    100) *
                                                Number(
                                                    voucher.discount_percentages
                                                ) *
                                                2)
                                        )}
                                    </div>
                                )
                            ) : biteshipUsed ? (
                                <div className="price-total h2 font-bold" style={{color:"#000A8C"}}>
                                    {formatMoney(
                                        dataOrder?.total *
                                        productInfo?.product?.price +
                                        (biteshipUsed.price || 0) +
                                        (((biteshipUsed.price || 0) / 100) *
                                            Number(
                                                voucher.discount_percentages
                                            ) >
                                            voucher.max_discount
                                            ? voucher.max_discount -
                                            voucher.max_discount * 2
                                            : ((biteshipUsed.price || 0) /
                                                100) *
                                            Number(
                                                voucher.discount_percentages
                                            ) -
                                            ((biteshipUsed.price || 0) /
                                                100) *
                                            Number(
                                                voucher.discount_percentages
                                            ) *
                                            2)
                                    )}
                                </div>
                            ) : (
                                <div className="price-total h2 font-bold" style={{color:"#000A8C"}}>
                                    {formatMoney(
// Harga barang x total barang dibeli + biaya pengiriman + (Harga barang x total barang dibeli / 100) x persentase pembayaran + biaya asuransi
                                        dataOrder?.total *
                                        productInfo?.product?.price +
                                        (((biteshipUsed.price || 0) / 100) *
                                            Number(
                                                voucher.discount_percentages
                                            ) >
                                            voucher.max_discount
                                            ? voucher.max_discount -
                                            voucher.max_discount * 2
                                            : ((biteshipUsed.price || 0) /
                                                100) *
                                            Number(
                                                voucher.discount_percentages
                                            ) -
                                            ((biteshipUsed.price || 0) /
                                                100) *
                                            Number(
                                                voucher.discount_percentages
                                            ) *
                                            2)
                                    )}
                                </div>
                            )
                        ) : paymentChannel ? (
                            paymentChannel.percentages !== 0 ? (
// Harga barang x total barang dibeli + biaya pengiriman + (Harga barang x total barang dibeli / 100) x persentase pembayaran + biaya asuransi
                                <div className="price-total h2 font-bold" style={{color:"#000A8C"}}>
                                    {priceInsurance && insuranceShow === true
                                        ? formatMoney(
                                            dataOrder?.total *
                                            productInfo?.product?.price +
                                            (biteshipUsed.price || 0) +
                                            ((dataOrder?.total *
                                                productInfo?.product?.price) /
                                                100) *
                                            paymentChannel.percentages +
                                            priceInsurance.insuranceRate
                                        )
// Harga barang x total barang dibeli + biaya pengiriman + (Harga barang x total barang dibeli / 100) x persentase pembayaran
                                        : formatMoney(
                                            dataOrder?.total *
                                            productInfo?.product?.price +
                                            (biteshipUsed.price || 0) +
                                            ((dataOrder?.total *
                                                productInfo?.product?.price) /
                                                100) *
                                            paymentChannel.percentages
                                        )}
                                </div>
                            ) : priceInsurance && insuranceShow === true ? (
// Harga barang x total barang dibeli + biaya pengiriman + fee payment channel + biaya asuransi
                                <div className="price-total h2 font-bold" style={{color:"#000A8C"}}>
                                    {formatMoney(
                                        dataOrder?.total *
                                        productInfo?.product?.price +
                                        (biteshipUsed.price || 0) +
                                        paymentChannel.fee +
                                        priceInsurance.insuranceRate
                                    )}
                                </div>
                            ) : (
        // Harga barang x total barang dibeli + biaya pengiriman + fee payment channel
                                <div className="price-total h2 font-bold" style={{color:"#000A8C"}}>
                                    {formatMoney(
                                        dataOrder?.total *
                                        productInfo?.product?.price +
                                        (biteshipUsed.price || 0) +
                                        paymentChannel.fee
                                    )}
                                </div>
                            )
                        ) : biteshipUsed ? (
                // Harga barang x total barang dibeli + biaya pengiriman
                            <div className="price-total h2 font-bold" style={{color:"#000A8C"}}>
                                {formatMoney(
                                    dataOrder?.total * productInfo?.product?.price +
                                    (biteshipUsed.price || 0)
                                )}
                            </div>
                        ) : (
                            // Harga barang x total barang dibeli
                            <div className="price-total h2 font-bold" style={{color:"#000A8C"}}>
                                {formatMoney(
                                    dataOrder?.total * productInfo?.product?.price
                                )}
                            </div>
                        )}
                    </div>
                    { paymentMobile === true && deliveryMobile === true ?
                        <button onClick={handleOrderPayment} className="buy-btn-mobile">Bayar</button>
                        :
                        <button disabled={true} className="buy-btn-mobile" style={{background:"rgba(0, 10, 140, 0.2)", color:" #FFFFFF"}}>Bayar</button>
                    }
                </div>
            :
                false

            }
        </Style>
    );
};
export default Payment;
