import React from "react";
import { Card, Col, Row } from "reactstrap";
import styled from "styled-components";
import { formatMoney } from "../../../../Utils/PriceConverter";
import Rating from "../../../../Components/Particle/Rating";
import { color } from "../../../../Utils/Variable";
import Button from "../../../../Components/Particle/Button";
const Style = styled.div`
    margin-bottom: 30px;
    position: relative;
    p {
        @media (max-width: 575px) {
            font-size: 12px;
        }
    }
    .product-detail {
        @media (max-width: 767px) {
            padding: 0 10px;
        }
    }
    .card-product {
        box-shadow: 0 3px 6px #ddd;
        border: unset;
        img {
            border-radius: 0.25rem 0 0 0.25rem;
        }
    }
    .img-product {
        height: 177px;
        object-fit: contain;
        width: 100%;
    }
    .price {
        @media (max-width: 575px) {
            font-size: 12px;
        }
    }
    .title {
        font-size: 16px;
        color: #464646;
        font-weight: bold;
        text-transform: uppercase;
        white-space: normal;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        @media (max-width: 575px) {
            font-size: 12px;
        }
    }
    .pre-order {
        padding: 4px;
        font-size: 14px;
        width: max-content;
        margin: 5px 0 0 auto;
        background: ${color.primary};
        font-weight: bold;
        position: absolute;
        top: 0;
        left: 10px;
        border-radius: 5px;
        color: ${color.user.yellow};
        @media (max-width: 575px) {
            font-size: 12px;
        }
    }
    .btn {
        @media (max-width: 767px) {
            padding: 0 5px;
            margin-bottom: 10px;
        }
    }
    .desktop-action {
        @media (max-width: 575px) {
            display: none;
        }
    }
`;
const CardProduct = ({
    item,
    openProduct = () => {},
    handleEditProduct = () => {},
}) => {
    class TravellerCollection extends Array {
        sum(key) {
            return this.reduce((a, b) => a + (b[key] || 0), 0);
        }
    }
    const traveler = new TravellerCollection(...item.spec);
    const handleViewProduct = (item) => {
        if (window.screen.width < 576) {
            openProduct(item);
        }
    };
    // const [isShow, setShow] = React.useState(false);
    return (
        <Style onClick={() => handleViewProduct(item)}>
            <Card className="card-product">
                <Row>
                    <Col xl="5" md="12" sm="12" xs="12">
                        <img
                            src={item?.product_image[0]?.img_url}
                            alt=""
                            className="img-fluid img-product"
                        />
                    </Col>

                    {item.pre_order && <div className="pre-order">PO</div>}
                    <Col xl="7" md="12" sm="12" xs="12">
                        <div className="product-detail">
                            <h6 className="mt-2 title">{item.name}</h6>
                            <p className="font-weight-bold mb-0">
                                {formatMoney(item.price)}
                            </p>

                            <Rating rating={item.rating} totalStars={5} />

                            <div
                                className={
                                    traveler.sum("total_stock") > 9
                                        ? "mb-4"
                                        : "mb-0"
                                }
                            >
                                {item.supplier.city && (
                                    <p className=" mb-0 mr-3 product-city">
                                        {item.supplier.city}
                                    </p>
                                )}
                                <div>
                                    {traveler.sum("total_stock") === 0 ? (
                                        <i
                                            style={{ fontSize: 14 }}
                                            className="text-danger"
                                        >
                                            Stok kosong
                                        </i>
                                    ) : (
                                        traveler.sum("total_stock") < 10 && (
                                            <i
                                                style={{ fontSize: 12 }}
                                                className="text-danger"
                                            >
                                                Stok hampir habis
                                            </i>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                        {window.screen.width > 575 && (
                            <div className="desktop-action">
                                <Row>
                                    <Col xl="8" lg="8" md="8" sm="8" xs="8">
                                        <Button
                                            color="primary"
                                            block
                                            onClick={() => openProduct(item)}
                                        >
                                            Lihat Produk
                                        </Button>
                                    </Col>
                                    <Col
                                        xl="4"
                                        lg="4"
                                        md="4"
                                        sm="4"
                                        xs="4"
                                        style={{ paddingLeft: 0 }}
                                    >
                                        <Button
                                            color="#28a745"
                                            onClick={() =>
                                                handleEditProduct(item)
                                            }
                                        >
                                            <i className="fas fa-edit"></i> Edit
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Col>
                </Row>
            </Card>
        </Style>
    );
};
export default CardProduct;
