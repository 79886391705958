import React from "react";
import Tab from "./Tab";
import { Card, CardBody, Col, Row } from "reactstrap";
import OrderCard from "./Card/OrderCard";
import styled from "styled-components";
const Style = styled(Card)`
    border: unset;
    .border-bottom {
        margin-bottom: 35px;
    }
`;
// import Style from "./style";

function OrderListReseller() {
    return (
        <Style>
            <CardBody>
                <div className="border-bottom">
                    <Tab />
                </div>
                <Row>
                    <Col xl="12">
                        <OrderCard />
                    </Col>
                </Row>
            </CardBody>
        </Style>
    );
}

export default OrderListReseller;
