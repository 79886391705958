const StepOfPayment = [
    {
        name: "Bank Sinarmas",
        step: [
            {
                header: "Pembayaran Melalui Menu Transfer pada Mesin ATM",
                list: [
                    "Pilih “Transfer”",
                    "Pilih “Rekening Bank Sinarmas”",
                    "Pilih “Rekening Nasabah Lain”",
                    "Masukkan kode Virtual Account + No. Pelanggan Contoh : 1637241969209486",
                    "Tagihan akan muncul secara otomatis atau masukkan jumlah nominal yang akan dibayarkan",
                    "Pastikan nama penerima dan jumlah sesuai dengan yang akan dibayarkan",
                    "Jika informasi sudah benar, pilih BENAR",
                ],
            },
            {
                header: "Pembayaran Melalui Menu Transfer pada Internet Banking",
                list: [
                    "Pilih “Transfer Dana”",
                    "Pilih “Transfer Bank Sinarmas”",
                    "Masukkan kode Virtual Account + No. Pelanggan Contoh : 1637241969209486",
                    "Tagihan akan muncul secara otomatis atau masukkan jumlah nominal yang akan dibayarkan",
                    "Pastikan nama penerima dan jumlah sesuai dengan yang akan dibayarkan",
                    "Silahkan masukkan Nomor Token atau SMS Token Anda",
                ],
            },
            {
                header: "Pembayaran Melalui Mesin ATM Bank Lain (ATM Bersama, ALTO, Prima)",
                list: [
                    "Masuk ke menu “Layanan Transfer”",
                    "Lalu pilih “Transfer Rek. Bank Lain”",
                    "Masukkan kode sandi Bank Sinarmas (153) terlebih dahulu Masukkan 153 + 8MMM + No. Pelanggan Contoh : 1637241969209486",
                    "Tagihan akan muncul secara otomatis atau masukkan jumlah nominal yang akan dibayarkan",
                    "Pastikan nama penerima dan jumlah sesuai dengan yang akan dibayarkan",
                ],
            },
            {
                header: "Cara Pembayaran Melalui Menu Pembayaran pada Mesin ATM",
                list: [
                    "Pilih “Pembayaran”",
                    "Pilih “Layar Berikutnya”",
                    "Pilih “Virtual Account”",
                    "Masukkan kode Virtual Account + No. Pelanggan Contoh : 1637241969209486",
                    "Tagihan akan muncul secara otomatis atau masukkan jumlah nominal yang akan dibayarkan",
                    "Pastikan nama penerima dan jumlah sesuai dengan yang akan dibayarkan",
                ],
            },
            {
                header: "Cara Pembayaran Melalui Menu Pembayaran pada Internet Banking",
                list: [
                    "Pilih “Pembayaran/Pembelian”",
                    "Pilih “Virtual Account”",
                    "Masukkan kode Virtual Account + No. Pelanggan pada kolom IPDEL Contoh : 1637241969209486",
                    "Tagihan akan muncul secara otomatis",
                    "Pastikan nama penerima dan jumlah sesuai dengan yang akan dibayarkan",
                ],
            },
            {
                header: "Cara Pembayaran Melalui Teller Bank Sinarmas",
                list: [
                    "Sebutkan kode Virtual Account 8MMM + No. Pelanggan kepada petugas Teller Bank Sinarmas",
                    "Pastikan nama penerima dan jumlah sesuai dengan yang akan dibayarkan",
                    "Transaksi selesai dan Anda akan menerima bukti bayar tagihan",
                ],
            },
            {
                header: "Cara Pembayaran Melalui Teller Bank LLG/RTGS Melalui Bank Lain",
                list: [
                    "Isi nama penerima : Inisial Merchant QQ Nama Nasabah",
                    "Isi Bank Tujuan",
                    "Pastikan nama penerima dan jumlah sesuai dengan yang akan dibayarkan",
                    "Transaksi selesai dan Anda akan menerima bukti bayar tagihan",
                ],
            },
        ],
    },
    {
        name: "CIMB Niaga",
        step: [
            {
                header: "Pembayaran VA melalui ATM CIMB NIAGA",
                list: [
                    "Masukan kartu ATM dan PIN CIMB Niaga Anda",
                    "Pilih Menu Pembayaran",
                    "Pilih Virtual Account",
                    "Masukkan NOMOR VIRTUAL ACCOUNT",
                    "Muncul nama dan nominal billing di layar konfirmasi",
                    "Pilih OK untuk payment",
                ],
            },
            {
                header: "Pembayaran VA melalui ATM BERSAMA/PRIMA/BANK LAIN",
                list: [
                    "Masukan kartu ATM dan PIN Anda",
                    "Masuk ke menu TRANSFER/TRANSFER Online",
                    "Pilih Bank tujuan -> Bank CIMB Niaga (kode bank: 022)",
                    "Masukkan nomor Virtual Account Anda",
                    "Masukkan jumlah pembayaran sesuai tagihan",
                    "Ikuti instruksi untuk menyelesaikan transaksi",
                ],
            },
            {
                header: "Pembayaran VA melalui OCTO Clicks",
                list: [
                    "Login ke OCTO Clicks",
                    "Pilih menu Pembayaran Tagihan",
                    "Pilih kategori Mobile Rekening Virtual",
                    "Masukkan nomor Virtual Account Anda",
                    "Tekan tombol 'lanjut untuk verifikasi detail'",
                    "Tekan tombol 'kirim OTP untuk lanjut'",
                    "Masukkan OTP yang dikirimkan ke nomor HP anda",
                    "Tekan tombol 'Konfirmasi'",
                ],
            },
            {
                header: "Pembayaran VA melalui Internet Banking Bank Lain",
                list: [
                    "Login Ke Internet Banking",
                    "Pilih menu Transfer ke Bank Lain Online",
                    "Pilih bank tujuan Bank CIMB Niaga (kode bank: 022)",
                    "Masukkan nomor Virtual Account Anda",
                    "Masukan jumlah amount sesuai tagihan",
                    "Ikuti instruksi untuk menyelesaikan transaksi",
                ],
            },
            {
                header: "Pembayaran VA melalui OCTO MOBILE",
                list: [
                    "Login ke Octo Mobile",
                    "Pilih menu TRANSFER > Transfer to Other CIMB Niaga Account",
                    "Pilih rekening sumber anda: CASA atau Rekening Ponsel",
                    "Masukkan nomor Virtual Account Anda pada kolom Transfer To",
                    "Masukkan jumlah amount sesuai tagihan",
                    "Ikuti instruksi untuk menyelesaikan transaksi",
                ],
            },
            {
                header: "Pembayaran VA melalui EDC",
                list: [
                    "Pada menu EDC pilih menu ‘Virtual Account’ menggunakan kursor",
                    "Pilih ‘Pembayaran Tagihan VA’ dengan menggunakan tombol kursor",
                    "Kemudian masukkan / gesekkan kartu CIMB Niaga",
                    "Konfirmasi kartu jika ingin melanjutkan, Pilih YES atau tekan tombol ENTER",
                    "Masukkan nomor virtual account",
                    "Kemudian pilih jenis Rekening anda dengan menggunakan tombol angka 1 atau 2, lalu tekan ENTER",
                    "Ikuti instruksi untuk menyelesaikan transaksi",
                ],
            },
        ],
    },
    {
        name: "Maybank",
        step: [
            {
                header: "Pembayaran VA Melalui SMS+ Banking",
                list: [
                    "Klik App Maybank SMS+ Banking",
                    "Input Passcode (jika ada)",
                    "Pilih media koneksi : SMS , USSD atau Data (lihat indikator koneksi pada pojok kanan atas)",
                    "Menu Utama, klik icon Transfer",
                    "Pilih Virtual Account",
                    "Pada kolom Rekening Sumber, masukkan rekening sumber (Pada media koneksi USSD tidak diperlukan)",
                    "Pada kolom Jumlah, Masukkan jumlah yang akan di bayarkan",
                    "Pada kolom No Ref/Berita, masukkan No Ref/Berita",
                    "Pada kolom Rekening Tujuan, Masukkan Nomor Virtual Account Yang Tertera Pada Halaman Konfirmasi",
                    "Klik tombol Kirim",
                    "Masukkan PIN yang dikirim melalui SMS",
                    "Transaksi selesai",
                ],
            },
            {
                header: "Pembayaran VA Melalui Aplikasi M2U",
                list: [
                    "Login Aplikasi M2U Menggunakan Fingerprint Atau Masukkan Password",
                    "Pilih Transfer",
                    "Pilih Virtual Account",
                    "Pilih Sumber Tabungan",
                    "Masukkan Nomor Virtual Account Yang Tertera Pada Halaman Konfirmasi, Klik LANJUT",
                    "Masukkan Jumlah Transfer, Klik PROSES",
                    "Klik KONFIRMASI & MINTA TAC",
                    "Masukkan SMS Token/TAC, Klik OK",
                    "Selesai",
                ],
            },
            {
                header: "Pembayaran VA ATM Maybank",
                list: [
                    "Masukkan PIN ATM Anda",
                    "Pilih Transfer",
                    "Pilih Virtual Account",
                    "Masukkan Nomor Virtual Account Yang Tertera Pada Halaman Konfirmasi",
                    "Pilih BENAR",
                    "Pilih YA",
                    "Selesai",
                ],
            },
            {
                header: "Pembayaran VA Dari ATM Bank Lain",
                list: [
                    "Masukkan PIN ATM Anda",
                    "Pilih Menu Transaksi Lainnya",
                    "Pilih Menu Ke Rek Bank Lain",
                    "Masukkan Kode Bank Maybank (016) Kemudian Tekan Benar",
                    "Masukkan Nomor Virtual Account Yang Tertera Pada Halaman Konfirmasi, Dan Tekan Benar",
                    "Masukkan Jumlah Pembayaran Sesuai Dengan Yang Ditagihkan Dalam Halaman Konfirmasi",
                    "Pilih Benar Untuk Menyetujui Transaksi Tersebut",
                    "Selesai",
                ],
            },
            {
                header: "Pembayaran VA Dari Internet Atau Mobile Banking Bank Lain",
                list: [
                    "Buka Aplikasi Internet Banking Anda",
                    "Masukkan ID Dan Password Anda",
                    "Pilih Menu Transaksi Antarbank",
                    "Pilih Nama Bank: Maybank (016), Nomor Virtual Account Yang Tertera Pada Halaman Konfirmasi, Nominal Transfer Dan Informasi Lainnya",
                    "Pastikan Detail Tagihan Anda Sudah Benar, Kemudian Pilih OK Atau Lanjut",
                    "Masukkan PIN Transaksi Anda",
                    "Pembayaran Selesai",
                ],
            },
        ],
    },
    {
        name: "Bank Danamon",
        step: [
            {
                header: "Pembayaran Melalui ATM Danamon(Pembayaran Open/Partial)",
                list: [
                    "Masukkan KARTU ATM",
                    "Pilih BAHASA yang akan digunakan",
                    "Masukkan PIN, pilih LANJUTKAN",
                    "Pada layar pengumuman Danamon, pilih LANJUTKAN",
                    "Pada menu PILIH JUMLAH UANG ATAU JENIS TRANSAKSI, pilih PEMBAYARAN",
                    "Pada menu PEMBAYARAN, pilih LAINNYA",
                    "Pada menu PEMBAYARAN LANJUTAN, pilih VIRTUAL ACCOUNT",
                    "Masukkan NOMOR VIRTUAL ACCOUNT yang akan dibayarkan, setelah itu pilih BENAR",
                    "Setelah muncul konfirmasi data pembayaran, MASUKKAN NOMINAL yang akan dibayar",
                    "Pada layar konfirmasi pembayaran virtual account, pastikan data yang tertera sudah benar.",
                    "Pilih YA untuk membayar.",
                    "Transaksi selesai, simpan struk pembayar",
                ],
            },
            {
                header: "Pembayaran VA melalui ATM Danamon (Closed Payment)",
                list: [
                    "Masukkan KARTU",
                    "Pilih BAHASA yang akan digunakan",
                    "Masukkan PIN, pilih LANJUTKAN",
                    "Pada layar pengumuman Danamon, pilih LANJUTKAN",
                    "Pada menu PILIH JUMLAH UANG ATAU JENIS TRANSAKSI, pilih PEMBAYARAN",
                    "Pada menu PEMBAYARAN, pilih LAINNYA",
                    "Pada menu PEMBAYARAN LANJUTAN, pilih VIRTUAL ACCOUNT",
                    "Masukkan NOMOR VIRTUAL ACCOUNT yang akan dibayarkan, setelah itu pilih BENAR",
                    "Setelah muncul konfirmasi data pembayaran, pastikan data yang tertera sudah benar. Pilih YA untuk membayar",
                ],
            },
            {
                header: "Cara Pembayaran Melalui ATM Bank Lain",
                list: [
                    "Setelah memasukkan ATM & PIN, pilih menu PINDAH BUKU ke Bank Lain atau ONLINE TRANSFER ke Bank Lain.",
                    "Masukkan KODE ‘011’ (kode Danamon) diikuti dengan NOMOR VIRTUAL ACCOUNT yang akan dibayarkan, kemudian pilih PROSES/LANJUTKAN.",
                    "Masukkan NOMINAL PEMBAYARAN, kemudian pilih PROSES/LANJUTKAN.",
                    "Biarkan kosong pada pilihan input NOMOR REFERENSI, langsung pilih PROSES/LANJUTKAN.",
                    "Pilih SUMBER REKENING pendebetan anda.",
                    "Pastikan data yang tertera sudah benar, pilih PROSES/LANJUTKAN untuk melakukan pembayaran.",
                    "Transaksi selesai, simpan struk pembayaran anda",
                ],
            },
            {
                header: "Pembayaran VA melalui Danamon Mobile Banking D-Bank (Open/Partial Payment)",
                list: [
                    "Masukkan USER ID & PASSWORD, pilih LOGIN",
                    "Pilih PEMBAYARAN",
                    "Gulung layar kebawah, pilih VIRTUAL ACCOUNT",
                    "A. Jika NOMOR VA BELUM PERNAH disimpan, pilih ‘+TAMBAH BILLER BARU’ untuk memasukkan NOMOR VA, setelah itu ikuti petunjuk pada poin 5.A B. Jika NOMOR VA SUDAH DISIMPAN sebelumnya, pilih NOMOR VA pada DAFTAR REKENING TUJUAN Favorit, setelah itu ikuti panduan pada poin 5.B",
                    "A. Masukkan NOMOR VA untuk PENDAFTARAN VA baru, pilih Ajukan. Setelah itu ikuti panduan B. Masukkan NOMINAL yang ingin ditransfer.",
                    "Pastikan kebenaran pengisian data. Masukkan mPIN untuk mengkonfirmasi pembayaran, pilih KONFIRMASI. Tunggu sampai layar menunjukkan konfirmasi ‘Transaksi Berhasil’. Selesai",
                ],
            },
            {
                header: "Pembayaran VA melalui Danamon Mobile Banking D-Bank (Closed Payment)",
                list: [
                    "Masukkan USER ID & PASSWORD, pilih LOGIN",
                    "Pilih PEMBAYARAN",
                    "Gulung layar kebawah, pilih VIRTUAL ACCOUNT",
                    "A. Jika NOMOR VA BELUM PERNAH disimpan, pilih ‘+TAMBAH BILLER BARU’ untuk memasukkan NOMOR VA, setelah itu ikuti petunjuk pada poin 5.A B. Jika NOMOR VA SUDAH DISIMPAN sebelumnya, pilih NOMOR VA pada DAFTAR REKENING TUJUAN Favorit, setelah itu ikuti panduan pada poin 5.B",
                    "A. Masukkan NOMOR VA untuk PENDAFTARAN VA baru, pilih Ajukan. Setelah itu ikuti panduan 5.B B. Pastikan kebenaran data yang tertera pada layar. Masukkan mPIN untuk mengkonfirmasi pembayaran, pilih KONFIRMASI. Tunggu sampai layar menunjukkan konfirmasi ‘Transaksi Berhasil’. Selesai.",
                ],
            },
        ],
    },
];
export default StepOfPayment;
