/* eslint-disable */
import React from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import Button from "../../../../Components/Particle/Button";
import service from "../index.service";
import { useStore } from "../../../../Reducers";
import GrowingLoading from "../../../../Components/Loading/GrowingLoading";

const DeleteProduct = ({
    setDelete = () => {},
    item = false,
    toggle = () => {},
    isShow = false,
    setFailed = () => {},
}) => {
    const { dispatch, state } = useStore();
    const [isLoading, setLoading] = React.useState(false);
    const handleDeleteProduct = () => {
        let id = item.id;
        service.deleteProduct({ dispatch, id });
        setLoading(true);
    };
    React.useEffect(() => {
        if (state?.reseller?.deleteProduct?.isSuccess) {
            service.productList({ dispatch });
            setDelete(false);
            setLoading(false);
        }
        if (state?.reseller?.deleteProduct?.isError) {
            setLoading(false);
            toggle();
            setFailed(true);
            setTimeout(() => {
                setFailed(false);
            }, 3000);
        }
    }, [state.reseller.deleteProduct]);
    return (
        <Modal isOpen={isShow} toggle={toggle}>
            <GrowingLoading active={isLoading} />
            <ModalBody>
                <h5 className="text-center" style={{ margin: "50px 0" }}>
                    Anda yakin ingin menghapus {item.name} dari Katalog ?
                </h5>
                <Row>
                    <Col xl="6" ld="6" md="6" sm="6" xs="6">
                        <Button
                            color="primary"
                            type="button"
                            onClick={toggle}
                            block
                        >
                            Tidak
                        </Button>
                    </Col>
                    <Col xl="6" ld="6" md="6" sm="6" xs="6">
                        <Button
                            type="button"
                            onClick={handleDeleteProduct}
                            block
                        >
                            Ya
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};
export default DeleteProduct;
