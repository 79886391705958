/* eslint-disable */
import React from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    InputGroupText,
    InputGroup,
    Input,
    InputGroupAddon,
    FormGroup,
} from "reactstrap";
import FirstLoad from "../../../Components/Loading/FirstLoad";
import service from "./index.service";
import { useStore } from "../../../Reducers";
import SkeletonLoading from "../../../Components/Loading/Skeleton";
import Dummy from "../../../Components/Assets/Product/defaultImage.jpg";
import ProductList from "./ProductList";
import InfiniteScroll from "../../../Components/InfiniteScroll";
import styled from "styled-components";
import debounce from "../../../Utils/Debounce";

const Style = styled(Card)`
    border: unset;
    .button-card {
        margin-bottom: 30px;
    }
    .card {
        border-bottom: 5px solid #3f4091;
        box-shadow: 0 3px 6px #ddd;
        &:hover {
            box-shadow: 0 3px 6px #bbb;
        }
        h6 {
            font-size: 14px;
        }
        &-image {
            margin-bottom: 15px;
            height: 155px;
            img {
                object-fit: contain;
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
    .city {
        font-size: 12px;
        color: #868686;
    }
    .search-box {
        input {
            background-color: #fff;
            padding-top: 14px;
            padding-bottom: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.24;
            letter-spacing: normal;
            text-align: left;
            color: #656565;
            font-size: 16px;
            border-top-right-radius: 11px;
            border-left: none;
            border-bottom-right-radius: 11px;
            &:focus {
                outline: none;
                box-shadow: none;
                border-color: #ced4da;
            }
        }
        i {
            color: #656565;
        }
        .input-group-text {
            border-top-left-radius: 11px;
            border-bottom-left-radius: 11px;
            padding: 10px;
            background-color: #fff;
            @media (max-width: 575px) {
                padding: 7px;
            }
        }
    }
`;
const DEFAULT_LIMIT_DATA = 16;

const DEFAULT_LIMIT_DATA_PRODUCT = 10;
const SupplierList = () => {
    const [data, setData] = React.useState([]);
    const [dataProduct, setDataProduct] = React.useState(false);
    const [configTable, setConfigurationTable] = React.useState(false);
    const [totalElements, setTotalElements] = React.useState(0);
    const [isFirstGet, setFirstGet] = React.useState(true);
    const { dispatch, state } = useStore();
    const [isLoading, setLoading] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState("");
    const [isShow, setShow] = React.useState(false);
    const [productList, setProductList] = React.useState(false);
    const [product, setProduct] = React.useState(false);
    const [params, setParams] = React.useState({
        page: 1,
        size: DEFAULT_LIMIT_DATA,
        filters: `[["name","like","${searchValue}"]]`,
    });
    const [paramsProduct, setParamsProduct] = React.useState({
        page: 0,
        size: DEFAULT_LIMIT_DATA_PRODUCT,
    });
    const handleProductList = (value) => {
        let supplierId = `[["supplier.id","=","${value.id}"]]`;
        paramsProduct.page = 1;
        paramsProduct.filters = supplierId;
        getDataProduct(paramsProduct);
        setProductList(value);
        setShow(!isShow);
    };
    const getDataProduct = (params) => {
        setDataProduct(false);
        service.productSupplier({ dispatch, params });
        setParamsProduct(params);
    };
    const eventOnChangeSearchInput = debounce((value) => {
        service.supplierListClear({ dispatch });
        setData([]);
        getData({
            page: 1,
            size: DEFAULT_LIMIT_DATA,
            filters: `[["name","like","${value}"]]`,
        });
        setSearchValue(value);
    }, 750);
    const showProduct = () => {
        setShow(!isShow);
    };
    const handleLoadData = debounce(() => {
        if (params.size < totalElements) {
            setLoading(true);
            getData({
                page: 1,
                size: params.size + DEFAULT_LIMIT_DATA,
                filters: `[["name","like","${searchValue}"]]`,
            });
        }
    }, 750);
    const getData = (params) => {
        service.supplierList({ dispatch, params });
        setParams(params);
    };
    React.useEffect(() => {
        if (isFirstGet) {
            getData(params);
            // service.supplierList({ dispatch });
            service.product({ dispatch });
            setFirstGet(false);
        }
    }, [isFirstGet, dispatch]);
    React.useEffect(() => {
        if (state?.reseller?.supplierList?.isSuccess && !isShow) {
            setLoading(false);
            setData(state.reseller.supplierList.data.content);
            setTotalElements(state.reseller.supplierList.data.total_elements);
            service.supplierListClear({ dispatch });
        }
        if (state?.reseller?.supplierList?.isError) {
            setData([]);
        }
        if (state?.reseller?.product?.isSuccess) {
            setProduct(state.reseller.product.data.content);
            service.productResellerClear({ dispatch });
        }
        if (state?.reseller?.productList?.isSuccess) {
            const { number, next_page, total_pages, total_elements } =
                state.reseller.productList.data;
            const isPagination = Number(total_elements) > 1;
            const responseHeaderData = {
                currentPage: Number(number + 1),
                nextPage: next_page,
                total_page: Number(total_pages),
                total_elements: total_elements,
                isPagination: isPagination,
                paginationListNumber: total_pages,
            };
            setConfigurationTable(responseHeaderData);
            setDataProduct(state.reseller.productList.data.content);
            service.productSupplierClear({ dispatch });
        }
    }, [state]);
    return (
        <Style>
            <InfiniteScroll
                threshold={10}
                isLoading={isLoading}
                onLoadData={handleLoadData}
                hasMore={false}
                loadingComponent={<FirstLoad />}
            >
                <CardBody>
                    <h3 className="mb-3">List Supplier</h3>
                    <Row>
                        <Col md={12} lg={12} sm={12} xs={12}>
                            <Row className="justify-content-end">
                                <Col md={5} lg={5} sm={6} xs={12}>
                                    <FormGroup className="search-box">
                                        <InputGroup className="mb-4">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-search" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                onChange={({
                                                    target: { value },
                                                }) =>
                                                    eventOnChangeSearchInput(
                                                        value
                                                    )
                                                }
                                                defaultValue={searchValue}
                                                placeholder="Cari"
                                                type="text"
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        {data.length > 0 ? (
                            <React.Fragment>
                                {data.map((item, key) => {
                                    return (
                                        <Col
                                            xl="2"
                                            lg="2"
                                            md="2"
                                            sm="3"
                                            xs="6"
                                            key={key}
                                        >
                                            <button
                                                onClick={() =>
                                                    handleProductList(item)
                                                }
                                                type="button"
                                                className="button-card"
                                            >
                                                <Card>
                                                    <div className="card-image">
                                                        <img
                                                            src={
                                                                item.image_store !==
                                                                undefined
                                                                    ? item.image_store
                                                                    : Dummy
                                                            }
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                    <div className="p-2">
                                                        <div className="text-center">
                                                            <h6>{item.name}</h6>
                                                            <span className="city">
                                                                {item.city}
                                                            </span>
                                                            {/* <Button
                                                            type="buton"
                                                            color="primary"
                                                            onClick={() =>
                                                                handleProductList(
                                                                    item.product_list
                                                                )
                                                            }
                                                        >
                                                            Lihat Produk
                                                        </Button> */}
                                                        </div>
                                                    </div>
                                                </Card>
                                            </button>
                                        </Col>
                                    );
                                })}
                                <ProductList
                                    setShow={setShow}
                                    defaultLimitData={
                                        DEFAULT_LIMIT_DATA_PRODUCT
                                    }
                                    paramsProduct={paramsProduct}
                                    getDataProduct={getDataProduct}
                                    isLoading={isLoading}
                                    configTable={configTable}
                                    dataProductSupplier={dataProduct}
                                    setProductList={setProductList}
                                    dataProductReseller={product}
                                    store={productList}
                                    productList={productList.product_list}
                                    toggle={showProduct}
                                    isShow={isShow}
                                />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Col xl="3" lg="3" md="4" sm="6">
                                    <SkeletonLoading
                                        height="300px"
                                        width="100%"
                                    />
                                </Col>
                                <Col xl="3" lg="3" md="4" sm="6">
                                    <SkeletonLoading
                                        height="300px"
                                        width="100%"
                                    />
                                </Col>
                                <Col xl="3" lg="3" md="4" sm="6">
                                    <SkeletonLoading
                                        height="300px"
                                        width="100%"
                                    />
                                </Col>
                                <Col xl="3" lg="3" md="4" sm="6">
                                    <SkeletonLoading
                                        height="300px"
                                        width="100%"
                                    />
                                </Col>
                            </React.Fragment>
                        )}
                    </Row>
                </CardBody>
            </InfiniteScroll>
        </Style>
    );
};
export default SupplierList;
