import { servicesAction } from "../../../Reducers";

const service = {
  updateProfile: ({ dispatch, data }) => {
    servicesAction(dispatch).reduxFetch({
      url: "/profile",
      method: "PUT",
      token: true,
      data: data,
      reducer: "service",
      group: "reseller",
      key: "profile",
      message: {
        200: {
          mode: {
            use: "alert",
            type: "success",
            timeOut: 3000,
          },
          text: "Profile berhasil diperbaharui",
        },
        401: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "",
        },
        400: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
        },
        500: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Internal Service Error",
        },
      },
    });
  }, 
  getProvince: ({ dispatch }) => {
    servicesAction(dispatch).reduxFetch({
      url: "/province?size=34",
      method: "GET",
      token: true,
      reducer: "service",
      qs: true,
      group: "user",
      key: "province",
      message: {
        200: {
          mode: {
            use: "alert",
            type: "success",
            timeOut: 3000,
          },
          text: "Success Get Ingredients",
        },
        401: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Password atau email salah",
        },
        400: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Password atau email salah",
        },
        500: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Internal Service Error",
        },
      },
    });
  },
  getCity: ({ dispatch, params }) => {
    servicesAction(dispatch).reduxFetch({
      url: `/city/${params}?size=100`,
      method: "GET",
      token: true,
      reducer: "service",
      group: "user",
      key: "city",
      message: {
        200: {
          mode: {
            use: "alert",
            type: "success",
            timeOut: 3000,
          },
          text: "Success Get Ingredients",
        },
        401: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Password atau email salah",
        },
        400: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Password atau email salah",
        },
        500: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Internal Service Error",
        },
      },
    });
  },
  getDistrict: ({ dispatch, params }) => {
    servicesAction(dispatch).reduxFetch({
      url: `/district/${params}?size=100`,
      method: "GET",
      token: true,
      reducer: "service",
      group: "user",
      key: "district",
      message: {
        200: {
          mode: {
            use: "alert",
            type: "success",
            timeOut: 3000,
          },
          text: "Success Get Ingredients",
        },
        401: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Password atau email salah",
        },
        400: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Password atau email salah",
        },
        500: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Internal Service Error",
        },
      },
    });
  },
  getArea: ({ dispatch, params }) => {
    servicesAction(dispatch).reduxFetch({
      url: `/subdistrict/${params}?size=100`,
      method: "GET",
      token: true,
      reducer: "service",
      group: "user",
      key: "area",
      message: {
        200: {
          mode: {
            use: "alert",
            type: "success",
            timeOut: 3000,
          },
          text: "Success Get Ingredients",
        },
        401: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Terjadi Kesalahan, harap mencoba beberapa saat lagi",
        },
        400: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Terjadi Kesalahan, harap mencoba beberapa saat lagi",
        },
        500: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Internal Service Error",
        },
      },
    });
  },
  postBank: ({ dispatch, data }) => {
    servicesAction(dispatch).reduxFetch({
      url: "/bank/new",
      method: "POST",
      token: true,
      data: data,
      reducer: "service",
      group: "reseller",
      key: "bank",
      message: {
        200: {
          mode: {
            use: "alert",
            type: "success",
            timeOut: 3000,
          },
          text: "Success Get Ingredients",
        },
        401: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Password atau email salah",
        },
        400: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Password atau email salah",
        },
        500: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Internal Service Error",
        },
      },
    });
  },
  updateBank: ({ dispatch, data }) => {
    servicesAction(dispatch).reduxFetch({
      url: "/bank/update",
      method: "PUT",
      token: true,
      data: data,
      reducer: "service",
      group: "reseller",
      key: "bank",
      message: {
        200: {
          mode: {
            use: "alert",
            type: "success",
            timeOut: 3000,
          },
          text: "Success Get Ingredients",
        },
        401: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Terjadi kesalahan, harap pencoba beberapa saat lagi",
        },
        400: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "",
        },
        500: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Internal Service Error",
        },
      },
    });
  },
  updatePasswordReseller: ({ dispatch, data }) => {
    servicesAction(dispatch).reduxFetch({
      url: "/password/change",
      method: "POST",
      token: true,
      data: data,
      reducer: "service",
      group: "user",
      key: "passwordChange",
      message: {
        200: {
          mode: {
            use: "alert",
            type: "success",
            timeOut: 3000,
          },
          text: "Success Get Ingredients",
        },
        401: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Terjadi kesalahan, harap pencoba beberapa saat lagi",
        },
        400: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "",
        },
        500: {
          mode: {
            use: "alert",
            type: "danger",
            timeOut: 3000,
          },
          text: "Internal Service Error",
        },
      },
    });
  },
  passwordClear: ({ dispatch }) => {
    servicesAction(dispatch).reduxClear({
      reducer: "service",
      group: "user",
      key: "passwordChange",
      type: "CLEAR",
    });
  },
  bankUpdateClear: ({ dispatch }) => {
    servicesAction(dispatch).reduxClear({
      reducer: "service",
      group: "reseller",
      key: "bank",
      type: "CLEAR",
    });
  },
  updateProfileClear: ({ dispatch }) => {
    servicesAction(dispatch).reduxClear({
      reducer: "service",
      group: "reseller",
      key: "profile",
      type: "CLEAR",
    });
  },
  getClearProvince: ({ dispatch }) => {
    servicesAction(dispatch).reduxClear({
      reducer: "service",
      group: "user",
      key: "province",
      type: "CLEAR",
    });
  },
  getClearCity: ({ dispatch }) => {
    servicesAction(dispatch).reduxClear({
      reducer: "service",
      group: "user",
      key: "city",
      type: "CLEAR",
    });
  },
  getClearDistrict: ({ dispatch }) => {
    servicesAction(dispatch).reduxClear({
      reducer: "service",
      group: "user",
      key: "district",
      type: "CLEAR",
    });
  },
  getClearArea: ({ dispatch }) => {
    servicesAction(dispatch).reduxClear({
      reducer: "service",
      group: "user",
      key: "area",
      type: "CLEAR",
    });
  },
};
export default service;
