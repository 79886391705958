import styled from "styled-components";
import { color } from "../../../../Utils/Variable";

const Style = styled.div`
    .card {
        margin-bottom: 30px;
        border: unset;
        display: flex;
        flex-direction: column;
        background: #fff;
        .balance {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 767px) {
                position: unset;
                justify-content: start;
            }
        }
        .catalog {
            &-header {
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media (max-width: 450px) {
                    display: block;
                    .title {
                        margin-bottom: 15px;
                        text-align: center;
                    }
                }
            }
            &-button {
                display: flex;
                @media (max-width: 450px) {
                    justify-content: space-between;
                }
            }
        }
        .sub-title {
            color: #aaa;
            font-size: 14px;
            padding-bottom: 5px;
            margin-bottom: 17px;
            border-bottom: 1px solid #ddd;
        }
        .text {
            &-provisions {
                font-size: 12px;
            }
            &-balance {
                font-size: 30px;
            }
            &-location {
                margin: 0;
            }
        }
        .location {
            display: flex;
            align-items: center;
        }
    }
    .btn {
        padding: 7px 20px;
        border-radius: 5px;
    }
    .label-specification {
        color: #aaa;
        font-size: 14px;
        padding-bottom: 5px;
        margin-bottom: 17px;
    }
    .fa-trash-alt {
        transition: all 0.25s ease;
        color: #888888;
        &:hover {
            color: #dc3545;
        }
    }
    .null-product {
        color: #aaa;
        font-weight: bold;
        text-align: center;
        width: 100%;
        margin: 50px 0;
        @media (max-width: 575px) {
            .img-fluid {
                width: 200px;
            }
            font-size: 16px;
        }
    }
    .pagination-table {
        .active {
            .page-link {
                background: ${color.primary};
                color: #fff !important;
            }
        }
    }
`;
export default Style;
