import React from "react";
import { Button } from "reactstrap";
import { formatMoney } from "../../../../Utils/PriceConverter";
// import PaymentMethod from "./PaymentMethod";
import { color } from "../../../../Utils/Variable";
// import InsuranceCheck from "../Detail/Delivery";
import ArrowStepBack from "../../../../Components/Assets/icon/arrow-stepback.svg";
import CouponIcon from "../../../../Components/Assets/icon/coupon.svg"
import checkMobile from "../../../../Utils/CheckMobile";

const CardBill = ({
    voucher = false,
    priceInsurance = false,
    setMobilePayment = () => {},
    mobilePayment = false,
    shipperList = false,
    courier = "",
    paymentChannel = false,
    // shipperUsed = false,
    biteshipUsed = false,
    dataOrder = false,
    productInfo = false,
    setVoucher = () => {},
    voucherList = [],
    digital = false,
    setPaymentChannel = () => {},
    checkCourier = false,
    handleOrder = () => {},
    courierRate = false,
    selectCourier = false,
    setPriceInsurance = () => {},
    insuranceShow = false,
    handleInsuranceChecklist = () => {},
    handleOrderPayment = () => {},
    voucherUsed = false,
    handleStepBack = () => {},
    handleCheckInsurance = () => {},
}) => {
    return (
        <React.Fragment>
            <div className="accordion-area" id="bill">
                {/* {checkMobile() === true ?
                    <div style={{ marginTop: "16px", borderBottom:"1.5px solid #E8E8E8", borderTop: "1.5px solid #E8E8E8", padding: 8, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div className="d-flex">
                            <p style={{ margin: "8px 4px" }} className="font-semibold">Asuransi</p>
                            <p style={{ margin: "8px 4px" }}>({formatMoney(priceInsurance?.insuranceRate)})</p>
                        </div>
                        {priceInsurance.compulsory_insurance === 1 ?
                            <div class="round my-auto">
                                <input type="checkbox" id="checkbox" checked="checked"/>
                                <label for="checkbox"></label>
                            </div>
                            :
                            <div class="round my-auto">
                                <input type="checkbox" id="checkbox" disabled={priceInsurance === false ? true : false} onClick={handleInsuranceChecklist}/>
                                <label for="checkbox"></label>
                            </div>
                        }
                    </div>
                :
                    checkCourier === false ? <div style={{ border: "1.5px solid #E8E8E8", borderRadius: 20, padding: 8, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <p style={{ margin: "8px 4px" }}>Asuransi pengiriman</p>
                        <p style={{ margin: "8px 4px" }}>{formatMoney(priceInsurance?.insuranceRate)}</p>
                        {priceInsurance.compulsory_insurance === 1 ?
                            (<input type="checkbox" checked="checked" style={{ width: 20, height: 20, margin: "10px 6px 0 0" }}/>)
                            :
                            (<input type="checkbox" disabled={priceInsurance === false ? true : false} onClick={handleInsuranceChecklist} style={{ width: 20, height: 20, margin: "8px 4px" }}></input>)
                        }
                    </div>
                    :
                    <div onClick={handleStepBack} style={{ border:"1px solid #C4C4C4", color:"#C4C4C4", maxWidth:220, marginLeft:"auto", padding:8, borderRadius:8, justifyContent:"space-around", display:"flex" }}><img src={ArrowStepBack} alt="" /> Step Sebelumnya</div>
                } */}
                <div className="accordion-area-price sm-px-3">
                    {productInfo && (
                        <div className="bill-price my-2 mx-3">
                            <img src={productInfo?.product?.product_image[0].img_url} alt="" style={{ width: 80, height: 80, borderRadius: 20 }} />
                            <div style={{ margin: "0 auto", width:"calc(2/3*100%)" }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <h6 style={{ fontWeight: 700 }}>{productInfo?.product?.name}</h6>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <p style={{ margin: 0 }}>Jumlah</p>
                                    <p style={{ margin: 0, fontWeight: 600 }}>X{dataOrder?.total}</p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <p style={{ margin: 0 }}>Per-Item</p>
                                    <p style={{ margin: 0, fontWeight: 600 }}>{formatMoney(productInfo?.product?.price)}</p>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* {digital === true || checkCourier === true ? (
                        <React.Fragment>
                            {voucherList.length > 0 && (
                                <Voucher
                                    voucher={voucher}
                                    setVoucher={setVoucher}
                                    data={voucherList}
                                />
                            )}
                        </React.Fragment>
                    ) : (
                        false
                    )} */}
                    {checkCourier === true && voucherUsed?.status === true && <div style={{ margin: 16 }}>
                        <div style={{ borderTop: "1.5px solid #E8E8E8"}} />
                        <div style={{display:"flex", justifyContent:"space-between", margin: "16px 2px" }} >
                        {voucherUsed?.name} 
                        <img src={CouponIcon} alt=""/>
                        </div>
                    </div>}
                    <div style={{ borderBottom: "1.5px solid #E8E8E8", margin: "0 16px" }} />
                    <div className="mx-3">
                        <h6 style={{ margin: "16px 0"}} className="font-bold h4">Ringkasan Pembayaran</h6>
                        <div className="price-detail">
                            <p>Total harga</p>
                            { checkMobile() === true ?
                                <p style={{color:"#000A8C"}} className="font-regular h6">{formatMoney(productInfo?.product?.price * dataOrder?.total)}</p>
                                :
                                <p>{formatMoney(productInfo?.product?.price * dataOrder?.total)}</p>
                            }
                        </div>
                        <div className="price-detail">
                            <p>Ongkos Kirim </p>
                            { checkMobile() === true ?
                                <p style={{color:"#000A8C"}} className="font-regular h6">{formatMoney(courierRate)}</p>
                                :
                                <p>{formatMoney(courierRate)}</p>
                            }
                        </div>
                        <div className="price-detail">
                            <p>Asuransi </p>
                            { checkMobile() === true ?
                                insuranceShow === true ? <p style={{color:"#000A8C"}} className="font-regular h6">{formatMoney(priceInsurance?.insuranceRate)}</p> : <p style={{color:"#000A8C"}} className="font-regular h6">{formatMoney(0)}</p>
                                :
                                insuranceShow === true ? <p>{formatMoney(priceInsurance?.insuranceRate)}</p> : <p>{formatMoney(0)}</p>
                            }
                        </div>
                        {/* biaya admin */}
                    <div className="price-detail">
                        {paymentChannel && (
                            <React.Fragment>
                                <div>
                                    <p>Biaya Admin</p>
                                </div>
                                
                                { checkMobile() === true ?
                                    <p style={{color:"#000A8C"}} className="font-regular h6">
                                        {paymentChannel.percentages === 0
                                            ? formatMoney(
                                                paymentChannel.fee
                                            )
                                            : formatMoney((productInfo?.product?.price * dataOrder?.total * paymentChannel.percentages) / 100)}
                                    </p>
                                    :
                                    <p>
                                        {paymentChannel.percentages === 0
                                            ? formatMoney(
                                                paymentChannel.fee
                                            )
                                            : formatMoney((productInfo?.product?.price * dataOrder?.total * paymentChannel.percentages) / 100)}
                                    </p>
                                }
                            </React.Fragment>
                            )}
                    </div>
                        {voucher && (
                            <div className="bill-price">
                                <div>
                                    <span className="mb-0 bill-price__text">
                                        {voucher.name}
                                    </span>
                                </div>
                                <div className="price" style={{ color: color.primary }}>
                                    {formatMoney(
                                        (biteshipUsed.price / 100) *
                                            Number(voucher.discount_percentages) >
                                            voucher.max_discount
                                            ? voucher.max_discount -
                                            voucher.max_discount * 2
                                            : (biteshipUsed.price / 100) *
                                            Number(voucher.discount_percentages) -
                                            (biteshipUsed.price / 100) *
                                            Number(
                                                voucher.discount_percentages
                                            ) *
                                            2
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                { checkMobile() === true ?
                    <div style={{borderBottom:"1.5px solid #E8E8E8", padding:"8px 0", width:"70%", margin:"0 auto"}}/>
                :
                    false
                }
                   { checkMobile() === false && <div>
                        <div style={{ borderBottom: "1.5px solid #E8E8E8", margin: "0 16px" }} />
                        <div className="bill-price" style={{ margin: "16px 0" }}>
                            <div>
                                <h6 className="mb-0 font-weight-bold" style={{fontSize: 16}}>Total Pembayaran</h6>
                            </div>
                            {priceInsurance && insuranceShow === true ? (
                                <div className="price-total">
                                    {voucher
                                        ? paymentChannel
                                            ? paymentChannel.percentages !== 0
                                                ? formatMoney(
                                                    dataOrder?.total *
                                                    productInfo?.product?.price +
                                                    (biteshipUsed.price || 0) +
                                                    ((dataOrder?.total *
                                                        productInfo?.product
                                                            ?.price) /
                                                        100) *
                                                    paymentChannel.percentages +
                                                    priceInsurance.insuranceRate +
                                                    (((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                        Number(
                                                            voucher.discount_percentages
                                                        ) >
                                                        voucher.max_discount
                                                        ? voucher.max_discount -
                                                        voucher.max_discount * 2
                                                        : ((biteshipUsed.price ||
                                                            0) /
                                                            100) *
                                                        Number(
                                                            voucher.discount_percentages
                                                        ) -
                                                        ((biteshipUsed.price ||
                                                            0) /
                                                            100) *
                                                        Number(
                                                            voucher.discount_percentages
                                                        ) *
                                                        2)
                                                )
                                                : formatMoney(
                                                    dataOrder?.total *
                                                    productInfo?.product?.price +
                                                    (biteshipUsed.price || 0) +
                                                    paymentChannel.fee +
                                                    priceInsurance.insuranceRate +
                                                    (((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                        Number(
                                                            voucher.discount_percentages
                                                        ) >
                                                        voucher.max_discount
                                                        ? voucher.max_discount -
                                                        voucher.max_discount * 2
                                                        : ((biteshipUsed.price ||
                                                            0) /
                                                            100) *
                                                        Number(
                                                            voucher.discount_percentages
                                                        ) -
                                                        ((biteshipUsed.price ||
                                                            0) /
                                                            100) *
                                                        Number(
                                                            voucher.discount_percentages
                                                        ) *
                                                        2)
                                                )
                                            : formatMoney(
                                                dataOrder?.total *
                                                productInfo?.product?.price +
                                                (biteshipUsed.price || 0) +
                                                priceInsurance.insuranceRate +
                                                (((biteshipUsed.price || 0) /
                                                    100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) >
                                                    voucher.max_discount
                                                    ? voucher.max_discount -
                                                    voucher.max_discount * 2
                                                    : ((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) -
                                                    ((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) *
                                                    2)
                                            )
                                        : paymentChannel
                                            ? paymentChannel.percentages !== 0
                                                ? formatMoney(
                                                    dataOrder?.total *
                                                    productInfo?.product?.price +
                                                    (biteshipUsed.price || 0) +
                                                    ((dataOrder?.total *
                                                        productInfo?.product?.price) /
                                                        100) *
                                                    paymentChannel.percentages +
                                                    priceInsurance.insuranceRate
                                                )
                                                : formatMoney(
                                                    dataOrder?.total *
                                                    productInfo?.product?.price +
                                                    (biteshipUsed.price || 0) +
                                                    paymentChannel.fee +
                                                    priceInsurance.insuranceRate
                                                )
                                            : formatMoney(
                                                dataOrder?.total *
                                                productInfo?.product?.price +
                                                (biteshipUsed.price || 0) +
                                                priceInsurance.insuranceRate
                                            )}
                                </div>
                            ) : voucher ? (
                                paymentChannel ? (
                                    paymentChannel.percentages !== 0 ? (
                                        <div className="price-total">
                                            {priceInsurance && insuranceShow === true
                                                ? formatMoney(
                                                    dataOrder?.total *
                                                    productInfo?.product?.price +
                                                    (biteshipUsed.price || 0) +
                                                    ((dataOrder?.total *
                                                        productInfo?.product
                                                            ?.price) /
                                                        100) *
                                                    paymentChannel.percentages +
                                                    priceInsurance.insuranceRate +
                                                    (((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                        Number(
                                                            voucher.discount_percentages
                                                        ) >
                                                        voucher.max_discount
                                                        ? voucher.max_discount -
                                                        voucher.max_discount * 2
                                                        : ((biteshipUsed.price ||
                                                            0) /
                                                            100) *
                                                        Number(
                                                            voucher.discount_percentages
                                                        ) -
                                                        ((biteshipUsed.price ||
                                                            0) /
                                                            100) *
                                                        Number(
                                                            voucher.discount_percentages
                                                        ) *
                                                        2)
                                                )
                                                : formatMoney(
                                                    dataOrder?.total *
                                                    productInfo?.product?.price +
                                                    (biteshipUsed.price || 0) +
                                                    ((dataOrder?.total *
                                                        productInfo?.product
                                                            ?.price) /
                                                        100) *
                                                    paymentChannel.percentages +
                                                    (((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                        Number(
                                                            voucher.discount_percentages
                                                        ) >
                                                        voucher.max_discount
                                                        ? voucher.max_discount -
                                                        voucher.max_discount * 2
                                                        : ((biteshipUsed.price ||
                                                            0) /
                                                            100) *
                                                        Number(
                                                            voucher.discount_percentages
                                                        ) -
                                                        ((biteshipUsed.price ||
                                                            0) /
                                                            100) *
                                                        Number(
                                                            voucher.discount_percentages
                                                        ) *
                                                        2)
                                                )}
                                        </div>
                                    ) : priceInsurance && insuranceShow === true ? (
                                        <div className="price-total">
                                            {formatMoney(
                                                dataOrder?.total *
                                                productInfo?.product?.price +
                                                (biteshipUsed.price || 0) +
                                                paymentChannel.fee +
                                                priceInsurance.insuranceRate +
                                                (((biteshipUsed.price || 0) /
                                                    100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) >
                                                    voucher.max_discount
                                                    ? voucher.max_discount -
                                                    voucher.max_discount * 2
                                                    : ((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) -
                                                    ((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) *
                                                    2)
                                            )}
                                        </div>
                                    ) : (
                                        <div className="price-total">
                                            {formatMoney(
                                                dataOrder?.total *
                                                productInfo?.product?.price +
                                                (biteshipUsed.price || 0) +
                                                paymentChannel.fee +
                                                (((biteshipUsed.price || 0) /
                                                    100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) >
                                                    voucher.max_discount
                                                    ? voucher.max_discount -
                                                    voucher.max_discount * 2
                                                    : ((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) -
                                                    ((biteshipUsed.price ||
                                                        0) /
                                                        100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) *
                                                    2)
                                            )}
                                        </div>
                                    )
                                ) : biteshipUsed ? (
                                    <div className="price-total">
                                        {formatMoney(
                                            dataOrder?.total *
                                            productInfo?.product?.price +
                                            (biteshipUsed.price || 0) +
                                            (((biteshipUsed.price || 0) / 100) *
                                                Number(
                                                    voucher.discount_percentages
                                                ) >
                                                voucher.max_discount
                                                ? voucher.max_discount -
                                                voucher.max_discount * 2
                                                : ((biteshipUsed.price || 0) /
                                                    100) *
                                                Number(
                                                    voucher.discount_percentages
                                                ) -
                                                ((biteshipUsed.price || 0) /
                                                    100) *
                                                Number(
                                                    voucher.discount_percentages
                                                ) *
                                                2)
                                        )}
                                    </div>
                                ) : (
                                    <div className="price-total">
                                        {formatMoney(
    // Harga barang x total barang dibeli + biaya pengiriman + (Harga barang x total barang dibeli / 100) x persentase pembayaran + biaya asuransi
                                            dataOrder?.total *
                                            productInfo?.product?.price +
                                            (((biteshipUsed.price || 0) / 100) *
                                                Number(
                                                    voucher.discount_percentages
                                                ) >
                                                voucher.max_discount
                                                ? voucher.max_discount -
                                                voucher.max_discount * 2
                                                : ((biteshipUsed.price || 0) /
                                                    100) *
                                                Number(
                                                    voucher.discount_percentages
                                                ) -
                                                ((biteshipUsed.price || 0) /
                                                    100) *
                                                Number(
                                                    voucher.discount_percentages
                                                ) *
                                                2)
                                        )}
                                    </div>
                                )
                            ) : paymentChannel ? (
                                paymentChannel.percentages !== 0 ? (
    // Harga barang x total barang dibeli + biaya pengiriman + (Harga barang x total barang dibeli / 100) x persentase pembayaran + biaya asuransi
                                    <div className="price-total">
                                        {priceInsurance && insuranceShow === true
                                            ? formatMoney(
                                                dataOrder?.total *
                                                productInfo?.product?.price +
                                                (biteshipUsed.price || 0) +
                                                ((dataOrder?.total *
                                                    productInfo?.product?.price) /
                                                    100) *
                                                paymentChannel.percentages +
                                                priceInsurance.insuranceRate
                                            )
    // Harga barang x total barang dibeli + biaya pengiriman + (Harga barang x total barang dibeli / 100) x persentase pembayaran
                                            : formatMoney(
                                                dataOrder?.total *
                                                productInfo?.product?.price +
                                                (biteshipUsed.price || 0) +
                                                ((dataOrder?.total *
                                                    productInfo?.product?.price) /
                                                    100) *
                                                paymentChannel.percentages
                                            )}
                                    </div>
                                ) : priceInsurance && insuranceShow === true ? (
    // Harga barang x total barang dibeli + biaya pengiriman + fee payment channel + biaya asuransi
                                    <div className="price-total">
                                        {formatMoney(
                                            dataOrder?.total *
                                            productInfo?.product?.price +
                                            (biteshipUsed.price || 0) +
                                            paymentChannel.fee +
                                            priceInsurance.insuranceRate
                                        )}
                                    </div>
                                ) : (
            // Harga barang x total barang dibeli + biaya pengiriman + fee payment channel
                                    <div className="price-total">
                                        {formatMoney(
                                            dataOrder?.total *
                                            productInfo?.product?.price +
                                            (biteshipUsed.price || 0) +
                                            paymentChannel.fee
                                        )}
                                    </div>
                                )
                            ) : biteshipUsed ? (
                    // Harga barang x total barang dibeli + biaya pengiriman
                                <div className="price-total">
                                    {formatMoney(
                                        dataOrder?.total * productInfo?.product?.price +
                                        (biteshipUsed.price || 0)
                                    )}
                                </div>
                            ) : (
                                // Harga barang x total barang dibeli
                                <div className="price-total">
                                    {formatMoney(
                                        dataOrder?.total * productInfo?.product?.price
                                    )}
                                </div>
                            )}
                        </div>
                        <div style={{ borderBottom: "1.5px solid #E8E8E8", margin: "0 16px" }} />
                    </div>}
                    <div className="bill-price">
                        {paymentChannel === false && checkCourier === false && <Button
                            type="button"
                            onClick={checkCourier === false ? handleOrder : handleOrderPayment}
                            style={{ 
                                width: "100%", 
                                margin: "16px 0", 
                                padding: "8px 0", 
                                borderRadius: 8,
                                color: "#FFFFFF",
                                background: selectCourier === false ? "rgba(0, 10, 140, 0.4)" : "#000A8C", 
                                fontWeight: 600, border: "none" }}
                            disabled={selectCourier === false ? true : false}
                        >
                            Bayar
                        </Button>}
                        {selectCourier !== false && checkCourier !== false && <Button
                            type="button"
                            onClick={checkCourier === false ? handleOrder : handleOrderPayment}
                            style={{ 
                                width: "100%", 
                                margin: "16px 0", 
                                padding: "8px 0", 
                                borderRadius: 8,
                                color: "#FFFFFF",
                                background: paymentChannel === false ? "rgba(0, 10, 140, 0.4)" : "#000A8C", 
                                fontWeight: 600, border: "none" }}
                            disabled={paymentChannel === false ? true : false}
                        >
                            Bayar
                        </Button>}
                    </div>
                    {/* <div style={{ borderBottom: "1.5px solid #E8E8E8" }} /> */}
                </div>
                {/* <div style={{borderBottom:"1.5px solid #E8E8E8"}}/> */}
                {/* <div>
                        <h6>Total Pembayaran </h6>
                    </div>
                <div className="bill-price">
                    <div>
                        <span className="mb-0 bill-price__text">
                            Harga Barang
                        </span>
                    </div>
                    {productInfo && (
                        <div className="price">
                            {formatMoney(productInfo?.product?.price)}
                        </div>
                    )}
                </div> */}
                {/* <div className="bill-price">
                    <div>
                        <span className="mb-0 bill-price__text">
                            Total Harga Barang ({dataOrder?.total} item)
                        </span>
                    </div>
                    {dataOrder && (
                        <div className="price" style={{ color: color.primary }}>
                            {formatMoney(
                                dataOrder?.total * productInfo?.product?.price
                            )}
                        </div>
                    )}
                </div> */}
                {courier !== "" && (
                    <div className="bill-price">
                        <div>
                            <span className="mb-0 bill-price__text">
                                Biaya Pengiriman
                            </span>
                        </div>
                        {dataOrder && (
                            <div className="price" style={{ color: "#F6B833" }}>
                                {formatMoney(
                                    biteshipUsed !== null || biteshipUsed !== ""
                                        ? biteshipUsed.price
                                        : "-"
                                )}
                            </div>
                        )}
                    </div>
                )}
                {/* {priceInsurance && (
                    <div className="bill-price">
                        <div>
                            <span className="mb-0 bill-price__text">
                                Biaya Asuransi
                            </span>
                        </div>

                        <div className="price" style={{ color: color.primary }}>
                            {formatMoney(priceInsurance.insuranceRate)}
                        </div>
                    </div>
                )} */}
                
                {/* <hr /> */}
            </div>
        </React.Fragment>
    );
};
export default CardBill;
