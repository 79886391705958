/* eslint-disable */
import React, { useState } from "react";
import { Alert, Spinner } from "reactstrap";
import Button from "../../../Components/Particle/Button";
import CustomForm from "../../../Components/Molekul/FormValidation";
import Logo from "../../../Components/Assets/Images/Lakugan_Logo.png";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import LocalStorage from "../../../Utils/LocalStorage";
import PictLogin from "../../../Components/Assets/Images/sign_up_3.jpg";
import { useEffect } from "react";
import service from "./services";
import { useStore } from "../../../Reducers";
import JSON_to_URLEncoded from "../../../Utils/JSON-URLEncode";
import { color } from "../../../Utils/Variable";

const Style = styled.div`
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .lakugan-title {
        font-weight: bold;
        margin-bottom: 30px;
        @media (max-width: 1200px) {
            font-size: 20px;
        }
        @media (max-width: 767px) {
            text-align: center;
        }
    }
    .login-page {
        height: 100%;
        width: 100%;
        display: flex;
        overflow: scroll;
    }
    .btn-custom {
        border-radius: 5px;
    }
    .background-image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #4a4a4a;
    }
    .card {
        &-image {
            width: 65%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @media (max-width: 767px) {
                display: none;
            }
        }
        &-form {
            padding: 20px;
            display: flex;
            align-items: center;
            height: 100%;
            width: 35%;
            form {
                width: 100%;
            }
            .lakugan-logo {
                width: 150px;
                margin-bottom: 30px;
                @media (max-width: 767px) {
                    display: block;
                    margin: 0 auto 20px;
                }
            }
            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }
    .register {
        display: block;
        margin-top: 10px;
        font-size: 14px;
        a {
            color: ${color.lakugan};
        }
        @media (max-width: 450px) {
            font-size: 12px;
        }
    }
`;

const Login = () => {
    const token = LocalStorage().get("auth");
    const auth = LocalStorage().get(`${token?.access_token}`);
    const [isFirstGet, setFirstGet] = useState(true);
    const [validationClick, setValidationClick] = useState(true);
    const [validateForm, setValidateForm] = useState(true);
    const [isFailed, setFailed] = useState(false);
    const history = useHistory();
    const [message, setMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const { dispatch, state } = useStore();
    // form login
    const [form, setForm] = useState([
        {
            type: "email",
            action: "email",
            required: true,
            name: "email",
            label: "Email",
            col: 12,
            valid: "Email tidak boleh kosong",
            value: "",
            status: false,
        },
        {
            type: "password",
            action: "password",
            required: true,
            name: "password",
            label: "Kata Sandi",
            col: 12,
            valid: "Kata sandi tidak boleh kosong",
            value: "",
            status: false,
        },
    ]);
    // function submit data login
    const handlerSubmitData = (e) => {
        setFailed(false);
        setMessage(false);
        let isError = false;
        e.preventDefault();
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setLoading(true);
            let formData = {
                username: form[0].value,
                password: form[1].value,
                grant_type: "password",
                client_id: "my-client-id",
                client_secret: "password",
            };
            let data = JSON_to_URLEncoded(formData);
            service.token({ dispatch, data });
            setFailed(false);
            setTimeout(() => {
                setLoading(false);
                setFailed(true);
            }, 60000);
        }
    };
    // store state response API
    useEffect(() => {
        if (state?.user?.token?.isSuccess) {
            LocalStorage().save("auth", state.user.token.data);
            service.profile({ dispatch });
            setFailed(false);
        }
        if (state?.user?.profile?.isSuccess) {
            const token = LocalStorage().get("auth");
            LocalStorage().save(
                `${token.access_token}`,
                state.user.profile.data
            );
            setFailed(false);
            setLoading(false);
            history.push("/reseller/home");
        }
        if (state?.user?.token?.isError) {
            setLoading(false);
            setFailed(false);
            setMessage(state.user.token.data.message);
        }
        if (state?.user?.profile?.isError) {
            setLoading(false);
            setFailed(false);
            setMessage(state.user.profile.data.message);
        }
    }, [state.user.token]);
    useEffect(() => {
        if (state?.user?.profile?.isSuccess) {
            const token = LocalStorage().get("auth");

            LocalStorage().save(
                `${token.access_token}`,
                state.user.profile.data
            );
            // SETUP EXPIRED LOCAL STORAGE (TERHAPUS OTOMATIS DALAM WAKTU 5 JAM)
            const ex_date = new Date();
            LocalStorage().save(
                "EX",
                `${new Date(ex_date.getTime() + 5 * 60 * 60 * 1000)}`
            );
            setLoading(false);

            if (
                state.user.profile.data.user.authorities[0].name ===
                "ROLE_RESELLER"
            ) {
                history.push("/reseller/home");
            }
            if (
                state.user.profile.data.user.authorities[0].name ===
                "ROLE_SUPPLIER"
            ) {
                history.push("/supplier/home");
            }
        }
    }, [state.user.profile]);
    useEffect(() => {
        if (isFirstGet) {
            if (auth !== null || token !== null) {
                setFirstGet(false);
                window.location = "/";
            }
        }
    }, [isFirstGet]);
    return (
        <Style id="login">
            <div className="login-page">
                <div className="card-image">
                    <img src={PictLogin} alt="" className="img-fluid" />
                </div>
                <div className="card-form">
                    <form onSubmit={handlerSubmitData}>
                        <Link to="/">
                            <img
                                src={Logo}
                                alt="logo"
                                className="img-fluid lakugan-logo"
                            />
                        </Link>
                        <h2 className="lakugan-title">
                            Mulai berjualan #BisaBisnisTanpaModal
                        </h2>
                        {message && <Alert color="danger">{message}</Alert>}
                        {isFailed && !message && (
                            <Alert color="danger" className="text-center">
                                Terjadi kesalahan, harap coba beberapa menit
                                lagi, Terimakasih
                            </Alert>
                        )}

                        <CustomForm
                            setForm={setForm}
                            validateForm={validateForm}
                            validationClick={validationClick}
                            form={form}
                        />
                        <Button
                            block
                            color="primary"
                            type="submit"
                            className="btn-custom"
                            disabled={loading}
                        >
                            {loading ? <Spinner color="light" /> : "Masuk"}
                        </Button>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <span className="register">
                                <Link to="/forgot-password">
                                    Lupa kata sandi
                                </Link>
                            </span>
                            <span className="register">
                                <Link to="/signup/reseller">
                                    Belum punya akun?
                                </Link>
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </Style>
    );
};
export default Login;
