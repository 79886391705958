import React from "react";
import {
    Collapse,
    InputGroupText,
    InputGroup,
    Input,
    InputGroupAddon,
    Row,
    Col,
} from "reactstrap";
import styled from "styled-components";
import Logo from "./../../../../Components/Assets/icon/Lakugan_Logo_Square-2.png";
import Button from "../../../../Components/Particle/Button";
import { color } from "../../../../Utils/Variable";
const CheckedStyle = styled.div`
    .container {
        display: block;
        position: relative;
        padding-left: 27px;
        cursor: pointer;
        font-size: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: #eee;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
        background-image: url(${Logo});
        background-repeat: no-repeat;
        background-size: contain;
    }
`;
const CategoryList = ({
    item,
    setSortFilterCity = () => {},
    onSortFilterCity = [],
    cityFilters = [],
    setCityFilters = () => {},
    style = {
        row: false,
    },
}) => {
    const [isActive, setActive] = React.useState(0);
    const handleFilterCity = (e, item) => {
        if (isActive === 0) {
            setCityFilters((prevstate) => {
                return cityFilters.length < 1
                    ? [...prevstate, item.name]
                    : [...prevstate, item.name];
            });
            setSortFilterCity((prevstate) => {
                return onSortFilterCity.length < 1
                    ? [...prevstate, `["supplier.city","like","${item.name}"]`]
                    : [
                          ...prevstate,
                          `["or"],["supplier.city","like","${item.name}"]`,
                      ];
            });
            setActive(item.name);
        } else {
            const removeCity = cityFilters.filter(function (value) {
                let withOr = value !== item.name;
                let notWithOr = value !== item.name;
                return notWithOr ? withOr : notWithOr;
            });
            const removeFilter = onSortFilterCity.filter(function (value) {
                let withOr =
                    value !== `["supplier.city","like","${item.name}"]`;
                let notWithOr =
                    value !== `["or"],["supplier.city","like","${item.name}"]`;
                return notWithOr ? withOr : notWithOr;
            });
            setCityFilters(removeCity);
            setSortFilterCity(removeFilter);
            setActive(0);
        }
    };
    return (
        <div className={style.row ? "mb-3" : "filter-value"}>
            <CheckedStyle>
                <label className="container">
                    <input
                        checked={
                            cityFilters.filter((value) => value === item.name)
                                .length === 1
                                ? "active"
                                : ""
                        }
                        type="checkbox"
                        onChange={(e) => handleFilterCity(e, item)}
                    />
                    <span className="checkmark"></span>
                    {item.name}
                </label>
            </CheckedStyle>
            {/* <button
                type="button"
                className={`btn btn-filter ${
                    cityFilters.filter((value) => value === item.name)
                        .length === 1
                        ? "active"
                        : ""
                }`}
                onClick={() => handleFilterCity(item)}
            >
                {item.name}
            </button> */}
        </div>
    );
};
const City = ({
    city = [],
    allCity = [],
    openCity = true,
    cityFilters = [],
    searchValueCity = "",
    onSortFilterCity = [],
    showAllCity = false,
    handleCloseCity = () => {},
    handlerSearchCity = () => {},
    setShowAllCity = () => {},
    handleGetAllCity = () => {},
    setCityFilters = () => {},
    setOpenCity = () => {},
    setSortFilterCity = () => {},
}) => {
    return (
        <div
            style={{
                borderBottom: "1px solid #ddd",
                position: "relative",
            }}
        >
            {showAllCity && (
                <div className="popup-category">
                    <div className="popup-category__head">
                        <div
                            style={{
                                marginRight: 10,
                            }}
                        >
                            Kota
                        </div>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-search" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                // ref={inputEl}
                                onChange={({ target: { value } }) =>
                                    handlerSearchCity(value)
                                }
                                defaultValue={searchValueCity}
                                placeholder="Cari"
                                type="text"
                            />
                        </InputGroup>
                    </div>
                    <div className="popup-category__section">
                        <Row>
                            {allCity &&
                                allCity.map((item, key) => {
                                    return (
                                        <Col xs={6} key={key}>
                                            <CategoryList
                                                style={{ row: true }}
                                                cityFilters={cityFilters}
                                                setCityFilters={setCityFilters}
                                                onSortFilterCity={
                                                    onSortFilterCity
                                                }
                                                setSortFilterCity={
                                                    setSortFilterCity
                                                }
                                                item={item}
                                            />
                                        </Col>
                                    );
                                })}
                        </Row>
                    </div>
                    <div className="popup-category__bottom">
                        <Button
                            size={"14px"}
                            color="outline-primary"
                            style={{
                                marginRight: 10,
                            }}
                            onClick={handleCloseCity}
                        >
                            Batal
                        </Button>
                        <Button
                            size={"14px"}
                            color="primary"
                            onClick={() => setShowAllCity(false)}
                        >
                            Simpan
                        </Button>
                    </div>
                </div>
            )}
            <div>
                <div
                    className={`label-category ${openCity ? "active" : ""}`}
                    onClick={() => setOpenCity(!openCity)}
                >
                    Kota
                    <i
                        className={`fas fa-angle-down ${
                            openCity ? "active" : ""
                        }`}
                    ></i>
                </div>
            </div>
            <div className="filter-list">
                <Collapse isOpen={openCity}>
                    <div className="filter-category">
                        {city &&
                            city.length > 0 &&
                            city.map((item, key) => {
                                return (
                                    key < 5 && (
                                        <CategoryList
                                            style={{ row: false }}
                                            cityFilters={cityFilters}
                                            setCityFilters={setCityFilters}
                                            onSortFilterCity={onSortFilterCity}
                                            setSortFilterCity={
                                                setSortFilterCity
                                            }
                                            key={key}
                                            item={item}
                                        />
                                    )
                                );
                            })}
                    </div>
                    <div className="text-right">
                        <button
                            onClick={handleGetAllCity}
                            className="btn"
                            style={{
                                color: color.primary,
                            }}
                        >
                            Lihat Semua
                        </button>
                    </div>
                </Collapse>
            </div>
        </div>
    );
};
export default City;
