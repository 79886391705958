import styled from "styled-components";

export const SectionTestimonial = styled.div`
  .title-testimonial {
    margin-bottom: 15px;
    color: #000000;
  }
  .card-testimoni {
    background: #ffffff;
    box-shadow: -4px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    border-color: transparent;
    margin: 10px 0;
  }
  .desc-testimonial {
    margin: 0 0 30px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .user {
    display: flex;
    align-items: center;
    .img-user {
      border-radius: 50%;
      width: 75px;
      height: 75px;
      margin-right: 10px;
      object-fit: cover;
    }
  }
  .decs-name {
    font-weight: bold;
    font-size: 14px;
  }
  .desc-company {
    font-size: 12px;
  }
`;
