import React from "react";
import Style from "../index.style";
import Frame1 from "../../../../Components/Assets/v2/frame1.png";
import Frame2 from "../../../../Components/Assets/v2/frame2.png";
import Frame3 from "../../../../Components/Assets/v2/frame3.png";

const SectionStep = () => {
  
  return (
    <Style>
        <div className="container">
            <div className="row">
                <div className="col-sm-7 col-title">
                    <ul>
                        <li>3 Langkah Awal Mulai Bisnis Tanpa Modal</li>
                    </ul>
                    <p className="subtitle-primary"><strong>Lakugan</strong> merupakan platform reseller tanpa modal yang dapat memberikan layanan kepada masyarakat untuk bisa mendapatkan penghasilan tambahan dengan cara yang mudah hanya dengan sekali share link produk.</p>
                </div>
            </div>
            <div className="row pt-5">
                <div className="col-sm-4">
                    <div className="column-frame">
                        <span className="number">1</span>
                        <img src={Frame1} alt="" />
                    </div>
                    <div className="column-frame-text">
                        <span className="frame-title">Buat Akun</span>
                        <p className="frame-subtitle">Lengkapi registrasi akun, lalu update produkmu dan mulai jualan dengan cara share link produk ke teman terdekat melalui sosial media</p>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="column-frame">
                        <span className="number">2</span>
                        <img src={Frame2} alt="" />
                    </div>
                    <div className="column-frame-text">
                        <span className="frame-title">Pilih Produk</span>
                        <p className="frame-subtitle">Kamu bisa pilih produk yang akan dijual dari Katalog <strong>Lakugan</strong>, pilih produk yang kamu butuhkan atau produk terlaris agar cuanmu makin mengalir!</p>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="column-frame">
                        <span className="number">3</span>
                        <img src={Frame3} alt="" />
                    </div>
                    <div className="column-frame-text">
                        <span className="frame-title">Dapatkan Komisi Mulai Dari 10%</span>
                        <p className="frame-subtitle">Hanya dengan modal jempol atau share link doang, kalian bisa dapatkan komisi mulai dari 10% untuk produk yang terjual</p>
                    </div>
                </div>
            </div>
        </div>
    </Style>
  );
};
export default SectionStep;
