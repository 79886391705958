import React from "react";
import { Col, Row } from "reactstrap";
import CardNew from "../../../../Components/Cards/CardNew";
import styled from "styled-components";
import SkeletonLoading from "../../../../Components/Loading/Skeleton";
import { Link } from "react-router-dom";
import { color } from "../../../../Utils/Variable";

const Style = styled.div`
  padding-bottom: 30px;
  border-bottom: 1.5px solid #e8e8e8;
  margin-bottom: 60px;
  .header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .see-all {
      font-weight: 500;
      color: ${color.primary};
      font-size: 14px;
      i {
        margin-left: 5px;
      }
      @media (max-width: 550px) {
        font-size: 12px;
      }
    }
  }
  .title {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    @media (max-width: 550px) {
      font-size: 20.58px;
      margin-bottom: 0;
    }
  }
`;
const SectionRatingProduct = ({ title = "", data = [], dataShow = 10, onGetValue = () => {} }) => {
  return (
    <Style>
      <div className="header-title">
        <h3 className="title">{title}</h3>
        <Link to="/best-product" className="see-all">
          Lihat Semua <i className="fa fa-chevron-right"></i>
        </Link>
      </div>

      {data.length > 0 ? (
        <Row>
          {data.map((item, key) => {
            return (
              key + 1 <= dataShow && (
                <Col xl={3} md={4} xs={6} key={key}>
                  <CardNew
                    onGetValue={onGetValue}
                    item={item}
                    image={item.product.product_image[0]?.img_url}
                    alt={item.product.product_image[0]?.name}
                    name={item.product.name}
                    rating={item.product.rating}
                    price={item.product.price}
                    city={item.store.city}
                    stock={item.product.total_stock_product}
                  />
                </Col>
              )
            );
          })}
        </Row>
      ) : (
        <Row>
          {[...Array(4)].map((item, key) => {
            return (
              <Col xl={3} md={3} key={key}>
                <SkeletonLoading width="100%" height="250px" />
              </Col>
            );
          })}
        </Row>
      )}
    </Style>
  );
};
export default SectionRatingProduct;
