/*eslint-disable*/

import React from "react";
import {
    Col,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Table,
} from "reactstrap";
import styled from "styled-components";
import GrowingLoading from "../../../../Components/Loading/GrowingLoading";
import { formatMoney } from "../../../../Utils/PriceConverter";
import Button from "../../../../Components/Particle/Button";
import { calculateCommission } from "../../../../Utils/CommissionCalculator";
import { color } from "../../../../Utils/Variable";
import Logo from "../../../../Components/Assets/Images/Lakugan_LogoColor_BrightBG.png";
import service from "../index.service";
import { useStore } from "../../../../Reducers";
import SkeletonLoading from "../../../../Components/Loading/Skeleton";
import MessageState from "../../../../Components/Modal/Message";
import moment from "moment";
import Rating from "../../../../Components/Particle/Rating";
import { useHistory } from "react-router-dom";
const Style = styled(Modal)`
    color: #4a4a4a;
    .title {
        color: #4a4a4a;
        width: 164px;
        font-size: 14px;
        font-weight: 500;
    }
    .desc {
        padding-bottom: 10px;
        margin-bottom: 30px;
        border-bottom: 1px dashed #4a4a4a;
        &-transaction {
            font-size: 14px;
            margin-bottom: 10px;
            &__detail {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
            }
            &__total {
                color: ${color.primary};
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: bold;
            }
        }

        &-value {
            color: ${color.primary};
            font-size: 14px;
            font-weight: 500;
            width: calc(100% - 164px);
            overflow: auto;
        }
        th,
        td {
            font-size: 14px;
        }
    }
    .order-picture {
        margin: auto;
        img {
            width: 200px;
            height: 200px;
        }
        h6 {
            font-size: 18px;
            color: #4a4a4a;
            font-weight: 500;
            margin-top: 30px;
        }
    }
    .order-title {
        font-size: 16px;
        margin-right: 10px;
    }
`;
const ShipperListStyle = styled.div`
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;

    .shipper {
        &-list {
            font-size: 14px;
            display: flex;
            align-items: center;
        }
        &-location {
            font-size: 16px;
            margin-bottom: 8px;
            font-size: 14px;
            font-weight: bold;
        }
        &-label {
            width: 150px;
        }
        &-recommended {
            color: #58aa15;
            margin-left: 10px;
        }
    }
    .shipper {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default radio button */
    .shipper input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .shipper:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .shipper input:checked ~ .checkmark {
        background-color: ${color.primary};
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .shipper input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .shipper .checkmark:after {
        top: 8px;
        left: 8px;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: white;
    }
`;

const DetailOrder = ({
    action = {
        detail: "",
    },
    setOrderIdNumber = () => {},
    orderIdNumber = "",
    setRefresh = () => {},
    isShow = false,
    toggle = () => {},
    data = false,
    setTracking = () => {},
    commissions = false,
    setOrderShipper = () => {},
}) => {
    const isCancel = false;
    const history = useHistory();
    const { detail } = action;
    const [orderIdDetail, setOrderIdDetail] = React.useState([]);
    const [listDataLongId, setListDataLongId] = React.useState([]);
    const { dispatch, state } = useStore();
    const [isModalCancel, setModalCancel] = React.useState(false);
    const [transactionDate, setTransactionDate] = React.useState("");
    const [isLoading, setLoading] = React.useState(false);
    const [totalIncome, setTotalIncome] = React.useState(0);
    const [status, setStatus] = React.useState(true);
    const [message, setMessage] = React.useState("");
    const [showMessage, setShowMessage] = React.useState(false);

    const handleStatusPickup = () => {
        toggle();
    };
    const modalCancel = (item) => {
        setModalCancel(!isModalCancel);
    };

    // const msToTime=(duration)=> {
    //     var milliseconds = Math.floor((duration % 1000) / 100),
    //       seconds = Math.floor((duration / 1000) % 60),
    //       minutes = Math.floor((duration / (1000 * 60)) % 60),
    //       hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
      
    //     hours = (hours < 10) ? "0" + hours : hours;
    //     minutes = (minutes < 10) ? "0" + minutes : minutes;
    //     seconds = (seconds < 10) ? "0" + seconds : seconds;
      
    //     return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
    //   };
    //   console.log(msToTime(300000)) 

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
      }

    const formatDate = () => {
        let date = new Date()

        return (
          [
            date.getFullYear(),
            padTo2Digits(date.getUTCMonth() + 1),
            padTo2Digits(date.getUTCDate()),
          ].join('-') +
          ' ' +
          [
            padTo2Digits(date.getUTCHours()),
            padTo2Digits(date.getUTCMinutes()),
            //padTo2Digits(date.getUTCSeconds()),
          ].join(':')
        );
      }

    const processTransaction = (item) => {
        setRefresh(false);
        setLoading(true);
        console.log(item);
        let transaction_id = item.transaction_code.split("/")[1];
        let transaction_code = item.transaction_code;
        let courrier_name = item.shipper_name;
        let delivery_date = item.created.split("T")[0];
        let product_id = item.id;
        let courrier_type = "reg";
        let datetime = new Date().toLocaleString('en-US', {
            timeZone: 'Asia/Bangkok'
        })
        let date = formatDate().split(" ")[0];
        let time = datetime.split(", ")[1].replace("AM","").replace("PM","").trim();
        //let time = datetime.split(", ")[1];
                
        let items = [
            {
                id : item.id.toString(),
                name : item.product.name, //mandatory
                image : "",
                description : item.product.name,
                value : item.product.price, //mandatory
                quantity : 1, //mandatory
                height : 1,//item.product_spec_heigt, //in CM
                length : 1, //item.product_spec_length, //in CM
                weight : 1, //item.product_spec_weight, //mandatory in grams
                width : 1, //item.product_spec_width //in CM
            }
        ]

        let exists = {
            transaction_id: transaction_id,
            transaction_code: transaction_code,
            courrier_name: courrier_name,
            courrier_type: courrier_type,
            origin_contact_name: item.product.supplier.name, 
            origin_contact_phone: item.product.supplier.phone_cs, 
            origin_address: item.product.supplier.address ? item.product.supplier.address : "",
            destination_contact_name: item.customer_detail.full_name, 
            destination_contact_phone: item.customer_detail.phone_number,
            destination_address: item.customer_detail.address,
            delivery_date: delivery_date,
            // delivery_date: delivery_date, //mandatory if delivery_type = scheduled
            // delivery_time: delivery_time, //mandatory if delivery_type = scheduled
            // delivery_type: now, //mandatory
            items: items
        }
        let payload = {
            "transaction_id":transaction_id,
            "transaction_code":transaction_code,
            "shipper_contact_name":item.product.supplier.name, 
            "shipper_contact_phone":item.product.supplier.phone_cs, 
            "shipper_contact_email":"", 
            "shipper_organization":"", 
            "origin_contact_name": item.product.supplier.name, 
            "origin_contact_phone": item.product.supplier.phone_cs, 
            "origin_address": item.product.supplier.address ? item.product.supplier.address : "",
            "origin_note":"",
            "origin_postal_code": item.product.supplier.postal_code, 
            "destination_contact_name":item.customer_detail.full_name, 
            "destination_contact_phone":item.customer_detail.phone_number,
            "destination_contact_email":item.customer_detail.email,
            "destination_address":item.customer_detail.address, 
            "destination_postal_code":item.customer_detail.zip_code, 
            "destination_note":"",
            "courier_company":courrier_name.toLowerCase(), 
            "courier_type":courrier_type, 
            "courier_insurance":"",
            "delivery_date":"", 
            "delivery_time":"",
            "delivery_type":"now",
            "order_note": "",
            "items": items

            
        //         "transaction_id":transaction_id,
        //         "transaction_code":transaction_code,
                
        //         "shipper_contact_name":item.product.supplier.name, 
        //         "shipper_contact_phone":item.product.supplier.phone_cs, 
        //         "shipper_contact_email":"biteship@test.com", 
        //         "shipper_organization":"Biteship Org Test", 
        //         "origin_contact_name": item.product.supplier.name, //mandatory
        //         "origin_contact_phone": item.product.supplier.phone_cs, //mandatory
        //         "origin_address":"Jl. Aria Putra No.88, RT.09/RW.01, Sawah Baru, Kec. Ciputat, Kota Tangerang Selatan", //mandatory
        //         "origin_note":"Deket pintu masuk STC",
        //         "origin_postal_code":15413, //mandatory
        //         // "origin_coordinate": { //mandatory if instant courier
        //         //     "latitude":"", 
        //         //     "longitude":""
        //         // },
        //         "destination_contact_name":item.customer_detail.full_name, //mandatory
        //         "destination_contact_phone":item.customer_detail.phone_number, //mandatory
        //         "destination_contact_email":item.customer_detail.email, 
        //         "destination_address":item.customer_detail.address, //mandatory
        //         "destination_postal_code":15413, //mandatory
        //         // "destination_note":"",
        //         // "destination_coordinate":{
        //         //     "latitude":"", 
        //         //     "longitude":""
        //         // },
        //         "courier_company": "jne", //courrier_name, //mandatory
        //         "courier_type":courrier_type, //mandatory
        //         // "courier_insurance":"",
        //         "delivery_date": "2023-07-07", //delivery_date, //mandatory if delivery_type = scheduled
        //         "delivery_time": "16:30", //mandatory if delivery_type = scheduled
        //         "delivery_type": "scheduled", //mandatory
        //         // "order_note": "",
        //         "items": items //mandatory
                                     
            
        }

        service.orderShipperNew({ dispatch, data: payload});
    };

    const handleCancelTransaction = (item) => {
        let transaction_split = item.transaction_code.split("/");
        let transaction_code = transaction_split[1];
        service.cancelTransaction({ dispatch, transaction_code });
    };
    const handleCallShipper = () => {
        let ex_date = new Date();
        let getDateLater = new Date(ex_date.getTime() + 1 * 60 * 60 * 1000);
        let dataPickup = {
            datePickup: moment(getDateLater).format("YYYY-MM-DD HH:mm:ss"),
            orderIds: orderIdDetail,
            orderIdLong: listDataLongId,
        };
        setRefresh(false);
        service.pickupShipper({
            dispatch,
            data: dataPickup,
            id: "",
        });
        setLoading(true);
    };
    React.useEffect(() => {
        if (state?.supplier?.transactionAddress?.isSuccess) {
            setLoading(false);
            let districtId =
                state.supplier.transactionAddress.data[
                    state.supplier.transactionAddress.data.length - 1
                ].shipper_district_id;
            service.transactionAddressClear({ dispatch });
            service.agentId({ dispatch, id: districtId });
        }

        if (state?.shipper?.orderId?.isError) {
            service.orderDetailClear({ dispatch });
        }
        if (state?.shipper?.orderId?.isSuccess) {
            setOrderIdDetail((prevState) => [
                ...prevState,
                state.shipper.orderId.data.data.id,
            ]);
            setListDataLongId((prevState) => [
                ...prevState,
                state.shipper.orderId.data.data.order.detail._id,
            ]);
            setOrderIdNumber(state.shipper.orderId.data.data.id);
            service.orderDetailClear({ dispatch });
            setTracking(state.shipper.orderId.data);
        }
        if (state?.shipper?.pickup?.isSuccess) {
            setLoading(false);
            setTimeout(() => {
                setRefresh(true);
                toggle();
                history.push("/supplier/transaction/send-item");
            }, 2000);
        }
        if (state?.shipper?.order?.isSuccess) {
            setStatus(state.shipper.order.isSuccess);
            setMessage(state.shipper.order.message.text);
            setLoading(false);
            setShowMessage(true);
            setTimeout(() => {
                setRefresh(true);
                setShowMessage(false);
                toggle();
                if (data.product.category.name === "Voucher Digital") {
                    history.push("/supplier/transaction/send-item");
                } else {
                    history.push("/supplier/transaction/packaging");
                }
            }, 2000);
            setOrderShipper(state.shipper.order.data);
            service.orderShipperClear({ dispatch });
        }
        if (state?.shipper?.order?.isError) {
            setStatus(state.shipper.order.isSuccess);
            setMessage(state.shipper.order.data.message);
            setLoading(false);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
                toggle();
            }, 2000);
            setOrderShipper(state.shipper.order.data);
            service.orderShipperClear({ dispatch });
        }
        if (state?.shipper?.pickup?.isError) {
            setLoading(false);
            setTimeout(() => {
                toggle();
            }, 2000);
        }
        if (state?.supplier?.cancelTransaction?.isSuccess) {
            setModalCancel(false);
            service.cancelTransactionClear({ dispatch });
            setStatus(state.supplier.cancelTransaction.isSuccess);
            setMessage(state.supplier.cancelTransaction.message.text);
            setLoading(false);
            setShowMessage(true);
            setTimeout(() => {
                setRefresh(true);
                setShowMessage(false);
                toggle();
            }, 2000);
        }
        if (state?.supplier?.cancelTransaction?.isError) {
            setModalCancel(false);
            service.cancelTransactionClear({ dispatch });
            setStatus(state.supplier.cancelTransaction.isSuccess);
            setMessage(state.supplier.cancelTransaction.data.message);
            setLoading(false);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
                toggle();
            }, 2000);
        }
    }, [state, isCancel]);
    const buttonActionShow = () => {
        switch (detail) {
            case "order_shipper":
                return (
                    <React.Fragment>
                        <React.Fragment>
                            <Button
                                color="danger"
                                className="mr-3"
                                onClick={() => modalCancel(data)}
                            >
                                Tolak Pemesanan
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => processTransaction(data)}
                            >
                                Proses
                            </Button>
                        </React.Fragment>
                    </React.Fragment>
                );
                break;
            case "request_pickup":
                return null
                // return (
                //     <React.Fragment>
                //         <Button
                //             color="primary"
                //             onClick={() => handleCallShipper(data)}
                //         >
                //             Meminta penjemputan barang
                //         </Button>
                //     </React.Fragment>
                // );
                break;
            default:
                return false;
        }
    };
    React.useEffect(() => {
        async function calculateIncome() {
            let resellerCommission = await calculateCommission({
                product: data.product,
                total_amount: data.total_amount,
                commission_type: "reseller_commission",
                commissionList: commissions,
                product_fee: data.product_fee,
            });
            let lakuganCommission = await calculateCommission({
                product: data.product,
                total_amount: data.total_amount,
                commission_type: "lakugan_commission",
                commissionList: commissions,
                product_fee: data.product_fee,
            });
            let t_price = data.total_amount * data.product_fee;
            let special = data.total_amount * data.special_cut;
            setTotalIncome(
                data.special_cut > 0
                    ? t_price - special
                    : t_price - resellerCommission - lakuganCommission
            );
        }

        if (data) {
            let getTime = data.transaction_code.split("/");
            let timestamp = getTime[1];
            let getFullDate = new Date(Number(timestamp));
            setTransactionDate(
                `${getFullDate.getFullYear()}-${String(
                    getFullDate.getMonth() + 1
                ).padStart(2, "0")}-${String(getFullDate.getDate()).padStart(
                    2,
                    "0"
                )}`
            );
            calculateIncome();
        }
    }, [data, commissions]);
    return (
        <Style isOpen={isShow} toggle={handleStatusPickup} size="lg">
            <GrowingLoading active={isLoading} />
            <MessageState
                status={status}
                message={message}
                isShow={showMessage}
            />
            <ModalHeader toggle={handleStatusPickup}>
                <img src={Logo} alt="Lakugan" className="img-fluid" />
            </ModalHeader>
            <ModalBody>
                {data ? (
                    <React.Fragment>
                        <div className="desc">
                            <div className="mb-4">
                                <Row className="justify-content-between">
                                    <Col xl="6" lg="6" md="6" sm="12">
                                        <h6 className="mb-2 border-bottom pb-2">
                                            Data Pembeli
                                        </h6>
                                        <div className="d-flex">
                                            <div className="title">Nama</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data.customer_detail.full_name}
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Email</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data.customer_detail.email}
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Telepon</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {
                                                    data.customer_detail
                                                        .phone_number
                                                }
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Alamat</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data.customer_detail.address}
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">
                                                Metode Pembayaran
                                            </div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data.payment_channel.name}
                                            </div>
                                        </div>
                                        {data.shipper && (
                                            <div className="d-flex">
                                                <div className="title">
                                                    Metode Pengiriman
                                                </div>
                                                <div className="mr-2">:</div>
                                                <div className="desc-value">
                                                    {data.shipper.rate_name}
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                    <Col xl="5" lg="5" md="5" sm="12">
                                        <h6 className="mb-2 border-bottom pb-2">
                                            Data Reseller
                                        </h6>
                                        <div className="d-flex">
                                            <div className="title">Nama</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data.reseller.full_name}
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Telepon</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data.reseller.phone_number}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="12">
                                        <div className="text-center">
                                            <div className="mb-4">
                                                <div className="order-picture">
                                                    <h6>Pemesanan</h6>
                                                    <img
                                                        src={
                                                            data.product
                                                                .product_image[0]
                                                                .img_url
                                                        }
                                                        alt=""
                                                        className="img-fluid mb-3"
                                                    />
                                                    <h4 className="text-center">
                                                        {data.product.name}
                                                    </h4>
                                                    <h4 className="text-center">
                                                        {formatMoney(
                                                            data.product_fee
                                                        )}
                                                    </h4>
                                                    {data.is_rated && (
                                                        <div
                                                            className="d-block mx-auto"
                                                            style={{
                                                                width: "max-content",
                                                            }}
                                                        >
                                                            <Rating
                                                                size={"24px"}
                                                                totalStars={5}
                                                                rating={
                                                                    data.rating
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <div className="order-title">
                                                    Jumlah
                                                </div>
                                                <div className="mr-2">:</div>
                                                <div
                                                    className="desc-value"
                                                    style={{ fontSize: 16 }}
                                                >
                                                    {data.total_amount} Barang
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <span>Spesifikasi Pemesanan :</span>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Warna</th>
                                        <th>Ukuran</th>
                                        <th>Jumlah Barang</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{data?.product?.spec[0]?.color}</td>
                                        <td>{data?.product?.spec[0]?.size}</td>
                                        <td>{data?.total_amount}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div className="mb-4">
                                <div className="title mb-1">
                                    Catatan Pembeli:{" "}
                                </div>
                                <div style={{ fontSize: 14 }}>
                                    {data.customer_detail.address_direction}
                                </div>
                            </div>
                        </div>

                        <div className="desc-transaction">
                            {orderIdNumber !== "" && (
                                <div className="desc-transaction__detail">
                                    <div>Order ID</div>
                                    <div>{orderIdNumber}</div>
                                </div>
                            )}

                            <div className="desc-transaction__detail">
                                <div>Nomor Transaksi</div>
                                <div style={{ overflow: "auto" }}>
                                    {data.transaction_code}
                                </div>
                            </div>
                            <div className="desc-transaction__detail ">
                                <div>Tanggal Transaksi</div>
                                <div>{transactionDate}</div>
                            </div>
                        </div>

                        <div className="desc-transaction__total">
                            <div>Total Transaksi</div>
                            <div>{formatMoney(data?.total_cost)}</div>
                        </div>
                        <div className="desc-transaction__total">
                            <div>Total Pendapatan</div>
                            <div>{formatMoney(totalIncome)}</div>
                        </div>
                        <div className="mt-4">
                            {isCancel && (
                                <div>
                                    <FormGroup>
                                        <label htmlFor="">
                                            Tuliskan Alasan Penolakan
                                        </label>
                                        <Input
                                            type="textarea"
                                            rows="7"
                                            placeholder="Tuliskan alasan penolakan order"
                                        />
                                    </FormGroup>
                                </div>
                            )}

                            <div className="text-right">
                                {buttonActionShow()}
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="desc">
                            <div className="mb-4">
                                <Row className="justify-content-between">
                                    <Col xl="6" lg="6" md="6" sm="12">
                                        <h6 className="mb-2 border-bottom pb-2">
                                            Data Pembeli
                                        </h6>
                                        <div className="d-flex">
                                            <div className="title">Nama</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Email</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Telepon</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Alamat</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">
                                                Metode Pembayaran
                                            </div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">
                                                Metode Pengiriman
                                            </div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="5" lg="5" md="5" sm="12">
                                        <h6 className="mb-2 border-bottom pb-2">
                                            Data Reseller
                                        </h6>
                                        <div className="d-flex">
                                            <div className="title">Nama</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="170px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Telepon</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="170px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="12">
                                        <div className="text-center">
                                            <div className="mb-4">
                                                <div className="order-picture">
                                                    <h6>Pemesanan</h6>

                                                    <SkeletonLoading
                                                        width="200px"
                                                        height="200px"
                                                        style={{
                                                            margin: "10px auto",
                                                        }}
                                                    />
                                                    <h4 className="text-center">
                                                        <SkeletonLoading
                                                            width="200px"
                                                            height="25px"
                                                            style={{
                                                                margin: "10px auto",
                                                            }}
                                                        />
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <div className="order-title">
                                                    Jumlah
                                                </div>
                                                <div className="mr-2">:</div>
                                                <div
                                                    className="desc-value"
                                                    style={{ fontSize: 16 }}
                                                >
                                                    <SkeletonLoading
                                                        width="200px"
                                                        height="25px"
                                                        style={{
                                                            marginBottom: 10,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mb-4">
                                <div className="title mb-1">
                                    Catatan Pembeli:{" "}
                                </div>
                                <div style={{ fontSize: 14 }}>
                                    <SkeletonLoading
                                        width="200px"
                                        height="25px"
                                        style={{ marginBottom: 10 }}
                                    />
                                </div>
                            </div>
                            {/* <span>JNE REG</span> */}
                        </div>

                        <div className="desc-transaction">
                            <div className="desc-transaction__detail">
                                <div>Order ID</div>
                                <div>
                                    <SkeletonLoading
                                        width="200px"
                                        height="25px"
                                        style={{ marginBottom: 10 }}
                                    />
                                </div>
                            </div>
                            <div className="desc-transaction__detail">
                                <div>Nomor Transaksi</div>
                                <div>
                                    <SkeletonLoading
                                        width="200px"
                                        height="25px"
                                        style={{ marginBottom: 10 }}
                                    />
                                </div>
                            </div>
                            <div className="desc-transaction__detail ">
                                <div>Tanggal Transaksi</div>
                                <div>
                                    <SkeletonLoading
                                        width="200px"
                                        height="25px"
                                        style={{ marginBottom: 10 }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="desc-transaction__total">
                            <div>Total Transaksi</div>
                            <div>
                                <SkeletonLoading
                                    width="100px"
                                    height="30px"
                                    style={{ marginBottom: 10 }}
                                />
                            </div>
                        </div>
                        <div className="desc-transaction__total">
                            <div>Total Pendapatan</div>
                            <div>
                                <SkeletonLoading
                                    width="100px"
                                    height="30px"
                                    style={{ marginBottom: 10 }}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </ModalBody>
            {/* <AgentList
                isLoading={isLoading}
                setLoading={setLoading}
                handleCallShipper={handleCallShipper}
                handleChoiceShipper={handleChoiceShipper}
                data={getDataAgent}
                isShow={isShowAgent}
                toggle={hideAgentList}
                recommendedShipper={recommendedShipper}
            /> */}
            <Modal
                isOpen={isModalCancel}
                toggle={() => setModalCancel(!isModalCancel)}
            >
                <ModalBody>
                    <h5 className="text-center mb-4">
                        Anda yakin ingin menolak transaksi ini ?
                    </h5>
                    <Row>
                        <Col xl="6" md="6" lg="6" sm="6">
                            <Button
                                color="primary"
                                type="button"
                                block
                                onClick={() => setModalCancel(!isModalCancel)}
                            >
                                Tidak
                            </Button>
                        </Col>
                        <Col xl="6" md="6" lg="6" sm="6">
                            <Button
                                type="button"
                                block
                                color="danger"
                                onClick={() => handleCancelTransaction(data)}
                            >
                                Ya
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Style>
    );
};
export default DetailOrder;
