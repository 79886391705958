export const color = {
    primary: "#000A8C",
    secondary: "#6488f3",
    orange: "#f6b733",
    red: "#FF2020",
    green: "#24C400",
    white: "#FFFFFF",
    user: {
        primary: "#ffeb3b",
        secondary: "#ffeb3b",
        yellow: "#fdee32",
        lakugan: "#0c258c",
    },
};
export const sidebar = {
    width: 250,
};
export const aside = {
    width: "300px",
};
