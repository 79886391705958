import React from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import Image from "../../../../Components/Assets/Images/FAQ-banner.png";
import service from "../index.service";
import { useStore } from "../../../../Reducers";
import Collapse from "../../../../Components/Collapse";
import Button from "../../../../Components/Particle/Button";
import { useHistory } from "react-router";
import IconAsk from "../../../../Components/Assets/icon/mobile/faq_home_mobile.png";
const Style = styled.div`
    margin-bottom: 100px;
    .header-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .img-mobile {
            display: none;
            @media (max-width: 550px) {
                display: block;
                width: 50px;
            }
        }
    }
    .img-desktop {
        @media (max-width: 550px) {
            display: none;
        }
    }
    .title {
        margin-bottom: 15px;
        font-weight: bold;
        font-size: 24px;
        color: #000000;
        @media (max-width: 550px) {
            font-size: 18px;
            margin-bottom: 0;
        }
    }
    .see-more {
        text-align: center;
        margin-top: 20px;
    }
`;
const SectionFAQ = () => {
    const { dispatch, state } = useStore();
    const history = useHistory();
    const [isFirstGet, setFirstGet] = React.useState(true);
    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        if (isFirstGet) {
            setFirstGet(false);
            service.FAQ({ dispatch });
        }
    }, [isFirstGet, dispatch]);
    React.useEffect(() => {
        if (state?.user?.faqList?.isSuccess) {
            setData(
                state.user.faqList.data.content.filter(
                    (item) =>
                        item.category === "Reseller" &&
                        item.question_answer_list.length > 0
                )
            );
        }
    }, [state]);
    return (
        <Style>
            <Container>
                <Row>
                    <Col xl={5} md={5}>
                        <div className="header-title">
                            <h3 className="title">
                                Pertanyaan Umum <br />
                                Tentang Lakugan
                            </h3>
                            <img
                                src={IconAsk}
                                alt=""
                                className="img-fluid img-mobile"
                            />
                        </div>
                        <div className="img-desktop">
                            <img
                                src={Image}
                                alt="Lakugan Banner"
                                className="img-fluid"
                            />
                        </div>
                    </Col>
                    <Col xl={7} md={7}>
                        {data
                            .filter((item) => item.category === "Reseller")
                            .map((item, key) => {
                                return (
                                    key === 0 && (
                                        <Collapse
                                            item={item}
                                            key={key}
                                            id={key}
                                        />
                                    )
                                );
                            })}
                        <div className="see-more">
                            <Button
                                color="primary"
                                onClick={() => history.push("/FAQ")}
                                style={{ paddingLeft: 30, paddingRight: 30 }}
                            >
                                Lebih Banyak
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Style>
    );
};
export default SectionFAQ;
