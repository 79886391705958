import { servicesAction } from "../../../Reducers";

const service = {
    register: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/v1/register/supplier",
            method: "POST",
            data: data,
            v1: true,
            reducer: "service",
            group: "user",
            key: "register",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Register berhasil!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Bad Request",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
    registerReseller: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/v1/register/reseller",
            method: "POST",
            data: data,
            v1: true,
            reducer: "service",
            group: "user",
            key: "register",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Register berhasil!",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Email atau Nomor telepon sudah terdaftar",
                },
            },
        });
    },
    registerClearList: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "register",
            type: "CLEAR",
        });
    },
};
export default service;
