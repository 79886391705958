import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import styled from "styled-components";
import BackLogo from "../../../../Components/Assets/icon/arrow-left.svg";

const Style = styled.header`
    top: 0;
    z-index: 9;
    display: block;
    width: 100%;
    padding: 4% 0;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    h2 {
        white-space: normal;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        text-align: left;
    }
`;
const Header = ({ isShow = "", HeaderName }) => {
    return (
        <Style id="header-payment" isShow={isShow} >
            <Container fluid="sm">
                <div className="d-flex flex-row">
                    <Link to="/" className="my-auto">
                        <img src={BackLogo} alt="" />
                    </Link>
                    <h2 className="mx-4 font-semibold">{HeaderName}</h2>
                </div>
            </Container>
        </Style>
    );
};
export default Header;
