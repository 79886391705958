import styled from "styled-components";
import Banner from "../../../Components/Assets/v2/banner-2.png";
import BannerMobile from "../../../Components/Assets/v2/banner-mobile.png";
import BackgroundBlue from "../../../Components/Assets/v2/background-blue.png";
import BackgroundGrey2 from "../../../Components/Assets/v2/background-grey2.png";
import BackgroundGrey3 from "../../../Components/Assets/v2/background-grey3.png";
import BackgroundFooterMobile from "../../../Components/Assets/v2/background-footer-mobile.png";

const Style = styled.div`
    .first .header {
        background: url(${Banner});
        background-repeat: no-repeat;
        background-size: 125%;
    }
    .navbar {
        padding: 30px 140px;
    }
    .nav-item {
        padding: 0 8px;
        font-size: 18px;
    }
    .nav-item a {
        font-size: 18px;
    }
    .nav-item .nav-link {
        color: #fff !important;
        font-weight: bold;
    }
    .wrapper-header {
        padding: 0 180px;
    }
    .wrapper-header-column {
        padding-top: 121px;
        padding-left: 48px;
    }
    .btn-radius {
        border-radius: 64px;
        color: #124981 !important;
        font-weight: bold;
        width: 115px;
        height: 44px;
    }
    .header3-primary {
        font-weight: 500;
        font-size: 42px;
        line-height: 66px;
        color: #F8F8F8;
    }
    .btn-register {
        margin-top: 32px;
        font-weight: bold;
        font-size: 18px;
        line-height: 34px;
        width: 205px;
        height: 48px;
        background-color: #FFB508;
        border-radius: 16px;
        color: #003466 !important;
        position: relative;
        z-index: 1000;
    }
    .subtitle-primary {
        padding-left: 2em;
        color: #00305E;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
    }
    .second {
        margin-top: 200px;
        padding-bottom: 60px;
    }
    .second .col-sm-4 {
        margin-bottom: 24px;
    }
    .col-title ul {
        list-style: none;
        padding: 0;
    }
    .col-title li {
        padding-left: 1.3em;
        font-size: 36px;
        font-weight: bold;
        line-height: 54px;
        color: #00305E;
    }
    .col-title li:before {
        content: "\f068"; /* FontAwesome Unicode */
        font-family: FontAwesome;
        display: inline-block;
        margin-left: -1.3em; /* same as padding-left set on li */
        width: 1.3em; /* same as padding-left set on li */
        color: #FFB508;
    }
    .column-frame {
        display: flex;
        justify-content: center;
        height: 250px;
    }
    .column-frame-text {
        margin-top: 12px;
        text-align: center;
    }
    .col-step {
        margin-top: 48px;
    }
    .col-step .text-step {
        text-align: center;
        font-weight: 600;
        font-size: 32px;
        color: #00305D;
    }
    .frame-subtitle {
        margin-top: 8px;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #00305E;
    }
    .eighth {
        margin-top: 120px;
    }
    .eighth .header {
        background: url(${BackgroundGrey3}), url(${BackgroundGrey2});
        background-repeat: no-repeat;
        background-position-y: 40px, 80px;
        background-size: 25%, 100%;
        position: relative;
        z-index: 997, 998;
        padding-top: 100px;
    }
    .eighth .footer {
        background: url(${BackgroundBlue});
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: -200px;
        padding-bottom: 36px;
        position: relative;
        z-index: 999;
    }
    .wrapper-footer {
        padding-top: 180px;
    }
    .wrapper-footer h3 {
        font-weight: bold;
        font-size: 28px;
        line-height: 42px;
        color: #fff;
    }
    .wrapper-footer h4 {
        font-weight: bold;
        font-size: 24px;
        line-height: 34px;
        text-align: center;
        color: #fff;
        padding-bottom: 12px;
    }
    .wrapper-footer img {
        padding-bottom: 12px;
    }
    .wrapper-footer p, .wrapper-footer span {
        font-weight: 500;
        font-size: 16px;
        line-height: 34px;
        color: #fff;
    }
    .wrapper-footer a {
        color: #fff !important;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 34px;
        display: block;
        padding: 6px 0;
    }
    .wrapper-icons a {
        display: inline-block;
    }
    .header1-primary-blue {
        font-weight: bold;
        font-size: 44px;
        line-height: 66px;
        color: #003466;
    }
    .img-footer {
        margin-top: -80px;
        padding-left: 36px;
    }
    .row-footer {
        padding-bottom: 24px;
        border-bottom: 1px solid #fff;
    }
    .row-footer-bottom {
        padding: 46px 0;
    }
    .row-footer-bottom a {
        font-weight: bold;
        font-size: 24px;
        line-height: 34px;
        color: #fff !important;
    }
    .text-footer {
        font-weight: 500;
        font-size: 18px;
        line-height: 34px;
        color: #fff;
    }
    .img-icons {
        padding: 0 6px;
    }
    .benefit-mobile-image img {
        margin-left: -120px;
        margin-top: -80px;
    }
    .column-frame img {
        min-height: 20px;
    }
    .text-left a {
        text-align: left;
        margin-left: 72px;
    }
    .col-footer {
        padding-left: 125px;
    }
    .navbar-nav .active {
        border-bottom: 2px solid #FFB508;
    }

    @media only screen and (max-width: 768px) {
        .row {
            margin: 0px !important;
        }
        .navbar {
            padding: 12px;
        }
        .navbar img {
            width: 140px;
        }
        .first .header {
            background: url(${BannerMobile});
            background-position: top right 50%;
            background-repeat: no-repeat;
        }
        .first .order-first {
            order: 2;
        }
        .first .order-second {
            order: 1;
        }
        .wrapper-header {
            padding: 0;
        }
        .wrapper-header .row .col-6 {
            align-self: center;
        }
        .wrapper-header-column {
            padding-top: 36px;
            padding-left: 0;
            text-align: center;
        }
        .wrapper-header img {
            width: 100%;
            display: block;
            margin: auto;
        }
        .header1-primary-blue {
            font-weight: 600;
            font-size: 24px;
            line-height: 41px;
            padding: 0 10px;
        }
        .header3-primary, .header3-primary-blue {
            font-weight: 600;
            font-size: 24px;
            line-height: 41px;
            padding: 0 10px;
        }
        .header3-primary br {
            display: none;
        }
        .header4-primary {
            font-size: 18px;
            line-height: 28px;
        }
        .col-title li {
            font-size: 26px;
            line-height: 34px;
        }
        .img-footer {
            padding-left: 0;
            width: 100%;
            margin-top: 50px;
        }
        .wrapper-footer {
            padding-top: 36px;
        }
        .eighth {
            margin-top: 36px;
        }
        .eighth .header {
            background: url(${BackgroundGrey2});
            background-position-y: 160px;
            background-repeat: no-repeat;
        }
        .eighth .footer {
            background: url(${BackgroundFooterMobile});
            background-size: cover;
            margin-top: -60px;
        }
        .wrapper-footer, .row-footer-bottom, .text-footer {
            text-align: center;
        }
        .row-footer-bottom a {
            font-size: 18px;
        }
        .wrapper-footer h4 {
            font-size: 18px;
        }
        .wrapper-footer > img {
            width: 140px;
        }
        .col-footer {
            padding-left: 15px;
            text-align: center;
        }
        .nav-open {
            position: fixed;
            top: 0 !important;
            left: 0 !important;
            height: 100%;
            width: 100%;
            background-color: #003263;
            z-index: 1001;
            -webkit-transtition: left .3s ease-in-out, right .3s ease-in-out;
            transition: left .3s ease-in-out, right .3s ease-in-out;
        }
        .nav-open ul {
            text-align: center;
        }
        .nav-open .nav-link {
            padding: 24px 0;
        }
        .nav-open img {
            float: left;
            padding: 12px;
            width: 165px;
        }
        .nav-open i {
            float: right;
            color: #fff;
            padding: 12px;
            font-size: 36px;
            margin-right: 12px;
        }
        .nav-open .nav-item:last-of-type {
            border-bottom: 1px solid #fff;
            width: 70%;
            display: block;
            margin: auto;
        }
        .nav-open-btn a {
            margin: 24px;
        }
        .btn-radius {
            height: 40px;
        }
        .text-left a {
            text-align: center;
            margin-left: 0;
        }
        .col-step .text-step {
            font-size: 24px;
        }
    }
`;
export default Style;
