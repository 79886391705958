import React from "react";
import { Card, CardBody } from "reactstrap";
import styled from "styled-components";
import Tab from "./Tab";

import Dummy from "../../../Components/Assets/Images/reseller_default.png";
import moment from "moment";
import service from "./index.service";
import General from "./content/General";
import LocalStorage from "../../../Utils/LocalStorage";
import { useStore } from "../../../Reducers";
import Verification from "./content/Verification";
import Button from "../../../Components/Particle/Button";
import GrowingLoading from "../../../Components/Loading/GrowingLoading";
import MessageState from "../../../Components/Modal/Message";

const Style = styled(Card)`
    border: unset;
    width: 1200px;
    margin: auto;
    hr {
        margin-top: 0;
        margin-bottom: 50px;
    }
    @media (max-width: 1200px) {
        width: 100%;
    }
`;
const EditProfile = () => {
    const token = LocalStorage().get("auth");
    const { dispatch, state } = useStore();
    const auth = LocalStorage().get(`${token.access_token}`);
    const [isFirstGet, setFirstGet] = React.useState(true);
    const [isLoading, setLoading] = React.useState(false);
    //
    const [avatar, setAvatar] = React.useState(auth.photo_profile || Dummy);
    const [address, setAddress] = React.useState(auth.address);
    const [city, setCity] = React.useState([]);
    const [status, setStatus] = React.useState(true);
    const [message, setMessage] = React.useState("");
    const [showMessage, setShowMessage] = React.useState(false);

    const [district, setDistrict] = React.useState([]);
    const [area, setArea] = React.useState([]);
    const [province, setProvince] = React.useState([]);
    const [valueProvince, setValueProvince] = React.useState(province[0]);
    const [valueCity, setValueCity] = React.useState(city[0]);
    const [valueArea, setValueArea] = React.useState(area[0]);
    const [postCode, setPostCode] = React.useState("");
    const [valueDistrict, setValueDistrict] = React.useState(district[0]);
    const [validationClick, setValidationClick] = React.useState(true);
    const [validateForm, setValidateForm] = React.useState(true);
    const [formVerification, setFormVerification] = React.useState([
        {
            label: "Foto KTP",
            name: "identity_photo",
            required: true,
            action: "uploadImage",
            placeholder: "Klik Untuk Masukan Gambar",
            col: 4,
            valid: "Harap masukkan gambar",
            value: auth.identity_photo,
            status: auth.identity_photo !== null ? true : false,
            normalData: false,
            heightStyle: "150px",
        },
        {
            type: "number",
            required: true,
            action: "text",
            name: "ktp",
            label: "Nomor KTP",
            col: 8,
            character: {
                min: {
                    length: 1,
                    valid: "Nomor KTP tidak valid",
                },
                max: {
                    length: 16,
                    valid: "Nomor KTP tidak valid",
                },
            },
            valid: "Nomor KTP tidak boleh kosong",
            value: auth.nik,
            status: auth.nik !== null ? true : false,
        },
    ]);
    const [formAddress, setFormAddress] = React.useState({
        type: "textarea",
        required: true,
        label: "Alamat lengkap",
        action: "textarea",
        rows: 5,
        character: {
            min: {
                length: 5,
                valid: "Minimal 5 karakter",
            },
            max: {
                length: 200,
                valid: "Miximal 200 karakter",
            },
        },
        name: "address",
        valid: "Alamat tidak boleh kosong",
        value: address,
        status: address === "" || address === null ? false : true,
    });
    const [form, setForm] = React.useState([
        {
            type: "text",
            required: true,
            action: "text",
            name: "username",
            label: "Nama",
            col: 4,
            valid: "Nama tidak boleh kosong",
            value: auth.full_name,
            status: auth.full_name !== null ? true : false,
        },
        {
            type: "email",
            required: true,
            action: "email",
            name: "Email",
            label: "Email",
            col: 4,
            valid: "",
            disable: true,
            value: auth.email,
            status: true,
        },
        {
            type: "number",
            required: true,
            action: "phone",
            name: "phone",
            label: "Nomor telepon (yang terhubung dengan WhatsApp)",
            col: 4,
            valid: "Nomor telepon tidak boleh kosong",
            value: auth.phone_number,
            status: auth.phone_number !== null ? true : false,
        },
        {
            label: "Jenis kelamin",
            required: true,
            type: "select",
            action: "select",
            name: "gender",
            col: 4,
            option: [
                {
                    label: "Laki-Laki",
                    value: "1",
                },
                {
                    label: "Perempuan",
                    value: "2",
                },
            ],
            valid: "Jenis kelamin tidak boleh kosong",
            value:
                auth.gender === "Laki-Laki"
                    ? {
                          label: "Laki-Laki",
                          value: "1",
                      }
                    : auth.gender === "Perempuan"
                    ? {
                          label: "Perempuan",
                          value: "2",
                      }
                    : "",
            status: auth.gender !== null ? true : false,
        },
        {
            label: "Pekerjaan",
            required: "optional",
            type: "text",
            action: "text",
            name: "jobdesc",
            col: 4,
            valid: "",
            value: auth.job === null ? "" : auth.job,
            status: true,
        },
        {
            type: "date",
            required: true,
            action: "datepicker",
            name: "birthday",
            label: "Tanggal lahir",
            col: 4,
            maxDate: new Date(moment(new Date()).format("YYYY-MM-DD")),
            valid: "Tanggal lahir tidak boleh kosong",
            value:
                auth.dob !== null
                    ? moment(auth.dob).format("YYYY-MM-DD")
                    : moment(new Date()).format("YYYY-MM-DD"),
            status: true,
        },
    ]);

    const handleGetCity = (value) => {
        setValueProvince(value);
        setDistrict([]);
        setValueCity([]);
        setValueDistrict([]);
        setArea([]);
        setValueArea([]);
        setPostCode("");
        let params = value.value;
        service.getCity({ dispatch, params });
        service.getClearDistrict({ dispatch });
        service.getClearArea({ dispatch });
    };
    const handleGetDistrict = (value) => {
        service.getClearArea({ dispatch });
        setValueDistrict([]);
        setValueArea([]);
        setArea([]);
        setPostCode("");
        let params = value.value;
        setValueCity({ label: value.label, value: value.value });
        service.getDistrict({ dispatch, params });
    };
    const handleGetValueDistrict = (value) => {
        setValueArea([]);
        setPostCode("");
        setValueDistrict({ label: value.label, value: value.value });
        let params = value.value;
        service.getArea({ dispatch, params });
    };
    const handleGetValueArea = (value) => {
        setValueArea({ label: value.label, value: value.value });
        setPostCode(value.postcode);
    };
    const onGetValue = (value) => {
        setAvatar(value.value);
    };
    const handlerSubmitData = (e) => {
        let isError = false;
        let isErrorVerification = false;
        let isErrorAddressStatus = false;
        let addressStatus = [
            {
                value: valueProvince,
                status:
                    valueProvince?.label || valueProvince?.length > 0
                        ? true
                        : false,
            },
            {
                value: valueCity,
                status:
                    valueCity?.label || valueCity?.length > 0 ? true : false,
            },
            {
                value: valueDistrict,
                status:
                    valueDistrict?.label || valueDistrict?.length > 0
                        ? true
                        : false,
            },
            {
                value: valueArea,
                status:
                    valueArea?.label || valueArea?.length > 0 ? true : false,
            },
            {
                value: address,
                status: address === "" || address === null ? false : true,
            },
        ];
        e.preventDefault();
        for (let key in addressStatus) {
            if (!addressStatus[key].status) {
                isErrorAddressStatus = true;
            }
        }
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        for (let key in formVerification) {
            if (!formVerification[key].status) {
                isErrorVerification = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (
            !isError &&
            !isErrorVerification &&
            !isErrorAddressStatus &&
            formAddress.status
        ) {
            setLoading(true);
            let data = {
                phone_number: form[2].value,
                full_name: form[0].value,
                gender: form[3].value.label,
                address: address,
                identity_photo: formVerification[0].value,
                nik: formVerification[1].value,
                description: "",
                dob: form[5].value,
                email: form[1].value,
                province:
                    valueProvince.length > 0
                        ? valueProvince[0].label
                        : valueProvince?.label,
                city:
                    valueCity.length > 0
                        ? valueCity[0].label
                        : valueCity?.label,
                district:
                    valueDistrict.length > 0
                        ? valueDistrict[0].label
                        : valueDistrict?.label,
                sub_district:
                    valueArea.length > 0
                        ? valueArea[0].label
                        : valueArea?.label,
                job: form[4].value,
                postal_code: Array.isArray(postCode) ? postCode[0] : postCode,
                photo_profile: avatar,
            };
            service.updateProfile({ dispatch, data });
        }
    };
    React.useEffect(() => {
        if (isFirstGet) {
            service.getProvince({ dispatch });
            setFirstGet(false);
        }
    }, [isFirstGet, dispatch]);
    React.useEffect(() => {
        if (state?.reseller?.profile?.isSuccess) {
            LocalStorage().save(
                `${token.access_token}`,
                state.reseller.profile.data
            );
            setLoading(false);
            setStatus(state?.reseller?.profile.isSuccess);
            setMessage(state.reseller.profile.message.text);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
            service.updateProfileClear({ dispatch });
        }
        if (state?.reseller?.profile?.isError) {
            setLoading(false);
            setStatus(state?.reseller?.profile.isSuccess);
            setMessage(state.reseller.profile.data.message);
            setShowMessage(true);
            service.updateProfileClear({ dispatch });
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
        }
        if (state?.user?.province?.isSuccess) {
            setProvince(
                state.user.province.data.content.map((item) => {
                    return {
                        ...{ label: "Pilih", value: 0 },
                        ...{ label: item.name, value: item.id },
                    };
                })
            );
            setValueProvince(
                state.user.province.data.content
                    .filter((item) => item.name === auth.province)
                    .map((item) => {
                        return { label: item.name, value: item.id };
                    })
            );
            service.getClearProvince({ dispatch });
            if (auth.province !== null) {
                let params = state.user.province.data.content
                    .filter((item) => item.name === auth.province)
                    .map((item) => {
                        return item.id;
                    });
                service.getCity({ dispatch, params });
            }
        }
        if (state?.user?.city?.isSuccess) {
            setDistrict([]);
            setCity(
                state.user.city.data.content.map((item) => {
                    return {
                        ...{ label: "Pilih", value: 0 },
                        ...{ label: item.name, value: item.id },
                    };
                })
            );
            setValueCity(
                state.user.city.data.content
                    .filter((item) => item.name === auth.city)
                    .map((item) => {
                        return { label: item.name, value: item.id };
                    })
            );
            service.getClearCity({ dispatch });
            if (auth.city !== null) {
                let params = state.user.city.data.content
                    .filter((item) => item.name === auth.city)
                    .map((item) => {
                        return item.id;
                    });
                service.getDistrict({ dispatch, params });
            }
        }
        if (state?.user?.district?.isSuccess) {
            setDistrict(
                state.user.district.data.content.map((item) => {
                    return {
                        ...{ label: "Pilih", value: 0 },
                        ...{ label: item.name, value: item.id },
                    };
                })
            );
            setValueDistrict(
                state.user.district.data.content
                    .filter((item) => item.name === auth.district)
                    .map((item) => {
                        return { label: item.name, value: item.id };
                    })
            );
            service.getClearDistrict({ dispatch });
            if (auth.district !== null) {
                let params = state.user.district.data.content
                    .filter((item) => item.name === auth.district)
                    .map((item) => {
                        return item.id;
                    });
                service.getArea({ dispatch, params });
            }
        }
        if (state?.user?.area?.isSuccess) {
            setArea(
                state.user.area.data.content.map((item) => {
                    return {
                        ...{ label: "Pilih", value: 0 },
                        ...{
                            label: item.name,
                            value: item.id,
                            postcode: item.postal_code,
                        },
                    };
                })
            );
            setValueArea(
                state.user.area.data.content
                    .filter((item) => item.name === auth.sub_district)
                    .map((item) => {
                        return { label: item.name, value: item.id };
                    })
            );
            setPostCode(
                state.user.area.data.content
                    .filter((item) => item.name === auth.sub_district)
                    .map((item) => {
                        return item.postal_code;
                    })
            );
            service.getClearArea({ dispatch });
        }
    }, [state, token.access_token, auth, dispatch]);
    return (
        <Style>
            <GrowingLoading active={isLoading} />
            <MessageState
                status={status}
                message={message}
                isShow={showMessage}
            />
            <CardBody>
                <div
                    className="d-flex justify-content-between align-items-baseline"
                    style={{ overflow: "auto" }}
                >
                    <Tab />
                </div>
                <hr />
                <div>
                    <General
                        formAddress={formAddress}
                        setFormAddress={setFormAddress}
                        auth={auth}
                        avatar={avatar}
                        onGetValue={onGetValue}
                        setAddress={setAddress}
                        address={address}
                        city={city}
                        district={district}
                        area={area}
                        province={province}
                        handleGetCity={handleGetCity}
                        handleGetDistrict={handleGetDistrict}
                        handleGetValueDistrict={handleGetValueDistrict}
                        handleGetValueArea={handleGetValueArea}
                        valueCity={valueCity}
                        valueDistrict={valueDistrict}
                        valueArea={valueArea}
                        valueProvince={valueProvince}
                        postCode={postCode}
                        setForm={setForm}
                        validateForm={validateForm}
                        validationClick={validationClick}
                        form={form}
                    />
                    {auth.update_data === false && (
                        <Verification
                            validateForm={validateForm}
                            validationClick={validationClick}
                            formVerification={formVerification}
                            setFormVerification={setFormVerification}
                        />
                    )}
                </div>
                <div className="text-right" style={{ marginTop: 20 }}>
                    <Button
                        type="button"
                        color="primary"
                        onClick={handlerSubmitData}
                    >
                        {auth.verify_id === false ? "Kirim" : "Simpan"}
                    </Button>
                </div>
            </CardBody>
        </Style>
    );
};

export default EditProfile;
