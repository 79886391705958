import { color } from "../../Utils/Variable";
import styled from "styled-components";
export const Style = styled.header`
    position: ${(props) => (props.scroll > 385 ? "fixed" : "absolute")};
    padding: ${(props) => (props.auth === null ? "30px 0" : "10px 0")};
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    left: 0;
    right: 0;
    width: 100%;
    background-color: ${color.white};
    top: ${(props) =>
        props.scroll < 285 && props.scroll > 250 ? "-100px" : "0"};
    z-index: 99;
    transition: all 1.25s ease;
    @media (max-width: 500px) {
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 10px;
        box-shadow: unset;
    }
    ${(props) =>
        props.responsive &&
        props.responsive.map((item) => {
            return `@media (max-width: ${item.breakpoint}px) {
        display: ${item.isHeader ? "block" : "none"};
    }`;
        })}
    .container {
        padding: 0 20px;
    }
    .header {
        padding: 0 40px;
        @media (max-width: 550px) {
            padding: 0 20px;
        }
        &-area {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }
    .logo {
        width: 200px;
        @media (max-width: 575px) {
            width: 130px;
        }
    }
    .signup {
        &-desktop {
            display: flex;
            @media (max-width: 550px) {
                display: none;
            }
        }
        &-mobile {
            display: none;
            .fa-user {
                font-size: 22px;
            }
            @media (max-width: 550px) {
                display: flex;
            }
        }
    }
    .help {
        display: none;
        position: absolute;
        right: -1px;
        top: 60px;
        .card {
            border-radius: 15px;
            left: 0;
            border-color: transparent;
            background: #fff;
            &-help {
                display: flex;
                padding: 6px 10px;
                align-items: center;
                justify-content: space-between;
                border-radius: 20px;
                border: 1px solid #ddd;
                margin: 10px;
                min-width: 275px;
                cursor: pointer;
                &__center {
                    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.08),
                        -1px -1px 8px rgba(0, 0, 0, 0.08);
                }
                .group-flex {
                    display: flex;
                    align-items: center;
                }
                div {
                    font-weight: 600;
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.8);
                    img {
                        margin-right: 7px;
                        width: 25px;
                    }
                }
            }
        }
    }
    .help-center {
        position: relative;
        display: inline-block;
        padding: 20px 0;
        &:hover {
            opacity: 1;
            .help {
                display: block;
            }
        }
        .total-product {
            color: ${color.primary};
            font-size: 12px;
            font-weight: bold;
            width: 22px;
            height: 22px;
            position: absolute;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #f2b705;
            border-radius: 50%;
            top: -6px;
            right: -6px;
        }
    }
    .nav-header {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .btn-user {
        padding: 0;
        margin: 0 35px;
    }
    .btn-border {
        border-radius: 0px;
        position: relative;
        &:before {
            content: "";
            top: 0;
            bottom: 0;
            width: 1px;
            background: ${color.primary};
            position: absolute;
            right: -35px;
        }
    }
    .input-box {
        display: inline-flex;
        width: 100%;
        margin-right: 30px;
        position: relative;
        input[type="search"]::-webkit-search-decoration,
        input[type="search"]::-webkit-search-cancel-button,
        input[type="search"]::-webkit-search-results-button,
        input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance:none;
        }
        Input {
            height: 40px;
        }
        @media (max-width: 1024px) {
            min-width: 235px;
            max-width: 250px;
        }
        @media (min-width: 1280px) {
            width: 541px;
        }
        @media (max-width: 550px) {
            min-width: 200px;
            max-width: 200px;
        }
    }
    .mid-flex {
        @media (min-width: 1024px) {
            margin: 0 145px;
        }
        @media (max-width: 768px) {
            margin: 0;
        }
        @media (min-width: 1280px) {
            margin: 0 104px;
        }
    }
    .desktop-login {
        .btn-login {
            color: #fff;
            background: ${color.primary};
            border: unset;
            padding: 0.375rem 0.75rem;
            display: block;
            .fa-user-circle {
                font-size: 18px;
                margin-right: 5px;
            }
            &:hover {
                text-decoration: unset;
                color: ${color.primary};
            }
        }
        button {
            border: unset;
            padding: 0;
        }
    }

    .nav-item {
        .nav-link {
            margin: 0.5rem 1rem;
            padding: 0;
        }
        a {
            font-weight: 500;
            color: #fff;
            &.active {
                border-bottom: 2px solid #fff;
            }
            @media (max-width: 767px) {
                color: #4a4a4a;
                padding: 0.5rem 1rem;
            }
        }
        .btn-register {
            @media (max-width: 767px) {
                color: #fff;
            }
        }
    }
    .btn-bars {
        display: none;
        @media (max-width: 991px) {
            border: unset;
            background: transparent;
            color: #fff;
            position: relative;
            padding: 9px 0;
            display: block;
            font-size: 14px;
            &:focus {
                outline: unset;
                box-shadow: unset;
            }
        }
    }
    .nav-mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo {
            display: none;
            @media (max-width: 991px) {
                display: block;
            }
        }
    }

    .navbar {
        @media (max-width: 991px) {
            display: none;
        }
    }
    .icon-link {
        display: none;
        font-size: 16px;
        @media (max-width: 991px) {
            display: inline;
        }
    }
    @media (max-width: 991px) {
        .desktop-login {
            display: none;
        }
    }
    .desktop-lakugan {
        height: 40px !important;
        @media (max-width: 550px) {
            width: 30px;
            height: 30px !important;
        }
    }
    .searcn-btn {
        position: absolute;
        z-index: 4;
        top: 0;
        right: 0;
    }
`;
export const HeaderStyle = styled.header`
    display: none;
    @media (max-width: 550px) {
        display: block;
        padding: 10px;
        background: #fff;
        border-radius: 0 0 10px 10px;
        position: relative;
        z-index: 2;
        .title {
            font-size: 18px;
        }
        .header {
            &-top {
                display: flex;
                align-items: center;
                padding: 5px;
                justify-content: end;
                .back-link {
                    margin-right: 10px;
                }
                .search-input {
                    display: flex;
                    align-items: center;
                    width: ${(props) => (props.isSearch ? "100%" : "0px")};
                    overflow: hidden;
                    transition: all 0.75s ease;
                    .close-search {
                        margin-right: 10px;
                    }
                }
                .btn {
                    &-search {
                        &:focus {
                            outline: none;
                            box-shadow: unset;
                        }
                    }
                }
            }
            &-left {
                position: absolute;
                left: ${(props) => (props.isSearch ? "-500px" : "10px")};
                display: flex;
                align-items: center;
                transition: all 0.75s ease;
            }
        }
    }
`;
