import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactCrop from "react-image-crop";
import { Modal, Col, ModalBody, Button, Row } from "reactstrap";

import "react-image-crop/dist/ReactCrop.css";
import ModalFooter from "reactstrap/lib/ModalFooter";

const CropImage = ({
  currentImg,
  showImageCropper,
  closeImageCropper,
  setCropImage,
}) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    height: 50,
    aspect: 4 / 3,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [realWidth, setRealWidth] = useState(null);

  const onLoadImage = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    if (!realWidth) setRealWidth(image.width / 1.5);

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop, realWidth]);

  const cropImage = (canvas, crop) => {
    if (!crop || !canvas) {
      return;
    }
    const base64Image = canvas.toDataURL("image/jpeg");
    setCropImage(base64Image);
    closeImageCropper(false);
  };
  //

  return (
    <Modal size="lg" isOpen={showImageCropper}>
      <ModalBody>
        <Row>
          <Col xl={7}>
            <h5>Image</h5>
            <ReactCrop
              src={currentImg}
              onImageLoaded={onLoadImage}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
              style={{
                width: realWidth,
                objectFit: "contain",
              }}
            />
          </Col>
          <Col xl={5}>
            <h5>Preview</h5>
            <canvas
              ref={previewCanvasRef}
              style={{
                width: Math.round(completedCrop?.width / 2 ?? 0),
                height: Math.round(completedCrop?.height / 2 ?? 0),
              }}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeImageCropper}>Batal</Button>
        <Button
          disabled={!completedCrop?.width || !completedCrop?.height}
          onClick={() => cropImage(previewCanvasRef.current, completedCrop)}
          style={{ backgroundColor: "#0c258c" }}
        >
          Crop Image
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CropImage;
