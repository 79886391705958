/*eslint-disable*/

import React from "react";
import { Col, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";
import styled from "styled-components";
import { formatMoney } from "../../Utils/PriceConverter";
import { calculateCommission } from "../../Utils/CommissionCalculator";
import { color } from "../../Utils/Variable";
import Logo from "../Assets/Images/Lakugan_LogoColor_BrightBG.png";
import SkeletonLoading from "../Loading/Skeleton";
import Rating from "../Particle/Rating";
const Style = styled(Modal)`
    color: #4a4a4a;
    .title {
        color: #4a4a4a;
        width: 164px;
        font-size: 14px;
        font-weight: 500;
    }
    .desc {
        padding-bottom: 10px;
        margin-bottom: 30px;
        border-bottom: 1px dashed #4a4a4a;
        &-transaction {
            font-size: 14px;
            margin-bottom: 10px;
            &__detail {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
            }
            &__total {
                color: ${color.primary};
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: bold;
            }
        }

        &-value {
            color: ${color.primary};
            font-size: 14px;
            font-weight: 500;
            width: calc(100% - 164px);
            overflow: auto;
        }
        th,
        td {
            font-size: 14px;
        }
    }
    .order-picture {
        margin: auto;
        img {
            width: 200px;
            height: 200px;
        }
        h6 {
            font-size: 18px;
            color: #4a4a4a;
            font-weight: 500;
            margin-top: 30px;
        }
    }
    .order-title {
        font-size: 16px;
        margin-right: 10px;
    }
`;

const ModalData = ({
    role = "",
    orderIdNumber = "",
    isShow = false,
    toggle = () => {},
    data = false,
    commissions = false,
}) => {
    const [transactionDate, setTransactionDate] = React.useState("");
    const [totalIncome, setTotalIncome] = React.useState(0);
    const [totalCommission, setTotalCommission] = React.useState(0);

    const handleStatusPickup = () => {
        toggle();
    };

    React.useEffect(() => {
        async function calculateIncome() {
            let resellerCommission = await calculateCommission({
                product: data.product,
                total_amount: data.total_amount,
                commission_type: "reseller_commission",
                commissionList: commissions,
                product_fee: data.product_fee,
            });
            setTotalCommission(resellerCommission);
            let lakuganCommission = await calculateCommission({
                product: data.product,
                total_amount: data.total_amount,
                commission_type: "lakugan_commission",
                commissionList: commissions,
                product_fee: data.product_fee,
            });
            let t_price = data.total_amount * data.product_fee;
            let special = data.total_amount * data.special_cut;

            setTotalIncome(
                data.special_cut !== 0 ||
                    data.special_cut !== undefined ||
                    data.special_cut !== null
                    ? t_price - special
                    : t_price - resellerCommission - lakuganCommission
            );
        }

        if (data) {
            let getTime = data.transaction_code.split("/");
            let timestamp = getTime[1];
            let getFullDate = new Date(Number(timestamp));
            setTransactionDate(
                `${getFullDate.getFullYear()}-${String(
                    getFullDate.getMonth() + 1
                ).padStart(2, "0")}-${String(getFullDate.getDate()).padStart(
                    2,
                    "0"
                )}`
            );
            calculateIncome();
        }
    }, [data, commissions]);
    return (
        <Style isOpen={isShow} toggle={handleStatusPickup} size="lg">
            <ModalHeader toggle={handleStatusPickup}>
                <img src={Logo} alt="Lakugan" className="img-fluid" />
            </ModalHeader>
            <ModalBody>
                {data ? (
                    <React.Fragment>
                        <div className="desc">
                            <div className="mb-4">
                                <Row className="justify-content-between">
                                    <Col xl="6" lg="6" md="6" sm="12">
                                        <h6 className="mb-2 border-bottom pb-2">
                                            Data Pembeli
                                        </h6>
                                        <div className="d-flex">
                                            <div className="title">Nama</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data.customer_detail.full_name}
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Email</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data.customer_detail.email}
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Telepon</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {
                                                    data.customer_detail
                                                        .phone_number
                                                }
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Alamat</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data.customer_detail.address}
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">
                                                Metode Pembayaran
                                            </div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data.payment_channel.name}
                                            </div>
                                        </div>
                                        {data.shipper && (
                                            <div className="d-flex">
                                                <div className="title">
                                                    Metode Pengiriman
                                                </div>
                                                <div className="mr-2">:</div>
                                                <div className="desc-value">
                                                    {data.shipper.rate_name}
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                    {role === "supplier" ? (
                                        <Col xl="5" lg="5" md="5" sm="12">
                                            <h6 className="mb-2 border-bottom pb-2">
                                                Data Reseller
                                            </h6>
                                            <div className="d-flex">
                                                <div className="title">
                                                    Nama
                                                </div>
                                                <div className="mr-2">:</div>
                                                <div className="desc-value">
                                                    {data.reseller.full_name}
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="title">
                                                    Telepon
                                                </div>
                                                <div className="mr-2">:</div>
                                                <div className="desc-value">
                                                    {data.reseller.phone_number}
                                                </div>
                                            </div>
                                        </Col>
                                    ) : (
                                        <Col xl="5" lg="5" md="5" sm="12">
                                            <h6 className="mb-2 border-bottom pb-2">
                                                Data Supplier
                                            </h6>
                                            <div className="d-flex">
                                                <div className="title">
                                                    Nama
                                                </div>
                                                <div className="mr-2">:</div>
                                                <div className="desc-value">
                                                    {data.product.supplier.name}
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="title">
                                                    Telepon
                                                </div>
                                                <div className="mr-2">:</div>
                                                <div className="desc-value">
                                                    {
                                                        data.product.supplier
                                                            .phone_cs
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                    <Col xl="12">
                                        <div className="text-center">
                                            <div className="mb-4">
                                                <div className="order-picture">
                                                    <h6>Pemesanan</h6>
                                                    <img
                                                        src={
                                                            data.product
                                                                .product_image[0]
                                                                .img_url
                                                        }
                                                        alt=""
                                                        className="img-fluid mb-3"
                                                    />
                                                    <h4 className="text-center">
                                                        {data.product.name}
                                                    </h4>
                                                    <h4 className="text-center">
                                                        {formatMoney(
                                                            data.product_fee
                                                        )}
                                                    </h4>
                                                    {data.is_rated && (
                                                        <div
                                                            className="d-block mx-auto"
                                                            style={{
                                                                width: "max-content",
                                                            }}
                                                        >
                                                            <Rating
                                                                size={"24px"}
                                                                totalStars={5}
                                                                rating={
                                                                    data.product
                                                                        .rating
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <div className="order-title">
                                                    Jumlah
                                                </div>
                                                <div className="mr-2">:</div>
                                                <div
                                                    className="desc-value"
                                                    style={{ fontSize: 16 }}
                                                >
                                                    {data.total_amount} Barang
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <span>Spesifikasi Pemesanan :</span>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Warna</th>
                                        <th>Ukuran</th>
                                        <th>Jumlah Barang</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{data?.product?.spec[0]?.color}</td>
                                        <td>{data?.product?.spec[0]?.size}</td>
                                        <td>{data?.total_amount}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div className="mb-4">
                                <div className="title mb-1">
                                    Catatan Pembeli:{" "}
                                </div>
                                <div style={{ fontSize: 14 }}>
                                    {data.customer_detail.address_direction}
                                </div>
                            </div>
                        </div>

                        <div className="desc-transaction">
                            {orderIdNumber !== "" && (
                                <div className="desc-transaction__detail">
                                    <div>Order ID</div>
                                    <div>{orderIdNumber}</div>
                                </div>
                            )}

                            <div className="desc-transaction__detail">
                                <div>Nomor Transaksi</div>
                                <div style={{ overflow: "auto" }}>
                                    {data.transaction_code}
                                </div>
                            </div>
                            <div className="desc-transaction__detail ">
                                <div>Tanggal Transaksi</div>
                                <div>{transactionDate}</div>
                            </div>
                        </div>

                        <div className="desc-transaction__total">
                            <div>Total Transaksi</div>
                            <div>{formatMoney(data?.total_cost)}</div>
                        </div>
                        {role === "supplier" ? (
                            <div className="desc-transaction__total">
                                <div>Total Pendapatan</div>
                                <div>{formatMoney(totalIncome)}</div>
                            </div>
                        ) : (
                            <div className="desc-transaction__total">
                                <div>Komisi</div>
                                <div>{formatMoney(totalCommission)}</div>
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="desc">
                            <div className="mb-4">
                                <Row className="justify-content-between">
                                    <Col xl="6" lg="6" md="6" sm="12">
                                        <h6 className="mb-2 border-bottom pb-2">
                                            Data Pembeli
                                        </h6>
                                        <div className="d-flex">
                                            <div className="title">Nama</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Email</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Telepon</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Alamat</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">
                                                Metode Pembayaran
                                            </div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">
                                                Metode Pengiriman
                                            </div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="5" lg="5" md="5" sm="12">
                                        <h6 className="mb-2 border-bottom pb-2">
                                            Data{" "}
                                            {role === "supplier"
                                                ? "Reseller"
                                                : "Supplier"}
                                        </h6>
                                        <div className="d-flex">
                                            <div className="title">Nama</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="170px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Telepon</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="170px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="12">
                                        <div className="text-center">
                                            <div className="mb-4">
                                                <div className="order-picture">
                                                    <h6>Pemesanan</h6>

                                                    <SkeletonLoading
                                                        width="200px"
                                                        height="200px"
                                                        style={{
                                                            margin: "10px auto",
                                                        }}
                                                    />
                                                    <h4 className="text-center">
                                                        <SkeletonLoading
                                                            width="200px"
                                                            height="25px"
                                                            style={{
                                                                margin: "10px auto",
                                                            }}
                                                        />
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <div className="order-title">
                                                    Jumlah
                                                </div>
                                                <div className="mr-2">:</div>
                                                <div
                                                    className="desc-value"
                                                    style={{ fontSize: 16 }}
                                                >
                                                    <SkeletonLoading
                                                        width="200px"
                                                        height="25px"
                                                        style={{
                                                            marginBottom: 10,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mb-4">
                                <div className="title mb-1">
                                    Catatan Pembeli:{" "}
                                </div>
                                <div style={{ fontSize: 14 }}>
                                    <SkeletonLoading
                                        width="200px"
                                        height="25px"
                                        style={{ marginBottom: 10 }}
                                    />
                                </div>
                            </div>
                            {/* <span>JNE REG</span> */}
                        </div>

                        <div className="desc-transaction">
                            <div className="desc-transaction__detail">
                                <div>Order ID</div>
                                <div>
                                    <SkeletonLoading
                                        width="200px"
                                        height="25px"
                                        style={{ marginBottom: 10 }}
                                    />
                                </div>
                            </div>
                            <div className="desc-transaction__detail">
                                <div>Nomor Transaksi</div>
                                <div>
                                    <SkeletonLoading
                                        width="200px"
                                        height="25px"
                                        style={{ marginBottom: 10 }}
                                    />
                                </div>
                            </div>
                            <div className="desc-transaction__detail ">
                                <div>Tanggal Transaksi</div>
                                <div>
                                    <SkeletonLoading
                                        width="200px"
                                        height="25px"
                                        style={{ marginBottom: 10 }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="desc-transaction__total">
                            <div>Total Transaksi</div>
                            <div>
                                <SkeletonLoading
                                    width="100px"
                                    height="30px"
                                    style={{ marginBottom: 10 }}
                                />
                            </div>
                        </div>
                        {role === "supplier" ? (
                            <div className="desc-transaction__total">
                                <div>Total Pendapatan</div>
                                <div>
                                    <SkeletonLoading
                                        width="100px"
                                        height="30px"
                                        style={{ marginBottom: 10 }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="desc-transaction__total">
                                <div>Komisi</div>
                                <div>
                                    <SkeletonLoading
                                        width="100px"
                                        height="30px"
                                        style={{ marginBottom: 10 }}
                                    />
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </ModalBody>
        </Style>
    );
};
export default ModalData;
