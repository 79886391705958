import React, { useState, useEffect } from "react";
import Modal from "react-modal";
// import { PaymentStyle } from "./index.style";
import { useStore } from "../../../../../Reducers";
import service from "../../index.service";
import IconRight from "../../../../../Components/Assets/icon/arrow-step-right.svg";
import IconBack from "../../../../../Components/Assets/icon/arrow-left.svg";

const customStyles = {
    content: {
        position: 'fixed',
        padding: "0",
        margin: 'auto 0',
        width: '100%',
        height: '100%',
        inset: '0',
    },
};

const DEFAULT_LIMIT_DATA = 200;
const ModalDelivery = ({
    mobilePayment = false,
    paymentChannel,
    setMobilePayment = () => { },
    setPaymentChannel = () => { },
    headerShow,
    setHeaderShow = () => {},
    setPaymentMobile = () => {},
}) => {
    const { dispatch, state } = useStore();
    
    const [modalShow, setModalShow] = useState(false);
    const [paymentName, setPaymentName] = useState("Pilih Pembayaran")
    const [payment, setPayment] = useState([]);
    // eslint-disable-next-line
    const [eWallet, setWallet] = useState(false);
    const [isFirstGet, setFirstGet] = React.useState(true);

    const handleShowModal = () => {
        setModalShow(!modalShow);
        setHeaderShow(!headerShow);
    }
    const handleChoicePayment = (item) => {
        setPaymentMobile(true);
        setPaymentChannel(item);
        setPaymentName(item.name);
        setModalShow(false);
        setHeaderShow(!headerShow);
    };
    useEffect(() => {
        if (isFirstGet) {
            let params = {
                size: DEFAULT_LIMIT_DATA,
            };
            service.paymentMethod({ dispatch, params });
            setFirstGet(false);
        }
    }, [isFirstGet, dispatch]);
    useEffect(() => {
        if (state?.user?.paymentMethod.isSuccess) {
            setPayment(
                state.user.paymentMethod.data.content.filter(
                    (item) => item.publish === true
                )
            );
            setWallet(
                state.user.paymentMethod.data.content.filter(
                    (item) => item.type === "E-wallet"
                )
            );
        }
    }, [state, setWallet]);
    const virtual = payment.filter((item) => item.type === "Virtual Account");
    const ewallet = payment.filter(
        (item) =>
            item.type === "e-wallet" ||
            item.type === "E-Wallet" ||
            item.type === "E-wallet"
    );
    const gerai = payment.filter((item) => item.type === "Gerai Retail");

    return (
        <div style={{borderTop:"1.5px solid #e8e8e8",borderBottom:"1.5px solid #e8e8e8"}} className="py-3">
            <div onClick={handleShowModal}>
                {paymentChannel !== false ?
              <div className="d-flex justify-content-between px-3">
                    <p className="m-0 h4 font-semibold pt-1">{paymentChannel?.type}</p>
                    <img src={paymentChannel?.image} alt="" style={{maxWidth:"30%",margin:"0 auto", maxHeight:26}}/>
                    <img src={IconRight} alt="" />
              </div>
              :
              <div className="d-flex justify-content-between px-3">
                <p className="m-0 h5 font-semibold">{paymentName}</p>
                <img src={IconRight} alt="" />
              </div>
            } 
            </div>
            <Modal
                isOpen={modalShow}
                onRequestClose={handleShowModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="d-flex justify-content-between my-3 py-3 px-2" style={{boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.08)", borderRadius:"0 0 8px 8px"}}>
                    <img src={IconBack} alt="" onClick={handleShowModal}/>
                    <h3 style={{ fontWeight: 700, fontSize: 20, paddingRight:"20%" }}>Metode Pembayaran</h3>
                </div>
                <div className="px-3">
                    <div>
                        {virtual && virtual.length > 0 && (
                            <div id="payment_method">
                                <div className="list-payment" id="listPayment">
                                    <div className="payment-group">
                                        <h6 className="payment-title h3 font-bold">
                                            Virtual Account
                                        </h6>
                                        {virtual.map((item, key) => {
                                            return (
                                                <div
                                                    key={key}
                                                    style={{
                                                        padding: "2%",
                                                        margin: "20px 0",
                                                        border: "1px solid #ddd",
                                                        borderRadius: 20,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "flex-start",
                                                        height: 66,
                                                    }}
                                                    onClick={() => handleChoicePayment(item)}
                                                >
                                                    <img
                                                        style={{
                                                            alignSelf: "center",
                                                            maxWidth: 64,
                                                            maxHeight: 50,
                                                            objectFit: "contain",
                                                            margin: 16,
                                                        }}
                                                        src={item.image}
                                                        alt={item.name}
                                                        className="img-fluid"
                                                    />
                                                    <div className="d-flex flex-column">
                                                        <p className="my-auto mx-3 h4">{item.name}</p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                        {ewallet && ewallet.length > 0 && (
                            <div>
                                <div className="list-payment" id="listPayment">
                                    <div className="payment-group">
                                        <h6 className="payment-title h3 font-bold">e-Wallet</h6>
                                        {ewallet.map((item, key) => {
                                            return (
                                                <div
                                                    key={key}
                                                    style={{
                                                        padding: "2%",
                                                        margin: "20px 0",
                                                        border: "1px solid #ddd",
                                                        borderRadius: 20,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "flex-start",
                                                        height: 66,
                                                    }}
                                                    onClick={() => handleChoicePayment(item)}
                                                >
                                                    <img
                                                        style={{
                                                            alignSelf: "center",
                                                            maxWidth: 64,
                                                            maxHeight: 48,
                                                            objectFit: "contain",
                                                            margin: 16,
                                                        }}
                                                        src={item.image}
                                                        alt={item.name}
                                                        className="img-fluid"
                                                    />
                                                    <div className="d-flex flex-column">
                                                        <p className="my-auto mx-3 h4">{item.name}</p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                        {gerai && gerai.length > 0 && (
                            <div>
                                <div className="list-payment">
                                    <div className="payment-group">
                                        <h6 className="payment-title h3 font-bold">Gerai Retail</h6>
                                        {gerai.map((item, key) => {
                                            return (
                                                <div
                                                    key={key}
                                                    style={{
                                                        padding: "2%",
                                                        margin: "20px 0",
                                                        border: "1px solid #ddd",
                                                        borderRadius: 20,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "flex-start",
                                                        height: 66,
                                                    }}
                                                    onClick={() => handleChoicePayment(item)}
                                                >
                                                    <img
                                                        style={{
                                                            alignSelf: "center",
                                                            maxWidth: 64,
                                                            maxHeight: 48,
                                                            objectFit: "contain",
                                                            margin: 16,
                                                        }}
                                                        src={item.image}
                                                        alt={item.name}
                                                        className="img-fluid"
                                                    />
                                                    <div className="d-flex flex-column">
                                                        <p className="my-auto mx-3 h4">{item.name}</p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default ModalDelivery;