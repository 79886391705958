import React from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import RoutesList from "./Router/RoutesList";
import CodeSplit from "./Components/CodeSplit";
import LocalStorage from "./Utils/LocalStorage";
import styled from "styled-components";
import Logo from "./Components/Assets/icon/Lakugan_Logo_Circle.png";
import FirstLoad from "./Components/Loading/FirstLoad";
import TagManager from 'react-gtm-module'
// import ReactGA from "react-ga";

const tagManagerArgs = {
    gtmId: 'GTM-KKWLRTW'
}

// const TRACKING_ID = "UA-217858785-1"; // OUR_TRACKING_ID
const LoadingStyle = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .loading {
        width: 165px;
        margin: auto;
        &-border {
            position: fixed;
            z-index: 3;
        }
        @media (max-width: 575px) {
            width: 75px;
        }
    }
    @keyframes zoominoutsinglefeatured {
        0% {
            transform: scale(1, 1);
        }
        50% {
            transform: scale(1.2, 1.2);
        }
        100% {
            transform: scale(1, 1);
        }
    }
    .img-fluid {
        animation: zoominoutsinglefeatured 2s infinite;
    }
`;
const App = (props) => {
    const expired = LocalStorage().get("EX");
    const token = LocalStorage().get("auth");
    React.useEffect(() => {
        var countDownDate = new Date(expired).getTime();

        // Update the count down every 1 second
        var x = setInterval(function () {
            var now = new Date().getTime();
            var distance = countDownDate - now;
            if (distance < 0) {
                clearInterval(x);
                LocalStorage().remove("EX");
                LocalStorage().remove("auth");
                LocalStorage().remove(`${token?.access_token}`);
            }
        }, 1000);
    }, [expired, token]);

    React.useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        // ReactGA.initialize(TRACKING_ID);
        // ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Router>
            <Switch>
                {RoutesList.map((item) => {
                    return (
                        <Route
                            exact
                            key={item.path}
                            path={item.role + item.path}
                            component={(props) => (
                                <CodeSplit load={() => item.layout}>
                                    {(Component) => {
                                        return Component === null ? (
                                            <LoadingStyle>
                                                <div className="loading-border">
                                                    <FirstLoad />
                                                </div>
                                                <div className="loading">
                                                    <img
                                                        src={Logo}
                                                        alt="Lakugan"
                                                        className="img-fluid"
                                                    />
                                                </div>
                                            </LoadingStyle>
                                        ) : (
                                            <Component item={item} {...props} />
                                        );
                                    }}
                                </CodeSplit>
                            )}
                        />
                    );
                })}

                <Redirect to="/404" />
            </Switch>
        </Router>
    );
};

export default App;
