import React from "react";
import styled from "styled-components";
import ModalComplaint from "../Modal/Complaint";
import CommonTable from "../../../../Components/Molekul/CommonTable";

const Style = styled.div``;
const ConfirmationCard = () => {
    const [isShow, setShow] = React.useState(false);
    const [dummyData, setDataDummy] = React.useState([
        {
            name: "Budi",
            address: "Jalan. tomang raya No. 29",
            email: "user@mail.com",
            phone: "081234567890",
            phone_reseller: "081234562233",
            item: "Jaket Kulit",
            total: "1",
            color: "Hitam",
            size: "XL",
            reseller: "Reseller",
            payment: "200000",
            metode: "Virtual Account Bank BCA",
            status: "Batal",
        },
    ]);
    const handlerViewDetail = (item) => {
        setShow(!isShow);
    };
    return (
        <Style shadow="md">
            <CommonTable
                no_data="Tidak ada transaksi"
                dummyData={dummyData}
                setDataDummy={setDataDummy}
                configuration={{
                    searchInput: true,
                    nav: {
                        hasSearch: true,
                        filter: [
                            {
                                icon: "fas fa-sort-amount-down",
                                type: "dropdown",
                                value: [
                                    {
                                        value: "date_az",
                                        label: "Date Desc",
                                        key: "order-date",
                                    },
                                    {
                                        value: "date_za",
                                        label: "Date Asc",
                                        key: "order-date",
                                    },
                                    {
                                        value: "title_za",
                                        label: "Title Asc",
                                        key: "sort",
                                    },
                                    {
                                        value: "title_az",
                                        label: "Title Desc",
                                        key: "sort",
                                    },
                                    {
                                        value: "price_az",
                                        label: "Price ASC",
                                        key: "sort",
                                    },
                                    {
                                        value: "price_za",
                                        label: "Price DESC",
                                        key: "sort",
                                    },
                                ],
                            },
                        ],
                    },
                    // service: {
                    //     api: service.missionList,
                    //     group: "mission",
                    //     key: "missionList"
                    // },
                    optionTable: [
                        {
                            headName: "No",
                            type: "number",
                        },
                        {
                            headName: "Nama Pemesan",
                            type: "text",
                            fieldName: "name",
                        },
                        {
                            headName: "Nomor Telepon",
                            type: "text",
                            fieldName: "phone",
                        },
                        {
                            headName: "Nama Produk",
                            type: "text",
                            fieldName: "item",
                        },
                        {
                            headName: "Jumlah",
                            type: "text",
                            fieldName: "total",
                        },
                        {
                            headName: "Total Pembayaran",
                            type: "price",
                            fieldName: "payment",
                        },
                        {
                            headName: "Status",
                            type: "text",
                            fieldName: "status",
                        },
                        {
                            headName: "",
                            className: "text-center",
                            type: "actions",
                            options: [
                                {
                                    actionType: "isDeleteModal",
                                    name: "Tambah Keluhan",
                                    color: "outline-primary",
                                    function: (item) => handlerViewDetail(item),
                                    renderItem: () => {},
                                    field: "id",
                                },
                            ],
                        },
                    ],
                }}
            ></CommonTable>
            <ModalComplaint toggle={handlerViewDetail} isShow={isShow} />
        </Style>
    );
};
export default ConfirmationCard;
