import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import styled from "styled-components";

const Style = styled(Card)`
    margin-bottom: 45px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    img {
        margin-bottom: 15px;
    }
    .article {
        &-time {
            color: #aaa;
            font-size: 12px;
            display: block;
            margin-bottom: 10px;
        }
        &-title {
            font-size: 16px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /* / autoprefixer: off / */
            -webkit-box-orient: vertical;
            /* / autoprefixer: on / */
            text-overflow: ellipsis;
            overflow: hidden;
        }
        &-desc {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            /* / autoprefixer: off / */
            -webkit-box-orient: vertical;
            /* / autoprefixer: on / */
            font-size: 14px;
            text-overflow: ellipsis;
            overflow: hidden;
            span {
                display: block;
            }
        }
    }
    .article-detail {
        margin-top: 15px;
        text-align: right;
        a {
            font-size: 14px;
            &:hover {
                text-decoration: underline;
                color: #007bff;
            }
        }
    }
`;

const CardArticle = ({ data }) => {
    const month = [
        "Januari",
        "Febuari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
    ];
    return (
        <Style>
            <CardBody>
                <img
                    src={data.article_image[0].img_url}
                    alt={data.article_image[0].name}
                    className="img-fluid"
                />
                <time className="article-time">
                    {String(new Date(data.created).getDate()).padStart(2, "0")}{" "}
                    {month[new Date(data.created).getMonth()]}{" "}
                    {new Date(data.created).getFullYear()}
                </time>
                <div>
                    <h4 className="article-title">{data.title}</h4>
                    <div
                        className="article-desc"
                        dangerouslySetInnerHTML={{
                            __html: data.content,
                        }}
                    />
                </div>
                <div className="article-detail">
                    <Link
                        to={`/blog/${data.id}/${data.title.replace(/ /g, "-")}`}
                    >
                        Baca selengkapnya
                    </Link>
                </div>
            </CardBody>
        </Style>
    );
};
export default CardArticle;
