import React from "react";
import Header from "../Header";
import Footer from "../../../../Components/Footer";
import service from "../index.service";
import { useStore } from "../../../../Reducers";
import { useHistory } from "react-router-dom";
import GrowingLoading from "../../../../Components/Loading/GrowingLoading";
import Logo from "../../../../Components/Assets/Images/Lakugan_transparent.png";

const TransactionPayment = (
    {
        match: {
            params: { timestamp },
        },
    },
    props
) => {
    const history = useHistory();
    const { dispatch, state } = useStore();

    const [isFirstGet, setFirstGet] = React.useState(true);

    const [isLoading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (isFirstGet) {
            setLoading(true);
            service.transactionCode({ dispatch, params: timestamp });
            setFirstGet(false);
        }
    }, [isFirstGet, dispatch, timestamp]);
    React.useEffect(() => {
        if (state?.user?.transaction?.isSuccess) {
            history.push(`/payment/${timestamp}`);
            setLoading(false);
        }
        if (state?.user?.transaction?.isError) {
            history.push("/404");
            setLoading(false);
        }
    }, [state, history, timestamp]);
    return (
        <React.Fragment>
            <Header lakugan={Logo} history={"order/beli-sekarang"} />

            <GrowingLoading active={isLoading} />

            <Footer footerTop={false} />
        </React.Fragment>
    );
};
export default TransactionPayment;
