import React from "react";
import Tab from "./Tab";
import { Card, CardBody, Col, Row } from "reactstrap";
import styled from "styled-components";
import DoneCard from "./Card/DoneCard";
const Style = styled(Card)`
    border: unset;
    .border-bottom {
        margin-bottom: 35px;
    }
`;

function DoneTransactionReseller() {
    return (
        <Style>
            <CardBody>
                <div className="border-bottom">
                    <Tab />
                </div>
                <Row>
                    <Col xl="12">
                        <DoneCard />
                    </Col>
                </Row>
            </CardBody>
        </Style>
    );
}

export default DoneTransactionReseller;
