import { servicesAction } from "../../../Reducers";

const service = {
    blogList: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/article/?sort=created",
            method: "GET",
            reducer: "service",
            group: "user",
            key: "blogList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
            },
        });
    },
    blogDetail: ({ dispatch, slug }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/article/${slug}`,
            method: "GET",
            reducer: "service",
            group: "user",
            key: "blogDetail",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
            },
        });
    },
    blogDetailClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "blogDetail",
            type: "CLEAR",
        });
    },
};
export default service;
