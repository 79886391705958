import { servicesAction } from "../../../Reducers";

const service = {
    buyProduct: ({ dispatch, data, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/product/list/buy/",
            qs: true,
            method: "POST",
            data: data,
            params: params,
            reducer: "service",
            group: "user",
            key: "buyProduct",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
            },
        });
    },
    transactionDone: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/done`,
            params: params,
            method: "GET",
            qs: true,
            reducer: "service",
            group: "user",
            key: "transactionDone",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
            },
        });
    },
    transactionCode: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/transaction/${params}`,
            method: "GET",
            reducer: "service",
            group: "user",
            key: "transaction",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
            },
        });
    },
    getProduct: ({ dispatch, slug }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/product/list/${slug}`,
            method: "GET",
            reducer: "service",
            group: "user",
            key: "product",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    paymentMethod: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/payment/method",
            method: "GET",
            qs: true,
            params: params,
            reducer: "service",
            group: "user",
            key: "paymentMethod",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    tracking: ({ dispatch, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/shipper/tracking?id=${id}`,
            method: "GET",
            reducer: "service",
            group: "shipper",
            key: "tracking",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Shipper sudah di pesan",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
            },
        });
    },
    getProvince: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/province?size=34",
            method: "GET",
            reducer: "service",
            qs: true,
            group: "user",
            key: "province",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    getCity: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/city/${params}?size=100`,
            method: "GET",
            reducer: "service",
            group: "user",
            key: "city",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    getDistrict: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/district/${params}?size=100`,
            method: "GET",
            reducer: "service",
            group: "user",
            key: "district",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    getArea: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/subdistrict/${params}?size=100`,
            method: "GET",
            reducer: "service",
            group: "user",
            key: "subdistrict",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    getShipper: ({ dispatch, id, product, qty, coords }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/shipper/domestic_rate/?customer_sub_district=${id}&product_id=${product}&qty=${qty}&destination_coord=${coords}`,
            method: "GET",
            reducer: "service",
            group: "user",
            key: "shipper",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    ratingProduct: ({ dispatch, data, transaction_code }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/product/${transaction_code}/rating`,
            qs: true,
            method: "POST",
            data: data,
            reducer: "service",
            group: "user",
            key: "productRating",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Terimakasih sudah memberikan penilaian pada produk ini",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
            },
        });
    },
    orderDetail: ({ dispatch, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/biteship/pickup-order/${id}`,
            method: "GET",
            reducer: "service",
            group: "shipper",
            key: "orderId",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi Kesalahan, Harap mencoba beberapa saat lagi, Terimakasih!",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Silakan lengkapi data terlebih dahulu",
                },
            },
        });
    },
    voucherList: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/voucher/customerVoucher`,
            method: "GET",
            params: params,
            reducer: "service",
            group: "voucher",
            key: "voucherList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    voucherListClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            type: "CLEAR",
            reducer: "service",
            group: "voucher",
            key: "voucherList",
        });
    },
    orderDetailClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "shipper",
            key: "orderId",
            type: "CLEAR",
        });
    },
    transactionCodeClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "transaction",
            type: "CLEAR",
        });
    },
    transactionDoneClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "transactionDone",
            type: "CLEAR",
        });
    },
    ratingProductClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "productRating",
            type: "CLEAR",
        });
    },
    buyProductClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "buyProduct",
            type: "CLEAR",
        });
    },
    getShipperClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "shipper",
            type: "CLEAR",
        });
    },
    getClearDistrict: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "district",
            type: "CLEAR",
        });
    },
    getClearArea: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "area",
            type: "CLEAR",
        });
    },
    productClearList: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "product",
            type: "CLEAR",
        });
    },
    trackingClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "shipper",
            key: "tracking",
            type: "CLEAR",
        });
    },
    // SERVICE API GET AUTH
    token: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/oauth/token",
            method: "POST",
            data: data,
            v1: false,
            formData: true,
            type: "application/x-www-form-urlencoded",
            reducer: "service",
            group: "user",
            key: "token",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
    // SERVICE GET PROFILE USER
    profile: ({ dispatch }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/profile/me",
            method: "GET",
            reducer: "service",
            group: "user",
            token: true,
            key: "profile",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Bad Request",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
    clearGeoLocation: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "location",
            key: "coords",
            type: "CLEAR",
        });
    },
    getCourierBiteship: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/biteship/rates/couriers`,
            method: "POST",
            data: data,
            reducer: "service",
            group: "user",
            key: "biteship",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    getBiteshipClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "biteship",
            type: "CLEAR",
        });
    },
    pickupOrder: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxClear({
            url: "/biteship/pickup-order/now",
            method: "POST",
            reducer: "service",
            group: "user",
            token: true,
            key: "biteship",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Bad Request",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    }
};
export default service;
