/* eslint-disable */
import React, { useCallback } from "react";
import { Col, FormGroup, Input, Row, Label, Alert } from "reactstrap";
import CommonTable from "../../../../Components/Molekul/CommonTable";
import Form from "../../../../Components/Molekul/FormValidation";
import Button from "../../../../Components/Particle/Button";
import FormPrice from "../../../../Components/Particle/Form/FormPrice";
import FormText from "../../../../Components/Particle/Form/FormText";
import service from "../index.service";
import GrowingLoading from "../../../../Components/Loading/GrowingLoading";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../../Reducers";
import TableVolume from "./TableVolume";
const CreateProduct = () => {
    const { dispatch, state } = useStore();
    const [isSpec, setSpec] = React.useState(false);
    const [validationClick, setValidationClick] = React.useState(true);
    const [validateForm, setValidateForm] = React.useState(true);
    const [isErrorSpec, setErrorSpec] = React.useState(false);
    const [formPiece, setFormPiece] = React.useState(false);
    const [nominalSpecialCut, setNominalSpecialCut] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [countDay, setCountDay] = React.useState(0);
    const [isVoucher, setVoucher] = React.useState(false);
    const [message, setMessage] = React.useState(false);
    const [stockVoucher, setStockVoucher] = React.useState({
        name: "total_stock",
        value: "",
        type: "number",
        label: "Stock Voucher",
        required: true,
    });
    const [formDuration, setFormDuration] = React.useState(false);

    const history = useHistory();

    const [form, setForm] = React.useState([
        {
            name: "nameProduct",
            label: "Nama Produk",
            required: true,
            type: "text",
            action: "text",
            col: 4,
            valid: "Nama produk tidak boleh kosong",
            value: "",
            status: false,
        },
        {
            name: "price",
            type: "price",
            label: "Harga",
            action: "price",
            currencyLogo: "Rp. ",
            col: 4,
            value: 0,
            status: false,
        },
        {
            name: "category",
            type: "select",
            required: true,
            action: "select",
            label: "Kategori",
            col: 4,
            service: {
                api: service.category,
                group: "category",
                key: "category",
            },
            isMulti: false,
            valid: "Pilih kategori",
            value: "",
            status: false,
        },
        {
            name: "image-multiple",
            type: "image",
            required: true,
            label: "Gambar Produk",
            action: "uploadImageMultiple",
            col: 12,
            valid: "Harap masukkan gambar",
            value: [],
            status: false,
            max: 10,
        },
        {
            name: "description",
            required: true,
            height: 300,
            maxLength: 2000,
            label: "Deskripsi Produk",
            action: "html",
            col: 12,
            valid: "Deskripsi produk tidak boleh kosong",
            value: "",
            status: false,
        },
    ]);
    const [validTable, setValidTable] = React.useState([
        {
            id: 1,
            weight: false,
            height: false,
            width: false,
            length: false,
            total_stock: false,
        },
    ]);
    const [specification, setSpecification] = React.useState([
        {
            id: 1,
            color: "",
            size: "",
            total_stock: 0,
            weight: 0.0,
            height: 0.0,
            width: 0.0,
            length: 0.0,
        },
    ]);
    const handleAddSpecification = () => {
        let obj = {
            id: specification.length + 1,
            color: "",
            size: "",
            total_stock: 0,
            weight: 0.0,
            height: 0.0,
            width: 0.0,
            length: 0.0,
        };
        let validObj = {
            id: validTable.length + 1,
            weight: false,
            height: false,
            width: false,
            length: false,
            total_stock: false,
        };

        setSpecification((prevState) => {
            return [...prevState, obj];
        });
        setValidTable((prevState) => {
            return [...prevState, validObj];
        });
        // setDataDummy(obj);
    };
    const handleDeleteItem = (item, valid) => {
        // e.preventDefault();
        const items = specification;
        const valueToRemove = item;
        const filteredItems = items.filter(function (item) {
            return item !== valueToRemove;
        });
        const validItems = validTable;
        const valueToRemoveValid = valid;
        const filteredItemsValid = validItems.filter(function (item) {
            return item !== valueToRemoveValid;
        });
        // setSpecification(specification.splice(0, item.id - 1));
        // setLoadingRemove(true);
        onRemoveData(filteredItems);
        onRemoveDataValid(filteredItemsValid);
    };
    const onRemoveData = useCallback((value) => {
        return setSpecification(value);
    });

    const onRemoveDataValid = useCallback((value) => {
        return setValidTable(value);
    });
    const handleCheckPiece = (e) => {
        let checked = e.target.checked;
        setFormPiece(checked);
        if (!checked) {
            setNominalSpecialCut(0);
        }
    };
    const handleCheckDuration = (e) => {
        let checked = e.target.checked;
        setFormDuration(checked);
        if (!checked) {
            setCountDay(0);
        }
    };

    const onGetValue = (item) => {
        setErrorSpec(false);
        specification[item.id - 1][item.name] = item.value;
        if (
            item.name === "height" ||
            item.name === "total_stock" ||
            item.name === "length" ||
            item.name === "width" ||
            item.name === "weight"
        ) {
            validTable[item.id - 1][item.name] =
                item.value === "" ? false : true;
        }
        setValidTable(validTable);
        setSpecification(specification);
    };
    const onGetValueStockVoucher = (item) => {
        stockVoucher.value = item.value;
        setStockVoucher(stockVoucher);
    };
    const onGetValueSpecialCut = (item) => {
        setNominalSpecialCut(item.value);
    };
    const onGetValuePreOrder = (item) => {
        setCountDay(item.value);
    };
    const handleCreateProduct = (e) => {
        setMessage(false);
        let isError = false;
        let isErrorColor = false;
        e.preventDefault();
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }

        if (isVoucher) {
            isErrorColor = false;
        } else {
            for (let key in validTable) {
                if (!validTable[key].weight) {
                    isErrorColor = true;
                }
                if (!validTable[key].height) {
                    isErrorColor = true;
                }
                if (!validTable[key].width) {
                    isErrorColor = true;
                }
                if (!validTable[key].length) {
                    isErrorColor = true;
                }
                if (!validTable[key].total_stock) {
                    isErrorColor = true;
                }
                if (
                    validTable[key].weight &&
                    validTable[key].total_stock &&
                    validTable[key].height &&
                    validTable[key].width &&
                    validTable[key].length
                ) {
                    isErrorColor = false;
                }
            }
        }

        setErrorSpec(isErrorColor);
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError && !isErrorColor) {
            if (!formPiece) {
                setLoading(true);
                let data = {
                    name: form[0].value,
                    pre_order: formDuration,
                    count_day: countDay,
                    description: form[4].value,
                    price: form[1].value,
                    rate: 0.0,
                    is_special_cut: false,
                    nominal_special_cut: 0,
                    category: { id: form[2].value.value },
                    spec: specification.map((item) => {
                        return {
                            color: item.color || "",
                            size: item.size || "",
                            total_stock: isVoucher
                                ? stockVoucher.value
                                : item.total_stock,
                            weight: item.weight,
                            height: item.height,
                            width: item.width,
                            length: item.length,
                        };
                    }),
                    product_image: form[3].value.map((item) => {
                        return { name: item.name, img_url: item.link };
                    }),
                };
                service.createProduct({ dispatch, data });
            }
            if (formPiece) {
                if (nominalSpecialCut !== 0) {
                    setLoading(true);
                    let data = {
                        name: form[0].value,
                        description: form[4].value,
                        pre_order: formDuration,
                        count_day: countDay,
                        price: form[1].value,
                        rate: 0.0,
                        is_special_cut: formPiece,
                        nominal_special_cut: nominalSpecialCut,
                        category: { id: form[2].value.value },
                        spec: specification.map((item) => {
                            return {
                                color: item.color || "",
                                size: item.size || "",
                                total_stock: isVoucher
                                    ? stockVoucher.value
                                    : item.total_stock,
                                weight: item.weight,
                                height: item.height,
                                width: item.width,
                                length: item.length,
                            };
                        }),
                        product_image: form[3].value.map((item) => {
                            return { name: item.name, img_url: item.link };
                        }),
                    };
                    service.createProduct({ dispatch, data });
                }
            }
        }
    };
    React.useEffect(() => {
        if (state?.product?.createProduct?.isSuccess) {
            setLoading(false);
            history.push("/supplier/products");
            service.createProductClear({ dispatch });
        }
        if (state?.product?.createProduct?.isError) {
            setMessage(state?.product?.createProduct.message.text);
            setLoading(false);
            service.createProductClear({ dispatch });
        }
    }, [state, dispatch]);
    const onCheckValue = (form) => {
        if (
            form[2].value.label === "Aksesoris Khusus" ||
            form[2].value.label === "Pulsa & Paket Data"
        ) {
            setSpec(true);
        } else {
            if (form[2].value.label === "Voucher Digital") {
                setVoucher(true);
                setSpec(true);
            } else {
                setVoucher(false);
                setSpec(false);
            }
        }
        if (form[3].value > 0) {
            setValidateForm(true);
            setValidationClick(true);
        }        
    };
    return (
        <div>
            <GrowingLoading active={loading} />
            <Row>
                <Col xl="12">
                    <h5 className="title mb-3">Tambah Produk</h5>
                </Col>
                {message && <Alert color="danger">{message}</Alert>}
                <Col xl="12">
                    <div className="product-desc">
                        <h6 className="sub-title">Deskripsi Produk</h6>
                        <Form
                            onCheckValue={onCheckValue}
                            setForm={setForm}
                            validateForm={validateForm}
                            validationClick={validationClick}
                            form={form}
                        />
                    </div>
                </Col>
                <Col xl="12">
                    {isVoucher ? (
                        <Row>
                            <Col sm="3" xs="6">
                                <FormText
                                    className="form-spec"
                                    label="Stock"
                                    // id={item.id}
                                    onGetValue={onGetValueStockVoucher}
                                    item={stockVoucher}
                                />
                            </Col>
                        </Row>
                    ) : (
                        <React.Fragment>
                            <h6 className="sub-title">Spesifikasi</h6>
                            <div className="mb-5 position-relative">
                                <TableVolume
                                    validTable={validTable}
                                    handleDeleteItem={handleDeleteItem}
                                    specification={specification}
                                    setSpecification={setSpecification}
                                    onGetValue={onGetValue}
                                />

                                {isErrorSpec && (
                                    <small className="text-danger valid-text">
                                        <i>Form bertanda bintang wajib diisi</i><br/>
                                        <i>Maksimal kapasitas volume 150cm x 100cm x 100cm</i>
                                    </small>
                                )}

                                <Button
                                    style={{ marginTop: 20 }}
                                    onClick={handleAddSpecification}
                                    color="primary"
                                    type="button"
                                    block
                                >
                                    + Tambah Spesifikasi
                                </Button>
                            </div>
                        </React.Fragment>
                    )}

                    <div>
                        <h6 className="sub-title">Spesifikasi Khusus</h6>
                        <Row>
                            <Col xl="6" md="6" sm="6">
                                <span className="label-specification">
                                    Pre Order
                                </span>
                                <div className="mb-2">
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                id="checkbox2"
                                                onChange={handleCheckDuration}
                                            />{" "}
                                            Durasi (Hari)
                                        </Label>
                                    </FormGroup>
                                </div>
                                {formDuration && (
                                    <FormGroup>
                                        <FormText
                                            onGetValue={onGetValuePreOrder}
                                            item={{
                                                value: countDay,
                                                type: "number",
                                                name: "day",
                                                status: formDuration
                                                    ? false
                                                    : true,
                                            }}
                                        />
                                    </FormGroup>
                                )}
                            </Col>
                            {isSpec && (
                                <Col xl="6" md="6" sm="6">
                                    <span className="label-specification">
                                        Pilih Jenis Potongan
                                        <span className="text-danger">*</span>
                                    </span>
                                    <div className="mb-2">
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    id="checkbox2"
                                                    onChange={handleCheckPiece}
                                                />{" "}
                                                Potongan Khusus
                                            </Label>
                                        </FormGroup>
                                    </div>
                                    {formPiece && (
                                        <FormGroup>
                                            <FormPrice
                                                id={"nominal_special_cut"}
                                                onGetValue={
                                                    onGetValueSpecialCut
                                                }
                                                item={{
                                                    value: nominalSpecialCut,
                                                    defaultValue: 0,
                                                    currencyLogo: "Rp ",
                                                    valid: "Masukan nominal bagi hasil yang diberikan untuk Lakugan",
                                                    status: false,
                                                }}
                                            />
                                        </FormGroup>
                                    )}
                                </Col>
                            )}
                        </Row>
                    </div>
                </Col>
            </Row>
            <div className="text-right">
                <Button
                    type="button"
                    color="primary"
                    onClick={handleCreateProduct}
                >
                    Tambah Produk
                </Button>
            </div>
        </div>
    );
};
export default CreateProduct;
