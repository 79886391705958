import React from "react";
import { Card, CardBody } from "reactstrap";
import Button from "../Particle/Button";
import styled from "styled-components";
import { color } from "../../Utils/Variable";
import StartIcon from "../Assets/icon/star-card.png";
import { formatMoney } from "../../Utils/PriceConverter";
import { Link } from "react-router-dom";
import DeleteIcon from "../Assets/icon/icon-delete.png";

const Style = styled.div`
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.08),
        -1px -1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    .card {
        width: max-content;
        border-radius: 15px 15px 0px 0px !important;
        max-height: 300px;
        position: relative;
        overflow: auto;
        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            h3 {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 0px;
            }
            i {
                color: ${color.primary};
                font-size: 18px;
            }
        }
        a {
            &:hover {
                text-decoration: unset;
            }
        }
        .btn-delete {
            font-size: 10px;
            border-radius: 20px;
            padding: 3px 25px;
        }
    }
    .info {
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);
        color: ${color.primary};
        background: #fff;
        padding: 10px;
        border-radius: 0 0 15px 15px;
        font-size: 12px;
        position: relative;
    }
`;
const CardStyle = styled.div`
    display: flex;
    justify-content: space-between;
    min-width: 300px;
    .product-zero {
        font-size: 16px;
        text-align: center;
        margin-bottom: 20px;
    }
    .card {
        &-image {
            position: relative;
            margin-bottom: 16px;
            text-align: center;
            margin-right: 20px;
        }
        &-price {
            font-weight: 500;
            font-size: 14px;
            color: #000000;
            margin-bottom: 5px;
        }
        &-city {
            font-weight: 400;
            font-size: 12px;
            color: #000000;
            margin-bottom: 5px;
        }
        &-rating {
            position: absolute;
            top: 0;
            right: 0;
            background: #fff;
            padding: 0 10px;
            display: flex;
            align-items: center;
            border-radius: 0px 0px 0px 12px;
            .rating {
                font-size: 14px;
                margin-right: 5px;
            }
        }
    }
    .img-product {
        width: 100px;
    }
    .product-name {
        font-weight: 600;
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 140px;
        margin-bottom: 10px;
        line-height: 1.3;
    }
`;

const ProductSupplier = ({ product = {} }) => {
    return (
        <CardStyle>
            <div style={{ display: "flex" }}>
                <div className="card-image">
                    <img
                        src={product.product_image[0].img_url}
                        alt={product.name}
                        className="img-fluid img-product"
                    />
                    <div className="card-rating">
                        <div className="rating">{product.rating}</div>
                        <img
                            src={StartIcon}
                            alt="rating"
                            className="img-fluid"
                        />
                    </div>
                </div>
                <div>
                    <div className="product-name">{product.name}</div>
                    <h4 className="card-price">{formatMoney(product.price)}</h4>
                    <div className="card-city">{product.supplier.city}</div>
                </div>
            </div>
        </CardStyle>
    );
};
const ProductReseller = ({
    product = {},
    isDelete,
    handleModalDelete = () => {},
    handleViewProduct = () => {},
}) => {
    return (
        <CardStyle>
            <div style={{ display: "flex" }}>
                <div className="card-image">
                    <img
                        src={product.product.product_image[0].img_url}
                        alt={product.product.name}
                        className="img-fluid img-product"
                    />
                    <div className="card-rating">
                        <div className="rating">{product.product.rating}</div>
                        <img
                            src={StartIcon}
                            alt="rating"
                            className="img-fluid"
                        />
                    </div>
                </div>
                <div>
                    <div className="product-name">
                        <Link
                            onClick={() => handleViewProduct(product)}
                            to={`/product/${product.code}`}
                        >
                            {product.product.name}
                        </Link>
                    </div>
                    <h4 className="card-price">
                        {formatMoney(product.product.price)}
                    </h4>
                    <div className="card-city">{product.store.city}</div>
                </div>
            </div>
            {isDelete && (
                <div style={{ marginLeft: 40 }}>
                    <Button
                        onClick={() => handleModalDelete(product.product)}
                        color="primary"
                        className="btn-delete"
                    >
                        Hapus
                    </Button>
                </div>
            )}
        </CardStyle>
    );
};

const ProductDropdown = ({
    productList = [],
    role = "",
    handleModalDelete = () => {},
    handleViewProduct = () => {},
}) => {
    const [isDelete, setDelete] = React.useState(false);
    const showDeleteButton = () => {
        setDelete(!isDelete);
    };
    return (
        <Style>
            <Card>
                <CardBody>
                    <div className="title">
                        <h3>Toko Saya</h3>
                        {productList.length > 0 && (
                            <React.Fragment>
                                {isDelete ? (
                                    <Button
                                        color="outline-primary"
                                        onClick={showDeleteButton}
                                        className="btn-delete"
                                    >
                                        Batalkan
                                    </Button>
                                ) : (
                                    <img
                                        src={DeleteIcon}
                                        style={{ cursor: "pointer", width: 20 }}
                                        onClick={showDeleteButton}
                                        alt="delete"
                                        className="img-fluid"
                                    />
                                )}
                            </React.Fragment>
                        )}
                    </div>
                    {productList.length > 0 ? (
                        productList.map((item, key) => {
                            return role === "ROLE_SUPPLIER" ? (
                                <ProductSupplier product={item} key={key} />
                            ) : (
                                <ProductReseller
                                    handleViewProduct={handleViewProduct}
                                    handleModalDelete={handleModalDelete}
                                    isDelete={isDelete}
                                    product={item}
                                    key={key}
                                />
                            );
                        })
                    ) : (
                        <CardStyle
                            style={{
                                height: 150,
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <div>
                                <h3 className="product-zero">
                                    Ayo tambahkan produk,
                                    <br />
                                    dan ambil komisi mu!
                                </h3>
                                <div className="text-center">
                                    <Link to="/reseller/tambah-produk">
                                        <Button size={"14"} color="primary">
                                            Cari Produk
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </CardStyle>
                    )}
                </CardBody>
            </Card>
            <div className="info">*Maksimal toko hanya 5</div>
        </Style>
    );
};
export default ProductDropdown;
