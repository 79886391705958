import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Form from "../../../../Components/Molekul/FormValidation";
import Button from "../../../../Components/Particle/Button";

const VoucherDigitalForm = ({
    isOpen = false,
    data = {},
    handleSendVoucher = () => {},
    toggle = () => {},
}) => {
    const [validationClick, setValidationClick] = React.useState(true);
    const [validateForm, setValidateForm] = React.useState(true);
    const [form, setForm] = React.useState([
        {
            type: "text",
            label: "Kode Voucher",
            required: true,
            action: "text",
            name: "voucher_code",
            placeholder: "Kode Voucher",
            col: 12,
            valid: "Kode voucher boleh kosong",
            value: "",
            status: false,
        },
        {
            type: "text",
            label: "Syarat dan Ketentuan",
            required: true,
            action: "text",
            name: "term_condition",
            placeholder: "Syarat dan Ketentuan",
            col: 12,
            valid: "Syarat dan ketentuan boleh kosong",
            value: "",
            status: false,
        },
        {
            type: "textarea",
            label: "Cara Penggunaan",
            required: true,
            action: "textarea",
            name: "address",
            placeholder: "Cara Penggunaan",
            col: 12,
            rows: 5,
            valid: "Harap tulis cara penggunaan voucher",
            value: "",
            status: false,
        },
    ]);
    const handlerSubmitData = () => {
        let isError = false;
        for (let key in form) {
            if (!form[key].status) {
                isError = true;
            }
        }
        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            let params = data.transaction_code.split("/");
            let transaction_id = params[1];
            let dataForm = {
                voucher_code: form[0].value,
                term_condition: form[1].value,
                usage: form[2].value,
            };
            handleSendVoucher({ data: dataForm, transaction_id });
        }
    };
    return (
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={toggle}>Voucher Digital</ModalHeader>
            <ModalBody>
                <Form
                    setForm={setForm}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={form}
                />
                <Button color="primary" onClick={handlerSubmitData}>
                    Kirim
                </Button>
            </ModalBody>
        </Modal>
    );
};
export default VoucherDigitalForm;
