/* eslint-disable */
import React from "react";
import styled from "styled-components";
import { useStore } from "../../../../Reducers";
import { color } from "../../../../Utils/Variable";
import service from "../index.service";

const Style = styled.div`
    /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1); */
    /* border: 1px solid #ddd; */
    margin-bottom: 30px;
    border-radius: ${(props) => (props.active ? "7px 7px 0 0" : "7px")};
    padding: 20px;
    position: relative;
    .list-payment {
        &__item {
            display: flex;
            align-items: end;

            transition: all 0.25s ease;
        }
        &__check {
            cursor: pointer;
        }
    }
    .label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
        padding-left: 30px;
        margin-bottom: 0;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default radio button */
    .label input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #eee;
        border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .label:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .label input:checked ~ .checkmark {
        background-color: ${color.primary};
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .label input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .label .checkmark:after {
        top: 3px;
        right: 3px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: white;
    }
    .payment {
        margin: 0 20px 20px;
        border: 1px solid #eee;
        border-radius: 5px;
        padding: 10px 20px;
        background: #eee;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
            outline: -webkit-focus-ring-color auto 1px;
        }
        .fa-angle-right {
            display: none;
            font-size: 20px;
            color: ${color.primary};
            @media (max-width: 767px) {
                display: block;
            }
        }
    }
    .payment-title {
        text-align: left;
        color: #262626;
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 20px;
    }
    .payment-sub__title {
        text-align: left;
        color: #7d7d7d;
        margin-bottom: 20px;
        font-size: 12px;
    }
    .payment-name {
        font-size: 14px;
        text-transform: capitalize;
        text-align: center;
        color: ${color.primary};
        font-weight: 600;
    }
    .payment-group {
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 2px solid #ddd;
    }
    .payment-group:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: unset;
    }
    .border-image {
        border-right: 1px solid #ddd;
        padding-right: 10px;
        margin-right: 10px;
    }
    .fa-angle-down {
        transition: all 0.25s ease;
        transform: rotate(${(props) => (props.active ? "180deg" : "0")});
    }
    /*style for new checklist*/
    .round {
        position: relative;
        margin-right: 8px;
    }
    .round label {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 50%;
      cursor: pointer;
      height: 28px;
      left: 0;
      position: absolute;
      top: 0;
      width: 28px;
    }
    
    .round label:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 6px;
      left: 7px;
      opacity: 0;
      position: absolute;
      top: 8px;
      transform: rotate(-45deg);
      width: 12px;
    }
    .round input[type="checkbox"] {
      visibility: hidden;
    }
    .round input[type="checkbox"]:checked + label {
      background-color: #000A8C;
    }
    
    .round input[type="checkbox"]:checked + label:after {
      opacity: 1;
    }
`;

const DEFAULT_LIMIT_DATA = 200;
const PaymentMethod = ({
    mobilePayment = false,
    setMobilePayment = () => {},
    setPaymentChannel = () => {},
}) => {
    const { dispatch, state } = useStore();

    const [isShow, setShow] = React.useState(false);
    const [eWallet, setWallet] = React.useState(false);
    const [payment, setPayment] = React.useState([]);
    const [isFirstGet, setFirstGet] = React.useState(true);
    const [isHide, setHide] = React.useState(false);
    const [isChecked, setChecked] = React.useState(false);
    const handleChoicePayment = (item,type) => {
        setMobilePayment(false);
        setPaymentChannel(item);
        setShow(!isShow);
        setHide(!isHide);
        setChecked(type);
        document.body.classList.toggle("hiddenScroll", false);
        document.getElementById("listPayment").style.overflow = "auto";
    };
    React.useEffect(() => {
        if (isFirstGet) {
            let params = {
                size: DEFAULT_LIMIT_DATA,
            };
            service.paymentMethod({ dispatch, params });
            setFirstGet(false);
        }
    }, [isFirstGet]);
    React.useEffect(() => {
        if (state?.user?.paymentMethod.isSuccess) {
            setPayment(
                state.user.paymentMethod.data.content.filter(
                    (item) => item.publish === true
                )
            );
            setWallet(
                state.user.paymentMethod.data.content.filter(
                    (item) => item.type === "E-wallet"
                )
            );
        }
    }, [state]);
    const virtual = payment.filter((item) => item.type === "Virtual Account");
    const ewallet = payment.filter(
        (item) =>
            item.type === "e-wallet" ||
            item.type === "E-Wallet" ||
            item.type === "E-wallet"
    );
    const gerai = payment.filter((item) => item.type === "Gerai Retail");
    return (
        <React.Fragment>
        <h3 style={{ fontWeight:700, paddingTop: 24, fontSize: 32 }}>Pembayaran</h3>
            {virtual && virtual.length > 0 && (
                <Style id="payment_method" active={isShow}>
                    <div className="list-payment" id="listPayment">
                        <div className="payment-group">
                            <h6 className="payment-title">
                                Transfer Virtual Account
                            </h6>
                            <div className="payment-sub__title">
                                Anda dapat membayar melalui transfer ATM,
                                Internet Banking, Mobile Banking
                            </div>
                            {virtual.map((item, key) => {
                                return (
                                    <div
                                        key={key}
                                        style={{
                                            padding: 20,
                                            margin: "20px 0",
                                            border: "1px solid #ddd",
                                            borderRadius: 20,
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent:"space-between",
                                            maxHeight: 70,
                                        }}
                                    >
                                        {/* <label className="label list-payment__item list-payment__check">
                                            {item.name}
                                            <input
                                                type="checkbox"
                                                name="payment"
                                                onClick={() =>
                                                    handleChoicePayment(item)
                                                }
                                            />
                                            <span className="checkmark"></span>
                                        </label> */}
                                            <img
                                                style={{
                                                    alignSelf: "center",
                                                    maxWidth: 129,
                                                    maxHeight: 50,
                                                    objectFit: "contain",
                                                }}
                                                src={item.image}
                                                alt={item.name}
                                                className="img-fluid"
                                            />
                                        <div className="round">
                                            <input type="checkbox" checked={isChecked === `checkbox_${item.name}` ? true : false} id={`checkbox_${item.name}`} onChange={() =>
                                                    handleChoicePayment(item,`checkbox_${item.name}`)} />
                                            <label htmlFor={`checkbox_${item.name}`}></label>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Style>
            )}
            {ewallet && ewallet.length > 0 && (
                <Style>
                    <div className="list-payment" id="listPayment">
                        <div className="payment-group">
                            <h6 className="payment-title">e-Wallet</h6>
                            <div className="payment-sub__title">
                                Anda dapat membayar menggunakan e - wallet yang
                                tersedia
                            </div>
                            {ewallet.map((item, key) => {
                                return (
                                    <div
                                        key={key}
                                        style={{
                                            padding: 20,
                                            margin: "20px 0",
                                            border: "1px solid #ddd",
                                            borderRadius: 20,
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent:"space-between",
                                            height: 70,
                                        }}
                                    >
                                        {/* <label className="label list-payment__item list-payment__check">
                                            {item.name}
                                            <input
                                                type="radio"
                                                name="payment"
                                                onClick={() =>
                                                    handleChoicePayment(item)
                                                }
                                            />
                                            <span className="checkmark"></span>
                                        </label> */}
                                            <img
                                                style={{
                                                    alignSelf: "center",
                                                    maxWidth: 129,
                                                    maxHeight: 50,
                                                    objectFit: "contain",
                                                }}
                                                src={item.image}
                                                alt={item.name}
                                                className="img-fluid"
                                            />
                                        <div className="round">
                                            <input type="checkbox" checked={isChecked === `checkbox_${item.name}` ? true : false} id={`checkbox_${item.name}`} onChange={() =>
                                                    handleChoicePayment(item,`checkbox_${item.name}`)} />
                                            <label htmlFor={`checkbox_${item.name}`}></label>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Style>
            )}
            {gerai && gerai.length > 0 && (
                <Style>
                    <div className="list-payment">
                        <div className="payment-group">
                            <h6 className="payment-title">Gerai Retail</h6>
                            <div className="payment-sub__title">
                                Anda dapat membayar langsung di gerai retail
                                yang tersedia
                            </div>
                            {gerai.map((item, key) => {
                                return (
                                    <div
                                        key={key}
                                        style={{
                                            padding: 20,
                                            margin: "20px 0",
                                            border: "1px solid #ddd",
                                            borderRadius: 20,
                                        }}
                                    >
                                        {/* <label className="label list-payment__item list-payment__check">
                                            {item.name}
                                            <input
                                                type="radio"
                                                name="payment"
                                                onClick={() =>
                                                    handleChoicePayment(item)
                                                }
                                            />
                                            <span className="checkmark"></span>
                                        </label> */}
                                            <img
                                                style={{
                                                    alignSelf: "center",
                                                    maxWidth: 129,
                                                    maxHeight: 50,
                                                    objectFit: "contain",
                                                }}
                                                src={item.image}
                                                alt={item.name}
                                                className="img-fluid"
                                            />
                                        <div className="round">
                                            <input type="checkbox" checked={isChecked === `checkbox_${item.name}` ? true : false} id={`checkbox_${item.name}`} onChange={() =>
                                                    handleChoicePayment(item,`checkbox_${item.name}`)} />
                                            <label htmlFor={`checkbox_${item.name}`}></label>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Style>
            )}
        </React.Fragment>
    );
};
export default PaymentMethod;
