import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Received from "../../../../../Components/Assets/icon/terima-active.png";
import Process from "../../../../../Components/Assets/icon/proses.png";
import ProcessActive from "../../../../../Components/Assets/icon/proses-active.png";
import Sending from "../../../../../Components/Assets/icon/dikirim.png";
import SendingActive from "../../../../../Components/Assets/icon/dikirim-active.png";
import SendingWantActive from "../../../../../Components/Assets/icon/SedingWantActive.png";
import Arrive from "../../../../../Components/Assets/icon/sampai.png";
import ArriveWantActive from "../../../../../Components/Assets/icon/ArriveWantActive.png";
import ArrivedActive from "../../../../../Components/Assets/icon/sampai-active.png";
import DetailProduct from "../DetailProduct";
import Button from "../../../../../Components/Particle/Button";
import Style from "./index.style";
import { useStore } from "../../../../../Reducers";
import Logo from "../../../../../Components/Assets/icon/Lakugan_Logo_Circle.png";
import service from "../../index.service";
import Rating from "../../../../../Components/Particle/Rating";
import { useHistory } from "react-router";
import CheckMobile from "../../../../../Utils/CheckMobile";

const statusIcon = [
  {
    img: Received,
    nextImage: Received,
    imgActive: Received,
    status: "Pesanan Diterima",
    className: "icon-area icon-border",
  },
  {
    img: Process,
    nextImage: Process,
    imgActive: ProcessActive,
    status: "Pesanan Dikemas",
    className: "icon-area icon-border",
  },
  {
    img: Sending,
    nextImage: SendingWantActive,
    imgActive: SendingActive,
    status: "Pesanan Dikirim",
    className: "icon-area icon-border",
  },
  {
    img: Arrive,
    nextImage: ArriveWantActive,
    imgActive: ArrivedActive,
    status: "Pesanan Sampai",
    className: "icon-area",
  },
];
const StatusOne = ({ data, activeKey = 1, setLoading = () => {} }) => {
  // const [isTracking, setTracking] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const isFirstGet = true;
  const history = useHistory();
  const [isRating, setRating] = React.useState(0);
  const { dispatch, state } = useStore();
  const giveRating = ({ rating }) => {
    setRating(rating);
    handleRatingProduct(data);
  };
  const handleDoneTransaction = () => {
    setLoading(true);
    let params = {
      transaction_code: data?.transaction_code,
    };
    service.transactionDone({ dispatch, params });
  };
  const handleRatingProduct = (data) => {
    let getTransaction = data.transaction_code.split("/");
    let transaction_code = getTransaction[1];
    let rating = {
      rating: isRating,
    };
    setLoading(true);
    service.ratingProduct({ dispatch, data: rating, transaction_code });
  };
  React.useEffect(() => {
    if (isFirstGet) {
      if (data?.product?.category?.name !== "Voucher Digital") {
        let id = data?.shipper.order_id;
        service.tracking({ dispatch, id });
      } else {
        setMessage("Voucher Digital Berhasil di kirim ke Email Anda");
      }
    }
  }, [isFirstGet, data, dispatch]);
  React.useEffect(() => {
    if (state?.shipper?.tracking?.isSuccess) {
      let id = state.shipper.tracking.data.data.id;
      service.orderDetail({ dispatch, id });
      service.trackingClear({ dispatch });
    }
    if (state?.shipper?.orderId?.isSuccess) {
      // setTracking(state.shipper.orderId.data.data.order.detail);
      service.orderDetailClear({ dispatch });
    }
  }, [state, dispatch]);
  const splitTransactionCode = (transaction) => {
    let transactionCode = transaction.split("/");
    return transactionCode[1];
  };
  React.useEffect(() => {
    if (data?.is_rated) {
      window.location = "/404";
    }
  }, [data]);
  React.useEffect(() => {
    if (data?.shipper?.tracking && CheckMobile() === false) {
      let key = data.shipper.tracking.length;
      setMessage(data?.shipper?.tracking[key - 1]?.external?.description || "Barang Sudah Sampai");
    }
  }, [data]);
  return (
    <Style>
      <div className="container">
        <div className="header-title justify-content-md-start justify-content-between mb-md-5 mb-3">
          <h3 className="title font-bold">Status Produk</h3>
          <h4 className="order-id font-semibold">
            No Pesanan : {splitTransactionCode(data.transaction_code)}
          </h4>
        </div>

        <div style={{ marginBottom: 50 }}>
          <Row className="justify-content-md-center justify-content-between">
            {data.status === 10 && (
              <Col xl={12} md={12}>
                <div className="text-center mb-3" style={{ marginBottom: 30 }}>
                  <div className="title text-center" style={{ marginRight: 0 }}>
                    {message}
                  </div>
                </div>
                <div className="text-center">
                  <img src={Logo} alt="Lakugan" className="img-fluid mb-2 img-done-transaction" />
                </div>
                <div>
                  <h3 className="title text-center" style={{ marginRight: 0 }}>
                    Pesanan Selesai
                  </h3>
                  <h6
                    className="order-id text-center"
                    style={{
                      fontWeight: "normal",
                      marginBottom: 20,
                    }}
                  >
                    Beri Penilaian
                  </h6>
                  {
                    CheckMobile() === true ?
                      <Rating
                        style={{margin:"0 16px"}}
                        textColor={false}
                        onGetValue={giveRating}
                        ratingStart={true}
                        size="31px"
                        rating={isRating}
                      />
                    :
                      <Rating
                        style={{margin:"0 30px"}}
                        textColor={false}
                        onGetValue={giveRating}
                        ratingStart={true}
                        size="50px"
                        rating={isRating}
                      />
                  }
                  
                </div>
              </Col>
            )}

            {data.status !== 10 &&
              statusIcon.map((item, key) => {
                return (
                  <Col sm={2} xs={3} key={key}>
                    <div className={`${key + 2 < activeKey ? "active" : ""} ${item.className}`}>
                      <img
                        src={
                          key + 1 < activeKey
                            ? item.imgActive
                            : key + 1 === activeKey
                            ? item.nextImage
                            : item.img
                        }
                        alt={`image_${key + 1}`}
                        className="img-fluid"
                      />
                    </div>
                    <div className={`${key + 1 < activeKey ? "active status" : "status"}`}>
                      {item.status}
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
        {data.status === 5 && (
          <div style={{ marginBottom: 20 }}>
            <Row className="justify-content-center">
              <Col xl={6} md={6}>
                <div className="text-center mb-3">
                  Barang sudah sampai di alamat tujuan, apakah kamu sudah menerimanya? Jika sudah,
                  silahkan konfirmasi. Dan jika ada Keluhan hubungi Reseller.
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xl={5} md={5}>
                <div className="button-area">
                  <Button
                    className="btn-size"
                    color="outline-primary"
                    onClick={() =>
                      window.open(
                        `https://api.whatsapp.com/send?phone=${data?.reseller?.phone_number.replace(
                          "0",
                          "62"
                        )}&text=Hallo ka ${data?.reseller?.full_name}, ${
                          data?.product?.name
                        } saya sudah pesan, mohon untuk di proses pemesanan saya ya, terimakasih`
                      )
                    }
                  >
                    <p className="title-btn">Hubungi Penjual</p>
                  </Button>
                  <Button
                    color="primary"
                    onClick={handleDoneTransaction}
                    className="btn-size"
                  >
                    <p className="title-btn">Barang Diterima</p>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        )}

        <div>
          <div>
            <h4 className="title">Detail Pesanan</h4>
            <Row>
              <Col xl={4} md={4} className="mb-md-0 mb-4">
                <DetailProduct data={data} />
              </Col>
              <Col xl={4} md={4} className="mb-md-0 mb-4">
                <h6 className="sub-title font-bold" style={{ marginBottom: 10 }}>
                  Alamat Tujuan
                </h6>
                <Card className="card-address">
                  <CardBody>
                    <p style={{ marginBottom: 5 }}>{data.customer_detail.full_name}</p>
                    <p style={{ marginBottom: 5 }}>{data.customer_detail.email}</p>
                    <p style={{ marginBottom: 5 }}>{data.customer_detail.phone_number}</p>
                    <p style={{ marginBottom: 5 }}>
                      {data.customer_detail.address}, {data.customer_detail.districts},{" "}
                      {data.customer_detail.city}, {data.customer_detail.zip_code}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4} md={4} className="mb-md-0 mb-4">
                <div className="d-flex flex-md-column flex-row justify-content-between">
                  {data.status === 10 && (
                    <Card className="card-address mb-2">
                      <CardBody>
                        <div className="sub-title" style={{ marginBottom: 8 }}>
                          Tertarik Menjadi Reseller?
                        </div>
                        <div style={{ marginBottom: 8 }}>
                          Ayo Join lakugan, memulai bisnis tanpa modal sekarang!
                        </div>
                        <Button
                          block
                          color="primary"
                          onClick={() => history.push("/signup/reseller")}
                          className="btn-reseller"
                        >
                          Join Reseller
                        </Button>
                      </CardBody>
                    </Card>
                  )}
                  {data.status !== 10 && (
                    <React.Fragment>
                      <div className="mb-3">
                        <h6 className="sub-title font-bold mb-2">Dikirim Dengan</h6>
                        <p className="font-bold">{data.shipper.rate_name}</p>
                      </div>
                      <div style={{ marginBottom: 60 }}>
                        <h6 className="sub-title font-bold mb-1">Dibayar Melalui</h6>
                        <div>
                          <img
                            src={data.payment_channel.image}
                            alt={data.payment_channel.name}
                            className="img-fluid"
                            style={{ width: 100 }}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
                <div className="text-center">
                  <h6 className="sub-title font-bold mb-1">Ada Keluhan Saat Pesan?</h6>
                  <p className="mb-1">Ayo join Lakugan, memulai bisnis tanpa modal sekarang!</p>
                  <Button
                    style={{
                      padding: "12px 40px",
                    }}
                    className="btn-reseller"
                    color="outline-primary"
                    onClick={() =>
                      window.open(
                        `https://api.whatsapp.com/send?phone=${data?.reseller?.phone_number.replace(
                          "0",
                          "62"
                        )}&text=Hallo ka ${data?.reseller?.full_name}, ${
                          data?.product?.name
                        } saya sudah pesan, mohon untuk di proses pemesanan saya ya, terimakasih`
                      )
                    }
                  >
                    Hubungi Reseller
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Style>
  );
};
export default StatusOne;
