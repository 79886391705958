import { useCallback } from "react";
import { useEffect, useState } from "react";
import Geocode from "react-geocode";
import { useStore } from "../Reducers";
import service from "./service";

const useGeoLocation = (address, province, city, district, area, postCode) => {
  const { dispatch } = useStore();
  const [addressValue, setAddressValue] = useState(null);

  const getGeoCodeFromAddress = useCallback(
    async (value) => {
      await Geocode.fromAddress(value).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          service.saveGeoLocation({
            dispatch,
            data: { latitude: lat, longitude: lng },
          });
        },
        (error) => {
          console.error(error);
        }
      );
    },
    [dispatch]
  );

  useEffect(() => {
    service.clearGeoLocation({ dispatch });
  }, [dispatch]);

  useEffect(() => {
    Geocode.setApiKey(process.env.REACT_APP_GEOLOCATION_API_KEY);
    Geocode.setLanguage("en");

    if (addressValue && province && city && district && area && postCode) {
      let concatValue = `${addressValue}, ${area.label}, ${district.label}, ${city.label}, ${province.label}, ${postCode}`;
      getGeoCodeFromAddress(concatValue);
    }

    return () => {};
  }, [
    addressValue,
    province,
    city,
    district,
    area,
    postCode,
    getGeoCodeFromAddress,
  ]);

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      setAddressValue(address);
    }, 2000);

    return () => clearTimeout(typingTimeout);
  }, [address]);
};

export default useGeoLocation;
