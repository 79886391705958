import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import SkeletonLoading from "../../../../Components/Loading/Skeleton";
import { formatMoney } from "../../../../Utils/PriceConverter";
import { DetailStyle, OnlyMobile } from "../index.style";
import RatingImg from "../../../../Components/Assets/icon/rating.png";
import TabMenu from "../../../../Components/TabMenu";
import { color } from "../../../../Utils/Variable";
import ShareButton from "../../../../Components/Assets/icon/share.png";
import CloseIcon from "../../../../Components/Assets/icon/icon-close.png";
import Modal from "react-modal";
import LocalStorage from "../../../../Utils/LocalStorage";
import ArrowShow from "../../../../Components/Assets/icon/arrow-step-right.svg";
import checkMobile from "../../../../Utils/CheckMobile";
import { Link } from "react-router-dom";

const customStyles = {
  content: {
    bottom: "0px",
    height: "75%",
    position: "sticky",
    inset: "40% 0",
    borderRadius: "12px",
    overflow: "auto",
    padding: "16px",
  },
};
const voucherStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  borderBottom: "1.5px solid #E8E8E8",
};

const Detail = ({
  slug = "",
  description = "",
  totalItem = 1,
  setTotalItem = () => {},
  data = false,
  toggle = () => {},
  filterSize = [],
  filterColor = [],
  setFilterSize = () => {},
  setFilterColor = () => {},
  size = "",
  setSize = () => {},
  colorItem = "",
  setColorItem = () => {},
  handleDescription = () => {},
  voucherList = [],
  voucherName,
  setVoucherName = () => {},
}) => {
  // eslint-disable-next-line
  const [totalStock, setTotalStock] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);

  const productDetail = [
    {
      title: "Deskripsi Barang",
      desc: data?.product?.description,
    },
  ];
  const handleShowModal = () => {
    setIsOpen(!modalIsOpen);
  };
  const handleGetVoucher = (item) => {
    let voucherUsed = {
      discount_percentages: item.discount_percentages,
      id: item.id,
      limit_voucher: item.limit_voucher,
      max_discount: item.max_discount,
      name: item.name,
      publish_until: item.publish_until,
      published: item.published,
      status: true,
    };
    LocalStorage().remove("VOUCHER_USED", voucherUsed);
    LocalStorage().save("VOUCHER_USED", voucherUsed);
    setVoucherName(item.name);
    handleShowModal();
  };

  const handleSizeProduct = (e, item) => {
    let value = e.target.id;
    if (size !== e.target.id) {
      setSize(value);
      setFilterColor(
        data.product.spec
          .reduce((acc, current) => {
            const x = acc.find(
              (filter) =>
                filter.size === current.size && filter.color === current.color
            );
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, [])
          .filter((obj) => obj.size === item.size)
          .sort((a, b) => a.color - b.color)
      );
    }
    if (size === e.target.id) {
      setFilterColor(
        data.product.spec
          .reduce((acc, current) => {
            const x = acc.find((filter) => filter.color === current.color);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, [])
          .sort((a, b) => a.color - b.color)
      );
      setSize("");
    }
  };
  const handleColorProduct = (e, item) => {
    let value = e.target.id;
    if (colorItem !== e.target.id) {
      setColorItem(value);
      setFilterSize(
        data.product.spec
          .reduce((acc, current) => {
            const x = acc.find(
              (filter) =>
                filter.size === current.size && filter.color === current.color
            );
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, [])
          .filter((obj) => obj.color === item.color)
          .sort((a, b) => a.size - b.size)
      );
    } else {
      setFilterSize(
        data.product.spec
          .reduce((acc, current) => {
            const x = acc.find((filter) => filter.size === current.size);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, [])
          .sort((a, b) => a.size - b.size)
      );
      setColorItem("");
    }
  };
  React.useEffect(() => {
    if (data) {
      class TravellerCollection extends Array {
        sum(key) {
          return this.reduce((a, b) => a + (b[key] || 0), 0);
        }
      }
      setFilterSize(
        data.product.spec
          .reduce((acc, current) => {
            const x = acc.find((filter) => filter.size === current.size);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, [])
          .sort((a, b) => a.size - b.size)
      );
      setFilterColor(
        data.product.spec
          .reduce((acc, current) => {
            const x = acc.find((filter) => filter.color === current.color);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, [])
          .sort((a, b) => a.color - b.color)
      );
      const traveler = new TravellerCollection(...data.product.spec);
      setTotalStock(traveler.sum("total_stock"));
    }
  }, [data, setFilterSize, setFilterColor]);
  return (
    <DetailStyle>
      <div className="product">
        {data ? (
          <h4 className="product-title">{data.product.name}</h4>
        ) : (
          <SkeletonLoading
            height="28px"
            width="100%"
            style={{ marginBottom: 20 }}
          />
        )}
        <OnlyMobile>
          <div className="product-name">
            <h4>{data?.product?.name}</h4>
            <Link to={"/login"}>
              <img
                src={ShareButton}
                alt=" "
                style={{ height: 27, width: 27 }}
              />
            </Link>
          </div>
        </OnlyMobile>
        <OnlyMobile>
          <h4 className="product-price-mobile">
            {formatMoney(data?.product?.price)}
          </h4>
        </OnlyMobile>
        <div className="estimation-desc">
          <Row>
            <Col xl="9" lg="9" md="9" sm="12">
              <Row>
                <Col xl="10" lg="12" md="12" sm="12">
                  {checkMobile() === true ? (
                    <span className="product-city">
                      <p className="h4">{data?.product?.supplier?.city}</p>{" "}
                      &#xb7;{" "}
                      <p
                        className={
                          data?.product?.total_stock_product <= 5
                            ? "text-danger h4 font-light"
                            : "text-primary h4 font-light"
                        }
                      >
                        Stok {data?.product?.total_stock_product}
                      </p>{" "}
                      &#xb7; <p className="h4">{data?.product?.rated}</p>{" "}
                      <img src={RatingImg} alt="" />
                      {data?.product?.rating >= 1 &&
                        `(${data?.product.rating} ulasan)`}
                    </span>
                  ) : (
                    <span className="product-city">
                      <p className="h6">{data?.product?.supplier?.city}</p>{" "}
                      &#xb7; <p className="h6">{data?.product?.rated}</p>{" "}
                      <img src={RatingImg} alt="" />
                      {data?.product?.rating >= 1 &&
                        `(${data?.product.rating} ulasan)`}{" "}
                      &#xb7;{" "}
                      <p
                        className={
                          data?.product?.total_stock_product <= 5
                            ? "text-danger h6 font-light"
                            : "text-primary h6 font-light"
                        }
                      >
                        Stok {data?.product?.total_stock_product}
                      </p>
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {data ? (
          <h3 className="product-price">{formatMoney(data.product.price)}</h3>
        ) : (
          <SkeletonLoading
            height="28px"
            width="200px"
            style={{ marginBottom: 20 }}
          />
        )}
        <OnlyMobile>
          <div className="border-top" />
          <div
            className="d-flex flex-row justify-content-between voucher-select"
            onClick={handleShowModal}
          >
            <p>{voucherName}</p>
            <img src={ArrowShow} alt="" />
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={handleShowModal}
            style={customStyles}
            contentLabel="ctm modal"
            ariaHideApp={false}
          >
            <div style={voucherStyles}>
              <p style={{ fontWeight: 600, fontSize: 18 }}>Pilihan Voucher</p>
              <img
                onClick={handleShowModal}
                src={CloseIcon}
                alt=""
                style={{ width: 20, height: 20 }}
              />
            </div>
            {voucherList?.map((item, key) => {
              return (
                <div
                  onClick={() => handleGetVoucher(item)}
                  key={key}
                  style={{
                    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
                    margin: "12px 0",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderRadius: "12px",
                  }}
                >
                  <p
                    style={{ padding: "1rem", margin: 0 }}
                    className="h5 font-semibold"
                  >
                    {item.name}
                  </p>
                  <div
                    style={{
                      color: "white",
                      background: "#000A8C",
                      padding: 8,
                      borderRadius: "0 12px 12px 0",
                    }}
                  >
                    <p style={{ alignSelf: "center", margin: "0.5rem auto" }}>
                      Pilih
                    </p>
                  </div>
                </div>
              );
            })}
          </Modal>
        </OnlyMobile>
        <div className="order">
          <div className="order-color">
            {data &&
              data.product.spec.length > 0 &&
              data.product.spec[0].color !== "" && (
                <React.Fragment>
                  <span className="font-sub">Warna</span>
                  <div className="color-list">
                    {filterColor.map((item, key) => {
                      return (
                        item.color !== "" &&
                        item.total_stock !== 0 && (
                          <button
                            key={key}
                            className={`btn order-color__button ${
                              colorItem === item.color ? "active" : ""
                            }`}
                            id={item.color}
                            onClick={(e) => handleColorProduct(e, item)}
                            type="button"
                            disabled={
                              item.total_stock < 1 ||
                              item.total_stock < totalItem
                                ? true
                                : false
                            }
                          >
                            {item.color}
                          </button>
                        )
                      );
                    })}
                  </div>
                </React.Fragment>
              )}
          </div>

          {data &&
            data.product.spec.length > 0 &&
            data.product.spec[0].size !== "" && (
              <React.Fragment>
                <div className="order-size">
                  <span className="font-sub">Ukuran</span>
                  <div className="size-list">
                    {filterSize.map((item, key) => {
                      return (
                        item.size !== "" &&
                        item.total_stock !== 0 && (
                          <button
                            key={key}
                            className={`btn order-size__button ${
                              size === item.size ? "active" : ""
                            }`}
                            id={item.size}
                            onClick={(e) => handleSizeProduct(e, item)}
                            type="button"
                            disabled={
                              item.total_stock < totalItem ? true : false
                            }
                          >
                            {item.size}
                          </button>
                        )
                      );
                    })}
                  </div>
                </div>
              </React.Fragment>
            )}
        </div>
        {checkMobile() === true
          ? false
          : data &&
            data.product.spec.length > 0 &&
            data.product.spec[0].size !== "" && (
              //show line border when any product variant(Desktop Only)
              <div style={{ borderBottom: "1.5px solid #E8E8E8" }} />
            )}
        <div className="info-product">Informasi Produk</div>
        <TabMenu
          color={{
            text: "#aaa",
            background: "#fff",
            activeColor: color.primary,
            activeBackground: color.primary,
          }}
          contentStyle={{
            padding: "15px 0",
          }}
          data={[
            {
              key: 1,
              render: () => {
                return productDetail.map((item, key) => {
                  return (
                    <Row key={key}>
                      <Col xl="7" lg="9" md="12" sm="10">
                        <div
                          key={key}
                          className="article-desc"
                          dangerouslySetInnerHTML={{
                            __html: item.desc,
                          }}
                          style={{
                            height: 230,
                            width: "180%",
                            overflow: "auto",
                          }}
                        />
                      </Col>
                    </Row>
                  );
                });
              },
            },
          ]}
        />
      </div>
    </DetailStyle>
  );
};

export default Detail;
