import { servicesAction } from "../Reducers";

const service = {
  saveGeoLocation: ({ dispatch, data }) => {
    servicesAction(dispatch).reduxSetData({
      reducer: "service",
      group: "location",
      key: "coords",
      latitude: data.latitude,
      longitude: data.longitude,
      type: 200,
    });
  },
  clearGeoLocation: ({ dispatch }) => {
    servicesAction(dispatch).reduxClear({
      reducer: "service",
      group: "location",
      key: "coords",
      type: "CLEAR",
    });
  },
};

export default service;
