import React from "react";
import { Collapse } from "reactstrap";

const AccordionPayment = ({ value = {} }) => {
    const [isOpen, setOpen] = React.useState(false);

    const toggle = () => {
        setOpen(!isOpen);
    };
    return (
        <React.Fragment>
            <button onClick={toggle} className="btn-accordion">
                {value.header}
                <i className="fas fa-angle-down"></i>
            </button>
            <Collapse isOpen={isOpen}>
                <ul style={{ listStyle: "number", padding: "0 14px" }}>
                    {value.list.map((list, key) => {
                        return <li key={key}>{list}</li>;
                    })}
                </ul>
            </Collapse>
        </React.Fragment>
    );
};
export default AccordionPayment;
