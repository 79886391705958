import { servicesAction } from "../../../Reducers";

const service = {
    walletLog: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/wallet/log`,
            method: "GET",
            params: params,
            qs: true,
            token: true,
            reducer: "service",
            group: "reseller",
            key: "walletLog",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Wallet log",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
};
export default service;
