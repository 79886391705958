import React from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, Input, Card } from "reactstrap";
import styled from "styled-components";
import { color } from "../../Utils/Variable";
import Logo from "../Assets/Images/Lakugan_LogoColor_BrightBG.png";
import SearchIcon from "../../Components/Assets/icon/search-icon.svg";
import LocalStorage from "../../Utils/LocalStorage";
import Button from "../Particle/Button";
import service from "./index.service";
import { useHistory } from "react-router-dom";
import IconUser from "../Assets/icon/icon-user.png";
import IconHelp from "../Assets/icon/icon-help.png";
import IconWhatsApp from "../Assets/icon/icon-whatsapp.png";
import IconEmail from "../Assets/icon/icon-email.png";
import IconDownload from "../Assets/icon/icon-decument.png";
import IcoShop from "../Assets/icon/icon-shop.png";
import IcoDownload from "../Assets/icon/icon-download.png";
import IcoDirect from "../Assets/icon/icon-direct.png";
import { useStore } from "../../Reducers";
import ProductDropdown from "./ProductDropdown";
import DeleteProduct from "../../Pages/Reseller/Home/Modal/DeleteProduct";
import fileDocx from "../Assets/Blog_Lakugan.docx";
import { Style } from "./index.styles";
import LogoMobile from "../Assets/icon/Lakugan_Logo_Circle.png";
import UserIcon from "../Assets/icon/mobile/icon-user-mobile.png";
import ReactGA from "react-ga4"

const TRACKING_ID = "G-3PE43DTVMX"; // OUR_TRACKING_ID

const ModalStyle = styled(Modal)`
    .nav-mobile {
        text-align: right;
    }
    .nav-list {
        margin-bottom: 20px;
    }
    .nav-link {
        font-size: 14px;
        &.active {
            color: ${color.primary};
            font-weight: bold;
            border-bottom: 1px solid ${color.primary};
        }
    }
`;
const ModalNav = ({
    isShow = true,
    handleShowNavbar = () => {},
    auth = null,
    token = null,
    setShow = () => {},
    navList = [],
}) => {
    return (
        <ModalStyle isOpen={isShow} size="sm">
            <ModalBody>
                <div className="nav-mobile">
                    <button
                        type="button"
                        className="btn btn-times"
                        onClick={handleShowNavbar}
                    >
                        <i className={" fas fa-times"}></i>
                    </button>
                </div>
                <div>
                    <div className="nav-list">
                        {navList.map((item, key) => {
                            return (
                                <div key={key}>
                                    <Link
                                        onClick={() => setShow(false)}
                                        className={`nav-link ${
                                            window.location.pathname.split(
                                                "/"
                                            )[1] === item.activeName
                                                ? "active"
                                                : ""
                                        }`}
                                        to={item.link}
                                    >
                                        <i
                                            className={`${item.icon} mr-2 icon-link`}
                                        ></i>{" "}
                                        {item.name}
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                    <div>
                        <Link
                            to={
                                auth === null || token === null
                                    ? `/login`
                                    : auth.user.authorities[0].name ===
                                      "ROLE_SUPPLIER"
                                    ? "/supplier/home"
                                    : "/reseller/home"
                            }
                            className="btn-login nav-link"
                        >
                            <i className="fas fa-user-circle"></i>{" "}
                            {auth == null || token === null
                                ? "Masuk"
                                : "Beranda"}
                        </Link>
                    </div>
                </div>
            </ModalBody>
        </ModalStyle>
    );
};

const Header = (props) => {
    const { dispatch, state } = useStore();
    const [scrollTop, setScrollTop] = React.useState(0);
    const [isFirstGet, setFirstGet] = React.useState(true);
    const [isActive, setActive] = React.useState(false);
    const [productList, setProductList] = React.useState([]);
    const history = useHistory();
    const [searchProduct, setSearchProduct] = React.useState("");
    const token = LocalStorage().get("auth");
    const [isDelete, setDelete] = React.useState(false);
    const [isFailed, setFailed] = React.useState(false);
    const [productDelete, setProductDelete] = React.useState(false);
    const { menu = true, responsive = false } = props;
    const auth = LocalStorage().get(`${token?.access_token}`);
    const handleShowNavbar = (e) => {
        setActive(!isActive);
    };
    const handleModalDelete = (item) => {
        setProductDelete(item);
        setDelete(!isDelete);
    };
    const onSubmitProduct = (e) => {
        e.preventDefault();
        history.push(`/search?${searchProduct}`);
        // service.searchProduct({ dispatch, params: filter });
    };
    const handleViewProduct = (item) => {
        service.getProduct({ dispatch, slug: item.code });
    };
    const onChangeProduct = (e) => {
        let value = e.target.value;
        setSearchProduct(value);
    };
    React.useEffect(() => {
        const onScroll = (e) => {
            setScrollTop(e.target.documentElement.scrollTop);
            // setScrolling(e.target.documentElement.scrollTop > scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);
    React.useEffect(() => {
        if (auth !== null && isFirstGet) {
            if (auth.user.authorities[0].name === "ROLE_SUPPLIER") {
                service.product({ dispatch });
            } else {
                service.productList({ dispatch });
            }
            setFirstGet(false);
        }
    }, [auth, isFirstGet, dispatch]);
    React.useEffect(() => {
        if (state?.product?.list?.isSuccess) {
            setProductList(state.product.list.data.content);
        }
    }, [state]);

    React.useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
        ReactGA.send("pageview");
        ReactGA.send({ hitType: "pageview", page: "window.location.pathname" });
    }, []);

    return (
        <React.Fragment>
            <Style
                id="header"
                auth={auth}
                scroll={scrollTop}
                responsive={responsive}
            >
                <div className="header">
                    <div className="header-area">
                        <div className="desktop-lakugan">
                            <Link to="/">
                                <img
                                    src={
                                        window.screen.width > 550
                                            ? Logo
                                            : LogoMobile
                                    }
                                    alt="Lakugan"
                                    className="img-fluid logo"
                                />
                            </Link>
                        </div>
                        <div className="mid-flex" />
                        {menu && (
                            <div className="nav-header">
                                {(auth === null || token === null) && (
                                    <form action="" onSubmit={onSubmitProduct}>
                                        <div className="input-box">
                                            <Input
                                                onChange={onChangeProduct}
                                                placeholder="Pakaian, Makanan, Vouc.."
                                                type="search"
                                            ></Input>
                                            <Button
                                                color="transparent"
                                                type="submit"
                                                className="searcn-btn"
                                            >
                                                <img
                                                    src={SearchIcon}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </Button>
                                        </div>
                                    </form>
                                )}

                                {auth === null || token === null ? (
                                    <React.Fragment>
                                        {/* SIGNUP DESKTOP */}
                                        <div className="signup-desktop">
                                            <Link to="/signup/reseller">
                                                <Button
                                                    color="outline-primary"
                                                    type="button"
                                                    style={{
                                                        border: "2px solid #000A8C",
                                                        padding: "2px 16px",
                                                        margin: "0px 8px",
                                                        width: "120px",
                                                        height: "40px",
                                                        borderRadius: "8px",
                                                        fontSize: 15,
                                                    }}
                                                >
                                                    Daftar
                                                </Button>
                                            </Link>
                                            <Link
                                                to={"/login"}
                                                className="btn-login"
                                            >
                                                <Button
                                                    color="primary"
                                                    type="button"
                                                    style={{
                                                        background: "#000A8C",
                                                        padding: "2px 16px",
                                                        margin: "0px 8px",
                                                        width: "120px",
                                                        height: "40px",
                                                        borderRadius: "8px",
                                                        fontSize: 15,
                                                    }}
                                                >
                                                    Masuk
                                                </Button>
                                            </Link>
                                        </div>
                                        {/* SIGNUP MOBILE */}
                                        <div className="signup-mobile">
                                            <Link to={"/login"}>
                                                <img
                                                    src={UserIcon}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </Link>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <div>
                                        <div
                                            className="help-center"
                                            style={{
                                                position: "relative",
                                                display: "inline-block",
                                            }}
                                        >
                                            <Button
                                                color="transparent"
                                                type="button"
                                                className="btn-user btn-border btn-help"
                                            >
                                                <img
                                                    src={IconHelp}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </Button>
                                            <div className="help">
                                                <Card className="card-help__center">
                                                    <a
                                                        rel="noopener noreferrer"
                                                        href={`https://api.whatsapp.com/send?phone=6282180005188`}
                                                    >
                                                        <div className="card-help">
                                                            <div className="group-flex">
                                                                <div>
                                                                    <img
                                                                        src={
                                                                            IconWhatsApp
                                                                        }
                                                                        alt=""
                                                                        className="img-fluid"
                                                                    />
                                                                </div>
                                                                <div>
                                                                    0821-8000-5188
                                                                </div>
                                                            </div>
                                                            <img
                                                                src={IcoDirect}
                                                                alt=""
                                                                className="img-fluid"
                                                            />
                                                        </div>
                                                    </a>
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href="mailto:halo@lakugan.com"
                                                    >
                                                        <div className="card-help">
                                                            <div className="group-flex">
                                                                <div>
                                                                    <img
                                                                        src={
                                                                            IconEmail
                                                                        }
                                                                        alt=""
                                                                        className="img-fluid img-action"
                                                                    />
                                                                </div>
                                                                <div>
                                                                    halo@lakugan.com
                                                                </div>
                                                            </div>
                                                            <img
                                                                src={IcoDirect}
                                                                alt=""
                                                                className="img-fluid"
                                                            />
                                                        </div>
                                                    </a>
                                                    <a href={fileDocx}>
                                                        <div className="card-help">
                                                            <div className="group-flex">
                                                                <div>
                                                                    <img
                                                                        src={
                                                                            IconDownload
                                                                        }
                                                                        alt=""
                                                                        className="img-fluid img-action"
                                                                    />
                                                                </div>
                                                                <div>
                                                                    Panduan
                                                                    Lakugan
                                                                </div>
                                                            </div>
                                                            <img
                                                                src={
                                                                    IcoDownload
                                                                }
                                                                alt=""
                                                                className="img-fluid img-action"
                                                            />
                                                        </div>
                                                    </a>
                                                </Card>
                                            </div>
                                        </div>
                                        <div className="help-center">
                                            <Button
                                                color="transparent"
                                                type="button"
                                                className="btn-user btn-border  btn-help"
                                                style={{ position: "relative" }}
                                            >
                                                <img
                                                    src={IcoShop}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                                {productList.length > 0 && (
                                                    <div className="total-product">
                                                        {productList.length}
                                                    </div>
                                                )}
                                            </Button>
                                            <div className="help">
                                                <ProductDropdown
                                                    isFailed={isFailed}
                                                    handleViewProduct={
                                                        handleViewProduct
                                                    }
                                                    handleModalDelete={
                                                        handleModalDelete
                                                    }
                                                    role={
                                                        auth.user.authorities[0]
                                                            .name
                                                    }
                                                    productList={productList}
                                                />
                                            </div>
                                        </div>
                                        <Link
                                            to={
                                                auth.user.authorities[0]
                                                    .name === "ROLE_SUPPLIER"
                                                    ? "/supplier/home"
                                                    : "/reseller/home"
                                            }
                                            className="btn-login"
                                        >
                                            <Button
                                                color="transparent"
                                                type="button"
                                                className="btn-user"
                                            >
                                                <img
                                                    src={IconUser}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </Button>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </Style>
            <ModalNav
                isShow={isActive}
                setShow={setActive}
                auth={auth}
                handleShowNavbar={handleShowNavbar}
                token={token}
            />
            <DeleteProduct
                setDelete={setDelete}
                setFailed={setFailed}
                item={productDelete}
                isShow={isDelete}
                toggle={handleModalDelete}
            />
        </React.Fragment>
    );
};
export default Header;
