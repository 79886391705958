import React from "react";
import { Card, CardBody } from "reactstrap";
import ModalData from "../../../../Components/Modal";
import CommonTable from "../../../../Components/Molekul/CommonTable";
import { useStore } from "../../../../Reducers";
import LocalStorage from "../../../../Utils/LocalStorage";
import { formatMoney } from "../../../../Utils/PriceConverter";
import service from "../index.service";
import styled from "styled-components";
const Style = styled.div`
    .table-absolute {
        position: absolute;
        width: 84px;
        right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 575px) {
            padding-top: 23px !important;
            padding-bottom: 23px !important;
            width: 55px;
        }
    }
    tbody {
        .table-absolute {
            padding: 14px !important;
            @media (max-width: 575px) {
                padding: 10px !important;
            }
        }
    }
`;

const Index = () => {
    const { dispatch, state } = useStore();
    const [isShow, setShow] = React.useState(false);
    const [data, setData] = React.useState(false);
    const [isRefresh, setRefresh] = React.useState(true);
    const getToken = LocalStorage().get("auth");
    const [commissionList, setCommissionList] = React.useState([]);
    const [isFirstGetCommission, setIsFirstGetCommision] = React.useState(true);
    const getId = LocalStorage().get(`${getToken.access_token}`);
    const handlerViewDetail = (item) => {
        setShow(!isShow);
        setData(item);
    };
    React.useState(() => {
        if (isFirstGetCommission) {
            service.commissionList({ dispatch });
            setIsFirstGetCommision(false);
        }
    }, [isFirstGetCommission]);
    React.useEffect(() => {
        if (state?.reseller?.transactionList?.isSuccess) {
            service.transactionListClear({ dispatch });
        }
        if (state?.commission?.commissionList?.isSuccess) {
            setCommissionList(state.commission.commissionList.data.content);
        }
    }, [state, dispatch]);
    return (
        <Style>
            <Card>
                <CardBody>
                    {/* <Header /> */}
                    <CommonTable
                        setRefresh={setRefresh}
                        no_data="Tidak ada transaksi"
                        isRefresh={isRefresh}
                        searchBy={"customer_detail.full_name"}
                        configuration={{
                            searchInput: true,
                            searchValue: ["customer_detail.full_name"],
                            nav: {
                                hasSearch: true,
                                filter: [
                                    {
                                        icon: "fas fa-sort-amount-down",
                                        type: "dropdown",
                                        value: [
                                            {
                                                value: "full_name",
                                                label: "Email",
                                                key: "customer_detail.full_name",
                                            },
                                        ],
                                    },
                                ],
                            },
                            service: {
                                api: service.transactionList,
                                id: getId.id,
                                group: "reseller",
                                key: "transactionList",
                                filters: ``,
                                filterData: `["status","=","10"]`,
                                // filters: ``,
                            },
                            optionTable: [
                                {
                                    headName: "No",
                                    type: "number",
                                },
                                {
                                    headName: "Nama Pemesan",
                                    type: "isCostume",
                                    fieldName: "customer_detail.full_name",
                                    iconClass: "fas fa-sort-alpha-down",
                                    iconClass2: "fas fa-sort-alpha-up-alt",
                                    renderItem: (item) => {
                                        return (
                                            <React.Fragment>
                                                <span
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    {
                                                        item.customer_detail
                                                            .full_name
                                                    }
                                                </span>
                                            </React.Fragment>
                                        );
                                    },
                                },
                                {
                                    headName: "Nomor Telepon",
                                    type: "isCostume",
                                    iconClass: "fas fa-sort-alpha-down",
                                    iconClass2: "fas fa-sort-alpha-up-alt",
                                    renderItem: (item) => {
                                        return (
                                            <React.Fragment>
                                                <span
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    {
                                                        item.customer_detail
                                                            .phone_number
                                                    }
                                                </span>
                                            </React.Fragment>
                                        );
                                    },
                                },
                                {
                                    headName: "Produk",
                                    type: "isCostume",
                                    iconClass: "fas fa-sort-alpha-down",
                                    iconClass2: "fas fa-sort-alpha-up-alt",
                                    renderItem: (item) => {
                                        return (
                                            <React.Fragment>
                                                <span
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    {item.product.name}
                                                </span>
                                            </React.Fragment>
                                        );
                                    },
                                },
                                {
                                    headName: "Jumlah Barang",
                                    type: "text",
                                    iconClass: "fas fa-sort-alpha-down",
                                    iconClass2: "fas fa-sort-alpha-up-alt",
                                    fieldName: "total_amount",
                                },
                                {
                                    headName: "Total Pembayaran",
                                    type: "isCostume",
                                    iconClass: "fas fa-sort-alpha-down",
                                    iconClass2: "fas fa-sort-alpha-up-alt",
                                    style: {
                                        paddingRight: 84,
                                    },
                                    renderItem: (item) => {
                                        return (
                                            <React.Fragment>
                                                <span
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    {formatMoney(
                                                        item.total_cost
                                                    )}
                                                </span>
                                            </React.Fragment>
                                        );
                                    },
                                },
                                {
                                    headName: "Aksi",
                                    type: "actions",
                                    className: "table-absolute",
                                    options: [
                                        {
                                            actionType: "isViewModal",
                                            function: (item) =>
                                                handlerViewDetail(item),
                                            renderItem: () => {},
                                            field: "id",
                                        },
                                    ],
                                },
                            ],
                        }}
                    ></CommonTable>
                </CardBody>
            </Card>
            <ModalData
                role="reseller"
                data={data}
                toggle={handlerViewDetail}
                isShow={isShow}
                commissions={commissionList}
            />
        </Style>
    );
};

export default Index;
