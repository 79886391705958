import React from "react";
import { Col, Container, FormGroup, Input, Row } from "reactstrap";
import styled from "styled-components";
import Button from "../../../Components/Particle/Button";
import { useStore } from "../../../Reducers";
import service from "./index.service";
import Logo from "../../../Components/Assets/Images/Lakugan_LogoColor_BrightBG.png";
import Form from "../../../Components/Molekul/FormValidation";
import MessageState from "../../../Components/Modal/Message";
import GrowingLoading from "../../../Components/Loading/GrowingLoading";
import { useHistory } from "react-router";
const Style = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .note {
        font-size: 12px;
        color: #fff;
        background: orange;
        border-radius: 10px;
        padding: 10px 30px;
        margin-bottom: 30px;
        margin-top: 150px;
    }
`;

const ForgotPassword = () => {
    const history = useHistory();
    const { dispatch, state } = useStore();
    const [value, setValue] = React.useState("");
    const [status, setStatus] = React.useState(true);
    const [message, setMessage] = React.useState("");
    const [showMessage, setShowMessage] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [validationClick, setValidationClick] = React.useState(true);
    const [getOtp, setGetOtp] = React.useState(false);
    const [validateForm, setValidateForm] = React.useState(true);
    const [formPassword, setFormPassword] = React.useState([
        {
            type: "password",
            action: "confirmPassword",
            required: true,
            block: true,
            name: "new_password",
            label: "Kata Sandi Baru",
            label2: "Konfirmasi Kata Sandi Baru",
            col: 12,
            value: "",
            status: false,
            valid: "Kata Sandi Baru tidak boleh kosong",
            valid2: "Konfirmasi Kata Sandi Baru tidak boleh kosong",
        },
        {
            label: "Kode OTP",
            type: "number",
            required: true,
            col: 12,
            action: "text",
            name: "OTP",
            valid: "Kode OTP tidak boleh kosong",
            value: "",
            status: false,
        },
    ]);
    const handleGetEmail = (e) => {
        let email = e.target.value;
        setValue(email);
    };
    const handleSendOtp = () => {
        service.forgotPassword({ dispatch, email: value });
    };
    const handleChangePassword = (e) => {
        let isError = false;
        e.preventDefault();
        for (let key in formPassword) {
            if (!formPassword[key].status) {
                isError = true;
            }
        }

        setValidationClick(isError);
        setValidateForm(false);
        if (!isError) {
            setLoading(true);
            let otp = formPassword[1].value;
            let data = {
                new_pass: formPassword[0].value,
                re_new_pass: formPassword[0].value,
            };
            service.changePassword({ dispatch, otp, data });
        }
    };
    React.useEffect(() => {
        if (state?.user?.forgotPassword?.isSuccess) {
            setLoading(false);
            setGetOtp(true);
            service.forgotPasswordClear({ dispatch });
        }
        if (state?.user?.forgotPassword?.isError) {
            setLoading(false);
            setStatus(state.user?.forgotPassword?.isSuccess);
            setMessage(state.user?.forgotPassword?.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
        }
        if (state?.user?.changePassword?.isSuccess) {
            setLoading(false);
            setStatus(state.user?.changePassword?.isSuccess);
            setMessage(state.user?.changePassword?.message.text);
            setShowMessage(true);
            service.changePasswordClear({ dispatch });
            setTimeout(() => {
                setGetOtp(false);
                setShowMessage(false);
                history.push("/login");
            }, 5000);
        }
        if (state?.user?.changePassword?.isError) {
            setLoading(false);
            setStatus(state.user?.changePassword?.isSuccess);
            setMessage(state.user?.changePassword?.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
        }
    }, [state, history, dispatch]);

    return (
        <React.Fragment>
            <header style={{ padding: 20, textAlign: "center" }}>
                <img src={Logo} alt="" className="img-fluid" />
            </header>
            <Style>
                <GrowingLoading active={isLoading} />
                <MessageState
                    status={status}
                    message={message}
                    isShow={showMessage}
                />
                <Container>
                    {getOtp ? (
                        <React.Fragment>
                            <Row className="justify-content-center">
                                <Col xl="4" lg="4" md="4" sm="4" xs="10">
                                    <div className="note">
                                        <i>
                                            Masukan kode OTP yang sudah
                                            dikirimkan ke email anda
                                        </i>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <Col xl="4" lg="4" md="4" sm="4" xs="10">
                                    <Form
                                        form={formPassword}
                                        setForm={setFormPassword}
                                        validateForm={validateForm}
                                        validationClick={validationClick}
                                    />
                                    <Button
                                        onClick={handleChangePassword}
                                        color="primary"
                                        type="button"
                                    >
                                        Ubah
                                    </Button>
                                </Col>
                            </Row>
                        </React.Fragment>
                    ) : (
                        <Row className="justify-content-center">
                            <Col xl="4" lg="4" md="4" sm="4" xs="10">
                                <div>
                                    <FormGroup>
                                        <label htmlFor="">Email</label>
                                        <Input
                                            type="email"
                                            onChange={handleGetEmail}
                                        />
                                    </FormGroup>
                                    <Button
                                        color="primary"
                                        type="button"
                                        onClick={handleSendOtp}
                                        disabled={value === "" ? true : false}
                                    >
                                        Kirim OTP
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Container>
            </Style>
        </React.Fragment>
    );
};
export default ForgotPassword;
