/* eslint-disable */
import React from "react";
import {
    Card,
    CardBody,
    Col,
    FormGroup,
    Row,
    Container,
    InputGroupText,
    InputGroup,
    Input,
    InputGroupAddon,
} from "reactstrap";
import Button from "../../../Components/Particle/Button";
import { color } from "../../../Utils/Variable";
import { useStore } from "../../../Reducers";
import ProductDetail from "./ProductDetail";
import service from "./index.service";
import SkeletonLoading from "../../../Components/Loading/Skeleton";
import GrowingLoading from "../../../Components/Loading/GrowingLoading";
import { Link, useHistory } from "react-router-dom";
import TablePagination from "../../../Components/Molekul/CommonTable/index.pagination";
import debounce from "../../../Utils/Debounce";
import MessageState from "../../../Components/Modal/Message";
import CardProduct from "./CardProduct";
import Style from "./index.style";
import Filter from "./Filter";

const DEFAULT_LIMIT_DATA = 18;

const DEFAULT_LIMIT_CATEGORY = 7;

const DEFAULT_LIMIT_PAGINATION = 5;

const ProductList = () => {
    const history = useHistory();
    const inputEl = React.useRef(null);
    const filterTotalProduct = `["wallet.current_balance","!=","0"]`;
    const [paramsFilter, setParamsFilter] = React.useState("");
    const [params, setParams] = React.useState({
        page: 0,
        size: DEFAULT_LIMIT_DATA,
        filters: `[${paramsFilter}]`,
    });
    const [paramsCategory, setParamsCategory] = React.useState({
        page: 0,
        size: DEFAULT_LIMIT_CATEGORY,
    });
    const [paramsCity, setParamsCity] = React.useState({});
    const [arrFilters, setArrFilters] = React.useState([]);
    const [status, setStatus] = React.useState(true);
    const [city, setCity] = React.useState([]);
    const [cityFirst, setCityFirst] = React.useState([]);
    const [allCity, setAllCity] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const [showMessage, setShowMessage] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchValueCategory, setSearchValueCategory] = React.useState("");
    const [searchValueCity, setSearchValueCity] = React.useState("");
    const [configTable, setConfigurationTable] = React.useState(false);
    const [onSortFilter, setSortFilter] = React.useState([]);
    const [onSortFilterCity, setSortFilterCity] = React.useState([]);
    const [allCategory, setAllCategory] = React.useState(false);
    const [showAllCategory, setShowAllCategory] = React.useState(false);
    const [showAllCity, setShowAllCity] = React.useState(false);
    const [category, setCategory] = React.useState(false);
    const [categoryFilters, setCategoryFilters] = React.useState([]);
    const [cityFilters, setCityFilters] = React.useState([]);
    const [productReseller, setProductReseller] = React.useState([]);
    const [isLoading, setLoadingData] = React.useState(false);
    const [productList, setProductList] = React.useState(false);
    const [product, setProduct] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [totalDataCategory, setTotalDataCategory] = React.useState(0);
    const [dataProduct, setDataProduct] = React.useState(false);
    const [isOpen, setOpen] = React.useState(false);
    const [openCategory, setOpenCategory] = React.useState(true);
    const [openCity, setOpenCity] = React.useState(true);
    const { dispatch, state } = useStore();
    const [isShowFilter, setShowFilter] = React.useState(false);
    const [isFirstGet, setFirstGet] = React.useState(true);
    const [getIdMultiple, setIdMultiple] = React.useState([]);
    const handleDetailProduct = (item) => {
        setDataProduct(item);
        setOpen(true);
    };
    const toggleDetailProduct = () => {
        setOpen(!isOpen);
    };
    const handleAddProduct = (item) => {
        let id = item.id;
        setLoading(true);
        service.addProduct({ dispatch, id });
    };
    const handleCheckProduct = async (e, item) => {
        const getId = [
            {
                id: item.id,
            },
        ];
        let value = e.target.checked;
        if (value) {
            setIdMultiple((prevState) => {
                return [...prevState, ...getId];
            });
        }
        if (!value) {
            onRemoveData(item);
        }
    };
    const onRemoveData = async (value) => {
        setIdMultiple((prevState) => {
            return prevState.filter((item) => item.id !== value.id);
        });
    };
    const getData = (params) => {
        setLoading(true);
        setLoadingData(true);
        service.product({ dispatch, params });
        setParams(params);
    };
    const onClickNumber = (number) => {
        getData({
            filters: paramsFilter,
            page: number,
            size: DEFAULT_LIMIT_DATA,
        });
    };
    const handleGetAllCategory = () => {
        setShowAllCategory(!showAllCategory);
    };
    const handleGetAllCity = () => {
        setShowAllCity(!showAllCity);
    };
    const handleCloseCategory = () => {
        setSortFilter([]);
        setCategoryFilters([]);
        setShowAllCategory(false);
    };
    const handleCloseCity = () => {
        setSortFilterCity([]);
        setCityFilters([]);
        setShowAllCity(false);
    };
    const handleFilterAction = () => {
        let x = `[${cityFilters.length > 0 ? onSortFilterCity : ""}${
            categoryFilters.length > 0 && cityFilters.length > 0
                ? ',["and"],'
                : ""
        }${categoryFilters.length > 0 ? onSortFilter : ""}]`;
        params.filters = x;
        params.page = 1;
        params.size = 18;
        setParamsFilter(x);
        getData(params);
        if (window.screen.width < 768) {
            setShowFilter(!isShowFilter);
        }
    };

    const handlerSearchCategory = debounce((value) => {
        let param = {
            page: 1,
            size: totalDataCategory,
            filters: `[["name","like","${value}"]]`,
        };
        service.categoryAll({ dispatch, paramsCategory: param });
        setTotalDataCategory(param.size);
        setParamsCategory(param);
        setSearchValueCategory(value);
    }, 750);
    const handlerSearchCity = debounce((value) => {
        let dataCity = cityFirst;
        const filteredData = dataCity.filter((city) => {
            const searchStr = value.toLowerCase();
            const nameMatches = city.name.toLowerCase().includes(searchStr);

            return nameMatches;
        });
        setAllCity(filteredData);
        setParamsCity(params);
        setSearchValueCity(value);
    }, 250);
    const handleShowFilter = () => {
        if (window.screen.width < 768) {
            setShowFilter(!isShowFilter);
        }
    };
    const eventOnChangeSearchInput = debounce((value) => {
        // setActive(0);
        params.page = 1;
        params.size = 18;
        params.filters = `[["name","like","${value}"]]`;
        setSearchValue(value);
        getData(params);
    }, 750);
    const checkProduct = (value) => {
        let isHideProduct = false;
        for (let key in productReseller) {
            if (productReseller[key] === value) {
                isHideProduct = true;
            }
        }
        return isHideProduct;
    };
    React.useEffect(() => {
        if (isFirstGet) {
            service.allCity({ dispatch });
            service.category({ dispatch, paramsCategory });
            service.productList({ dispatch });
            service.product({ dispatch, params });
            setParamsCategory(paramsCategory);
            setFirstGet(false);
        }
    }, [isFirstGet, dispatch]);
    React.useEffect(() => {
        if (state?.reseller?.addProductMultiple?.isSuccess) {
            setLoading(false);
            setIdMultiple([]);
            window.location = "/reseller/home";
        }
        if (state?.reseller?.addProductMultiple?.isError) {
            setLoading(false);
            setStatus(state?.reseller.addProductMultiple.isSuccess);
            setMessage(state.reseller.addProductMultiple.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
            service.addProductMultipleClear({ dispatch });
        }
    }, [state?.reseller?.addProductMultiple]);
    React.useEffect(() => {
        if (state?.reseller?.addProduct?.isSuccess) {
            setLoading(false);
            setStatus(state?.reseller.addProduct.isSuccess);
            setMessage(state.reseller.addProduct.message.text);
            setShowMessage(true);
            setTimeout(() => {
                history.push("/reseller/home");
                setShowMessage(false);
            }, 2000);
            service.addProductClear({ dispatch });
        }
        if (state?.reseller?.addProduct?.isError) {
            setStatus(state?.reseller.addProduct.isSuccess);
            setMessage(state.reseller.addProduct.data.message);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
            setLoading(false);
            service.addProductClear({ dispatch });
        }
    }, [state?.reseller?.addProduct]);
    React.useEffect(() => {
        if (state?.category?.category?.isSuccess) {
            setCategory(state.category.category.data.content);
            setTotalDataCategory(state.category.category.data.total_elements);
        }
        if (state?.city?.allCity?.isSuccess) {
            setCity(state?.city?.allCity.data);
            setAllCity(state?.city?.allCity.data);
            setCityFirst(state?.city?.allCity.data);
        }
        if (state?.category?.categoryAll?.isSuccess) {
            setAllCategory(state.category.categoryAll.data.content);
        }
        if (state?.reseller?.product?.isSuccess) {
            setProduct(state.reseller.product.data.content);
        }
        if (state?.reseller?.productList?.isSuccess) {
            setArrFilters(categoryFilters);
            setLoading(false);
            setProductList(state.reseller.productList.data.content);
            const { number, next_page, total_pages, total_elements } =
                state.reseller.productList.data;
            const isPagination = Number(total_elements) > 1;
            const responseHeaderData = {
                currentPage: Number(number + 1),
                nextPage: next_page,
                total_page: Number(total_pages),
                total_elements: total_elements,
                isPagination: isPagination,
                paginationListNumber: total_pages,
            };
            service.productClearList({ dispatch });
            setConfigurationTable(responseHeaderData);
        }
        setLoadingData(false);
    }, [state, dispatch, totalDataCategory]);
    React.useEffect(() => {
        if (totalDataCategory !== 0) {
            paramsCategory.size = totalDataCategory;
            service.categoryAll({ dispatch, paramsCategory });
            setParamsCategory(paramsCategory);
        }
    }, [totalDataCategory]);
    React.useEffect(() => {
        if (product) {
            setProductReseller(
                product
                    ?.filter((item) => {
                        const filterArray = product?.map(
                            (item) => item.product.id
                        );
                        return !filterArray.includes(item.id);
                    })
                    .map((item) => {
                        return item.product.id;
                    })
            );
        }
    }, [product]);
    return (
        <Style>
            <GrowingLoading active={loading} />
            <MessageState
                status={status}
                message={message}
                isShow={showMessage}
            />
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "20px 10px",
                }}
            >
                <Link
                    to="/reseller/home"
                    style={{
                        fontSize: 14,
                    }}
                >
                    <i className="fas fa-arrow-left"></i> Kembali
                </Link>
            </div>
            <Container fluid>
                <Row>
                    <Filter
                        handlerSearchCity={handlerSearchCity}
                        handleGetAllCity={handleGetAllCity}
                        showAllCity={showAllCity}
                        searchValueCity={searchValueCity}
                        setShowAllCity={setShowAllCity}
                        cityFilters={cityFilters}
                        setCityFilters={setCityFilters}
                        city={city}
                        allCity={allCity}
                        handlerSearchCategory={handlerSearchCategory}
                        handleGetAllCategory={handleGetAllCategory}
                        handleShowFilter={handleShowFilter}
                        handleCloseCity={handleCloseCity}
                        handleCloseCategory={handleCloseCategory}
                        isShowFilter={isShowFilter}
                        showAllCategory={showAllCategory}
                        searchValueCategory={searchValueCategory}
                        allCategory={allCategory}
                        categoryFilters={categoryFilters}
                        setCategoryFilters={setCategoryFilters}
                        onSortFilter={onSortFilter}
                        setSortFilter={setSortFilter}
                        onSortFilterCity={onSortFilterCity}
                        setSortFilterCity={setSortFilterCity}
                        setShowAllCategory={setShowAllCategory}
                        setOpenCategory={setOpenCategory}
                        openCategory={openCategory}
                        setOpenCity={setOpenCity}
                        openCity={openCity}
                        category={category}
                        handleFilterAction={handleFilterAction}
                    />

                    <Col xl="9" lg="9" md="9">
                        <div className="label-top">Daftar Produk</div>
                        <Card>
                            <CardBody>
                                <div>
                                    <Row className="justify-content-between align-items-center">
                                        <Col md={5} lg={5} sm={6} xs={12}>
                                            <FormGroup className="search-box">
                                                <InputGroup className="mb-4">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="fa fa-search" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        ref={inputEl}
                                                        onChange={({
                                                            target: { value },
                                                        }) =>
                                                            eventOnChangeSearchInput(
                                                                value
                                                            )
                                                        }
                                                        defaultValue={
                                                            searchValue
                                                        }
                                                        placeholder="Cari"
                                                        type="text"
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                                {arrFilters.length > 0 && (
                                    <div
                                        style={{
                                            fontSize: 13,
                                            fontWeight: 500,
                                            color: "#464646",
                                            marginBottom: 10,
                                        }}
                                    >
                                        <i>
                                            Kategori :{" "}
                                            <b>
                                                {arrFilters.map(
                                                    (value, key) => {
                                                        return `${value}${
                                                            arrFilters.length ===
                                                            key + 1
                                                                ? ""
                                                                : `,${" "}`
                                                        }`;
                                                    }
                                                )}
                                            </b>
                                        </i>
                                    </div>
                                )}

                                <div>
                                    <Row>
                                        {productList ? (
                                            product &&
                                            (productList.length > 0 ? (
                                                productList.map((item, key) => {
                                                    return (
                                                        <Col
                                                            xl="6"
                                                            lg="6"
                                                            md="6"
                                                            sm="6"
                                                            xs="6"
                                                            key={key}
                                                        >
                                                            <CardProduct
                                                                handleAddProduct={
                                                                    handleAddProduct
                                                                }
                                                                setLoading={
                                                                    setLoading
                                                                }
                                                                loading={
                                                                    loading
                                                                }
                                                                item={item}
                                                                checkProduct={
                                                                    checkProduct
                                                                }
                                                                handleCheckProduct={
                                                                    handleCheckProduct
                                                                }
                                                                handleDetailProduct={
                                                                    handleDetailProduct
                                                                }
                                                            />
                                                        </Col>
                                                    );
                                                })
                                            ) : (
                                                <h6 className="product-zero">
                                                    Tidak Ada Produk
                                                </h6>
                                            ))
                                        ) : (
                                            <React.Fragment>
                                                <Col xl="6" md="6" xs="6">
                                                    <SkeletonLoading
                                                        width="100%"
                                                        height="177px"
                                                        style={{
                                                            marginBottom: 30,
                                                        }}
                                                    />
                                                </Col>
                                                <Col xl="6" md="6" xs="6">
                                                    <SkeletonLoading
                                                        width="100%"
                                                        height="177px"
                                                        style={{
                                                            marginBottom: 30,
                                                        }}
                                                    />
                                                </Col>
                                                <Col xl="6" md="6" xs="6">
                                                    <SkeletonLoading
                                                        width="100%"
                                                        height="177px"
                                                        style={{
                                                            marginBottom: 30,
                                                        }}
                                                    />
                                                </Col>
                                                <Col xl="6" md="6" xs="6">
                                                    <SkeletonLoading
                                                        width="100%"
                                                        height="177px"
                                                        style={{
                                                            marginBottom: 30,
                                                        }}
                                                    />
                                                </Col>
                                            </React.Fragment>
                                        )}
                                    </Row>
                                </div>
                                {configTable && product && !isLoading && (
                                    <TablePagination
                                        limitPagination={
                                            DEFAULT_LIMIT_PAGINATION
                                        }
                                        onClickNumber={onClickNumber}
                                        {...configTable}
                                    ></TablePagination>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {dataProduct && (
                    <ProductDetail
                        showMessage={showMessage}
                        checkProduct={checkProduct}
                        setLoading={setLoading}
                        loading={loading}
                        myProduct={false}
                        item={dataProduct}
                        title="Produk Detail"
                        isShow={isOpen}
                        setShow={setOpen}
                        toggle={toggleDetailProduct}
                    />
                )}
            </Container>
        </Style>
    );
};
export default ProductList;
