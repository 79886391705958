import styled from "styled-components";
import { color } from "../../../Utils/Variable";
const Style = styled.div`
    .banner {
        margin-bottom: 35px;
        .slick-prev,
        .slick-next {
            top: calc(100% - 50%);
            font-size: 20px;
            color: #000000;
            width: max-content;
            height: max-content;
            padding: 6px 10px;
            box-shadow: -4px 4px 16px rgba(0, 0, 0, 0.08);
            background: #fff;
            border-radius: 50%;
            @media (max-width: 550px) {
                font-size: 14px;
                padding: 6px 8px;
            }
            &::before {
                display: none;
            }
        }
        .slick-prev {
            left: -5px;
            z-index: 3;
            @media (max-width: 550px) {
                left: 5px;
            }
        }
        .slick-next {
            right: -7px;
            @media (max-width: 550px) {
                right: 5px;
            }
        }
        .fa-chevron-left,
        .fa-chevron-right {
            color: ${color.primary};
        }
        .slick-dots {
            display: block;
            position: absolute;
            bottom: 25px;
            right: 60px;
            width: max-content;
            @media (max-width: 550px) {
                bottom: 20px;
                right: 20px;
            }
            li {
                @media (max-width: 550px) {
                    width: 5px;
                    height: 5px;
                }
            }
        }
        .slick-dots li button:before {
            font-size: 20px;
            @media (max-width: 550px) {
                font-size: 10px;
            }
        }
        .slick-dots li.slick-active button:before {
            color: #fff;
        }
        &-slider,
        .container {
            @media (max-width: 550px) {
                padding: 0;
            }
        }
    }
    .image-banner {
        border-radius: 20px;
        @media (max-width: 550px) {
            border-radius: 0px;
        }
    }
    .section {
        position: relative;
        .background-section {
            &__top {
                position: absolute;
                top: -1px;
                right: -1px;
            }
            &__bottom {
                position: absolute;
                top: 0;
                left: -1px;
                z-index: 1;
            }
        }
        &-info {
            margin-bottom: 90px;
            @media (max-width: 550px) {
                margin-bottom: 0px;
            }
        }
        &-bottom {
            display: block;
            width: 100%;
            img {
                margin-bottom: 15px;
            }
            .col-title {
                font-size: 16px;
                span {
                    font-weight: bold;
                    color: ${color.primary};
                }
            }
            @media (max-width: 767px) {
                .img-border {
                    display: none;
                }
            }
            @media (max-width: 550px) {
                img {
                    max-width: 40px;
                }

                .col-title {
                    font-size: 10px;
                }
            }
        }
        &-join {
            background: ${color.primary};
            color: #fff;
        }
        @media (max-width: 767px) {
            padding: 50px 0;
        }
    }
    .desc {
        &-step {
            font-size: 14px;
        }
    }
    .number {
        font-size: 40px;
        font-weight: 900;
        color: #ddd;
        transition: all 0.5s ease;
    }
    .col {
        &-desc,
        &-title {
            font-size: 14px;
        }
    }
    hr {
        margin-bottom: 5rem;
        margin-top: 5rem;
    }

    .step {
        position: relative;
        &-card {
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            text-align: center;
            top: -80px;
            img {
                margin: auto;
            }
        }
    }

    .card-step {
        background: #2d46ee;
        @media (max-width: 400px) {
            min-height: 187px;
        }
        .card-body {
            padding: 60px 15px 10px;
            .accordion-desc {
                color: #fff;
                text-align: center;
                .fas {
                    color: #fff;
                    border: 1px solid #fff;
                    padding: 3px 6px;
                    border-radius: 50%;
                }
            }
        }
        .col-title {
            line-height: 1.5;
            margin: auto;
            color: #000;
            padding: 10px;
            margin-bottom: 20px;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;

            @media (max-width: 487px) {
                width: 100%;
                font-size: 10px;
            }
        }
    }

    .img-join {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .section-content__text {
        .slick-slide {
            display: block;
            width: 100% !important;
            transition: all 0.5s ease;
            &.slick-current {
                .number {
                    color: ${color.user.yellow};
                    transition: all 0.5s ease;
                    font-size: 50px;
                }
                .title-step {
                    font-size: 18px;
                    transition: all 0.5s ease;
                    font-weight: 600;
                    color: ${color.primary};
                    margin-bottom: 5px;
                    @media (max-width: 575px) {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .section-content__step {
        margin-bottom: 30px;
        transition: all 0.5s ease;
        display: block;
    }
    .join-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3 {
            font-weight: bold;
            @media (max-width: 767px) {
                font-size: 16px;
            }
        }
        p {
            @media (max-width: 767px) {
                font-size: 12px;
            }
        }
        .btn-outline-primary {
            font-size: 20px;
            padding-left: 40px;
            padding-right: 40px;
            border-radius: 20px;
            @media (max-width: 767px) {
                font-size: 12px;
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
`;
export default Style;
