import React from "react";
import { Link } from "react-router-dom";
import Style from "../index.style";
import Logo from "../../../../Components/Assets/v2/logo.png";
import Footer from "../../../../Components/Assets/v2/footer.png";
import IconsFacebook from "../../../../Components/Assets/v2/icons/facebook.png";
import IconsInstagram from "../../../../Components/Assets/v2/icons/instagram.png";
import IconsTwitter from "../../../../Components/Assets/v2/icons/twitter.png";
import IconsTiktok from "../../../../Components/Assets/v2/icons/tiktok.png";
import IconsYoutube from "../../../../Components/Assets/v2/icons/youtube.png";
import IconsTelegram from "../../../../Components/Assets/v2/icons/telegram.png";
import IconsWhatsapp from "../../../../Components/Assets/v2/icons/whatsapp.png";
import IconsLinkedin from "../../../../Components/Assets/v2/icons/linkedin.png";

const SectionFooter = () => {
    return (
        <Style>
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-footer">
                            <h1 className="header1-primary-blue">Tunggu Apa Lagi? <br/> Yuk Join Sekarang!</h1>
                            <Link
                                to={`/signup/reseller`}
                                className="btn btn-warning btn-register"
                            >
                                Daftar Sekarang
                            </Link>
                        </div>
                        <div className="col-sm-6">
                            <img src={Footer} alt="" className="img-footer" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="container">
                    <div className="row row-footer">
                        <div className="col-sm-3">
                            <div className="wrapper-footer">
                                <img src={Logo} alt="" />
                                <p className="text-white">Jl. Aria Putra no.88, RT.09/RW.01, Sawah Baru, Kec.Ciputat, Kota Tangerang Selatan, Banten 15414</p>
                                <br/>
                                <span>halo@lakugan.com</span>
                                <h3>08218005188</h3>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="wrapper-footer text-left">
                                <h4>Lakugan</h4>
                                <a href="/about-us">Tentang Kami</a>
                                <a href="/blog">Blog</a>
                                <a href="/FAQ">FAQ</a>
                                <a href="/contact">Kontak Kami</a>
                                <a href="/career">Karir</a>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="wrapper-footer">
                                <h4>Pengguna</h4>
                                <a href="#">Daftar Reseller</a>
                                <a href="#">Daftar Supplier</a>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="wrapper-footer wrapper-icons">
                                <h4>Media Sosial</h4>
                                <a target="_blank" href="#"><img src={IconsFacebook} className="img-icons" alt="" /></a>
                                <a target="_blank" href="https://www.instagram.com/lakugan_id/"><img src={IconsInstagram} className="img-icons" alt="" /></a>
                                <a target="_blank" href="https://twitter.com/lakugan_id"><img src={IconsTwitter} className="img-icons" alt="" /></a>
                                <a target="_blank" href="#"><img src={IconsTiktok} className="img-icons" alt="" /></a>
                                <a target="_blank" href="https://www.youtube.com/@lakuganofficial"><img src={IconsYoutube} className="img-icons" alt="" /></a>
                                <a target="_blank" href="#"><img src={IconsTelegram} className="img-icons" alt="" /></a>
                                <a target="_blank" href="#"><img src={IconsWhatsapp} className="img-icons" alt="" /></a>
                                <a target="_blank" href="#"><img src={IconsLinkedin} className="img-icons" alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="row row-footer-bottom">
                        <div className="col-sm-3">
                            <a href="#">Syarat Penggunaan</a>
                        </div>
                        <div className="col-sm-3 text-center">
                            <a href="#">Kebijakan Privasi</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <p className="text-footer">2023 Lakugan, Inc, All Rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </Style>
    )
};
export default SectionFooter;