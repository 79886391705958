import React, { useRef } from "react";
import Style from "./index.style";
import Slider from "../../../Components/Assets/v2/slider.png";
import Testimonial from "../../../Components/Assets/v2/renon.jpg";
import Testimonial2 from "../../../Components/Assets/v2/testimonial2.jpeg";
import Testimonial3 from "../../../Components/Assets/v2/dewi.jpg";
import Testimonial5 from "../../../Components/Assets/v2/tutty.jpg";
import Testimonial4 from "../../../Components/Assets/v2/hendra.jpg";

import SectionStart from "./SectionStart";
import SectionStep from "./SectionStep";
import SectionBenefit from "./SectionBenefit";
import SectionProduct from "./SectionProduct";
import SectionFAQ from "./SectionFAQ";
import SectionPartnership from "./SectionPartnership";
import SectionFooter from "./SectionFooter";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Navigation, Pagination } from "swiper";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

const Home2 = () => {
    const swiperRef = useRef();
    let sliderTestimonial= [{
        id:1,
        src:Testimonial,
        name:"Renon",
        job:"Photographer",
        testimonial:"Produk Emas nya keren banget, bisa custom untuk kasih gift ke model saya. Harga juga terjangkau banget."
    },
    {
        id:2,
        src:Testimonial2,
        name:"Idham",
        job:"Product Owner",
        testimonial:"Sebagai pemilik produk, saya bangga bekerja dengan Lakugan. Mereka menjadi mitra andal dalam pemasaran dan penjualan produk saya. Profesionalisme mereka luar biasa, detail-oriented, dan memiliki jaringan distribusi yang luas. Kemitraan ini sangat berharga bagi saya."

    },
    {
        id:3,
        src:Testimonial3,
        name:"Dewi Maharani",
        job:"Product Owner",
        testimonial:"Harga skincare nya murah-murah banget…saya langsung nyetok aja biar untung nya double."

    },
    {
        id:5,
        src:Testimonial5,
        name:"Tutty",
        job:"Ibu Rumah Tangga",
        testimonial:"Enak banget pakai lakugan nggak pake modal sama sekali, sangat membantu untuk tambah-tambah uang dapur."

    },
    {
        id:4,
        src:Testimonial4,
        name:"Hendra",
        job:"Wiraswasta",
        testimonial:"Baru kali ini nemu platform reseller yang enak banget, tanpa modal, Cuma share link produk dapet komisi."

}
]

    return (
        <Style>
            <section className="first">
                <SectionStart />
            </section>
            <section className="second">
                <SectionStep />
            </section>
            <section className="third">
                <SectionBenefit />
            </section>
            <section className="fourth">
                <SectionProduct />
                <div className="footer">
                    <Swiper
                        modules={[Pagination, Navigation]}
                        className="swiperProduct"
                        slidesPerView="auto"
                        spaceBetween={30}
                        loop={true}
                        loopFillGroupWithBlank={true}
                        centeredSlides={true}
                        navigation
                        pagination={{clickable: true}}
                    >
                        {Array.apply(0, Array(4)).map(function (x, i) {
                            return <SwiperSlide key={i}><img src={Slider} alt="" /></SwiperSlide>;
                        })}
                    </Swiper>
                </div>
            </section>
            <section className="fiveth">
                <div className="header">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 col-title">
                                <ul>
                                    <li>Testimonial</li>
                                </ul>
                            </div>
                            <div className="col-sm-6 testimonial-nav">
                                <div className="swiper-button-prev" onClick={() => swiperRef.current?.slidePrev()}></div>
                                <div className="swiper-button-next" onClick={() => swiperRef.current?.slideNext()}></div>
                            </div>
                        </div>
                    </div>
                    <Swiper
                        className="swiperTestimonial"
                        slidesPerView="auto"
                        spaceBetween={30}
                        loop={true}
                        loopFillGroupWithBlank={true}
                        centeredSlides={true}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                    >
                        {sliderTestimonial.map(function (x, i) {
                            console.log(x)
                            return <SwiperSlide key={i}>
                                    <div className="wrapper-testmonial">
                                        <div className="row">
                                            <div className="col-3">
                                                <div className="avatar-testimonial">
                                                    <img src={x.src} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-9 col-testimonial-mobile">
                                                <h3>{x.name}</h3>
                                                <span>{x.job}</span>
                                            </div>
                                        </div>
                                        <p style={{align:"justify"}}>{x.testimonial}</p>
                                    </div>
                                </SwiperSlide>;
                        })}
                    </Swiper>
                </div>
            </section>
            <section className="sixth">
                <SectionFAQ />
            </section>
            <section className="seventh">
                <SectionPartnership />
            </section>
            <section className="eighth">
                <SectionFooter />
            </section>
        </Style>
    );
};
export default Home2;
