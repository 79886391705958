import React, { useState } from "react";
import { Col, Container, Row, Alert } from "reactstrap";
import Detail from "./Detail";
import { ProductStyle } from "./index.style";
import SliderProduct from "./SliderProduct";
import { useHistory } from "react-router-dom";
// import { formatMoney } from "../../../Utils/PriceConverter";
// import Button from "../../../Components/Particle/Button";
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";
// import Member from "./Modal/Member";
import service from "./index.service";
import { useStore } from "../../../Reducers";
import SkeletonLoading from "../../../Components/Loading/Skeleton";
import LocalStorage from "../../../Utils/LocalStorage";
import CardNew from "../../../Components/Cards/CardNew";
import CheckMobile from "../../../Utils/CheckMobile";
import HeaderMobile from "./Header/HeaderMobile";

const Product = (
  {
    match: {
      params: { slug },
    },
  },
  props
) => {
  // const SHIPPER_TYPE = JSON.parse(LocalStorage().get("SHIPPER_TYPE"));
  const { dispatch, state } = useStore();
  const [isFirstGetLoad, setFirstGetLoad] = useState(true);
  const [isAlert, setAlert] = useState(false);
  const [voucherList, setVoucherList] = useState([]);
  const [alertMessage, setAlertMessage] = useState(false);
  const [filterSize, setFilterSize] = useState([]);
  const [filterColor, setFilterColor] = useState([]);
  const [size, setSize] = useState("");
  const [colorItem, setColorItem] = useState("");
  const [isFirsGet, setFirstGet] = useState(true);
  const [dataProduct, setDataProduct] = useState(false);
  const [totalItem, setTotalItem] = useState(1);
  const [description, setDescription] = useState("");
  const [isPriceFooterShow] = useState(true);
  const [voucherName, setVoucherName] = useState("Pilih Voucher");
  const history = useHistory();
  const [headerName, setHeaderName] = useState(false);

  // const productDetail = [
  //     {
  //         title: "Deskripsi Barang",
  //         desc: dataProduct?.product?.description,
  //     },
  // ];
  const onGetValue = (item) => {
    history.push(`/product/${item.code}`);
    setFirstGet(true);
    window.scrollTo(0, 0);
  };
  const handleDescription = (e) => {
    let value = e.target.value;
    setDescription(value);
  };
  // FUNCTION SAVE DATA ORDER IN LOCAL STORAGE
  const nextPayment = () => {
    let dataOrder = {
      item: slug,
      total: totalItem,
      color: colorItem,
      size: size,
      weight: dataProduct.product.spec[0].weight,
      width: dataProduct.product.spec[0].width,
      height: dataProduct.product.spec[0].height,
      length: dataProduct.product.spec[0].length,
      product_id: dataProduct.product.id,
      description: description,
      spec:
        filterColor.length < 1 || filterSize.length < 1
          ? dataProduct.product.spec.filter(
              (item) =>
                item.color === filterColor[0].color &&
                item.size === filterSize[0].size
            )
          : dataProduct.product.spec,
    };

    LocalStorage().save("_ORDER_ITEM_LAKUGAN", dataOrder);
    history.push(`/product/order/beli-sekarang`);
  };
  // function check form sebelum ke next payment
  const handleNextOrder = () => {
    if (dataProduct?.product?.supplier?.is_open === true) {
      if (
        dataProduct?.product?.spec[0]?.color !== "" ||
        dataProduct?.product?.spec[0]?.size !== ""
      ) {
        if (filterSize.length > 0) {
          if (description !== "" && size !== "") {
            if (
              dataProduct?.product?.spec[0]?.color !== "" &&
              colorItem === ""
            ) {
              window.scroll(0, 0);
              setAlert(true);
              setAlertMessage("Pilih Warna");
            } else {
              nextPayment();
              setAlert(false);
              LocalStorage().save("BUYER_DESCRIPTION", {
                size: size,
                color: colorItem,
                description: description,
              });
              window.scroll(0, 0);
            }
          } else {
            setAlert(true);
            // if (
            //     (description === "" &&
            //         dataProduct?.product.spec[0].color !== "") ||
            //     (description === "" &&
            //         dataProduct?.product.spec[0].size !== "")
            // ) {
            //     setAlertMessage("Catatan Pembeli Harus di isi");
            if (colorItem === "" && dataProduct?.product.spec[0].color !== "") {
              setAlertMessage("Pilih Warna");
            } else if (
              size === "" &&
              dataProduct?.product.spec[0].size !== ""
            ) {
              setAlertMessage("Pilih Ukuran");
            } else {
              LocalStorage().save("BUYER_DESCRIPTION", {
                size: size,
                color: colorItem,
                description: description,
              });
              setAlert(false);
              nextPayment();
            }
            window.scroll(0, 0);
          }
        } else if (filterColor.length > 0) {
          if (colorItem !== "") {
            if (dataProduct?.product?.spec[0]?.size !== "" && size === "") {
              window.scroll(0, 0);
              setAlert(true);
              setAlertMessage("Pilih Ukuran");
            } else {
              nextPayment();
              setAlert(false);
              LocalStorage().save("BUYER_DESCRIPTION", {
                size: size,
                color: colorItem,
              });
              window.scroll(0, 0);
            }
          } else {
            setAlert(true);
            if (colorItem === "" && dataProduct?.product.spec[0].color !== "") {
              setAlertMessage("Pilih Warna");
            } else if (
              size === "" &&
              dataProduct?.product.spec[0].size !== ""
            ) {
              setAlertMessage("Pilih Ukuran");
            } else {
              setAlert(false);
              nextPayment();
            }
            window.scroll(0, 0);
          }
        }
      } else {
        nextPayment();
        setAlert(false);
        window.scroll(0, 0);
      }
    }
  };
  const handleGetProduct = () => {
    if (isFirsGet) {
      service.voucherList({ dispatch });
      service.getProduct({ dispatch, slug });
      setFirstGet(false);
    }
    window.scroll(0, 0);
  };
  React.useEffect(() => {
    if (isFirstGetLoad) {
      setFirstGetLoad(false);
    }
  }, [isFirstGetLoad]);
  const handleGetProductCallback = React.useCallback(handleGetProduct);
  React.useEffect(() => {
    handleGetProductCallback();
  }, [handleGetProductCallback]);
  React.useEffect(() => {
    if (state?.user?.product?.isSuccess) {
      setDataProduct(state.user.product.data);
      setHeaderName(state.user.product.data.product.name);
      setFirstGet(false);
      service.productClearList({ dispatch });
    }
    if (state?.voucher?.voucherList?.isSuccess) {
      service.voucherListClear({ dispatch });
      setVoucherList(state.voucher.voucherList.data.content);
    }
    if (state?.user?.product?.isError) {
      window.location = "/404";
    }
  }, [state, dispatch]);
  React.useEffect(() => {
    // clear voucher when back to product detail from payment page
    LocalStorage().remove("VOUCHER_USED");
  }, []);
  return (
    <React.Fragment>
      {CheckMobile() === true ? (
        <HeaderMobile HeaderName={headerName} />
      ) : (
        <Header backButton={false} />
      )}
      <ProductStyle mrg={dataProduct?.product?.product_image?.length}>
        {dataProduct && !dataProduct.product.supplier.is_open && (
          <div className="isClose">
            <div>Toko ini sedang tidak beroprasi</div>
          </div>
        )} 
        <Container fluid="md">
          {isAlert && <Alert color="danger">{alertMessage}</Alert>}
          <Row>
            <Col xl="5" lg="5" md="5" sm="12" className="no-padding">
              <div className="slider-product">
                <SliderProduct data={dataProduct} />
              </div>
              {/* {dataProduct?.product?.product_image?.length >
                                1 && <hr style={{ marginBottom: "2rem" }} />} */}
              {/* <div>
                                <TabMenu
                                    color={{
                                        text: "#aaa",
                                        background: "#fff",
                                        activeColor: color.primary,
                                        activeBackground: color.primary,
                                    }}
                                    contentStyle={{
                                        padding: "15px 0",
                                    }}
                                    data={[
                                        {
                                            title: "Informasi Produk",
                                            key: 1,
                                            render: () => {
                                                return productDetail.map(
                                                    (item, key) => {
                                                        return (
                                                            <div
                                                                key={key}
                                                                className="article-desc"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item.desc,
                                                                }}
                                                            />
                                                        );
                                                    }
                                                );
                                            },
                                        },
                                    ]}
                                />
                            </div> */}
            </Col>
            <Col xl="7" lg="7" md="7" sm="12">
              <Detail
                description={description}
                slug={slug}
                filterColor={filterColor}
                filterSize={filterSize}
                setFilterColor={setFilterColor}
                setFilterSize={setFilterSize}
                totalItem={totalItem}
                handleDescription={handleDescription}
                setColorItem={setColorItem}
                colorItem={colorItem}
                size={size}
                setSize={setSize}
                data={dataProduct}
                setTotalItem={setTotalItem}
                toggle={handleNextOrder}
                voucherList={voucherList}
                setVoucherName={setVoucherName}
                voucherName={voucherName}
              />
            </Col>
          </Row>
          {CheckMobile() === true ? (
            <hr style={{ margin: 0, paddingBottom: "1rem" }} />
          ) : (
            <hr />
          )}
          <Row>
            <Col xl="12">
              {dataProduct ? (
                <h3 className="any-product">Produk Lainnya</h3>
              ) : (
                <SkeletonLoading height="28px" width="120px" />
              )}
            </Col>
            {dataProduct ? (
              dataProduct.other_product.map((item, key) => {
                return (
                  <div className="col-lg-3 col-md-4 col-6" key={key}>
                    <CardNew
                      onGetValue={onGetValue}
                      item={item}
                      image={item.product?.product_image[0]?.img_url}
                      alt={item.product?.product_image[0]?.name}
                      name={item.product?.name}
                      rating={item.product?.rating}
                      price={item.product?.price}
                      city={item.product?.supplier.city}
                      stock={item.product?.total_stock_product}
                    />
                  </div>
                );
              })
            ) : (
              <React.Fragment>
                <Col xl="3" md="3" lg="3" sm="6" xs="12">
                  <SkeletonLoading width="100%" height="100%" />
                </Col>
                <Col xl="3" md="3" lg="3" sm="6" xs="12">
                  <SkeletonLoading width="100%" height="100%" />
                </Col>
                <Col xl="3" md="3" lg="3" sm="6" xs="12">
                  <SkeletonLoading width="100%" height="100%" />
                </Col>
                <Col xl="3" md="3" lg="3" sm="6" xs="12">
                  <SkeletonLoading width="100%" height="100%" />
                </Col>
              </React.Fragment>
            )}
          </Row>
        </Container>
      </ProductStyle>
      <div className="footer-product">
        <Footer
          setTotalItem={setTotalItem}
          totalItem={totalItem}
          nextPayment={handleNextOrder}
          voucherList={voucherList}
          dataProduct={dataProduct}
          footerTop={false}
          isPriceFooterShow={isPriceFooterShow}
          setVoucherName={setVoucherName}
          voucherName={voucherName}
        />
      </div>
    </React.Fragment>
  );
};
export default Product;
