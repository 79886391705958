import React from "react";
import Style from "../index.style";
import Skintific from "../../../../Components/Assets/v2/skintific.png";
import Shekia from "../../../../Components/Assets/v2/shekia.png";
import Somethinc from "../../../../Components/Assets/v2/somethinc.png";
import Sweet from "../../../../Components/Assets/v2/sweet.png";
import Cheerful from "../../../../Components/Assets/v2/cheerful.png";
import Emasin from "../../../../Components/Assets/v2/emasin.png";
import Reseller from "../../../../Components/Assets/v2/reseller.png";
import Reseller2 from "../../../../Components/Assets/v2/reseller2.jpeg";
import Reseller3 from "../../../../Components/Assets/v2/reseller3.jpeg";
import Ovo from "../../../../Components/Assets/v2/pembayaran/ovo.png";
import Gopay from "../../../../Components/Assets/v2/pembayaran/gopay.png";
import Dana from "../../../../Components/Assets/v2/pembayaran/dana.png";
import LinkAja from "../../../../Components/Assets/v2/pembayaran/linkaja.png";
import Cimb from "../../../../Components/Assets/v2/pembayaran/cimb.png";
import Maybank from "../../../../Components/Assets/v2/pembayaran/maybank.png";
import Danamon from "../../../../Components/Assets/v2/pembayaran/danamon.png";
import Bca from "../../../../Components/Assets/v2/pembayaran/bca.png";
import Bni from "../../../../Components/Assets/v2/pembayaran/bni.png";
import Bnc from "../../../../Components/Assets/v2/pembayaran/bnc.png";
import Bri from "../../../../Components/Assets/v2/pembayaran/bri.png";
import Permata from "../../../../Components/Assets/v2/pembayaran/permata.png";
import Mandiri from "../../../../Components/Assets/v2/pembayaran/mandiri.png";
import Anteraja from "../../../../Components/Assets/v2/pengiriman/anteraja.png";
import Sicepat from "../../../../Components/Assets/v2/pengiriman/sicepat.png";
import Tiki from "../../../../Components/Assets/v2/pengiriman/tiki.png";
import Jne from "../../../../Components/Assets/v2/pengiriman/jne.png";
import Lion from "../../../../Components/Assets/v2/pengiriman/lion.png";
import Grab from "../../../../Components/Assets/v2/pengiriman/grab.png";
import Ninja from "../../../../Components/Assets/v2/pengiriman/ninja.png";
import Rpx from "../../../../Components/Assets/v2/pengiriman/rpx.png";
import Wahana from "../../../../Components/Assets/v2/pengiriman/wahana.png";
import Alfatrex from "../../../../Components/Assets/v2/pengiriman/alfatrex.png";

const SectionPartnership = () => {
    return (
        <Style>
            <div className="container">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="wrapper-reseller-month">
                            <div className="col-sm-12 col-title">
                                <ul>
                                    <li>Reseller of The Month</li>
                                </ul>
                            </div>
                            <div className="card-reseller-month">
                                <img src={Reseller} alt="" />
                                <h3>Lakugan Emas</h3>
                                <p>Entrepreneur</p>
                            </div>
                            <div className="card-reseller-month">
                                <img src={Reseller2} alt="" />
                                <h3>Hafidh</h3>
                                <p>Guru</p>
                            </div>
                            <div className="card-reseller-month">
                                <img src={Reseller3} alt="" />
                                <h3>Adrian</h3>
                                <p>Software Developer</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="wrapper-reseller-rating">
                            <div className="col-sm-12 col-title">
                                <ul>
                                    <li>Reseller Rating</li>
                                </ul>
                            </div>
                            <div className="table-reseller-rating">
                                <table className="table table-responsive borderless">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Nama Reseller</th>
                                            <th>Domisili</th>
                                            <th>Total Transaksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span style={{float: 'left', marginLeft: '-10px'}}>
                                                    <i className="fa fa-arrow-up" style={{color: '#00FF01'}}></i>
                                                </span>
                                                1
                                            </td>
                                            <td>Lakugan Emas</td>
                                            <td>Ciledug</td>
                                            <td>52</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Hafidh</td>
                                            <td>Bandung</td>
                                            <td>44</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Adrian</td>
                                            <td>Bekasi</td>
                                            <td>40</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span style={{float: 'left', marginLeft: '-10px'}}>
                                                    <i className="fa fa-arrow-down" style={{color: '#FE0000'}}></i>
                                                </span>
                                                4
                                            </td>
                                            <td>Tarjo</td>
                                            <td>Cilacap</td>
                                            <td>39</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Sri</td>
                                            <td>Jombang</td>
                                            <td>33</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>Kevin</td>
                                            <td>Jakarta</td>
                                            <td>30</td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>Ilham</td>
                                            <td>Kudus</td>
                                            <td>29</td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>HerryP</td>
                                            <td>Anyer</td>
                                            <td>28</td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>Sarirasa</td>
                                            <td>Aceh</td>
                                            <td>13</td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                            <td>Helmi</td>
                                            <td>Tangerang</td>
                                            <td>11</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row-partnership">
                    <div className="col-sm-6 col-title">
                        <ul>
                            <li>
                                Bekerja Sama Dengan
                            </li>
                        </ul>
                    </div>
                    <div className="wrapper-partnership">
                        <div className="row">
                            <div className="col-4">
                                <img src={Skintific} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Shekia} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Sweet} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Somethinc} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Cheerful} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Emasin} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop: '120px'}}>
                    <div className="col-sm-6 col-title">
                        <ul>
                            <li>Pembayaran</li>
                        </ul>
                        <div className="row row-finance">
                            <div className="col-4">
                                <img src={Ovo} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Gopay} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Dana} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={LinkAja} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Cimb} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Maybank} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Danamon} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Bca} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Bni} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Bnc} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Bri} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Permata} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Mandiri} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-title">
                        <ul>
                            <li>
                                Jasa Pengiriman
                            </li>
                        </ul>
                        <div className="row row-shipper">
                            <div className="col-4">
                                <img src={Anteraja} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Sicepat} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Tiki} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Jne} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Lion} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Grab} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Ninja} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Rpx} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Wahana} alt="" />
                            </div>
                            <div className="col-4">
                                <img src={Alfatrex} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Style>
    )
};
export default SectionPartnership;