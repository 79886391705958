import React from "react";
import { Container } from "reactstrap";
import Button from "../../../../Components/Particle/Button";
import { formatMoney } from "../../../../Utils/PriceConverter";
import styled from "styled-components";
import { color } from "../../../../Utils/Variable";
const Style = styled.div`
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    background: #fff;
    padding: 10px;
    border-top: 1px solid #ddd;
    .checkout-payment {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .bill-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        &__text {
            color: #4a4a4a;
            font-size: 14px;
            display: block;
            font-weight: 500;
        }
        h6 {
            font-size: 14px;
        }
        .desc {
            font-size: 12px;
            color: #4a4a4a;
        }
        .price {
            font-weight: 500;
            color: #4a4a4a;
            font-size: 14px;
            &-total {
                font-size: 20px;
                font-weight: bold;
                color: ${color.primary};
            }
        }
    }
`;
const ButtonNavigation = ({
    priceInsurance = false,
    shipperList = false,
    paymentChannel = false,
    shipperUsed = false,
    dataOrder = false,
    voucher = false,
    productInfo = false,
    handleOrderPayment = () => {},
    onCheckValue = () => {},
    digital = false,
}) => {
    return (
        <Style>
            <Container>
                <div className="checkout-payment">
                    <div className="bill-price" style={{ margin: 0 }}>
                        <div>
                            <h5
                                className="font-weight-bold"
                                style={{
                                    margin: "0 20px 0 0",
                                    color: "#464646",
                                }}
                            >
                                Sub
                                <br />
                                Total
                            </h5>
                        </div>
                        {priceInsurance ? (
                            <div className="price-total">
                                {voucher
                                    ? paymentChannel
                                        ? paymentChannel.percentages !== 0
                                            ? formatMoney(
                                                  dataOrder?.total *
                                                      productInfo?.product
                                                          ?.price +
                                                      (shipperUsed.finalRate ||
                                                          0) +
                                                      ((dataOrder?.total *
                                                          productInfo?.product
                                                              ?.price) /
                                                          100) *
                                                          paymentChannel.percentages +
                                                      priceInsurance.insuranceRate +
                                                      (((shipperUsed.finalRate ||
                                                          0) /
                                                          100) *
                                                          Number(
                                                              voucher.discount_percentages
                                                          ) >
                                                      voucher.max_discount
                                                          ? voucher.max_discount -
                                                            voucher.max_discount *
                                                                2
                                                          : ((shipperUsed.finalRate ||
                                                                0) /
                                                                100) *
                                                                Number(
                                                                    voucher.discount_percentages
                                                                ) -
                                                            ((shipperUsed.finalRate ||
                                                                0) /
                                                                100) *
                                                                Number(
                                                                    voucher.discount_percentages
                                                                ) *
                                                                2)
                                              )
                                            : formatMoney(
                                                  dataOrder?.total *
                                                      productInfo?.product
                                                          ?.price +
                                                      (shipperUsed.finalRate ||
                                                          0) +
                                                      paymentChannel.fee +
                                                      priceInsurance.insuranceRate +
                                                      (((shipperUsed.finalRate ||
                                                          0) /
                                                          100) *
                                                          Number(
                                                              voucher.discount_percentages
                                                          ) >
                                                      voucher.max_discount
                                                          ? voucher.max_discount -
                                                            voucher.max_discount *
                                                                2
                                                          : ((shipperUsed.finalRate ||
                                                                0) /
                                                                100) *
                                                                Number(
                                                                    voucher.discount_percentages
                                                                ) -
                                                            ((shipperUsed.finalRate ||
                                                                0) /
                                                                100) *
                                                                Number(
                                                                    voucher.discount_percentages
                                                                ) *
                                                                2)
                                              )
                                        : formatMoney(
                                              dataOrder?.total *
                                                  productInfo?.product?.price +
                                                  (shipperUsed.finalRate || 0) +
                                                  priceInsurance.insuranceRate +
                                                  (((shipperUsed.finalRate ||
                                                      0) /
                                                      100) *
                                                      Number(
                                                          voucher.discount_percentages
                                                      ) >
                                                  voucher.max_discount
                                                      ? voucher.max_discount -
                                                        voucher.max_discount * 2
                                                      : ((shipperUsed.finalRate ||
                                                            0) /
                                                            100) *
                                                            Number(
                                                                voucher.discount_percentages
                                                            ) -
                                                        ((shipperUsed.finalRate ||
                                                            0) /
                                                            100) *
                                                            Number(
                                                                voucher.discount_percentages
                                                            ) *
                                                            2)
                                          )
                                    : paymentChannel
                                    ? paymentChannel.percentages !== 0
                                        ? formatMoney(
                                              dataOrder?.total *
                                                  productInfo?.product?.price +
                                                  (shipperUsed.finalRate || 0) +
                                                  ((dataOrder?.total *
                                                      productInfo?.product
                                                          ?.price) /
                                                      100) *
                                                      paymentChannel.percentages +
                                                  priceInsurance.insuranceRate
                                          )
                                        : formatMoney(
                                              dataOrder?.total *
                                                  productInfo?.product?.price +
                                                  (shipperUsed.finalRate || 0) +
                                                  paymentChannel.fee +
                                                  priceInsurance.insuranceRate
                                          )
                                    : formatMoney(
                                          dataOrder?.total *
                                              productInfo?.product?.price +
                                              (shipperUsed.finalRate || 0) +
                                              priceInsurance.insuranceRate
                                      )}
                            </div>
                        ) : voucher ? (
                            paymentChannel ? (
                                paymentChannel.percentages !== 0 ? (
                                    <div className="price-total">
                                        {priceInsurance
                                            ? formatMoney(
                                                  dataOrder?.total *
                                                      productInfo?.product
                                                          ?.price +
                                                      (shipperUsed.finalRate ||
                                                          0) +
                                                      ((dataOrder?.total *
                                                          productInfo?.product
                                                              ?.price) /
                                                          100) *
                                                          paymentChannel.percentages +
                                                      priceInsurance.insuranceRate +
                                                      (((shipperUsed.finalRate ||
                                                          0) /
                                                          100) *
                                                          Number(
                                                              voucher.discount_percentages
                                                          ) >
                                                      voucher.max_discount
                                                          ? voucher.max_discount -
                                                            voucher.max_discount *
                                                                2
                                                          : ((shipperUsed.finalRate ||
                                                                0) /
                                                                100) *
                                                                Number(
                                                                    voucher.discount_percentages
                                                                ) -
                                                            ((shipperUsed.finalRate ||
                                                                0) /
                                                                100) *
                                                                Number(
                                                                    voucher.discount_percentages
                                                                ) *
                                                                2)
                                              )
                                            : formatMoney(
                                                  dataOrder?.total *
                                                      productInfo?.product
                                                          ?.price +
                                                      (shipperUsed.finalRate ||
                                                          0) +
                                                      ((dataOrder?.total *
                                                          productInfo?.product
                                                              ?.price) /
                                                          100) *
                                                          paymentChannel.percentages +
                                                      (((shipperUsed.finalRate ||
                                                          0) /
                                                          100) *
                                                          Number(
                                                              voucher.discount_percentages
                                                          ) >
                                                      voucher.max_discount
                                                          ? voucher.max_discount -
                                                            voucher.max_discount *
                                                                2
                                                          : ((shipperUsed.finalRate ||
                                                                0) /
                                                                100) *
                                                                Number(
                                                                    voucher.discount_percentages
                                                                ) -
                                                            ((shipperUsed.finalRate ||
                                                                0) /
                                                                100) *
                                                                Number(
                                                                    voucher.discount_percentages
                                                                ) *
                                                                2)
                                              )}
                                    </div>
                                ) : priceInsurance ? (
                                    <div className="price-total">
                                        {formatMoney(
                                            dataOrder?.total *
                                                productInfo?.product?.price +
                                                (shipperUsed.finalRate || 0) +
                                                paymentChannel.fee +
                                                priceInsurance.insuranceRate +
                                                (((shipperUsed.finalRate || 0) /
                                                    100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) >
                                                voucher.max_discount
                                                    ? voucher.max_discount -
                                                      voucher.max_discount * 2
                                                    : ((shipperUsed.finalRate ||
                                                          0) /
                                                          100) *
                                                          Number(
                                                              voucher.discount_percentages
                                                          ) -
                                                      ((shipperUsed.finalRate ||
                                                          0) /
                                                          100) *
                                                          Number(
                                                              voucher.discount_percentages
                                                          ) *
                                                          2)
                                        )}
                                    </div>
                                ) : (
                                    <div className="price-total">
                                        {formatMoney(
                                            dataOrder?.total *
                                                productInfo?.product?.price +
                                                (shipperUsed.finalRate || 0) +
                                                paymentChannel.fee +
                                                (((shipperUsed.finalRate || 0) /
                                                    100) *
                                                    Number(
                                                        voucher.discount_percentages
                                                    ) >
                                                voucher.max_discount
                                                    ? voucher.max_discount -
                                                      voucher.max_discount * 2
                                                    : ((shipperUsed.finalRate ||
                                                          0) /
                                                          100) *
                                                          Number(
                                                              voucher.discount_percentages
                                                          ) -
                                                      ((shipperUsed.finalRate ||
                                                          0) /
                                                          100) *
                                                          Number(
                                                              voucher.discount_percentages
                                                          ) *
                                                          2)
                                        )}
                                    </div>
                                )
                            ) : shipperUsed ? (
                                <div className="price-total">
                                    {formatMoney(
                                        dataOrder?.total *
                                            productInfo?.product?.price +
                                            (shipperUsed.finalRate || 0) +
                                            (((shipperUsed.finalRate || 0) /
                                                100) *
                                                Number(
                                                    voucher.discount_percentages
                                                ) >
                                            voucher.max_discount
                                                ? voucher.max_discount -
                                                  voucher.max_discount * 2
                                                : ((shipperUsed.finalRate ||
                                                      0) /
                                                      100) *
                                                      Number(
                                                          voucher.discount_percentages
                                                      ) -
                                                  ((shipperUsed.finalRate ||
                                                      0) /
                                                      100) *
                                                      Number(
                                                          voucher.discount_percentages
                                                      ) *
                                                      2)
                                    )}
                                </div>
                            ) : (
                                <div className="price-total">
                                    {formatMoney(
                                        dataOrder?.total *
                                            productInfo?.product?.price +
                                            (((shipperUsed.finalRate || 0) /
                                                100) *
                                                Number(
                                                    voucher.discount_percentages
                                                ) >
                                            voucher.max_discount
                                                ? voucher.max_discount -
                                                  voucher.max_discount * 2
                                                : ((shipperUsed.finalRate ||
                                                      0) /
                                                      100) *
                                                      Number(
                                                          voucher.discount_percentages
                                                      ) -
                                                  ((shipperUsed.finalRate ||
                                                      0) /
                                                      100) *
                                                      Number(
                                                          voucher.discount_percentages
                                                      ) *
                                                      2)
                                    )}
                                </div>
                            )
                        ) : paymentChannel ? (
                            paymentChannel.percentages !== 0 ? (
                                <div className="price-total">
                                    {priceInsurance
                                        ? formatMoney(
                                              dataOrder?.total *
                                                  productInfo?.product?.price +
                                                  (shipperUsed.finalRate || 0) +
                                                  ((dataOrder?.total *
                                                      productInfo?.product
                                                          ?.price) /
                                                      100) *
                                                      paymentChannel.percentages +
                                                  priceInsurance.insuranceRate
                                          )
                                        : formatMoney(
                                              dataOrder?.total *
                                                  productInfo?.product?.price +
                                                  (shipperUsed.finalRate || 0) +
                                                  ((dataOrder?.total *
                                                      productInfo?.product
                                                          ?.price) /
                                                      100) *
                                                      paymentChannel.percentages
                                          )}
                                </div>
                            ) : priceInsurance ? (
                                <div className="price-total">
                                    {formatMoney(
                                        dataOrder?.total *
                                            productInfo?.product?.price +
                                            (shipperUsed.finalRate || 0) +
                                            paymentChannel.fee +
                                            priceInsurance.insuranceRate
                                    )}
                                </div>
                            ) : (
                                <div className="price-total">
                                    {formatMoney(
                                        dataOrder?.total *
                                            productInfo?.product?.price +
                                            (shipperUsed.finalRate || 0) +
                                            paymentChannel.fee
                                    )}
                                </div>
                            )
                        ) : shipperUsed ? (
                            <div className="price-total">
                                {formatMoney(
                                    dataOrder?.total *
                                        productInfo?.product?.price +
                                        (shipperUsed.finalRate || 0)
                                )}
                            </div>
                        ) : (
                            <div className="price-total">
                                {formatMoney(
                                    dataOrder?.total *
                                        productInfo?.product?.price
                                )}
                            </div>
                        )}
                    </div>
                    {(shipperList &&
                        productInfo.product.category.name !==
                            "Voucher Digital") ||
                    digital ? (
                        <Button
                            style={{ paddingLeft: 30, paddingRight: 30 }}
                            color="primary"
                            type="button"
                            disabled={paymentChannel ? false : true}
                            onClick={handleOrderPayment}
                        >
                            Bayar
                        </Button>
                    ) : (
                        <Button color="primary" onClick={onCheckValue}>
                            Lanjut
                        </Button>
                    )}
                </div>
            </Container>
        </Style>
    );
};
export default ButtonNavigation;
