import React from "react";
import { Card, Col, Row } from "reactstrap";
import Button from "../../../Components/Particle/Button";
import Rating from "../../../Components/Particle/Rating";
import { formatMoney } from "../../../Utils/PriceConverter";

const CardProduct = ({
    item,
    checkProduct = () => {},
    handleAddProduct = () => {},
    handleDetailProduct = () => {},
}) => {
    class TravellerCollection extends Array {
        sum(key) {
            return this.reduce((a, b) => a + (b[key] || 0), 0);
        }
    }
    const traveler = new TravellerCollection(...item.spec);
    const handleViewProduct = (item) => {
        if (window.screen.width < 576) {
            handleDetailProduct(item);
        }
    };

    return (
        <div
            onClick={() => handleViewProduct(item)}
            className="position-relative "
            style={{ marginBottom: 30 }}
        >
            {item.total_stock_product === 0 && (
                <div className="isClose">
                    <div>Stok barang sedang kosong</div>
                </div>
            )}
            <Card className="card-product">
                <Row>
                    <Col xl="5" md="5" sm="5" xs="12">
                        <img
                            src={item?.product_image[0]?.img_url}
                            alt=""
                            className="img-fluid img-product"
                        />
                    </Col>
                    {item.pre_order && <div className="pre-order">PO</div>}
                    <Col xl="7" md="7" sm="7" xs="12">
                        <div className="product-detail">
                            <h6 className="mt-2 title">{item.name}</h6>
                            <p className="font-weight-bold mb-0">
                                {formatMoney(item.price)}
                            </p>
                            <div style={{ fontSize: 12, color: "#28a745" }}>
                                Komisi yang di dapat:{" "}
                                <b>{formatMoney(item.commission_product)}</b>
                            </div>
                            <Rating rating={item.rating} />

                            <div
                                className={
                                    traveler.sum("total_stock") > 9
                                        ? "mb-4"
                                        : "mb-0"
                                }
                            >
                                {item.supplier.city && (
                                    <p className=" mb-0 mr-3 product-city">
                                        {item.supplier.city}
                                    </p>
                                )}
                                <div>
                                    {traveler.sum("total_stock") === 0 ? (
                                        <i
                                            style={{ fontSize: 14 }}
                                            className="text-danger"
                                        >
                                            Stok kosong
                                        </i>
                                    ) : (
                                        traveler.sum("total_stock") < 10 && (
                                            <i
                                                style={{ fontSize: 12 }}
                                                className="text-danger"
                                            >
                                                Stok hampir habis
                                            </i>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                        {window.screen.width > 575 && (
                            <div className="desktop-action">
                                <Row className="align-items-center">
                                    <Col xl="8" lg="8" md="8" sm="8" xs="8">
                                        {!checkProduct(item.id) ? (
                                            <Button
                                                color="primary"
                                                block
                                                onClick={() =>
                                                    handleAddProduct(item)
                                                }
                                            >
                                                Tambah katalog
                                            </Button>
                                        ) : (
                                            <Button
                                                color="outline-primary"
                                                block
                                                disabled={true}
                                            >
                                                Sudah tersedia
                                            </Button>
                                        )}
                                    </Col>
                                    <Col
                                        xl="4"
                                        lg="4"
                                        md="4"
                                        sm="4"
                                        xs="4"
                                        style={{ paddingLeft: 0 }}
                                        onClick={() =>
                                            handleDetailProduct(item)
                                        }
                                    >
                                        <Button color="outline-primary">
                                            Lihat
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Col>
                </Row>
            </Card>
        </div>
    );
};
export default CardProduct;
