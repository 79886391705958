import React from "react";
import { Col } from "reactstrap";
import Slider from "react-slick";
import { useStore } from "../../../Reducers";
import service from "./index.service";
import Testimonial from "../../../Components/Testimonial";
import { Link } from "react-router-dom";
import Style from "./index.style";
import SectionProduct from "./SectionProduct";
import SectionRatingProduct from "./SectionRatingProduct";
import SectionFAQ from "./SectionFAQ";
import SectionBottom from "./SectionBottom";
import { useHistory } from "react-router";
import SectionBenefit from "./SectionBenefit";
import ImageInfo from "../../../Components/Assets/Images/Final-Design-banner-1.png";
import ImageInfoMobile from "../../../Components/Assets/icon/mobile/Lakugan_banner_mobile.png";
import ReactGA from "react-ga";

const useAnalyticsEventTracker = (category="Blog category") => {
  const eventTracker = (action = "test action", label = "test label") => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}




const Home = () => {
  const [maintenance, setMaintenance] = React.useState(false);
  const { dispatch, state } = useStore();
  const history = useHistory();
  const [commission, setCommission] = React.useState({});
  const [banner, setBanner] = React.useState(false);
  const [isFirstGet, setFirstGet] = React.useState(true);
  const [newProduct, setNewProduct] = React.useState([]);
  const [ratingProduct, setRatingProduct] = React.useState([]);
  const gaEventTracker = useAnalyticsEventTracker('Contact us');

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <button
        style={{ ...style, border: "unset" }}
        onClick={onClick}
        className={className}
        type="button"
        // className="prev"
      >
        <i className="fas fa-chevron-left"></i>
      </button>
    );
  };
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <button
        style={{ ...style, border: "unset" }}
        onClick={onClick}
        className={className}
        type="button"
        // className="next"
      >
        <i className="fas fa-chevron-right"></i>
      </button>
    );
  };
  const settingsBanner = {
    dots: true,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    speed: 1000,
    // autoplay: true,
  };
  const handleGetProduct = (item) => {
    history.push(`/product/${item.code}`);
    window.scroll(0, 0);
    // service.getProduct({ dispatch, slug: item.id });
  };
  React.useEffect(() => {
    if (maintenance == true) {
        window.location = "/503";
    }
  })
  React.useEffect(() => {
    if (isFirstGet) {
      service.newProduct({ dispatch });
      service.commissionList({ dispatch });
      service.ratingProduct({ dispatch });
      service.getBanner({ dispatch });
      setFirstGet(false);
    }
  }, [isFirstGet, dispatch]);
  React.useEffect(() => {
    if (state?.user?.banner?.isSuccess) {
      setBanner(state.user.banner.data.content);
    }
    if (state?.commission?.commissionList?.isSuccess) {
      const customCommission =
        state?.commission?.commissionList.data.content.filter(
          (item) => item.id === 1
        );
      setCommission(customCommission[0]);
      service.commissionListClear({ dispatch });
    }
    if (state?.user?.newProduct?.isSuccess) {
      setNewProduct(state.user.newProduct.data.content);
    }
    if (state?.user?.ratingProduct?.isSuccess) {
      setRatingProduct(state.user.ratingProduct.data.content);
    }
    if (state?.user?.product?.isSuccess) {
      history.push(`/product/${state.user.product.data.product_code}`);
      service.getProductClear({ dispatch });
    }
  }, [state, history, dispatch]);
  React.useEffect(() => {
    gaEventTracker("call")
  }, [gaEventTracker])
  return (
    <Style>
      <div className="container">
        {banner && (
          <div className="banner">
            <Slider {...settingsBanner}>
              {banner.map((item, key) => {
                return (
                  <div key={key}>
                    <Col className="banner-slider">
                      <Link to={item.banner_url === "" ? "/" : item.banner_url}>
                        <img
                          src={item.image.img_url}
                          alt=""
                          className="img-fluid image-banner"
                        />
                      </Link>
                    </Col>
                  </div>
                );
              })}
            </Slider>
          </div>
        )}
        <section className="section" style={{ paddingBottom: 0 }}>
          <SectionProduct
            onGetValue={handleGetProduct}
            title={"Produk Terbaru"}
            data={newProduct}
          />
        </section>
        <section className="section" style={{ paddingBottom: 0 }}>
          <SectionRatingProduct
            onGetValue={handleGetProduct}
            title={"Produk Terlaris"}
            data={ratingProduct}
            dataShow={8}
          />
        </section>
        <section className="section-info">
          <div className="text-center">
            <img
              src={window.screen.width > 550 ? ImageInfo : ImageInfoMobile}
              alt="3 Langkah Lakugan"
              className="img-fluid"
            />
          </div>
        </section>
        <section className="section">
          <SectionBenefit commission={commission} />
        </section>
        <section style={{ marginBottom: 50 }}>
          <Testimonial />
        </section>
        <section>
          <SectionFAQ />
        </section>
        <section>
          <SectionBottom />
        </section>
      </div>
    </Style>
  );
};
export default Home;
