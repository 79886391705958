import { servicesAction } from "../../../Reducers";

const service = {
    about: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/about_us",
            params: params,
            qs: true,
            method: "GET",
            reducer: "service",
            group: "user",
            key: "about",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Bad Request",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
};
export default service;
