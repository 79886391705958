import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import EditProduct from "./EditProduct";

const Detail = ({
    setShow = () => {},
    toggle = () => {},
    isShow = false,
    setProductItem = () => {},
    dataProduct,
    setAlert = () => {},
    updateProduct = () => {},
    setLoading = () => {},
    loading = false,
    stockVoucher,
    setStockVoucher = () => {},
}) => {
    return (
        <Modal size="xl" toggle={toggle} isOpen={isShow}>
            <ModalHeader className="border-0 pb-0" toggle={toggle} />
            <ModalBody>
                <EditProduct
                    stockVoucher={stockVoucher}
                    setStockVoucher={setStockVoucher}
                    setProductItem={setProductItem}
                    setLoading={setLoading}
                    loading={loading}
                    updateProduct={updateProduct}
                    isShow={isShow}
                    setShow={setShow}
                    setAlert={setAlert}
                    dataProduct={dataProduct}
                />
            </ModalBody>
        </Modal>
    );
};
export default Detail;
