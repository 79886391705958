import React from "react";
import { Col, Row } from "reactstrap";
import Form from "../../../../Components/Molekul/FormValidation";

const PaymentForm = ({
    setForm = () => {},
    form = [],
    validationClick = true,
    validateForm = true,
    onCheckValue = () => {},
}) => {
    return (
        <Row>
            <Col xl="12">
                <Form
                    onCheckValue={onCheckValue}
                    setForm={setForm}
                    validateForm={validateForm}
                    validationClick={validationClick}
                    form={form}
                />
            </Col>
        </Row>
    );
};
export default PaymentForm;
