/* eslint-disable */

import React from "react";
import Slider from "react-slick";
import SkeletonLoading from "../../../../Components/Loading/Skeleton";
import styled from "styled-components";
import Default from "../../../../Components/Assets/Product/defaultImage.jpg";
import CheckMobile from "../../../../Utils/CheckMobile";
import { color } from "../../../../Utils/Variable";

const Style = styled.div`
  .slick-dots {
    bottom: -90px;
    li {
      display: none;
      width: 135px;
      height: 70px;
      @media (min-width:768px) and (max-width:820px) {
        width: 80px;
      }
      transition: all 0.25s ease;
      &.slick-active {
        transition: all 0.25s ease;
        outline: -webkit-focus-ring-color auto 1px;
      }
    }
    li:nth-child(${(props) => props.active}) {
      display: inline-block;
    }
    li:nth-child(${(props) => props.prevActive}) {
      display: inline-block;
    }
    li:nth-child(${(props) => props.nextActive}) {
      display: inline-block;
    }
  }

  .slick-prev,
  .slick-next {
    &:before {
      font-size: 30px;
    }
  }
  .slick-prev {
    left: 25px;
    z-index: 9;
  }
  .slick-next {
    right: 35px;
  }
  .img-product {
    width: 100%;
    height: 528px;
    @media (min-width: 768px) and (max-width: 820px) {
      height: 374px;
    }
  }
  .img-list{
    heightL 140px;
    @media (min-width: 768px) and (max-width: 820px) {
      height: 80px !important;
    }
  }
`;

const OnlyMobile = styled.div`
  .slick-prev,
  .slick-next {
    top: calc(100% - 50%);
    color: #000000;
    width: max-content;
    height: max-content;
    box-shadow: -4px 4px 16px rgba(0, 0, 0, 0.08);
    background: #fff;
    border-radius: 50%;
    font-size: 14px;
    padding: 6px 8px;
    &::before {
      display: none;
    }
  }
  .slick-prev {
    z-index: 3;
    left: 5px;
  }
  .slick-next {
    right: 5px;
  }
  .fa-chevron-left,
  .fa-chevron-right {
    color: ${color.primary};
  }
  .slick-dots {
    display: block;
    position: absolute;
    width: max-content;
    bottom: 20px;
    li {
      width: 5px;
      height: 5px;
    }
  }
  .slick-dots li button:before {
    font-size: 10px;
  }
  .slick-dots li.slick-active button:before {
    color: #fff;
  }
  &-slider,
  .container {
    padding: 0;
  }
  .img-product {
    width: 100%;
    height: max(375px);
  }
`;
const SliderProduct = ({ data = false }) => {
  const [limitData, setLimitData] = React.useState({
    active: 1,
    prevActive: 2,
    nextActive: 3,
  });
  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img
            onClick={() => eventSliderActive(i)}
            src={`${data.product.product_image[i].img_url}`}
            className="img-fluid img-list"
            alt="Lakugan"
          />
        </a>
      );
    },
    arrows: false,
    dots: true,
    dotsClass: `slick-dots slick-thumb`,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const settingsMobile = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const eventSliderActive = (i) => {
    let dotsActive = i + 1;
    let prevActive =
      dotsActive === 1
        ? dotsActive + 1
        : dotsActive === data.product.product_image.length
        ? dotsActive - 1
        : dotsActive - 1;
    let active = dotsActive;
    let nextActive =
      dotsActive === 1
        ? dotsActive + 2
        : dotsActive === data.product.product_image.length
        ? dotsActive - 2
        : dotsActive + 1;
    setLimitData({
      active: active,
      prevActive: prevActive,
      nextActive: nextActive,
    });
  };
  return CheckMobile() === true ? (
    <OnlyMobile>
      {data ? (
        data.product.product_image.length > 0 ? (
          <Slider {...settingsMobile}>
            {data.product.product_image.map((item, key) => {
              return (
                <div key={key}>
                  <img
                    alt={item.name}
                    src={item.img_url}
                    className="img-product"
                  />
                </div>
              );
            })}
          </Slider>
        ) : (
          <img
            src={Default}
            alt="default"
            className="img-fluid mx-auto img-product"
          />
        )
      ) : (
        <SkeletonLoading width="100%" height="400px" />
      )}
    </OnlyMobile>
  ) : (
    <Style
      active={limitData.active}
      prevActive={limitData.prevActive}
      nextActive={limitData.nextActive}
    >
      {data ? (
        data.product.product_image.length > 0 ? (
          <Slider {...settings}>
            {data.product.product_image.map((item, key) => {
              return (
                <div key={key}>
                  <img
                    alt={item.name}
                    src={item.img_url}
                    className="img-fluid mx-auto img-product"
                  />
                </div>
              );
            })}
          </Slider>
        ) : (
          <img
            src={Default}
            alt="default"
            className="img-fluid mx-auto img-product"
          />
        )
      ) : (
        <SkeletonLoading width="100%" height="400px" />
      )}
    </Style>
  );
};
export default SliderProduct;
