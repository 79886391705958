import { servicesAction } from "../../../Reducers";

const service = {
    forgotPassword: ({ dispatch, email }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/forgot-password/${email}`,
            method: "POST",
            qs: true,
            reducer: "service",
            group: "user",
            key: "forgotPassword",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Silakn cek E-mail kamu, untuk mendapatkan kode OTP",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
    changePassword: ({ dispatch, otp, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/forgot-password/${otp}/new`,
            method: "POST",
            data: data,
            reducer: "service",
            group: "user",
            key: "changePassword",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Kata sandi anda berhasil dirubah",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
    changePasswordClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "changePassword",
            type: "CLEAR",
        });
    },
    forgotPasswordClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "user",
            key: "forgotPassword",
            type: "CLEAR",
        });
    },
};
export default service;
