import React from "react";
import { Modal, ModalBody } from "reactstrap";

const MessageState = ({ isShow = false, message = "", status = true }) => {
    return (
        <Modal isOpen={isShow}>
            <ModalBody>
                <div className="text-center">
                    <i
                        className={`fas ${
                            status
                                ? "fa-check-circle text-success "
                                : "fa-times-circle text-danger "
                        } mb-4`}
                        style={{ fontSize: 40 }}
                    ></i>
                    <div>{message}</div>
                </div>
            </ModalBody>
        </Modal>
    );
};
export default MessageState;
