import React from "react";
import Style from "./index.style";
import ArrowLeft from "../Assets/icon/mobile/arrow-left.png";
import { Input } from "reactstrap";
import SearchIcon from "../Assets/icon/search-icon.svg";
import Button from "../Particle/Button";
const CategoryList = ({
    item,
    categoryFilters = [],
    onSortFilter = [],
    setSortFilter = () => {},
    setCategoryFilters = () => {},
}) => {
    const [isActiveCategory, setActiveCategory] = React.useState(
        categoryFilters.filter((value) => value === item.name).length === 1
            ? item.id
            : 0
    );
    const handleFilterCategory = (item) => {
        if (isActiveCategory === 0) {
            setCategoryFilters((prevstate) => {
                return categoryFilters.length < 1
                    ? [...prevstate, item.name]
                    : [...prevstate, item.name];
            });
            setSortFilter((prevstate) => {
                return onSortFilter.length < 1
                    ? [...prevstate, `["category.id","=","${item.id}"]`]
                    : [...prevstate, `["or"],["category.id","=","${item.id}"]`];
            });
            setActiveCategory(item.id);
        } else {
            const removeCategory = categoryFilters.filter(function (value) {
                let withOr = value !== item.name;
                let notWithOr = value !== item.name;
                return notWithOr ? withOr : notWithOr;
            });
            const removeFilter = onSortFilter.filter(function (value) {
                let withOr = value !== `["category.id","=","${item.id}"]`;
                let notWithOr =
                    value !== `["or"],["category.id","=","${item.id}"]`;
                return notWithOr ? withOr : notWithOr;
            });
            setCategoryFilters(removeCategory);
            setSortFilter(removeFilter);
            setActiveCategory(0);
        }
    };
    return (
        <div
            onClick={() => handleFilterCategory(item)}
            className={`category-list`}
        >
            <span>{item.name}</span>{" "}
            {categoryFilters.filter((value) => value === item.name).length ===
                1 && <i className="fas fa-check mr-2"></i>}
        </div>
    );
};
const FilterCategory = ({
    categoryList = [],
    activeFilter = false,
    showAllCategoryFilter = () => {},
    categoryFilters = [],
    onSortFilter = [],
    setSortFilter = () => {},
    setCategoryFilters = () => {},
}) => {
    return (
        <Style active={activeFilter}>
            <div className="filter">
                <div className="filter-header justify-content-start">
                    <img
                        src={ArrowLeft}
                        alt="arrow"
                        className="img-fluid mr-2"
                        onClick={showAllCategoryFilter}
                    />
                    <h6 className="title">Kategori</h6>
                </div>
                <div className="filter-body">
                    <div className="search-category">
                        <Input placeholder="Fashion Pria, Makanan,..." />
                        <img src={SearchIcon} alt="" className="img-fluid" />
                    </div>
                    <div className="category">
                        {categoryList.map((item, key) => {
                            return (
                                <CategoryList
                                    setCategoryFilters={setCategoryFilters}
                                    onSortFilter={onSortFilter}
                                    setSortFilter={setSortFilter}
                                    categoryFilters={categoryFilters}
                                    item={item}
                                    key={key}
                                />
                            );
                        })}
                    </div>
                    <div className="text-center p-3">
                        <Button color="primary" onClick={showAllCategoryFilter}>
                            Simpan Kategori
                        </Button>
                    </div>
                </div>
            </div>
        </Style>
    );
};
export default FilterCategory;
