import { servicesAction } from "../../../Reducers";

const service = {
    getTransactionAddress: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/transaction_address/list`,
            method: "GET",
            token: true,
            reducer: "service",
            group: "supplier",
            key: "transaction_address",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "",
                },
            },
        });
    },
    TransactionAddressClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "supplier",
            key: "transaction_address",
            type: "CLEAR",
        });
    },
    category: ({ dispatch, params }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/category/?filters=[["type","=","0"]]`,
            method: "GET",
            params: params,
            qs: true,
            token: true,
            reducer: "service",
            group: "category",
            key: "category",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Password atau email salah",
                },
            },
        });
    },
    createProduct: ({ dispatch, data }) => {
        servicesAction(dispatch).reduxFetch({
            url: "/product",
            method: "POST",
            token: true,
            data: data,
            reducer: "service",
            group: "product",
            key: "createProduct",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Success Get Ingredients",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Unauthorized (401)",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Bad request error (400)",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal service error (500)",
                },
            },
        });
    },
    createProductClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "product",
            key: "createProduct",
            type: "CLEAR",
        });
    },
};
export default service;
