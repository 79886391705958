import React from "react";
import tableType from "./index.type";
import { Fragment } from "react";
import Logo from "../../Assets/icon/Lakugan_Logo_Square-2.png";
import Skeleton from "../../Loading/Skeleton";
import styled from "styled-components";
const CheckedStyle = styled.div`
    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
        background-image: url(${Logo});
        background-repeat: no-repeat;
        background-size: contain;
    }
`;
const TableBody = ({
    onGetValueChecked = () => {},
    isLoading = false,
    haveChecked = false,
    configTable = false,
    isNumber = 1,
    no_data = "Data tidak ada",
    optionTable = [],
    dataTable = [],
    dummyData = [],
    edit = false,
}) => {
    const onGetValue = (e, data) => {
        onGetValueChecked(e, data);
    };
    return (
        <tbody>
            {!isLoading ? (
                edit ? (
                    dummyData.length > 0 ? (
                        dummyData.map((item, key) => {
                            let number = key;
                            return (
                                <tr key={key}>
                                    {optionTable.map((itemOptionTable, key) => {
                                        return (
                                            <td
                                                key={key}
                                                className={
                                                    itemOptionTable?.className
                                                }
                                                style={{
                                                    ...itemOptionTable?.style,
                                                    verticalAlign: "middle",
                                                }}
                                            >
                                                {itemOptionTable &&
                                                !itemOptionTable.renderItem
                                                    ? tableType({
                                                          length: optionTable[
                                                              key
                                                          ],
                                                          number: number + 1,
                                                          isNumber: isNumber,
                                                          value: item[
                                                              itemOptionTable
                                                                  ?.fieldName
                                                          ],
                                                          targetField:
                                                              item[
                                                                  itemOptionTable
                                                                      ?.targetField
                                                              ],
                                                          img: item[
                                                              itemOptionTable
                                                                  ?.img
                                                          ],
                                                          percent:
                                                              item[
                                                                  itemOptionTable
                                                                      ?.percent
                                                              ],
                                                          itemOptionTable,
                                                          item,
                                                      })[itemOptionTable?.type](
                                                          item
                                                      )
                                                    : itemOptionTable.renderItem(
                                                          item,
                                                          number
                                                      )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td>NO DATA</td>
                        </tr>
                    )
                ) : dataTable.length > 0 ? (
                    dataTable.map((item, key) => {
                        let number = key;
                        return (
                            <tr key={key}>
                                {haveChecked && dataTable.length > 0 && (
                                    <td>
                                        <CheckedStyle>
                                            <label className="container">
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) =>
                                                        onGetValue(e, item)
                                                    }
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </CheckedStyle>
                                    </td>
                                )}
                                {optionTable.map((itemOptionTable, key) => {
                                    return (
                                        <td
                                            key={key}
                                            className={
                                                itemOptionTable?.className
                                            }
                                            style={{
                                                ...itemOptionTable?.style,
                                                verticalAlign: "middle",
                                            }}
                                        >
                                            {itemOptionTable &&
                                            !itemOptionTable.renderItem
                                                ? tableType({
                                                      configTable: configTable,
                                                      length: optionTable[key],
                                                      number: number + 1,
                                                      isNumber: isNumber,
                                                      value: item[
                                                          itemOptionTable
                                                              ?.fieldName
                                                      ],
                                                      targetField:
                                                          item[
                                                              itemOptionTable
                                                                  ?.targetField
                                                          ],
                                                      img: item[
                                                          itemOptionTable?.img
                                                      ],
                                                      percent:
                                                          item[
                                                              itemOptionTable
                                                                  ?.percent
                                                          ],
                                                      itemOptionTable,
                                                      item,
                                                  })[itemOptionTable?.type](
                                                      item
                                                  )
                                                : itemOptionTable.renderItem(
                                                      item
                                                  )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={`${optionTable.length}`}>{no_data}</td>
                    </tr>
                )
            ) : (
                <Fragment>
                    <tr>
                        {optionTable.map((itemOptionTable, key) => {
                            return (
                                <td key={key}>
                                    <Skeleton></Skeleton>
                                </td>
                            );
                        })}
                    </tr>
                    <tr>
                        {optionTable.map((itemOptionTable, key) => {
                            return (
                                <td key={key}>
                                    <Skeleton></Skeleton>
                                </td>
                            );
                        })}
                    </tr>
                    <tr>
                        {optionTable.map((itemOptionTable, key) => {
                            return (
                                <td key={key}>
                                    <Skeleton></Skeleton>
                                </td>
                            );
                        })}
                    </tr>
                </Fragment>
            )}
        </tbody>
    );
};

export default TableBody;
