import { servicesAction } from "../../../Reducers";
const service = {
    transactionList: ({ dispatch, params, id }) => {
        servicesAction(dispatch).reduxFetch({
            url: `/transaction/client/${id}`,
            params: params,
            qs: true,
            method: "GET",
            token: true,
            reducer: "service",
            group: "supplier",
            key: "transactionList",
            message: {
                200: {
                    mode: {
                        use: "alert",
                        type: "success",
                        timeOut: 3000,
                    },
                    text: "Daftar transaksi",
                },
                401: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "",
                },
                400: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Terjadi kesalahan, harap mencoba beberapa saat lagi",
                },
                500: {
                    mode: {
                        use: "alert",
                        type: "danger",
                        timeOut: 3000,
                    },
                    text: "Internal Service Error",
                },
            },
        });
    },
    transactionListClear: ({ dispatch }) => {
        servicesAction(dispatch).reduxClear({
            reducer: "service",
            group: "reseller",
            key: "transactionList",
            type: "CLEAR",
        });
    },
};
export default service;
