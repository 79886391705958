import React from "react";
import { FormGroup } from "reactstrap";
import CommonTable from "../../../../Components/Molekul/CommonTable";
import Button from "../../../../Components/Particle/Button";
import FormPrice from "../../../../Components/Particle/Form/FormPrice";
import FormText from "../../../../Components/Particle/Form/FormText";

const TableVolume = ({
    specification = [],
    validTable = [],
    setSpecification = () => {},
    onGetValue = () => {},
    handleDeleteItem = () => {},
}) => {
    return (
        <CommonTable
            edit={true}
            dummyData={specification}
            setDataDummy={setSpecification}
            configuration={{
                searchInput: false,
                nav: {
                    hasSearch: false,
                },
                service: false,
                rowHead: [
                    {
                        headName: "",
                        colSpan: 2,
                    },
                    {
                        headName: "Ukuran Paket",
                        colSpan: 4,
                    },
                    {
                        headName: "",
                        colSpan: 1,
                    },
                    {
                        headName: "",
                        colSpan: 1,
                    },
                ],
                optionTable: [
                    {
                        headName: "Warna",
                        rowSpan: 2,
                        fieldName: "color",
                        type: "isCostume",
                        renderItem: (item) => {
                            return (
                                <div
                                    className="position-relative"
                                    style={{
                                        minWidth: 70,
                                    }}
                                >
                                    <FormText
                                        className="form-spec"
                                        id={item.id}
                                        onGetValue={onGetValue}
                                        item={{
                                            value: item.color,
                                            name: "color",
                                        }}
                                    />
                                </div>
                            );
                        },
                    },
                    {
                        headName: "Ukuran",
                        rowSpan: 2,
                        fieldName: "size",
                        type: "isCostume",
                        renderItem: (item) => {
                            return (
                                <div
                                    style={{
                                        minWidth: 70,
                                    }}
                                >
                                    <FormText
                                        className="form-spec"
                                        id={item.id}
                                        item={{
                                            value: item.size,
                                            name: "size",
                                            placeholder: "XL, 40",
                                            character: {
                                                min: {
                                                    length: "0",
                                                },
                                                max: {
                                                    length: "2",
                                                },
                                            },
                                        }}
                                        onGetValue={onGetValue}
                                    />
                                </div>
                            );
                        },
                    },
                    {
                        headName: "Lebar",
                        rowSpan: 1,
                        fieldName: "width",
                        required: true,
                        type: "isCostume",
                        renderItem: (item) => {
                            return (
                                <React.Fragment>
                                    <FormPrice
                                        className="form-spec"
                                        id={item.id}
                                        item={{
                                            defaultValue: item.width,
                                            suffix: " cm",
                                            precision: 1,
                                            name: "width",
                                        }}
                                        onGetValue={onGetValue}
                                    />
                                </React.Fragment>
                            );
                        },
                    },
                    {
                        headName: "Panjang",
                        fieldName: "length",
                        rowSpan: 1,
                        required: true,
                        type: "isCostume",
                        renderItem: (item) => {
                            return (
                                <React.Fragment>
                                    <FormPrice
                                        className="form-spec"
                                        id={item.id}
                                        item={{
                                            defaultValue: item.length,
                                            suffix: " cm",
                                            precision: 1,
                                            name: "length",
                                        }}
                                        onGetValue={onGetValue}
                                    />
                                </React.Fragment>
                            );
                        },
                    },
                    {
                        headName: "Tinggi",
                        rowSpan: 1,
                        fieldName: "height",
                        required: true,
                        type: "isCostume",
                        renderItem: (item) => {
                            return (
                                <React.Fragment>
                                    <FormPrice
                                        className="form-spec"
                                        id={item.id}
                                        item={{
                                            defaultValue: item.height,
                                            suffix: " cm",
                                            precision: 1,
                                            name: "height",
                                        }}
                                        onGetValue={onGetValue}
                                    />
                                </React.Fragment>
                            );
                        },
                    },
                    {
                        headName: "Berat",
                        fieldName: "weight",
                        rowSpan: 1,
                        required: true,
                        type: "isCostume",
                        renderItem: (item) => {
                            return (
                                <React.Fragment>
                                    <FormPrice
                                        className="form-spec"
                                        onGetValue={onGetValue}
                                        id={item.id}
                                        item={{
                                            defaultValue: item.weight,
                                            suffix: " kg",
                                            precision: 1,
                                            name: "weight",
                                        }}
                                    />
                                </React.Fragment>
                            );
                        },
                    },
                    {
                        headName: "Stok",
                        rowSpan: 2,
                        required: true,
                        fieldName: "total_stock",
                        type: "isCostume",
                        renderItem: (item) => {
                            return (
                                <React.Fragment>
                                    <FormText
                                        className="form-spec"
                                        id={item.id}
                                        onGetValue={onGetValue}
                                        item={{
                                            name: "total_stock",
                                            value: item.total_stock,
                                            type: "number",
                                        }}
                                    />
                                </React.Fragment>
                            );
                        },
                    },
                    {
                        headName: "",
                        type: "isCostume",
                        renderItem: (item, key) => {
                            return (
                                <React.Fragment key={key}>
                                    {key > 0 && (
                                        <FormGroup>
                                            <Button
                                                color="transparent"
                                                size="sm"
                                                type="button"
                                                onClick={() =>
                                                    handleDeleteItem(
                                                        item,
                                                        validTable[item.id - 1]
                                                    )
                                                }
                                            >
                                                <i className="fas fa-trash-alt text-secondary"></i>
                                            </Button>
                                        </FormGroup>
                                    )}
                                </React.Fragment>
                            );
                        },
                    },
                ],
            }}
        ></CommonTable>
    );
};
export default TableVolume;
