import React from "react";
import { Col, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";
import styled from "styled-components";
import { formatMoney } from "../../../../Utils/PriceConverter";
import Button from "../../../../Components/Particle/Button";
import { color } from "../../../../Utils/Variable";
import Logo from "../../../../Components/Assets/Images/Lakugan_LogoColor_BrightBG.png";
import SkeletonLoading from "../../../../Components/Loading/Skeleton";
import { useStore } from "../../../../Reducers";
import service from "../index.service";

const Style = styled(Modal)`
    color: #4a4a4a;
    .title {
        color: #4a4a4a;
        width: 164px;
        font-size: 14px;
        font-weight: 500;
    }
    .desc {
        padding-bottom: 10px;
        margin-bottom: 30px;
        border-bottom: 1px dashed #4a4a4a;
        span {
            display: block;
            color: #888888;
            font-size: 14px;
        }
        &-transaction {
            font-size: 14px;
            margin-bottom: 10px;
            &__detail {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
            }
            &__total {
                color: ${color.primary};
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: bold;
                margin-bottom: 20px;
            }
        }

        &-value {
            color: ${color.primary};
            font-size: 14px;
            font-weight: 500;
            width: calc(100% - 164px);
            overflow: auto;
        }
        th,
        td {
            font-size: 14px;
        }
    }
    .order-picture {
        margin: auto;
        img {
            width: 200px;
            height: 200px;
        }
        h6 {
            font-size: 18px;
            color: #4a4a4a;
            font-weight: 500;
            margin-top: 30px;
        }
    }
    .order-title {
        font-size: 16px;
        margin-right: 10px;
    }
    .tracking-live {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
    }
    .btn-product__info {
        display: flex;
        align-items: center;
        text-align: center;
        margin-right: 10px;
        justify-content: center;
        border: 2px solid #77e879;
        background: #77e879;
        color: #fff;
        width: max-content;
        font-weight: bold;
        font-size: 14px;
        .fa-whatsapp {
            font-size: 20px;
            font-weight: bold;
        }
        @media (max-width: 767px) {
            margin-bottom: 15px;
        }
    }
`;

const DetailTracking = ({
    isShow = false,
    toggle = () => {},
    setOrderIdNumber = () => {},
    orderIdNumber = "",
    data = false,
    getTracking = false,
    handleShowTracking = () => {},
}) => {
    const [transactionDate, setTransactionDate] = React.useState("");
    const handleStatusPickup = () => {
        toggle();
    };
    const { dispatch, state } = useStore();
    React.useEffect(() => {
        if (data) {
            let getTime = data.transaction_code.split("/");
            let timestamp = getTime[1];
            let getFullDate = new Date(Number(timestamp));
            setTransactionDate(
                `${getFullDate.getFullYear()}-${String(
                    getFullDate.getMonth() + 1
                ).padStart(2, "0")}-${String(getFullDate.getDate()).padStart(
                    2,
                    "0"
                )}`
            );
        }
    }, [data]);
    React.useEffect(() => {
        if (state?.shipper?.orderId?.isSuccess) {
            setOrderIdNumber(state.shipper.orderId.data.data.id);
            service.orderDetailClear({ dispatch });
        }
    }, [state, dispatch, setOrderIdNumber]);
    return (
        <Style isOpen={isShow} toggle={handleStatusPickup} size="lg">
            <ModalHeader toggle={handleStatusPickup}>
                <img src={Logo} alt="Lakugan" className="img-fluid" />
            </ModalHeader>
            <ModalBody>
                {data ? (
                    <React.Fragment>
                        <div className="desc">
                            <div className="mb-4">
                                <Row className="justify-content-between">
                                    <Col xl="6" lg="6" md="6" sm="12">
                                        <h6 className="mb-2 border-bottom pb-2">
                                            Data Pembeli
                                        </h6>
                                        <div className="d-flex">
                                            <div className="title">Nama</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data.customer_detail.full_name}
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Email</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data.customer_detail.email}
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Telepon</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {
                                                    data.customer_detail
                                                        .phone_number
                                                }
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Alamat</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data.customer_detail.address}
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">
                                                Metode Pembayaran
                                            </div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data.payment_channel.name}
                                            </div>
                                        </div>
                                        {data.shipper && (
                                            <div className="d-flex">
                                                <div className="title">
                                                    Metode Pengiriman
                                                </div>
                                                <div className="mr-2">:</div>
                                                <div className="desc-value">
                                                    {data.shipper.rate_name}
                                                </div>
                                            </div>
                                        )}

                                        <div className="d-flex">
                                            <div className="title">
                                                Komisi saya
                                            </div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {formatMoney(
                                                    data?.product_commission_transaction
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="5" lg="5" md="5" sm="12">
                                        <h6 className="mb-2 border-bottom pb-2">
                                            Data Supplier
                                        </h6>
                                        <div className="d-flex">
                                            <div className="title">Nama</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {data?.product.supplier.name}
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Telepon</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                {
                                                    data?.product.supplier
                                                        .phone_cs
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="12">
                                        <div className="text-center">
                                            <div className="mb-4">
                                                <div className="order-picture">
                                                    <h6>Pemesanan</h6>
                                                    <img
                                                        src={
                                                            data.product
                                                                .product_image[0]
                                                                .img_url
                                                        }
                                                        alt=""
                                                        className="img-fluid mb-3"
                                                    />
                                                    <h4 className="text-center">
                                                        {data.product.name}
                                                    </h4>
                                                    <h4 className="text-center">
                                                        {formatMoney(
                                                            data.product_fee
                                                        )}
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <div className="order-title">
                                                    Jumlah
                                                </div>
                                                <div className="mr-2">:</div>
                                                <div
                                                    className="desc-value"
                                                    style={{ fontSize: 16 }}
                                                >
                                                    {data.total_amount} Barang
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <span>Spesifikasi Pemesanan :</span>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Warna</th>
                                        <th>Ukuran</th>
                                        <th>Jumlah Barang</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{data.product.spec[0].color}</td>
                                        <td>{data.product.spec[0].size}</td>
                                        <td>{data.total_amount}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div className="mb-4">
                                <div className="title mb-1">
                                    Catatan Pembeli:{" "}
                                </div>
                                <div style={{ fontSize: 14 }}>
                                    {data.customer_detail.address_direction}
                                </div>
                            </div>
                        </div>
                        {orderIdNumber !== "" && (
                            <div className="desc-transaction__detail">
                                <div>Order ID</div>
                                <div>{orderIdNumber}</div>
                            </div>
                        )}
                        <div className="desc-transaction">
                            <div className="desc-transaction__detail">
                                <div>Nomor Transaksi</div>
                                <div style={{ overflow: "auto" }}>
                                    {data.transaction_code}
                                </div>
                            </div>
                            <div className="desc-transaction__detail ">
                                <div>Tanggal Transaksi</div>
                                <div>{transactionDate}</div>
                            </div>
                        </div>

                        <div className="desc-transaction__total">
                            <div>Total Transaksi</div>
                            <div>{formatMoney(data?.total_cost)}</div>
                        </div>
                        <div className="mt-4">
                            <div className="text-right">
                                {getTracking?.data?.courier?.waybill_id ===
                                "" ? (
                                    <i
                                        style={{
                                            fontSize: 14,
                                            color: "#464646",
                                        }}
                                    >
                                        Menunggu pickup dari driver
                                    </i>
                                ) : (
                                    getTracking && (
                                        <div className="tracking-live">
                                            <a
                                                rel="noopener noreferrer"
                                                href={`https://api.whatsapp.com/send?phone=${data?.product?.supplier?.phone_cs.replace(
                                                    "0",
                                                    "62"
                                                )}&text=Hallo ka ${
                                                    data?.product?.supplier
                                                        ?.name
                                                }, ada pesanan ${
                                                    data?.total_amount
                                                } ${
                                                    data?.product?.name
                                                } dari lakugan, mohon di proses ya, terimakasih ?`}
                                                target="_blank"
                                                className="btn btn-product__info"
                                                type="button"
                                            >
                                                <i className="fab fa-whatsapp mr-2"></i>{" "}
                                                Hubungi Penjual
                                            </a>
                                            <Button
                                                color="primary"
                                                onClick={() =>
                                                    handleShowTracking(data)
                                                }
                                            >
                                                Live tracking
                                            </Button>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="desc">
                            <div className="mb-4">
                                <Row className="justify-content-between">
                                    <Col xl="6" lg="6" md="6" sm="12">
                                        <h6 className="mb-2 border-bottom pb-2">
                                            Data Pembeli
                                        </h6>
                                        <div className="d-flex">
                                            <div className="title">Nama</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Email</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Telepon</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Alamat</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">
                                                Metode Pembayaran
                                            </div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">
                                                Metode Pengiriman
                                            </div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="200px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="5" lg="5" md="5" sm="12">
                                        <h6 className="mb-2 border-bottom pb-2">
                                            Data Reseller
                                        </h6>
                                        <div className="d-flex">
                                            <div className="title">Nama</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="170px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="title">Telepon</div>
                                            <div className="mr-2">:</div>
                                            <div className="desc-value">
                                                <SkeletonLoading
                                                    width="170px"
                                                    height="25px"
                                                    style={{ marginBottom: 10 }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="12">
                                        <div className="text-center">
                                            <div className="mb-4">
                                                <div className="order-picture">
                                                    <h6>Pemesanan</h6>

                                                    <SkeletonLoading
                                                        width="200px"
                                                        height="200px"
                                                        style={{
                                                            margin: "10px auto",
                                                        }}
                                                    />
                                                    <h4 className="text-center">
                                                        <SkeletonLoading
                                                            width="200px"
                                                            height="25px"
                                                            style={{
                                                                margin: "10px auto",
                                                            }}
                                                        />
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <div className="order-title">
                                                    Jumlah
                                                </div>
                                                <div className="mr-2">:</div>
                                                <div
                                                    className="desc-value"
                                                    style={{ fontSize: 16 }}
                                                >
                                                    <SkeletonLoading
                                                        width="200px"
                                                        height="25px"
                                                        style={{
                                                            marginBottom: 10,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mb-4">
                                <div className="title mb-1">
                                    Catatan Pembeli:{" "}
                                </div>
                                <div style={{ fontSize: 14 }}>
                                    <SkeletonLoading
                                        width="200px"
                                        height="25px"
                                        style={{ marginBottom: 10 }}
                                    />
                                </div>
                            </div>
                            {/* <span>JNE REG</span> */}
                        </div>

                        <div className="desc-transaction">
                            <div className="desc-transaction__detail">
                                <div>Nomor Transaksi</div>
                                <div>
                                    <SkeletonLoading
                                        width="200px"
                                        height="25px"
                                        style={{ marginBottom: 10 }}
                                    />
                                </div>
                            </div>
                            <div className="desc-transaction__detail ">
                                <div>Tanggal Transaksi</div>
                                <div>
                                    <SkeletonLoading
                                        width="200px"
                                        height="25px"
                                        style={{ marginBottom: 10 }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="desc-transaction__total">
                            <div>Total Transaksi</div>
                            <div>
                                <SkeletonLoading
                                    width="100px"
                                    height="30px"
                                    style={{ marginBottom: 10 }}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </ModalBody>
        </Style>
    );
};
export default DetailTracking;
