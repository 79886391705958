import React from "react";
import { Collapse } from "reactstrap";
import styled from "styled-components";

const Style = styled.div`
    border-bottom: 1px solid #ddd;
    .btn-accordion {
        background: transparent;
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 10px 0;
        border: none;
        margin-bottom: 10px;
        font-weight: 500;
        text-align: left;
        @media (max-width: 499px) {
            font-size: 14px;
        }
        &:focus {
            box-shadow: none;
            outline: none;
        }
        .fa-angle-down {
            transition: all 0.5s ease;
            transform: rotate(${(props) => (props.active ? "180deg" : "0")});
        }
    }
    p {
        @media (max-width: 499px) {
            font-size: 12px;
        }
    }
`;

const Accordion = ({ item }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);
    return (
        <Style active={isOpen}>
            <button onClick={toggle} className="btn-accordion">
                {item.question}
                <i className="fas fa-angle-down"></i>
            </button>
            <Collapse isOpen={isOpen}>
                <div
                    className="article-desc"
                    dangerouslySetInnerHTML={{
                        __html: item.answer,
                    }}
                />
            </Collapse>
        </Style>
    );
};
export default Accordion;
